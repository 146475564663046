import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import axios from 'axios'

import I18n from '../I18n'
import Link, { path } from '../Routes'

import Quick from './Quick'
import Covid from '../Requests/Covid'
import Events from '../Events/Block'
import CardsSlider from './Cards/CardsSlider'
import VabinarCard2024 from './Cards/Vebinar'

import GridNews from '../News/GridNews'
import stylesNews from '../News/News.module.css'

import styles from './Index.module.css'
import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import Image from '../../images/pages/index/glavnaya.svg'

import fmc from '../../images/pages/index/fmc.png'


class Index extends Component {
  state = {
    events: null,
    news: null
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('index_path', {}, true), { cancelToken: this._asyncRequest.token })
    const res_news = await axios.get(path('news_index_path', {}, true), { cancelToken: this._asyncRequest.token })
    this.setState({ ... res.data, ... res_news.data })
    this._asyncRequest = null
  }

  render () {
    const { events, news } = this.state
    const { counters } = this.props

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <div className={styles.main}>
            <Quick {...counters} />

            <div className={classNames(styles.section, styles.intro)}>
              <div className={styles.ill} aria-hidden="true">
                <img src={Image} alt="Иллюстрация для страницы" />
              </div>

              <div className={styles.content}>
                <h1>
                  {I18n.t('pages.index.title')}
                </h1>

                <p>
                  {I18n.t('pages.index.desc')}
                </p>

                <p>
                  <Link to="about_path" className={buttons.main}>
                    {I18n.t('pages.index.more')}
                  </Link>
                </p>
              </div>
            </div>

            <div className={styles.section}>  
              <div className={styles.banners_container}>
                <Link to="monitoring_page_index_path" className={classNames(styles.banner_item, styles.big_banner_item, styles.mon_banner_item)}>
                  <div>Мониторинговые сведения о деятельности вузов</div>
                </Link>

                <div className={styles.small_banners_container}>
                  <Link to="schools_path" className={classNames(styles.banner_item, styles.small_banner_item, styles.first_banner)}>
                    <div>Найдите учебное заведение по:</div>
                  </Link>
                  <Link to="schools_path" className={classNames(styles.banner_item, styles.small_banner_item, styles.second_banner)}>
                    <div>Узнайте всю необходимую информацию в карточке вуза</div>
                  </Link>
                </div>
                <Link to="careers_path" className={classNames(styles.banner_item, styles.big_banner_item, styles.third_banner)}>
                  <div>Выберите востребованную профессию, рекомендованную экспертами ресурсных центров</div>
                </Link>
              </div>
            </div>


            <div className={classNames(styles.section, styles.for)}>
              <Link to="abiturs_path" className={styles.fors}>
                <div className={styles.abiturs}>
                  <h3>{I18n.t('pages.index.for.abiturs.title')}</h3>
                  <p>{I18n.t('pages.index.for.abiturs.desc')}</p>
                </div>
              </Link>
              <Link to="students_path" className={styles.fors}>
                <div className={styles.students}>
                  <h3>{I18n.t('pages.index.for.students.title')}</h3>
                  <p>{I18n.t('pages.index.for.students.desc')}</p>
                </div>
              </Link>
              <Link to="universities_path" className={styles.fors}>
                <div className={styles.universities}>
                  <h3>{I18n.t('pages.index.for.universities.title')}</h3>
                  <p>{I18n.t('pages.index.for.universities.desc')}</p>
                </div>
              </Link>
              <Link to="rumcs_path" className={styles.fors}>
                <div className={styles.rumcs}>
                  <h3>{I18n.t('pages.index.for.rumcs.title')}</h3>
                  <p>{I18n.t('pages.index.for.rumcs.desc')}</p>
                </div>
              </Link>
            </div>

            <section className={classNames(styles.section, styles.request)}>
              <Covid />
            </section>

            {/* Карточка с вебинарами по приемной кампании 2024
            <section className={classNames(styles.section, styles.request)}>
              <VabinarCard2024 />
            </section> */}


            {I18n.locale === 'ru' &&
              <div className={classNames(styles.section)}>

                {news &&
                  <div className={styles.news_wrapper}>
                    <h2 className={stylesNews.newsIndexTitleOffset}>Новости</h2>
                    <section>
                      <GridNews news={news} count={6} />
                    </section>
                    <Link to="news_index_path" className={classNames(buttons.main, stylesNews.btnIndexOffset)}>
                      Все новости
                    </Link>
                  </div>
                }

                {events &&  
                  <div>
                    <section className={classNames(styles.section, styles.events)}>
                      <Events events={events} count={6} />
                    </section>
                    <Link to="events_path" className={buttons.main}>
                      Все события
                    </Link>
                  </div>
                }   

                <CardsSlider />


              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

Index.propTypes = {
  counters: PropTypes.object
}

export default Index
