import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import I18n from '../I18n';
import { path } from '../Routes';

import styles from './States.module.css';
import buttons from '../Buttons.module.css';

class States extends Component {
  render() {
    const { object } = this.props;
    const { states, id } = this.props.data;

    return (
      <div className={styles.root}>
        <div className={styles.list}>
          {states.map((state, i) =>
            <div className={styles.item} key={state.id}>
              <div className={styles.current}>
                {i + 1}
              </div>

              <div>
                <div>
                  {I18n.t(`state.${state.id}`)}
                </div>

                {state.active &&
                  <>
                    {state.id == 'agreed' &&
                      <div className={styles.review}>
                        <p>
                          Материал проверен, согласован и готов к публикации. Для того чтобы он участвовал в формировании индекса необходимо проставить ручные характеристики объекта.
                          <br />
                          <Link to={path(`review_account_${object.type}_path`, { id: object.id })}>
                            Поставить оценки
                          </Link>
                        </p>
                      </div>

                    }

                    {state.markers && state.markers.length > 0 &&
                      <div className={styles.markers}>
                        {state.markers.map(marker =>
                          <div className={styles.marker} key={marker}>
                            {marker}
                          </div>
                        )}
                      </div>
                    }

                    {state.events && state.events.length > 0 &&
                      <div className={styles.events}>
                        {state.events.map(event =>
                          <div className={styles.event} key={event}>
                            <button className={buttons.main} onClick={() => this.props.onStateChange && this.props.onStateChange(event)}>{event}</button>
                          </div>
                        )}
                      </div>
                    }
                  </>
                }
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default States;
