import * as React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Routes } from '../Routes'
import classNames from 'classnames'
import axios from 'axios'
import Link, { path } from '../Routes'
import Loaf from '../Loaf/Loaf'
import I18n from '../I18n'
import { Helmet } from 'react-helmet-async'

import page from '../Page.module.css'

import GridNews from './GridNews'

class News extends React.Component {
  state = {
    news: null
  }

  _loadAsyncData = async () => {
      this._asyncRequest = axios.CancelToken.source()
      const res = await axios.get(path('news_index_path', { }, true), { cancelToken: this._asyncRequest.token })
      this.setState({ ... res.data })
      this._asyncRequest = null
    }
  
  componentDidMount () {
    this._loadAsyncData()
  }
  
  render () {
    const { news } = this.state
    const { history, location } = this.props

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="root_path">{I18n.t('nav.root')}</Link>
          </Loaf>

          <Helmet>
            <title>{I18n.t('nav.news')}</title>
          </Helmet>

          <div className={classNames(page.title, page.tight)}>
            <h1>
              Новости
            </h1>
          </div>
          
          <div>
            {news && news.length > 0 &&
              <GridNews news={news} count={news.length} />
            }
          </div>

        </div>
      </div>
    );
  }
}
class NewsWrapper extends React.Component {
    render() {
      return (
        <Switch>
          <Route path={decodeURIComponent(Routes.news_index_path)} component={News} exact strict />
        </Switch>
      )
    }
  }
  
export default NewsWrapper;
