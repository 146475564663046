import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from '../../Routes';

import Index from './Index';
import Show from './Show';
import Review from './Review';

class TargetDocuments extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path={decodeURIComponent(Routes.review_account_target_document_path)} render={props => <Review id={props.match.params.id} {...props} />} />
          <Route path={decodeURIComponent(Routes.account_target_document_path)} render={props => <Show id={props.match.params.id} {...props} />} />
          <Route exact path={decodeURIComponent(Routes.account_target_documents_path)} component={Index}/>
        </Switch>
      </React.Fragment>
    );
  }
}

export default TargetDocuments;
