import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';

import VerificateInput from './VerificateInput';

import styles from '../Section.module.css';
import form from '../../Form.module.css';

class Employees extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      values: ['ofc', 'resp', 'pp', 'sp', 'spt', 'po', 'pry', 'tp', 't', 'as', 'o', 'ag', 'pps', 'pov'].reduce((acc, e) => {
        acc[e] = this.props.values[e] || '';
        return acc;
      }, {}),
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      [name]: {
        $set: value
      }
    });

    this.setState({ values });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.onValuesChange) this.props.onValuesChange(this.props.prefix, this.state.values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state != nextState);
  }

  render () {
    const { values, show } = this.state;
    const { num } = this.props;

    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Обеспеченность процесса обучения лиц с ОВЗ и инвалидностью в вузе кадрами

          <div className={classNames(styles.label, { [styles.ok]: Object.values(values).filter(v => v).length == Object.values(values).length })}>
            {Object.values(values).filter(v => v).length} из {Object.values(values).length}
          </div>
        </div>

        <div className={styles.content}>
          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="ofc" checked={values.ofc} onChange={this.handleInputChange} />
                {num}.1. Наличие отдела (структурного подразделения) по сопровождению студентов с ОВЗ и инвалидностью
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="resp" checked={values.resp} onChange={this.handleInputChange} />
                {num}.2. Ответственный за организацию сопровождения студентов с ОВЗ и инвалидностью в вузе
              </label>
            </div>
          </div>

          <div className={styles.desc}>
            {num}.3. Численность специалистов в штате вуза, обеспечивающих сопровождение студентов с ОВЗ и инвалидностью:
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.1. Психолог (педагог-психолог)
            </div>

            <div className={form.input}>
              <VerificateInput name="pp" value={values.pp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.2. Социальный педагог (социальный работник)
            </div>

            <div className={form.input}>
              <VerificateInput name="sp" value={values.sp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.3. Специалист по техническим и программным средствам
            </div>

            <div className={form.input}>
              <VerificateInput name="spt" value={values.spt} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.4. Профориентолог
            </div>

            <div className={form.input}>
              <VerificateInput name="po" value={values.po} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.5. Переводчик русского жестового языка (переводчик-дактилолог)
            </div>

            <div className={form.input}>
              <VerificateInput name="pry" value={values.pry} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.6. Тифлопедагог
            </div>

            <div className={form.input}>
              <VerificateInput name="tp" value={values.tp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.7. Тьютор
            </div>

            <div className={form.input}>
              <VerificateInput name="t" value={values.t} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.8. Ассистент-помощник
            </div>

            <div className={form.input}>
              <VerificateInput name="as" value={values.as} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.9. Другое
            </div>

            <div className={form.input}>
              <VerificateInput name="o" value={values.o} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="ag" checked={values.ag} onChange={this.handleInputChange} />
                {num}.4. Наличие договора с организацией по привлечению таких специалистов (п.{num}.3)
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.5. Общее число ППС и учебно-вспомогательного персонала в вузе
            </div>

            <div className={form.input}>
              <VerificateInput name="pps" value={values.pps} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.6. Из них (п.{num}.5) прошедших повышение квалификации по вопросам работы с обучающимися с ОВЗ и инвалидностью (в течение последних 3-х лет)
            </div>

            <div className={form.input}>
              <VerificateInput name="pov" value={values.pov} onChange={this.handleInputChange} />
            </div>
          </div>


        </div>
      </div>
    );
  }
}

Employees.defaultProps = {
  prefix: 'employees',
  num: '4'
};

export default Employees;
