import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../../Routes'

import Index from './Index'

class UsersRoles extends Component {
  render () {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={decodeURIComponent(Routes.admin_users_roles_path)} component={Index}/>
        </Switch>
      </React.Fragment>
    )
  }
}

export default UsersRoles
