import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Facilities.module.css'

class Facilities extends Component {
  render () {
    const { facilities } = this.props

    if (!facilities) return null

    return (
      <div className={styles.root}>
        {facilities.map(nosology =>
          <Nosology key={nosology.id} {...nosology} />
        )}
      </div>
    )
  }
}

Facilities.propTypes = {
  facilities: PropTypes.array
}

class Nosology extends Component {
  state = {
    active: false
  }

  render () {
    const { title, sections, count } = this.props
    const { active } = this.state

    return (
      <div className={styles.nosology}>
        <h3 onClick={() => this.setState(state => ({ active: !state.active }))} className={classNames({ [styles.active]: active })}>
          {title}

          <div className={styles.count}>
            {count}
          </div>
        </h3>

        {active &&
          <div>
            {sections.map(section =>
              <Section key={section.id} {...section} />
            )}
          </div>
        }
      </div>
    )
  }
}

Nosology.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  count: PropTypes.number,
  sections: PropTypes.array
}

class Section extends Component {
  render () {
    const { title, facilities } = this.props

    return (
      <div className={styles.section}>
        <div>
          <strong>
            {title}
          </strong>
        </div>

        <div className={styles.facilities}>
          <ul>
            {facilities.map(facility =>
              <li className={styles.facility} key={facility.id}>
                {facility.title}
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  }
}

Section.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  facilities: PropTypes.array
}
export default Facilities
