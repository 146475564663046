import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import I18n from '../../I18n'
import { path } from '../../Routes'

// import Monitorings from './Index/Monitorings'
// import Targets from './Index/Targets'

import page from '../../Page.module.css'
import styles from './Index.module.css'

export default function Index () {
  const cancelToken = useRef(axios.CancelToken.source())
  const search = useLocation().search
  const query = new Map(new URLSearchParams(useLocation().search))

  const [rumcs, setRumcs] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(
        path('statistics_rumcs_path', {}, true),
        {
          params: {
            target: query.get('target')
          }
        },
        { cancelToken: cancelToken.current.token }
      )

      setRumcs(data.rumcs)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [search])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <div className={page.title}>
          <h1>
            {I18n.t('statistics.rumcs.title')}
          </h1>

          <Helmet>
            <title>{I18n.t('statistics.rumcs.title')}</title>
          </Helmet>
        </div>

        {rumcs &&
          <table className={styles.table}>
            <thead>
              <tr>
                <td colSpan="2">
                  РУМЦ
                </td>

                <td rowSpan="2">
                  Соглашения
                </td>

                <td colSpan="2">
                  Вебинары
                </td>

                <td colSpan="2">
                  Семинары
                </td>

                <td colSpan="2">
                  Профориентация
                </td>

                <td colSpan="2">
                  Трудоустройство
                </td>

                <td colSpan="2">
                  Совещания с вузами
                </td>

                <td colSpan="2">
                  Развитие ИВО
                </td>

                <td colSpan="2">
                  Волонтёрство
                </td>

                <td colSpan="2">
                  Сопр. мол. инвал.
                </td>

                <td rowSpan="2">
                  Кол. оборуд.
                </td>

                <td rowSpan="2">
                  Обращ. в call-центр
                </td>

                <td rowSpan="2">
                  Историй успеха
                </td>

                <td rowSpan="2">
                  Профессии
                </td>

                <td rowSpan="2">
                  Мон. труд.
                </td>

                <td rowSpan="2">
                  Мон. ППС
                </td>

                <td rowSpan="2">
                  Мон. соц.-псих. благ.
                </td>

                <td rowSpan="2">
                  Готовность к обучению слабовидящих
                </td>

                <td rowSpan="2">
                  Готовность к обучению слабослышащих
                </td>

                <td rowSpan="2">
                  Готовность к обучению студентов с нарушениями опорно-двигательного аппарата
                </td>
              </tr>
              <tr>
                <td>ID</td>
                <td className={styles.title}>Название</td>

                <td>Кол.</td>
                <td>Участ.</td>

                <td>Кол.</td>
                <td>Участ.</td>

                <td>Кол.</td>
                <td>Участ.</td>

                <td>Кол.</td>
                <td>Участ.</td>

                <td>Кол.</td>
                <td>Участ.</td>

                <td>Кол.</td>
                <td>Участ.</td>

                <td>Кол.</td>
                <td>Участ.</td>

                <td>Кол.</td>
                <td>Участ.</td>
              </tr>
            </thead>

            <tbody>
              {rumcs.map(rumc =>
                <tr key={rumc.id}>
                  <td>
                    {rumc.id}
                  </td>

                  <td>
                    {rumc.label}
                  </td>

                  <td>
                    {rumc.rumc_schools.length}
                  </td>

                  {['webinar', 'training', 'profession', 'employment', 'meeting', 'development', 'volunteering', 'young'].map(section =>
                    <>
                      <td>
                        {rumc.events.filter(event => event.section === section).length}
                      </td>

                      <td>
                        {rumc.events.filter(event => event.section === section).map(event => event.registrations).reduce((sum, x) => sum + x, 0)}
                      </td>
                    </>
                  )}

                  <td>
                    {rumc.facilities.length}
                  </td>

                  <td>
                    {rumc.calls}
                  </td>

                  <td>
                    {rumc.stories.length}
                  </td>

                  <td>
                    {rumc.careers}
                  </td>

                  <td>
                    {rumc.form_1367}
                  </td>

                  <td>
                    {rumc.form_1370}
                  </td>

                  <td>
                    {rumc.form_1371}
                  </td>

                  <td>
                    {rumc.nosology_e}
                  </td>

                  <td>
                    {rumc.nosology_h}
                  </td>

                  <td>
                    {rumc.nosology_m}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        }
      </div>
    </div>
  )
}
