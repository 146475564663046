import * as React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Routes } from '../Routes'
import classNames from 'classnames'
import axios from 'axios'
import I18n from '../I18n'
import GlobalVariables from '../Vars';

import Link, { path } from '../Routes'
import Loaf from '../Loaf/Loaf'
import styles from './TypesEquipment.module.css'
import page from '../Page.module.css'
import qs from 'qs';

import ScrollIntoView from 'react-scroll-into-view';

import back_arrow from '../../images/icons/bc_white.svg';
import forward_arrow from '../../images/icons/forward_arrow_white.svg';


export const FilterType = Object.freeze({
  Page: 'page',
  NosologyId: 'nosology_id',
  Letters: 'letters'
});


export const DefaultFilterValue = Object.freeze({
  [FilterType.Page]: 1,
  [FilterType.NosologyId]: 1,
  [FilterType.Letters]: []
});


class TypesEquipment extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      equipments: [],
      alphabet: [],
      activeNosologyTab: 1,
      letters: [], 
  
      pages: [1],
      activePage: 1,
      loading: true,
      openedLoading: false,
  
      openedEquipments: [],
      openedCardData: {},
      mRecShowMore: 3,
      closedPhoto: []
    }

    this.equipmentRef = React.createRef([])
    this.toggleNosologyRef = React.createRef()
 }

  _loadAsyncData = async (params) => {
    this._asyncRequest = axios.CancelToken.source()
    const res = await axios.get(path("types_equipment_index_path", {}, true), { cancelToken: this._asyncRequest.token, params: params })
    this._asyncRequest = null

    this.setState({ 
      equipments: res.data.equipments, 
      pages: [...Array(res.data.pages).keys()].map((i) => i + 1), 
      alphabet: res.data.alphabet, 
      loading: false
    })

    this.equipmentRef = res.data.equipments.map((_, i) => this.equipmentRef[i] || React.createRef([]))   
  }

  componentDidMount () {
    const params = this.updateURLParams({});
    this._loadAsyncData(params)
  }

  parseURLQuery = () => {
    const searchParamsStr = this.props.history?.location?.search;
    const paramsObject = qs.parse(searchParamsStr || '', { ignoreQueryPrefix: true }) || {};

    var overriddenDefaultFilters = { ...DefaultFilterValue };
    overriddenDefaultFilters.letters = [];
    for (const key of Object.keys(DefaultFilterValue)) {
      if (paramsObject[key]) {
        overriddenDefaultFilters[key] = paramsObject[key];
      }
    }
    return overriddenDefaultFilters;
  };

  updateURLParams = (newParams) => {
    const params = this.parseURLQuery();

    for (const [key, value] of Object.entries(newParams)) {
      params[key] = value;
    }

    const strParams = qs.stringify(params) || '';
    this.props.history.push({
      search: strParams.length > 0 ? '?' + qs.stringify(params) : ''
    });

    this.setState({activeNosologyTab: params.nosology_id, page: params.page, letters: params.letters})
    return params
  }

  toggleTabNosology = (e, newId) => {
    e.preventDefault();
    
    if (this.toggleNosologyRef.current) {
      this.toggleNosologyRef.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "start"
      })
    }

    const params = this.updateURLParams({nosology_id: newId});
    this._loadAsyncData(params);
  }

  filterPages = () => {
    let active_page = this.state.activePage;

    let min = active_page - 3;
    let max = active_page + 2;
    if (window.innerWidth <= 480) {
      min = active_page - 2;
      max = active_page + 1;
    }
    let filtered = [...this.state.pages];

    if (min < 0) {
      min = 0;
      max = 5;
      if (window.innerWidth <= 480) {
        max = 3;
      }
    }

    if (max >= filtered.length) {
      min = filtered.length - 5;
      if (window.innerWidth <= 320) {
        min = filtered.length - 3;
      }
      max = filtered.length;
    }
    
    return filtered.filter((v, i) => {
      if (i >= min && i < max) return v;
    });
  }

  applyLetter = (e, letter) => {
    e.preventDefault();
    
    var newLetters = this.state.letters;
    if (this.state.letters.includes(letter)) {
      newLetters = newLetters.filter((n) => {return n != letter});
    } else {
      newLetters.push(letter);
    }
    const newparams = this.updateURLParams({letters: newLetters});
    this._loadAsyncData(newparams)
  }

  clearLetters = (e) => {
    e.preventDefault();
    const newparams = this.updateURLParams({letters: []});
    this._loadAsyncData(newparams);
  }

  toggleOpenEquipment = async (e, id, index) => {
    e.preventDefault();
    // this.setState({openedLoading: true})
    var tmp_opened = this.state.openedEquipments;
    
    if (tmp_opened.includes(id)) {
      tmp_opened = tmp_opened.filter((n) => {return n != id});
    } else {
      this._asyncRequest = axios.CancelToken.source();
      const res = await axios.get(path("types_equipment_path", { id }, true), { cancelToken: this._asyncRequest.token });
      // tmp_opened.push(id);
      tmp_opened = [id]; // открыта только одна карточка
      this.setState({ 
        openedCardData: res.data.equipment, 
        mRecShowMore: res.data.equipment.m_rec ? res.data.equipment.m_rec.length > 3 ? 3 : res.data.equipment.m_rec.length : 0
      });
      this._asyncRequest = null;
    }
    // this.setState({ openedEquipments: tmp_opened, openedLoading: false });
    this.setState({ openedEquipments: tmp_opened });
    
    this.equipmentRef[index].current.scrollIntoView({ 
      behavior: "smooth", 
      block: "start"
    })
  }

  togglePhoto = (e, id) => {
    e.preventDefault();
    let tmp_closed = this.state.closedPhoto;
    if (tmp_closed.includes(id)) {
      tmp_closed = tmp_closed.filter((n) => {return n != id});
    } else {
      tmp_closed.push(id);
    }
    this.setState({closedPhoto: tmp_closed});
  }

  render () {
    const { equipments, alphabet, activeNosologyTab, letters, pages, 
      activePage, loading, openedLoading, openedEquipments, openedCardData, mRecShowMore, closedPhoto } = this.state;

    console.log(GlobalVariables.cdnHost)

    return (
      <div className={page.wrapper}>
          <div className={page.container}>
          <Loaf>
            <Link to="universities_path">{I18n.t('nav.universities')}</Link>
          </Loaf>

          <div className={classNames(page.title, page.tight)}>
            <h1>
              {I18n.t('types_equipment.title')}
            </h1>

            <h3><i>Типы оборудования и программное обеспечение</i></h3>
            <p>Инфраструктурные листы по оснащению Центра коллективного пользования специальными техническими и программными средствами обучения Ресурсных учебно-методических центров по обучению инвалидов и лиц с ограниченными возможностями здоровья в системе высшего образования.</p>
            
            <Helmet>
              <title>{I18n.t('types_equipment.title')}</title>
            </Helmet>
          </div>

          <div className={styles.nav_container} ref={this.toggleNosologyRef}>
            <div className={styles.info_groups_nav}>
              <div className={(activeNosologyTab == 1 ? styles.info_groups_active : '')}>
                <a href="#" className={styles.g_eye} onClick={(e) => {this.toggleTabNosology(e, 1)}} title="Для людей с нарушениями зрения" />
              </div>
              <div className={(activeNosologyTab == 2 ? styles.info_groups_active : '')}>
                <a href="#" className={styles.g_ear} onClick={(e) => {this.toggleTabNosology(e, 2)}} title="Для людей с нарушениями слуха" />
              </div>
              <div className={(activeNosologyTab == 3 ? styles.info_groups_active : '')}>
                <a href="#" className={styles.g_body} onClick={(e) => {this.toggleTabNosology(e, 3)}} title="Для людей с нарушениями опорно-двигательного аппарата" />
              </div>
            </div>

            {alphabet &&
              <div className={styles.alphabet}>
                {alphabet.map((letter) => ( 
                  <div key={letter.id}>
                    {letter.active == true && letter.id != "100" && 
                      <p 
                        key={letter.id} 
                        onClick={(e) => this.applyLetter(e, letter.id)} 
                        className={letters.includes(letter.id) ? classNames(styles.letter, styles.active) : styles.letter}
                      >
                        {letter.value}
                      </p>
                    }
                    {letter.active == false && letter.id != "100" && 
                      <p 
                        className={classNames(styles.no_equipment, styles.letter)}
                        data-title="Такого оборудования нет"
                        disabled={true}
                      >
                        {letter.value}
                      </p>
                    }
                    
                    {letter.active == true && letter.id == "100" &&
                      <p 
                        key={letter.id} 
                        onClick={(e) => this.applyLetter(e, letter.id)} 
                        className={letters.includes(letter.id) ? classNames(styles.letter_other, styles.active, styles.letter) : classNames(styles.letter, styles.letter_other)}
                      >
                        {letter.value}
                      </p>
                    }
                    {letter.active == false && letter.id == "100" && 
                      <p 
                        className={classNames(styles.no_equipment, styles.letter, styles.letter_other,)}
                        data-title="Такого оборудования нет"
                        disabled={true}
                      >
                        {letter.value}
                      </p>
                    }
                  </div>
                  // <p 
                  //   key={letter.id} 
                  //   onClick={(e) => this.applyLetter(e, letter.id)} 
                  //   className={letter.active ? (letters.includes(letter.id) ? styles.active : '') : (styles.no_equipment)}
                  //   title={letter.active ? "" : "Такого оборудования нет" }
                  //   disabled={!letter.active}
                  // >
                  //   {letter.value}
                  // </p>
                ))}
                {letters.length != 0 && <p className={styles.clear_filters} onClick={(e) => this.clearLetters(e)} title="Очистить фильтр" />}
              </div>
            }
          </div>

          {equipments && loading == false &&
            <div className={styles.equipment_container}>
              <div className={styles.my_hr} />
              {equipments.map((equipment, index) => (
                <div key={equipment.id}>
                <div ref={this.equipmentRef[index]} className={styles.equipment} onClick={(e) => {this.toggleOpenEquipment(e, equipment.id, index)}} id={"equipment_" + equipment.id}>
                  <div className={styles.title}>{equipment.title}</div>
                  <div className={openedEquipments.includes(equipment.id) ? styles.eq_arrow : classNames(styles.arrow_down, styles.eq_arrow)} />
                </div>
                <div className={styles.my_hr} />
                {openedEquipments.includes(equipment.id) && openedCardData &&
                  <div className={styles.opened_equipment_container}>
                    <div className={styles.opened_equipment_column}>
                      {openedCardData.m_rec &&
                        <div>
                          <p className={styles.opened_header} id="m_rec">Методические рекомендации</p>
                          {openedCardData.m_rec.slice(0, mRecShowMore).map((item) => (
                            <p key={item.id}>{item.value}</p>
                          ))}
                          {mRecShowMore >= 3 && mRecShowMore != openedCardData.m_rec.length && <p className={styles.showMore} onClick={() => {this.setState({mRecShowMore: openedCardData.m_rec.length})}}>Читать полностью</p> }
                          {mRecShowMore > 3 && mRecShowMore == openedCardData.m_rec.length && 
                            <ScrollIntoView selector="#m_rec">
                              <p className={styles.showMore} onClick={() => {this.setState({mRecShowMore: 3})}}>Скрыть</p> 
                            </ScrollIntoView>
                          }
                        </div>
                      }

                      {openedCardData.names &&
                        <div>
                          <p className={styles.opened_header}>Наименования</p>
                          <div className={styles.opened_names_wrapper}>
                            {openedCardData.names.map((item) => (
                              <div key={item.id} className={styles.opened_names}>{item.value}</div>
                            ))}
                          </div>
                        </div>
                      }

                      {openedCardData.specifications &&
                        <div>
                          <p className={styles.opened_header}>Технические характеристики</p>
                          {openedCardData.specifications.map((item) => (
                            <div key={item.id} className={styles.specifications}>{item.value}</div>
                          ))}
                        </div>
                      }

                      <p className={styles.opened_header}>Имеющиеся модификации</p>
                      {openedCardData.modifications &&
                        <div>
                          {openedCardData.modifications.map((item) => (
                            <div key={item.id}>
                              <div className={styles.modifications}>{item.value}</div>
                              {item.ul && 
                                <ul className={styles.modifications_ul}>
                                  {item.ul.map((li, index) => (
                                    <li key={index}>{li}</li>
                                  ))}
                                </ul>
                              }
                              {item.equipment && 
                                <div  className={styles.opened_names_wrapper}>
                                  {item.equipment.map((e, index) => (
                                    <div key={index} className={styles.opened_names}>{e}</div>
                                  ))}
                                </div>
                              }
                            </div>
                          ))}
                        </div>
                      }
                      {!openedCardData.modifications && <p>Не имеет модификаций</p>}
                    </div>


                    <div className={styles.opened_equipment_column}>
                      <div className={styles.photos_container}>
                        {openedCardData.photo.map((item) => (
                          <div className={styles.photo_wrapper} key={item.id}>
                            {item.name && 
                              <div className={styles.photo_name_wrapper} onClick={(e) => {this.togglePhoto(e, item.id)}}>
                                <div className={styles.photo_name}>{item.name}</div>
                                <div className={closedPhoto.includes(item.id) ? classNames(styles.arrow_down, styles.arrow) : styles.arrow} />
                              </div>
                            }
                            {!closedPhoto.includes(item.id) && 
                              <a href={GlobalVariables.cdnHost + item.url} target="_blank">
                                <img src={GlobalVariables.cdnHost + item.url} />
                              </a>
                            }
                          </div>
                        ))}
                      </div>

                      {openedCardData.category && 
                        <div>
                          <div className={styles.opened_subheader}>Категория</div>
                          <p>{openedCardData.category}</p>
                        </div>
                      }

                      {openedCardData.ru != null && 
                        <div>
                          <div className={styles.opened_subheader}>Производится в России</div>
                          {openedCardData.ru == 1 && <span className={styles.ru}>Да</span>}
                          {openedCardData.ru == 0 && <span className={styles.ru}>Нет</span>}
                        </div>
                      }

                      {openedCardData.use_terms && 
                        <div>
                          <div className={styles.opened_subheader}>Условия использования</div>
                          <p>{openedCardData.use_terms}</p>
                        </div>
                      }

                      {openedCardData.cases && 
                        <div>
                          <div className={styles.opened_subheader}>Варианты использования</div>
                          <p>{openedCardData.cases}</p>
                        </div>
                      }

                      {openedCardData.hardware &&
                        <div>
                          <div className={styles.opened_subheader}>Аппаратное обеспечение для работы</div>
                          {openedCardData.hardware.map((item) => (
                            <div key={item.id}>
                              <div className={styles.hardware}>{item.value}</div>
                              {item.included && <div className={styles.included}>Поставляется в комплекте</div>}
                            </div>
                          ))}
                        </div>
                      }

                      {openedCardData.software && 
                        <div>
                          <div className={styles.opened_subheader}>Программное обеспечение для работы</div>
                          <p>{openedCardData.software}</p>
                        </div>
                      }

                      {openedCardData.consumables && 
                        <div>
                          <div className={styles.opened_subheader}>Необходимые расходные материалы</div>
                          <p>{openedCardData.consumables}</p>
                        </div>
                      } 
                      
                      <div className={styles.hr} />

                      {openedCardData.rumcs &&
                        <div>
                          <p className={styles.opened_header}>РУМЦ, располагающие данным оборудованием</p>
                          {openedCardData.rumcs.map((item) => ( 
                            <div key={item.id} className={styles.rumc_wrapper}>
                              <Link to="rumc_path" params={{ id: item.rumc_id }} className={styles.rumcs_title}>{item.rumc_title}</Link>
                              <div className={styles.hr_s} />
                              <div  className={styles.opened_names_wrapper}>
                                {item.equipment.map((e, index) => (
                                  <div key={index} className={styles.opened_names}>{e}</div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      }

                      {openedCardData.files &&
                        <div className={styles.files_wrapper}>
                          {openedCardData.files.map((item) => ( 
                            <a key={item.id} className={styles.file} href={item.url} target="_blank">
                              <div className={styles.file_data}>
                                <div className={styles.extension}>{item.ex}</div>
                                <div className={styles.size}>{item.size}</div>
                              </div>
                              <div className={styles.file_title}>{item.title}</div>
                            </a>
                          ))}
                        </div>
                      }

                      <ScrollIntoView selector={"#equipment_" + equipment.id} className={classNames(styles.included, styles.scroll_up)}>
                        Наверх
                      </ScrollIntoView>

                    </div>
                    <div className={styles.my_hr} />

                  </div>
                }
                </div>
              ))}
            </div>
          }

          {loading && <div className={styles.loading} /> }
          {loading == false && equipments.length == 0 && <h3>Оборудование не найдено</h3>}

          {pages.length > 1 && 
          <div className={styles.pagination}>
            <div
              className={classNames(styles.first_last_page, styles.first_last_page_first)}
              title="В начало"
              onClick={(e) => {
                  activePage != 1 ? this.setActivePage(e, 1) : '';
              }}
            >
              <img src={back_arrow} />
              <img src={back_arrow} />
            </div>

            <div
              className={styles.back_forward}
              onClick={(e) => {
                  activePage != 1 ? this.setActivePage(e, activePage - 1) : '';
              }}
            >
              <div className={styles.back_arrow}></div>
              <span className={activePage == 1 ? styles.disabled : styles.enabled} id="back">
                Назад
              </span>
            </div>
            {this.filterPages().map((p, i) => (
              <div
                key={i}
                className={activePage == p ? classNames(styles.pagination_page, styles.pagination_active) : styles.pagination_page}
                id={p}
                onClick={(e) => {
                    this.setActivePage(e, p);
                }}
              >
                {p}
              </div>
            ))}
            <div
              className={styles.back_forward}
              onClick={(e) => {
                activePage != this.state.pages[this.state.pages.length - 1] ? this.setActivePage(e, activePage + 1) : '';
              }}
            >
              <span className={activePage == this.state.pages[this.state.pages.length - 1] ? styles.disabled : styles.enabled} id="forward">
                Вперед
              </span>
              <div className={styles.forward_arrow}></div>
            </div>

            <div
              className={styles.first_last_page}
              title="В конец"
              onClick={(e) => {
                  activePage != this.state.pages[this.state.pages.length - 1] ? this.setActivePage(e, this.state.pages[this.state.pages.length - 1]) : '';
              }}
            >
              <img src={forward_arrow} />
              <img src={forward_arrow} />
            </div>
          </div>
          }

          </div>
        </div>
    );
  }
}

class TypesEquipmentWrapper extends React.Component {
  render() {
    return (
      <Switch>
        <Route path={decodeURIComponent(Routes.types_equipment_index_path)} component={TypesEquipment} exact strict />
      </Switch>
    )
  }
}

export default TypesEquipmentWrapper
