import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import axios from 'axios'
import update from 'immutability-helper'
import qs from 'query-string'

import I18n from '../I18n'
import { path } from '../Routes'

import Loaf from '../Loaf/Loaf'
import Numbers from './School/Numbers'
import Finance from './School/Finance'
import Docs from './School/Docs'
import Employees from './School/Employees'
import Environment from './School/Environment'
import Technical from './School/Technical'
import Prof from './School/Prof'
import Adaptation from './School/Adaptation'
import Quality from './School/Quality'
import Employment from './School/Employment'
import Post from './School/Post'
import Programs from './School/Programs'

import form from '../Form.module.css'
import styles from './Form.module.css'
import buttons from '../Buttons.module.css'
import page from '../Page.module.css'

class Form extends React.Component {
  state = {
    section: qs.parse(this.props.location.search).section,
    create: false,
    send: false,
    save: false,
    report: null,
    values: null,
    dictionaries: null,
    monitorings: null,
    reportables: null
  }

  submit = {
    url: path('reports_path', {}, true),
    method: 'post'
  }

  componentDidMount () {
    this._loadAsyncData(this.props.id)
  }

  _loadAsyncData (id) {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(id ? path('edit_report_path', { id: id }, true) : path('new_report_path', {}, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          report: res.data.report,
          dictionaries: res.data.dictionaries,
          reportables: res.data.reportables,
          monitorings: res.data.monitorings,
          values: res.data.values
        })

        this._asyncRequest = null
      })
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  static getDerivedStateFromProps (props, state) {
    if (props.id !== state.prevID) {
      return { prevID: props.id, values: null }
    }
    return null
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.values === null) this._loadAsyncData(this.props.id)
  }

  handleValuesChange = (prefix, data) => {
    const values = update(this.state.values, {
      stats: {
        [prefix]: {
          $set: data
        }
      }
    })

    this.setState({ values })
  }

  handleSubmit = (e) => {
    if (this._canSubmit()) {
      this.setState({ send: true, save: false })
      this._asyncRequest = axios.CancelToken.source()

      axios({
        method: 'patch',
        url: path('report_path', { id: this.props.id, query: { section: this.state.section } }, true),
        cancelToken: this._asyncRequest.token,
        data: { report: this.state.values }
      }).then(
        res => {
          this.setState({ send: false, save: true })
          // this.props.history.push(res.headers.location)
        }
      ).catch(_error => {
        this.setState({ send: false })
      }).then(() => {
        this._asyncRequest = null
      })
    }

    if (e) e.preventDefault()
  }

  _canSubmit = () => {
    return (
      !!this.state.report &&
      !this.state.send
    );
  }

  render () {
    const { report, values, dictionaries, monitorings, schools, save, section } = this.state

    if (!values) return null
    const title = report ? 'Редактирование формы мониторинга' : 'Новая форма мониторинга'

    let monitoring = report && report.monitoring ? report.monitoring : monitorings.find(m => m.active)
    if (!monitoring) monitoring = monitorings[monitorings.length - 1]

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={path('reports_path')}>{I18n.t('reports.title')}</Link>
            {report &&
              <Link to={path('report_path', { id: report.id })}>№ {report.id}</Link>
            }
          </Loaf>

          <div className={page.title}>
            {report ? (
              <>
              <h1>
                {title}
              </h1>

              <Helmet>
                <title>
                  {title}
                </title>
              </Helmet>
              </>
            ) : (
              <div className={styles.create}>
                <p>
                  Форма ниже размещена лишь для ознакомления с вопросами и не даёт возможности сохранять заполненные данные. Если вы представитель вуза и хотите заполнить форму мониторинга — зайдите в личный кабинет и нажмите кнопку «Заполнить форму мониторинга» около соответствующей организации.
                </p>

                {/*
                <div>
                  <div className={form.elem}>
                    <div className={form.label}>
                      Мониторинг
                    </div>

                    <div className="input_input">
                      <select name="monitoring_id">
                      </select>
                    </div>
                  </div>

                  <div className={form.elem}>
                    <div className={form.label}>
                      Образовательная организация
                    </div>

                    <div className="input_input">
                      <select name="reportable_id">
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <button onClick={this.handleCreateClick} className={buttons.black}>
                    Начать заполнение
                  </button>
                </div>
                */}
              </div>
            )}
          </div>
          <div className={styles.form}>
            <form name='tt' className={form.root} onSubmit={this.handleSubmit}>
              {section &&
                <>
                  {section === 'numbers' &&
                    <Numbers monitoring={monitoring} values={values.stats.numbers || {}} onValuesChange={this.handleValuesChange} />
                  }
                  {section === 'finance' &&
                    <Finance monitoring={monitoring} values={values.stats.finance || {}} onValuesChange={this.handleValuesChange} />
                  }
                  {section === 'docs' &&
                    <Docs values={values.stats.docs || {}} onValuesChange={this.handleValuesChange} />
                  }
                  {section === 'employees' &&
                    <Employees values={values.stats.employees || {}} onValuesChange={this.handleValuesChange} />
                  }
                  {section === 'environment' &&
                    <Environment values={values.stats.environment || {}} onValuesChange={this.handleValuesChange} />
                  }
                  {section === 'technical' &&
                    <Technical values={values.stats || {}} onValuesChange={this.handleValuesChange} />
                  }
                  {section === 'prof' &&
                    <Prof values={values.stats || {}} onValuesChange={this.handleValuesChange} />
                  }
                  {section === 'adaptation' &&
                    <Adaptation values={values.stats || {}} onValuesChange={this.handleValuesChange} />
                  }
                  {section === 'quality' &&
                    <Quality values={values.stats || {}} onValuesChange={this.handleValuesChange} />
                  }
                  {section === 'employment' &&
                    <Employment monitoring={monitoring} values={values.stats || {}} onValuesChange={this.handleValuesChange} />
                  }
                  {section === 'post' &&
                    <Post values={values.stats || {}} onValuesChange={this.handleValuesChange} />
                  }
                  {section === 'programs' &&
                    <Programs monitoring={monitoring} report={report} directions={dictionaries.directions} />
                  }

                  {section !== 'programs' &&
                    <div className={form.submit}>
                      <input type="submit" value="Сохранить" className={buttons.main} disabled={!this._canSubmit()} />
                    </div>
                  }

                  {save &&
                    <div className={styles.save}>
                      <p>
                        Данные успешно сохранены.
                      </p>

                      <Link to="report_path" params={{ id: report.id }} className={buttons.cancel}>
                        Вернуться к форме мониторинга
                      </Link>
                    </div>
                  }

                  {section === 'programs' &&
                    <Link to="report_path" params={{ id: report.id }} className={buttons.cancel}>
                      Вернуться к форме мониторинга
                    </Link>
                  }
                </>
              }
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Form
