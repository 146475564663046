import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Index'
import Show from './Show'
import Form from './Form'

class Schools extends Component {
  render() {
    return (
      <Switch>
        <Route path={decodeURIComponent(Routes.edit_school_path)} render={props => <Form id={props.match.params.id} />} />
        <Route path={decodeURIComponent(Routes.school_path)} render={props => <Show id={props.match.params.id} />} />
        <Route path={decodeURIComponent(Routes.schools_path)} component={Index} exact strict />
      </Switch>
    )
  }
}

export default Schools
