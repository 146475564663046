import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Redirect } from 'react-router-dom'

import { path } from '../Routes'
// import I18n from '../I18n'

import styles from './RumcsBlock.module.css'
import map from '../Map.js'

class RumcsMapRegion extends React.Component {
  handleMouseEnter = (e, rumc_id) =>  {
    if (this.props.onChangeRumc) this.props.onChangeRumc(rumc_id, parseInt(this.props.id))
  }

  handleRumcClick = (rumc_id) => {
    if (this.props.onRumcClick) this.props.onRumcClick(rumc_id)
  }

  render () {
    return (
      <g className={classNames(styles.group, { [styles.active]: this.props.active})} onMouseEnter={(e) => this.handleMouseEnter(e, this.props.rumc_id)} onClick={(e) => this.handleRumcClick(this.props.rumc_id)}>
        {this.props.polygons &&
          <React.Fragment>
            {this.props.polygons.map((polygon, _) =>
              <path key={_} className={styles.region} d={`M${polygon}`} />
            )}
          </React.Fragment>
        }

        {this.props.paths.map((path, _) =>
          <path key={_} className={styles.region} d={path} />
        )}
      </g>
    )
  }
}

class RumcsMapRumc extends React.Component {
  handleMouseEnter = (e, rumc_id) =>  {
    if (this.props.onChangeRumc) this.props.onChangeRumc(rumc_id)
  }

  render () {
    return (
      <svg
        className={classNames(styles.baloon, { [styles.active]: this.props.active })}
        x={this.props.rumc.x + 13}
        y={this.props.rumc.y + 60}
        onMouseEnter={(e) => this.handleMouseEnter(e, this.props.rumc.id)}
      >
        <circle cx="13" cy="13" r="9" />
        <line x1="13" y1="13" x2="13" y2="60" />
        <rect width="26" height="60" />
      </svg>
    )
  }
}

class RumcsMap extends React.Component {
  state = {
    rumc_id: null,
    region_ids: [],
    redirect_to: null,

    rumcs_selected: [],

    rumcsDict: null,
    redionsDict: {},
  }
 
  componentDidUpdate() {
    if (!this.state.rumcsDict && this.props.rumcs) {
      var rumcsDict = {};
      var redionsDict = {};
      for (const item of this.props.rumcs) {
        rumcsDict[item.id] = item
        for (const region_id of item.regions) {
          redionsDict[region_id] = redionsDict[region_id] ? [...redionsDict[region_id], item.id] : [item.id]
        }
      }
      this.setState({ rumcsDict, redionsDict })
    }
  }

  hoverRef = React.createRef();

  handleMouseLeave = (e) => {
    this.hoverRef.current.innerHTML = ''
  }

  handleChangeRumc = (rumc_id, region_id = null) => {
    const target_rumc = this.state.rumcsDict ? this.state.rumcsDict[rumc_id] : null;
    this.setState({
      rumc_id: rumc_id,
      // region_ids: map.regions.filter(region => region.rumc === rumc_id).map(region => region.ident)
      region_ids: target_rumc ? target_rumc.regions : [],
      rumcs_selected: this.state.redionsDict[region_id],
    })
  }

  handleRumcClick = (rumc_id) => {
    // TODO: исправить на тач девайсах чтобы сначала был ховер, а потом уже переход.
    this.setState({
      redirect_to: path('rumc_path', { id: rumc_id })
    })
  }

  render () {
    if (this.state.redirect_to) return <Redirect push to={this.state.redirect_to} />

    return (
      <div>
        <svg viewBox={map.viewPort}>

          <g className={styles.bg}>
            {map.regions.map(region =>
              <RumcsMapRegion
                active={this.state.region_ids.includes(parseInt(region.ident)) && this.state.rumc_id}
                paths={region.paths}
                polygons={region.polygons}
                key={region.ident}
                id={region.ident}
              />
            )}
          </g>

          <g className={styles.hover} ref={this.hoverRef}>
            {map.regions.map(region => 
              <RumcsMapRegion
                active={this.state.region_ids.includes(parseInt(region.ident)) && this.state.rumc_id}
                rumc={region.rumc}
                paths={region.paths}
                polygons={region.polygons}
                key={`hover_${region.ident}`}
              />
            )}
          </g>

          <g className={styles.baloons}>
            {map.rumcs.map(rumc =>
              <RumcsMapRumc
                key={rumc.id}
                rumc={rumc}
                active={rumc.id === this.state.rumc_id && this.state.rumc_id}
                // active={this.state.rumcs_selected && this.state.rumcs_selected.includes(rumc.id) && this.state.rumc_id}
                onChangeRumc={this.handleChangeRumc}
              />
            )}
          </g>

          <g className={styles.labels}>
          
            {map.rumcs.map(rumc =>
              <svg
                className={classNames(styles.label, { [styles.active]: rumc.id === this.state.rumc_id && this.state.rumc_id })}
                // className={classNames(styles.label, { [styles.active]: this.state.rumcs_selected &&  this.state.rumcs_selected.includes(rumc.id) && this.state.rumc_id })}
                key={`label_${rumc.id}`}
                x={rumc.x + 20}
                y={rumc.y + 80}
              >
                {rumc.title != 'Тихоокеанский государственный университет' &&
                  <text className={styles.rumcTitle} x="40" y="30">
                    {rumc.title}
                  </text>
                }
                {rumc.title == 'Тихоокеанский государственный университет' &&
                  <>
                    <text className={styles.rumcTitle} x="40" y="30">
                      Тихоокеанский
                    </text>
                    <text className={styles.rumcTitle} x="40" y="60">
                      государственный
                    </text>
                    <text className={styles.rumcTitle} x="40" y="90">
                      университет
                    </text>
                  </>
                }
              </svg>
            )}
          </g>

          <g className={styles.handle} ref={this.hoverRef}>
            {map.regions.map(region =>
              <RumcsMapRegion
                paths={region.paths}
                polygons={region.polygons}
                rumc_id={region.rumc}
                id={region.ident}
                key={`handle_${region.ident}`}
                onChangeRumc={this.handleChangeRumc}
                onRumcClick={this.handleRumcClick}
              />
            )}
          </g>

        </svg>
      </div>
    )
  }
}

class RumcsBlock extends React.Component {
  render () {
    return (
      <div className={styles.root}>
        <RumcsMap className={styles.map} rumcs={this.props.rumcs} />
      </div>
    )
  }
}

export default RumcsBlock
