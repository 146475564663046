import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'query-string';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import classNames from 'classnames';

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import Datec from '../../Datec';
import { path } from '../../Routes';
import AttachmentsList from '../../Attachments/AttachmentsList';

import page from '../../Page.module.css';
import buttons from '../../Buttons.module.css';
import styles from './Show.module.css';
import stylesStates from '../States.module.css';

class Show extends Component {
  state = {
    rumc_school: null,
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('account_rumc_school_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { rumc_school } = res.data;

        this.setState({ rumc_school });
        this._asyncRequest = null;
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rumc_school === null) this._loadAsyncData(this.props.id);
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleStateChange = (event) => {
    this._asyncRequest = axios.CancelToken.source();

    axios({
      method: 'post',
      cancelToken: this._asyncRequest.token,
      url: path(`${event}_account_rumc_school_path`, { id: this.props.id }, true),
    }).then(
      res => {
        this.setState({
          rumc_school: null,
        });
      }
    );
  }

  handleDestroy = (id) => {
    axios.delete(path('account_rumc_school_path', { id: id }, true)).then(
      res => {
        this.props.history.push(res.headers.location)
      }
    )
  }

  render() {
    const { rumc_school } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={`${path('account_rumc_schools_path')}?${qs.stringify(cookies.get('filters', { path: path('account_rumc_schools_path') }))}`}>{I18n.t('rumc_schools.title')}</Link>
          </Loaf>

          {rumc_school &&
            <>
              <div className={page.title}>
                <h1>
                  {rumc_school.title}
                </h1>

                <Helmet>
                  <title>{rumc_school.title}</title>
                </Helmet>
              </div>

              <div className={stylesStates.root}>
                <div className={stylesStates.list}>
                  <div className={classNames(stylesStates.item, stylesStates.item_link)} key={99}>
                    {`Ссылка на страницу: https://инклюзивноеобразование.рф${decodeURIComponent(path('account_rumc_school_path', { id: this.props.id }))}`}
                  </div>
                  {rumc_school.state.states.map((state, i) =>
                    <div className={stylesStates.item} key={state.id}>
                      <div className={classNames(stylesStates.current, { [stylesStates.active]: state.active })}>
                        {i + 1}
                      </div>

                      <div className={stylesStates.rest}>
                        <div>
                          {I18n.t(`state.${state.id}`)}
                        </div>

                        {state.id == 'active' &&
                          <>
                            <div className={styles.active}>
                              <p>
                                <strong>
                                  {rumc_school.rumc.title}
                                </strong>
                                <br />
                                Соглашение было создано <Datec time={rumc_school.created_at} />.
                                {rumc_school.date && rumc_school.date != rumc_school.created_at &&
                                  <>
                                    <br />
                                    Дата соглашения <Datec time={rumc_school.date} type="date" />
                                  </>
                                }

                                {rumc_school.can_edit &&
                                  <>
                                    <br />
                                    <Link to={path('edit_account_rumc_school_path', { id: rumc_school.id })}>
                                      Редактировать
                                    </Link>
                                    <br />
                                  </>
                                }

                              </p>

                              <AttachmentsList attachments={rumc_school.attachments} />

                            </div>

                            {rumc_school.can_edit &&
                              <button className={classNames(buttons.main, styles.destroy)} onClick={() => this.handleDestroy(rumc_school.id)}>
                                Удалить соглашение
                              </button>
                            }
                          </>
                        }

                        {state.active &&
                          <>
                            {state.id == 'agreed' &&
                              <div className={styles.review}>
                                <p>
                                  Соглашение проверено, согласовано и готово к публикации для расчета индекса. Для того чтобы он участвовал в формировании индекса необходимо проставить ручные характеристики объекта. Соглашения не публикуются в публичном доступе.

                                  {rumc_school.can_review &&
                                    <>
                                      <br />
                                      <Link to={path('review_account_rumc_school_path', { id: rumc_school.id })}>
                                        Поставить оценки
                                      </Link>
                                      <div className={classNames(styles.reviewed, { [styles.not]: rumc_school.review.rated_fields < rumc_school.review.fields })}>
                                        {`${rumc_school.review.rated_fields} из ${rumc_school.review.fields}`}
                                      </div>
                                    </>
                                  }
                                </p>
                              </div>
                            }

                            {state.id == 'archived' &&
                              <div className={styles.archived}>
                                <p>
                                  Соглашение находится в архиве и участвует в расчете показателей. Вы можете разархивировать чтобы изменить оценки или чтобы оно не участвовало в расчете показателей.
                                </p>
                              </div>
                            }

                            {state.events && state.events.length > 0 &&
                              <div className={styles.events}>
                                {state.events.map(event =>
                                  <div className={styles.event} key={event}>
                                    <button className={buttons.main} onClick={() => this.handleStateChange(event)}>{I18n.t(`state.events.${event}`)}</button>
                                  </div>
                                )}
                              </div>
                            }
                          </>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}

export default Show;
