import React, { Component } from 'react'
import PropTypes from 'prop-types'

// import { Link, Route, Redirect } from 'react-router-dom'
import axios from 'axios'
import qs from 'query-string'
import className from 'classnames'

import Filters from '../../Filters/Filters'
import Loaf from '../../Loaf/Loaf'
import Link, { path } from '../../Routes'
import I18n from '../../I18n'

import styles from './New.module.css'
import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'

class New extends Component {
  state = {
    schools: null,
    search: this.props.location.search
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('new_account_rumc_school_path', {}, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search) })
    this._asyncRequest = null
    console.log(res.data)

    this.setState({ ...res.data })
  }

  static getDerivedStateFromProps (props, state) {
    if (state.filters && props.location.search !== state.search) {
      return {
        schools: null,
        search: props.location.search
      }
    }

    return null
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.schools === null) {
      this._loadAsyncData()
    }
  }

  handleClick = async schoolId => {
    await axios.post(
      path('account_rumc_schools_path', {}, true),
      { rumc_school: { school_id: schoolId } }
    ).then(res => {
      if (res.headers.location) this.props.history.push(res.headers.location)
    }).catch(({ response }) => {
      this.setState({ errors: response.data })
    })
  }

  render () {
    const { schools, filters } = this.state
    const { history, location } = this.props

    // if(this.state.redirect_to) {
    //   return <Redirect to={this.state.redirect_to} />;
    // }

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">{I18n.t('account.title')}</Link>
            <Link to="account_rumc_schools_path">{I18n.t('account.rumc_schools.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Новое соглашение с вузом
            </h1>
            <p>
              Найдите вуз, с которым вы планируете заключить соглашение. Вузы, у которых уже имеются активные соглашения с другими вузами, недоступны для заключения соглашений, у таких вузов указан ресурсный центр. После того, как вы нажмете на ссылку «Заключить соглашение», вам будет доступна форма для загрузки соглашения и дорожной карты взаимодейтсвия.
            </p>
          </div>

          <div className={styles.root}>
            <div className={styles.filters}>
              {filters &&
                <Filters filters={filters} history={history} location={location} />
              }
            </div>

            {schools &&
              <div className={styles.schools}>
                {schools.map(school =>
                  <div key={school.id} className={styles.school}>
                    <div className={styles.title}>
                      {school.title_last}
                    </div>
                    <div className={styles.rumc}>
                      {school.rumc &&
                        <React.Fragment>
                          {school.rumc.title}
                        </React.Fragment>
                      }

                      {!school.rumc &&
                        <button className={className(buttons.main, buttons.small)} onClick={e => this.handleClick(school.id)}>
                          Заключить соглашение
                        </button>
                      }
                    </div>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

New.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default New
