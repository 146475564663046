import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Show from './Show'

export default function Stages () {
  return (
    <Switch>
      <Route path={Routes.stage_course_path}>
        <Show type="course" />
      </Route>

      <Route path={Routes.stage_umm_path}>
        <Show type="umm" />
      </Route>
    </Switch>
  )
}
