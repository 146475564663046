import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import classNames from 'classnames'

import { path } from '../../Routes'
import I18n from '../../I18n'

import styles from './Items.module.css'
import form from '../../Form.module.css'
import buttons from '../../Buttons.module.css'

Items.propTypes = {
  items: PropTypes.array,
  onItemsChanged: PropTypes.func
}

export default function Items (props) {
  const { onItemsChanged } = props

  const [items, setItems] = useState(new Map())

  const handleItemAdd = (e) => {
    const newItems = new Map(items)
    newItems.set(items.size + 1, { id: null, desc: '', section: null })

    setItems(newItems)

    e.preventDefault()
  }

  const handleItemDelete = async (item, id) => {
    const newItems = new Map(items)

    if (item.id) {
      await axios.delete(
        path('account_media_item_path', { id: item.id })
      ).then(() => {
        newItems.delete(id)
        setItems(newItems)
      }).catch(_error => {
        // setErrors(error.response.data)
      })
    } else {
      newItems.delete(id)
      setItems(newItems)
    }
  }

  useEffect(() => {
    if (props.items) {
      const newItems = new Map(items)
      props.items.forEach(item => {
        newItems.set(item.id, item)
      })

      setItems(newItems)
    }
  }, [props.items])

  useEffect(() => {
    onItemsChanged && onItemsChanged(items)
  }, [items])

  const handleItemChange = (item, itemKey) => {
    const newItems = new Map(items)
    newItems.set(itemKey, item)

    setItems(newItems)
  }

  return (
    <>
      <div className={form.el}>
        <label>
          <div className={form.label}>
            Ссылки на СМИ
          </div>

          {items.size > 0 &&
            <>
              {[...items.keys()].map(key =>
                <MediaItem
                  key={key}
                  item={items.get(key)}
                  itemKey={key}
                  sections={['federal', 'regional']}
                  onItemChange={handleItemChange}
                  onItemDelete={handleItemDelete}
                />
              )}
            </>
          }

          <div className={form.hint}>
            Если ваше событие освещается в СМИ, добавьте ссылки на источник. Необходимы только ссылки, без указания названия источника или другой информации. Также можно указать уровень на котором освещалось событие, региональный или федеральный.
          </div>
        </label>

        <button className={buttons.main} onClick={handleItemAdd}>Добавить ссылку на СМИ</button>
      </div>
    </>
  )
}

MediaItem.propTypes = {
  item: PropTypes.object,
  itemKey: PropTypes.number,
  sections: PropTypes.array,
  onItemChange: PropTypes.func,
  onItemDelete: PropTypes.func
}

function MediaItem ({ item, itemKey, sections, onItemChange, onItemDelete }) {
  const [mediaItem, setListItem] = useState(item)

  useEffect(() => {
    onItemChange && onItemChange(mediaItem, itemKey)
  }, [mediaItem])

  const handleInputChange = ({ target: { name, value } }) => {
    setListItem({ ...mediaItem, [name]: value })
  }

  const handleSectionChange = (section) => {
    setListItem({ ...mediaItem, section: section })
  }

  return (
    <div className={styles.item}>
      <div className={form.input}>
        <div className={styles.sections}>
          {sections.map(section =>
            <div
              key={section}
              className={classNames(styles.section, { [styles.selected]: item.section === section })}
              onClick={() => handleSectionChange(section)}
            >
              {I18n.t(`media.sections.${section}`)}
            </div>
          )}
        </div>

        <input
          type="text"
          name="desc"
          value={mediaItem.desc}
          onChange={handleInputChange}
        />
      </div>

      <div className={styles.delete} onClick={() => onItemDelete(mediaItem, mediaItem.id ? mediaItem.id : itemKey)}>
        удалить
      </div>
    </div>
  )
}
