import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Index'
import Show from './Show'

export default function News () {
    return (
        <Switch>
            <Route path={Routes.news_path}>
                <Show />
            </Route>

            <Route path={Routes.news_index_path}>
                <Index />
            </Route>
        </Switch>
    )
}
