import React from 'react'
import { Helmet } from 'react-helmet-async'
import Link from '../Routes'
import classNames from 'classnames'
import I18n from '../I18n'
import Loaf from '../Loaf/Loaf'
import GlobalVariables from '../Vars';

import page from '../Page.module.css'
import styles from "./CDN.module.css"

import SimpleImageSlider from "react-simple-image-slider";


class Cdn extends React.Component {

  constructor (props) {
    super(props)

    this.state = {
      clicked0: false,
      clicked1: false,
      clicked2: false,
      clicked3: false,
    }

    this.copyTextToClipboard = this.copyTextToClipboard.bind(this)
    this.fallbackCopyTextToClipboard = this.fallbackCopyTextToClipboard.bind(this)
  }

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }
  
  copyTextToClipboard(text, index) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
    }, function(err) {});

    switch (index) {
      case 0:
        this.setState({clicked0: true})
        setTimeout(() => {
          this.setState({clicked0: false});
        }, 5000)
        break
      case 1:
        this.setState({clicked1: true})
        setTimeout(() => {
          this.setState({clicked1: false});
        }, 5000)
        break
      case 2:
        this.setState({clicked2: true})
        setTimeout(() => {
          this.setState({clicked2: false});
        }, 5000)
        break
      case 3:
        this.setState({clicked3: true})
        setTimeout(() => {
          this.setState({clicked3: false});
        }, 5000)
        break
    }
  }

  urls = {
    "Logo_white": {
      "jpg": GlobalVariables.cdnHost + "logo/Logo_white.jpg",
      "png": GlobalVariables.cdnHost + "logo/Logo_white.png",
      "svg": GlobalVariables.cdnHost + "logo/Logo_white.svg"
    },
    "Logo_black": {
      "jpg": GlobalVariables.cdnHost + "logo/Logo_black.jpg",
      "png": GlobalVariables.cdnHost + "logo/Logo_black.png",
      "svg": GlobalVariables.cdnHost + "logo/Logo_black.svg"
    },
    "banners": {
      "main": GlobalVariables.cdnHost + "banners/main.svg",
      "half_page": GlobalVariables.cdnHost + "banners/half_page.svg",
      "vertical_rectangle": GlobalVariables.cdnHost + "banners/vertical_rectangle.svg",
      "big_rectangle": GlobalVariables.cdnHost + "banners/big_rectangle.svg"
    },
    "code": {
      "main": `<a href='${window.location.origin}' target='_blank'>\n\t<img src='${GlobalVariables.cdnHost}banners/main.svg' alt=''${window.location.origin}' width='728px' height='90px'></img>\n</a>`,
      "half_page": `<a href='${window.location.origin}' target='_blank'>\n\t<img src='${GlobalVariables.cdnHost}banners/half_page.svg' alt=''${window.location.origin}' width='300px' height='600px'></img>\n</a>`,
      "vertical_rectangle": `<a href='${window.location.origin}' target='_blank'>\n\t<img src='${GlobalVariables.cdnHost}banners/vertical_rectangle.svg' alt=''${window.location.origin}' width='240px' height='400px'></img>\n</a>`,
      "big_rectangle": `<a href='${window.location.origin}' target='_blank'>\n\t<img src='${GlobalVariables.cdnHost}banners/big_rectangle.svg' alt=''${window.location.origin}' width='336px' height='280px'></img>\n</a>`,
    }
  }

  brandbook = [ 
    { "id": "1", url:  GlobalVariables.cdnHost + "brandbook/1.png" }, 
    { "id": "2", url:  GlobalVariables.cdnHost + "brandbook/2.png" }, 
    { "id": "3", url:  GlobalVariables.cdnHost + "brandbook/3.png" }, 
    { "id": "4", url:  GlobalVariables.cdnHost + "brandbook/4.png" }, 
    { "id": "5", url:  GlobalVariables.cdnHost + "brandbook/5.png" }, 
    { "id": "6", url:  GlobalVariables.cdnHost + "brandbook/6.png" }, 
    { "id": "7", url:  GlobalVariables.cdnHost + "brandbook/7.png" }, 
    { "id": "8", url:  GlobalVariables.cdnHost + "brandbook/8.png" }, 
    { "id": "9", url:  GlobalVariables.cdnHost + "brandbook/9.png" }, 
    { "id": "10", url:  GlobalVariables.cdnHost + "brandbook/10.png" }, 
    { "id": "11", url:  GlobalVariables.cdnHost + "brandbook/11.png" }, 
    { "id": "12", url:  GlobalVariables.cdnHost + "brandbook/12.png" }, 
    { "id": "13", url:  GlobalVariables.cdnHost + "brandbook/13.png" }, 
    { "id": "14", url:  GlobalVariables.cdnHost + "brandbook/14.png" }, 
    { "id": "15", url:  GlobalVariables.cdnHost + "brandbook/15.png" }, 
    { "id": "16", url:  GlobalVariables.cdnHost + "brandbook/16.png" }, 
    { "id": "17", url:  GlobalVariables.cdnHost + "brandbook/17.png" }, 
    { "id": "18", url:  GlobalVariables.cdnHost + "brandbook/18.png" }, 
    { "id": "19", url:  GlobalVariables.cdnHost + "brandbook/19.png" }, 
    { "id": "20", url:  GlobalVariables.cdnHost + "brandbook/20.png" }, 
    { "id": "21", url:  GlobalVariables.cdnHost + "brandbook/21.png" }, 
    { "id": "22", url:  GlobalVariables.cdnHost + "brandbook/22.png" }, 
    { "id": "23", url:  GlobalVariables.cdnHost + "brandbook/23.png" }, 
    { "id": "24", url:  GlobalVariables.cdnHost + "brandbook/24.png" }, 
    { "id": "25", url:  GlobalVariables.cdnHost + "brandbook/25.png" }, 
    { "id": "26", url:  GlobalVariables.cdnHost + "brandbook/26.png" }, 
    { "id": "27", url:  GlobalVariables.cdnHost + "brandbook/27.png" }, 
    { "id": "28", url:  GlobalVariables.cdnHost + "brandbook/28.png" },
    { "id": "29", url:  GlobalVariables.cdnHost + "brandbook/29.png" }, 
    { "id": "30", url:  GlobalVariables.cdnHost + "brandbook/30.png" }, 
    { "id": "31", url:  GlobalVariables.cdnHost + "brandbook/31.png" }, 
    { "id": "32", url:  GlobalVariables.cdnHost + "brandbook/32.png" }, 
    { "id": "33", url:  GlobalVariables.cdnHost + "brandbook/33.png" }, 
    { "id": "34", url:  GlobalVariables.cdnHost + "brandbook/34.png" }, 
    { "id": "35", url:  GlobalVariables.cdnHost + "brandbook/35.png" }, 
    { "id": "36", url:  GlobalVariables.cdnHost + "brandbook/36.png" }, 
    { "id": "37", url:  GlobalVariables.cdnHost + "brandbook/37.png" }, 
    { "id": "38", url:  GlobalVariables.cdnHost + "brandbook/38.png" }, 
    { "id": "39", url:  GlobalVariables.cdnHost + "brandbook/39.png" }, 
    { "id": "40", url:  GlobalVariables.cdnHost + "brandbook/40.png" }, 
    { "id": "41", url:  GlobalVariables.cdnHost + "brandbook/41.png" }, 
    { "id": "42", url:  GlobalVariables.cdnHost + "brandbook/42.png" }, 
    { "id": "43", url:  GlobalVariables.cdnHost + "brandbook/43.png" }, 
    { "id": "44", url:  GlobalVariables.cdnHost + "brandbook/44.png" }, 
    { "id": "45", url:  GlobalVariables.cdnHost + "brandbook/45.png" }, 
    { "id": "46", url:  GlobalVariables.cdnHost + "brandbook/46.png" }, 
    { "id": "47", url:  GlobalVariables.cdnHost + "brandbook/47.png" } 
  ]

  render () {
    const { clicked0, clicked1, clicked2, clicked3 }= this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Helmet>
            <title>{I18n.t('footer.partners')}</title>
          </Helmet>

          <Loaf>
            <Link to="root_path">{I18n.t('nav.root')}</Link>
          </Loaf>

          <div className={classNames(page.title, page.tight)}>
            <h1>
              {I18n.t('footer.partners')}
            </h1>
          </div>


          <p className={styles.subtitle}>Логотип и цвета</p>

          <div className={styles.logo_block}>
            <div className={styles.logo_item} >
              <img src={this.urls.Logo_white.svg} alt='Логотип на белом фоне'></img>
              
              <div className={styles.select_type}>
                <a href={this.urls.Logo_white.jpg} target='_blank'>jpg</a>
                <div>|</div>
                <a href={this.urls.Logo_white.png} target='_blank'>png</a>
                <div>|</div>
                <a href={this.urls.Logo_white.svg} target='_blank'>svg</a>
              </div>
            </div>

            <div className={styles.logo_item}>
              <img src={this.urls.Logo_black.svg} alt='Логотип на черном фоне'></img>
              
              <div className={styles.type_items}>
                <div className={styles.select_type}>
                  <a href={this.urls.Logo_black.jpg} target='_blank'>jpg</a>
                  <div>|</div>
                  <a href={this.urls.Logo_black.png} target='_blank'>png</a>
                  <div>|</div>
                  <a href={this.urls.Logo_black.svg} target='_blank'>svg</a>
                </div>
              </div>

            </div>
          </div>
                
          <div className={styles.colors}>
            <div id="FCDA2D">#FCDA2D</div>
            <div id="BBBDBC">#BBBDBC</div>
            <div id="131819">#131819</div>
          </div>

          <p className={styles.subtitle}>Баннеры</p>

          <div className={styles.banners_title}>
            <div>Горизонтальный</div>
            <div>728 х 90</div>
          </div>
          

          <div className={styles.main_banner}>
            <img src={this.urls.banners.main} alt='Горизонтальный 728 х 90'></img>
          </div>

          <div className={styles.main_banner_copy_container}>
            
            <textarea className={styles.main_banner_copy_url} wrap='off' spellCheck="false" defaultValue={this.urls.code.main} />

            <div className={styles.main_banner_copy_btn} onClick={() => this.copyTextToClipboard(this.urls.code.main, 0)}>
              {clicked0 ? 'Скопировано' : 'Копировать'} 
            </div>
          </div>

          <div className={styles.banners_block}>
            <div className={styles.banners_item}>
              <div className={styles.banners_title}>
                <div><b>Вертикальный</b></div>
                <div><b>300 х 600</b></div>
              </div>
              <div className={classNames(styles.first_b, styles.image_block)}>
                <img width="300px" height="600px" src={this.urls.banners.half_page} alt='Вертикальный 300 х 600'></img>
              </div>
              <textarea className={styles.url_block} wrap='off' spellCheck="false" defaultValue={this.urls.code.half_page} />
              <div className={styles.url_copy} onClick={() => this.copyTextToClipboard(this.urls.code.half_page, 1)}>
                {clicked1 ? 'Скопировано' : 'Копировать'}
              </div>
            </div>
            {/* ---------------------------------------------------------------------------------------------------------------------- */}
            <div className={styles.banners_item}>
              <div className={styles.banners_title}>
                <div><b>Вертикальный</b></div>
                <div><b>240 х 400</b></div>
              </div>

              <div className={classNames(styles.second_b, styles.image_block)}>
                <img width="240px" height="400px" src={this.urls.banners.vertical_rectangle} alt='Вертикальный 240 х 400'></img>
              </div>

              <textarea className={classNames(styles.url_block, styles.second_u)} wrap='off' spellCheck="false" defaultValue={this.urls.code.vertical_rectangle} />

              <div className={classNames(styles.url_copy, styles.url_copy_second)} onClick={() => this.copyTextToClipboard(this.urls.code.vertical_rectangle, 2)}>
                {clicked2 ? 'Скопировано' : 'Копировать'}
              </div>
            </div>
            {/* ---------------------------------------------------------------------------------------------------------------------- */}
            <div className={styles.banners_item}>
              <div className={styles.banners_title}>
                <div><b>Прямоугольный</b></div>
                <div><b>336 х 280</b></div>
              </div>

              <div className={classNames(styles.third_b, styles.image_block)}>
                <img width="336px" height="280px" src={this.urls.banners.big_rectangle} alt='Прямоугольный 336 х 280'></img>
              </div>

              <textarea className={styles.url_block} wrap='off' spellCheck="false" defaultValue={this.urls.code.big_rectangle} />
              
              <div className={styles.url_copy} onClick={() => this.copyTextToClipboard(this.urls.code.big_rectangle, 3)}>
                {clicked3 ? 'Скопировано' : 'Копировать'}
              </div>
            </div>
          </div>

            
          <p className={styles.subtitle}>Официальный брендбук сети РУМЦ по обучению инвалидов и лиц с ограниченными возможностями здоровья в вузе</p>
          
          <div className='wraptest'>
            <div className={styles.brandbook}>
              <SimpleImageSlider
                width={862}
                height={635}
                images={this.brandbook}
                showBullets={false}
                showNavs={true}
                navStyle={2}
                navSize={30}
                autoPlay={true}
                autoPlayDelay={5}
              />
            </div>
          </div>

          <a href={`${GlobalVariables.cdnHost}brandbook/brandbook.zip`}>
            <div className={styles.save_brandbook}>
              Скачать брендбук
            </div>
          </a>

          <a href={`${GlobalVariables.cdnHost}fonts/fonts.zip`}>
            <div className={styles.save_brandbook}>
              Скачать шрифты
            </div>
          </a>

        </div>
      </div>
    )
  }
}

export default Cdn