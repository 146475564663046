import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'
import Add from '../Stages/Add'
import Redraft from '../../Redraft'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
import styles from './Show.module.css'

export default function Show () {
  const { id } = useParams()
  const cancelToken = useRef(axios.CancelToken.source())

  const [theme, setTheme] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('account_plan_theme_path', { id }, true), { cancelToken: cancelToken.current.token })

      setTheme(data.plan_theme)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
          {/* <Link to="account_plans_path">{I18n.t('account.plans.title')}</Link> */}
          {theme &&
            <Link to="account_plan_path" params={{ id: theme.plan.id }}>{theme.plan.title}</Link>
          }
        </Loaf>

        {theme &&
          <>
            <div className={page.title}>
              <h1>
                {theme.title}
              </h1>

              <Helmet>
                <title>{theme.title}</title>
              </Helmet>

              {theme.can_edit &&
                <div className={page.links}>
                  <Link className={buttons.main} to="edit_account_plan_theme_path" params={{ id: theme.id }}>Редактировать</Link>
                </div>
              }
            </div>

            <div className={page.common}>
              {theme.text &&
                <div>
                  <Redraft src={theme.text} />
                </div>
              }
            </div>

            <div>
              <div className={styles.stages}>
                {theme.stages.map(stage =>
                  <div key={stage.id} className={styles.stage}>
                    <Link to="account_stage_path" params={{ id: stage.id }}>
                      {stage.title}
                    </Link>
                  </div>
                )}
              </div>

              <Add stageable_type="PlanTheme" stageable_id={theme.id} />
            </div>
          </>
        }
      </div>
    </div>
  )
}
