import React, { Component } from 'react'
import classNames from 'classnames';
import styles from './Pagination.module.css'
import forward_arrow from '../../../images/icons/forward_arrow_white.svg';
import back_arrow from '../../../images/icons/bc_white.svg';


class Pagination extends Component {
    state = {
        perPage: 5,
        activePage: 1,
        pages: [...Array(this.props.count_pages).keys()].map((i) => i + 1),
    }

    filterPages = () => {
        let active_page = this.state.activePage;

        let min = active_page - 3;
        let max = active_page + 2;
        if (window.innerWidth <= 480) {
            min = active_page - 2;
            max = active_page + 1;
        }
        let filtered = [...this.state.pages];

        if (min < 0) {
            min = 0;
            max = 5;
            if (window.innerWidth <= 480) {
                max = 3;
            }
        }

        if (max >= filtered.length) {
            min = filtered.length - 5;
            if (window.innerWidth <= 320) {
                min = filtered.length - 3;
            }
            max = filtered.length;
        }
        return filtered.filter((v, i) => {
            if (i >= min && i < max) return v;
        });
    };
    

    setActivePage = (e, page) => {
        this.setState({activePage: page});
        if (this.props.events) {
            this.props.togglePage(this.props.date, false, page);
        }
        else if (this.props.archive) {
            this.props.togglePage(page);
        }
    };

    componentWillReceiveProps (newProps) {
        this.setState({ pages: [...Array(newProps.count_pages).keys()].map((i) => i + 1)})
    }

    componentDidMount () {
        this.setState({ pages: [...Array(this.props.count_pages).keys()].map((i) => i + 1)})
    }
  
    render () {
        const { activePage } = this.state;

        return (
            <div className={styles.pagination}>
                <div
                    className={classNames(styles.first_last_page, styles.first_last_page_first)}
                    title="В начало"
                    onClick={(e) => {
                        activePage != 1 ? this.setActivePage(e, 1) : '';
                    }}
                >
                    <img src={back_arrow} />
                    <img src={back_arrow} />
                </div>

                <div
                    className={styles.back_forward}
                    onClick={(e) => {
                        activePage != 1 ? this.setActivePage(e, activePage - 1) : '';
                    }}
                >
                    <div className={styles.back_arrow} />
                    <span className={activePage == 1 ? styles.disabled : styles.enabled} id="back">Назад</span>
                </div>
                {this.filterPages().map((p, i) => (
                    <div
                        key={i}
                        className={activePage == p ? classNames(styles.pagination_page, styles.pagination_active) : styles.pagination_page}
                        id={p}
                        onClick={(e) => {
                        this.setActivePage(e, p);
                        }}
                    >
                        {p}
                    </div>
                ))}
                <div
                    className={styles.back_forward}
                    onClick={(e) => {
                        activePage != this.state.pages[this.state.pages.length - 1] ? this.setActivePage(e, activePage + 1) : '';
                    }}
                >
                    <span className={activePage == this.state.pages[this.state.pages.length - 1] ? styles.disabled : styles.enabled} id="forward">
                        Вперед
                    </span>
                    <div className={styles.forward_arrow}></div>
                </div>
                <div
                    className={styles.first_last_page}
                    title="В конец"
                    onClick={(e) => {
                        activePage != this.state.pages[this.state.pages.length - 1] ? this.setActivePage(e, this.state.pages[this.state.pages.length - 1]) : '';
                    }}
                >
                    <img src={forward_arrow} />
                    <img src={forward_arrow} />
                </div>
            </div>
        )
    }
}

export default Pagination;