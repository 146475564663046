import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import Cookies from 'universal-cookie'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import qs from 'query-string'

import Link, { path } from '../Routes'
import Loaf from '../Loaf/Loaf'

import Stringg from './Show/Stringg'
import Text from './Show/Text'
import Checkboxes from './Show/Checkboxes'
import References from './Show/References'
import Radio from './Show/Radio'
import NoRumc from './Show/NoRumc'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import forms from '../Form.module.css'
import styles from './Show.module.css'

export default function Show () {
  const query = new URLSearchParams(useLocation().search)
  const { id } = useParams()
  const history = useHistory()
  const cookies = new Cookies()
  const cancelToken = useRef(axios.CancelToken.source())

  const rumcId = query.get('rumc_id')
  const cookieRumcId = cookies.get('form_rumc_id')

  const [form, setForm] = useState()
  const [elements, setElements] = useState()
  const [dictionaries, setDictionaries] = useState()

  if (!rumcId && !cookieRumcId) return <NoRumc />

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('form_path', { id }, true))

      setForm(data.form)
      setDictionaries(data.dictionaries)
      setElements(data.form_elements)
    }

    if (!rumcId) {
      if (cookieRumcId) {
        history.push(path('form_path', { id, query: { rumc_id: cookieRumcId } }))
      } else {

      }
    } else {
      cookies.set('form_rumc_id', rumcId, { path: '/', expires: new Date(Date.now() + 2592000) })
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const dtRef = useRef(cookies.get(`form-${id}`))

  const [dt, setDt] = useState(dtRef.current || { q: 0, a: {} })

  useEffect(() => {
    if (dt.a[dt.q] === undefined) {
      setDt({
        ...dt,
        a: {
          ...dt.a,
          [dt.q]: null
        }
      })
    }
  }, [dt.q])

  useEffect(() => {
    if (dtRef.current !== dt) {
      dtRef.current = dt
      cookies.set(`form-${id}`, dt)
    }
  }, [dt])

  const handleQuestionChange = (q, value) => {
    setDt({
      ...dt,
      a: {
        ...dt.a,
        [q]: value
      }
    })
  }

  const handleNextClick = () => {
    setDt({
      ...dt,
      q: dt.q + 1
    })

    window.scrollTo(0, 0)
  }

  const handleSkipClick = () => {
    setDt({
      ...dt,
      q: dt.q + 1
    })

    window.scrollTo(0, 0)
  }

  // const isFinish = () => dt.q === elements.length

  const isLast = () => dt.q + 1 === elements.length

  const [send, setSend] = useState(false)
  const [errors, setErrors] = useState({})

  const handleFinish = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    await axios.post(
      path('form_form_results_path', { form_id: id }, true),
      {
        form_result: {
          rumc_id: rumcId,
          form_elements_results_attributes: Object.entries(dt.a).map(o => {
            return {
              value: o[1],
              form_element_id: elements[o[0]].id
            }
          })
        }
      },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      cookies.remove(`form-${id}`)
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  return (
    <div className={page.root}>
      <div className={page.container}>
        <Loaf>
          <Link to="forms_path">Все анкеты</Link>
        </Loaf>

        {form && elements && dictionaries &&
          <div className={page.narrow}>
            <div className={page.title}>
              <h1>{form.title}</h1>

              <Helmet>
                <title>
                  {form.title}
                </title>
              </Helmet>

              {form.desc && dt.q === 0 &&
                <ReactMarkdown source={form.desc} className={styles.intro} />
              }

              {form.rumc && dt.q === 0 &&
                <div className={styles.ref}>
                  <p>
                    Анкеты могут заполнять как сами респонденты, так и сотрудники ресурсных центров с их слов. Чтобы система смогла потом определить что анкета относится к вашему ресурсному центру — нужно передавать респондентам ссылку:
                    <a href={`https://инклюзивноеобразование.рф/forms/${form.id}?rumc_id=${form.rumc.id}`} target="_blank" rel="noopener noreferrer">
                      https://инклюзивноеобразование.рф/forms/{form.id}?rumc_id={form.rumc.id}
                    </a>
                  </p>

                  <p>
                    Проверьте наличие идентификатора rumc_id=XXX, где XXX уникальный номер для каждого РУМЦ.
                  </p>
                </div>
              }

              {form.rumc &&
                <div className={styles.results}>
                  <p>
                    <a className={styles.file} href={`${(path('stats_form_path', { id: 3, form_id: form.id }))}.xlsx?${qs.stringify({ fields: elements.map(e => e.id), rumcs: form.rumc.id }, { arrayFormat: 'bracket' })}` }>Получить результаты анкет в Excel</a>
                  </p>
                </div>
              }
            </div>

            <p>
              Вопрос № {dt.q + 1} из {elements.length}
            </p>

            {dt.a[dt.q] !== undefined &&
              <>
                <Question
                  dt={dt}
                  elements={elements}
                  dictionaries={dictionaries}
                  onQuestionChange={handleQuestionChange}
                />

                <div className={styles.buttons}>
                  {isLast() &&
                    <div>
                      {errors.rumc &&
                        <p className={forms.error}>
                          Не определён РУМЦ для анкеты! Обратитесь к тому, от кого вы получили ссылку на анкету.
                        </p>
                      }

                      <button
                        className={classNames(buttons.main, { [buttons.loading]: send })}
                        disabled={(!dt.a[dt.q] && elements[dt.q].required) || send}
                        onClick={handleFinish}
                      >
                        Завершить заполнение
                      </button>
                    </div>
                  }

                  {!isLast() &&
                    <div>
                      {(dt.a[dt.q] || elements[dt.q].required) &&
                        <button disabled={!dt.a[dt.q]} onClick={handleNextClick} className={buttons.main}>
                          Следующий вопрос
                        </button>
                      }

                      {!dt.a[dt.q] && !elements[dt.q].required &&
                        <button onClick={handleSkipClick} className={buttons.main}>
                          Пропустить вопрос
                        </button>
                      }
                    </div>
                  }
                </div>
              </>
            }
          </div>
        }
      </div>
    </div>
  )
}

Question.propTypes = {
  dt: PropTypes.object,
  elements: PropTypes.array,
  dictionaries: PropTypes.object,
  onQuestionChange: PropTypes.func
}

function Question ({ dt, elements, dictionaries, onQuestionChange }) {
  const element = elements[dt.q]

  const prevValueRef = useRef(dt.a[dt.q])
  const [value, setValue] = useState(prevValueRef.current)

  const handleValueChange = newValue => {
    setValue(newValue)
  }

  useEffect(() => {
    if (value !== prevValueRef.current) {
      prevValueRef.current = value
      onQuestionChange && onQuestionChange(dt.q, value)
    }
  }, [value])

  const mapping = {
    string: Stringg,
    text: Text,
    radio: Radio,
    checkbox: Checkboxes,
    references: References
  }

  const Element = mapping[element.type]

  return (
    <div>
      <h3>
        {element.title}
      </h3>

      <Element
        element={element}
        dictionaries={dictionaries}
        value={value || (element.type === 'checkbox' ? [] : '')}
        onValueChange={handleValueChange}
      />

      <div>

      </div>
    </div>
  )
}
