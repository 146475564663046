import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Routes } from '../Routes.js.erb';
import Loaf from '../Loaf/Loaf';

import page from '../Page.module.css';

class TermsRumcExperts extends React.Component {
  render() {
    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={Routes.terms_path}>Правила</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Создание и публикация професии
            </h1>

            <Helmet>
              <title>
                Создание и публикация професии
              </title>
            </Helmet>
          </div>

          <div className={page.text}>
            <p>
              1. Для создания профессии необходимо зайти в личный кабинет и найти блок №7 «Атлас профессий». По клику на ссылку создания открывается форма добавление профессии, которая содержит необходимые поля: название, аннотацию, нозологию, направления подготовки и ссылку на профстандарт, остальные поля будут доступны для заполнения после утверждения профессии. После нажатия на кнопку «Сохранить профессию» она уходит на утверждение. Список ваших профессий можно посмотреть по ссылке «Ваши профессии».
            </p>
            <p>
              2. После создания профессии она находится в статусе «Новая» и ожидает подтверждения модератором. От сотрудников ресурсных центров никаких действий не требуется. После утверждения или отклонения автор получает уведомление об этом на почту. В случае отклонения будет указана причина отказа и вы сможете внести правки в форму редактирования профессии и повторно отправить на утверждения.
            </p>

            <p>
              3. После усешного утверждения профессии она переходит в статус «Активная», это означает что от сотрудников центров требуется заполнить недостающие поля и загрузить необходимые файлы. К каждой профессии можно загрузить любое количество файлов, но для медиа-паспорта нужно поставить метку чтобы система смогла его идентифицировать.
            </p>

            <p>
              4. Когда все поля и файлы будут заполнены — у вас появится возможность перевести профессию в статус «Согласование». На этом этапе вам будут назначены два анонимных эксперта, которые должны будут подготовить свою рецензию и заклюение на вашу профессию.
            </p>

            <p>
              5. В случае положительных заключений экспертов — вы сможете перевести профессию в статус «Готова». На этом этапе её снова проверяет модератор. В случае необходимых правок он сообщит об этом, в случае их отсутствия — он отправит профессию в статус «Опубликована».
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsRumcExperts;
