import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../../Routes'

import Index from './Index'
import Show from './Show'
import Form from './Form'
import Roles from './Roles'

class Rumcs extends Component {
  render () {
    return (
      <Switch>
        <Route
          path={decodeURIComponent(Routes.edit_account_rumc_path)}
          render={props => <Form id={props.match.params.id} /> }
        />

        <Route path={Routes.account_rumc_rumc_users_path}>
          <Roles />
        </Route>

        <Route
          path={decodeURIComponent(Routes.account_rumc_path)}
          render={props => <Show id={props.match.params.id} target={props.location.hash.slice(1)} /> }
        />

        <Route
          path={decodeURIComponent(Routes.account_rumcs_path)}
          render={props => <Index target={props.location.hash.slice(1)} /> }
        />
      </Switch>
    )
  }
}

export default Rumcs
