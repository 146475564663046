import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import form from './Form.module.css'

Errors.propTypes = {
  errors: PropTypes.array
}

export function Errors ({ errors }) {
  if (!errors) return null

  return (
    <div className={form.error}>
      <ul>
        {errors.map((error, _) => <li key={_}>{error}</li>)}
      </ul>
    </div>
  )
}

export function useForm (initValues) {
  const cancelToken = useRef(axios.CancelToken.source())

  const prevSendRef = useRef(false)
  const prevErrorsRef = useRef({})

  const [values, setValues] = useState(initValues)

  const [send, setSend] = useState(prevSendRef.current)
  const [errors, setErrors] = useState(prevErrorsRef.current)
  const [success, setSuccess] = useState()

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value })
  }

  // useEffect(() => {
  //   if (prevSendRef.current !== send) {
  //     // NOT WORKING "{} !== {}"
  //     if (send && errors !== {}) {
  //       setErrors({})
  //     }
  //
  //     prevSendRef.current = send
  //   }
  // }, [send])
  //
  // useEffect(() => {
  //   if (prevErrorsRef.current !== errors) {
  //     if (send) {
  //       setSend(false)
  //     }
  //     prevErrorsRef.current = errors
  //   }
  // }, [errors])

  return {
    send,
    errors,
    setSend,
    setErrors,
    cancelToken,
    success,
    setSuccess,
    values,
    setValues,
    handleInputChange
  }
}
