import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { path } from '../../Routes';
import Loaf from '../../Loaf/Loaf';

import List from '../List/List';

import page from '../../Page.module.css';

class Show extends React.Component {
  state = {
    form: null
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('stats_form_path', { id: this.props.match.params.id, form_id: this.props.match.params.form_id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          form: res.data.form
        });

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  render() {
    const id = this.props.match.params.id;
    const { form } = this.state;
    if(!form) return null;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('stats_path', { id: 5 })}>Весь отчёт</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Анкета
            </h1>

            <Helmet>
              <title>
                Анкета
              </title>
            </Helmet>
          </div>

          <div>
            <List {...this.props} url={path('stats_form_path', { id: this.props.match.params.id, form_id: this.props.match.params.form_id }, true)} fields={form.fields}  />
          </div>
        </div>
      </div>
    );
  }
}

export default Show;
