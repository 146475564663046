import React from 'react'
import PropTypes from 'prop-types'

import styles from './Audiences.module.css'

Audiences.propTypes = {
  regions: PropTypes.array,
  registrations: PropTypes.array
}

export default function Audiences ({ regions, registrations }) {
  return (
    <div className={styles.root}>
      <h3>
        Регионы участников
      </h3>

      <p className={styles.desc}>
        Количество участников по регионам.
      </p>

      {regions.filter(region => registrations.filter(r => r.region.id === region.id).length > 0).map(region =>
        <div key={region.id} className={styles.row}>
          <div className={styles.values}>
            <div className={styles.main}>
              {registrations.filter(r => r.region.id === region.id).length}
            </div>
          </div>

          <div className={styles.label}>
            <div>
              {region.label}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
