import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import qs from 'query-string';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import ReactMarkdown from 'react-markdown';

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import Datec from '../../Datec';
import { path } from '../../Routes';
import AttachmentsList from '../../Attachments/AttachmentsList';
import Rate from '../../Rate/Rate';
import StagesList from '../../Stages/StagesList';

import Comments from '../../Comments/Comments';

import page from '../../Page.module.css';
import buttons from '../../Buttons.module.css';
import styles from './Show.module.css';
import stylesStates from '../States.module.css';

class Show extends Component {
  state = {
    umm: null,
    rate: null,
    stages: null,
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('account_umm_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { umm, rate, stages } = res.data;

        this.setState({ umm, rate, stages });
        this._asyncRequest = null;
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.umm === null) this._loadAsyncData(this.props.id);
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleStateChange = (event) => {
    this._asyncRequest = axios.CancelToken.source();

    axios({
      method: 'post',
      cancelToken: this._asyncRequest.token,
      url: path(`${event}_account_umm_path`, { id: this.props.id }, true),
    }).then(
      res => {
        this.setState({
          umm: null,
        });
      }
    );
  }

  handleRateSave = () => {
    this.setState({
      umm: null
    });
  }

  render() {
    const { umm, rate, stages } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={`${path('account_umms_path')}?${qs.stringify(cookies.get('filters', { path: path('account_umms_path') }))}`}>{I18n.t('umms.title')}</Link>
          </Loaf>

          {umm &&
            <>
              <div className={page.title}>
                <h1>
                  {umm.title}
                </h1>

                <Helmet>
                  <title>{umm.title}</title>
                </Helmet>
              </div>

              <div className={stylesStates.root}>
                <div className={stylesStates.list}>
                  <div className={classNames(stylesStates.item, stylesStates.item_link)} key={99}>
                    {`Ссылка на страницу: https://инклюзивноеобразование.рф${decodeURIComponent(path('account_umms_path', { id: this.props.id }))}`}
                  </div>
                  {umm.state.states.map((state, i) =>
                    <div className={classNames(stylesStates.item, { [stylesStates.show]: state.show })} key={state.id}>
                      <div className={classNames(stylesStates.current, {[stylesStates.passed]: state.passed}, { [stylesStates.active]: state.active }, { [stylesStates.published]: state.id == 'published' && state.active})}>
                        {i + 1}
                      </div>

                      <div className={stylesStates.rest}>
                        <div>
                          {I18n.t(`state.${state.id}`)}
                        </div>

                        {state.id == 'inactive' &&
                          <div className={styles.inactive}>
                            <p>
                              <strong>
                                {umm.rumc.title}
                              </strong>
                              <br />
                              Материал создан <Datec time={umm.created_at} />.
                              {umm.can_edit &&
                                <>
                                  <br/>
                                  <Link to={path('edit_account_umm_path', {id: umm.id })}>Редактировать</Link>
                                  <br/>
                                  <Link to={path('stages_account_umm_path', {id: umm.id })}>Редактировать разделы</Link>
                                </>
                              }
                            </p>

                            <ReactMarkdown source={umm.desc} />
                          </div>
                        }

                        {state.id == 'active' &&
                          <div className={styles.active}>
                            <AttachmentsList attachments={umm.attachments} />
                          </div>
                        }

                        {state.id == 'agreement' &&
                          <div className={styles.agreement}>
                            {umm.confirmations &&
                              <div className={stylesStates.confirmations}>
                                {umm.confirmations.map(confirmation =>
                                  <div key={confirmation.id} className={classNames(stylesStates.confirmation, { [stylesStates.confirmed]: confirmation.state })}>
                                    {confirmation.confirmator} ({confirmation.rumc.title}) от 
                                    <Datec time={confirmation.created_at} />
                                  </div>
                                )}
                              </div>
                            }

                            {rate &&
                              <Rate rate={rate} ratable={{ type: "Umm", id: umm.id}} button="Сохранить и утвердить" onRateSave={this.handleRateSave} />
                            }

                            {rate && rate.not_rated &&
                              <Rate rate={rate} ratable={{ type: "Umm", id: umm.id}} not_rated={rate.not_rated} button="Сохранить и утвердить" onRateSave={this.handleRateSave} />
                            }

                          </div>
                        }


                        {state.active &&
                          <>
                            {state.id == 'done' &&
                              <div className={styles.done}>
                                <p>
                                  Материал готов, проверен экспертами РУМЦ и проверяется экспертами перед публикацией.

                                  {umm.can_review &&
                                    <>
                                      <br />
                                      <Link to={path('review_account_umm_path', { id: umm.id })}>
                                        Поставить оценки
                                      </Link>
                                      <div className={classNames(styles.reviewed, { [styles.not]: umm.review.rated_fields < umm.review.fields })}>
                                        {`${umm.review.rated_fields} из ${umm.review.fields}`}
                                      </div>
                                    </>
                                  }
                                </p>
                              </div>
                            }


                            {state.id == 'agreed' &&
                              <div className={styles.review}>
                                <p>
                                  Материал проверен, согласован и готов к публикации. Для того чтобы он участвовал в формировании индекса необходимо проставить ручные характеристики объекта. После публикации оно будет доступно всем посетителям сайта.

                                  {umm.can_review &&
                                    <>
                                      <br />
                                      <Link to={path('review_account_umm_path', { id: umm.id })}>
                                        Поставить оценки
                                      </Link>
                                      <div className={classNames(styles.reviewed, { [styles.not]: umm.review.rated_fields < umm.review.fields })}>
                                        {`${umm.review.rated_fields} из ${umm.review.fields}`}
                                      </div>
                                    </>
                                  }
                                </p>
                              </div>
                            }

                            {state.id == 'archived' &&
                              <div className={styles.archived}>
                                <p>
                                  Соглашение находится в архиве и участвует в расчете показателей. Вы можете разархивировать чтобы изменить оценки или чтобы оно не участвовало в расчете показателей.
                                </p>
                              </div>
                            }

                            {state.events && state.events.length > 0 &&
                              <div className={styles.events}>
                                {state.events.map(event =>
                                  <div className={styles.event} key={event}>
                                    <button className={buttons.main} onClick={() => this.handleStateChange(event)}>{I18n.t(`state.events.${event}`)}</button>
                                  </div>
                                )}
                              </div>
                            }
                          </>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={page.text}>
                <div className={styles.stages}>
                  <StagesList stages={stages} location={this.props.location} />
                </div>
              </div>

              <Comments object="Umm" id={this.props.id} />
            </>
          }
        </div>
      </div>
    );
  }
}

export default Show;
