import React from 'react'
import PropTypes from 'prop-types'

import RequestNewBlock from './RequestNewBlock'

Covid.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
}

export default function Covid ({ title, text }) {
  return (
    <RequestNewBlock
      text="Работают «горячие линии» сети РУМЦ по информационно-методическому сопровождению студентов с инвалидностью, их родителей (законных представителей) и работников вузов  в условиях предупреждения распространения новой коронавирусной инфекции"
      title="Горячая линия по вопросам обучения лиц с инвалидностью"
    />
  )
}
