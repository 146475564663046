import React from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import { path } from '../../Routes'

import I18n from '../../I18n'

import styles from './Common.module.css'

class Experts extends React.Component {
  state = {
    experts: null
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  _loadAsyncData () {
    axios.get(path('tomorrow_experts_path', {}, true))
      .then(res => {
        this.setState(
          res.data
        )

        this._asyncRequest = null
      })
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  render () {
    const { experts, expert } = this.state
    const nominations = {
      1: 'Эссе «Почему я ценный и полезный работник: 10 аргументов»',
      2: 'Профессиональный старт-ап молодежи',
      3: 'Исследовательская работа',
      4: 'Мое полезное изобретение',
      5: 'Социальная реклама',
    }

    return (
      <div className={styles.root}>
        <Helmet>
          <title>{I18n.t('events.tomorrow.experts')}</title>
        </Helmet>
        <h2>
          {I18n.t('events.tomorrow.experts')}
        </h2>

        {/* {expert &&
          <>
            <h3>
              Критерии оценки
            </h3>

            <div className={styles.file}>
              <a href="/uploads/tomorrow/esse.docx">Эссе «Почему я ценный и полезный работник: 10 аргументов»</a>

              <a href="/uploads/tomorrow/professianal_startup.docx">Профессиональный старт-ап молодежи</a>

              <a href="/uploads/tomorrow/research.docx">Исследовательская работа</a>

              <a href="/uploads/tomorrow/useful_invention.docx">Мое полезное изобретение</a>

              <a href="/uploads/tomorrow/social_commercial.docx">Социальная реклама</a>
            </div>

            <h3>
              Экспертные карты
            </h3>

            <div className={styles.file}>
              <a href="/uploads/tomorrow/eo_esse.docx">Эссе «Почему я ценный и полезный работник: 10 аргументов»</a>

              <a href="/uploads/tomorrow/eo_professional_startup.docx">Профессиональный старт-ап молодежи</a>

              <a href="/uploads/tomorrow/eo_research.docx">Исследовательская работа</a>

              <a href="/uploads/tomorrow/eo_usefull_invention.docx">Мое полезное изобретение</a>

              <a href="/uploads/tomorrow/eo_social_commercial.docx">Социальная реклама</a>
            </div>
          </>
        } */}
        <h3>В 2024 году список экспертов (на <a href="https://profzavtra.nstu.ru/experts/" target="_blank"><u>сайте</u></a> оператора Конкурса)</h3>

        <h3>В 2023 году список экспертов (на <a href="https://rumc.ncfu.ru/eksperty/" target="_blank"><u>сайте</u></a> оператора Конкурса)</h3>

        <h3>В 2022 году список экспертов (на <a href="https://www.utmn.ru/professional-tomorrow/experts/" target="_blank"><u>сайте</u></a> оператора Конкурса)</h3>
        <h3>В 2021 году список экспертов (на <a href="https://rumc.cfuv.ru/node/165" target="_blank"><u>сайте</u></a> оператора Конкурса):</h3>
        {/* <a href="https://rumc.cfuv.ru/node/165" target="_blank">https://rumc.cfuv.ru/node/165</a> */}
        <strong>Номинация «Исследовательская работа»:</strong>
        <ul>
          <li>Вячеславова Екатерина Васильевна</li>
          <li>Камашева Светлана Романовна</li>
          <li>Силина Елена Константиновна</li>
          <li>Мозговая Татьяна Павлова</li>
          <li>Старовойт Наталья Васильевна</li>
          <li>Губина Светлана Тельмановна</li>
          <li>Романенкова Дарья Феликсовна</li>
          <li>Быкова Светлана Станиславовна</li>
          <li>Колокольникова Мария Валерьевна</li>
          <li>Кукуев Евгений Анатольевич</li>
          <li>Музыка Оксана Анатольевна</li>
          <li>Джафар-Заде Джамиля Авсаф кызы</li>
          <li>Каштанова Светлана Николаевна</li>
          <li>Гришаева Светлана Алексеевна</li>
          <li>Моцовкина Елена Владимировна</li>
          <li>Веричева Ольга Николаевна</li>
          <li>Морозов Владимир Борисович</li>
          <li>Саяпина Ирина Юрьевна</li>
          <li>Сафонова Татьяна Витальевна</li>
          <li>Хрупина Ксения Сергеевна</li>
        </ul>
        <strong>Номинация «Социальная реклама»:</strong>
        <ul>
          <li>Бендер Анастасия Павловна</li>
          <li>Смирнова Марина Николаевна</li>
          <li>Канева Светлана Александровна</li>
          <li>Романович Наталья Анатольевна</li>
          <li>Волченкова Елена Валерьевна</li>
          <li>Калашникова Виктория Александровна</li>
          <li>Дмитракова Василиса Васильевна</li>
          <li>Родина Мария Александровна</li>
          <li>Селюкова Милена Арменовна</li>
          <li>Карпушкина Наталья Викторовна</li>
          <li>Митрофанова Елена Александровна</li>
          <li>Андрусева Ирина Владимировна</li>
          <li>Саитгалиева Гузель Газимовна</li>
          <li>Лентовский Александр Иванович</li>
          <li>Лебедева Симона Эрнестовна</li>
          <li>Соломенникова Ирина  Павловна</li>
        </ul>
        <strong>Номинация «Старт-ап»:</strong>
        <ul>
          <li>Казанкин Владимир Валерьевич</li>
          <li>Добромиль Янина Юрьевна</li>
          <li>Жданова Инна Валерьевна</li>
          <li>Гудина Татьяна Викторовна</li>
          <li>Овчинников Михаил Владимирович</li>
          <li>Городилова Светлана Александровна</li>
          <li>Демиденко Оксана Петровна</li>
          <li>Бузолина Анастасия Николаевна</li>
          <li>Чемерисова Наталья Васильевна</li>
          <li>Кудрявцев Владимир Александрович</li>
          <li>Ольхина Елена Александровна</li>
          <li>Осокин Михаил Геннадьевич</li>
          <li>Елизарьева Лариса Вениаминовна</li>
          <li>Нестеренко Юлия Николаевна</li>
          <li>Алексеенко Анна Михайловна</li>
        </ul>
        <strong>Номинация «Мое полезное изобретение»:</strong>
        <ul>
          <li>Береговская Татьяна Александровна</li>
          <li>Богинская Юлия Валериевна</li>
          <li>Скляренко Алена Владимировна</li>
          <li>Глухова Ольга Айваровна</li>
          <li>Хмелькова Елена Вячеславовна</li>
          <li>Эм Елена Александровна</li>
          <li>Постников Сергей Юрьевич</li>
          <li>Феофанов Василий Николаевич</li>
          <li>Медведева Елена Юрьевна</li>
          <li>Лукьянова Елена Юрьевна</li>
          <li>Лапп Елена Александровна</li>
          <li>Потапчук Алла Аскольдовна</li>
          <li>Пчелинова Вера Владимировна</li>
          <li>Герасимов Анатолий Васильевич</li>
          <li>Авдеев Александр Васильевич</li>
        </ul>
        <h3>В 2020 году список экспертов (на <a target="_blank" href="https://guu.ru/об-университете/доступная-среда-в-гуу/деятельность-румц-2020/конкурс-профессиональное-завтра/эксперты/"><u>сайте</u></a> оператора Конкурса):</h3>
        {/* <a target="_blank" href="https://guu.ru/об-университете/доступная-среда-в-гуу/деятельность-румц-2020/конкурс-профессиональное-завтра/эксперты/">https://guu.ru/об-университете/доступная-среда-в-гуу/деятельность-румц-2020/конкурс-профессиональное-завтра/эксперты/</a> */}
        <strong>Эссе «Почему я ценный и полезный работник: 10 аргументов»</strong>
        <ul>
          <li>Лукьянова Елена Юрьевна</li>
          <li>Жданова Инна Валерьевна</li>
          <li>Филимонова Елена Анатольевна</li>
          <li>Клыпутенко Виктория Владимировна</li>
          <li>Лапп Елена Александровна</li>
          <li>Канева Светлана Александровна</li>
          <li>Старовойт Наталья Васильевна</li>
          <li>Карпушкина Наталья Викторовна</li>
          <li>Феофанов Василий Николаевич</li>
          <li>Шмелева Надежда Александровна</li>
          <li>Грабчук Ксения Михайловна</li>
          <li>Камашева Светлана Романовна</li>
          <li>Беляева Ольга Леонидовна</li>
          <li>Речицкая Екатерина Григорьевна</li>
          <li>Дианкова Анастасия Сергеевна</li>
          <li>Волченкова Елена Валерьевна</li>
          <li>Митрофанова Елена Александровна</li>
          <li>Вячеславова Екатерина Васильевна</li>
        </ul>
        <strong>Профессиональный старт-ап молодежи</strong>
        <ul>
          <li>Ниязова Амина Абтрахмановна</li>
          <li>Демиденко Оксана Петровна</li>
          <li>Ольхина Елена Александровна</li>
          <li>Овчинников Михаил Владимирович</li>
          <li>Добромиль Янина Юрьевна</li>
          <li>Смирнова Елена Станиславовна</li>
          <li>Веселов Геннадий Евгеньевич</li>
          <li>Бородатая Марина Николаевна</li>
          <li>Лаас Наталия Ивановна</li>
          <li>Осокин Михаил Геннадьевич</li>
          <li>Казанкин Владимир Валерьевич</li>
        </ul>
        <strong>Исследовательская работа</strong>
        <ul>
          <li>Глузман Юлия Валериевна</li>
          <li>Щербаков Денис Викторович</li>
          <li>Мозговая Татьяна Павловна</li>
          <li>Кукуев Евгений Анатольевич</li>
          <li>Линкер Гульнара Ринатовна</li>
          <li>Малёнова Евгения Дмитриевна</li>
          <li>Роготнева Елена Николаевна</li>
          <li>Моцовкина Елена Владимировна</li>
          <li>Гудина Татьяна Викторовна</li>
          <li>Каштанова Светлана Николаевна</li>
          <li>Кудрявцев Владимир Александрович</li>
          <li>Музыка Оксана Анатольевна</li>
          <li>Холина Оксана Александровна</li>
          <li>Чебарыкова Светлана Васильевна</li>
          <li>Джафар-заде Дарья Авсафовна</li>
          <li>Морозов Владимир Борисович</li>
          <li>Степанова Татьяна Анатольевна</li>
        </ul>
        <strong>Мое полезное изобретение</strong>
        <ul>
          <li>Некрасова Ольга Александровна</li>
          <li>Шостак Марина Анатольевна</li>
          <li>Дежнева Галина Ивановна</li>
          <li>Калашникова Виктория Александровна</li>
          <li>Медведева Елена Юрьевна</li>
          <li>Постников Сергей Юрьевич</li>
          <li>Вахрамеев Павел Сергеевич</li>
          <li>Нагибина Ольга Валерьевна</li>
          <li>Береговская Татьяна Александровна</li>
          <li>Веричева Ольга Николаевна</li>
        </ul>
        <strong>Социальная реклама</strong>
        <ul>
          <li>Бендер Анастасия Павловна</li>
          <li>Чащина Анна Александровна</li>
          <li>Парунина Любовь Владимировна</li>
          <li>Чуйкова Ирина Владимировна</li>
          <li>Андрусёва Ирина Владимировна</li>
          <li>Балымов Илья Леонидович</li>
          <li>Усова Зоя Михайловна</li>
          <li>Петренко Марина Сергеевна</li>
          <li>Эм Елена Александровна</li>
          <li>Романович Наталья Анатольевна</li>
          <li>Еремкина Яна Алексеевна</li>
          <li>Родина Мария Александровна</li>
          <li>Смирнова Марина Николаевна</li>
          <li>Гришаева Светлана Алексеевна</li>
          <li>Чемерисова Наталья Васильевна</li>
        </ul>



        <h3>В 2019 году список экспертов:</h3>
        {experts &&
          <div className={styles.experts}>
            {[1, 2, 3, 4, 5].map(i =>
              <div key={i} className={styles.nomination}>
                <strong>
                  {nominations[i]}
                </strong>
                <br />
                <ul>
                  {experts.filter(e => e.nomination === i).map(expert =>
                    <li key={expert.id} className={styles.expert}>
                      {expert.name}
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        }
      </div>
    );
  }
}

export default Experts
