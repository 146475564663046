import React from 'react'
import PropTypes from 'prop-types'

import form from '../../Form.module.css'

Text.propTypes = {
  element: PropTypes.object,
  value: PropTypes.string,
  onValueChange: PropTypes.func
}

export default function Text ({ element, value, onValueChange }) {
  const handleChange = ({ target: { value } }) => {
    onValueChange && onValueChange(value)
  }

  if (value === undefined) return null

  return (
    <div className={form.input}>
      <textarea onChange={handleChange} value={value} />
    </div>
  )
}
