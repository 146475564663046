import React from 'react'
// import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../../Routes'

import Index from './Index'
import Form from './Form'
import Show from './Show'
// import FormResults from './FormResults'

export default function FormResults () {
  return (
    <Switch>
      <Route path={Routes.form_form_results_path} exact>
        <Index />
      </Route>

      <Route path={Routes.form_form_result_path} exact>
        <Show />
      </Route>

      <Route path={Routes.edit_form_form_result_path} exact>
        <Form />
      </Route>
    </Switch>
  )
}
