const GlobalVariables = Object.freeze({
  cdnHost: 'https://cdn.inc-edu.ru/',
  apitestHost: 'https://apitest.inc-edu.ru',
  newBackendHost: 'http://10.111.0.147:3000'
  // apitestHost: 'http://10.111.0.147:5050'
});

// local
// export const newBackendHost = 'http://10.111.0.147:3000'

// prod
export const newBackendHost = 'https://app.xn--80aabdcpejeebhqo2afglbd3b9w.xn--p1ai'

export default GlobalVariables
