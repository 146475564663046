import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';

import VerificateInput from './VerificateInput';

import styles from '../Section.module.css';
import form from '../../Form.module.css';

class Employment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      values: [
        'ofc',
        'prg',
        'py',
        'pyw',
        'pyd',
        'pydw',
        'pydwc',
        'pydws',
        'pydc',
      ].reduce((acc, e) => {
        acc[e] = (this.props.values[this.props.prefix] && this.props.values[this.props.prefix][e]) || '';
        return acc;
      }, {}),
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      [name]: {
        $set: value
      }
    });

    this.setState({ values });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.onValuesChange) this.props.onValuesChange(this.props.prefix, this.state.values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state != nextState);
  }

  render () {
    const { values, show } = this.state;
    const { num, monitoring } = this.props;

    const errors = {
      'err_py': ~~parseInt(values.py) < ~~parseInt(values.pyw),
      'err_pyd': ~~parseInt(values.pyd) < ~~parseInt(values.pydw) || ~~parseInt(values.pyd) < ~~parseInt(values.pydwc) || ~~parseInt(values.pyd) < ~~parseInt(values.pydws) || ~~parseInt(values.pyd) < ~~parseInt(values.pydc),
    };

    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Трудоустройство выпускников с ОВЗ и инвалидностью

          <div className={classNames(styles.label, { [styles.ok]: Object.values(values).filter(v => v).length == Object.values(values).length })}>
            {Object.values(values).filter(v => v).length} из {Object.values(values).length}
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.desc}>
            Указывать данные за предыдущий ({monitoring.period_year.join('/')}) учебный год.
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="ofc" checked={values.ofc} onChange={this.handleInputChange} />
                {num}.1. В отделе по трудоустройству студентов ведется работа с лицами с ОВЗ и инвалидностью
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="prg" checked={values.prg} onChange={this.handleInputChange} />
                {num}.2. Утверждена программа(ы) содействия трудоустройству выпускников с ОВЗ и инвалидностью
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3. Численность выпускников вуза предыдущего учебного года
              {errors['err_py'] &&
                <div className={form.error}>Ошибка в данных о численности трудоустроившихся</div>
              }
            </div>

            <div className={form.input}>
              <VerificateInput name="py" value={values.py} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4. Численность выпускников вуза предыдущего учебного года, трудоустроившихся в течение календарного года
            </div>

            <div className={form.input}>
              <VerificateInput name="pyw" value={values.pyw} onChange={this.handleInputChange} />
            </div>

            {values.py && values.pyw && parseInt(values.py) > 0 &&
              <div className={styles.count}>
                Удельный вес выпускников вуза, трудоустроившихся в течение календарного года, следующего за годом выпуска, в общем числе выпускников предыдущего года: {(parseInt(values.pyw) / parseInt(values.py) * 100).toFixed(2)}%
              </div>
            }
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.5. Численность выпускников с ОВЗ и инвалидностью предыдущего учебного года
              {errors['err_pyd'] &&
                <div className={form.error}>Ошибка в данных о численности трудоустроившихся</div>
              }
            </div>

            <div className={form.input}>
              <VerificateInput name="pyd" value={values.pyd} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.6. Численность выпускников с ОВЗ и инвалидностью предыдущего учебного года, трудоустроившихся в течение календарного года
            </div>

            <div className={form.input}>
              <VerificateInput name="pydw" value={values.pydw} onChange={this.handleInputChange} />
            </div>

            {values.pyd && values.pydw && parseInt(values.pyd) > 0 &&
              <div className={styles.count}>
                Удельный вес выпускников с ОВЗ и инвалидностью, трудоустроившихся в течение календарного года, следующего за годом выпуска, в общем числе выпускников предыдущего года с ОВЗ и инвалидностью: {(parseInt(values.pydw) / parseInt(values.pyd) * 100).toFixed(2)}%
              </div>
            }
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.7. Численность выпускников с ОВЗ и инвалидностью предыдущего учебного года, трудоустроившихся по специальности в течение календарного года
            </div>

            <div className={form.input}>
              <VerificateInput name="pydwc" value={values.pydwc} onChange={this.handleInputChange} />
            </div>

            {values.pyd && values.pydwc && parseInt(values.pyd) > 0 &&
              <div className={styles.count}>
                Удельный вес выпускников с ОВЗ и инвалидностью, трудоустроившихся по специальности в течение календарного года, следующего за годом выпуска, в общем числе выпускников предыдущего года с ОВЗ и инвалидностью: {(parseInt(values.pydwc) / parseInt(values.pyd) * 100).toFixed(2)}%
              </div>
            }
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.8. Численность выпускников с ОВЗ и инвалидностью трудоустроившихся по специальности студентов во время обучения в вузе
            </div>

            <div className={form.input}>
              <VerificateInput name="pydws" value={values.pydws} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.9. Численность выпускников с ОВЗ и инвалидностью предыдущего учебного года, продолживших обучение на следующем уровне образования
            </div>

            <div className={form.input}>
              <VerificateInput name="pydc" value={values.pydc} onChange={this.handleInputChange} />
            </div>

            {values.pyd && values.pydc && parseInt(values.pyd) > 0 &&
              <div className={styles.count}>
                Удельный вес выпускников с ОВЗ и инвалидностью, продолживших обучение на следующем уровне образования, в общем численности выпускников предыдущего года с ОВЗ и инвалидностью: {(parseInt(values.pydc) / parseInt(values.pyd) * 100).toFixed(2)}%
              </div>
            }
          </div>

        </div>
      </div>
    );
  }
}

Employment.defaultProps = {
  prefix: 'employment',
  num: '10'
};

export default Employment;
