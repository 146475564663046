import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from './Routes'
import I18n from './I18n'

import styles from './Directory.module.css'
import Star from '!svg-react-loader?!../images/icons/star.svg'

class Directory extends React.Component {
  state = {
    input: '',
    focus: false
  }

  focus = () => {
    this.setState({ focus: true })
  }

  blur = () => {
    this.setState({ focus: false })
  }

  render () {
    const { input, focus } = this.state
    const { type } = this.props
    let { items } = this.props

    if (input !== '') {
      items = items.filter(item => item.search.toLowerCase().includes(input))
    }

    if (type === 'umm' && items) {
      items = items.sort((a, b) => b.rate - a.rate)
    }

    return (
      <div className={styles.root}>
        <div className={styles.directory}>
          <div className={classNames(styles.search, { [styles.focus]: focus })}>
            <input onChange={(e) => this.setState({ input: e.target.value.toLowerCase() })} onFocus={this.focus} onBlur={this.blur} value={input} />
          </div>

          {items && items.length > 0 &&
            <div className={styles.items}>
              {items.map(item =>
                <Link to={`${type}_path`} params={{ id: item.id }} key={item.id} className={classNames(styles.item, styles.a_in_block)}>
                  <div className={styles.data}>
                    {item.state && item.state !== 'published' &&
                      <div className={styles.state}>
                        Скрыто
                      </div>
                    }

                    <div className={classNames(styles.title, { [styles[`nosologies-${item.nosologies ? item.nosologies.length : 'no'}`]]: item.nosologies })}>
                      {item.title}

                      {item.nosologies && item.nosologies.length > 0 &&
                        <div className={styles.nosologies}>
                          {item.nosologies.map(nosology =>
                            <div key={nosology} className={styles[nosology]} />
                          )}
                        </div>
                      }
                    </div>

                    <div className={styles.desc}>
                      {item.desc}
                    </div>
                  </div>

                  {type === 'career' &&
                    <div className={styles.meta}>
                      {!!item.youtube_duration &&
                        <div className={styles.duration}>
                          {(item.youtube_duration.minutes || 0)}:{(item.youtube_duration.seconds || 0).toString().padStart(2, '0')} мин
                        </div>
                      }

                      {!!item.schools_count &&
                        <div className={styles.schools_count}>
                          {I18n.t('career.schools.count', { count: item.schools_count })}
                        </div>
                      }

                      {item.rate &&
                        <div className={styles.rate}>
                          <Star />
                          {item.rate}
                        </div>
                      }
                    </div>
                  }

                  {(type === 'course' || type === 'umm') &&
                    <div className={styles.meta}>
                      {!!item.volume &&
                        <div className={styles.volume}>
                          {I18n.t(`${type}.volume`, { count: item.volume.replace(/\D+/, '') })}
                        </div>
                      }

                      {!!item.stages &&
                        <div className={styles.stages}>
                          {I18n.t(`${type}.stages`, { count: item.stages })}
                        </div>
                      }

                      {item.rate &&
                        <div className={styles.rate}>
                          <Star />
                          {item.rate}
                        </div>
                      }
                    </div>
                  }

                  <div className={styles.more}>
                    Подробнее
                  </div>
                </Link>
              )}
            </div>
          }

          {items && items.length === 0 && input === '' &&
            <div className={styles.none}>
              К сожалению, на текущий момент все материалы проходят модерацию и поэтому недоступны.
            </div>
          }

          {items && items.length === 0 && input !== '' &&
            <div className={styles.none}>
              К сожалению, по такому запросу ничего не найдено. Попробуйте ввести только часть, поиск ищет по любому вхождению части слов.
            </div>
          }
        </div>
      </div>
    )
  }
}

Directory.propTypes = {
  type: PropTypes.string,
  items: PropTypes.array
}

export default Directory
