import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Radio.module.css'

Radio.propTypes = {
  element: PropTypes.object,
  dictionaries: PropTypes.object,
  value: PropTypes.string,
  onValueChange: PropTypes.func
}

export default function Radio ({ element, dictionaries, value, onValueChange }) {
  const handleChange = ({ target: { value } }) => {
    onValueChange && onValueChange(value)
  }

  const options = element.settings === 'Nosology' ? dictionaries.nosologies : JSON.parse(element.settings)

  const isInline = () => {
    return [
      189,
      190,
      191,
      192,
      193
    ].includes(element.id)
  }

  return (
    <ul className={classNames(styles.radios, { [styles.inline]: isInline() })}>
      {options.map(option =>
        <li key={option.id} className={classNames(styles.radio, { [styles.selected]: false })}>
          <label>
            <input
              checked={option.id.toString() === value}
              type="radio"
              name={`form-elem-${element.id}`}
              value={option.id}
              onChange={handleChange}
            />
            {option.title}
          </label>
        </li>
      )}
    </ul>
  )
}
