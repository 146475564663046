import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'query-string';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import classNames from 'classnames';
import update from 'immutability-helper';

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import Datec from '../../Datec';
import { path } from '../../Routes';
import AttachmentsList from '../../Attachments/AttachmentsList';

import States from '../States';

import page from '../../Page.module.css';
import buttons from '../../Buttons.module.css';
import styles from './Show.module.css';
import stylesStates from '../States.module.css';

class Show extends Component {
  state = {
    event: null,
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('account_event_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { event } = res.data;

        this.setState({ event });
        this._asyncRequest = null;
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.event === null) this._loadAsyncData(this.props.id);
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleShow = (state) => {
    if (this.state.event.state.states[state].passed) {
      const event = update(this.state.event, {
        state: {
          states: {
            [state]: {
              show: {
                $set: !this.state.event.state.states[state].show
              }
            }
          }
        }
      });

      this.setState(prevState => ({
        event: event
      }));
    }
  }

  handleStateChange = (event) => {
    this._asyncRequest = axios.CancelToken.source();

    axios({
      method: 'post',
      cancelToken: this._asyncRequest.token,
      url: path(`${event}_account_event_path`, { id: this.props.id }, true),
    }).then(
      res => {
        this.setState({
          event: null,
        });
      }
    );
  }

  render() {
    const { event } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={`${path('account_events_path')}?${qs.stringify(cookies.get('filters', { path: path('account_events_path') }))}`}>{I18n.t('events.title')}</Link>
          </Loaf>

          {event &&
            <>
              <div className={page.title}>
                <h1>
                  {event.title}
                </h1>

                <Helmet>
                  <title>{event.title}</title>
                </Helmet>
              </div>

              <div className={stylesStates.root}>
                <div className={stylesStates.list}>
                  <div className={classNames(stylesStates.item, stylesStates.item_link)} key={99}>
                    {`Ссылка на страницу: https://инклюзивноеобразование.рф${decodeURIComponent(path('account_event_path', { id: this.props.id }))}`}
                  </div>
                  {event.state.states.map((state, i) =>
                    <div className={classNames(stylesStates.item, { [stylesStates.show]: state.show })} key={state.id}>
                      <div className={classNames(stylesStates.current, {[stylesStates.passed]: state.passed}, { [stylesStates.active]: state.active }, { [stylesStates.published]: state.id == 'published' && state.active})}>
                        {i + 1}
                      </div>

                      <div className={stylesStates.rest}>
                        <div className={stylesStates.title} onClick={() => this.handleShow(i)}>
                          {I18n.t(`state.${state.id}`)}
                        </div>

                        {state.show &&
                          <>
                            {state.id == 'active' &&
                              <div className={styles.active}>
                                <p>
                                  {event.rumc &&
                                    <>
                                      <strong>
                                        {event.rumc.title}
                                      </strong>
                                      <br />
                                    </>
                                  }
                                  Событие было создано <Datec time={event.created_at} />.
                                  <br />
                                  <Link target="_blank" to={path('event_path', { id: event.id })}>Посмотреть событие</Link>
                                  <br />
                                  {event.can_edit &&
                                    <>
                                      <br/>
                                      <Link to={path('edit_account_event_path', {id: event.id })}>Редактировать</Link>

                                      {event.has_report ? (
                                        <>
                                          <br/>
                                          <Link target="_blank" to={path('event_event_report_path', { event_id: event.id })}>Посмотреть отчет</Link>
                                        </>
                                      ) : (
                                        <>
                                          <br/>
                                          <Link to={path('new_event_event_report_path', { event_id: event.id })}>Добавить отчёт</Link>
                                        </>
                                      )}

                                      <br/>
                                      <Link to={path('event_event_registrations_path', { event_id: event.id })}>Список участников</Link>
                                    </>
                                  }

                                  {!event.can_edit && event.role && event.role.assignable_type == "Rumc" && event.role.assignable_id != event.rumc.id &&
                                    <Link to={path('event_event_registrations_path', {event_id: event.id})} params={{ query: { limited: true } }} >Список участников события, связанных с вашим РУМЦ</Link>
                                  }
                                </p>

                                <AttachmentsList attachments={event.attachments} />
                              </div>
                            }

                            {state.active &&
                              <>
                                {state.id == 'active' &&
                                  <div className={styles.review}>
                                    <p>
                                      Событие готово к публикации для расчета индекса. Для того чтобы оно участвовало в формировании индекса необходимо проставить ручные характеристики объекта.

                                      {event.can_review &&
                                        <>
                                          <br />
                                          <Link to={path('review_account_event_path', { id: event.id })}>
                                            Поставить оценки
                                          </Link>
                                          <div className={classNames(styles.reviewed, { [styles.not]: event.review.rated_fields < event.review.fields })}>
                                            {`${event.review.rated_fields} из ${event.review.fields}`}
                                          </div>
                                        </>
                                      }
                                    </p>
                                  </div>
                                }

                                {state.id == 'archived' &&
                                  <div className={styles.archived}>
                                    <p>
                                      Событие находится в архиве и участвует в расчете показателей. Вы можете разархивировать чтобы изменить оценки или чтобы он не участвовал в расчете показателей.
                                    </p>
                                  </div>
                                }

                                {state.events && state.events.length > 0 &&
                                  <div className={styles.events}>
                                    {state.events.map(event =>
                                      <div className={styles.event} key={event}>
                                        <button className={buttons.main} onClick={() => this.handleStateChange(event)}>{I18n.t(`state.events.${event}`)}</button>
                                      </div>
                                    )}
                                  </div>
                                }
                              </>
                            }
                          </>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}

export default Show;
