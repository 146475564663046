import React from 'react'
import PropTypes from 'prop-types'

Type.propTypes = {
  type: PropTypes.object,
  facilities: PropTypes.array
}

export default function Type ({ type, facilities }) {
  return (
    <div>
      <h2>
        {type.title}
      </h2>

      {facilities.length > 0 &&
        <div></div>
      }

      {facilities.length < 1 &&
        <div></div>
      }
    </div>
  )
}
