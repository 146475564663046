import React from 'react'
import classNames from 'classnames'

import Current from '../Current'

import Link from '../Routes'
import I18n from '../I18n'

import Vision from './Vision'

import Logo from '!svg-react-loader?!../../images/logo.svg'
import Arrow from '!svg-react-loader?!../../images/icons/login_arrow.svg'
import styles from './Header.module.css'

class Header extends React.Component {
  state = {
    open: false
  }

  handleClick = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }))
  }

  handleClose = (e) => {
    if (e.target.tagName === 'A') {
      this.setState({
        open: false
      })
    }
  }

  render () {
    const { user } = this.context

    return (
      <>
        <Vision />

        <header className={classNames(styles.root, { [styles.open]: this.state.open })}>
          <div aria-hidden="true" className={styles.logo}>
            <Link rel="home" aria-label={I18n.t('meta.logo')} to="root_path">
              <Logo className={I18n.locale} />
            </Link>
          </div>

          <div aria-hidden="true" className={styles.menu} onClick={this.handleClick}>
            <div className={styles.burger}></div>
            Меню
          </div>

          <nav aria-label="Основное меню" className={styles.nav} onClick={e => this.handleClose(e)}>
            <ul itemScope={true} itemType="http://www.schema.org/SiteNavigationElement">
              <li className={styles.main}>
                <Link to="root_path" activeClassName={styles.active} exact accessKey={1}>
                  {I18n.t('nav.home')}
                </Link>
              </li>

              <li>
                <Link to="abiturs_path" activeClassName={styles.active} accessKey={2}>
                  {I18n.t('nav.abiturs')}
                </Link>
              </li>

              <li>
                <Link to="students_path" activeClassName={styles.active} accessKey={3}>
                  {I18n.t('nav.students')}
                </Link>
              </li>

              <li>
                <Link to="universities_path" activeClassName={styles.active} accessKey={4}>
                  {I18n.t('nav.universities')}
                </Link>
              </li>

              <li>
                <Link to="rumcs_path" activeClassName={styles.active} accessKey={5}>
                  {I18n.t('nav.rumcs')}
                </Link>
              </li>

              {I18n.locale === 'ru' &&
                <li>
                  <Link to="projects_path" activeClassName={styles.active} accessKey={6}>
                    {I18n.t('nav.projects')}
                  </Link>
                </li>
              }

              {I18n.locale === 'ru' &&
                <li>
                  <Link to="events_path" activeClassName={styles.active} accessKey={6}>
                    {I18n.t('nav.events')}
                  </Link>
                </li>
              }

              {I18n.locale === 'ru' &&
                <li>
                  {user &&
                    <Link to="account_path" className={styles.account} activeClassName={styles.active} accessKey={7}>
                      {I18n.t('nav.account')}
                      <Arrow />
                    </Link>
                  }

                  {!user &&
                    <Link to="login_path" className={styles.login} activeClassName={styles.active} accessKey={7}>
                      {I18n.t('nav.login')}
                      <Arrow />
                    </Link>
                  }

                </li>
              }
            </ul>
          </nav>
        </header>
      </>
    )
  }
}

Header.contextType = Current

export default Header
