import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from '../../Routes';

import Index from './Index';
import Show from './Show';
import Form from './Form';

class Inquiries extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={decodeURIComponent(Routes.new_account_inquiry_path)} component={Form} />
          <Route exact path={decodeURIComponent(Routes.edit_account_inquiry_path)} render={props => <Form id={props.match.params.id} {...props} />} />
          <Route path={decodeURIComponent(Routes.account_inquiry_path)} render={props => <Show id={props.match.params.id} location={props.location} {...props} />} />
          <Route exact path={decodeURIComponent(Routes.account_inquiries_path)} component={Index}/>
        </Switch>
      </React.Fragment>
    );
  }
}

export default Inquiries;
