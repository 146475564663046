import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { path } from '../Routes'

import buttons from '../Buttons.module.css'

class Form extends React.Component {
  state = {
    values: {
      body: ''
    },
    comments: null
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.onCommentsUpdate) {
      if (this.state.comments !== prevState.comments) {
        this.props.onCommentsUpdate(this.state.comments)
      }
    }
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }))
  }

  handleSubmit = (e) => {
    if (this.canSubmit()) {
      axios.post(path('comments_path', {}, true), { comment: this.state.values, object: this.props.object, id: this.props.id }).then(
        res => {
          this.setState(prevState => ({
            values: {
              body: ''
            },
            comments: res.data.comments
          }))
        }
      )
    }

    e.preventDefault()
  }

  canSubmit = () => this.state.values.body

  render () {
    return (
      <div className="comments_form">
        <form className="form" onSubmit={this.handleSubmit}>
          <div className="input">
            <div className="input_label">
              <label>
                Добавить комментарий
              </label>
            </div>

            <div className="input_input">
              <textarea name="body" value={this.state.values.body} rows="9" onChange={this.handleInputChange} />
            </div>
          </div>

          <div className="input_submit">
            <input type="submit" value="Добавить" className={buttons.main} disabled={!this.canSubmit()} />
          </div>
        </form>
      </div>
    )
  }
}

export default Form
