import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'

import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
// import styles from './Index.module.css'

export default function Show () {
  const { id } = useParams()
  const cancelToken = useRef(axios.CancelToken.source())

  const [facility, setFacility] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('account_facility_path', { id }, true), { cancelToken: cancelToken.current.token })

      setFacility(data.facility)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
          <Link to="account_facilities_path">{I18n.t('account.facilities.title')}</Link>
        </Loaf>

        {facility &&
          <>
            <div className={page.title}>
              <h1>
                {facility.title}
              </h1>

              <Helmet>
                <title>{facility.title}</title>
              </Helmet>

              {facility.can_edit &&
                <div className={page.links}>
                  <Link className={buttons.main} to="edit_account_facility_path" params={{ id: facility.id }}>Редактировать</Link>
                </div>
              }
            </div>

            <div className={page.common}>
              <div>
                <strong>
                  Спецификация
                </strong>
                <ReactMarkdown
                  source={facility.specification}
                />
              </div>

              <div>
                <strong>
                  Количество
                </strong>: {facility.quantity}
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}
