import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import Current from '../Current'

import I18n from '../I18n'
import Link, { path } from '../Routes'

import Block from './Block'

import styles from './Index.module.css'
import page from '../Page.module.css'

export default function Index () {
  const history = useHistory()
  const { user } = useContext(Current)
  const cancelToken = axios.CancelToken.source()

  const [blocks, setBlocks] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('account_path', {}, true), { cancelToken: cancelToken.token })

      setBlocks(data.blocks)
    }

    _fetch()

    return function cleanup () {
      cancelToken.cancel()
    }
  }, [])

  const logoutHandle = async e => {
    e.preventDefault()
    const res = await axios.post(e.target.getAttribute('href'))
    res.headers.location && history.push(res.headers.location)
  }

  if (!user) return null

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <div className={styles.root}>
          <div className={classNames(page.title, styles.title)}>
            <h1>
              {I18n.t('account.title')}
            </h1>

            <Helmet>
              <title>
                {I18n.t('account.title')}
              </title>
            </Helmet>
          </div>

          <div className={styles.user}>
            <h2>
              {user.name}
            </h2>

            <p>
              В личном кабинете вы можете настроить параметры своей учетной записи. В зависимости от вашей роли — блоки личного кабинета могут отличаться.
            </p>

            <p className={page.links}>
              <Link to="settings_path">Настройки</Link>
              <Link to="password_path">Сменить пароль</Link>
              <a href={path('logout_path')} onClick={logoutHandle}>Выйти</a>
            </p>
          </div>

          {blocks &&
            <div className={styles.blocks}>
              {Object.values(blocks).map((block, _) =>
                <Block block={block} key={_} />
              )}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
