import React from 'react'
import PropTypes from 'prop-types'

import Link from '../../../Routes'
// import I18n from '../../../I18n'

import styles from './Types.module.css'

Types.propTypes = {
  types: PropTypes.array,
  rumcId: PropTypes.string
}

export default function Types ({ types, rumcId }) {
  const query = {}
  if (rumcId) query.rumc_id = rumcId

  return (
    <div className={styles.types}>
      {types.map(type =>
        <div key={type.id} className={styles.type}>
          <div className={styles.title}>
            {type.title}
          </div>

          <div className={styles.desc}>
            <Link to="account_facilities_path" params={{ query: { ...query, facility_type_id: type.id } }}>
              Найти всё оборудование этого типа
            </Link>
            {/* {type.facilities === 0 ? 'Нет оборудования этого типа' : I18n.t('account.facility.type.facilities', { count: type.facilities })} */}
          </div>

          <div className={styles.edit}>
            <Link to="edit_account_facility_type_path" params={{ id: type.id }}>
              Редактировать или добавить видеоинструкцию
            </Link>
          </div>

          {!type.has_video &&
            <div className={styles.noVideo}>
              Нет видео
            </div>
          }
        </div>
      )}

      <Link to="account_facilities_path" params={{ query: { ...query, facility_type_id: null } }} className={styles.type}>
        <div className={styles.title}>
          Тип не выбран
        </div>

        <div className={styles.desc}>
          Найти всё оборудование этого типа
        </div>
      </Link>
    </div>
  )
}
