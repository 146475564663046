import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Index'

export default function Students () {
  return (
    <Switch>
      <Route path={Routes.students_path}>
        <Index />
      </Route>
    </Switch>
  )
}
