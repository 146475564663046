import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



ChartJS.defaults.color = "#000";
ChartJS.defaults.borderColor = "#fff";


// ChartJS.defaults.layout.padding.right = 10
// console.log(ChartJS.defaults.plugins.legend)
ChartJS.defaults.plugins.legend.labels.boxWidth = 30
ChartJS.defaults.plugins.legend.labels.padding = 15

// ChartJS.Legend.prototype.afterFit = function() {
//     this.height = this.width + 500;
// };

function normalise(array) {
    var res = Array(array.length);
    for (let i = 0; i < res.length; i++) {
        res[i] = Array();
    }
    
    for (let i = 0; i < array[0].length; i++) {
        var tmp = []
        for (let j = 0; j < array.length; j++) {
            tmp.push(array[j][i])
        }
        let max = Math.max.apply(null, tmp);
        let min = Math.min.apply(null, tmp);
        for (let j = 0; j < array.length; j++) {
            res[j].push((tmp[j]-min)/(max-min))
        }
    }
    return res;
}

const LineChart = (props) => {
    var options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            autopadding: false,
            padding: {
                // right: 100
            }
        },
        plugins: {
            legend: {
                // position: 'right',
                position: 'bottom',
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 14,
                        family: 'Fira Sans',
                        weight: 400,
                        lineHeight: 1.4
                    }
                }
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
        scales: {
            y: {
                min: 0,
                max: 300,
                ticks: {
                    font: {
                        size: 0,
                        weight: 500,
                        family: 'Fira Sans',
                        fontColor: '#fff'
                    },
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 17,
                        weight: 500,
                        family: 'Fira Sans',
                        fontColor: '#000000'
                    },
                },
                grid: {
                    drawBorder: false,
                },
            }
        }
    };
    // const labels = props.data.map((d) => String(d.year));

    // убираем года, по которым нет значений
    const labels = props.data.map((d) => {
        let count = 0;
        for (let i of Array.from(Array(22).keys())) {
            if (d[`col_${i + 1}`]) count++;
        }
        if (count > 0) return String(d.year)
    }).filter(i => i != undefined);

    if (props.number == 1) {
        var main_array = props.data.map((d) => d.col_1);

        var dataset = [
            main_array, 
            props.data.map((d) => d.col_2), 
            props.data.map((d) => d.col_3),
            props.data.map((d) => d.col_4),
            props.data.map((d) => d.col_5),
            props.data.map((d) => d.col_6),
            props.data.map((d) => d.col_7),
            props.data.map((d) => d.col_8)
        ]
        // var norm_dataset = normalise(dataset);
        // dataset = normalise(dataset);

        options.scales = {
            y: {
                min: Math.min.apply(null, dataset[0]) - Math.min.apply(null, dataset[0]) * 0.5,
                max: Math.max.apply(null, dataset[0]) + Math.max.apply(null, dataset[0]) * 0.01,
                display: false,
                position: 'left',
                ticks: {
                    font: {
                        size: 10,
                        weight: 500,
                        family: 'Fira Sans',
                        fontColor: '#fff'
                    },
                }
            },
            y1 : {
                min: Math.min.apply(null, dataset[1]) - Math.min.apply(null, dataset[1]) * 0.5,
                max: Math.max.apply(null, dataset[1]) + Math.max.apply(null, dataset[1]) * 0.5,
                display: false,
                position: 'right',
                ticks: {
                    font: {
                        size: 10,
                        weight: 500,
                        family: 'Fira Sans',
                        fontColor: '#fff'
                    },
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 17,
                        weight: 500,
                        family: 'Fira Sans',
                        fontColor: '#000000'
                    },
                },
                grid: {
                    drawBorder: false,
                },
            }
        }

        var data = {
            labels,
            datasets: [
                // общая численность (желтый)
                {
                    label: 'общая численность обучающихся в вузе',
                    yAxisID: 'y',
                    data: dataset[0],
                    borderColor: '#FFDD09',
                    backgroundColor: '#FFDD09',
                },
                {
                    label: 'численность обучающихся инвалидов и лиц с ОВЗ',
                    yAxisID: 'y1',
                    data: dataset[1],
                    borderColor: '#FFDD09',
                    backgroundColor: '#FFDD09',
                    borderDash: [8, 2]
                },
                // численность принятых на обучение (синий)
                // {
                //     label: 'общая численность принятых на обучение',
                //     yAxisID: 'y',
                //     data: dataset[2],
                //     borderColor: '#9FD2FD',
                //     backgroundColor: '#9FD2FD',
                // },
                // {
                //     label: 'численность принятых на обучение инвалидов и лиц с ОВЗ',
                //     yAxisID: 'y1',
                //     data: dataset[3],
                //     borderColor: '#9FD2FD',
                //     backgroundColor: '#9FD2FD',
                //     borderDash: [8, 2]
                // },
                // численность выпускников (черный)
                // {
                //     label: 'общая численность выпускников',
                //     yAxisID: 'y-axis-0',
                //     data: dataset[4],
                //     borderColor: '#212121',
                //     backgroundColor: '#212121',
                // },
                // {
                //     label: 'численность выпускников-инвалидов',
                //     yAxisID: 'y-axis-1',
                //     data: dataset[5],
                //     borderColor: '#212121',
                //     backgroundColor: '#212121',
                //     borderDash: [8, 2]
                // },
                // трудоустроившихся (серый #bcbec0)
                // {
                //     label: 'численность выпускников-инвалидов трудоустроившихся в течение календарного года',
                //     yAxisID: 'y-axis-1',
                //     data: dataset[6],
                //     borderColor: '#bcbec0',
                //     backgroundColor: '#bcbec0',
                // },
                // {
                //     label: 'численность выпускников-инвалидов трудоустроившихся по специальности в течение календарного года',
                //     yAxisID: 'y-axis-1',
                //     data: dataset[7],
                //     borderColor: '#bcbec0',
                //     backgroundColor: '#bcbec0',
                //     borderDash: [8, 2]
                // },
            ],
        };
    } else if (props.number == 2) {
        var main_array = props.data.map((d) => d.col_9);
        options.scales.y.max = Math.max.apply(null, main_array) + Math.max.apply(null, main_array) * 0.01;
        var data = {
            labels,
            datasets: [
                {
                    label: 'расходы организации на обеспепчение доступной среды всего',
                    data: main_array,
                    borderColor: '#FFDD09',
                    backgroundColor: '#FFDD09',
                },
                {
                    label: 'расходы на обеспепчение безбарьерной среды',
                    data: props.data.map((d) => d.col_10),
                    borderColor: '#FFDD09',
                    backgroundColor: '#FFDD09',
                    borderDash: [8, 2]
                },
                {
                    label: 'расходы на технические средства обучения лиц с ОВЗ ',
                    data: props.data.map((d) => d.col_11),
                    borderColor: '#555',
                    backgroundColor: '#555',
                },
                {
                    label: 'расходы на кадровое сопровождение обучения лиц с ОВЗ ',
                    data: props.data.map((d) => d.col_12),
                    borderColor: '#212121',
                    backgroundColor: '#212121',
                    borderDash: [8, 2]
                },
                {
                    label: 'расходы на специализированное учебно-методическое обеспечение',
                    data: props.data.map((d) => d.col_13),
                    borderColor: '#9FD2FD',
                    backgroundColor: '#9FD2FD',
                }
            ],
        };
    } else if (props.number == 3) {
        // let tmp_lables = props.data.slice(3).map((d) => String(d.year));
        // let tmp_1 = props.data.slice(3).map((d) => d.col_20);
        // let tmp_2 = props.data.slice(3).map((d) => d.col_21);
        // let tmp_3 = props.data.slice(3).map((d) => d.col_22);
        var data = {
            labels,
            datasets: [
                {
                    label: 'общая численность занимающихся в вузе адаптивной физической культурой в рамках освоения образовательной программы',
                    data: props.data.map((d) => d.col_20),
                    borderColor: '#FFDD09',
                    backgroundColor: '#FFDD09',
                },
                {
                    label: 'количество единиц адаптированного спортивного оборудования для обучающихся инвалидов и лиц с ОВЗ',
                    data: props.data.map((d) => d.col_21),
                    borderColor: '#FFDD09',
                    backgroundColor: '#FFDD09',
                    borderDash: [8, 2]
                },
                {
                    label: 'численность обучающихся инвалидов и лиц с ОВЗ - призеров соревнований различного уровня',
                    data: props.data.map((d) => d.col_22),
                    borderColor: '#555',
                    backgroundColor: '#555',
                    borderDash: [8, 2]
                }
            ],
        };
    } else if (props.number == 4) {
        var main_array = props.data.map((d) => d.col_1);

        var dataset = [
            main_array, 
            props.data.map((d) => d.col_2), 
            props.data.map((d) => d.col_3),
            props.data.map((d) => d.col_4),
            props.data.map((d) => d.col_5),
            props.data.map((d) => d.col_6),
            props.data.map((d) => d.col_7),
            props.data.map((d) => d.col_8)
        ]
        // var norm_dataset = normalise(dataset);
        // dataset = normalise(dataset);

        options.scales = {
            y2: {
                min: Math.min.apply(null, dataset[2]) - Math.min.apply(null, dataset[2]) * 0.5,
                max: Math.max.apply(null, dataset[2]) + Math.max.apply(null, dataset[2]) * 0.01,
                display: false,
                position: 'left',
                ticks: {
                    font: {
                        size: 10,
                        weight: 500,
                        family: 'Fira Sans',
                        fontColor: '#fff'
                    },
                }
            },
            y3 : {
                min: Math.min.apply(null, dataset[3]) - Math.min.apply(null, dataset[3]) * 0.5,
                max: Math.max.apply(null, dataset[3]) + Math.max.apply(null, dataset[3]) * 0.5,
                display: false,
                position: 'right',
                ticks: {
                    font: {
                        size: 10,
                        weight: 500,
                        family: 'Fira Sans',
                        fontColor: '#fff'
                    },
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 17,
                        weight: 500,
                        family: 'Fira Sans',
                        fontColor: '#000000'
                    },
                },
                grid: {
                    drawBorder: false,
                },
            }
        }

        var data = {
            labels,
            datasets: [
                // численность принятых на обучение (синий)
                {
                    label: 'общая численность принятых на обучение',
                    yAxisID: 'y2',
                    data: dataset[2],
                    borderColor: '#9FD2FD',
                    backgroundColor: '#9FD2FD',
                },
                {
                    label: 'численность принятых на обучение инвалидов и лиц с ОВЗ',
                    yAxisID: 'y3',
                    data: dataset[3],
                    borderColor: '#9FD2FD',
                    backgroundColor: '#9FD2FD',
                    borderDash: [8, 2]
                },
            ],
        };
    } else if (props.number == 5) {
        var main_array = props.data.map((d) => d.col_1);

        var dataset = [
            main_array, 
            props.data.map((d) => d.col_2), 
            props.data.map((d) => d.col_3),
            props.data.map((d) => d.col_4),
            props.data.map((d) => d.col_5),
            props.data.map((d) => d.col_6),
            props.data.map((d) => d.col_7),
            props.data.map((d) => d.col_8)
        ]
        // var norm_dataset = normalise(dataset);
        // dataset = normalise(dataset);

        options.scales = {
            y4: {
                min: Math.min.apply(null, dataset[4]) - Math.min.apply(null, dataset[4]) * 0.8,
                max: Math.max.apply(null, dataset[4]) + Math.max.apply(null, dataset[4]) * 0.01,
                display: false,
                position: 'left',
                ticks: {
                    font: {
                        size: 10,
                        weight: 500,
                        family: 'Fira Sans',
                        fontColor: '#fff'
                    },
                }
            },
            y5 : {
                min: Math.min.apply(null, dataset[7]) - Math.min.apply(null, dataset[7]) * 0.5,
                max: Math.max.apply(null, dataset[5]) + Math.max.apply(null, dataset[5]) * 3.5,
                display: false,
                position: 'right',
                ticks: {
                    font: {
                        size: 10,
                        weight: 500,
                        family: 'Fira Sans',
                        fontColor: '#fff'
                    },
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 17,
                        weight: 500,
                        family: 'Fira Sans',
                        fontColor: '#000000'
                    },
                },
                grid: {
                    drawBorder: false,
                },
            }
        }

        var data = {
            labels,
            datasets: [
                // численность выпускников (черный)
                {
                    label: 'общая численность выпускников',
                    yAxisID: 'y4',
                    data: dataset[4],
                    borderColor: '#212121',
                    backgroundColor: '#212121',
                },
                {
                    label: 'численность выпускников-инвалидов',
                    yAxisID: 'y5',
                    data: dataset[5],
                    borderColor: '#212121',
                    backgroundColor: '#212121',
                    borderDash: [8, 2]
                },
                // трудоустроившихся (серый #bcbec0)
                {
                    label: 'численность выпускников-инвалидов трудоустроившихся в течение календарного года',
                    yAxisID: 'y5',
                    data: dataset[6],
                    borderColor: '#bcbec0',
                    backgroundColor: '#bcbec0',
                },
                {
                    label: 'численность выпускников-инвалидов трудоустроившихся по специальности в течение календарного года',
                    yAxisID: 'y5',
                    data: dataset[7],
                    borderColor: '#bcbec0',
                    backgroundColor: '#bcbec0',
                    borderDash: [8, 2]
                },
            ],
        };
    }
    return <Line options={options} data={data} />;
}

export default LineChart;
