import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Routes } from '../Routes';
import classNames from 'classnames';
import axios from 'axios';
import I18n from '../I18n';
import Select from 'react-select';

import Link, { path } from '../Routes';
import Loaf from '../Loaf/Loaf';
import styles from './MonitoringPage.module.css';
import teStyles from '../TypesEquipment/TypesEquipment.module.css';
import form from '../Form.module.css';
import schoolStyles from '../Schools/Show.module.css';
import page from '../Page.module.css';
import ScrollIntoView from 'react-scroll-into-view';
// import AnimatedNumber from 'react-animated-number';
// import TextTransition, { presets } from 'react-text-transition';

import PolarAreaChart from './Charts/PolarArea';
import BarChart from './Charts/BarChart';
import Preloader from './Preloader.js';

const presets = {
    wobbly: ''
};

function TextTransition(props) {
    return <>{props.children}</>;
}

function AnimatedNumber(props) {
    return <>{props.value}</>;
}

class MonitoringPage extends React.Component {
    state = {
        loading: false,
        available_years: [],
        active_year: null,
        data: {},
        federal_districts: [],
        openedFd: null,
        activeFd: null,
        activeRegion: null,
        activeSubject: '',
        chart_data: [],
        openedSection: '',
        openedSectionData: null,

        lastOpenedSection: '',
        lastOpenedSectionData: null,
        isScroll: false,

        showMoreOne: false,
        showMoreTwo: false,
        specialties: {},
        showMoreThree: false,
        showMoreFour: false,
        loadingSection: {},
        spec_bsm: null,
        spec_asp: null
    };

    componentDidMount() {
        this.getSubjects();
        this._loadAsyncData({});
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        if (this._asyncRequestSchools) this._asyncRequestSchools.cancel();
        window.removeEventListener('scroll', this.handleScroll);
    }

    getSubjects = async () => {
        this.setState({ loading: true });
        this._asyncRequest = axios.CancelToken.source();
        const res = await axios.get(path('subjects_monitoring_page_index_path', {}, true), { cancelToken: this._asyncRequest.token });
        this._asyncRequest = null;
        this.setState({
            available_years: res.data.available_years,
            active_year: res.data.available_years[0],
            loading: false,
            federal_districts: res.data.federal_districts,
            specialties: res.data.specialties
        });
    };

    _loadAsyncData = async (params) => {
        params = params && params.year ? params : { ...params, year: this.state.active_year };
        this._asyncRequest = axios.CancelToken.source();
        const res = await axios.get(path('monitoring_page_index_path', {}, true), { cancelToken: this._asyncRequest.token, params: params });
        this._asyncRequest = null;
        this.setState({
            data: res.data.monitoring_data,
            chart_data: res.data.chart_data
        });
    };

    toggleYear = (e, year) => {
        this.setState({ loading: true });
        e.preventDefault();
        this.setState({ active_year: year });
        this._loadAsyncData({ year: year, fd: this.state.activeFd, region: this.state.activeRegion });
        if (this.state.openedSection) {
            this.loadSectionData({ year: year, fd: this.state.activeFd, region: this.state.activeRegion, section: this.state.openedSection });
        }
        this.setState({ loading: false });
    };

    activateFdRegions = (e, fd_id, fd_name) => {
        e.preventDefault();
        this._loadAsyncData({ fd: fd_id });
        if (this.state.openedSection) {
            this.loadSectionData({ fd: fd_id, section: this.state.openedSection });
        }
        this.setState({ activeFd: fd_id, activeRegion: null, activeSubject: fd_name + ' федеральный округ:' });
    };

    openRegions = (e, fd_id) => {
        e.preventDefault();
        if (this.state.openedFd == fd_id) {
            this.setState({ openedFd: null });
        } else {
            this.setState({ openedFd: fd_id });
        }
    };

    activateRegion = (e, reg_id, reg_name) => {
        e.preventDefault();
        this._loadAsyncData({ region: reg_id });
        if (this.state.openedSection) {
            this.loadSectionData({ region: reg_id, section: this.state.openedSection });
        }
        this.setState({ activeRegion: reg_id, activeFd: null, activeSubject: reg_name + ':' });
    };

    activateRussia = (e) => {
        e.preventDefault();
        this._loadAsyncData();
        if (this.state.openedSection) {
            this.loadSectionData({ section: this.state.openedSection });
        }
        this.setState({ activeRegion: null, activeFd: null, activeSubject: '' });
    };

    loadSectionData = async (params) => {
        let tmploadingSection = { ...this.state.loadingSection };
        tmploadingSection[params.section] = true;
        this.setState({ loadingSection: tmploadingSection });

        params = params.year ? params : { ...params, year: this.state.active_year };
        this._asyncRequest = axios.CancelToken.source();
        const res = await axios.get(path('show_section_monitoring_page_index_path', {}, true), { cancelToken: this._asyncRequest.token, params: params });
        this._asyncRequest = null;
        this.setState({
            openedSection: params.section,
            openedSectionData: res.data,

            lastOpenedSection: params.section_name,
            lastOpenedSectionData: res.data,
            loadingSection: false
        });
    };

    openSection = (e, section_name) => {
        e.preventDefault();

        let params = {
            section: section_name,
            fd: this.state.activeFd ? this.state.activeFd : null,
            region: this.state.activeRegion ? this.state.activeRegion : null
        };

        if (this.state.openedSection != section_name && this.state.lastOpenedSection != section_name) {
            this.loadSectionData(params);
        } else if (this.state.openedSection != section_name && this.state.lastOpenedSection == section_name) {
            this.setState({ openedSection: this.state.lastOpenedSection, openedSectionData: this.state.lastOpenedSectionData });
        } else {
            this.setState({ openedSection: '', openedSectionData: null });
        }
    };

    handleScroll = () => {
        if (window.scrollY >= 500 && !this.state.isScroll) {
            this.setState({ isScroll: true });
        } else if (window.scrollY < 500 && this.state.isScroll) {
            this.setState({ isScroll: false });
        }
    };

    toggleSpec = (spec) => {
        let params = {
            section: 'contingent_spec',
            fd: this.state.activeFd ? this.state.activeFd : null,
            region: this.state.activeRegion ? this.state.activeRegion : null,
            ...spec
        };
        this.loadSectionData(params);
        this.setState({ ...spec });
    };

    render() {
        const {
            loading,
            available_years,
            active_year,
            data,
            federal_districts,
            openedFd,
            activeFd,
            activeRegion,
            activeSubject,
            chart_data,
            openedSection,
            openedSectionData,
            showMoreOne,
            showMoreTwo,
            specialties,
            showMoreThree,
            showMoreFour,
            loadingSection,
            spec_bsm,
            spec_asp
        } = this.state;

        return (
            <div className={page.wrapper} id="header">
                <div className={page.container}>
                    <Loaf>
                        <Link to="root_path">{I18n.t('nav.root')}</Link>
                    </Loaf>

                    <div className={page.title}>
                        <h1>{I18n.t('monitoring.title')}</h1>

                        <Helmet>
                            <title>{I18n.t('monitoring.title')}</title>
                        </Helmet>
                    </div>

                    {this.state.isScroll && <ScrollIntoView selector="#header" className={schoolStyles.button_up}></ScrollIntoView>}

                    {available_years && (
                        <div className={styles.info_nav}>
                            <ul>
                                {available_years.map((year) => (
                                    <li className={active_year == year ? styles.info_active_tab : ''} key={year}>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                this.toggleYear(e, year);
                                            }}
                                        >
                                            {year}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {loading && (
                        <div className={styles.preloader_container}>
                            <Preloader />
                        </div>
                    )}

                    {!loading && data && (
                        <>
                            <h2 style={{ margin: '72px 0' }}>Характеристика системы высшего образования в Российской Федерации</h2>
                            <div className={styles.main_data_wrapper}>
                                <div className={classNames(styles.main_data_column, styles.column_padding)}>
                                    <div className={classNames(styles.flex_row, styles.fd_chart)}>
                                        {active_year != 20225 && <div className={styles.chart}>{chart_data.polar_chart_data && <PolarAreaChart data={chart_data.polar_chart_data} />}</div>}
                                        {active_year != 20225 && (
                                            <div>
                                                <div className={styles.source}>
                                                    Источник:{' '}
                                                    <a href="https://monitoring.miccedu.ru/?m=vpo" target="_blank">
                                                        monitoring.miccedu.ru
                                                    </a>
                                                </div>
                                                <div className={styles.source_desc}>
                                                    Информационно-аналитические материалы по результатам проведения мониторинга деятельности образовательных организаций высшего образования{' '}
                                                    {active_year + 1} года (по данным за {active_year} год).
                                                </div>
                                            </div>
                                        )}
                                        {active_year == 20225 && (
                                            <div className={styles.source_desc}>
                                                Мониторинг деятельности образовательных организаций высшего образования по данным за {active_year} год еще не проводился. Данные будут добавлены позже.
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={classNames(styles.main_data_column, styles.column_padding)}>
                                    <div className={classNames(styles.fd_title_wrapper, styles.fd_plus_container)}>
                                        <TextTransition springConfig={presets.wobbly} className={styles.fd_title}>
                                            {activeSubject ? activeSubject.replace(':', '') : 'Федеральные округа'}
                                        </TextTransition>
                                        {activeSubject && (
                                            <div
                                                className={styles.close_subject}
                                                onClick={(e) => {
                                                    this.activateRussia(e);
                                                }}
                                            />
                                        )}
                                    </div>
                                    {federal_districts && (
                                        <div className={styles.fd_wrapper}>
                                            {federal_districts.map((fd) => (
                                                <div key={fd.id} className={classNames(styles.fd_column, styles.fd_item)}>
                                                    <div className={styles.fd_plus_container}>
                                                        <span
                                                            className={activeFd == fd.id ? classNames(styles.fd_span_active, styles.fd_span) : styles.fd_span}
                                                            onClick={(e) => {
                                                                this.activateFdRegions(e, fd.id, fd.title);
                                                            }}
                                                        >
                                                            {fd.title}
                                                        </span>
                                                        <div
                                                            className={openedFd == fd.id ? styles.fd_plus_active : styles.fd_plus}
                                                            onClick={(e) => {
                                                                this.openRegions(e, fd.id);
                                                            }}
                                                        />
                                                    </div>
                                                    <ul className={openedFd == fd.id ? styles.regions_show : styles.regions_hide}>
                                                        {openedFd == fd.id && (
                                                            <>
                                                                {fd.regions.map((region) => (
                                                                    <li
                                                                        key={region.id}
                                                                        onClick={(e) => {
                                                                            this.activateRegion(e, region.id, region.title);
                                                                        }}
                                                                        className={activeRegion == region.id ? styles.fd_span_active : ''}
                                                                    >
                                                                        {region.title}
                                                                    </li>
                                                                ))}
                                                            </>
                                                        )}
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                {active_year != 20225 && (
                                    <div className={styles.flex_row}>
                                        <div className={classNames(styles.flex_column, styles.contingent_flex_column, styles.subj_data_wrap)}>
                                            <div className={styles.contingent_wrapper}>
                                                <div className={styles.contingent_column}>
                                                    <div className={styles.coltingent_item}>
                                                        <div className={styles.coltingent_item_val_wrapper}>
                                                            {data.contingent &&
                                                                data.contingent.map((num, index) => (
                                                                    <div className={styles.coltingent_item_val} key={index}>
                                                                        {num.params && <span>{num.params}</span>}
                                                                        <AnimatedNumber component="span" value={num.val} formatValue={(n) => n.toFixed(0)} />
                                                                    </div>
                                                                ))}
                                                            {data.contingent == null && <span>Нет данных</span>}
                                                        </div>
                                                        <div className={styles.coltingent_item_desk}>контингент студентов, обучающихся по программам высшего образования</div>
                                                    </div>
                                                </div>
                                                <div className={styles.contingent_column}>
                                                    <div className={styles.coltingent_item}>
                                                        <div className={styles.coltingent_item_val}>
                                                            <div className={styles.coltingent_item_val_wrapper}>
                                                                {data.contingent_full_time &&
                                                                    data.contingent_full_time.map((num, index) => (
                                                                        <div className={styles.coltingent_item_val} key={index}>
                                                                            {num.params && <span>{num.params}</span>}
                                                                            <AnimatedNumber component="span" value={num.val} formatValue={(n) => n.toFixed(0)} />
                                                                        </div>
                                                                    ))}
                                                                {data.contingent_full_time == null && <span>Нет данных</span>}
                                                            </div>
                                                        </div>
                                                        <div className={styles.coltingent_item_desk}>очной формы обучения</div>
                                                    </div>
                                                    <div className={styles.coltingent_item}>
                                                        <div className={styles.coltingent_item_val}>
                                                            <div className={styles.coltingent_item_val}>
                                                                <div className={styles.coltingent_item_val_wrapper}>
                                                                    {data.contingent_budget &&
                                                                        data.contingent_budget.map((num, index) => (
                                                                            <div className={styles.coltingent_item_val} key={index}>
                                                                                {num.params && <span>{num.params}</span>}
                                                                                <AnimatedNumber component="span" value={num.val} formatValue={(n) => n.toFixed(0)} />
                                                                            </div>
                                                                        ))}
                                                                    {data.contingent_budget == null && <span>Нет данных</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.coltingent_item_desk}>за счет бюджетных средств бюджетной системы РФ</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {chart_data.bar_1 && (
                                                <div className={styles.main_chart}>
                                                    <div className={styles.flex_column}>
                                                        <div className={classNames(styles.legend_title, styles.legend_title_first)}>обучаются в государственных и муниципальных организациях</div>
                                                        <div className={classNames(styles.legend_title, styles.legend_title_second)}>в частных организациях</div>
                                                    </div>
                                                    <BarChart data={chart_data.bar_1} number={1} className={styles.main_chart} setMax setDl />
                                                </div>
                                            )}
                                        </div>

                                        <div className={classNames(styles.flex_column, styles.subj_data_wrap)}>
                                            <div>
                                                <div className={styles.coltingent_item}>
                                                    <div className={styles.coltingent_item_val_wrapper}>
                                                        {data.count_orgs &&
                                                            data.count_orgs.map((num, index) => (
                                                                <div className={styles.coltingent_item_val} key={index}>
                                                                    {num.params && <span>{num.params}</span>}
                                                                    <AnimatedNumber component="span" value={num.val} formatValue={(n) => n.toFixed(0)} />
                                                                </div>
                                                            ))}
                                                        {data.count_orgs == null && <div>Нет данных</div>}
                                                    </div>
                                                    {data.count_orgs_num != undefined && (
                                                        <div className={styles.coltingent_item_desk}>
                                                            {I18n.t('monitoring.counters.organsation', { count: data.count_orgs_num })} высшего образования:
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classNames(styles.flex_row, styles.fd_count_orgs_row)}>
                                                    <div className={styles.coltingent_item}>
                                                        <div className={styles.coltingent_item_val_wrapper}>
                                                            {data.count_branches &&
                                                                data.count_branches.map((num, index) => (
                                                                    <div className={styles.coltingent_item_val} key={index}>
                                                                        {num.params && <span>{num.params}</span>}
                                                                        <AnimatedNumber component="span" value={num.val} formatValue={(n) => n.toFixed(0)} />
                                                                    </div>
                                                                ))}
                                                            {data.count_branches == null && <div>Нет данных</div>}
                                                        </div>
                                                        {data.count_branches_num != undefined && (
                                                            <div className={styles.coltingent_item_desk}>{I18n.t('monitoring.counters.branches', { count: data.count_branches_num })}</div>
                                                        )}
                                                    </div>

                                                    <div className={styles.coltingent_item}>
                                                        <div className={styles.coltingent_item_val_wrapper}>
                                                            {data.count_fu &&
                                                                data.count_fu.map((num, index) => (
                                                                    <div className={styles.coltingent_item_val} key={index}>
                                                                        {num.params && <span>{num.params}</span>}
                                                                        <AnimatedNumber component="span" value={num.val} formatValue={(n) => n.toFixed(0)} />
                                                                    </div>
                                                                ))}
                                                            {data.count_fu == null && <div>Нет данных</div>}
                                                        </div>
                                                        {data.count_fu_num != undefined && (
                                                            <div className={styles.coltingent_item_desk}>{I18n.t('monitoring.counters.f_u', { count: data.count_fu_num })}</div>
                                                        )}
                                                    </div>

                                                    <div className={styles.coltingent_item}>
                                                        <div className={styles.coltingent_item_val_wrapper}>
                                                            {data.count_project &&
                                                                data.count_project.map((num, index) => (
                                                                    <div className={styles.coltingent_item_val} key={index}>
                                                                        {num.params && <span>{num.params}</span>}
                                                                        <AnimatedNumber component="span" value={num.val} formatValue={(n) => n.toFixed(0)} />
                                                                    </div>
                                                                ))}
                                                            {data.count_project == null && <div>Нет данных</div>}
                                                        </div>
                                                        {data.count_project_num != undefined && (
                                                            <div className={styles.coltingent_item_desk}>
                                                                {I18n.t('monitoring.counters.project_five_hundred', { count: data.count_project_num })}
                                                                <br />
                                                                проекта 5-100
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className={styles.coltingent_item}>
                                                        <div className={styles.coltingent_item_val_wrapper}>
                                                            {data.count_niu &&
                                                                data.count_niu.map((num, index) => (
                                                                    <div className={styles.coltingent_item_val} key={index}>
                                                                        {num.params && <span>{num.params}</span>}
                                                                        <AnimatedNumber component="span" value={num.val} formatValue={(n) => n.toFixed(0)} />
                                                                    </div>
                                                                ))}
                                                            {data.count_niu == null && <div>Нет данных</div>}
                                                        </div>
                                                        {data.count_niu_num != undefined && (
                                                            <div className={styles.coltingent_item_desk}>{I18n.t('monitoring.counters.niu', { count: data.count_niu_num })}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {chart_data.bar_1 && (
                                                <div className={styles.main_chart}>
                                                    <div className={styles.flex_column}>
                                                        <div className={classNames(styles.legend_title, styles.legend_title_first)}>количество государственных и муниципальных организаций</div>
                                                        <div className={classNames(styles.legend_title, styles.legend_title_second)}>частных организаций</div>
                                                    </div>
                                                    <BarChart data={chart_data.bar_2} number={2} className={styles.main_chart} setMax setDl />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {active_year > 2019 && (
                        <>
                            <div className={teStyles.equipment_container}>
                                <div className={teStyles.my_hr} />
                                <div
                                    className={classNames(teStyles.equipment, styles.section)}
                                    onClick={(e) => {
                                        this.openSection(e, 'contingent_spec');
                                    }}
                                >
                                    <div
                                        className={
                                            loadingSection['contingent_spec']
                                                ? classNames(teStyles.title, styles.section_title, styles.icon_contingent_spec_loading)
                                                : classNames(teStyles.title, styles.section_title, styles.icon_contingent_spec)
                                        }
                                    >
                                        Информация о численности студентов, приема и выпуска по формам обучения
                                    </div>
                                    <div className={openedSection == 'contingent_spec' ? teStyles.eq_arrow : classNames(teStyles.arrow_down, teStyles.eq_arrow)} />
                                </div>
                                {openedSection == 'contingent_spec' && (
                                    <div className={styles.contingent_spec_wrapper}>
                                        <div className={classNames(styles.flex_row, styles.double_bar, styles.double_bar_subtitle)}>
                                            По образовательным программам бакалавриата, специалитета, магистратуры
                                        </div>
                                        <div className={classNames(styles.input_wrapper, form.input)}>
                                            {specialties && (
                                                <Select
                                                    classNamePrefix="react-select"
                                                    getOptionLabel={(option) => option.code + ' ' + option.title}
                                                    getOptionValue={(option) => option.id}
                                                    options={specialties.spec_bsm}
                                                    placeholder="Выберите специальность.."
                                                    onChange={(value) => this.toggleSpec({ spec_bsm: value.id, spec_asp: spec_asp })}
                                                />
                                            )}
                                        </div>
                                        <div className={styles.main_data_wrapper}>
                                            <div className={styles.main_data_column}>
                                                <h3 className={styles.contingent_spec_title_item}>По Российской Федерации:</h3>
                                                <div className={teStyles.my_hr} />

                                                <div className={styles.contingent_spec_charts_wrapper}>
                                                    <div className={styles.flex_row}>
                                                        <div
                                                            className={styles.contingent_spec_charts_all_btn}
                                                            onClick={() => {
                                                                showMoreOne ? this.setState({ showMoreOne: false }) : this.setState({ showMoreOne: true });
                                                            }}
                                                        >
                                                            <span>Всего</span>
                                                            <div className={!showMoreOne ? classNames(teStyles.arrow_down, teStyles.arrow, styles.arrow) : classNames(teStyles.arrow, styles.arrow)} />
                                                        </div>
                                                        <div className={styles.contingent_spec_chart}>
                                                            {openedSectionData && <BarChart data={[openedSectionData.spec_bsm.RF.all_count]} number={null} reverseColors={true} setMax setDl />}
                                                        </div>
                                                    </div>
                                                </div>

                                                {showMoreOne && (
                                                    <div className={classNames(styles.flex_columnm, styles.spec_forms_wrapper)}>
                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Подано заявлений с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_bsm.RF.submitted.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_bsm.RF.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_bsm.RF.submitted.o]} number={null} maXvalue={openedSectionData.spec_bsm.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_bsm.RF.submitted.z]} number={null} maXvalue={openedSectionData.spec_bsm.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.RF.submitted.oz]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.RF.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Принято с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_bsm.RF.accepted.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_bsm.RF.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_bsm.RF.accepted.o]} number={null} maXvalue={openedSectionData.spec_bsm.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_bsm.RF.accepted.z]} number={null} maXvalue={openedSectionData.spec_bsm.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_bsm.RF.accepted.oz]} number={null} maXvalue={openedSectionData.spec_bsm.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>Обучаются на 01.10.{String(active_year).substr(2, 3)}</div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_bsm.RF.study.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_bsm.RF.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_bsm.RF.study.o]} number={null} maXvalue={openedSectionData.spec_bsm.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_bsm.RF.study.z]} number={null} maXvalue={openedSectionData.spec_bsm.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_bsm.RF.study.oz]} number={null} maXvalue={openedSectionData.spec_bsm.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Выпуск с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_bsm.RF.graduate.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_bsm.RF.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_bsm.RF.graduate.o]} number={null} maXvalue={openedSectionData.spec_bsm.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_bsm.RF.graduate.z]} number={null} maXvalue={openedSectionData.spec_bsm.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_bsm.RF.graduate.oz]} number={null} maXvalue={openedSectionData.spec_bsm.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className={classNames(styles.contingent_spec_wrapper, teStyles.my_hr)} />
                                            </div>

                                            <div className={styles.divider} />

                                            <div className={styles.main_data_column}>
                                                {!activeSubject && <h3 className={styles.contingent_spec_title_item}>По субъектам Российской Федерации:</h3>}
                                                {activeSubject && <h3 className={styles.contingent_spec_title_item}>{activeSubject}</h3>}
                                                <div className={teStyles.my_hr} />

                                                <div className={styles.contingent_spec_charts_wrapper}>
                                                    <div className={styles.flex_row}>
                                                        <div
                                                            className={styles.contingent_spec_charts_all_btn}
                                                            onClick={() => {
                                                                showMoreTwo ? this.setState({ showMoreTwo: false }) : this.setState({ showMoreTwo: true });
                                                            }}
                                                        >
                                                            <span>Всего</span>
                                                            <div className={!showMoreTwo ? classNames(teStyles.arrow_down, teStyles.arrow, styles.arrow) : classNames(teStyles.arrow, styles.arrow)} />
                                                        </div>
                                                        <div className={styles.contingent_spec_chart}>
                                                            {openedSectionData && <BarChart data={[openedSectionData.spec_bsm.Subject.all_count]} number={null} reverseColors={true} setMax setDl />}
                                                        </div>
                                                    </div>
                                                </div>

                                                {showMoreTwo && (
                                                    <div className={classNames(styles.flex_columnm, styles.spec_forms_wrapper)}>
                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Подано заявлений с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_bsm.Subject.submitted.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.submitted.o]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.submitted.z]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.submitted.oz]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Принято с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_bsm.Subject.accepted.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.accepted.o]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.accepted.z]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.accepted.oz]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>Обучаются на 01.10.{String(active_year).substr(2, 3)}</div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_bsm.Subject.study.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.study.o]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.study.z]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.study.oz]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Выпуск с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_bsm.Subject.graduate.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.graduate.o]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.graduate.z]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_bsm.Subject.graduate.oz]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_bsm.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className={classNames(styles.contingent_spec_wrapper, teStyles.my_hr)} />
                                            </div>
                                        </div>

                                        <div className={classNames(styles.flex_row, styles.double_bar, styles.double_bar_subtitle)}>
                                            По образовательным программам ординатуры, аспирантуры (адъюнктуры), ассистентуры-стажировки
                                        </div>
                                        <div className={classNames(styles.input_wrapper, form.input)}>
                                            {specialties && (
                                                <Select
                                                    classNamePrefix="react-select"
                                                    getOptionLabel={(option) => option.code + ' ' + option.title}
                                                    getOptionValue={(option) => option.id}
                                                    options={specialties.spec_asp}
                                                    placeholder="Выберите специальность.."
                                                    onChange={(value) => this.toggleSpec({ spec_bsm: spec_bsm, spec_asp: value.id })}
                                                />
                                            )}
                                        </div>
                                        <div className={styles.main_data_wrapper}>
                                            <div className={styles.main_data_column}>
                                                <h3 className={styles.contingent_spec_title_item}>По Российской Федерации:</h3>
                                                <div className={teStyles.my_hr} />

                                                <div className={styles.contingent_spec_charts_wrapper}>
                                                    <div className={styles.flex_row}>
                                                        <div
                                                            className={styles.contingent_spec_charts_all_btn}
                                                            onClick={() => {
                                                                showMoreThree ? this.setState({ showMoreThree: false }) : this.setState({ showMoreThree: true });
                                                            }}
                                                        >
                                                            <span>Всего</span>
                                                            <div
                                                                className={!showMoreThree ? classNames(teStyles.arrow_down, teStyles.arrow, styles.arrow) : classNames(teStyles.arrow, styles.arrow)}
                                                            />
                                                        </div>
                                                        <div className={styles.contingent_spec_chart}>
                                                            {openedSectionData && <BarChart data={[openedSectionData.spec_asp.RF.all_count]} number={null} reverseColors={true} setMax setDl />}
                                                        </div>
                                                    </div>
                                                </div>

                                                {showMoreThree && (
                                                    <div className={classNames(styles.flex_columnm, styles.spec_forms_wrapper)}>
                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Подано заявлений с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_asp.RF.submitted.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_asp.RF.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_asp.RF.submitted.o]} number={null} maXvalue={openedSectionData.spec_asp.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_asp.RF.submitted.z]} number={null} maXvalue={openedSectionData.spec_asp.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.RF.submitted.oz]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.RF.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Принято с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_asp.RF.accepted.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_asp.RF.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_asp.RF.accepted.o]} number={null} maXvalue={openedSectionData.spec_asp.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_asp.RF.accepted.z]} number={null} maXvalue={openedSectionData.spec_asp.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_asp.RF.accepted.oz]} number={null} maXvalue={openedSectionData.spec_asp.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>Обучаются на 01.10.{String(active_year).substr(2, 3)}</div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_asp.RF.study.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_asp.RF.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_asp.RF.study.o]} number={null} maXvalue={openedSectionData.spec_asp.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_asp.RF.study.z]} number={null} maXvalue={openedSectionData.spec_asp.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_asp.RF.study.oz]} number={null} maXvalue={openedSectionData.spec_asp.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Выпуск с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_asp.RF.graduate.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_asp.RF.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_asp.RF.graduate.o]} number={null} maXvalue={openedSectionData.spec_asp.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_asp.RF.graduate.z]} number={null} maXvalue={openedSectionData.spec_asp.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart data={[openedSectionData.spec_asp.RF.graduate.oz]} number={null} maXvalue={openedSectionData.spec_asp.RF.all_count} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* <div className={teStyles.my_hr} /> */}
                                                <div className={classNames(styles.contingent_spec_wrapper, teStyles.my_hr)} />
                                            </div>

                                            <div className={styles.divider} />

                                            <div className={styles.main_data_column}>
                                                {!activeSubject && <h3 className={styles.contingent_spec_title_item}>По субъектам Российской Федерации:</h3>}
                                                {activeSubject && <h3 className={styles.contingent_spec_title_item}>{activeSubject}</h3>}
                                                <div className={teStyles.my_hr} />

                                                <div className={styles.contingent_spec_charts_wrapper}>
                                                    <div className={styles.flex_row}>
                                                        <div
                                                            className={styles.contingent_spec_charts_all_btn}
                                                            onClick={() => {
                                                                showMoreFour ? this.setState({ showMoreFour: false }) : this.setState({ showMoreFour: true });
                                                            }}
                                                        >
                                                            <span>Всего</span>
                                                            <div className={!showMoreFour ? classNames(teStyles.arrow_down, teStyles.arrow, styles.arrow) : classNames(teStyles.arrow, styles.arrow)} />
                                                        </div>
                                                        <div className={styles.contingent_spec_chart}>
                                                            {openedSectionData && <BarChart data={[openedSectionData.spec_asp.Subject.all_count]} number={null} reverseColors={true} setMax setDl />}
                                                        </div>
                                                    </div>
                                                </div>

                                                {showMoreFour && (
                                                    <div className={classNames(styles.flex_columnm, styles.spec_forms_wrapper)}>
                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Подано заявлений с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_asp.Subject.submitted.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.submitted.o]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.submitted.z]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.submitted.oz]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Принято с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_asp.Subject.accepted.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.accepted.o]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.accepted.z]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.accepted.oz]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>Обучаются на 01.10.{String(active_year).substr(2, 3)}</div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_asp.Subject.study.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.study.o]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.study.z]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.study.oz]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={styles.flex_row}>
                                                            <div className={styles.contingent_spec_charts_item}>
                                                                Выпуск с 01.10.{String(active_year - 1).substr(2, 3)} по 30.09.{String(active_year).substr(2, 3)}
                                                            </div>
                                                            <div className={styles.contingent_spec_chart}>
                                                                {openedSectionData && (
                                                                    <BarChart
                                                                        data={[openedSectionData.spec_asp.Subject.graduate.all]}
                                                                        number={null}
                                                                        reverseColors={true}
                                                                        maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={styles.flex_column}>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.graduate.o]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.graduate.z]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex_row}>
                                                                <div className={styles.contingent_spec_charts_subitem}>Очно-заочная</div>
                                                                <div className={styles.contingent_spec_chart}>
                                                                    {openedSectionData && (
                                                                        <BarChart
                                                                            data={[openedSectionData.spec_asp.Subject.graduate.oz]}
                                                                            number={null}
                                                                            maXvalue={openedSectionData.spec_asp.Subject.all_count}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className={teStyles.my_hr} />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Динамика */}
                                <div className={teStyles.my_hr} />
                                <div
                                    className={classNames(teStyles.equipment, styles.section)}
                                    onClick={(e) => {
                                        this.openSection(e, 'dynamics');
                                    }}
                                >
                                    <div
                                        className={
                                            loadingSection['dynamics']
                                                ? classNames(teStyles.title, styles.section_title, styles.icon_dynamics_loading)
                                                : classNames(teStyles.title, styles.section_title, styles.icon_dynamics)
                                        }
                                    >
                                        Динамика
                                    </div>
                                    <div className={openedSection == 'dynamics' ? teStyles.eq_arrow : classNames(teStyles.arrow_down, teStyles.eq_arrow)} />
                                </div>
                                {openedSection == 'dynamics' && (
                                    <div className={classNames(styles.flex_row, styles.dynamics_chart_wrapper)}>
                                        <div className={styles.chart_flex_column}>
                                            <div className={classNames(styles.flex_column, styles.legend_wrapper)}>
                                                <div className={classNames(styles.legend_title, styles.legend_title_first)}>общая численность обучающихся в вузах</div>
                                                <div className={classNames(styles.legend_title, styles.legend_title_second)}>численность обучающихся лиц с ОВЗ и инвалидностью</div>
                                            </div>
                                            <div className={styles.dynamics_chart}>
                                                {openedSectionData && <BarChart data={[openedSectionData.col_7, openedSectionData.col_8]} number={'d1'} vertical setDl setMax />}
                                            </div>
                                        </div>

                                        <div className={styles.chart_flex_column}>
                                            <div className={classNames(styles.flex_column, styles.legend_wrapper)}>
                                                <div className={classNames(styles.legend_title, styles.legend_title_first)}>общая численность принятых на обучение</div>
                                                <div className={classNames(styles.legend_title, styles.legend_title_second)}>численность принятых на обучение лиц с ОВЗ и инвалидностью</div>
                                            </div>
                                            <div className={styles.dynamics_chart}>
                                                {openedSectionData && (
                                                    <BarChart data={[openedSectionData.col_9, openedSectionData.col_10]} number={'d2'} vertical maXvalue={openedSectionData.col_7} setDl setMax />
                                                )}
                                            </div>
                                        </div>

                                        <div className={styles.chart_flex_column}>
                                            <div className={classNames(styles.flex_column, styles.legend_wrapper)}>
                                                <div className={classNames(styles.legend_title, styles.legend_title_first)}>общая численность выпускников</div>
                                                <div className={classNames(styles.legend_title, styles.legend_title_second)}>численность выпускников с инвалидностью</div>
                                            </div>
                                            <div className={styles.dynamics_chart}>
                                                {openedSectionData && (
                                                    <BarChart data={[openedSectionData.col_11, openedSectionData.col_12]} number={'d3'} vertical maXvalue={openedSectionData.col_7} setDl setMax />
                                                )}
                                            </div>
                                        </div>

                                        <div className={styles.chart_flex_column}>
                                            <div className={classNames(styles.flex_column, styles.legend_wrapper)}>
                                                <div className={classNames(styles.legend_title, styles.legend_title_first)}>
                                                    численность выпускников с инвалидностью трудоустроившихся в течение календарного года
                                                </div>
                                                <div className={classNames(styles.legend_title, styles.legend_title_second)}>
                                                    численность выпускников с инвалидностью трудоустроившихся по специальности в течение календарного года
                                                </div>
                                            </div>
                                            <div className={styles.dynamics_chart}>
                                                {openedSectionData && (
                                                    <BarChart data={[openedSectionData.col_13, openedSectionData.col_14]} number={'d4'} vertical maXvalue={openedSectionData.col_7} setDl setMax />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Нормативное обеспечение деятельности по обучению лиц с ОВЗ и инвалидностью */}
                                <div className={teStyles.my_hr} />
                                <div
                                    className={classNames(teStyles.equipment, styles.section)}
                                    onClick={(e) => {
                                        this.openSection(e, 'regulatory_support');
                                    }}
                                >
                                    <div className={classNames(teStyles.title, styles.section_title, styles.icon_regulatory_support)}>
                                        Нормативное обеспечение деятельности по обучению лиц с ОВЗ и инвалидностью
                                    </div>
                                    <div className={openedSection == 'regulatory_support' ? teStyles.eq_arrow : classNames(teStyles.arrow_down, teStyles.eq_arrow)} />
                                </div>
                                {openedSection == 'regulatory_support' && (
                                    <>
                                        <div className={classNames(styles.flex_row, styles.double_bar)}>
                                            <h3 className={styles.double_bar_title_item}>По Российской Федерации:</h3>
                                            {!activeSubject && <h3 className={styles.double_bar_title_item}>По субъектам Российской Федерации:</h3>}
                                            {activeSubject && <h3 className={styles.double_bar_title_item}>{activeSubject}</h3>}
                                        </div>
                                        {openedSectionData &&
                                            ['col_15', 'col_16', 'col_17', 'col_18', 'col_19', 'col_20'].map((col, index) => (
                                                <div className={styles.double_dar_block_wrapper} key={index}>
                                                    <div className={classNames(styles.flex_row, styles.double_bar, styles.double_bar_subtitle)}>{openedSectionData.desc[col]}</div>
                                                    <div className={classNames(styles.chart_flex_column, styles.double_bar_wrapper)}>
                                                        <div className={styles.double_bar_legend_wrapper}>
                                                            <div className={styles.flex_row}>
                                                                <div className={classNames(styles.legend_title, styles.legend_title_first)}>да</div>
                                                                <div className={classNames(styles.legend_title, styles.legend_title_second)}>нет</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={classNames(styles.double_bar, styles.double_bar_first)}>
                                                                <div className={styles.double_bar_item}>
                                                                    <BarChart
                                                                        data={[openedSectionData[col].RF.yes * -1, openedSectionData[col].RF.no * -1]}
                                                                        number={'yesNo'}
                                                                        inverTooltip={true}
                                                                        showAxis={'right'}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={classNames(styles.double_bar, styles.double_bar_second)}>
                                                                <div className={styles.double_bar_item}>
                                                                    <BarChart
                                                                        data={[openedSectionData[col].subject.yes, openedSectionData[col].subject.no]}
                                                                        number={'yesNo'}
                                                                        showAxis={'left'}
                                                                        maXvalue={openedSectionData[col].RF.yes}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </>
                                )}

                                {/* Обеспечение условий доступности информации */}
                                <div className={teStyles.my_hr} />
                                <div
                                    className={classNames(teStyles.equipment, styles.section)}
                                    onClick={(e) => {
                                        this.openSection(e, 'availability_information');
                                    }}
                                >
                                    <div className={classNames(teStyles.title, styles.section_title, styles.icon_availability_information)}>Обеспечение условий доступности информации</div>
                                    <div className={openedSection == 'availability_information' ? teStyles.eq_arrow : classNames(teStyles.arrow_down, teStyles.eq_arrow)} />
                                </div>
                                {openedSection == 'availability_information' && (
                                    <>
                                        <div className={classNames(styles.flex_row, styles.double_bar)}>
                                            <h3 className={styles.double_bar_title_item}>По Российской Федерации:</h3>
                                            {!activeSubject && <h3 className={styles.double_bar_title_item}>По субъектам Российской Федерации:</h3>}
                                            {activeSubject && <h3 className={styles.double_bar_title_item}>{activeSubject}</h3>}
                                        </div>
                                        {openedSectionData &&
                                            ['col_21', 'col_22', 'col_23'].map((col, index) => (
                                                <div className={styles.double_dar_block_wrapper} key={index}>
                                                    <div className={classNames(styles.flex_row, styles.double_bar, styles.double_bar_subtitle)}>{openedSectionData.desc[col]}</div>
                                                    <div className={classNames(styles.chart_flex_column, styles.double_bar_wrapper)}>
                                                        <div className={styles.double_bar_legend_wrapper}>
                                                            <div className={styles.flex_row}>
                                                                <div className={classNames(styles.legend_title, styles.legend_title_first)}>да</div>
                                                                <div className={classNames(styles.legend_title, styles.legend_title_second)}>нет</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={classNames(styles.double_bar, styles.double_bar_first)}>
                                                                <div className={styles.double_bar_item}>
                                                                    <BarChart
                                                                        data={[openedSectionData[col].RF.yes * -1, openedSectionData[col].RF.no * -1]}
                                                                        number={'yesNo'}
                                                                        inverTooltip={true}
                                                                        showAxis={'right'}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={classNames(styles.double_bar, styles.double_bar_second)}>
                                                                <div className={styles.double_bar_item}>
                                                                    <BarChart
                                                                        data={[openedSectionData[col].subject.yes, openedSectionData[col].subject.no]}
                                                                        number={'yesNo'}
                                                                        showAxis={'left'}
                                                                        maXvalue={openedSectionData[col].RF.yes}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </>
                                )}

                                {/* Адаптация образовательных программ и учебно-методического обеспечения лиц с ОВЗ и инвалидностью */}
                                <div className={teStyles.my_hr} />
                                <div
                                    className={classNames(teStyles.equipment, styles.section)}
                                    onClick={(e) => {
                                        this.openSection(e, 'adaptation');
                                    }}
                                >
                                    <div
                                        className={
                                            loadingSection['adaptation']
                                                ? classNames(teStyles.title, styles.section_title, styles.icon_adaptation_loading)
                                                : classNames(teStyles.title, styles.section_title, styles.icon_adaptation)
                                        }
                                    >
                                        Адаптация образовательных программ и учебно-методического обеспечения лиц с ОВЗ и инвалидностью
                                    </div>
                                    <div className={openedSection == 'adaptation' ? teStyles.eq_arrow : classNames(teStyles.arrow_down, teStyles.eq_arrow)} />
                                </div>
                                {openedSection == 'adaptation' && (
                                    <>
                                        <div className={classNames(styles.flex_row, styles.double_bar)}>
                                            <h3 className={styles.double_bar_title_item}>По Российской Федерации:</h3>
                                            {!activeSubject && <h3 className={styles.double_bar_title_item}>По субъектам Российской Федерации:</h3>}
                                            {activeSubject && <h3 className={styles.double_bar_title_item}>{activeSubject}</h3>}
                                        </div>
                                        {openedSectionData &&
                                            ['col_24', 'col_25_26'].map((col, index) => (
                                                <div className={styles.double_dar_block_wrapper} key={index}>
                                                    <div className={classNames(styles.flex_row, styles.double_bar, styles.double_bar_subtitle)}>{openedSectionData.desc[col]}</div>
                                                    <div className={classNames(styles.chart_flex_column, styles.double_bar_wrapper)}>
                                                        <div className={styles.double_bar_legend_wrapper}>
                                                            {!openedSectionData[col].labels && (
                                                                <div className={styles.flex_row}>
                                                                    <div className={classNames(styles.legend_title, styles.legend_title_first)}>да</div>
                                                                    <div className={classNames(styles.legend_title, styles.legend_title_second)}>нет</div>
                                                                </div>
                                                            )}
                                                            {openedSectionData[col].labels && (
                                                                <div className={styles.flex_row}>
                                                                    <div className={classNames(styles.legend_title, styles.legend_title_first)}>{openedSectionData[col].labels[0]}</div>
                                                                    <div className={classNames(styles.legend_title, styles.legend_title_second)}>{openedSectionData[col].labels[1]}</div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <div className={classNames(styles.double_bar, styles.double_bar_first)}>
                                                                <div className={styles.double_bar_item}>
                                                                    <BarChart
                                                                        data={[openedSectionData[col].RF.yes * -1, openedSectionData[col].RF.no * -1]}
                                                                        number={openedSectionData[col].number ? openedSectionData[col].number : 'yesNo'}
                                                                        inverTooltip={true}
                                                                        showAxis={'right'}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={classNames(styles.double_bar, styles.double_bar_second)}>
                                                                <div className={styles.double_bar_item}>
                                                                    <BarChart
                                                                        data={[openedSectionData[col].subject.yes, openedSectionData[col].subject.no]}
                                                                        number={openedSectionData[col].number ? openedSectionData[col].number : 'yesNo'}
                                                                        showAxis={'left'}
                                                                        maXvalue={openedSectionData[col].RF.yes}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </>
                                )}

                                {/* Качество организации процесса обучения */}
                                <div className={teStyles.my_hr} />
                                <div
                                    className={classNames(teStyles.equipment, styles.section)}
                                    onClick={(e) => {
                                        this.openSection(e, 'quality');
                                    }}
                                >
                                    <div className={classNames(teStyles.title, styles.section_title, styles.icon_quality)}>Качество организации процесса обучения</div>
                                    <div className={openedSection == 'quality' ? teStyles.eq_arrow : classNames(teStyles.arrow_down, teStyles.eq_arrow)} />
                                </div>
                                {openedSection == 'quality' && (
                                    <>
                                        <div className={classNames(styles.flex_row, styles.double_bar)}>
                                            <h3 className={styles.double_bar_title_item}>По Российской Федерации:</h3>
                                            {!activeSubject && <h3 className={styles.double_bar_title_item}>По субъектам Российской Федерации:</h3>}
                                            {activeSubject && <h3 className={styles.double_bar_title_item}>{activeSubject}</h3>}
                                        </div>
                                        <div className={styles.double_dar_block_wrapper}>
                                            <div className={classNames(styles.flex_row, styles.double_bar, styles.double_bar_subtitle)}>
                                                Численность обучающихся лиц с ОВЗ и инвалидностью, получающих дополнительную финансовую поддержку (специальные стипендии, гранты и т.п.)
                                            </div>

                                            <div className={classNames(styles.chart_flex_column, styles.double_bar_wrapper)}>
                                                <div>
                                                    <div className={classNames(styles.double_bar, styles.double_bar_first)}>
                                                        <div className={styles.double_bar_item}>
                                                            {openedSectionData && <BarChart data={[openedSectionData.col_27.RF]} number={null} setDl setMax />}
                                                        </div>
                                                        <div className={classNames(styles.divider, styles.divider_no_margin)} />
                                                        <div className={styles.double_bar_item}>
                                                            {openedSectionData && (
                                                                <BarChart data={[openedSectionData.col_27.subject]} number={null} maXvalue={openedSectionData.col_27.RF} setDl setMax />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.double_dar_block_wrapper}>
                                            <div className={classNames(styles.flex_row, styles.double_bar, styles.double_bar_subtitle)}>
                                                Количество мероприятий художественного, творческого и интеллектуального характера, в которых могут принимать участие обучающиеся и лица с ОВЗ и
                                                инвалидностью (инклюзивных мероприятий)
                                            </div>

                                            <div className={classNames(styles.chart_flex_column, styles.double_bar_wrapper)}>
                                                <div>
                                                    <div className={classNames(styles.double_bar, styles.double_bar_first)}>
                                                        <div className={styles.double_bar_item}>
                                                            {openedSectionData && <BarChart data={[openedSectionData.col_28.RF]} number={null} setDl setMax />}
                                                        </div>
                                                        <div className={classNames(styles.divider, styles.divider_no_margin)} />
                                                        <div className={styles.double_bar_item}>
                                                            {openedSectionData && (
                                                                <BarChart data={[openedSectionData.col_28.subject]} number={null} maXvalue={openedSectionData.col_28.RF} setDl setMax />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className={teStyles.my_hr} />
                                <div
                                    className={classNames(teStyles.equipment, styles.section)}
                                    onClick={(e) => {
                                        this.openSection(e, 'employment_graduates');
                                    }}
                                >
                                    <div className={classNames(teStyles.title, styles.section_title, styles.icon_employment_graduates)}>Трудоустройство выпускников</div>
                                    <div className={openedSection == 'employment_graduates' ? teStyles.eq_arrow : classNames(teStyles.arrow_down, teStyles.eq_arrow)} />
                                </div>
                                {openedSection == 'employment_graduates' && (
                                    <>
                                        <div className={classNames(styles.flex_row, styles.double_bar)}>
                                            <h3 className={styles.double_bar_title_item}>По Российской Федерации:</h3>
                                            {!activeSubject && <h3 className={styles.double_bar_title_item}>По субъектам Российской Федерации:</h3>}
                                            {activeSubject && <h3 className={styles.double_bar_title_item}>{activeSubject}</h3>}
                                        </div>
                                        {openedSectionData &&
                                            ['col_29', 'col_30'].map((col, index) => (
                                                <div className={styles.double_dar_block_wrapper} key={index}>
                                                    <div className={classNames(styles.flex_row, styles.double_bar, styles.double_bar_subtitle)}>{openedSectionData.desc[col]}</div>
                                                    <div className={classNames(styles.chart_flex_column, styles.double_bar_wrapper)}>
                                                        <div className={styles.double_bar_legend_wrapper}>
                                                            <div className={styles.flex_row}>
                                                                <div className={classNames(styles.legend_title, styles.legend_title_first)}>да</div>
                                                                <div className={classNames(styles.legend_title, styles.legend_title_second)}>нет</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={classNames(styles.double_bar, styles.double_bar_first)}>
                                                                <div className={styles.double_bar_item}>
                                                                    <BarChart
                                                                        data={[openedSectionData[col].RF.yes * -1, openedSectionData[col].RF.no * -1]}
                                                                        number={'yesNo'}
                                                                        inverTooltip={true}
                                                                        showAxis={'right'}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={classNames(styles.double_bar, styles.double_bar_second)}>
                                                                <div className={styles.double_bar_item}>
                                                                    <BarChart
                                                                        data={[openedSectionData[col].subject.yes, openedSectionData[col].subject.no]}
                                                                        number={'yesNo'}
                                                                        showAxis={'left'}
                                                                        maXvalue={openedSectionData[col].RF.yes}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </>
                                )}

                                <div className={teStyles.my_hr} />
                                <div
                                    className={classNames(teStyles.equipment, styles.section)}
                                    onClick={(e) => {
                                        this.openSection(e, 'support_graduates');
                                    }}
                                >
                                    <div className={classNames(teStyles.title, styles.section_title, styles.icon_support_graduates)}>Обеспеченность постдипломного сопровождения выпускников</div>
                                    <div className={openedSection == 'support_graduates' ? teStyles.eq_arrow : classNames(teStyles.arrow_down, teStyles.eq_arrow)} />
                                </div>
                                {openedSection == 'support_graduates' && (
                                    <>
                                        <div className={classNames(styles.flex_row, styles.double_bar)}>
                                            <h3 className={styles.double_bar_title_item}>По Российской Федерации:</h3>
                                            {!activeSubject && <h3 className={styles.double_bar_title_item}>По субъектам Российской Федерации:</h3>}
                                            {activeSubject && <h3 className={styles.double_bar_title_item}>{activeSubject}</h3>}
                                        </div>
                                        {openedSectionData &&
                                            ['col_31', 'col_32'].map((col, index) => (
                                                <div className={styles.double_dar_block_wrapper} key={index}>
                                                    <div className={classNames(styles.flex_row, styles.double_bar, styles.double_bar_subtitle)}>{openedSectionData.desc[col]}</div>
                                                    <div className={classNames(styles.chart_flex_column, styles.double_bar_wrapper)}>
                                                        <div className={styles.double_bar_legend_wrapper}>
                                                            <div className={styles.flex_row}>
                                                                <div className={classNames(styles.legend_title, styles.legend_title_first)}>да</div>
                                                                <div className={classNames(styles.legend_title, styles.legend_title_second)}>нет</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={classNames(styles.double_bar, styles.double_bar_first)}>
                                                                <div className={styles.double_bar_item}>
                                                                    <BarChart
                                                                        data={[openedSectionData[col].RF.yes * -1, openedSectionData[col].RF.no * -1]}
                                                                        number={'yesNo'}
                                                                        inverTooltip={true}
                                                                        showAxis={'right'}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={classNames(styles.double_bar, styles.double_bar_second)}>
                                                                <div className={styles.double_bar_item}>
                                                                    <BarChart
                                                                        data={[openedSectionData[col].subject.yes, openedSectionData[col].subject.no]}
                                                                        number={'yesNo'}
                                                                        showAxis={'left'}
                                                                        maXvalue={openedSectionData[col].RF.yes}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        <div className={styles.double_dar_block_wrapper}>
                                            <div className={classNames(styles.flex_row, styles.double_bar, styles.double_bar_subtitle)}>
                                                Численность выпускников с инвалидностью, использующих программы постдипломного сопровождения
                                            </div>

                                            <div className={classNames(styles.chart_flex_column, styles.double_bar_wrapper)}>
                                                <div>
                                                    <div className={classNames(styles.double_bar, styles.double_bar_first)}>
                                                        <div className={styles.double_bar_item}>
                                                            {openedSectionData && <BarChart data={[openedSectionData.col_33.RF]} number={null} setMax setDl />}
                                                        </div>
                                                        <div className={classNames(styles.divider, styles.divider_no_margin)} />
                                                        <div className={styles.double_bar_item}>
                                                            {openedSectionData && (
                                                                <BarChart data={[openedSectionData.col_33.subject]} number={null} maXvalue={openedSectionData.col_33.RF} setMax setDl />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className={teStyles.my_hr} />
                                <div
                                    className={classNames(teStyles.equipment, styles.section)}
                                    onClick={(e) => {
                                        this.openSection(e, 'sport');
                                    }}
                                >
                                    <div className={classNames(teStyles.title, styles.section_title, styles.icon_sport)}>Развитие адаптивной физической культуры и спорта</div>
                                    <div className={openedSection == 'sport' ? teStyles.eq_arrow : classNames(teStyles.arrow_down, teStyles.eq_arrow)} />
                                </div>
                                {openedSection == 'sport' && (
                                    <>
                                        <div className={classNames(styles.flex_row, styles.double_bar)}>
                                            <h3 className={styles.double_bar_title_item}>По Российской Федерации:</h3>
                                            {!activeSubject && <h3 className={styles.double_bar_title_item}>По субъектам Российской Федерации:</h3>}
                                            {activeSubject && <h3 className={styles.double_bar_title_item}>{activeSubject}</h3>}
                                        </div>
                                        <div className={styles.double_dar_block_wrapper}>
                                            <div className={classNames(styles.flex_row, styles.double_bar, styles.double_bar_subtitle)}>{openedSectionData.desc['col_34']}</div>
                                            <div className={classNames(styles.chart_flex_column, styles.double_bar_wrapper)}>
                                                <div className={styles.double_bar_legend_wrapper}>
                                                    <div className={styles.flex_row}>
                                                        <div className={classNames(styles.legend_title, styles.legend_title_first)}>да</div>
                                                        <div className={classNames(styles.legend_title, styles.legend_title_second)}>нет</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={classNames(styles.double_bar, styles.double_bar_first)}>
                                                        <div className={styles.double_bar_item}>
                                                            <BarChart
                                                                data={[openedSectionData['col_34'].RF.yes * -1, openedSectionData['col_34'].RF.no * -1]}
                                                                number={'yesNo'}
                                                                inverTooltip={true}
                                                                showAxis={'right'}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(styles.double_bar, styles.double_bar_second)}>
                                                        <div className={styles.double_bar_item}>
                                                            <BarChart
                                                                data={[openedSectionData['col_34'].subject.yes, openedSectionData['col_34'].subject.no]}
                                                                number={'yesNo'}
                                                                showAxis={'left'}
                                                                maXvalue={openedSectionData['col_34'].RF.yes}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className={teStyles.my_hr} />
                            </div>

                            <div className={styles.source_wrapper}>
                                <div className={styles.source}>Источник: Мониторинг инклюзивного высшего образования</div>
                                <div className={styles.source_desc}>
                                    Мониторинг деятельности образовательных организаций высшего образования по вопросам приема, обучения и трудоустройства лиц с инвалидностью и ограниченными
                                    возможностями здоровья, формируемый на основе сведений, предоставляемых организациями.
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

class MonitoringPageWrapper extends React.Component {
    render() {
        return (
            <Switch>
                <Route path={decodeURIComponent(Routes.monitoring_page_index_path)} component={MonitoringPage} exact strict />
            </Switch>
        );
    }
}

export default MonitoringPageWrapper;
