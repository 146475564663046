import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { path } from '../../Routes';
import Loaf from '../../Loaf/Loaf';
import List from '../List/List';
import ListRating from '../List/ListRating';
import page from '../../Page.module.css';

class Efficiency extends Component {
  state = {
    search: this.props.location.search,
    rumcs: null,
    list: null,
  }

  static getDerivedStateFromProps(props, state) {
    if(state.id != props.match.params.id || props.location.search !== state.search) {
      return {
        id: props.match.params.id,
        search: props.location.search,
      }
    }

    return null;
  }

  handleDataChange = (data)  => {
    if (!this.state.list) {
      this.setState(prevState => ({
        list: data
      }));
    }
  }

  render () {
    const { id, rumcs, list } = this.state;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('stats_path', { id })}>Весь отчёт</Link>
            <Link to={path('stats_rumcs_path', { id })}>РУМЦ</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Оценка деятельности румц
            </h1>
          </div>

          <div>
            <List {...this.props} onDataChange={this.handleDataChange} url={path('stats_rumcs_efficiency_path', { id: id }, true)} fields={['all']} />
          </div>

          {list &&
            <>
              <br/>
              <h2>Общая оценка РУМЦ</h2>
              <div>
                <ListRating list={list} fields={['title', 'rating']} sort='rating'/>
              </div>
              <br/>
              <h2>1. Консалтинг вузов</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_consulting']} sort='block_consulting'/>
              </div>
              <br/>
              <h2>2. Центр коллективного пользования</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_collective_usage']} sort='block_collective_usage'/>
              </div>
              <br/>
              <h2>3. Call-центр</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_calls_2018']} sort='block_calls_2018'/>
              </div>
              <br/>
              <h2>4. Семинары для сотрудников вузов</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_seminars']} sort='block_seminars'/>
              </div>
              <br/>
              <h2>5. Учебно-методические материалы</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_umms_2018']} sort='block_umms_2018'/>
              </div>
              <br/>
              <h2>7. Атлас профессий</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_professions']} sort='block_professions'/>
              </div>
              <br/>
              <h2>8. Проведение событий по профориентации</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_career_guide']} sort='block_career_guide'/>
              </div>
              <br/>
              <h2>9. Трудоустройство</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_employment_2018']} sort='block_employment_2018'/>
              </div>
              <br/>
              <h2>10. Развитие инклюзивного высшего образования</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_development']} sort='block_development'/>
              </div>
              <br/>
              <h2>11. Волонтерское движение</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_volunteering']} sort='block_volunteering'/>
              </div>
              <br/>
              <h2>13. Мониторинг образовательных потребностей</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_eduneeds']} sort='block_eduneeds'/>
              </div>
              <br/>
              <h2>16. Информационное сопровождение</h2>
              <div>
                <ListRating list={list} fields={['title', 'block_info']} sort='block_info'/>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}

export default Efficiency;
