import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames'

import I18n from './I18n'

import styles from './Filters.module.css'

Filters.propTypes = {
  filters: PropTypes.object
}

export default function Filters ({ filters }) {
  const location = useLocation()
  const history = useHistory()

  const [items, setItems] = useState()
  const [labels, setLabels] = useState()

  // const  = new Map()

  useEffect(() => {
    const newItems = new Map()
    const newLabels = new Map()

    Object.entries(filters).forEach(ob => {
      const filter = new Map()
      newLabels.set(ob[0], new Map())
      ob[1].forEach(el => {
        filter.set(el.id, false)
        newLabels.get(ob[0]).set(el.id, el.label || el.title)
      })

      newItems.set(ob[0], filter)
    })

    setItems(newItems)
    setLabels(newLabels)
  }, [])

  const [query, setQuery] = useState()

  useEffect(() => {
    setQuery(new URLSearchParams(location.search))
  }, [location.search])

  useEffect(() => {
    if (query) {
      const newItems = new Map(items)

      query.forEach((value, key) => {
        if (newItems.has(key)) {
          newItems.get(key).set(parseInt(value), true)
        }
      })
      setItems(newItems)
    }
  }, [query])

  const handleItemsChange = (id, idItems) => {
    const newItems = new Map(items)
    newItems.set(id, idItems)
    setItems(newItems)

    newItems.forEach((value, i) => {
      query.delete(i)

      value.forEach((active, j) => {
        if (active) {
          query.append(i, j)
        }
      })
    })

    history.push(location.pathname + '?' + query.toString())
  }

  return (
    <div>
      {items &&
        <div>
          {[...items.keys()].map(id =>
            <Filter
              key={id}
              id={id}
              items={items.get(id)}
              onItemsChange={handleItemsChange}
              labels={labels.get(id)}
            />
          )}
        </div>
      }
    </div>
  )
}

Filter.propTypes = {
  id: PropTypes.string,
  labels: PropTypes.object,
  items: PropTypes.object,
  onItemsChange: PropTypes.func
}

function Filter ({ id, items, labels, onItemsChange }) {
  const [active, setActive] = useState(false)

  const handleClick = key => {
    const newItems = new Map(items)
    newItems.set(key, !items.get(key))

    onItemsChange && onItemsChange(id, newItems)
  }

  return (
    <div className={styles.filter}>
      <div className={styles.title} onClick={() => setActive(!active)}>
        {I18n.t(`filters.${id}`)}
      </div>

      {active &&
        <ul className={styles.items}>
          {[...items.keys()].map(key =>
            <li key={key} className={classNames(styles.item, { [styles.selected]: items.get(key) })}>
              <label>
                <input
                  checked={items.get(key)}
                  type="checkbox"
                  name={id}
                  onChange={() => handleClick(key)}
                />
                {labels.get(key)}
              </label>
            </li>
          )}
        </ul>
      }
    </div>
  )
}
