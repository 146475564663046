import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
// import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import ReactMarkdown from 'react-markdown'

import Link, { path } from '../../Routes'
import I18n from '../../I18n'
import Loaf from '../../Loaf/Loaf'

import styles from './Show.module.css'
import page from '../../Page.module.css'

export default function Show () {
  const { rumc_id: rumcId, id } = useParams()
  const cancelToken = useRef(axios.CancelToken.source())
  const [rumc, setRumc] = useState()
  const [facility, setFacility] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('rumc_facility_path', { rumc_id: rumcId, id }, true), { cancelToken: cancelToken.current.token })

      setRumc(data.rumc)
      setFacility(data.facility)
    }

    _fetch()

    return () => {
      cancelToken.current.cancel()
    }
  }, [rumcId, id])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="rumcs_path">{I18n.t('nav.rumcs')}</Link>
          {rumc &&
            <>
              <Link to="rumc_path" params={{ id: rumc.id }}>{rumc.title}</Link>
              <Link to="rumc_facilities_path" params={{ rumc_id: rumc.id }}>{I18n.t('rumc.facilities.title')}</Link>
            </>
          }
        </Loaf>

        {facility &&
          <div className={styles.root}>
            <div className={page.title}>
              <h1>
                {facility.title}
              </h1>

              <Helmet>
                <title>{facility.title}</title>
              </Helmet>
            </div>

            {facility.desc &&
              <div className={styles.desc}>
                <ReactMarkdown source={facility.desc} />
              </div>
            }

            {facility.facility_type && facility.facility_type.youtube_id &&
              <div className={styles.video}>
                <h2>
                  Инструкция
                </h2>

                <div className={styles.youtube}>
                  <iframe
                    src={`//youtube.com/embed/${facility.facility_type.youtube_id}?modestbranding=1&autohide=1&showinfo=0&rel=0&cc_load_policy=1`}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  />
                </div>

                <p className={styles.type}>
                  Видеоинструкция может содержать схожую модель, поэтому немного отличаться.
                </p>
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}
