import React, { useContext, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import Current from '../Current'

import { Routes, path } from '../Routes'

import RumcSchools from './RumcSchools/RumcSchools'
import ReportItems from './ReportItems/ReportItems'
// import TargetDocuments from './TargetDocuments/TargetDocuments'
import TargetPrograms from './TargetPrograms/TargetPrograms'
import Events from './Events/Events'
import Umms from './Umms/Umms'
import Stories from './Stories/Stories'
import Courses from './Courses/Courses'
import Careers from './Careers/Careers'
import Rumcs from './Rumcs/Rumcs'

import Plans from './Plans/Plans'
import PlanModules from './PlanModules/PlanModules'
import PlanThemes from './PlanThemes/PlanThemes'

import Stages from './Stages/Stages'

import Facilities from './Facilities/Facilities'
import FacilityTypes from './FacilityTypes/FacilityTypes'
import EventsStat from './EventsStat'
import Index from './Index'
// import Inquiries from './Inquiries/Inquiries'
import Settings from './Settings'
import Password from './Password'

export default function Account () {
  const history = useHistory()
  const { user } = useContext(Current)

  useEffect(() => {
    !user && history.push(path('root_path'))
  }, [user])

  return (
    <Switch>
      <Route
        path={[
          Routes.edit_account_plan_theme_path,
          Routes.account_plan_theme_path,
          Routes.new_account_plan_plan_theme_path
        ]}
      >
        <PlanThemes />
      </Route>

      <Route
        path={[
          Routes.edit_account_plan_module_path,
          Routes.account_plan_module_path,
          Routes.new_account_plan_plan_module_path
        ]}
      >
        <PlanModules />
      </Route>

      <Route path={Routes.account_plans_path}>
        <Plans />
      </Route>

      <Route path={Routes.account_facilities_path}>
        <Facilities />
      </Route>

      <Route path={Routes.account_stages_path}>
        <Stages />
      </Route>

      <Route path={Routes.account_facility_types_path}>
        <FacilityTypes />
      </Route>

      <Route path={Routes.settings_path}>
        <Settings />
      </Route>

      <Route path={Routes.password_path}>
        <Password />
      </Route>

      {/* <Route path={decodeURIComponent(Routes.account_target_documents_path)} component={TargetDocuments} /> */}
      <Route path={decodeURIComponent(Routes.account_target_programs_path)} component={TargetPrograms} />
      <Route path={decodeURIComponent(Routes.account_rumc_schools_path)} component={RumcSchools} />
      <Route path={decodeURIComponent(Routes.account_umms_path)} component={Umms} />
      <Route path={decodeURIComponent(Routes.account_stories_path)} component={Stories} />
      <Route path={decodeURIComponent(Routes.account_courses_path)} component={Courses} />
      <Route path={decodeURIComponent(Routes.account_careers_path)} component={Careers} />
      <Route path={decodeURIComponent(Routes.account_events_path)} component={Events} />
      <Route path={decodeURIComponent(Routes.account_rumcs_path)} component={Rumcs} />
      <Route path={decodeURIComponent(Routes.account_events_stat_path)} component={EventsStat} />
      <Route exact path={decodeURIComponent(Routes.account_path)} component={Index} />
      {/* <Route path={decodeURIComponent(Routes.account_inquiries_path)} component={Inquiries} /> */}
      <Route path={decodeURIComponent(Routes.account_report_items_path)} component={ReportItems} />
    </Switch>
  )
}
