import React from 'react'
import PropTypes from 'prop-types'

import Datec from '../Datec'
import Link, { path } from '../Routes'

import styles from '../News/News.module.css'
import classNames from 'classnames'

class Card extends React.Component {
    render () {
      const { id, created_at, name, date } = this.props
  
      return (
        <div className={styles.newsBlock}>
          <Link to="news_path" params={{ id: id }} className={classNames(styles.newsLink, styles.a_in_block)}>
            <div className={styles.news_date}>
              {date &&
                <div>
                  {date}
                  <meta itemprop="datePublished" content={created_at}></meta>
                </div>
              }
              {!date &&
                <Datec time={created_at} />
              }
            </div>
            <div className={styles.news_title}>{name}</div>
          </Link>
        </div>
      )
    }
}

Card.propTypes = {
    created_at: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number
}

export default Card