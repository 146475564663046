import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import classNames from 'classnames'

import I18n from '../../I18n'

import styles from './Targets.module.css'

Targets.propTypes = {
  targets: PropTypes.object
}

export default function Targets (props) {
  const { targets } = props

  return (
    <div className={styles.root}>
      <h3>
        Госзадание
      </h3>

      <p className={styles.desc}>
        Общей процесс выполнения госзадания ресурсными центрами.
      </p>

      <Target {...targets.rumc_schools} id="rumc_schools" rumcs={targets.rumcs} />

      <Target {...targets.careers} id="careers" rumcs={targets.rumcs} />

      <Target {...targets.umms} id="umms" rumcs={targets.rumcs} />

      <Target {...targets.courses} id="courses" rumcs={targets.rumcs} />

      <Target {...targets.events} id="events" rumcs={targets.rumcs} />
    </div>
  )
}

Target.propTypes = {
  rumcs: PropTypes.array,
  ok: PropTypes.number,
  rest: PropTypes.number,
  id: PropTypes.string
}

function Target (props) {
  const { ok, rest, id, rumcs } = props

  const [active, setActive] = useState(false)

  return (
    <>
      <div className={styles.row}>
        <div className={styles.values}>
          <div className={styles.main}>
            {ok}
          </div>

          {!!rest &&
            <div className={styles.secondary}>
              (+{rest} обр.)
            </div>
          }
        </div>

        <div className={styles.label}>
          <div>
            {I18n.t(`statistics.${id}.title`)}
          </div>

          <div className={styles.toggle} onClick={() => setActive(!active)}><span>по РУМЦ</span></div>
        </div>
      </div>

      {active &&
        <div className={styles.rumcs}>
          {rumcs.filter(rumc => !!rumc[id].ok || !!rumc[id].rest).map(rumc =>
            <div className={styles.rumc} key={rumc.id}>
              <div className={styles.values}>
                {rumc[id].ok}

                {!!rumc[id].rest &&
                  <span className={styles.secondary}>
                    (+{rumc[id].rest} обр.)
                  </span>
                }
              </div>
              <div className={styles.label}>{rumc.title_last}</div>
            </div>
          )}
        </div>
      }
    </>
  )
}
