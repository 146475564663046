import React, { useState, useEffect, useRef } from 'react'
// import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'

import page from '../../Page.module.css'
import styles from './Schools.module.css'

export default function Schools () {
  const cancelToken = useRef(axios.CancelToken.source())
  const search = useLocation().search
  const query = new Map(new URLSearchParams(useLocation().search))

  const [directions, setDirections] = useState()
  const [fields, setFields] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(
        path('statistics_reports_programs_path', {}, true),
        {
          params: {
            monitoring: query.get('monitoring')
          }
        },
        { cancelToken: cancelToken.current.token }
      )

      setDirections(data.directions)
      setFields(data.fields)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [search])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="statistics_path">{I18n.t('statistics.title')}</Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('statistics.reports.programs.title')}
          </h1>

          <Helmet>
            <title>{I18n.t('statistics.reports.programs.title')}</title>
          </Helmet>
        </div>

        {directions &&
          <table className={styles.table}>
            <thead>
              <tr>
                <td colSpan="2">
                  Направление
                </td>

                <td rowSpan="2">
                  Шифр
                </td>

                <td rowSpan="2">
                  Уровень
                </td>

                {['abitur', 'submit', 'student', 'graduate'].map(type =>
                  <React.Fragment key={type}>
                    <td rowSpan="2">
                      {I18n.t(`stats.${type}.ovall`)}
                    </td>

                    <td rowSpan="2">
                      {I18n.t(`stats.${type}.disabled`)}
                    </td>

                    {['e', 'h', 'nm', 'mm', 's', 'p', 'o'].map(nosology =>
                      <React.Fragment key={nosology}>
                        {['f', 'p', 'fp'].map(ed =>
                          <td key={ed} rowSpan="2">
                            {I18n.t(`stats.${type}.${nosology}`)}
                            <br />
                            {I18n.t(`stats.eds.${ed}`)}
                          </td>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </tr>

              <tr>
                <td>ID</td>
                <td className={styles.title}>Название</td>
              </tr>
            </thead>
            <tbody>
              {directions.map(direction =>
                <tr key={direction.id}>
                  <td>
                    {direction.id}
                  </td>

                  <td>
                    {direction.title}
                  </td>

                  <td>
                    {direction.code}
                  </td>

                  <td>
                    {I18n.t(`reports.headers.programs.${direction.form}`)}
                  </td>

                  {['abitur', 'submit', 'student', 'graduate'].map(type =>
                    <React.Fragment key={type}>
                      <td>
                        {direction[`${type}_ovall`]}
                      </td>

                      <td>
                        {direction[`${type}_disabled`]}
                      </td>

                      {['e', 'h', 'nm', 'mm', 's', 'p', 'o'].map(nosology =>
                        <React.Fragment key={nosology}>
                          {['f', 'p', 'fp'].map(ed =>
                            <td key={ed}>
                              {direction[`${type}_${nosology}_${ed}`]}
                            </td>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        }
      </div>
    </div>
  )
}
