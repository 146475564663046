import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';

import VerificateInput from './VerificateInput';

import styles from '../Section.module.css';
import form from '../../Form.module.css';

class Adaptation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      values: [
        'count',
        'count_e',
        'count_h',
        'count_m',
        'count_o',
        'nal_mod',
        'nal_ch',
        'nal_in',
        'nal_prc',
        'nal_int',
        'meth',
        'meth_count',
      ].reduce((acc, e) => {
        acc[e] = (this.props.values[this.props.prefix] && this.props.values[this.props.prefix][e]) || '';
        return acc;
      }, {}),
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      [name]: {
        $set: value
      }
    });

    this.setState({ values });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.onValuesChange) this.props.onValuesChange(this.props.prefix, this.state.values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state != nextState);
  }

  render () {
    const { values, show } = this.state;
    const { num } = this.props;

    const errors = {
      'err_count': ~~parseInt(values.count) < ~~parseInt(values.count_e) + ~~parseInt(values.count_h) + ~~parseInt(values.count_m) + ~~parseInt(values.count_o),
    };

    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Адаптация образовательных программ и учебно-методического обеспечения обучения лиц с ОВЗ и инвалидностью

          <div className={classNames(styles.label, { [styles.ok]: Object.values(values).filter(v => v).length == Object.values(values).length })}>
            {Object.values(values).filter(v => v).length} из {Object.values(values).length}
          </div>
        </div>

        <div className={styles.content}>
          <div className={form.elem}>
            <div className={form.label}>
              {num}.1. Общее число адаптированных образовательных программ

              {errors['err_count'] &&
                <div className={form.error}>Ошибка в данных об общем количестве адаптированных образовательных программ</div>
              }
            </div>

            <div className={form.input}>
              <VerificateInput name="count" value={values.count} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.1.1. Число адаптированных образовательных программ для лиц с нарушениями зрения
            </div>

            <div className={form.input}>
              <VerificateInput name="count_e" value={values.count_e} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.1.2. Число адаптированных образовательных программ для лиц с нарушениями слуха
            </div>

            <div className={form.input}>
              <VerificateInput name="count_h" value={values.count_h} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.1.3. Число адаптированных образовательных программ для лиц с нарушениями опорно-двигательного аппарата
            </div>

            <div className={form.input}>
              <VerificateInput name="count_m" value={values.count_m} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.1.4. Число адаптированных образовательных программ для лиц других нозологических групп
            </div>

            <div className={form.input}>
              <VerificateInput name="count_o" value={values.count_o} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.desc}>
            {num}.2. Наличие в адаптированной образовательной программе элементов, учитывающих форму нозологии:
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="nal_mod" checked={values.nal_mod} onChange={this.handleInputChange} />
                {num}.2.1. Наличие адаптационных модулей
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="nal_ch" checked={values.nal_ch} onChange={this.handleInputChange} />
                {num}.2.2. Выбор методов обучения, доступных для обучающихся с ОВЗ и инвалидностью в соответствии с нозологией
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="nal_in" checked={values.nal_in} onChange={this.handleInputChange} />
                {num}.2.3. Включение индивидуальных учебных планов и графиков для обучающихся с ОВЗ и инвалидностью
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="nal_prc" checked={values.nal_prc} onChange={this.handleInputChange} />
                {num}.2.4. Наличие адаптированной программы практики
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="nal_int" checked={values.nal_int} onChange={this.handleInputChange} />
                {num}.2.5. Наличие адаптированной программы промежуточной и итоговой аттестации
              </label>
            </div>
          </div>

          <hr />

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="meth" checked={values.meth} onChange={this.handleInputChange} />
                {num}.3. Адаптированная образовательная программа обеспечена учебниками и учебно-методическими пособиями, в т.ч. библиотечными ресурсами, в соответствии с формой нозологии
              </label>
            </div>
          </div>


          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.1. Количество адаптированных онлайн курсов, используемых в процессе обучения лиц с ОВЗ и инвалидностью
            </div>

            <div className={form.input}>
              <VerificateInput name="meth_count" value={values.meth_count} onChange={this.handleInputChange} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Adaptation.defaultProps = {
  prefix: 'adaptation',
  num: '8'
};

export default Adaptation;
