import React from 'react'
// import PropTypes from 'prop-types'

import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import TermsRumcUmm from './TermsRumcUmm'
import TermsRumcExperts from './TermsRumcExperts'
import TermsRumcCareer from './TermsRumcCareer'
import TermsRumcFacilities from './TermsRumcFacilities'
import TermsRumcForms from './TermsRumcForms'

class TermsRumc extends React.Component {
  render () {
    console.log(`${Routes.terms_path}/румц/умм`)

    return (
      <Switch>
        <Route path={`${Routes.terms_path}/румц/умм`} component={TermsRumcUmm} />
        <Route path={`${Routes.terms_path}/румц/профессии`} component={TermsRumcCareer} />
        <Route path={`${Routes.terms_path}/румц/эксперты`} component={TermsRumcExperts} />
        <Route path={`${Routes.terms_path}/румц/цкд`} component={TermsRumcFacilities} />
        <Route path={`${Routes.terms_path}/румц/анкеты`} component={TermsRumcForms} />
      </Switch>
    )
  }
}

export default TermsRumc
