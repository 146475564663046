import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Routes } from '../Routes.js.erb';
import Loaf from '../Loaf/Loaf';

import page from '../Page.module.css';

class TermsRumcExperts extends React.Component {
  render() {
    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={Routes.terms_path}>Правила</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Утверждение экспертами
            </h1>

            <Helmet>
              <title>
                Утверждение экспертами
              </title>
            </Helmet>
          </div>

          <div className={page.text}>
            <p>
              Все учебно-методические материалы и профессии из «Атласа профессий» (далее в тексте они будут называться контент) обязательно проходят этап согласования экспертами — статус «На согласовании». Когда контент попадает в этот статус ему автоматически назначаются два эксперта из других ресурсных центров или эксперты не привязанные к ресурсным центрам. Им приходят уведомления что им нужно изучить контент и выставить свою оценку. Пока эксперты не дадут своего заключения — автор не сможем перевести контент в статус «Готов». Автор не знает кого из экспертов ему назначили, но эксперты могут комментировать чтобы автор исправил те или иные вещи в контенте, но комментарии экспертов на этом шаге будут анонимными.
            </p>

            <p>
              Эксперты оценивают контент только в том случае если он их удовлетворяет. Если есть явные проблемы, то сначала нужно сообщить автору чтобы он их устранил и только после этого дать заключение.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsRumcExperts;
