import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';

import VerificateInput from './VerificateInput';

import styles from '../Section.module.css';
import form from '../../Form.module.css';

class Post extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      values: [
        'prg',
        'cnt',
        'ovall',
      ].reduce((acc, e) => {
        acc[e] = (this.props.values[this.props.prefix] && this.props.values[this.props.prefix][e]) || '';
        return acc;
      }, {}),
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      [name]: {
        $set: value
      }
    });

    this.setState({ values });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.onValuesChange) this.props.onValuesChange(this.props.prefix, this.state.values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state != nextState);
  }

  render () {
    const { values, show } = this.state;
    const { num } = this.props;

    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Обеспеченность постдипломного сопровождения

          <div className={classNames(styles.label, { [styles.ok]: Object.values(values).filter(v => v).length == Object.values(values).length })}>
            {Object.values(values).filter(v => v).length} из {Object.values(values).length}
          </div>
        </div>

        <div className={styles.content}>
          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="prg" checked={values.prg} onChange={this.handleInputChange} />
                {num}.1. Утверждена программа постдипломного сопровождения лиц с ОВЗ и инвалидностью, завершивших обучение по программам ВО (не менее 3-х лет после окончания вуза)
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2. Численность выпускников с ОВЗ и инвалидностью, использующих программы постдипломного сопровождения
            </div>

            <div className={form.input}>
              <VerificateInput name="cnt" value={values.cnt} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3. Общее число членов с ОВЗ и инвалидностью в ассоциации выпускников вуза
            </div>

            <div className={form.input}>
              <VerificateInput name="ovall" value={values.ovall} onChange={this.handleInputChange} />
            </div>
          </div>

        </div>
      </div>
    );
  }
}

Post.defaultProps = {
  prefix: 'post',
  num: '11'
};

export default Post;
