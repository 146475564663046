import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import classNames from 'classnames'

import Link, { path } from '../Routes'
import I18n from '../I18n'

import Loaf from '../Loaf/Loaf'

import page from '../Page.module.css'
import styles from './Archive.module.css'

class ArchiveRUMC extends Component {
    state = {
        active_year: 2021
    }
    render () {
        return (
            <div className={page.wrapper}>
                <div className={page.container}>
                    <Loaf>
                        <Link to="events_path">{I18n.t('events.title')}</Link>
                    </Loaf>

                    <div className={page.title}>
                        <h1>
                            График мероприятий РУМЦ (архив)
                        </h1>

                        <Helmet>
                        <title>
                            График мероприятий РУМЦ (архив)
                        </title>
                        </Helmet>
                        
                        <div className={styles.years_wrapper}>
                            <div className={styles.rumc_archive_year}>
                                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.57275 25.6658C4.73331 26.6245 5.54402 27.3287 6.51235 27.4133C9.38071 27.664 12.3828 28.0013 15.4741 28.0013C18.5935 28.0013 21.5999 27.789 24.504 27.4669C25.4404 27.363 26.1972 26.6545 26.349 25.7247C26.7079 23.5279 26.9481 21.2518 26.9481 18.898C26.9481 16.5686 26.7363 14.2894 26.3743 12.1241C26.2145 11.1682 25.4097 10.4651 24.4427 10.4005C22.1757 10.2489 18.063 10 15.5 10C12.3614 10 9.28293 10.0371 6.37649 10.3344C5.472 10.4269 4.73934 11.1038 4.58326 11.9995C4.20027 14.1975 4 16.5254 4 18.898C4 21.2315 4.20757 23.4852 4.57275 25.6658Z" fill="#FFDD09" fillOpacity="0.2"/>
                                    <path d="M26.3743 12.1241L27.8538 11.8767L26.3743 12.1241ZM24.504 27.4669L24.3386 25.9761L24.504 27.4669ZM26.349 25.7247L24.8687 25.4828L26.349 25.7247ZM6.51235 27.4133L6.64295 25.919L6.51235 27.4133ZM15.4741 26.5013C12.4678 26.5013 9.56069 26.174 6.64295 25.919L6.38175 28.9076C9.20074 29.154 12.2979 29.5013 15.4741 29.5013V26.5013ZM24.3386 25.9761C21.4752 26.2937 18.5256 26.5013 15.4741 26.5013V29.5013C18.6614 29.5013 21.7246 29.2844 24.6694 28.9578L24.3386 25.9761ZM27.8294 25.9665C28.1975 23.713 28.4481 21.3533 28.4481 18.898H25.4481C25.4481 21.1502 25.2182 23.3427 24.8687 25.4828L27.8294 25.9665ZM28.4481 18.898C28.4481 16.4784 28.2282 14.1163 27.8538 11.8767L24.8949 12.3714C25.2445 14.4626 25.4481 16.6588 25.4481 18.898H28.4481ZM15.5 11.5C18.005 11.5 22.0684 11.7451 24.3426 11.8971L24.5428 8.90382C22.283 8.75272 18.1211 8.5 15.5 8.5V11.5ZM6.52912 11.8266C9.35218 11.5378 12.363 11.5 15.5 11.5V8.5C12.3598 8.5 9.21368 8.53636 6.22387 8.84216L6.52912 11.8266ZM3.10553 11.742C2.70719 14.0281 2.5 16.4422 2.5 18.898H5.5C5.5 16.6086 5.69334 14.367 6.061 12.257L3.10553 11.742ZM2.5 18.898C2.5 21.321 2.71558 23.6578 3.09336 25.9136L6.05215 25.4181C5.69955 23.3126 5.5 21.142 5.5 18.898H2.5ZM27.8538 11.8767C27.5749 10.2088 26.1766 9.01307 24.5428 8.90382L24.3426 11.8971C24.6427 11.9172 24.8541 12.1276 24.8949 12.3714L27.8538 11.8767ZM24.6694 28.9578C26.2643 28.7809 27.5674 27.5708 27.8294 25.9665L24.8687 25.4828C24.827 25.7382 24.6165 25.9452 24.3386 25.9761L24.6694 28.9578ZM6.22387 8.84216C4.65712 9.00241 3.378 10.1784 3.10553 11.742L6.061 12.257C6.10069 12.0292 6.28687 11.8514 6.52912 11.8266L6.22387 8.84216ZM6.64295 25.919C6.30999 25.8899 6.09235 25.6581 6.05215 25.4181L3.09336 25.9136C3.37426 27.5909 4.77804 28.7675 6.38175 28.9076L6.64295 25.919Z" fill="#FFDD09"/>
                                    <path d="M11.9846 16H18.9738" stroke="#FFDD09" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M2.48541 8.79509C2.76811 9.56513 3.5506 10.003 4.37076 9.98898C11.6946 9.86354 19.1173 9.99188 26.7304 10.0124C27.5605 10.0146 28.3384 9.54843 28.6219 8.7682C28.9274 7.92742 29.1205 7.06201 29.1205 6.17239C29.1205 5.24059 28.9299 4.32633 28.5969 3.44896C28.3262 2.73549 27.621 2.30561 26.8587 2.27025C23.2165 2.10128 19.474 2 15.5603 2C11.6399 2 7.82372 2.09526 4.22322 2.26919C3.48184 2.305 2.79197 2.71431 2.52378 3.4064C2.17914 4.29575 2 5.22631 2 6.17239C2 7.07188 2.17351 7.94551 2.48541 8.79509Z" fill="#FFDD09" fillOpacity="0.2"/>
                                    <path d="M28.5969 3.44896L27.1945 3.98115L28.5969 3.44896ZM28.6219 8.7682L27.2121 8.25591L28.6219 8.7682ZM2.52378 3.4064L1.12512 2.8644L2.52378 3.4064ZM4.37076 9.98898L4.34507 8.4892L4.37076 9.98898ZM30.0317 9.28049C30.3799 8.32221 30.6205 7.28033 30.6205 6.17239H27.6205C27.6205 6.84368 27.4749 7.53262 27.2121 8.25591L30.0317 9.28049ZM30.6205 6.17239C30.6205 5.03411 30.3874 3.93948 29.9993 2.91677L27.1945 3.98115C27.4723 4.71317 27.6205 5.44707 27.6205 6.17239H30.6205ZM15.5603 3.5C19.4479 3.5 23.167 3.6006 26.7892 3.76863L26.9282 0.771857C23.266 0.601965 19.5002 0.5 15.5603 0.5V3.5ZM4.29559 3.76744C7.87181 3.59468 11.6639 3.5 15.5603 3.5V0.5C11.616 0.5 7.77563 0.595832 4.15084 0.770937L4.29559 3.76744ZM1.12512 2.8644C0.715775 3.92074 0.5 5.03475 0.5 6.17239H3.5C3.5 5.41786 3.64251 4.67075 3.92243 3.94841L1.12512 2.8644ZM0.5 6.17239C0.5 7.26867 0.712072 8.3172 1.0773 9.31204L3.89352 8.27814C3.63494 7.57382 3.5 6.87509 3.5 6.17239H0.5ZM26.7344 8.51239C19.1514 8.49197 11.6881 8.36343 4.34507 8.4892L4.39645 11.4888C11.7011 11.3636 19.0833 11.4918 26.7263 11.5124L26.7344 8.51239ZM29.9993 2.91677C29.4796 1.5472 28.1764 0.829762 26.9282 0.771857L26.7892 3.76863C26.9149 3.77446 27.0183 3.81274 27.0873 3.85818C27.1517 3.90054 27.1802 3.94359 27.1945 3.98115L29.9993 2.91677ZM27.2121 8.25591C27.177 8.35264 27.0326 8.5132 26.7344 8.51239L26.7263 11.5124C28.0884 11.5161 29.4998 10.7442 30.0317 9.28049L27.2121 8.25591ZM4.15084 0.770937C2.94149 0.829358 1.64917 1.51209 1.12512 2.8644L3.92243 3.94841C3.93229 3.92295 3.95323 3.8884 4.01112 3.85093C4.07422 3.81009 4.17278 3.77338 4.29559 3.76744L4.15084 0.770937ZM1.0773 9.31204C1.62237 10.7967 3.06999 11.5115 4.39645 11.4888L4.34507 8.4892C4.20476 8.49161 4.08703 8.45405 4.00922 8.40646C3.93762 8.36266 3.90801 8.31762 3.89352 8.27814L1.0773 9.31204Z" fill="#FFDD09"/>
                                </svg>
                                <span>2021</span>
                            </div>
                            {/* <div className={classNames(styles.rumc_archive_year, styles.rumc_archive_text_disabled)}>
                                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.57275 25.6658C4.73331 26.6245 5.54402 27.3287 6.51235 27.4133C9.38071 27.664 12.3828 28.0013 15.4741 28.0013C18.5935 28.0013 21.5999 27.789 24.504 27.4669C25.4404 27.363 26.1972 26.6545 26.349 25.7247C26.7079 23.5279 26.9481 21.2518 26.9481 18.898C26.9481 16.5686 26.7363 14.2894 26.3743 12.1241C26.2145 11.1682 25.4097 10.4651 24.4427 10.4005C22.1757 10.2489 18.063 10 15.5 10C12.3614 10 9.28293 10.0371 6.37649 10.3344C5.472 10.4269 4.73934 11.1038 4.58326 11.9995C4.20027 14.1975 4 16.5254 4 18.898C4 21.2315 4.20757 23.4852 4.57275 25.6658Z" fill="#F2F2F2" fillOpacity="0.2"/>
                                    <path d="M26.3743 12.1241L27.8538 11.8767L26.3743 12.1241ZM24.504 27.4669L24.3386 25.9761L24.504 27.4669ZM26.349 25.7247L24.8687 25.4828L26.349 25.7247ZM6.51235 27.4133L6.64295 25.919L6.51235 27.4133ZM15.4741 26.5013C12.4678 26.5013 9.56069 26.174 6.64295 25.919L6.38175 28.9076C9.20074 29.154 12.2979 29.5013 15.4741 29.5013V26.5013ZM24.3386 25.9761C21.4752 26.2937 18.5256 26.5013 15.4741 26.5013V29.5013C18.6614 29.5013 21.7246 29.2844 24.6694 28.9578L24.3386 25.9761ZM27.8294 25.9665C28.1975 23.713 28.4481 21.3533 28.4481 18.898H25.4481C25.4481 21.1502 25.2182 23.3427 24.8687 25.4828L27.8294 25.9665ZM28.4481 18.898C28.4481 16.4784 28.2282 14.1163 27.8538 11.8767L24.8949 12.3714C25.2445 14.4626 25.4481 16.6588 25.4481 18.898H28.4481ZM15.5 11.5C18.005 11.5 22.0684 11.7451 24.3426 11.8971L24.5428 8.90382C22.283 8.75272 18.1211 8.5 15.5 8.5V11.5ZM6.52912 11.8266C9.35218 11.5378 12.363 11.5 15.5 11.5V8.5C12.3598 8.5 9.21368 8.53636 6.22387 8.84216L6.52912 11.8266ZM3.10553 11.742C2.70719 14.0281 2.5 16.4422 2.5 18.898H5.5C5.5 16.6086 5.69334 14.367 6.061 12.257L3.10553 11.742ZM2.5 18.898C2.5 21.321 2.71558 23.6578 3.09336 25.9136L6.05215 25.4181C5.69955 23.3126 5.5 21.142 5.5 18.898H2.5ZM27.8538 11.8767C27.5749 10.2088 26.1766 9.01307 24.5428 8.90382L24.3426 11.8971C24.6427 11.9172 24.8541 12.1276 24.8949 12.3714L27.8538 11.8767ZM24.6694 28.9578C26.2643 28.7809 27.5674 27.5708 27.8294 25.9665L24.8687 25.4828C24.827 25.7382 24.6165 25.9452 24.3386 25.9761L24.6694 28.9578ZM6.22387 8.84216C4.65712 9.00241 3.378 10.1784 3.10553 11.742L6.061 12.257C6.10069 12.0292 6.28687 11.8514 6.52912 11.8266L6.22387 8.84216ZM6.64295 25.919C6.30999 25.8899 6.09235 25.6581 6.05215 25.4181L3.09336 25.9136C3.37426 27.5909 4.77804 28.7675 6.38175 28.9076L6.64295 25.919Z" fill="#F2F2F2"/>
                                    <path d="M12 16H19" stroke="#F2F2F2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M2.48541 8.79509C2.76811 9.56513 3.5506 10.003 4.37076 9.98898C11.6946 9.86354 19.1173 9.99188 26.7304 10.0124C27.5605 10.0146 28.3384 9.54843 28.6219 8.7682C28.9274 7.92742 29.1205 7.06201 29.1205 6.17239C29.1205 5.24059 28.9299 4.32633 28.5969 3.44896C28.3262 2.73549 27.621 2.30561 26.8587 2.27025C23.2165 2.10128 19.474 2 15.5603 2C11.6399 2 7.82372 2.09526 4.22322 2.26919C3.48184 2.305 2.79197 2.71431 2.52378 3.4064C2.17914 4.29575 2 5.22631 2 6.17239C2 7.07188 2.17351 7.94551 2.48541 8.79509Z" fill="#F2F2F2" fillOpacity="0.2"/>
                                    <path d="M28.5969 3.44896L27.1945 3.98115L28.5969 3.44896ZM28.6219 8.7682L27.2121 8.25591L28.6219 8.7682ZM2.52378 3.4064L1.12512 2.8644L2.52378 3.4064ZM4.37076 9.98898L4.34507 8.4892L4.37076 9.98898ZM30.0317 9.28049C30.3799 8.32221 30.6205 7.28033 30.6205 6.17239H27.6205C27.6205 6.84368 27.4749 7.53262 27.2121 8.25591L30.0317 9.28049ZM30.6205 6.17239C30.6205 5.03411 30.3874 3.93948 29.9993 2.91677L27.1945 3.98115C27.4723 4.71317 27.6205 5.44707 27.6205 6.17239H30.6205ZM15.5603 3.5C19.4479 3.5 23.167 3.6006 26.7892 3.76863L26.9282 0.771857C23.266 0.601965 19.5002 0.5 15.5603 0.5V3.5ZM4.29559 3.76744C7.87181 3.59468 11.6639 3.5 15.5603 3.5V0.5C11.616 0.5 7.77563 0.595832 4.15084 0.770937L4.29559 3.76744ZM1.12512 2.8644C0.715775 3.92074 0.5 5.03475 0.5 6.17239H3.5C3.5 5.41786 3.64251 4.67075 3.92243 3.94841L1.12512 2.8644ZM0.5 6.17239C0.5 7.26867 0.712072 8.3172 1.0773 9.31204L3.89352 8.27814C3.63494 7.57382 3.5 6.87509 3.5 6.17239H0.5ZM26.7344 8.51239C19.1514 8.49197 11.6881 8.36343 4.34507 8.4892L4.39645 11.4888C11.7011 11.3636 19.0833 11.4918 26.7263 11.5124L26.7344 8.51239ZM29.9993 2.91677C29.4796 1.5472 28.1764 0.829762 26.9282 0.771857L26.7892 3.76863C26.9149 3.77446 27.0183 3.81274 27.0873 3.85818C27.1517 3.90054 27.1802 3.94359 27.1945 3.98115L29.9993 2.91677ZM27.2121 8.25591C27.177 8.35264 27.0326 8.5132 26.7344 8.51239L26.7263 11.5124C28.0884 11.5161 29.4998 10.7442 30.0317 9.28049L27.2121 8.25591ZM4.15084 0.770937C2.94149 0.829358 1.64917 1.51209 1.12512 2.8644L3.92243 3.94841C3.93229 3.92295 3.95323 3.8884 4.01112 3.85093C4.07422 3.81009 4.17278 3.77338 4.29559 3.76744L4.15084 0.770937ZM1.0773 9.31204C1.62237 10.7967 3.06999 11.5115 4.39645 11.4888L4.34507 8.4892C4.20476 8.49161 4.08703 8.45405 4.00922 8.40646C3.93762 8.36266 3.90801 8.31762 3.89352 8.27814L1.0773 9.31204Z" fill="#F2F2F2"/>
                                </svg>
                                <span>2022</span>
                            </div> */}
                        </div>

                        <div className={styles.rumc_archive_text}>График мероприятий РУМЦ, направленных на реализацию региональных программ сопровождения инвалидов молодого возраста при получении ими профессионального образования и содействия в последующем трудоустройстве</div>


                        <a href="/uploads/document/press_reliz/schedule.xlsx" target="_blank" rel="noopener noreferrer" className={styles.file}>
                            <svg viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_109_3809)">
                                    <path d="M15.1865 7.82929L8.67696 14.3389C7.87949 15.1363 6.79789 15.5844 5.67009 15.5844C4.54229 15.5844 3.46069 15.1363 2.66321 14.3389C1.86574 13.5414 1.41772 12.4598 1.41772 11.332C1.41772 10.2042 1.86574 9.1226 2.66321 8.32512L8.73363 2.25471C9.26528 1.72212 9.98672 1.42254 10.7393 1.42188C11.4918 1.42121 12.2138 1.71952 12.7463 2.25116C13.2789 2.78281 13.5785 3.50426 13.5792 4.25678C13.5798 5.00931 13.2815 5.73128 12.7499 6.26387L6.6653 12.3343C6.39947 12.6001 6.03894 12.7495 5.663 12.7495C5.28707 12.7495 4.92654 12.6001 4.66071 12.3343C4.39489 12.0685 4.24555 11.7079 4.24555 11.332C4.24555 10.9561 4.39489 10.5955 4.66071 10.3297L10.6745 4.32304" stroke="#ACAFB8" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_109_3809">
                                        <rect width="17" height="17" fill="white" transform="translate(0 0.00195312)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span>График мероприятий РУМЦ, 2021.xlsx</span>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default ArchiveRUMC
