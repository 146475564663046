import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import classNames from 'classnames'

import ReactMarkdown from 'react-markdown'

import { CommentsList } from '../Comments'

import Loaf from '../Loaf/Loaf'
import Link, { path } from '../Routes'
import I18n from '../I18n'
// import Rate from '../Rate/Rate'
import Datec from '../Datec'
import { Errors } from '../Form'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import form from '../Form.module.css'
import styles from './Show.module.css'
import Select from 'react-select'

export default function Show () {
  const cancelToken = useRef(axios.CancelToken.source())
  const { id } = useParams()

  const [request, setRequest] = useState()
  const [values, setValues] = useState({ body: '' })
  const [send, setSend] = useState(false)
  const [errors, setErrors] = useState({})
  const [redirectQuestion, setRedirectQuestion] = useState(false)
  const [rumcs, setRumcs] = useState([])
  const [selectedRumc, setSelectedRumc] = useState([])

  const _fetch = async () => {
    const { data } = await axios.get(path('request_path', { id }, true), { cancelToken: cancelToken.current.token })

    setRequest(data.request)
  }

  useEffect(() => {
    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [id])

  const isClosed = () => request.state === 'closed' || request.state === 'redirected'

  const handleSubmit = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    await axios.post(
      path('close_request_path', { id }),
      { comment: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      _fetch()
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const getRumcs = async () => {
    const rumcs = await axios.get(path('rumcs_path', {}, true), { cancelToken: cancelToken.current.token })
    setRumcs(rumcs.data.rumcs)

    setRedirectQuestion(true)
  }
  
  const handleChangeRumc = async () => {
    await axios.post(
      path('redirect_request_path', { id }),
      { rumc_id: selectedRumc },
      { cancelToken: cancelToken.current.token }
    ).then(() => {
      setRedirectQuestion(false)
      _fetch()
    }).catch(() => {
      alert("Ошибка, обратитесь в техническую поддержку (pub@incedu.ru)")
    })
  }

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value })
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
          <Link to="requests_path">{I18n.t('requests.title')}</Link>
        </Loaf>

        {request &&
          <>
            <div className={page.title}>
              <h1>
                Вопрос № {request.id}
              </h1>

              <Helmet>
                <title>{`Вопрос № ${request.id}`}</title>
              </Helmet>

              <div className={styles.meta}>
                <div className={classNames(styles.state, styles[`state_${request.state}`])}>
                  {I18n.t(`request.state.${request.state}`)}
                </div>

                <div>
                  <Datec time={request.created_at} />
                </div>
              </div>
            </div>

            <div>
              <div className={styles.request}>
                <div className={styles.body}>
                  <ReactMarkdown source={request.body} />
                </div>

                <div className={styles.author}>
                  Имя: {request.author.name}<br />
                  Эл. почта: {request.author.email}<br />
                  Телефон: {request.author.phone}<br />
                  Регион: {request.author.region}
                </div>

                {!isClosed() && 
                  <div className={styles.rumc}>
                    <div className={styles.rumc_content_wrapper}>
                      {!redirectQuestion &&
                        <div>
                          <span>Вопрос направлен в <b>{request.rumc.title}</b>.</span>
                          
                          <br />
                          Срок рассмотрения консультаций — 7 календарных дней.
                        </div>
                      }
                      {redirectQuestion && 
                        <>
                          <div style={{width: '50%'}}>
                            <Select
                              classNamePrefix="react-select"
                              getOptionValue={option => option.id}
                              getOptionLabel={option => `${option.title_short} (${option.title})`}
                              noOptionsMessage={() => 'Ничего не найдено'}
                              options={rumcs}
                              placeholder="Выберите РУМЦ.."
                              onChange={value => setSelectedRumc(value.id)}
                            />
                          </div>
                          <button className={buttons.main} onClick={() => setRedirectQuestion(false)}>
                            Отменить
                          </button>
                        </>
                      }
                      <button 
                        className={buttons.main} 
                        onClick={() => redirectQuestion ? handleChangeRumc() : getRumcs()}
                      >
                        {redirectQuestion ? 'Подтвердить' : 'Перенаправить'}
                      </button>
                    </div>
                  </div>
                }

                {request.state === 'redirected' && 
                  <div className={classNames(styles.rumc, styles.redirected)}>
                    <span>Вопрос перенаправлен в <b>{request.redirect}</b>.</span>
                  </div>
                }

                <div className={styles.comments}>
                  <CommentsList comments={request.comments} />

                  {request.can_comment &&
                    <div className="comments_form">
                      <form onSubmit={handleSubmit}>
                        <div className={form.el}>
                          <label>
                            <div className={form.label}>
                              Ответ на вопрос
                            </div>

                            <div className={form.input}>
                              <textarea name="body" value={values.body} rows="9" onChange={handleInputChange} />
                            </div>
                          </label>

                          <Errors errors={errors.body} />
                        </div>

                        <div className={form.submit}>
                          <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                            Ответить и закрыть обращение
                          </button>
                        </div>
                      </form>
                    </div>
                  }
                </div>

                {/* {rate &&
                  <Rate rate={rate} ratable={{ type: 'Request', id: request.id }} button="Сохранить и утвердить" onRateSave={this.handleRateSave} />
                } */}
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}
