import React from 'react'
// import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import FormResults from './FormResults/FormResults'
import Index from './Index'
import Form from './Form'
import Import from './Import'
import Show from './Show'
// import FormResults from './FormResults'

export default function Forms () {
  return (
    <Switch>
      {/* <Route path={Routes.form_path} render={props => <Show id={props.match.params.id} {...props} />} exact strict />

      <Route path={Routes.edit_form_form_result_path} render={props => <Show id={props.match.params.form_id} form_result_id={props.match.params.id}  {...props}  />} />
      <Route path={Routes.form_form_results_path} render={props => <FormResults id={props.match.params.form_id} />} exact strict />*/}

      <Route path={Routes.edit_form_path} render={props => <Form id={props.match.params.id} />} />

      <Route path={Routes.form_form_results_path}>
        <FormResults />
      </Route>

      <Route path={Routes.import_form_path} exact>
        <Import />
      </Route>

      <Route path={Routes.form_path} exact>
        <Show />
      </Route>

      <Route path={Routes.forms_path} exact>
        <Index />
      </Route>
    </Switch>
  )
}
