import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import update from 'immutability-helper';
import qs from 'query-string';

import styles from './Filters.module.css';
import buttons from '../../Buttons.module.css';

class Filters extends React.PureComponent {
  state = {
    fields: false,
  }

  handleActiveField = (id) => {
    let fields = this.props.fields;

    if(id === true) {
      fields = update(fields, {
        $apply: x => x.map(i => {
          return {...i, active: true}
        })
      });
    } else if(id === null) {
      fields = update(fields, {
        $apply: x => x.map(i => {
          return {...i, active: false}
        })
      });
    } else {
      fields = update(fields, {
        [this.props.fields.findIndex(f => f.id == id)]: {
          $toggle: ['active']
        }
      });
    }

    if(fields != this.props.fields) {
      this.props.history.replace(`${this.props.location.pathname}?${qs.stringify({ fields: fields.filter(f => f.active).map(f => f.id), section: this.props.section })}`);
    }
  }

  render() {
    const { fields } = this.props;

    return (
      <>
        <div className={styles.settings}>
          <span className={styles.select} onClick={() => this.setState(state => ({ fields: !state.fields }))}>Колонки ({fields.filter(f => f.active).length})</span>
        </div>

        <div className={classNames(styles.fields, { [styles.show]: this.state.fields })}>
          <div className={classNames(styles.field, { [styles.fieldActive]: fields.filter(f => !f.active).length == 0 })} onClick={(e) => this.handleActiveField(true)}>
            Выбрать все
          </div>

          <div className={styles.field} onClick={(e) => this.handleActiveField(null)}>
            По-умолчанию
          </div>
          {fields.map(field =>
            <Field {...field} onActive={this.handleActiveField} />
          )}
        </div>
      </>
    );
  }
}

class Field extends React.Component {
  handleClick = () => {
    if(this.props.onActive) this.props.onActive(this.props.id);
  }

  render() {
    const { title, id, group, active } = this.props;

    return (
      <div className={classNames(styles.field, { [styles.fieldActive]: active })} onClick={this.handleClick}>
        {group || title}
      </div>
    );
  }
}

export default Filters;
