import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

import I18n from '../I18n'
import { path } from '../Routes'

import Monitorings from './Index/Monitorings'
import Targets from './Index/Targets'

import page from '../Page.module.css'
import styles from './Index.module.css'

export default function Index () {
  const [statistics, setStatistics] = useState()

  useEffect(() => {
    const _loadAsyncData = async () => {
      const { data: { statistics } } = await axios.get(path('statistics_path', {}, true))
      setStatistics(statistics)
    }

    _loadAsyncData()
  }, [])

  if (!statistics) return null

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <div className={page.title}>
          <h1>
            {I18n.t('statistics.title')}
          </h1>

          <Helmet>
            <title>{I18n.t('statistics.title')}</title>
          </Helmet>
        </div>

        <div className={styles.root}>
          <div className={styles.monitorings}>
            <Monitorings monitorings={statistics.monitorings} />
          </div>

          <div className={styles.targets}>
            <Targets targets={statistics.targets} />
          </div>
        </div>
      </div>
    </div>
  )
}
