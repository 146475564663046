import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import classNames from 'classnames'

import I18n from '../I18n'

import styles from './Not.module.css'
import page from '../Page.module.css'

export default function Not () {
  return (
    <HelmetProvider>
      <div className={classNames(page.wrapper, styles.root)}>
        <div className={classNames(page.container, page.tight)}>
          <div className={page.title}>
            <h1>
              {I18n.t('auth.not')}
            </h1>

            <Helmet>
              <title>
                {I18n.t('auth.not')}
              </title>
            </Helmet>

            <p>
              К сожалению, произошла ошибка и у вас нет прав доступа к этой странице. Мы уже уведомлены об этой ошибке и скоро её устраним. Возможно, вы зашли не под тем пользователем, попробуйте выйти и авторизоваться заново.
            </p>
          </div>
        </div>
      </div>
    </HelmetProvider>
  )
}
