import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import I18n from '../I18n'
import { path } from '../Routes'

import styles from './Join.module.css'
import page from '../Page.module.css'
import form from '../Form.module.css'
import buttons from '../Buttons.module.css'

class Join extends Component {
  state = {
    send: false,
    errors: {},
    values: {
      email: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      password: '',
      password_confirmation: ''
    }
  }

  componentWillUnmount() {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  handleInputChange = e =>  {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }))
  }

  handleSubmit = e => {
    if(this._canSubmit()) {
      this.setState({ send: true })
      this._asyncRequest = axios.CancelToken.source()

      axios.post(path('join_path'), { user: { ...this.state.values } }, { cancelToken: this._asyncRequest.token })
        .then(res => {
          this._asyncRequest = null
          this.setState({ error: false })
          if(res.headers.location) window.location = res.headers.location
        })
        .catch(error => {
          this.setState({ errors: error.response.data })
        })
        .then(() => {
          this.setState({ send: false })
        })
    }

    e.preventDefault()
  }

  _canSubmit() {
    return !this.state.send

    return (
      this.state.values.email &&
      !this.state.send
    )
  }

  render() {
    const { values, errors } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <div className={styles.root}>
            <div className={page.title}>
              <h1>
                {I18n.t('auth.join')}
              </h1>

              <Helmet>
                <title>
                  {I18n.t('auth.join')}
                </title>
              </Helmet>
            </div>

            <div className={styles.form}>
              <form action={path('join_path')} method="post" className={form.root} onSubmit={this.handleSubmit}>
                <div className={form.item}>
                  <div className={form.label}>
                    Электронная почта
                  </div>

                  <div className={form.input}>
                    <input type="text" name="email" value={values.email} onChange={this.handleInputChange} />
                  </div>

                  {errors.email &&
                    <div className={form.error}>
                      <ul>
                        {errors.email.map(error => <li>{error}</li>)}
                      </ul>

                    </div>
                  }

                  <div className={form.hint}>
                    Почта не для публичного отображения, необходима только для входа и получения системных уведомлений
                  </div>
                </div>

                <div className={form.item}>
                  <div className={form.label}>
                    Имя
                  </div>

                  <div className={form.input}>
                    <input type="text" name="first_name" value={values.first_name} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className={form.item}>
                  <div className={form.label}>
                    Фамилия
                  </div>

                  <div className={form.input}>
                    <input type="text" name="last_name" value={values.last_name} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className={form.item}>
                  <div className={form.label}>
                    Отчество
                  </div>

                  <div className={form.input}>
                    <input type="text" name="middle_name" value={values.middle_name} onChange={this.handleInputChange} />
                  </div>

                  <div className={form.hint}>
                    Необязательно к заполнению
                  </div>
                </div>

                <div className={form.item}>
                  <div className={form.label}>
                    Пароль
                  </div>

                  <div className={form.input}>
                    <input type="password" name="password" value={values.password} onChange={this.handleInputChange} />
                  </div>

                  {errors.password &&
                    <div className={form.error}>
                      <ul>
                        {errors.password.map(error => <li>{error}</li>)}
                      </ul>

                    </div>
                  }
                </div>

                <div className={form.item}>
                  <div className={form.label}>
                    Подтверждение пароля
                  </div>

                  <div className={form.input}>
                    <input type="password" name="password_confirmation" value={values.password_confirmation} onChange={this.handleInputChange} />
                  </div>

                  {errors.password_confirmation &&
                    <div className={form.error}>
                      <ul>
                        {errors.password_confirmation.map(error => <li>{error}</li>)}
                      </ul>

                    </div>
                  }
                </div>

                {Object.entries(errors).length > 0 &&
                  <div className={styles.error}>
                    Необходимо исправить ошибки и попробовать снова.
                  </div>
                }

                <div className={form.submit}>
                  <input type="submit" value="Зарегистрироваться" className={buttons.main} disabled={!this._canSubmit()} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Join
