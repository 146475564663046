import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
// import styles from './Index.module.css'

export default function Show () {
  const { id } = useParams()
  const cancelToken = useRef(axios.CancelToken.source())

  const [module, setModule] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('account_plan_module_path', { id }, true), { cancelToken: cancelToken.current.token })

      setModule(data.plan_module)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
          {/* <Link to="account_plans_path">{I18n.t('account.plans.title')}</Link> */}
          {module &&
            <Link to="account_plan_path" params={{ id: module.plan.id }}>{module.plan.title}</Link>
          }
        </Loaf>

        {module &&
          <>
            <div className={page.title}>
              <h1>
                {module.title}
              </h1>

              <Helmet>
                <title>{module.title}</title>
              </Helmet>

              {module.can_edit &&
                <div className={page.links}>
                  <Link className={buttons.main} to="edit_account_plan_module_path" params={{ id: module.id }}>Редактировать</Link>
                </div>
              }
            </div>

            <div>

            </div>
          </>
        }
      </div>
    </div>
  )
}
