import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';

import VerificateInput from './VerificateInput';

import styles from '../Section.module.css';
import form from '../../Form.module.css';

class Quality extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      values: [
        'pps',
        'med',
        't',
        'srs',
        'o',
        'fin',
        'vol',
        'sprt',
      ].reduce((acc, e) => {
        acc[e] = (this.props.values[this.props.prefix] && this.props.values[this.props.prefix][e]) || '';
        return acc;
      }, {}),
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      [name]: {
        $set: value
      }
    });

    this.setState({ values });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.onValuesChange) this.props.onValuesChange(this.props.prefix, this.state.values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state != nextState);
  }

  render () {
    const { values, show } = this.state;
    const { num } = this.props;


    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Качество организации процесса обучения и сопровождения инвалидов и лиц с ОВЗ

          <div className={classNames(styles.label, { [styles.ok]: Object.values(values).filter(v => v).length == Object.values(values).length })}>
            {Object.values(values).filter(v => v).length} из {Object.values(values).length}
          </div>
        </div>

        <div className={styles.content}>
          <div className={form.elem}>
            <div className={form.label}>
              {num}.1. Численность студентов с ОВЗ и инвалидностью, получающих психолого-педагогическое сопровождение
            </div>

            <div className={form.input}>
              <VerificateInput name="pps" value={values.pps} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2. Численность студентов с ОВЗ и инвалидностью, получающих медицинское сопровождение
            </div>

            <div className={form.input}>
              <VerificateInput name="med" value={values.med} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3. Численность студентов с ОВЗ и инвалидностью, получающих тьюторинг
            </div>

            <div className={form.input}>
              <VerificateInput name="t" value={values.t} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4. Численность студентов с ОВЗ и инвалидностью, получающих социально-реабилитационное сопровождение
            </div>

            <div className={form.input}>
              <VerificateInput name="srs" value={values.srs} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.5. Численность студентов с ОВЗ и инвалидностью, получающих иное сопровождение
            </div>

            <div className={form.input}>
              <VerificateInput name="o" value={values.o} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.6. Численность студентов с ОВЗ и инвалидностью, получающих дополнительную финансовую поддержку (специальные стипендии, гранты и т.п.)
            </div>

            <div className={form.input}>
              <VerificateInput name="fin" value={values.fin} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="vol" checked={values.vol} onChange={this.handleInputChange} />
                {num}.7. Наличие действующего волонтерского движения по работе со студентами с ОВЗ и инвалидностью
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.8. Количество досуговых, спортивных и культурно-массовых мероприятий, проводимых с участием студентов с ОВЗ и инвалидностью в вузе
            </div>

            <div className={form.input}>
              <VerificateInput name="sprt" value={values.sprt} onChange={this.handleInputChange} />
            </div>
          </div>

        </div>
      </div>
    );
  }
}

Quality.defaultProps = {
  prefix: 'quality',
  num: '9'
};

export default Quality;
