import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

import Link, { path } from '../../Routes'
import I18n from '../../I18n'

import Loaf from '../../Loaf/Loaf'
import Block from './Index/Block'

import page from '../../Page.module.css'
import styles from './Index.module.css'

class Index extends Component {
  state = {
    rumcs: null
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.target !== prevProps.target) {
      this._loadAsyncData()
    }
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()
    const res = await axios.get(path('account_rumcs_path', { query: { target_id: this.props.target } }, true), { cancelToken: this._asyncRequest.token })
    this._asyncRequest = null
    this.setState({ ...res.data })
  }

  render () {
    const { target, targets, rumcs } = this.state
    
    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">{I18n.t('account.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('account.rumcs.title')}
            </h1>

            <Helmet>
              <title>
                {I18n.t('account.rumcs.title')}
              </title>
            </Helmet>

            {targets &&
             <div className={styles.tabs}>
               {targets.map((target, _) =>
                 <Link
                   to="account_rumcs_path"
                   params={{ hash: _ === 0 ? null : target.id }}
                   key={target.id}
                   className={styles.tab}
                   activeClassName={styles.active}
                 >{target.title}</Link>
               )}
             </div>
            }
          </div>

          <p>
            <Link to="inner_documents_path">Список согласованных документов</Link>
          </p>

          {target &&
            <div className={styles.root}>
              <div className={styles.blocks}>
                {target.sections.map((section, _) =>
                  <Block {...section} rumcs={rumcs} num={_ + 1} key={_} />
                )}
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

Index.propTypes = {
  target: PropTypes.string
}

export default Index
