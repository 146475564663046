import React from 'react'

import Link from '../Routes'

import page from '../Page.module.css'
import styles from './TermsIndex.module.css'

class TermsIndex extends React.Component {
  render () {
    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>
              Правила использования портала
            </h1>
          </div>

          <div className={styles.content}>
            <h2>
              Ресурсным центрам
            </h2>

            <h3>
              Общие блоки
            </h3>

            <ul>
              <li>
                <Link to="terms_rumc_path" params={{ section: 'цкд' }}>Реестр центра коллективного доступа</Link>
              </li>
              <li>
                <Link to="terms_rumc_path" params={{ section: 'анкеты' }}>Анкеты мониторинга</Link>
              </li>
            </ul>

            <h3>
              Создание и утверждение контента
            </h3>
            <p>В данном блоке описан процесс создания контента и что и кому нужно делать в тех или иных статусах.</p>
            <ul>
              <li>
                <Link to="terms_rumc_path" params={{ section: 'умм' }}>Создание и утверждение учебных материалов</Link>
              </li>
              <li>
                <Link to="terms_rumc_path" params={{ section: 'профессии' }}>Создание и утверждение профессий</Link>
              </li>
              <li>
                <Link to="terms_rumc_path" params={{ section: 'эксперты' }}>Утверждение экспертами</Link>
              </li>
            </ul>

            <h3>
              Требование к контенту
            </h3>
            <ul>
              <li>
                <Link to="terms_video_path">Требования к видео файлам</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default TermsIndex
