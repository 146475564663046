import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { EditorRich } from '../../Draft'

import Link, { path } from '../../Routes'
import I18n from '../../I18n'
import Loaf from '../../Loaf/Loaf'
import { Errors } from '../../Form'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
import form from '../../Form.module.css'

export default function Form () {
  const { id } = useParams()
  const history = useHistory()
  const cancelToken = useRef(axios.CancelToken.source())

  const [plan, setPlan] = useState()
  const [values, setValues] = useState({
    title: '',
    text: EditorState.createEmpty()
  })
  const [send, setSend] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(
        path('edit_account_plan_path', { id }, true),
        {
          cancelToken: cancelToken.current.token
        }
      )

      data.values.text = data.values.text ? EditorState.createWithContent(convertFromRaw(data.values.text)) : EditorState.createEmpty()
      setPlan(data.plan)
      setValues(data.values)
    }

    if (id) _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [id])

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    const params = {
      ...values,
      text: values.text.getCurrentContent().hasText() ? convertToRaw(values.text.getCurrentContent()) : null
    }

    if (id) {
      handleUpdate(params)
    } else {
      handleCreate(params)
    }
  }

  const handleCreate = async params => {
    await axios.post(
      path('account_plans_path'),
      { plan: params },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const handleUpdate = async params => {
    await axios.patch(
      path('account_plan_path', { id }),
      { plan: params },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
          {plan &&
            <Link to="account_plan_path" params={{ id: plan.id }}>{plan.title}</Link>
          }
        </Loaf>
        <div className={page.title}>
          <h1>
            {id ? 'Редактирование' : 'Новая программа' }
          </h1>
        </div>

        <div className={form.tight}>
          <form onSubmit={handleSubmit}>
            <div className={form.el}>
              <label>
                <div className={form.label}>
                  Название программы
                </div>

                <div className={form.input}>
                  <input
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleInputChange}
                  />
                </div>
              </label>

              <Errors errors={errors.title} />
            </div>

            <div className={form.el}>
              <label>
                <div className={form.label}>
                  Текст программы
                </div>

                <div className={form.draft}>
                  <EditorRich
                    editorState={values.text}
                    onEditorStateChange={(editorState) => setValues({ ...values, text: editorState })}
                  />
                  {/* <Editor
                    editorState={values.text}
                    onChange={(editorState) => setValues({ ...values, text: editorState })}
                  /> */}
                </div>
              </label>

              <Errors errors={errors.text} />
            </div>

            <div className={form.submit}>
              <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
