import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import classNames from 'classnames'

import I18n from '../../I18n'

import styles from './Monitorings.module.css'

Monitorings.propTypes = {
  monitorings: PropTypes.array
}

export default function Monitorings (props) {
  const { monitorings } = props

  return (
    <div className={styles.root}>
      <h3>
        Мониторинг
      </h3>

      <p className={styles.desc}>
        Количество вузов, которые прошли мониторинг доступности системы инклюзивного высшего образования.
      </p>

      {monitorings.map(monitoring =>
        <Monitoring key={monitoring.id} {...monitoring} />
      )}
    </div>
  )
}

Monitoring.propTypes = {
  ministries: PropTypes.array,
  schools: PropTypes.object,
  title: PropTypes.string
}

function Monitoring (props) {
  const { schools, title, ministries } = props

  const [active, setActive] = useState(false)

  return (
    <>
      <div className={styles.row}>
        <div className={styles.values}>
          <div className={styles.main}>
            {schools.mains}
          </div>

          <div className={styles.secondary}>
            (+{schools.branchs} филиалов)
          </div>
        </div>

        <div className={styles.label}>
          <div>
            {title}
          </div>

          <div className={styles.toggle} onClick={() => setActive(!active)}><span>по ведомствам: {ministries.filter(ministry => ministry.mains > 0 || ministry.branchs > 0).length}</span></div>
        </div>
      </div>

      {active &&
        <div className={styles.ministries}>
          {ministries.filter(ministry => ministry.mains > 0 || ministry.branchs > 0).map(ministry =>
            <div className={styles.ministry} key={ministry.id}>
              <div className={styles.values}>
                {ministry.mains}
                <span className={styles.secondary}>
                  (+{ministry.branchs} фил.)
                </span>
              </div>
              <div className={styles.label}>{I18n.t(`statistics.ministries.${ministry.id}`)}</div>
            </div>
          )}
        </div>
      }
    </>
  )
}
