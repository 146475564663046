import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { path } from '../../Routes';
import Loaf from '../../Loaf/Loaf';

import page from '../../Page.module.css';

class Index extends React.Component {
  state = {
    forms: null
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('stats_forms_path', { id: this.props.match.params.id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          forms: res.data.forms
        });

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  render() {
    const id = this.props.match.params.id;
    const { forms } = this.state;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('stats_path', { id: 5 })}>Весь отчёт</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Анкеты
            </h1>

            <Helmet>
              <title>
                Анкеты
              </title>
            </Helmet>
          </div>

          <div className={page.text}>
            {forms &&
              <>
              {forms.map(form =>
                <div key={form.id}>
                  <Link to={path('stats_form_path', { id: 5, form_id: form.id })}>
                    {form.title}
                  </Link>
                </div>
              )}
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
