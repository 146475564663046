import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'

import Loaf from '../../Loaf/Loaf'
import I18n from '../../I18n'
import { path } from '../../Routes'

import styles from './Index.module.css'
import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'

export default function Index () {
  const [usersRoles, setUsersRoles] = useState([])
  const cancelToken = useRef(axios.CancelToken.source())

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(
        path('admin_users_roles_path', {}, true),
        {
          cancelToken: cancelToken.current.token
        }
      )

      setUsersRoles(data.users_roles)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const handleActivate = async (id) => {
    axios.post(path('activate_admin_users_role_path', { id }, true), { cancelToken: cancelToken.current.token })
      .then(res => {
        setUsersRoles(usersRoles.filter(ur => ur.id !== id))
      })
  }

  return (
    <div className={page.root}>
      <div className={page.container}>
        <Loaf>
          <Link to={path('account_path')}>Кабинет</Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('admin.users_roles.nav')}
          </h1>

          <Helmet>
            <title>
              {I18n.t('admin.users_roles.nav')}
            </title>
          </Helmet>
        </div>

        <div className={styles.root}>
          <div className={styles.users_roles}>
            {usersRoles.map(userRole =>
              <div className={styles.user_role} key={userRole.id}>
                <div className={styles.type}>
                  {userRole.assignable_type}
                </div>
                <div className={styles.title}>
                  {userRole.assignable.title} - {userRole.user.title} ({userRole.user.email})
                </div>

                <button className={buttons.small} onClick={() => handleActivate(userRole.id)}>
                  Утвердить
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
