import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'
import Redraft from '../../Redraft'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
// import styles from './Show.module.css'

export default function Show () {
  const { id } = useParams()
  const cancelToken = useRef(axios.CancelToken.source())

  const [stage, setStage] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('account_stage_path', { id }, true), { cancelToken: cancelToken.current.token })

      setStage(data.stage)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>

          {stage && stage.stageable &&
            <>
              {stage.stageable.plan &&
                <Link to="account_plan_path" params={{ id: stage.stageable.plan.id }}>{stage.stageable.plan.title}</Link>
              }

              <Link to={`account_${stage.stageable.stageable_type}_path`} params={{ id: stage.stageable.id }}>{stage.stageable.title}</Link>
            </>
          }
        </Loaf>

        {stage &&
          <>
            <div className={page.title}>
              <h1>
                {stage.title}
              </h1>

              <Helmet>
                <title>{stage.title}</title>
              </Helmet>

              {stage.can_edit &&
                <div className={page.links}>
                  <Link className={buttons.main} to="edit_account_stage_path" params={{ id: stage.id }}>Редактировать</Link>
                </div>
              }
            </div>

            <div className={page.common}>
              {stage.desc &&
                <div>
                  <Redraft src={stage.desc} />
                </div>
              }
            </div>
          </>
        }
      </div>
    </div>
  )
}
