import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import querystring from 'querystring'
import axios from 'axios'
import Datec from '../Datec'

import Link, { path } from '../Routes'
import I18n from '../I18n'

import styles from './Search.module.css'
import page from '../Page.module.css'

export default function Search () {
  const location = useLocation()
  let _asyncRequest = axios.CancelToken.source()

  const [q, setQ] = useState(querystring.parse(location.search.slice(1)).q || '')

  useEffect(() => {
    setQ(querystring.parse(location.search.slice(1)).q || '')
  }, [location])

  const [results, setResults] = useState()

  useEffect(() => {
    const _fetch = async () => {
      if (q.length < 3) return null

      const { data } = await axios.get(path('search_path', {}, true), { cancelToken: _asyncRequest.token, params: { q } })
      setResults(data.results)
      _asyncRequest = null
    }

    _fetch()

    return function cleanup () {
      if (_asyncRequest) _asyncRequest.cancel()
    }
  }, [q])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Helmet>
          <title>{q}</title>
        </Helmet>

        <div className={page.text}>
          {q.length < 3 &&
            <div className={styles.none}>
              Слишком короткий запрос
            </div>
          }

          {q.length >= 3 && results &&
            <div className={styles.results}>
              {results.map(result =>
                <div key={`${result.type}-${result.id}`} className={styles.result}>
                  <Link to={`${result.type === 'story' ? 'abiturs_story' : (result.type === 'career' ? 'career' : (result.type === 'umm' ? 'umm' : result.type))}_path`} params={{ id: result.id }} className={styles.title}>
                    {result.title}
                  </Link>

                  <div className={styles.desc} dangerouslySetInnerHTML={{ __html: result.desc }} />

                  <div className={styles.meta}>
                    <div className={styles.type}>
                      <div className={styles.row}>
                        <div style={{marginRight: '8px'}}>{I18n.t(`search.result.type.${result.type}`)}</div>
                        {result.type == 'event' &&
                          <div><Datec time={result.date} /></div>
                        }
                      </div>
                    </div>
                  </div>

                  
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
