import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Index'
import Show from './Show'

export default function Courses () {
  return (
    <Switch>
      <Route path={Routes.course_path}>
        <Show />
      </Route>

      <Route path={Routes.courses_path} exact strict>
        <Index />
      </Route>
    </Switch>
  )
}
