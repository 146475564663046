import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

import { path } from '../Routes';

import styles from './Form.module.css';
import form from '../Form.module.css';
import buttons from '../Buttons.module.css';

class Form extends React.Component {
  state = {
    audience: this.props.call.audience ? this.props.call.audience.model : null,
    values: {
      resolved: this.props.call.resolved || '',
      call_subject_id: this.props.call.subject ? this.props.call.subject.id : '',
      audience_ids: this.props.call.audience ? this.props.call.audience.id : '',
      school_id: this.props.call.school ? this.props.call.school.id : '',
      date: this.props.call.date ? new Date(this.props.call.date) : new Date(),
    },
    submit: {
      url: this.props.call.id ? path('call_path', { id: this.props.call.id }, true) : path('calls_path', {}, true) ,
      method: this.props.call.id ? 'patch' : 'post',
    },
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name == 'audience_ids') {
      this.setState({ audience: this.props.audiences.find(a => a.id == value).model })
    }

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }));
  }

  handleSubmit = (e) => {
    if(this._canSubmit()) {
      this.setState({ send: true });
      this._asyncRequest = axios.CancelToken.source();

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        cancelToken: this._asyncRequest.token,
        data: { call: this.state.values }
      }).then(
        res => {
          if(this.props.onUpdate) this.props.onUpdate(res.data);
        }
      ).catch((error) => {
        this.setState({ send: false });
      }).then(() => {
        this._asyncRequest = null;
      });
    }

    e.preventDefault();
  }

  handleClose = (e) => {
    if(this.props.onClose) this.props.onClose();
    e.preventDefault();
  }

  _loadSchools = (input, callback) => {
    callback(this.props.schools.filter(school => school.title.toLowerCase().includes(input.toLowerCase())).slice(0, 100));
  };

  _canSubmit = () => {
    return (
      this.state.values.date &&
      this.state.values.audience_ids &&
      this.state.values.call_subject_id &&
      ((this.state.audience == 'School' && this.state.values.school_id) || this.state.audience != 'School') &&
      !this.state.send
    );
  }

  render() {
    const { call, subjects, audiences, schools } = this.props;
    const { values, audience } = this.state;

    return (
      <div className={styles.root}>
        <div className={styles.id}>
          <strong>
            № {call.id}
          </strong> от {dayjs.utc(call.date).format('DD.MM.YYYY')}
        </div>

        <form className={form.root} onSubmit={this.handleSubmit}>
          <div className={form.elem}>
            <div className={form.label}>
              Дата обращения
            </div>

            <div className={form.input}>
              <DatePicker
                selected={values.date}
                onChange={date => this.setState(state => ({ values: { ...state.values, date: date } }))}
                dateFormat="dd.MM.yyyy"
              />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              Категория обратившегося
            </div>

            <div className={form.input}>
              <select name="audience_ids" onChange={this.handleInputChange} value={values.audience_ids}>
                <option />
                {audiences.map(audience =>
                  <option key={audience.id} value={audience.id}>{audience.title}</option>
                )}
              </select>
            </div>
          </div>

          {audience == 'School' &&
            <div className={form.elem}>
              <div className={form.label}>
                Вуз
              </div>

              <div className={form.input}>
                <AsyncSelect
                  classNamePrefix="react-select"
                  getOptionLabel={option => option.title}
                  getOptionValue={option => option.id}
                  loadOptions={this._loadSchools}
                  placeholder="Выберите вуз.."
                  value={schools.find(school => school.id == values.school_id)}
                  onChange={value => this.setState(state => ({ values: { ...state.values, school_id: value.id } }))}
                  noOptionsMessage={({inputValue}) => inputValue == '' ? 'Введите часть названия вуза' : 'Нет доступных результатов'}
                />
              </div>

              <div className={form.hint}>
                Начните вводить название вуза чтобы отобразились доступные варианты. Количество результатов ограничено 100 вузами.
              </div>
            </div>
          }

          <div className={form.elem}>
            <div className={form.label}>
              Тип обращения
            </div>

            <div className={form.input}>
              <select name="call_subject_id" onChange={this.handleInputChange} value={values.call_subject_id}>
                <option />
                {subjects.map(subject =>
                  <option key={subject.id} value={subject.id}>{subject.title}</option>
                )}
              </select>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.input}>
              <div className={form.checkbox}>
                <label>
                  <input type="checkbox" name="resolved" checked={this.state.values.resolved} onChange={this.handleInputChange} />
                  Проблема решена?
                </label>
              </div>
            </div>
          </div>

          <div className={form.submit}>
            <button className={classNames(buttons.small, buttons.cancel)} onClick={this.handleClose}>Отменить</button>
            <input type="submit" value="Сохранить" className={buttons.small} disabled={!this._canSubmit()} />
          </div>
        </form>
      </div>
    );
  }
}

export default Form
