import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'

import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'

import page from '../../Page.module.css'

export default function Show () {
  const { form_id: formId } = useParams()
  const cancelToken = useRef(axios.CancelToken.source())

  const [results, setResults] = useState()
  const [title, setTitle] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('form_form_results_path', { form_id: formId }, true))

      setResults(data.results)

      setTitle(`Результаты заполнения «${data.values.title}»`)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div className={page.root}>
      <div className={page.container}>
        <Loaf>
          <Link to="form_path" params={{ id: formId }}>Анкета</Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {title}
          </h1>

          <Helmet>
            <title>
              {title}
            </title>
          </Helmet>
        </div>

        {results && results.length > 0 &&
          <div>
            <ul>
              {results.map(result =>
                <li key={result.id}>
                  <Link to="edit_form_form_result_path" params={{ form_id: formId, id: result.id }}>Анкета  №{result.id} от {result.date}</Link>
                </li>
              )}
            </ul>
          </div>
        }
      </div>
    </div>
  )
}
