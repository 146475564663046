import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import XLSX from 'xlsx'
import { useParams, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Select from 'react-select'
import pluralize from 'pluralize'

import Link, { path } from '../Routes'
// import I18n from '../I18n'
import Loaf from '../Loaf/Loaf'

import NoRumc from './Show/NoRumc'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import forms from '../Form.module.css'
import styles from './Import.module.css'

export default function Import () {
  const query = new URLSearchParams(useLocation().search)
  const { id } = useParams()
  const cookies = new Cookies()
  const cancelToken = useRef(axios.CancelToken.source())

  const [form, setForm] = useState()
  const [elements, setElements] = useState()

  const [questions, setQuestions] = useState(new Map())
  const [options, setOptions] = useState(new Map())

  const [dictionaries, setDictionaries] = useState()

  const rumcId = query.get('rumc_id')
  const cookieRumcId = cookies.get('form_rumc_id')

  if (!rumcId && !cookieRumcId) return <NoRumc />

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('import_form_path', { id }, true))

      setForm(data.form)
      setElements(data.form_elements)
      setDictionaries(data.dictionaries)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const [wb, setWb] = useState()

  const handleFileChange = e => {
    e.preventDefault()

    const reader = new FileReader()

    reader.onload = e => {
      const wb = XLSX.read(e.target.result, { type: 'binary' })
      setWb(wb)
    }

    reader.readAsBinaryString(e.target.files[0])
  }

  if (wb) {
    var [header, ...rows] = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 })
    var variants = {}

    header.forEach((h, i) => {
      variants[i] = variants[i] || []

      rows.forEach(r => {
        if (!variants[i].includes(r[i]) && typeof r[i] !== 'undefined') {
          variants[i].push(r[i])
        }
      })
    })
  }

  const handleQuestionChange = (index, value) => {
    const newQuestions = new Map(questions)
    newQuestions.set(index, value)
    setQuestions(newQuestions)
  }

  const handleOptionsChange = (index, option, value) => {
    let tt = options.get(index) || new Map()
    tt.set(option, value.id)
    const newOptions = new Map(options)
    newOptions.set(index, tt)
    setOptions(newOptions)
  }

  const canSubmit = () => {
    return rows && rows.length > 0
  }

  const handleSubmit = e => {
    e.preventDefault()

    rows.map(async (row) => {
      await axios.post(
        path('form_form_results_path', { form_id: id }, true),
        {
          form_result: {
            rumc_id: rumcId,
            imported: true,
            form_elements_results_attributes: [...row].map((o, index) => {
              if (questions.get(index) && o) {
                let question = questions.get(index).id
                return {
                  value: questions.get(index).type === 'string' ? o : options.get(question).get(o),
                  form_element_id: question
                }
              }
            })
          }
        },
        { cancelToken: cancelToken.current.token }
      ).then(res => {
        // console.log('success');
        // cookies.remove(`form-${id}`)
        // res.headers.location && history.push(res.headers.location)
      }).catch(error => {
        // setErrors(error.response.data)
        // setSend(false)
      })
    })
  }

  return (
    <div className={page.root}>
      <div className={page.container}>
        <Loaf>
          {form &&
            <Link to="form_path" params={{ id }}>{form.title}</Link>
          }
        </Loaf>

        <div>
          <input type="file" onChange={handleFileChange} />
        </div>

        {variants && header && rows &&
          <div>
            <div>
              {header.map((i, index) =>
                <div key={i}>
                  <div className={forms.elem}>
                    <div className={forms.label}>
                      {i}
                    </div>

                    <div className={forms.input}>
                      <Select
                        classNamePrefix="react-select"
                        getOptionLabel={option => option.title}
                        getOptionValue={option => option.id}
                        options={elements}
                        placeholder="Выберите соответствующее поле"
                        onChange={value => handleQuestionChange(index, value)}
                      />
                    </div>

                    {questions.get(index) &&
                      <>
                        {(questions.get(index).type === 'radio' || questions.get(index).type === 'checkbox') && questions.get(index).settings !== 'Nosology' &&
                          <div className={styles}>
                            {variants[index] && variants[index].sort().map((option) =>
                              <>
                                <div className={forms.label}>
                                  {option}
                                </div>
                                <div className={forms.input}>
                                  <Select
                                    classNamePrefix="react-select"
                                    getOptionLabel={option => option.title}
                                    getOptionValue={option => option.id}
                                    options={JSON.parse(questions.get(index).settings)}
                                    placeholder="Выберите соответствующее поле"
                                    onChange={value => handleOptionsChange(questions.get(index).id, option, value)}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        }
                        {(questions.get(index).type === 'references' || questions.get(index).settings === 'Nosology') &&
                          <div>
                            {variants[index] && variants[index].sort().map((option) =>
                              <>
                                <div className={forms.label}>
                                  {option}
                                </div>
                                <div className={forms.input}>
                                  <Select
                                    classNamePrefix="react-select"
                                    getOptionLabel={option => option.title}
                                    getOptionValue={option => option.id}
                                    options={dictionaries[`${pluralize(questions.get(index).settings.toLowerCase())}`]}
                                    placeholder="Выберите соответствующее поле"
                                    onChange={value => handleOptionsChange(questions.get(index).id, option, value)}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        }
                      </>
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        }
        <button className={buttons.main} onClick={handleSubmit} disabled={!canSubmit()}>
          Загрузить
        </button>
      </div>
    </div>
  )
}
