import React from 'react'
import PropTypes from 'prop-types'

import axios from 'axios'
import qs from 'query-string'
import classNames from 'classnames'
import update from 'immutability-helper'

import I18n from '../I18n';
import Link, { path } from '../Routes'

import Filters from '../Filters/Filters'
import Loaf from '../Loaf/Loaf'

import styles from './CorrectionsIndex.module.css'
import page from '../Page.module.css'
import buttons from '../Buttons.module.css'

class CorrectionsIndex extends React.Component {
  state = {
    items: [],
    search: this.props.location.search,
    user: null
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) {
      this._asyncRequest.cancel()
    }
  }

  _loadAsyncData () {
    this._asyncRequest = axios.CancelToken.source()
    axios.get(path('corrections_path', {}, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search, { arrayFormat: 'bracket' })})
      .then(res => {
        this.setState({
          items: res.data.items,
          user: res.data.user,
          filters: []
        })

        this._asyncRequest = null
      })
  }

  static getDerivedStateFromProps (props, state) {
    if (state.filters && props.location.search !== state.search) {
      return {
        items: null,
        search: props.location.search,
      }
    }

    return null
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.items === null) {
      this._loadAsyncData()
    }
  }

  handleTake = (id, type) => {
    axios.post(path('corrections_path', {}),
      { correction: { state: 0, correctable_id: id, correctable_type: type } }).then(
      res => {
        if (res.status === 200) {
          let index = this.state.items.findIndex(item => (item.id === id && item.type === type))

          let newItems = update(this.state.items, {
            [index]: {
              $set: Object.assign(this.state.items[index], { correction: res.data })
            }
          })

          this.setState(prevState => ({
            items: newItems
          }))
        }
      }
    )
  }

  handleDisapprove = (correction, id, type) => {
    axios.delete(path('correction_path', { id: correction.id })).then(
      res => {
        let index = this.state.items.findIndex(item => (item.id === id && item.type === type))

        let newItems = update(this.state.items, {
          [index]: {
            $set: Object.assign(this.state.items[index], { correction: null })
          }
        })

        this.setState(prevState => ({
          items: newItems
        }))

        // this.setState(prevState => ({
        //   items: prevState.items.filter(item => !(item.id === id && item.type === type))
        // }))
      }
    )
  }

  handleApprove = (correction, id, type) => {
    axios.patch(path('correction_path', { id: correction.id }),
      { correction: { state: 1 } }).then(
      res => {
        this.setState(prevState => ({
          items: prevState.items.filter(item => !(item.id === id && item.type === type))
        }))
      }
    )
  }

  render () {
    const { items, user } = this.state
    const { history, location } = this.props

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">Кабинет</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Список материалов для проверки
            </h1>
            <p>
              Откройте непроверенный материал, исправьте все ошибки и утвердите его как проведший проверку. После утверждения он пропадет из списка на проверку, но если в него снова внесут изменения — он вновь попадет в этот список. Сортировка по дате обновления, новые материалы попадают вверх.
            </p>
          </div>

          <div className={styles.root}>
            <div className={styles.tabs}>
              <Link
                to="corrections_path"
                className={styles.tab}
                activeClassName={styles.active}
              >Непроверенные</Link>

              <Link
                to="corrections_path"
                params={{ query: { self: true } }}
                className={styles.tab}
                activeClassName={styles.active}
              >Мои</Link>

              <Link
                to="corrections_path"
                params={{ query: { corrected: true } }}
                className={styles.tab}
                activeClassName={styles.active}
              >Проверенные</Link>
            </div>

            <div className={styles.items}>
              {items &&
                <>
                  {items.map((item, _) =>
                    <div key={_} className={styles.item}>
                      <div className={styles.meta}>
                        <div className={styles.date}>{item.date_human}</div>
                        <div className={styles.type}>{I18n.t(`search.result.type.${item.type.toLowerCase()}`)}</div>
                        <div className={styles.edit}>
                          <a href={`${item.edit_path}`} target="_blank">
                            Редактировать
                          </a>
                        </div>
                        {item.correction && !item.correction.done &&
                          <div className={classNames(styles.incomplete, styles.corrector)}>На проверке: {item.correction.corrector}</div>
                        }
                        {item.correction && item.correction.done &&
                          <div className={classNames(styles.done, styles.corrector)}>Проверил(а): {item.correction.corrector}</div>
                        }
                      </div>

                      <div className={styles.title}>
                        <a href={`${item.show_path}`} target="_blank">
                          {item.title}
                        </a>
                      </div>

                      {!item.correction &&
                        <div className={styles.actions}>
                          <div className={classNames(styles.approve, buttons.small)} onClick={e => this.handleTake(item.id, item.type)}>
                            На проверку
                          </div>
                        </div>
                      }
                      {item.correction && item.correction.corrector_id === user.id &&
                        <div className={styles.actions}>
                          {!item.correction.done &&
                            <>
                              <div className={classNames(styles.approve, buttons.small)} onClick={e => this.handleApprove(item.correction, item.id, item.type)}>
                                Утвердить
                              </div>
                              <div className={classNames(styles.approve, buttons.small)} onClick={e => this.handleDisapprove(item.correction, item.id, item.type)}>
                                Отменить проверку
                              </div>
                            </>
                          }
                        </div>
                      }
                    </div>
                  )}
                </>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CorrectionsIndex
