import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../../Routes'

import Schools from './Schools'
import Programs from './Programs'

export default function Reports () {
  return (
    <Switch>
      <Route path={Routes.statistics_reports_schools_path} strict exact>
        <Schools />
      </Route>

      <Route path={Routes.statistics_reports_programs_path} strict exact>
        <Programs />
      </Route>
    </Switch>
  )
}
