import React from 'react';
import PropTypes from 'prop-types';

import form from '../../Form.module.css';

class VerificateInput extends React.Component {
  state = {
    value: this.props.value || '',
    verification_msg: ''
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.value != prevProps.value) {
      this.setState({ verification_msg: '' });
    }
  }

  handleVerification = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (value !== value.replace(/\D/,'')) {
      if (value.substring(0,1) == '-') {
        this.setState({
          verification_msg: 'Значение поля не может быть отрицательным'
        })
      }
      else {
        this.setState({
          verification_msg: 'Поле может содержать только числовое значение'
        })
      }
    }

    event.target.value = value.replace(/\D/,'')

    this.props.onChange(event);
  }

  render () {
    const { verification_msg } = this.state;
    return (
      <>
        <input type="text" name={this.props.name} value={this.props.value} onChange={this.handleVerification}/>
        {verification_msg &&
          <div className={form.error}>{verification_msg}</div>
        }
      </>
    )
  }
}

export default VerificateInput;
