import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import axios from 'axios';
import classNames from 'classnames';
import AsyncSelect from 'react-select/async';

import { path } from '../../Routes';
import { withAuthToken } from '../../Context/AuthTokenContext';

import VerificateInput from './VerificateInput';

import styles from './Program.module.css';
import form from '../../Form.module.css';
import buttons from '../../Buttons.module.css';

class Program extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      send: false,
      show: true,
      values: {
        direction_id: this.props.values.direction_id,
        data: [
          'edu',
          'edu_program',
          'abitur_ovall_f',
          'abitur_ovall_p',
          'abitur_ovall_fp',
          'abitur_e_f',
          'abitur_e_p',
          'abitur_e_fp',
          'abitur_h_f',
          'abitur_h_p',
          'abitur_h_fp',
          'abitur_mm_f',
          'abitur_mm_p',
          'abitur_mm_fp',
          'abitur_mn_f',
          'abitur_mn_p',
          'abitur_mn_fp',
          'abitur_s_f',
          'abitur_s_p',
          'abitur_s_fp',
          'abitur_p_f',
          'abitur_p_p',
          'abitur_p_fp',
          'abitur_o_f',
          'abitur_o_p',
          'abitur_o_fp',
          'submit_ovall_f',
          'submit_ovall_p',
          'submit_ovall_fp',
          'submit_e_f',
          'submit_e_p',
          'submit_e_fp',
          'submit_h_f',
          'submit_h_p',
          'submit_h_fp',
          'submit_mm_f',
          'submit_mm_p',
          'submit_mm_fp',
          'submit_mn_f',
          'submit_mn_p',
          'submit_mn_fp',
          'submit_s_f',
          'submit_s_p',
          'submit_s_fp',
          'submit_p_f',
          'submit_p_p',
          'submit_p_fp',
          'submit_o_f',
          'submit_o_p',
          'submit_o_fp',
          'student_ovall_f',
          'student_ovall_p',
          'student_ovall_fp',
          'student_e_f',
          'student_e_p',
          'student_e_fp',
          'student_h_f',
          'student_h_p',
          'student_h_fp',
          'student_mm_f',
          'student_mm_p',
          'student_mm_fp',
          'student_mn_f',
          'student_mn_p',
          'student_mn_fp',
          'student_s_f',
          'student_s_p',
          'student_s_fp',
          'student_p_f',
          'student_p_p',
          'student_p_fp',
          'student_o_f',
          'student_o_p',
          'student_o_fp',
          'student_ad_f',
          'student_ad_p',
          'student_ad_fp',
          'student_afc',
          'student_afc_d_f',
          'student_afc_d_p',
          'student_afc_d_fp',
          'student_afc_e_f',
          'student_afc_e_p',
          'student_afc_e_fp',
          'student_afc_h_f',
          'student_afc_h_p',
          'student_afc_h_fp',
          'student_afc_mm_f',
          'student_afc_mm_p',
          'student_afc_mm_fp',
          'student_afc_mn_f',
          'student_afc_mn_p',
          'student_afc_mn_fp',
          'student_afc_s_f',
          'student_afc_s_p',
          'student_afc_s_fp',
          'student_afc_p_f',
          'student_afc_p_p',
          'student_afc_p_fp',
          'student_afc_o_f',
          'student_afc_o_p',
          'student_afc_o_fp',
          'graduate_ovall_f',
          'graduate_ovall_p',
          'graduate_ovall_fp',
          'graduate_e_f',
          'graduate_e_p',
          'graduate_e_fp',
          'graduate_h_f',
          'graduate_h_p',
          'graduate_h_fp',
          'graduate_mm_f',
          'graduate_mm_p',
          'graduate_mm_fp',
          'graduate_mn_f',
          'graduate_mn_p',
          'graduate_mn_fp',
          'graduate_s_f',
          'graduate_s_p',
          'graduate_s_fp',
          'graduate_p_f',
          'graduate_p_p',
          'graduate_p_fp',
          'graduate_o_f',
          'graduate_o_p',
          'graduate_o_fp',
          'graduate_wrk_f',
          'graduate_wrk_p',
          'graduate_wrk_fp',
        ].reduce((acc, e) => {
          acc[e] = (this.props.values.data && this.props.values.data[e]) || '';
          return acc;
        }, {})
      },
    }
  }

  handleCancel = () => {
    if(this.props.onCancel) this.props.onCancel();
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      data: {
        [name]: {
          $set: value
        }
      }
    });

    this.setState({ values });
  }

  componentDidMount() {
    this._setSubmit();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this._setSubmit();
  }

  _setSubmit() {
    if(this.props.id) {
      this.submit = {
        method: 'patch',
        url: path('program_path', { id: this.props.id }, true)
      }
    } else {
      this.submit = {
        method: 'post',
        url: path('programs_path', { }, true)
      }
    }
  }

  handleSubmit = (e) => {
    if(this._canSubmit()) {
      this.setState({ send: true });
      this._asyncRequest = axios.CancelToken.source();

      axios({
        method: this.submit.method,
        url: this.submit.url,
        cancelToken: this._asyncRequest.token,
        data: {
          program: {
            ...this.state.values,
            report_id: this.props.report.id
          }
        }
      }).then(
        res => {
          if(this.props.onCancel) this.props.onCancel();
          if(this.props.onSave) this.props.onSave(this.props.report.id)
        }
      ).catch((error) => {
        console.log(error);
      }).then(() => {
        this.setState({ send: false });
        this._asyncRequest = null;
      });
    }
  }

  handleDelete = () => {
    this._asyncRequest = axios.CancelToken.source();

    axios({
      method: 'delete',
      url: this.submit.url,
      cancelToken: this._asyncRequest.token,
      data: {
        program: {
          ...this.state.values,
          report_id: this.props.report.id
        }
      }
    }).then(
      res => {
        if(this.props.onCancel) this.props.onCancel();
        if(this.props.onSave) this.props.onSave(this.props.report.id)
      }
    ).catch((error) => {
      console.log(error);
    }).then(() => {
      this.setState({ send: false });
      this._asyncRequest = null;
    });
  }

  _loadDirections = (input, callback) => {
    callback(this.props.directions.filter(d => (!this.props.existed.includes(d.id) || d.id == this.state.values.direction_id) && d.title.toLowerCase().includes(input.toLowerCase())).slice(0, 100));
  };

  _canSubmit = () => {
    return (
      !!this.props.report &&
      !this.state.send
    );
  }

  render () {
    const { show, values } = this.state;
    const { num, directions, monitoring } = this.props;

    return (
      <div className={styles.root}>
        <div>
          <div className={form.elem}>
            <div className={form.label}>
              Направление подготовки (специальность)
            </div>

            <div className={form.input}>
              <AsyncSelect
                classNamePrefix="react-select"
                noOptionsMessage={({inputValue}) => inputValue == '' ? 'Введите часть названия направления' : 'Нет доступных результатов'}
                getOptionLabel={option => option.title}
                getOptionValue={option => option.id}
                loadOptions={this._loadDirections}
                value={directions.find(d => d.id == this.state.values.direction_id)}
                placeholder="Выберите направление.."
                onChange={value => this.setState(state => ({ values: { ...state.values, direction_id: value.id }}))}
              />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>

                <input type="checkbox" name="edu" checked={values.data.edu} onChange={this.handleInputChange} />
                Реализуются образовательная программа с применением дистанционных технологий и электронного обучения?
              </label>
            </div>
          </div>

          { values.data.edu &&
            <div className={form.elem}>
              <div className={form.label}>
                Название образовательной программы с применением дистанционных технологий и электронного обучения
              </div>

              <div className={form.input}>
                <input type="text" name="edu_program" value={values.data.edu_program} onChange={this.handleInputChange} />
              </div>
            </div>
          }

          <div className={styles.desc}>
            Указывать данные об абитуриентах, зачисленных и обучающихся за текущий учебный год на момент заполнения формы или на 1 октября текущего года, а выпускников за предыдущий учебный год.
          </div>

          <div className={styles.headers}>
            <div className={styles.label} />
            <div>Очная</div>
            <div>Заочная</div>
            <div>Оч.-зао.</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.1.1. Общая численность абитуриентов {monitoring.current_year} года (число всех поданных заявлений, включая лиц без инвалидности и ОВЗ)
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_ovall_f" value={values.data.abitur_ovall_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_ovall_p" value={values.data.abitur_ovall_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_ovall_fp" value={values.data.abitur_ovall_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.1.2. Численность абитуриентов с нарушениями зрения
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_e_f" value={values.data.abitur_e_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_e_p" value={values.data.abitur_e_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_e_fp" value={values.data.abitur_e_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.1.3. Численность абитуриентов с нарушениями слуха
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_h_f" value={values.data.abitur_h_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_h_p" value={values.data.abitur_h_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_h_fp" value={values.data.abitur_h_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.1.4. Численность абитуриентов с нарушениями ОДА (мобильные)
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_mm_f" value={values.data.abitur_mm_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_mm_p" value={values.data.abitur_mm_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_mm_fp" value={values.data.abitur_mm_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.1.5. Численность абитуриентов с нарушениями ОДА (на кресле-коляске)
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_mn_f" value={values.data.abitur_mn_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_mn_p" value={values.data.abitur_mn_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_mn_fp" value={values.data.abitur_mn_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.1.6. Численность абитуриентов с соматическими заболеваниями
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_s_f" value={values.data.abitur_s_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_s_p" value={values.data.abitur_s_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_s_fp" value={values.data.abitur_s_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.1.7. Численность абитуриентов с психическими заболеваниями
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_p_f" value={values.data.abitur_p_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_p_p" value={values.data.abitur_p_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_p_fp" value={values.data.abitur_p_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.1.8. Численность абитуриентов с инвалидностию и ОВЗ без указания диагноза
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_o_f" value={values.data.abitur_o_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_o_p" value={values.data.abitur_o_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="abitur_o_fp" value={values.data.abitur_o_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.sep} />

          <div className={styles.headers}>
            <div className={styles.label} />
            <div>Очная</div>
            <div>Заочная</div>
            <div>Оч.-зао.</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.2.1. Всего зачислено на 1 курс на 1 октября {monitoring.current_year} года (всех, включая лиц без инвалидности и ОВЗ)
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_ovall_f" value={values.data.submit_ovall_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_ovall_p" value={values.data.submit_ovall_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_ovall_fp" value={values.data.submit_ovall_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.2.2. Численность зачисленных с нарушениями зрения
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_e_f" value={values.data.submit_e_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_e_p" value={values.data.submit_e_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_e_fp" value={values.data.submit_e_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.2.3. Численность зачисленных с нарушениями слуха
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_h_f" value={values.data.submit_h_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_h_p" value={values.data.submit_h_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_h_fp" value={values.data.submit_h_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.2.4. Численность зачисленных с нарушениями ОДА (мобильные)
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_mm_f" value={values.data.submit_mm_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_mm_p" value={values.data.submit_mm_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_mm_fp" value={values.data.submit_mm_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.2.5. Численность зачисленных с нарушениями ОДА (на кресле-коляске)
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_mn_f" value={values.data.submit_mn_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_mn_p" value={values.data.submit_mn_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_mn_fp" value={values.data.submit_mn_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.2.6. Численность зачисленных с соматическими заболеваниями
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_s_f" value={values.data.submit_s_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_s_p" value={values.data.submit_s_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_s_fp" value={values.data.submit_s_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.2.7. Численность зачисленных с психическими заболеваниями
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_p_f" value={values.data.submit_p_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_p_p" value={values.data.submit_p_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_p_fp" value={values.data.submit_p_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.2.8. Численность зачисленных с инвалидностию и ОВЗ без указания диагноза
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_o_f" value={values.data.submit_o_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_o_p" value={values.data.submit_o_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="submit_o_fp" value={values.data.submit_o_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.sep} />

          <div className={styles.headers}>
            <div className={styles.label} />
            <div>Очная</div>
            <div>Заочная</div>
            <div>Оч.-зао.</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.3.1. Общая численность обучающихся за текущий ({monitoring.period_year.map(y => y + 1).join('/')}) уч. год (всех, включая лиц без инвалидности и ОВЗ)
            </div>

            <div className={styles.input}>
              <input type="text" name="student_ovall_f" value={values.data.student_ovall_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_ovall_p" value={values.data.student_ovall_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_ovall_fp" value={values.data.student_ovall_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.3.2. Численность обучающихся с нарушениями зрения
            </div>

            <div className={styles.input}>
              <input type="text" name="student_e_f" value={values.data.student_e_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_e_p" value={values.data.student_e_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_e_fp" value={values.data.student_e_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.3.3. Численность обучающихся с нарушениями слуха
            </div>

            <div className={styles.input}>
              <input type="text" name="student_h_f" value={values.data.student_h_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_h_p" value={values.data.student_h_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_h_fp" value={values.data.student_h_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.3.4. Численность обучающихся с нарушениями ОДА (мобильные)
            </div>

            <div className={styles.input}>
              <input type="text" name="student_mm_f" value={values.data.student_mm_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_mm_p" value={values.data.student_mm_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_mm_fp" value={values.data.student_mm_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.3.5. Численность обучающихся с нарушениями ОДА (на кресле-коляске)
            </div>

            <div className={styles.input}>
              <input type="text" name="student_mn_f" value={values.data.student_mn_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_mn_p" value={values.data.student_mn_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_mn_fp" value={values.data.student_mn_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.3.6. Численность обучающихся с соматическими заболеваниями
            </div>

            <div className={styles.input}>
              <input type="text" name="student_s_f" value={values.data.student_s_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_s_p" value={values.data.student_s_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_s_fp" value={values.data.student_s_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.3.7. Численность обучающихся с психическими заболеваниями
            </div>

            <div className={styles.input}>
              <input type="text" name="student_p_f" value={values.data.student_p_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_p_p" value={values.data.student_p_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_p_fp" value={values.data.student_p_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.3.8. Численность обучающихся с инвалидностию и ОВЗ без указания диагноза
            </div>

            <div className={styles.input}>
              <input type="text" name="student_o_f" value={values.data.student_o_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_o_p" value={values.data.student_o_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_o_fp" value={values.data.student_o_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.3.9. Численность обучающихся по адаптированным программам из лиц с инвалидностью и ОВЗ
            </div>

            <div className={styles.input}>
              <input type="text" name="student_ad_f" value={values.data.student_ad_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_ad_p" value={values.data.student_ad_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="student_ad_fp" value={values.data.student_ad_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="student_afc" checked={values.data.student_afc} onChange={this.handleInputChange} />
                {num}.3.10. Для направления реализуется дисциплина «Адаптивная физическая культура и спорт для обучающихся с ограниченными возможностями здоровья и с инвалидностью»
              </label>
            </div>
          </div>

          {values.data.student_afc &&
            <>

              <div className={styles.row}>
                <div className={styles.label}>
                  {num}.3.10.1. Количество обучающихся, занимающихся по дисциплине
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_d_f" value={values.data.student_afc_d_f} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_d_p" value={values.data.student_afc_d_p} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_d_fp" value={values.data.student_afc_d_fp} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.label}>
                  {num}.3.10.2. Численность обучающихся с нарушением зрения
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_e_f" value={values.data.student_afc_e_f} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_e_p" value={values.data.student_afc_e_p} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_e_fp" value={values.data.student_afc_e_fp} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.label}>
                  {num}.3.10.3. Численность обучающихся с нарушением слуха
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_h_f" value={values.data.student_afc_h_f} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_h_p" value={values.data.student_afc_h_p} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_h_fp" value={values.data.student_afc_h_fp} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.label}>
                  {num}.3.10.4. Численность обучающихся с нарушением опорно-двигательного аппарата (мобильные)
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_mm_f" value={values.data.student_afc_mm_f} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_mm_p" value={values.data.student_afc_mm_p} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_mm_fp" value={values.data.student_afc_mm_fp} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.label}>
                  {num}.3.10.5. Численность обучающихся с нарушением опорно-двигательного аппарата (на кресле-коляске)
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_mn_f" value={values.data.student_afc_mn_f} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_mn_p" value={values.data.student_afc_mn_p} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_mn_fp" value={values.data.student_afc_mn_fp} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.label}>
                  {num}.3.10.6. Численность обучающихся с соматическими заболеваниями
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_s_f" value={values.data.student_afc_s_f} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_s_p" value={values.data.student_afc_s_p} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_s_fp" value={values.data.student_afc_s_fp} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.label}>
                  {num}.3.10.7. Численность обучающихся с психическими заболеваниями
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_p_f" value={values.data.student_afc_p_f} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_p_p" value={values.data.student_afc_p_p} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_p_fp" value={values.data.student_afc_p_fp} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.label}>
                  {num}.3.10.8. Численность обучающихся с ОВЗ и инвалидностью без указания диагноза
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_o_f" value={values.data.student_afc_o_f} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_o_p" value={values.data.student_afc_o_p} onChange={this.handleInputChange} />
                </div>

                <div className={styles.input}>
                  <input type="text" name="student_afc_o_fp" value={values.data.student_afc_o_fp} onChange={this.handleInputChange} />
                </div>
              </div>
            </>
          }

          <div className={styles.sep} />

          <div className={styles.headers}>
            <div className={styles.label} />
            <div>Очная</div>
            <div>Заочная</div>
            <div>Оч.-зао.</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.4.1. Общая численность выпускников за предыдущий ({monitoring.period_year.join('/')}) уч. год (всех, включая лиц без инвалидности и ОВЗ)
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_ovall_f" value={values.data.graduate_ovall_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_ovall_p" value={values.data.graduate_ovall_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_ovall_fp" value={values.data.graduate_ovall_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.4.2. Численность выпускников с нарушениями зрения
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_e_f" value={values.data.graduate_e_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_e_p" value={values.data.graduate_e_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_e_fp" value={values.data.graduate_e_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.4.3. Численность выпускников с нарушениями слуха
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_h_f" value={values.data.graduate_h_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_h_p" value={values.data.graduate_h_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_h_fp" value={values.data.graduate_h_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.4.4. Численность выпускников с нарушениями ОДА (мобильные)
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_mm_f" value={values.data.graduate_mm_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_mm_p" value={values.data.graduate_mm_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_mm_fp" value={values.data.graduate_mm_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.4.5. Численность выпускников с нарушениями ОДА (на кресле-коляске)
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_mn_f" value={values.data.graduate_mn_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_mn_p" value={values.data.graduate_mn_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_mn_fp" value={values.data.graduate_mn_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.4.6. Численность выпускников с соматическими заболеваниями
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_s_f" value={values.data.graduate_s_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_s_p" value={values.data.graduate_s_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_s_fp" value={values.data.graduate_s_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.4.7. Численность выпускников с психическими заболеваниями
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_p_f" value={values.data.graduate_p_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_p_p" value={values.data.graduate_p_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_p_fp" value={values.data.graduate_p_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.4.8. Численность выпускников с инвалидностию и ОВЗ без указания диагноза
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_o_f" value={values.data.graduate_o_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_o_p" value={values.data.graduate_o_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_o_fp" value={values.data.graduate_o_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>
              {num}.4.9. Численность выпускников трудоустроившихся из лиц с инвалидностью и ОВЗ
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_wrk_f" value={values.data.graduate_wrk_f} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_wrk_p" value={values.data.graduate_wrk_p} onChange={this.handleInputChange} />
            </div>

            <div className={styles.input}>
              <input type="text" name="graduate_wrk_fp" value={values.data.graduate_wrk_fp} onChange={this.handleInputChange} />
            </div>
          </div>

          <button className={classNames(buttons.small, buttons.cancel)} onClick={this.handleCancel}>Отменить</button>
          <button className={classNames(buttons.small)} onClick={this.handleSubmit}  disabled={!this._canSubmit()}>Сохранить</button>
          <button className={classNames(buttons.small)} onClick={this.handleDelete}>Удалить</button>
        </div>
      </div>
    );
  }
}

Program.defaultProps = {
  num: '12'
};

export default withAuthToken(Program);
