import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Link from '../Routes'
import I18n from '../I18n'

import buttons from '../Buttons.module.css'
import styles from './RequestNewBlock.module.css'

class RequestNewBlock extends Component {
  render () {
    const { title, text } = this.props

    return (
      I18n.locale === 'ru' ? (
        <div className={styles.root}>
          <h2>
            {title}
          </h2>

          <p>
            {text}
          </p>

          <p>
            <Link to="contacts_path" className={buttons.black}>Подробнее</Link>
          </p>
        </div>
      ) : (
        <div className={styles.root}>
          <h2>
            Hot line on the questions of applying for universities
          </h2>

          <p>
            Starting June 20 there has been set up a ‘hot line’ of Resource educational-methodological centers for informing people with disabilities on the questions of university application
          </p>

          <p>
            This option is not available on your language.
          </p>
        </div>
      )
    )
  }
}

RequestNewBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
}

export default RequestNewBlock
