import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import update from 'immutability-helper'

import Loaf from '../Loaf/Loaf'
import { path } from '../Routes'
import Form from './Form'

import page from '../Page'
import styles from './Index.module.css'
import buttons from '../Buttons.module.css'

class Call extends React.Component {
  state = {
    call: this.props.call,
    edit: false
  }

  handleClose = () => {
    this.setState({ edit: false })
  }

  handleUpdate = (call) => {
    this.setState({ call: call, edit: false })
  }

  render () {
    const { subjects, audiences, schools } = this.props
    const { edit, call } = this.state

    return (
      <>
        {edit ? (
          <Form call={call} subjects={subjects} audiences={audiences} schools={schools} onClose={this.handleClose} onUpdate={this.handleUpdate} />
        ) : (
          <div className={styles.call} onClick={() => this.setState({ edit: true })}>
            <div className={styles.id}>
              <strong>
                № {call.id}
              </strong> от {dayjs(call.date).format('DD.MM.YYYY')}
            </div>

            <div className={styles.subject}>
              {call.subject.title}  / {call.school ? call.school.title : call.audience.title}
            </div>
          </div>
        )}
      </>
    )
  }
}

class Index extends React.Component {
  state = {
    create: false,
    calls: null,
    show: 50
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) {
      this._asyncRequest.cancel()
    }
  }

  _loadAsyncData () {
    this._asyncRequest = axios.CancelToken.source()
    axios.get(path('calls_path', {}, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          calls: res.data.calls,
          rumc: res.data.rumc,
          subjects: res.data.dictionaries.subjects,
          audiences: res.data.dictionaries.audiences,
          schools: res.data.dictionaries.schools,
          overall: res.data.overall,
          call_subjects: res.data.call_subjects
        })

        this._asyncRequest = null
      })
  }

  handleUpdate = call => {
    const calls = update(this.state.calls, {
      $unshift: [call]
    })

    this.setState({ calls: calls, create: false })
  }

  render () {
    const { show, subjects, audiences, schools, overall, call_subjects, rumc } = this.state
    let { calls, create } = this.state

    if (calls) {
      if (show) calls = calls.slice(0, show)
    }

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>Кабинет</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Обращения в call-центр
            </h1>
          </div>

          {rumc &&
            <div className={styles.file}>
              <a href={`${(path('stats_calls_path', { id: 3 }))}.xlsx`}>Скачать результаты в Excel</a>
            </div>
          }

          {overall &&
            <div className={styles.overall}>
              {overall.map((value) =>
                <div>{value.title}: {value.value}</div>
              )}
            </div>
          }

          {call_subjects &&
            <div className={styles.overall}>
              {call_subjects.map(subject =>
                <div>{subject.title}: {subject.size}</div>
              )}
            </div>
          }

          <div className={styles.root}>
            {calls &&
              <div className={styles.calls}>
                <div>
                  {create ? (
                    <Form call={{}} subjects={subjects} audiences={audiences} schools={schools} onClose={() => this.setState({ create: false })} onUpdate={this.handleUpdate} />
                  ) : (
                    <button className={buttons.small} onClick={() => this.setState({ create: true })}>Новое обращение</button>
                  )}
                </div>
                {calls.map(call =>
                  <Call key={call.id} call={call} subjects={subjects} audiences={audiences} schools={schools} />
                )}
                <div className={styles.more}>
                  <button className={buttons.black} onClick={() => this.setState(state => ({ show: show + 100 }))}>Загрузить еще</button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Index
