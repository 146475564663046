import React from 'react'
// import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Form from './Form'
import Show from './Show'

class EventReports extends React.Component {
  render () {
    return (
      <React.Fragment>
        <Switch>
          <Route exact strict path={decodeURIComponent(Routes.event_event_report_path)} render={props => <Show event_id={props.match.params.event_id} />} />
          <Route path={decodeURIComponent(Routes.new_event_event_report_path)} render={props => <Form event_id={props.match.params.event_id} />} />
          <Route path={decodeURIComponent(Routes.edit_event_event_report_path)} render={props => <Form event_id={props.match.params.event_id} id={true} />} />
        </Switch>
      </React.Fragment>
    )
  }
}

export default EventReports
