import React from 'react'
import PropTypes from 'prop-types'
// import classNames from 'classnames'
import Select from 'react-select'

import I18n from '../../I18n'

// import styles from './References.module.css'

References.propTypes = {
  element: PropTypes.object,
  dictionaries: PropTypes.object,
  value: PropTypes.string,
  onValueChange: PropTypes.func
}

export default function References ({ value, element, dictionaries, onValueChange }) {
  let dictionary = null

  switch (element.settings) {
    case 'Region':
      dictionary = dictionaries.regions
      break

    case 'School':
      dictionary = dictionaries.schools
      break
  }

  // console.log(dictionary)

  return (
    <Select
      classNamePrefix="react-select"
      value={dictionary.find(region => region.id.toString() === value)}
      getOptionValue={option => option.id}
      noOptionsMessage={() => 'Ничего не найдено'}
      options={dictionary}
      placeholder={I18n.t(`form.references.choose.${element.settings.toLowerCase()}`)}
      onChange={value => onValueChange && onValueChange(value.id.toString())}
    />
  )
}
