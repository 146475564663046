import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { getPath } from '../../Routes'

import Index from './Index'
import Table from './Table'

class Schools extends React.Component {
  render () {
    return (
      <React.Fragment>
        <Switch>
          <Route path={decodeURIComponent(getPath('stats_schools_table_path'))} component={Table} />
          <Route path={decodeURIComponent(getPath('stats_schools_path'))} component={Index} />
        </Switch>
      </React.Fragment>
    )
  }
}

export default Schools
