import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import gradient_background from '../../../images/new_schools/radar-gradient.svg'


ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
ChartJS.defaults.color = "#000";
ChartJS.defaults.borderColor = "#fff";


let image = new Image();
image.src = gradient_background;

const plugin = {
    id: 'custom_canvas_background_image',
    beforeDraw: (chart) => {
      if (image.complete) {
        const ctx = chart.ctx;
        const {top, left, width, height} = chart.chartArea;
        const x = left + width / 2 - image.width / 2;
        const y = top + height / 2 - image.height / 2;
        ctx.drawImage(image, x, y);
      } else {
        image.onload = () => chart.draw();
      }
    }
};

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    backgroundColor : "red",
    plugins: {
        legend: {
            position: 'top',
            labels: {
                font: {
                    size: window.innerWidth > 480 ? 14 : 10,
                    family: 'Fira Sans',
                    weight: 400,
                    lineHeight: 1.4
                }
            }
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
    scales: {
        r: {
            suggestedMax: 13,
            ticks: {
                display: false,
                font: {
                    size: 0,
                    weight: 500,
                    family: 'Fira Sans',
                    fontColor: '#fff'
                },
            },
            pointLabels: {
                font: {
                    size: window.innerWidth > 480 ? 14 : 10,
                    family: 'Fira Sans',
                    weight: 400,
                    lineHeight: 1.4,
                    fontColor: '#fffff'
                }
            },
            angleLines: {
                display: false
            },
            grid: {
                display: false
            },
        }
    },
    title: {
        display: true
    },
    legend: {
        display: true
    }
}


const RadarChart = (props) => {
    let colors = [
        {
            backgroundColor: 'rgba(85, 85, 85, 0.5)',
            borderColor: 'rgb(85, 85, 85)',
            pointBackgroundColor: '#555555',
            pointBorderColor: '#555555',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(54, 162, 235)'
        },
        {
            backgroundColor: 'rgba(255, 122, 0, 0.5)',
            borderColor: '#FFD609',
            pointBackgroundColor: '#FF7C01',
            pointBorderColor: '#FF7C01',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'
        },
        {
            backgroundColor: 'rgb(159, 210, 253, 0.5)',
            borderColor: '#97C8F0',
            pointBackgroundColor: '#67b2f0',
            pointBorderColor: '#67b2f0',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(54, 162, 235)'
        },
        {
            backgroundColor: 'rgba(255, 122, 0, 0.2)',
            borderColor: 'rgba(255, 214, 9, 0.5);',
            pointBackgroundColor: 'rgba(255, 124, 1, 0.5);',
            pointBorderColor: 'rgba(255, 124, 1, 0.5)',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'
        },
    ];
    let datasets = [];
    let color_index = 0;
    for (let i of props.data) {
        if (i.e_1_norm) {
            datasets.push({
                label: 'показатели вуза за ' + i.year + ' год',
                data: [i.e_1_norm, i.e_2_norm, i.e_3_norm, i.e_4_norm, i.e_5_norm, i.e_8_norm],
                fill: true,
                ...colors[color_index] ? colors[color_index] : colors[1]
            })
            color_index++;
        }
    }
    datasets.reverse()

    let data = {
        labels: window.innerWidth > 480 ? [
            'Е.1. Образовательная деятельность',
            'Е.2. Научно-исследовательская деятельность',
            'Е.3. Международная деятельность',
            'Е.4. Финансово-экономическая деятельность',
            'Е.5. Заработная плата ППС',
            'Е.8. Дополнительный показатель',
        ] : [
            'Е.1.',
            'Е.2.',
            'Е.3.',
            'Е.4.',
            'Е.5.',
            'Е.8.',
        ],
        datasets: datasets
    }
    return <Radar data={data} options={options} plugins={[plugin]} />;
}

export default RadarChart
