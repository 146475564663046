import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../../Routes'

import Form from './Form'
// import Show from './Show'

export default function FacilityTypes () {
  return (
    <Switch>
      <Route path={[Routes.new_account_facility_type_path, Routes.edit_account_facility_type_path]} exact strict>
        <Form />
      </Route>
    </Switch>
  )
}
