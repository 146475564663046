import React from 'react'
import PropTypes from 'prop-types'

import Link from '../../../Routes'

import styles from './Themes.module.css'

Themes.propTypes = {
  planThemes: PropTypes.array
}

export default function Themes ({ planThemes }) {
  return (
    <div className={styles.themes}>
      {planThemes.map((planTheme, i) =>
        <Link key={planTheme.id} className={styles.theme} to="account_plan_theme_path" params={{ id: planTheme.id }}>
          <div className={styles.type}>
            Тема № {i + 1}
          </div>
          <div className={styles.title}>
            {planTheme.title}
          </div>
          <div className={styles.metas}>
            <div className={styles.meta}>
              3 лекции
            </div>

            <div className={styles.meta}>
              2 задания
            </div>

            <div className={styles.meta}>
              1 работа
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}
