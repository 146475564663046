import React from 'react'
import { Helmet } from 'react-helmet-async'

import page from '../../Page.module.css'

export default function NoRumc () {
  const title = 'Ошибка выбора ресурсного центра'

  return (
    <div className={page.root}>
      <div className={page.container}>
        <div className={page.narrow}>
          <div className={page.title}>
            <h1>{title}</h1>

            <Helmet>
              <title>
                {title}
              </title>
            </Helmet>
          </div>

          <p>
            Мы не смогли определить ресурсный центр к которому будет привязана анкета, скорее всего, произошла ошибка при передаче ссылки на анкету  и в ней не хватает одного параметра. Обратитесь к тому, кто дал вам эту ссылку, чтобы он предоставил вам правильную ссылку.
          </p>
        </div>
      </div>
    </div>
  )
}
