import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from '../../Routes';

import Form from './Form';

class ReportItems extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path={decodeURIComponent(Routes.new_account_report_item_path)} component={Form} />
          <Route exact path={decodeURIComponent(Routes.edit_account_report_item_path)} render={props => <Form id={props.match.params.id} {...props} />} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default ReportItems;
