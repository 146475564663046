import React from 'react'
// import PropTypes from 'prop-types'
import axios from 'axios'

import { path } from '../Routes'

import Form from './Form'

import styles from './Comments.module.css'

class Comments extends React.Component {
  state = {
    comments: null,
    trackers: null
  }

  componentDidMount () {
    this._loadAsyncData(this.props.id)
  }

  _loadAsyncData (id) {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('comments_path', {}, true), { params: { object: this.props.object, id: this.props.id } })
      .then(res => {
        this.setState({
          comments: res.data.comments,
          trackers: res.data.trackers
        })

        this._asyncRequest = null
      })
  }

  handleCommentsUpdate = (newComments) => {
    this.setState({
      comments: newComments
    })
  }

  render () {
    const { comments, trackers } = this.state
    let list = []

    if (comments) list = list.concat(comments)
    if (trackers) list = list.concat(trackers)

    list = list.sort((a, b) => new Date(a.sorted_at) - new Date(b.sorted_at))

    return (
      <div className={styles.root}>
        <h2>
          Комментарии
        </h2>
        <div className={styles.comments}>
          {list &&
            list.map(item =>
              <div className={styles.comment} key={`${item.type}_${item.id}`}>
                {item.type === 'comment' &&
                  <>
                    <div className={styles.header}>
                      <div className={styles.author}>
                        {item.author.name}
                      </div>
                      <div className={styles.created_at}>
                        {item.created_at_human}
                      </div>
                    </div>
                    <div className={styles.body} dangerouslySetInnerHTML={{ __html: item.body }} />
                  </>
                }

                {item.type === 'tracker' &&
                  <div className={styles.header}>
                    <div className={styles.act}>
                      {item.act_human}
                    </div>
                    <div className={styles.author}>
                      {item.user.name}
                    </div>
                    <div className={styles.created_at}>
                      {item.created_at_human}
                    </div>
                  </div>
                }
              </div>
            )
          }

          <Form object={this.props.object} id={this.props.id} onCommentsUpdate={this.handleCommentsUpdate}/>
        </div>
      </div>
    )
  }
}

export default Comments
