import React, { Component, useContext } from 'react'

import Current from '../Current'

import styles from './Loaf.module.css'

class Loaf extends Component {
  render () {
    return (
      <div className={styles.root}>
        {this.props.children}
      </div>
    )
  }
}

export default Loaf

export function useLoaf () {
  const { loaf, setLoaf } = useContext(Current)

  return {
    loaf,
    setLoaf
  }
}
