import React, { Component } from 'react';

import Item from './Item';

import section from './Section.module.css';

class Finance extends Component {
  render () {
    const { data } = this.props

    return (
      <div className={section.root}>
        <div className={section.title}>
          <h2>
            Финансово-экономическое обеспечение
          </h2>
        </div>

        <div className={section.list}>
          <Item data={data.bbs}  label={'stats.finance.bbs'}  />
          <Item data={data.tech} label={'stats.finance.tech'} />
          <Item data={data.empl} label={'stats.finance.empl'} />
          <Item data={data.meth} label={'stats.finance.meth'} />
        </div>
      </div>
    );
  }
}

export default Finance;
