import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Index'
import Show from './Show'
import Facilities from './Facilities'

export default function Rumcs () {
  return (
    <Switch>
      <Route path={Routes.rumc_facilities_path}>
        <Facilities />
      </Route>

      <Route path={Routes.rumc_path}>
        <Show />
      </Route>

      <Route path={Routes.rumcs_path}>
        <Index />
      </Route>
    </Switch>
  )
}
