import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import New from './New'
import Show from './Show'
import Index from './Index'

export default function Requests () {
  return (
    <Switch>
      <Route path={Routes.new_request_path}>
        <New />
      </Route>

      <Route path={Routes.request_path}>
        <Show />
      </Route>

      <Route path={Routes.requests_path}>
        <Index />
      </Route>
    </Switch>
  )
}
