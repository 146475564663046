import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { animateScroll as scroll, scroller } from 'react-scroll';
import redraft from 'redraft';

import VideoThumb from '../../images/stages/video_thumb.png';
import AttachmentsList from '../Attachments/AttachmentsList';

import styles from './StagesList.module.css';
import buttons from '../Buttons.module.css';

const renderers = {
  inline: {
    BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
  },
  blocks: {
    unstyled: (children) => children.map(child => <p>{child}</p>),
    blockquote: (children) => <blockquote>{children}</blockquote>,
    'header-three': (children) => children.map(child => <h2>{child}</h2>),
    'header-two': (children) => children.map(child => <h2>{child}</h2>),
    'unordered-list-item': (children, { depth, keys }) => <ul key={keys[keys.length - 1]} className={`ul-level-${depth}`}>{children.map(child => <li>{child}</li>)}</ul>,
    'ordered-list-item': (children, { depth, keys }) => <ol key={keys.join('|')} className={`ol-level-${depth}`}>{children.map((child, index) => <li key={keys[index]}>{child}</li>)}</ol>,
  },
  entities: {
    LINK: (children, data, { key }) => <a key={key} target="_blank" href={data.url}>{children}</a>,
  },
}

class StagesList extends React.Component {
  state = {
    stages: this.props.stages || null,
    active: false,
    show: true
  }

  static getDerivedStateFromProps(props, state) {
    const active = props.location.hash.substring(1);
    if(active && active != state.active) {
      return {
        active,
        show: false
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.active != prevState.active) {
      this.setState({ show: false });
      this.scrollToText();
    }
  }

  scrollToText() {
    scroller.scrollTo('toc', {
      duration: 500,
      smooth: true,
      offset: -100,
    });
  }

  render() {
    const { stages, active, show } = this.state;
    if(!stages) return null;

    const stage = stages.find(s => s.id == active);
    let next = false;
    let prev = false;
    let video = false;
    let subtitles = false;
    if(stage) {
      const current_id = stages.findIndex(s => stage.id == s.id);
      if(stages[current_id + 1]) next = stages[current_id + 1];
      if(stages[current_id - 1]) prev = stages[current_id - 1];
      video = stage.attachments.filter(a => a.section == 'video');
      subtitles = stage.attachments.filter(a => a.section == 'subtitles')[0];
    }

    let desc = null;

    if(stage) {
      desc = redraft(stage.desc, renderers);
    }

    return (
      <>
        <div name="toc">
          { stage && video && video.length > 0 &&
            <video className={styles.video} src={video[0].url} controls poster={VideoThumb}>
            </video>
          }

          <div className={classNames(styles.toc, { [styles.hidden]: !show })}>
            <div className={styles.toggle} onClick={() => this.setState(state => ({ show: !state.show }))}>
              Список разделов
            </div>

            <ol className={styles.stages}>
              {stages.map(stage =>
                <li className={classNames(styles.stage, { [styles.active]: stage.id == active })} key={stage.id}>
                  <Link to={`${this.props.location.pathname}#${stage.id}`}>
                    <div className={styles.title}>
                      {stage.title}
                    </div>
                  </Link>
                </li>
              )}
            </ol>
          </div>
        </div>


        {stage &&
          <div className={styles.text} name="text">
            <h3>
              {stage.title}
            </h3>

            {stage.attachments &&
              <div className={styles.item}>
                <AttachmentsList attachments={stage.attachments} />
              </div>
            }

            {desc}


            {(next || prev) &&
              <div className={styles.nav}>
                {prev &&
                  <Link to={`${this.props.location.pathname}#${prev.id}`} className={styles.prev}>
                    Предыдущая лекция
                  </Link>
                }

                {next &&
                  <Link to={`${this.props.location.pathname}#${next.id}`} className={styles.next}>
                    Следующая лекция
                  </Link>
                }
              </div>
            }
          </div>
        }
      </>
    );
  }
}

export default StagesList;
