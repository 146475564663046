import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
// import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import ReactMarkdown from 'react-markdown'
import Gallery from 'react-photo-gallery'
// import Lightbox from 'react-images'
// import Carousel from 'react-images'
import Carousel, { Modal, ModalGateway } from 'react-images'

import I18n from '../I18n'
import Link, { path } from '../Routes'
import Loaf from '../Loaf/Loaf'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'

class Show extends React.Component {
  state = {
    event_report: null,
    event: null,
    currentImage: 0,
    viewerIsOpen: false
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData (id) {
    this._asyncRequest = axios.CancelToken.source()
    axios.get(path('event_event_report_path', { event_id: this.props.event_id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          event_report: res.data.event_report,
          attachments: res.data.attachments,
          event: res.data.event
        })

        this._asyncRequest = null
      })
  }

  openLightbox = (event, obj) => {
    this.setState({
      currentImage: obj.index,
      viewerIsOpen: true
    })
  }

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      viewerIsOpen: false
    })
  }

  render () {
    const { event_report, event, attachments, viewerIsOpen, currentImage } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_events_path">{I18n.t('events.nav')}</Link>
            {event &&
              <Link to="account_event_path" params={{ id: event.id }}>
                {event.title}
              </Link>
            }
          </Loaf>

          {event_report &&
            <React.Fragment>
              {event_report.can_edit &&
                <div className={page.control}>
                  <Link to="edit_event_event_report_path" params={{ event_id: event.id }} className={buttons.small}>Редактировать отчёт</Link>
                </div>
              }

              <div className={page.title}>
                <h1>
                  {event_report.title}
                </h1>
                <Helmet>
                  <title>
                    {event_report.title}
                  </title>
                </Helmet>
              </div>

              <div className={page.text}>
                <ReactMarkdown source={event_report.body} />
              </div>

              <div>
                <Gallery
                  onClick={this.openLightbox}
                  photos={attachments.filter(a => a.width && a.height).map(a => ({ src: a.thumb, width: a.width, height: a.height }))}
                />

                {/* <Carousel images={attachments.filter(a => a.width && a.height).map(a => ({ src: a.large, width: a.width, height: a.height }))}
                  onClose={this.closeLightbox}
                  onClickPrev={this.gotoPrevious}
                  onClickNext={this.gotoNext}
                  currentImage={this.state.currentImage}
                  isOpen={this.state.lightboxIsOpen}
                /> */}

                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={this.closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={attachments.filter(a => a.width && a.height).map(a => ({ src: a.large, width: a.width, height: a.height }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    )
  }
}
export default Show
