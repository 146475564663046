import React, { Component } from 'react';

import I18n from '../../I18n'

import styles from './Reports.module.css';

class Reports extends Component {
  render() {
    const { reports } = this.props;


    return (
      <div className={styles.root}>
        <div className={styles.total}>
          <div className={styles.title}>
            {I18n.t('stats.ministries.total')}
          </div>

          <div className={styles.value}>
            {reports.archived}
          </div>

          <div className={styles.percent}>
            {~~(reports.archived / reports.expected * 100)}%
          </div>

        </div>

        <div className={styles.ministries}>
          {reports.ministries.map(ministry =>
            <div className={styles.ministry}>
              <div className={styles.title}>
                {I18n.t(`stats.ministries.${ministry.id}`)}
              </div>

              <div className={styles.value}>
                {ministry.reports.archived}
              </div>

              <div className={styles.percent}>
                {~~(ministry.reports.archived / ministry.reports.expected * 100)}%
              </div>

            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Reports
