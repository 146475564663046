import React from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { path } from '../Routes'
import I18n from '../I18n'

import styles from './Social.module.css'

Social.propTypes = {
  or: PropTypes.bool
}

export default function Social ({ or }) {
  const location = useLocation()
  const redirectTo = location.pathname

  return (
    <div>
      <div className={styles.desc}>
        Войти через соцсети
      </div>

      <div className={styles.networks}>
        <a className={styles.vkontakte} href={`${path('auth_at_provider_path', { provider: 'vk' })}${redirectTo ? `?redirect_to=${redirectTo}` : ''}`}>
          {I18n.t('auth.vkontakte')}
        </a>
      </div>

      {or &&
        <div className={styles.or}>
          Или с помощью электронной почты
        </div>
      }
    </div>
  )
}
