import React, { Component } from 'react'
import axios from 'axios'
import Link, { path } from '../../Routes'

import styles from '../Index.module.css'

class Cards extends Component {
  state = {
    cards: []
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('index_card_index_path', {}, true), { cancelToken: this._asyncRequest.token })
    this.setState({ ... res.data })
    this._asyncRequest = null
  }

  render () {
    let cards = this.state.cards
    return (
      <div className={styles.cards_container}>
        {cards.map((card) => (
          // TODO: target: _blank ?
          <a key={card.id} target="_blank" href={card.href} className={styles.cards_item} style={{width: card.width, backgroundImage: `url(${card.background})`}}>
            <h3>{card.title}</h3>

            <p>{card.desc}</p>

            {card.urls.map((url) => (
              <p key={url.id}><a href={url.url} onClick={(e) => {e.stopPropagation()}} target='_black'>{url.name}</a></p>
            ))}

            <div className={styles.ph} />

            <div className={styles.more}>
              <span>
                {card.date}
              </span>
            </div>
          </a>
        ))}
      </div>
    )
  }
}

export default Cards
