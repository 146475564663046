import React, { useState } from 'react'
import styles from './Preloader.module.css'


export default function Preloader () {
  const [update, setUpdate] = useState(true);

  function timer() {
    setTimeout(() => {
      setUpdate(!update);
    }, 1432)
  }

  return (
    <div>
      {update &&
        <div className={styles.preloader_wrapper} onClick={timer()}>
          <div className={styles.round_2}></div>
          <div className={styles.round_1}></div>
        </div>
      }
      {!update &&
        <div className={styles.preloader_wrapper} onClick={timer()}>
          <div className={styles.round_2}></div>
          <div className={styles.round_1}></div>
        </div>
      }
    </div>
  )
}