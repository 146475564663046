import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import classNames from 'classnames'
import { useDropzone } from 'react-dropzone'
import uuidv4 from 'uuid/v4'

import { path } from '../Routes'

import I18n from '../I18n'

import styles from './Files.module.css'
import form from '../Form.module.css'

Files.propTypes = {
  files: PropTypes.array,
  sections: PropTypes.array,
  onFilesChanged: PropTypes.func,
  single: PropTypes.bool
}

export default function Files (props) {
  const { onFilesChanged, single } = props

  const [sections, setSections] = useState(new Map([...props.sections.map(s => [s, true])]))
  const [files, setFiles] = useState(new Map())

  useEffect(() => {
    if (props.files) {
      const newFiles = new Map(files)
      const newSections = new Map(sections)

      props.files.forEach(file => {
        if (!files.has(file.uuid)) {
          newFiles.set(file.uuid, file)

          if (sections.has(file.section)) {
            newSections.set(file.section, false)
          }
        }
      })

      setSections(newSections)
      setFiles(newFiles)
    }
  }, [props.files])

  const onDrop = acceptedFiles => {
    const newFiles = new Map(files)

    acceptedFiles.forEach(file => {
      newFiles.set(uuidv4(), file)
    })

    setFiles(newFiles)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleFileChanged = attachment => {
    const newFiles = new Map(files)
    newFiles.set(attachment.uuid, attachment)

    setFiles(newFiles)
  }

  const handleFileDeleted = async (attachment) => {
    const newFiles = new Map(files)

    newFiles.delete(attachment.uuid)
    console.log(attachment.id)
    await axios.delete(
      path('attachment_path', { id: attachment.id })
    ).then(() => {
      setFiles(newFiles)
    }).catch(_error => {
      // setErrors(error.response.data)
    })

    setFiles(newFiles)
  }

  const handleSectionChanged = (section, available, prevSection = false) => {
    const newSections = new Map(sections)
    newSections.set(section, available)
    if (prevSection) {
      newSections.set(prevSection, true)
    }

    setSections(newSections)
  }

  useEffect(() => {
    onFilesChanged && onFilesChanged(files)
  }, [files])

  return (
    <>
      <div {...getRootProps()} className={classNames(styles.dropzone, { [styles.active]: isDragActive })}>
        <input {...getInputProps()} />
        <div className={styles.label}>
          Прикрепите файлы перетащив их в эту область или нажмите для выбора.
          <br />
          Название файла может содержать не более 50 символов.
        </div>
      </div>

      {files.size > 0 && !single && 
        <div className={styles.files}>
          {[...files.keys()].map(uuid =>
            <File
              sections={sections}
              key={uuid}
              onFileChanged={handleFileChanged}
              onFileDeleted={handleFileDeleted}
              onSectionChanged={handleSectionChanged}
              initFile={files.get(uuid)}
              uuid={uuid}
            />
          )}
        </div>
      }
    </>
  )
}

File.propTypes = {
  uuid: PropTypes.string,
  sections: PropTypes.object,
  initFile: PropTypes.object,
  onFileChanged: PropTypes.func,
  onFileDeleted: PropTypes.func,
  onSectionChanged: PropTypes.func
}

function File ({ uuid, initFile, onFileChanged, onFileDeleted, sections, onSectionChanged }) {
  const [upload, setUpload] = useState(0)
  const [title, setTitle] = useState(initFile.name || initFile.title)
  const [file, setFile] = useState(initFile)

  const filteredSections = [...sections].filter(s => (s[1] || s[0] === file.section)).map(s => s[0])

  const handleFileAttached = attached => {
    setFile(attached)
  }

  useEffect(() => {
    if (file.id) {
      onFileChanged(file)
    }
  }, [file])

  const handleTitleChanged = ({ target: { value } }) => {
    setTitle(value)
    // onFileChanged({ ...file, title: value })
  }

  const handleSectionChange = section => {
    onSectionChanged(section, file.section === section, file.section && file.section !== section ? file.section : false)
    const newSection = file.section === section ? null : section
    setFile({ ...file, section: newSection })
    onFileChanged({ ...file, section: newSection })
  }

  const isAttached = () => !!file.id

  useEffect(() => {
    const _attach = async () => {
      const formData = new FormData()
      formData.append('attachment[uuid]', uuid)
      formData.append('attachment[file]', file)
      formData.append('attachment[title]', file.name)
      formData.append('attachment[size]', file.size)

      console.log(file)

      await axios.post(
        path('attachments_path'),
        formData,
        {
          onUploadProgress: progressEvent => {
            let p = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
            setUpload(p)
          }
        }
      ).then(({ data }) => {
        setUpload(100)
        handleFileAttached(data)
      }).catch(_error => {
        // setErrors(error.response.data)
      })
    }

    if (!isAttached()) {
      _attach()
    }
  }, [])

  return (
    <div className={styles.file}>
      {isAttached() &&
        <div className={styles.row}>
          <div className={classNames(form.input, styles.title)}>
            <div className={styles.sections}>
              {filteredSections.map(section =>
                <div
                  key={section}
                  className={classNames(styles.section, { [styles.selected]: file.section === section })}
                  onClick={() => handleSectionChange(section)}
                >
                  {I18n.t(`attachments.types.${section}`)}
                </div>
              )}
            </div>
            <input
              type="text"
              value={title}
              onChange={handleTitleChanged}
            />
          </div>

          <div className={styles.delete} onClick={() => onFileDeleted(file)}>
            удалить
          </div>
        </div>
      }

      {!isAttached() &&
        <div className={styles.row}>
          <div className={styles.title}>
            {title}
          </div>

          <div className={styles.upload}>
            <div className={styles.bar} style={{ width: `${upload}%` }} />
            {upload >= 100 ? '99% обработка..' : upload + '%'}
          </div>
        </div>
      }
    </div>
  )
}
