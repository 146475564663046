import ScrollIntoView from 'react-scroll-into-view'
import ReactMarkdown from 'react-markdown'
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import axios from 'axios'
import qs from 'qs';

import { newBackendHost } from '../Vars';
import Link, { path } from '../Routes'
import showStyles from '../Careers/Show.module.css'
import styles from '../Careers/Index.module.css'
import vacStyles from './Show.module.css'
import page from '../Page.module.css'
import { calcSalaryString, calc_rating } from '../Careers/helpers'

class Show extends Component {
  state = {
    vacancy: {},
    location_region: {},
    filter_regions_ids: [],
    regions_list: [],
    filters_back: {}, // фильтры для возврата к списку профессий

    full_navigation: [
      {id: 'desc', title: 'О вакансии'},
      {id: 'addresses', title: 'Адреса'},
      {id: 'contact_list', title: 'Контакты', type: 'list'},
      {id: 'universities', title: 'Вузы для обучения', required: true}, // TODO: проверять наличие вузов и вакансий
      {id: 'careers', title: 'Атлас профессий', required: true},
      {id: 'contacts', title: 'Контактная информация', required: true},
    ],
    navigation: [], // с учетом непустых полей текущей профессии
    activeNavItem: '',
    activetedFromNav: false,

    schools: [],
    schools_count: 0,
    schools_limit: 3,
    schools_loading: false,
    school_search: '',
    schools_filter_region: true,

    careers: [],
    careers_count: 0,
    careers_limit: 3,
    careers_loading: false,
    careers_search: '',
    careers_filter_region: true,

    rumcs_contacts: [],
    show_nav_mobile: false
  }

  constructor(props) {
    super(props);
    this.throttleHandleSearch = debounce(this.throttleHandleSearch.bind(this), 500);
    this.handleSearch = this.handleSearch.bind(this);
  }

  async componentDidMount () {
    await this.getVacancy();
    await this.getLocation();

    await this.parseUrlParams();

    await this.getSchools();
    await this.getCareers();
    await this.getRumcContacts();

    await this.getReions();
    
    this.setNavigation();
    this.setScrollWatcher();
  }

  parseUrlParams = async () => {
    const searchParamsStr = window.location.search;
    const paramsObject = qs.parse(searchParamsStr || '', { ignoreQueryPrefix: true }) || {};

    this.setState({filters_back: paramsObject})    

    if (paramsObject.regions) {
      if (!Array.isArray(paramsObject.regions)) {
        paramsObject.regions = [paramsObject.regions]
      }
      this.setState({filter_regions_ids: paramsObject.regions.map(r => Number(r))});
    } else {
      this.setState({filter_regions_ids: [this.state.location_region.id]});
    }
  }

  getReions = async () => {
    const { data: regions_list } = await axios.get(`${newBackendHost}/regions`)
    this.setState({ regions_list })
  }

  getVacancy = async () => {
    try {
      const {data: res} = await axios.get(`${newBackendHost}/vacancy/${this.props.id}`)
      this.setState({ vacancy: res });
    } catch (e) {
      console.log(e)
    }
  }

  setNavigation = () => {
    const navigation = [];
    for (const n of this.state.full_navigation) {
      if (this.state.vacancy[n.id] || n.required) {
        navigation.push(n)
      }
    }
    this.setState({ navigation });
  }

  setScrollWatcher = () => {
    const headers = document.querySelectorAll('[name="header"]');
    const observer = new IntersectionObserver((entries) => {
      if (!this.state.activetedFromNav) {
        for (const entry of entries) {
          if (entry.intersectionRatio == 1) {
            this.setState({ activeNavItem: entry.target.id });
          }
        }
      } else {
        setTimeout(() => {
          this.setState({activetedFromNav: false})
        }, 2000);
      }
    }, {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
    });
    headers.forEach(element => observer.observe(element));
  }

  getLocation = async () => {
    const local = localStorage.getItem('location_region');
    if (local) {
      this.setState({
        location_region: JSON.parse(local)
      })
    } else {
      const { data: resp } = await axios.get(`${newBackendHost}/careers/location`)
      this.setState({ location_region: resp })
      localStorage.setItem('location_region', JSON.stringify(resp))
    }
  }

  getSchools = async (search = '') => {
    this.setState({schools_loading: true});
    try {
      const params = {};
      if (search) {
        params.search = search;
      }
      if (this.state.schools_filter_region) {
        if (this.state.filter_regions_ids) {
          params.region_id = this.state.filter_regions_ids;
        } else {
          params.region_id = [this.state.location_region.id];
        }
      }
      const {data: res} = await axios.get(`${newBackendHost}/vacancy/${this.props.id}/schools`, { params })
      this.setState({
        schools: res.data,
        schools_count: res.count,
      })
    } catch (e) {
      console.log(e)
    }
    this.setState({schools_loading: false});
  }

  getCareers = async (search = '') => {
    this.setState({careers_loading: true});
    try {
      const params = {
        vacancy_id: this.props.id,
        limit: 3,
      };
      if (search) {
        params.search = search;
      }
      if (this.state.careers_filter_region) {
        if (this.state.filter_regions_ids) {
          params.region_id = this.state.filter_regions_ids;
        } else {
          params.region_id = [this.state.location_region.id];
        }
      }
      params.limit = this.state.careers_limit;
      const {data: res} = await axios.get(`${newBackendHost}/careers`, { params })
      this.setState({
        careers: res.data,
        careers_count: res.count,
      })
    } catch (e) {
      console.log(e)
    }
    this.setState({careers_loading: false});
  }

  showMoreVacancy = async () => {
    await this.setState({careers_limit: this.state.careers_limit + 3});
    await this.getCareers(this.state.careers_search);
  }

  hideMoreVacancy = async () => {
    await this.setState({});
    this.setState({
      careers_limit: 3,
      careers: this.state.careers.slice(0, 3),
      careers_count: this.state.careers_count,
    })
  }

  getRumcContacts = async () => {
    const local = localStorage.getItem('rumcs_contacts')
    if (local) {
      this.setState({ rumcs_contacts: JSON.parse(local) })
    } else {
      try {
        const { data: rumcs_contacts } = await axios.get(`${newBackendHost}/rumc`, { params: { region: this.state.location_region.id } })
        this.setState({ rumcs_contacts })
      } catch(e) {
        console.log(e)
      }
    }
  }

  throttleHandleSearch = async (type) => {
    switch (type) {
      case 'school':
        await this.getSchools(this.state.school_search)
        break;
      case 'careers':
        await this.getCareers(this.state.careers_search)
        break;
    }
  }

  handleSearch(e, type) {
    switch (type) {
      case 'school':
        this.setState({school_search: e.target.value, schools_loading: true});
        break;
      case 'careers':
        this.setState({careers_search: e.target.value, careers_loading: true});
        break;
    }
    this.throttleHandleSearch(type);
  };

  async delRegionFilter(type) {
    switch (type) {
      case 'school':
        await this.setState({schools_filter_region: false})
        await this.getSchools(this.state.school_search)
        break;
      case 'careers':
        await this.setState({careers_filter_region: false})
        await this.getCareers(this.state.careers_search)
        break;
    }
    this.setState({filter_regions_ids: []})
  }

  render () {
    const { 
      location_region,
      vacancy,
      navigation, activeNavItem,
      schools, schools_count, schools_limit, schools_loading, school_search, schools_filter_region,
      careers, careers_count, careers_limit, careers_loading, careers_search, careers_filter_region,
      rumcs_contacts,
      show_nav_mobile,
      filter_regions_ids, regions_list, filters_back,
    } = this.state

    return (
      <div>
        <div className={styles.header}>
          <div className={styles.page_container}>
            <div className={classNames(styles.container, styles.navbar)}>
              <div className={classNames(styles.column, styles.colspan_2)}>
                <p><a href='/'>Главная</a> / <a href={`${path('vacancy_index_path')}${window.location.search}`}>Атлас вакансий</a> / {vacancy.title}</p>
                </div>
              {location_region.id && 
                <>
                  <div className={classNames(styles.column, styles.flex_row, styles.location_region)}>
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 0C3.58 0 0 4 0 8.5C0 12.96 2.55 17.81 6.53 19.67C7.46 20.1 8.53 20.1 9.46 19.67C13.44 17.81 16 12.96 16 8.5C16 4 12.41 0 8 0ZM8 10C9.1 10 10 9.1 10 8C10 6.89 9.1 6 8 6C6.89 6 6 6.89 6 8C6 9.1 6.89 10 8 10Z" fill="#050505" fillOpacity="1.000000" fillRule="evenodd"/>
                    </svg>
                    <p>{location_region.title}</p>
                  </div>
                </>
              }
            </div>
            <div className={styles.container}>
              <div className={classNames(styles.column, styles.navbar_title)} id="desc" name="header">
                {vacancy.title}
              </div>
              <div className={classNames(styles.column, vacStyles.vac_main_info)}>

                {(vacancy.salary_min || vacancy.salary_max) &&
                  <div className={vacStyles.vacancy_subtitle_salary}>{calcSalaryString(vacancy)}</div>
                }

                {vacancy.company_name && 
                  <div className={classNames(styles.card_info, styles.company_name)}>
                    {vacancy.company_name}
                    
                    {vacancy.company_site && 
                      <a href={vacancy.company_site.startsWith('http') ? vacancy.company_site : `https://${vacancy.company_site}`} target='_blank' title='Перейти на сайт организации'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
                        </svg>
                      </a>
                    }
                  </div>
                }
                <div className={vacStyles.card_info}>
                  {vacancy.regions && vacancy.regions.title &&
                    <div className={vacStyles.item}>
                      <span>Регион:</span>{vacancy.regions.title}
                    </div>
                  }
                  {vacancy.vacancies_schedule &&
                    <div className={vacStyles.item}>
                      <span>Тип занятости:</span>{vacancy.vacancies_schedule.title}
                    </div>
                  }
                  {vacancy.vacancies_specialisation &&
                    <div className={vacStyles.item}>
                      <span>Специализация:</span>{vacancy.vacancies_specialisation.title}
                    </div>
                  }
                  {vacancy.vacancies_education &&
                    <div className={vacStyles.item}>
                      <span>Образование:</span>{vacancy.vacancies_education.title}
                    </div>
                  }
                </div>
              </div>
              <div className={styles.column}>
                <a href={vacancy.url} target='_blank' className={vacStyles.ellipse_respond}>Откликнуться</a>
              </div>
            </div>
          </div>
        </div>

        <div className={page.wrapper}>
          <div className={styles.page_container}>

            <div className={showStyles.show_page_wrapper}>
              <div className={showStyles.show_page_left_column}>

                <div>
                  <div className={showStyles.show_page_left_column_content} id={'desc'} name="header">
                    <div className={showStyles.left_col_first}>
                      <div className={classNames(styles.page_subtitle, showStyles.show_page_subtitle)}>О вакансии</div>
                    </div>
                    <div className={showStyles.left_col_second}>
                      <div className={showStyles.value}>
                        <div dangerouslySetInnerHTML={{__html: vacancy['desc']}} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={vacStyles.divider} id={'addresses'} name="header" />
                  <div className={showStyles.show_page_left_column_content}>
                    <div className={showStyles.left_col_first}>
                      <div className={classNames(styles.page_subtitle, showStyles.show_page_subtitle)}>Адреса</div>
                    </div>
                    <div className={showStyles.left_col_second}>
                      <div className={showStyles.value}>
                        <ul>
                          {vacancy['addresses'] && vacancy['addresses']['address'].length > 0 && vacancy['addresses']['address'].map((item, index) => 
                            <li key={`addresses_item_${index}`}>
                              <a href={`https://yandex.ru/maps/?mode=search&text=${item.location}`} target='_blank'>{item.location}</a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={vacStyles.divider} id={'contact_list'} name="header" />
                  <div className={showStyles.show_page_left_column_content}>
                    <div className={showStyles.left_col_first}>
                      <div className={classNames(styles.page_subtitle, showStyles.show_page_subtitle)}>Контакты</div>
                    </div>
                    <div className={showStyles.left_col_second}>
                      <div className={showStyles.value}>
                        <ul>
                          <b>{vacancy.contact_person}</b>
                          {vacancy['contact_list'] && vacancy['contact_list'].length > 0 && vacancy['contact_list'].map((item, index) => 
                            <li key={`contact_list_item_${index}`}>{item.contact_type}: {item.contact_value}</li>  
                          )}
                          <a href={vacancy.url} target='_blank' className={classNames(styles.ellipse_about, vacStyles.respond_2)} style={{width: '40%'}}>Откликнуться</a>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className={showStyles.divider} id="universities" name="header" />

                {/* Вузы для обучения */}
                <div className={showStyles.show_page_left_column_content}>
                  <div className={showStyles.left_col_first}>
                    <div className={classNames(styles.flex_row, styles.page_subtitle_container)}>
                      <div className={styles.page_subtitle}>Вузы для<br />обучения</div>
                      <span className={schools_loading ? styles.active : ''}>{schools_count}</span>
                    </div>
                  </div>
                  <div className={showStyles.left_col_second}>
                    <div className={styles.careers_cards_container}>
                      {/* Поиск */}
                      <div className={styles.careers_search_container}>
                        <label>Используйте поиск, чтобы найти конкретный вуз</label>
                        <div className={styles.careers_search}>
                          <input placeholder='Поиск' name='search' value={school_search} type="text" onChange={(e) => this.handleSearch(e, 'school')} />
                        </div>

                        <div className={styles.selected_filters_container}>
                          {schools_filter_region && filter_regions_ids && filter_regions_ids.length != 0 &&
                            <>
                              <div className={styles.filters_category}>Регион:</div>
                              {filter_regions_ids.map(f =>
                                <div className={styles.selected_filters_item} key={`selected_region_${f}`}>
                                  <span className={styles.selected_filters_item_title}>{regions_list.find(r => r.id == f)?.title}</span>
                                  <svg onClick={() => this.delRegionFilter('school')} width="19.000000" height="19.000000" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector" d="M19 9.5C19 14.74 14.74 19 9.5 19C4.25 19 0 14.74 0 9.5C0 4.25 4.25 0 9.5 0C14.74 0 19 4.25 19 9.5ZM6.62 6.62C6.89 6.34 7.35 6.34 7.62 6.62L9.5 8.49L11.37 6.62C11.64 6.34 12.1 6.34 12.37 6.62C12.65 6.89 12.65 7.35 12.37 7.62L10.5 9.5L12.37 11.37C12.65 11.64 12.65 12.1 12.37 12.37C12.1 12.65 11.64 12.65 11.37 12.37L9.5 10.5L7.62 12.37C7.35 12.65 6.89 12.65 6.62 12.37C6.34 12.1 6.34 11.64 6.62 11.37L8.49 9.5L6.62 7.62C6.34 7.35 6.34 6.89 6.62 6.62Z" fill="#FF8D00" fillOpacity="1.000000" fillRule="evenodd"/>
                                  </svg>
                                </div>
                              )}
                            </>
                          }
                        </div>
                      </div>

                      {/* Вузы */}
                      {schools_count > 0 && schools.map(school => 
                        <Link to="school_path" target="_blank" params={{ id: school.id }} className={styles.careers_card} key={`${school.id}`}>
                          {/* <div className={styles.career_rating_about_row}>
                            <div className={styles.ellipse_rating}>
                            </div>
                            <div className={styles.ellipse_about}>Подробнее</div>
                          </div> */}

                          <div className={styles.careers_card_title}>{school.abbr}</div>
                          {school.abbr != school.short_name && <div className={showStyles.school_card_short_name}>{school.short_name}</div>}

                          <div className={styles.flex_row}>
                            {school.regions && school.regions.title &&
                              <div className={styles.flex_row}>
                                <div className={classNames(showStyles.card_info, showStyles.title)}>Регион:</div>
                                <div className={showStyles.card_info}>{school.regions.title}</div>
                              </div>
                            }
                            {school.uchred &&
                              <div className={styles.flex_row}>
                                <div className={classNames(showStyles.card_info, showStyles.title)}>Статус:</div>
                                <div className={showStyles.card_info}>{school.uchred}</div>
                              </div>
                            }
                          </div>

                          <div className={styles.flex_row}>
                            {school.e && <div className={classNames(styles.ellipse_nosology, styles.e)}>Нарушения зрения</div>}
                            {school.h && <div className={classNames(styles.ellipse_nosology, styles.h)}>Нарушения слуха</div>}
                            {school.m && <div className={classNames(styles.ellipse_nosology, styles.m)}>Нарушения ОДА</div>}
                          </div>
                        </Link>
                      )}
                      {schools_count > 0 && 
                        <a href={`${path('schools_path')}?${qs.stringify({regions: filter_regions_ids, ...(careers_count ? {careers: careers.map(i => i.id)} : {})})}`} target='_blank' className={showStyles.show_all}>
                          Показать ещё
                        </a>
                      }

                      {schools_count == 0 && <h2>Вузы не найдены</h2>}
                    </div>
                  </div>
                </div>


                {/* Профессии */}
                <div className={showStyles.divider} id="careers" name="header" />
                
                <div className={showStyles.show_page_left_column_content}>
                  <div className={showStyles.left_col_first}>
                    <div className={classNames(styles.flex_row, styles.page_subtitle_container)}>
                      <div className={styles.page_subtitle}>Профессии</div>
                      <span className={careers_loading ? styles.active : ''}>{careers_count}</span>
                    </div>
                  </div>
                  <div className={showStyles.left_col_second}>
                    <div className={styles.careers_cards_container}>
                      {/* Поиск */}
                      <div className={styles.careers_search_container}>
                        <label>Используйте поиск, чтобы найти профессию</label>
                        <div className={styles.careers_search}>
                          <input placeholder='Поиск' name='search' value={careers_search} type="text" onChange={(e) => this.handleSearch(e, 'careers')} />
                        </div>
                        <div className={styles.selected_filters_container}>
                          {careers_filter_region && filter_regions_ids && filter_regions_ids.length != 0 &&
                            <>
                              <div className={styles.filters_category}>Регион:</div>
                              {filter_regions_ids.map(f =>
                                <div className={styles.selected_filters_item} key={`selected_region_${f}`}>
                                  <span className={styles.selected_filters_item_title}>{regions_list.find(r => r.id == f)?.title}</span>
                                  <svg onClick={() => this.delRegionFilter('careers')} width="19.000000" height="19.000000" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector" d="M19 9.5C19 14.74 14.74 19 9.5 19C4.25 19 0 14.74 0 9.5C0 4.25 4.25 0 9.5 0C14.74 0 19 4.25 19 9.5ZM6.62 6.62C6.89 6.34 7.35 6.34 7.62 6.62L9.5 8.49L11.37 6.62C11.64 6.34 12.1 6.34 12.37 6.62C12.65 6.89 12.65 7.35 12.37 7.62L10.5 9.5L12.37 11.37C12.65 11.64 12.65 12.1 12.37 12.37C12.1 12.65 11.64 12.65 11.37 12.37L9.5 10.5L7.62 12.37C7.35 12.65 6.89 12.65 6.62 12.37C6.34 12.1 6.34 11.64 6.62 11.37L8.49 9.5L6.62 7.62C6.34 7.35 6.34 6.89 6.62 6.62Z" fill="#FF8D00" fillOpacity="1.000000" fillRule="evenodd"/>
                                  </svg>
                                </div>
                              )}
                            </>
                          }
                        </div>
                      </div>

                      {careers_count > 0 && careers.map(career => 
                        <div className={styles.careers_card} key={`${career.id}`}>
                          <div className={styles.career_rating_about_row}>
                            <div className={styles.ellipse_rating}>
                              <span>{career.rating}</span>
                              {calc_rating(career.rating).map((i, index) => 
                                <div key={`star_${index}`}>
                                  {i != 0 &&
                                    <svg  width="15.755859" height="15.082092" viewBox="0 0 15.7559 15.0821" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path id="Star" d="M7.05 0.43L4.9 3.56C4.77 3.75 4.58 3.89 4.36 3.96L0.71 5.03C0.04 5.23 -0.23 6.04 0.2 6.6L2.52 9.61C2.66 9.8 2.73 10.02 2.73 10.25L2.62 14.05C2.6 14.75 3.3 15.25 3.96 15.02L7.54 13.75C7.75 13.67 7.99 13.67 8.21 13.75L11.79 15.02C12.45 15.25 13.14 14.75 13.12 14.05L13.02 10.25C13.02 10.02 13.08 9.8 13.23 9.61L15.54 6.6C15.97 6.04 15.71 5.23 15.03 5.03L11.39 3.96C11.17 3.89 10.98 3.75 10.85 3.56L8.7 0.43C8.3 -0.15 7.45 -0.15 7.05 0.43Z" fill="#9399A6" fillOpacity="1.000000" fillRule="evenodd"/>
                                    </svg>
                                  }
                                  {i == 0 &&
                                    <svg width="8.318359" height="14.007385" viewBox="0 0 8.31836 14.0074" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path id="Star " d="M6.53 0.38L4.9 2.47C4.77 2.63 4.59 2.75 4.39 2.81L0.71 3.9C0.04 4.1 -0.23 4.91 0.2 5.47L2.52 8.48C2.66 8.66 2.73 8.88 2.73 9.11L2.62 12.97C2.6 13.66 3.26 14.16 3.92 13.96L7.61 12.81C8.03 12.68 8.31 12.29 8.31 11.85L8.31 1C8.31 0.05 7.11 -0.37 6.53 0.38Z" fill="#9399A6" fillOpacity="1.000000" fillRule="evenodd"/>
                                    </svg>
                                  }
                                </div>
                              )}
                            </div>
                            <a href={path('career_path', {id: career.id})} target='_blank' className={styles.ellipse_about}>Подробнее</a>
                          </div>

                          <div className={styles.careers_card_title}>{career.title}</div>
                          
                          {/* SALARY */}
                          <div className={styles.careers_card_salary}>{calcSalaryString(career)}</div>

                          <div className={styles.flex_row}>
                            {career.nosologies.includes('e') && <div className={classNames(styles.ellipse_nosology, styles.e)}>Нарушения зрения</div>}
                            {career.nosologies.includes('h') && <div className={classNames(styles.ellipse_nosology, styles.h)}>Нарушения слуха</div>}
                            {career.nosologies.includes('m') && <div className={classNames(styles.ellipse_nosology, styles.m)}>Нарушения ОДА</div>}
                          </div>
                        </div>
                      )}

                      <div className={showStyles.show_hide_vacancy}>
                        {careers_count > careers_limit && 
                          <div onClick={() => this.showMoreVacancy()} className={showStyles.show_all}>
                            Показать ещё
                          </div>
                        }
                        {careers_limit > 3 && 
                          <div onClick={() => this.hideMoreVacancy()} className={showStyles.show_all}>
                            Скрыть
                          </div>
                        }
                      </div>

                      {careers_count == 0 && <h2>Профессии не найдены</h2>}
                    </div>
                  </div>
                </div>
                

                {/* Контактная информация */}
                <div className={showStyles.divider} id="contacts" name="header" />
                
                <div className={showStyles.show_page_left_column_content}>
                  <div className={showStyles.left_col_first}>
                    <div className={classNames(styles.page_subtitle, showStyles.show_page_subtitle)} >Контактная<br />информация</div>
                  </div>
                  <div className={showStyles.left_col_second}>
                    <div className={styles.contacts_col}>
                      <div className={styles.consulting_about_container}>
                        <div className={styles.consulting_about_row}>
                          <svg width="22.000000" height="22.000000" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="Vector" d="M14.84 0C18.79 0 22 3.2 22 7.14C22 8.2 21.76 9.21 21.35 10.11C21.17 10.51 21.1 10.96 21.22 11.38L21.36 11.9C21.64 12.97 20.67 13.94 19.6 13.66L19.24 13.56C19.24 13.44 19.25 13.32 19.25 13.2C19.25 7.56 14.79 2.97 9.21 2.75C10.51 1.08 12.55 0 14.84 0ZM8.79 22C13.66 22 17.59 18.06 17.59 13.2C17.59 8.33 13.66 4.39 8.79 4.39C3.93 4.39 0 8.33 0 13.2C0 14.55 0.3 15.84 0.85 16.99C1.04 17.39 1.11 17.83 1 18.26L0.63 19.6C0.35 20.67 1.32 21.64 2.39 21.36L3.73 20.99C4.16 20.88 4.6 20.95 5 21.14C6.15 21.69 7.44 22 8.79 22ZM6.04 13.2C6.04 13.8 5.55 14.3 4.95 14.3C4.34 14.3 3.84 13.8 3.84 13.2C3.84 12.59 4.34 12.1 4.95 12.1C5.55 12.1 6.04 12.59 6.04 13.2ZM9.9 13.2C9.9 13.8 9.4 14.3 8.79 14.3C8.19 14.3 7.7 13.8 7.7 13.2C7.7 12.59 8.19 12.1 8.79 12.1C9.4 12.1 9.9 12.59 9.9 13.2ZM13.75 13.2C13.75 13.8 13.25 14.3 12.65 14.3C12.04 14.3 11.54 13.8 11.54 13.2C11.54 12.59 12.04 12.1 12.65 12.1C13.25 12.1 13.75 12.59 13.75 13.2Z" fill="#FCDA2D" fillOpacity="1.000000" fillRule="evenodd"/>
                          </svg>
                        </div>
                        <Link to="contacts_path" target='_blank' className={styles.ellipse_about}>Подробнее</Link>
                      </div>
                      <div className={styles.consulting_about_title}>Сервис онлайн-консультирования</div>
                      <p>Чтобы получить ответ на свой вопрос можно воспользоваться сервисом онлайн-консультирования.</p>
                      
                      <div className={styles.consulting_region_container}>
                        <div className={styles.consulting_about_row}>
                          <svg width="16.000000" height="20.000000" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="Vector" d="M8 0C3.58 0 0 4 0 8.5C0 12.96 2.55 17.81 6.53 19.67C7.46 20.1 8.53 20.1 9.46 19.67C13.44 17.81 16 12.96 16 8.5C16 4 12.41 0 8 0ZM8 10C9.1 10 10 9.1 10 8C10 6.89 9.1 6 8 6C6.89 6 6 6.89 6 8C6 9.1 6.89 10 8 10Z" fill="#FCDA2D" fillOpacity="1.000000" fillRule="evenodd"/>
                          </svg>
                          <span>{location_region.title}</span>
                        </div>
                      </div>
                      <div className={styles.consulting_about_title}>Горячая линия по вопросам обучения</div>

                      {rumcs_contacts.length > 0 && rumcs_contacts.map(r => 
                        <div key={`contact_rumc_${r.id}`}>
                          <div className={styles.contact_rumc_phone}>{r.phone}</div>
                          <Link to="rumc_path" params={{id: r.id}} target='_blank'>{r.title}</Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              
              <div className={showStyles.show_page_right_column}>

                {window.innerWidth <= 950 &&
                  <div 
                    className={classNames(showStyles.ellipse_small_filters, show_nav_mobile ? showStyles.active : '')} 
                    onClick={() => this.setState({show_nav_mobile: !show_nav_mobile})}
                  >
                    Навигация по странице
                  </div>
                }
                {((window.innerWidth > 950) || (window.innerWidth <= 950 && show_nav_mobile)) &&
                  <div className={showStyles.navigation_container}>
                    <div className={showStyles.navigation_title}>Навигация по странице:</div>
                    {navigation.map(n =>
                      <ScrollIntoView 
                        selector={`#${n.id}`} 
                        key={`navigation_${n.id}`} 
                        className={classNames(showStyles.navigation_item, n.id == activeNavItem ? showStyles.active : '')}
                        onClick={() => setTimeout(() => {this.setState({activeNavItem: n.id, activetedFromNav: true})}, 500)}
                      >
                        <span>{n.title}</span>
                      </ScrollIntoView>
                    )}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Show.propTypes = {
  id: PropTypes.string
}

export default Show
