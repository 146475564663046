import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from '../../Routes';

import Index from './Index';
import Show from './Show';
import Review from './Review';
import Form from './Form';
import StagesIndex from '../../Stages/StagesIndex';

class Courses extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={decodeURIComponent(Routes.new_account_course_path)} component={Form} />
        <Route exact path={decodeURIComponent(Routes.review_account_course_path)} render={props => <Review id={props.match.params.id} {...props} />} />
        <Route exact path={decodeURIComponent(Routes.edit_account_course_path)} render={props => <Form id={props.match.params.id} {...props} />} />
        <Route exact path={decodeURIComponent(Routes.account_course_path)} render={props => <Show id={props.match.params.id} location={props.location} />} />
        <Route exact path={decodeURIComponent(Routes.account_courses_path)} component={Index}/>
        <Route exact strict path={decodeURIComponent(Routes.stages_account_course_path)} render={props => <StagesIndex stageable_type="course" stageable_id={props.match.params.id} {...props}/>}  />
      </Switch>
    );
  }
}

export default Courses;
