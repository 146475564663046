import React from 'react'
import PropTypes from 'prop-types'

// import Link from '../../../Routes'
import Themes from './Themes'

import styles from './Modules.module.css'

Modules.propTypes = {
  planModules: PropTypes.array
}

export default function Modules ({ planModules }) {
  return (
    <div className={styles.modules}>
      {planModules.map((planModule, i) =>
        <div key={planModule.id} className={styles.module}>
          <h3>
            Модуль № {i + 1}. {planModule.title}
          </h3>

          <Themes planThemes={planModule.plan_themes} />
        </div>
      )}
    </div>
  )
}
