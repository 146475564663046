import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';

import styles from '../Section.module.css';
import form from '../../Form.module.css';

class Prof extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      values: [
        'w',
        'wc',
        'ori_dod',
        'ori_incon',
        'ori_gcon',
        'ori_pst',
        'ori_cncl',
        'ori_o',
        'por_e',
        'por_h',
        'por_m',
        'por_o',
        'por_sch',
        'spo_n',
        'spo_p',
      ].reduce((acc, e) => {
        acc[e] = (this.props.values[this.props.prefix] && this.props.values[this.props.prefix][e]) || '';
        return acc;
      }, {}),
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      [name]: {
        $set: value
      }
    });

    this.setState({ values });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.onValuesChange) this.props.onValuesChange(this.props.prefix, this.state.values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state != nextState);
  }

  render () {
    const { values, show } = this.state;
    const { num } = this.props;

    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Профориентация в системе инклюзивного высшего образования

          <div className={classNames(styles.label, { [styles.ok]: Object.values(values).filter(v => v).length == Object.values(values).length })}>
            {Object.values(values).filter(v => v).length} из {Object.values(values).length}
          </div>
        </div>

        <div className={styles.content}>
          <div className={form.elem}>
            <div className={form.label}>
              {num}.1. Общее число семинаров/вебинаров для педагогических работников и родителей (законных представителей) обучающихся-инвалидов по вопросам профориентации и получения услуг ВО для обучающихся с ОВЗ и инвалидностью
            </div>

            <div className={form.input}>
              <input type="text" name="w" value={values.w} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2. Общее число участников семинаров/вебинаров (из п.{num}.1)
            </div>

            <div className={form.input}>
              <input type="text" name="wc" value={values.wc} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.desc}>
            {num}.3. Использование форм трудового и профессионального ориентирования абитуриентов и студентов из числа лиц с ОВЗ и инвалидностью:
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="ori_dod" checked={values.ori_dod} onChange={this.handleInputChange} />
                {num}.3.1. Дни открытых дверей
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="ori_incon" checked={values.ori_incon} onChange={this.handleInputChange} />
                {num}.3.2. Индивидуальное профконсультирование инвалидов
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="ori_gcon" checked={values.ori_gcon} onChange={this.handleInputChange} />
                {num}.3.3. Групповое консультирование инвалидов и лиц с ОВЗ на базе средних образовательных учреждений
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="ori_pst" checked={values.ori_pst} onChange={this.handleInputChange} />
                {num}.3.4. Психологическое тестирование абитуриентов и студентов по запросу
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="ori_cncl" checked={values.ori_cncl} onChange={this.handleInputChange} />
                {num}.3.5. Каникулярные школы или иные тематические школы
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="ori_o" checked={values.ori_o} onChange={this.handleInputChange} />
                {num}.3.6. Другое
              </label>
            </div>
          </div>

          <div className={styles.desc}>
            {num}.4. Наличие адаптированных программ профориентации абитуриентов и студентов из числа лиц с ОВЗ и инвалидностью по нозологиям, в том числе:
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="por_e" checked={values.por_e} onChange={this.handleInputChange} />
                {num}.4.1. Для лиц с нарушением зрения
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="por_h" checked={values.por_h} onChange={this.handleInputChange} />
                {num}.4.2. Для лиц с нарушением слуха
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="por_m" checked={values.por_m} onChange={this.handleInputChange} />
                {num}.4.3. Для лиц с нарушениями опорно-двигательного аппарата
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="por_o" checked={values.por_o} onChange={this.handleInputChange} />
                {num}.4.4. Для других нозологических групп
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4.5. Количество школ, в которых вуз реализует адаптированную программу профориентации
            </div>

            <div className={form.input}>
              <input type="text" name="por_sch" value={values.por_sch} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="spo_n" checked={values.spo_n} onChange={this.handleInputChange} />
                {num}.5. Наличие специалистов по профессиональной ориентации студентов из числа лиц ОВЗ и инвалидностью
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.5.1. Если нет, то определите потребность в подготовке таких специалистов
            </div>

            <div className={form.input}>
              <input type="text" name="spo_p" value={values.spo_p} onChange={this.handleInputChange} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Prof.defaultProps = {
  prefix: 'prof',
  num: '7'
};

export default Prof;
