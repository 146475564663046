import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

import Link, { path } from '../Routes'

import page from '../Page.module.css'
import styles from './Index.module.css'

export default function Index () {
  const [forms, setForms] = useState()
  const [rumc, setRumc] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('forms_path', {}, true))

      setForms(data.forms)
      setRumc(data.rumc)
    }

    _fetch()
  }, [])

  return (
    <div className={page.root}>
      <div className={page.container}>

        <div className={page.title}>
          <h1>Анкеты и формы мониторинга</h1>

          <Helmet>
            <title>
              Анкеты и формы мониторинга
            </title>
          </Helmet>
        </div>

        <div className={page.narrow}>
          {forms && forms.length > 0 &&
            <div className={styles.forms}>
              {forms.map(form =>
                <Link
                  key={form.id}
                  to="form_path"
                  params={{ id: form.id, query: rumc ? { rumc_id: rumc.id } : null }}
                  className={styles.form}
                >
                  {form.title}
                </Link>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
