import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { path } from '../../Routes';
import Loaf from '../../Loaf/Loaf';
import List from '../List/List';

import page from '../../Page.module.css';

class Events extends Component {
  state = {
    search: this.props.location.search,
    rumcs: null
  }

  static getDerivedStateFromProps(props, state) {
    if(state.id != props.match.params.id || props.location.search !== state.search) {
      return {
        id: props.match.params.id,
        search: props.location.search,
      }
    }

    return null;
  }

  render () {
    const { id, rumcs } = this.state;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('stats_path', { id })}>Весь отчёт</Link>
            <Link to={path('stats_rumcs_path', { id })}>РУМЦ</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Результаты госзадания
            </h1>
          </div>

          <div>
            <List {...this.props} url={path('stats_events_path', { id: id }, true)} fields={['all']} />
          </div>
        </div>
      </div>
    );
  }
}

export default Events;
