import React from 'react'
import PropTypes from 'prop-types'
import { Editor } from 'react-draft-wysiwyg'

EditorRich.propTypes = {
  editorState: PropTypes.object,
  onEditorStateChange: PropTypes.func
}

export function EditorRich ({ editorState, onEditorStateChange }) {
  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ['inline', 'list', 'blockType', 'link'],
        inline: {
          options: ['bold']
        },
        list: {
          options: ['unordered', 'ordered']
        },
        blockType: {
          inDropdown: false,
          options: ['Blockquote', 'H3', 'H2']
        }
      }}
      stripPastedStyles = {true}
      localization={{
        locale: 'ru'
      }}
    />
  )
}
