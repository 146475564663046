import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import { path } from '../../Routes';

import page from '../../Page.module.css';
import form from '../../Form.module.css';
import buttons from '../../Buttons.module.css';

class Review extends Component {
  state = {
    event: null,
    values: {
      review: '',
      anounce_quality: '',
      report_quality: '',
    }
  }


  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('review_account_event_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          event: res.data.event,
          values: res.data.values,
        });

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleSubmit = (e) => {
    axios({
      method: 'patch',
      url: path('account_event_path', { id: this.props.id }, true),
      data: {
        event: this.state.values
      },
    }).then(
      res => {
        this.props.history.push(path('account_event_path', { id: this.props.id }))
      }
    );

    e.preventDefault();
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }));
  }

  render() {
    const { event, values } = this.state;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={path('account_events_path')}>{I18n.t('events.title')}</Link>
          </Loaf>

          {event &&
            <>
              <div className={page.title}>
                <h1>
                  {event.title}
                </h1>

                <Helmet>
                  <title>{event.title}</title>
                </Helmet>
              </div>

              <div className={page.form}>
                <form className={form.root} onSubmit={this.handleSubmit}>
                  <div className={form.elem}>
                    <div className={form.label}>
                      Качество анонса
                    </div>

                    <div className={form.input}>
                      <select name="anounce_quality" onChange={this.handleInputChange} value={values.anounce_quality}>
                        <option />
                        <option value={0}>Не информативно</option>
                        <option value={1}>Соответствует, но есть ошибки или замечания</option>
                        <option value={2}>Заполнены все разделы, информация не содержит ошибок, понятна для целевой аудитории</option>
                      </select>
                    </div>
                  </div>

                  <div className={form.elem}>
                    <div className={form.label}>
                      Качество отчета
                    </div>

                    <div className={form.input}>
                      <select name="report_quality" onChange={this.handleInputChange} value={values.report_quality}>
                        <option />
                        <option value={0}>Не информативно</option>
                        <option value={1}>Соответствует, но есть ошибки или замечания</option>
                        <option value={2}>Заполнены все разделы, информация не содержит ошибок, понятна для целевой аудитории</option>
                      </select>
                    </div>
                  </div>

                  <div className="input">
                    <div className="input_label">
                      <label>
                        Коментарии к оценкам
                      </label>
                    </div>

                    <div className="input_input">
                      <textarea name="review" value={values.review} rows="4" onChange={this.handleInputChange} />
                    </div>
                  </div>

                  <div className={form.submit}>
                    <input type="submit" value="Сохранить" className={buttons.main} />
                  </div>
                </form>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}

export default Review;
