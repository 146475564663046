import classNames from 'classnames'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import I18n from '../I18n'
import { path } from '../Routes'

import styles from './Block.module.css'

class Block extends Component {
  render() {
    const { title, desc, url, more } = this.props.block

    return (
      <Link to={url} className={classNames(styles.root, styles.a_in_block)}>
        <div className={styles.data}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.desc}>
            {desc}
          </div>
        </div>

        <div className={styles.more}>
          {more}
        </div>
      </Link>
    )
  }
}

export default Block
