import React from 'react'
// import PropTypes from 'prop-types'

import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Index'
import Test from './Test'
// import Vacancies from './Vacancies'
import Stories from './Stories'

import Story from './Story'

export default function Abiturs () {
  return (
    <React.Fragment>
      <Switch>
        <Route path={Routes.abiturs_path} component={Index} exact strict />
        <Route path={Routes.abiturs_test_path} component={Test} />
        {/* <Route path={decodeURIComponent(Routes.abiturs_vacancies_path)} component={Vacancies} /> */}
        <Route exact path={decodeURIComponent(Routes.abiturs_stories_path)} component={Stories} />
        <Route path={decodeURIComponent(Routes.abiturs_story_path)} render={props => <Story id={props.match.params.id} /> } />
      </Switch>
    </React.Fragment>
  )
}
