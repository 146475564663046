import React, { useEffect, useState } from 'react';
import styles from '../../Index.module.css';

/**
 *
 * @param {*} props
 * id: string
 * title: string - заголовок чекбокса
 * checked: boolean - входящее состояние
 * onChange: (id: string, state: boolean) => void; - реакция на изменение
 */

const Checkbox = (props) => {
    const { id, title = '', checked = false, onChange, disabled = false } = props;

    const handleOnChange = (event) => {
        event.preventDefault();
        onChange(id, !checked);
    }

    return (
        <div key={`div_input-${id}`} className={styles.new_search_filter_list_item}>
            <label className={styles.new_search_chekbox} onClick={(event) => handleOnChange(event)}>
                <input
                    type="checkbox"
                    className={styles.new_search_checkbox__input}
                    id={`input-${id}`}
                    name={`input-name-${id}`}
                    checked={checked}
                    onChange={() => {}}
                    disabled={disabled}
                />
                <span className={styles.new_search_checkbox__control}></span>
                <span className={disabled ? styles.new_search_checkbox__label_disabled : styles.new_search_checkbox__label}>{title}</span>
            </label>
        </div>
    );
};

export default Checkbox;
