import React, { Component } from 'react';
import classNames from 'classnames';

import I18n from '../../I18n'

import section from './Section.module.css';
import details from './Details.module.css';

class Item extends Component {

  renderValue = (data) => {
    const { sub, value, value_human, size } = data;
    const { type } = this.props.data;

    switch(type) {
      case 'boolean':
        return (value / size).toLocaleString(I18n.locale, { style: 'percent' });

      case 'currency':
        return value_human ? `${value_human} ₽` : `${value.toLocaleString(I18n.locale, { maximumFractionDigits: 0 })} ₽`;

      default:
        return value.toLocaleString(I18n.locale);
    }
  }

  render() {
    if(!this.props.data) return null;

    const { prev, percent, subs, value } = this.props.data;
    const { I18n, label } = this.props;

    return (
      <div className={section.item}>
        <div className={section.value}>
          {this.renderValue(this.props.data)}
        </div>

        {percent &&
          <div className={classNames(section.prev, { [section.up]: percent > 0, [section.down]: percent <= 0 })}>
            {(percent).toLocaleString(I18n.locale, { style: 'percent' })}
          </div>
        }

        {this.props.children}

        {label &&
          <div className={section.label}>
            {I18n.t(label)}
          </div>
        }

        {subs &&
          <div className={details.root}>
            {subs.map((sub, i) =>
              <div className={details.item} key={i}>
                <div className={details.label}>
                  <div className={details.value}>
                    {this.renderValue(sub)}
                    {sub.percent &&
                      <span className={classNames(details.percents, { [details.up]: sub.percent > 0, [details.down]: sub.percent <= 0 })}>
                        {sub.percent.toLocaleString(I18n.locale, { style: 'percent' })}
                      </span>
                    }

                    {!sub.percent &&
                      <span className={details.percents}>
                        {(sub.value / value).toLocaleString(I18n.locale, { style: 'percent' })}
                      </span>
                    }
                  </div>
                  <div className={details.title}>
                    {sub.title}
                  </div>
                </div>

                <div className={details.bar}>
                  <div className={details.percent} style={{width: `${parseInt(sub.value / value * 100)}%`}}></div>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    );
  }
}

export default Item
