import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Reports from './Reports/Reports'
import Rumcs from './Rumcs/Rumcs'
import Index from './Index'

export default function Statistics () {
  return (
    <Switch>
      <Route path={Routes.statistics_path} strict exact>
        <Index />
      </Route>

      <Route path={[Routes.statistics_reports_schools_path, Routes.statistics_reports_programs_path]}>
        <Reports />
      </Route>

      <Route path={Routes.statistics_runmcs_path}>
        <Rumcs />
      </Route>
    </Switch>
  )
}
