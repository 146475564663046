import React, { Component } from 'react';

import Item from './Item';

import section from './Section.module.css';

class Consulting extends Component {
  render() {
    const { data, I18n } = this.props;

    return (
      <div className={section.root}>
        <div className={section.title}>
          <h2>
            Учебно-методические материалы
          </h2>
        </div>

        <div className={section.list}>
          <Item data={data.a} label={'stats.umms.a'}/>
          <Item data={data.k} label={'stats.umms.k'} />
        </div>
      </div>
    );
  }
}

export default Consulting;
