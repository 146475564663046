import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Routes, getPath, path } from '../../Routes';

import Index from './Index';
import Show from './Show';

class Stats extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path={decodeURIComponent(getPath('stats_forms_path'))} component={Index} exact strict />
          <Route path={decodeURIComponent(getPath('stats_form_path'))} component={Show} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Stats;
