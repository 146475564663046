import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import I18n from '../../I18n';

import tabs from '../../Tabs.module.css'

class Sections extends React.Component {

  render() {
    const { sections, active } = this.props

    return (
      <div className={tabs.root}>
        {sections &&
          sections.map((section, _) =>
            <div key={_} className={classNames(tabs.tab, { [tabs.active]: section.key == active })} onClick={() => {this.props.onSectionChange(section.key)}}>{section.value}</div>
          )
        }
      </div>
    )
  }
}

export default Sections;
