import React from 'react'
import PropTypes from 'prop-types'

import Link from '../Routes'
import I18n from '../I18n'

import buttons from '../Buttons.module.css'
import styles from './Block.module.css'

Block.propTypes = {
  stories: PropTypes.array
}

export default function Block ({ stories }) {
  if (!stories || stories.length < 1) return null

  const first = stories.shift()

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2>
          {I18n.t('stories.block.title')}
        </h2>
      </div>

      {first &&
        <div className={styles.first}>
          <div className={styles.story}>
            <iframe src={`https://www.youtube.com/embed/${first.youtube_id}?modestbranding=1&autohide=1&showinfo=0&rel=0&cc_load_policy=1`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
          </div>

          <div className={styles.desc}>
            <p>
              <strong>
                {first.title}
              </strong>
              <br />
              {first.name}
              <br />
              {first.qualification}
            </p>
          </div>
        </div>
      }

      {stories.length > 0 &&
        <>
          <div className={styles.stories}>
            {stories.map(story =>
              <Link to="abiturs_story_path" params={{ id: story.id }} className={styles.story} key={story.id}>
                <div className={styles.img}>
                  <img src={`https://img.youtube.com/vi/${story.youtube_id}/mqdefault.jpg`} />
                </div>

                <div className={styles.desc}>
                  {story.title}
                </div>
              </Link>
            )}
          </div>

          <div className={styles.more}>
            <Link to="abiturs_stories_path" className={buttons.main}>
              Все истории
            </Link>
          </div>
        </>
      }
    </div>
  )
}
