import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes, getPath, path } from '../../Routes';

import Index from './Index';
import Table from './Table';

class Rumcs extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route path={decodeURIComponent(getPath('stats_rumcs_table_path'))} component={Table} />
          <Route path={decodeURIComponent(getPath('stats_rumcs_path'))} component={Index} />
        </Switch>
      </>
    );
  }
}

export default Rumcs;
