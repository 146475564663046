import 'normalize.css/normalize'
import '../vars.css'
import '../application.css'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import axios from 'axios'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ru'

dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.locale('ru')

const componentRequireContext = require.context('components', true)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
ReactRailsUJS.mountComponents()

// require('@rails/ujs').start()
// require('channels')

// const Rails = require('rails-ujs')
// Rails.start()

window.axiosActive = 0

axios.interceptors.request.use((config) => {
  window.axiosActive += 1
  return config
})

axios.interceptors.response.use((response) => {
  window.axiosActive -= 1
  return response
})
