import React, { Component } from 'react'
import styles from './Public.module.css'

class Public extends Component {
  render() {
    const { attachments } = this.props
    if(!attachments) return null

    return (
      <div className={styles.attachments}>
        {attachments.map(attachment =>
          <a key={attachment.id} href={attachment.url} target="_blank" className={styles.attachment}>
            <div className={styles.title}>
              {attachment.title}
            </div>
          </a>
        )}
      </div>
    );
  }
}

export default Public
