export const FilterType = Object.freeze({
    Page: 'page',
    Search: 'search',
    Regions: 'regions',
    Careers: 'careers',
    IndividualConditions: 'individual_conditions',
    hideImages: 'hide_images'
});

export const DefaultFilterValue = Object.freeze({
    [FilterType.Page]: 1,
    [FilterType.Search]: '',
    [FilterType.Regions]: [],
    [FilterType.Careers]: [],
    [FilterType.IndividualConditions]: [],
    [FilterType.hideImages]: false
});

export const Filters = [
    Object.freeze({
        type: FilterType.Regions,
        title: 'Регион',
        searchable: true,
    }),
    Object.freeze({
        type: FilterType.Careers,
        title: 'Профессии',
        searchable: true,
    }),
    Object.freeze({
        type: FilterType.IndividualConditions,
        title: 'Индивидуальные условия',
        searchable: false,
        groupBy: 'group_name'
    })
];
