import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import Audiences from './Stats/Audiences'
import Regions from './Stats/Regions'

import { path } from '../../../Routes'

import styles from './Stats.module.css'

Stats.propTypes = {
  eventIds: PropTypes.array,
}

export default function Stats ({ eventIds }) {
  const cancelToken = useRef(axios.CancelToken.source())

  const [audiences, setAudiences] = useState()
  const [regions, setRegions] = useState()
  const [registrations, setRegistrations] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(
        path('stats_event_event_registrations_path', {query: { [`event_ids[]`]: eventIds }, event_id: eventIds[0]}, true),
        { cancelToken: cancelToken.current.token }
      )

      setRegistrations(data.registrations)
      setAudiences(data.audiences)
      setRegions(data.regions)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div>
      {!registrations &&
        <div>
          Загрузка участников...
        </div>
      }

      {registrations &&
        <div className={styles.root}>
          {audiences &&
            <div className={styles.section}>
              <Audiences audiences={audiences} registrations={registrations} />
            </div>
          }

          {regions &&
            <div className={styles.section}>
              <Regions regions={regions} registrations={registrations} />
            </div>
          }
        </div>
      }
    </div>
  )
}
