import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';
import axios from 'axios';
import { animateScroll as scroll, scroller } from 'react-scroll';

import { Routes, path } from '../../Routes';

import Program from './Program';

import styles from '../Section.module.css';
import form from '../../Form.module.css';
import buttons from '../../Buttons.module.css';

class Programs extends React.Component {
  state = {
    show: true,
    program: false,
    programs: [],
    copy: false,
  }

  componentDidMount() {
    if(this.props.report) {
      this._loadAsyncData(this.props.report.id);
    }
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('programs_report_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          programs: res.data.programs,
          copy: res.data.copy,
        });

        this._asyncRequest = null;
      });
  }

  handleProgramsUpdate = (id) => {
    this._loadAsyncData(id);
  }

  handleProgramsClick = (id) => {
    this.setState({
      program: this.state.programs.find(p => p.id == id)
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.program != prevState.program) {
      this.scrollToText();
    }
  }

  scrollToText() {
    scroller.scrollTo('edit', {
      duration: 1500,
      smooth: true,
    });
  }

  handleCopy = () =>  {
    if(this._canCopy()) {
      this._asyncRequest = axios.CancelToken.source();

      axios.post(path('copy_report_path', { id: this.props.report.id }, false), { cancelToken: this._asyncRequest.token })
        .then(res => {
          this._loadAsyncData(this.props.report.id);

          this._asyncRequest = null;
        });
    }
  }

  _canCopy = () => {
    return (
      this.state.copy
    );
  }

  render () {
    const { show, program, programs, copy } = this.state;
    const { num, report, directions, monitoring } = this.props;

    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Контингент студентов
        </div>

        <div className={styles.content} name="edit">
          {program ? (
            <Program monitoring={monitoring} id={program.id} values={program.values} existed={programs.map(p => p.direction.id)} directions={directions} report={report} onCancel={() => this.setState(state => ({ program: false }))} onSave={this.handleProgramsUpdate}/>
          ) : (
            <>
              <div className={styles.buttons}>
                <button className={buttons.small} onClick={() => this.setState(state => ({ program: { values: {} } }))}>
                  Добавить направление
                </button>
                <button className={classNames(buttons.small, { [buttons.disabled]: !this._canCopy })} disabled={!this._canCopy()} onClick={this.handleCopy}>
                  Скопировать направления с пред. года
                </button>
              </div>
              {report && programs &&
                <div className={styles.programs}>
                  {programs.map(program =>
                    <div key={program.id} className={styles.program} onClick={() => this.handleProgramsClick(program.id)}>
                      <div className={styles.programTitle}>
                        {program.direction.title}
                      </div>

                      <div className={styles.programEdit}>
                        Редактировать
                      </div>
                    </div>
                  )}
                </div>
              }
            </>
          )}
        </div>
      </div>
    );
  }
}

Programs.defaultProps = {
  prefix: 'programs',
  num: '12'
};

export default Programs;
