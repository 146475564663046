import React from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import qs from 'query-string';

import Loaf from '../../Loaf/Loaf';
import { path } from '../../Routes';

import Compare from '../Compare';
import Consulting from './Consulting';
import Calls from './Calls';
import Umms from './Umms';

import page from '../../Page.module.css';
import styles from './Rumcs.module.css';

class Index extends React.Component {
  state = {
    search: this.props.location.search
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.summary === null) {
      this._loadAsyncData();
    }
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('stats_rumcs_path', { id: this.state.id }, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.state.search)})
      .then(res => {
        this.setState({
          summary: res.data.summary,
          consulting: res.data.consulting,
          calls: res.data.calls,
          umms: res.data.umms,
        });

        this._asyncRequest = null;
      });
  }

  static getDerivedStateFromProps(props, state) {
    if(state.id != props.match.params.id || props.location.search !== state.search) {
      return {
        id: props.match.params.id,
        search: props.location.search,
        summary: null
      }
    }

    return null;
  }

  render() {
    const { id, summary, consulting, calls, umms } = this.state;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('stats_path', { id })}>Весь отчёт</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Мониторинг ресурсных центров
            </h1>


            <Helmet>
              <title>
                Мониторинг ресурсных центров
              </title>
            </Helmet>
          </div>

          <Compare search={this.state.search} history={this.props.history} location={this.props.location} types={['rumcs']}/>

          {summary &&
            <div className={styles.root}>
              <div className={styles.consulting}>
                <Consulting data={consulting} />
              </div>
              <div className={styles.calls}>
                <Calls data={calls} />
              </div>
              <div className={styles.umms}>
                <Umms data={umms} />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Index;
