import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Checkboxes.module.css'

Checkboxes.propTypes = {
  element: PropTypes.object,
  dictionaries: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  onValueChange: PropTypes.func
}

export default function Checkboxes ({ element, value, dictionaries, onValueChange }) {
  const options = element.settings === 'Nosology' ? dictionaries.nosologies : JSON.parse(element.settings)

  const [values, setValues] = useState(() => {
    let init = {}

    options.forEach(option => {
      init[option.id] = value.includes(option.id.toString())
    })

    return init
  })

  const handleCheckboxChange = (id, changedValue) => {
    setValues({ ...values, [id]: changedValue })
  }

  useEffect(() => {
    let ids = Object.entries(values).filter(o => o[1]).map(o => o[0])
    if (ids.length === 0) ids = undefined
    onValueChange && onValueChange(ids)
  }, [values])

  return (
    <ul className={styles.checkboxes}>
      {options.map(option =>
        <Checkbox
          key={option.id}
          id={option.id}
          title={option.title}
          values={values}
          onCheckboxChange={handleCheckboxChange}
        />
      )}
    </ul>
  )
}

Checkbox.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  values: PropTypes.object,
  onCheckboxChange: PropTypes.func
}

function Checkbox ({ id, title, values, onCheckboxChange }) {
  const [value, setValue] = useState(values[id])

  const handleChange = ({ target: { checked } }) => {
    setValue(checked)
  }

  useEffect(() => {
    onCheckboxChange && onCheckboxChange(id, value)
  }, [value])

  return (
    <li className={classNames(styles.checkbox, { [styles.selected]: false })}>
      <label>
        <input
          checked={value}
          type="checkbox"
          name={`form-elem-${id}`}
          value={id}
          onChange={handleChange}
        />
        {title}
      </label>
    </li>
  )
}
