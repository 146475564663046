import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { Routes, path } from '../../Routes';

import Loaf from '../../Loaf/Loaf';

import Block from './Block'

import page from '../../Page.module.css';
import styles from './Rumc.module.css';

class Rumc extends React.Component {
  state = {
    rumc: null,
    blocks: null
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('stats_rumc_path', { id: this.props.id, rumc_id: this.props.rumc_id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { blocks, rumc } = res.data;

        this.setState({ blocks, rumc });
        this._asyncRequest = null;
      });
  }

  render() {
    const { blocks, rumc } = this.state

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>

          </Loaf>

          <div className={page.title}>
            <h1>
              {rumc &&
                `Эффективность ${rumc.title}`
              }
            </h1>


            <Helmet>
              <title>
                Эффективность ресурсных центров
              </title>
            </Helmet>
          </div>

          <div className={styles.blocks}>
            { blocks &&
              Object.entries(blocks).map((block, _ ) =>
                <Block key={_} block={block[1]} />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Rumc;
