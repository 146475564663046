import React from 'react'
// import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import CorrectionsIndex from './CorrectionsIndex'

class Corrections extends React.Component {
  render () {
    return (
      <React.Fragment>
        <Switch>
          <Route exact strict path={Routes.corrections_path} component={CorrectionsIndex} />
        </Switch>
      </React.Fragment>
    )
  }
}

export default Corrections
