import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'
import qs from 'query-string'
import Cookies from 'universal-cookie'
import ReactMarkdown from 'react-markdown'

import Loaf from '../../Loaf/Loaf'
import I18n from '../../I18n'
import Datec from '../../Datec'
import { path } from '../../Routes'
import AttachmentsList from '../../Attachments/AttachmentsList'
import Rate from '../../Rate/Rate'
import StagesList from '../../Stages/StagesList'

import Comments from '../../Comments/Comments'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
import styles from './Show.module.css'
import stylesStates from '../States.module.css'

const cookies = new Cookies()

class Show extends Component {
  state = {
    career: null,
    rate: null,
    stages: null
  }

  componentDidMount () {
    this._loadAsyncData(this.props.id)
  }

  _loadAsyncData (id) {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('account_career_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { career, rate, stages } = res.data

        this.setState({ career, rate, stages })
        this._asyncRequest = null;
      })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.career === null) this._loadAsyncData(this.props.id)
  }

  componentWillUnmount () {
    if(this._asyncRequest) this._asyncRequest.cancel()
  }

  handleStateChange = (event) => {
    this._asyncRequest = axios.CancelToken.source()

    axios({
      method: 'post',
      cancelToken: this._asyncRequest.token,
      url: path(`${event}_account_career_path`, { id: this.props.id }, true)
    }).then(
      res => {
        this.setState({
          career: null
        })
      }
    )
  }

  handleRateSave = () => {
    this.setState({
      career: null
    })
  }

  render () {
    const { career, rate, stages } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={`${path('account_careers_path')}?${qs.stringify(cookies.get('filters', { path: path('account_careers_path') }))}`}>{I18n.t('careers.title')}</Link>
          </Loaf>

          {career &&
            <>
              <div className={page.title}>
                <h1>
                  {career.title}
                </h1>

                <Helmet>
                  <title>{career.title}</title>
                </Helmet>
              </div>

              <div className={page.text}>
                <div className={styles.item}>
                  <ReactMarkdown source={career.desc} />
                </div>

                {'occupation areas responsibilities competences conditions'.split(' ').map(item =>
                  <div className={styles.item} key={item}>
                    <h3 className={styles.label}>
                      {I18n.t(`simple_form.labels.career.${item}`)}
                    </h3>
                    <div className={styles.value}>
                      <ReactMarkdown source={career[item]} />
                    </div>
                  </div>
                )}
              </div>

              <div className={stylesStates.root}>
                <div className={stylesStates.list}>
                  <div className={classNames(stylesStates.item, stylesStates.item_link)} key={99}>
                    {`Ссылка на страницу: https://инклюзивноеобразование.рф${decodeURIComponent(path('account_career_path', { id: this.props.id }))}`}
                  </div>
                  {career.state.states.map((state, i) =>
                    <div className={stylesStates.item} key={state.id}>
                      <div className={classNames(stylesStates.current, { [stylesStates.active]: state.active })}>
                        {i + 1}
                      </div>

                      <div className={stylesStates.rest}>
                        <div>
                          {I18n.t(`state.${state.id}`)}
                        </div>

                        {state.id === 'inactive' &&
                          <div className={styles.inactive}>
                            <p>
                              <strong>
                                {career.rumc.title}
                              </strong>
                              <br />
                              Материал создан <Datec time={career.created_at} />.
                              {career.can_edit &&
                                <>
                                  <br/>
                                  <Link to={path('edit_account_career_path', {id: career.id })}>Редактировать</Link>
                                </>
                              }
                            </p>

                            <ReactMarkdown source={career.desc} />
                          </div>
                        }

                        {state.id === 'active' &&
                          <div className={styles.active}>
                            <p>
                              На данном этапе вам нужно в форме редактирования профессии заполнить все дополнительные поля и загрузить медиа-паспорт с субтитрами. После этого можете отправить профессию на проверку.
                            </p>
                            <AttachmentsList attachments={career.attachments} />
                          </div>
                        }

                        {state.id === 'agreement' &&
                          <div className={styles.agreement}>
                            <p>
                              Материал проходит проверку эксперами, после того как они проставят оценки профессию можно будет отправить на финальное согласование.
                            </p>

                            {career.confirmations &&
                              <div className={stylesStates.confirmations}>
                                {career.confirmations.map(confirmation =>
                                  <div key={confirmation.id} className={classNames(stylesStates.confirmation, { [stylesStates.confirmed]: confirmation.state })}>
                                    {confirmation.confirmator} ({confirmation.rumc.title}) от 
                                    <Datec time={confirmation.created_at} />
                                  </div>
                                )}
                              </div>
                            }

                            {rate &&
                              <Rate rate={rate} ratable={{ type: "Career", id: career.id}} button="Сохранить и утвердить" onRateSave={this.handleRateSave} />
                            }

                            {rate && rate.not_rated &&
                              <Rate rate={rate} ratable={{ type: "Career", id: career.id}} not_rated={rate.not_rated} button="Сохранить и утвердить" onRateSave={this.handleRateSave} />
                            }
                          </div>
                        }

                        {state.active &&
                          <>
                            {state.id == 'done' &&
                              <div className={styles.done}>
                                <p>
                                  Материал готов, проверен экспертами РУМЦ и проверяется экспертами перед публикацией.

                                  {career.can_review &&
                                    <>
                                      <br />
                                      <Link to={path('review_account_career_path', { id: career.id })}>
                                        Поставить оценки
                                      </Link>
                                      <div className={classNames(styles.reviewed, { [styles.not]: career.review.rated_fields < career.review.fields })}>
                                        {`${career.review.rated_fields} из ${career.review.fields}`}
                                      </div>
                                    </>
                                  }
                                </p>
                              </div>
                            }

                            {state.id == 'agreed' &&
                              <div className={styles.review}>
                                <p>
                                  Материал проверен, согласован и готов к публикации. Для того чтобы он участвовал в формировании индекса необходимо проставить ручные характеристики объекта. После публикации оно будет доступно всем посетителям сайта.
                                </p>
                              </div>
                            }

                            {state.id == 'archived' &&
                              <div className={styles.archived}>
                                <p>
                                  Материал находится в архиве и участвует в расчете показателей. Вы можете разархивировать его, чтобы изменить оценки или чтобы он не участвовало в расчете показателей.
                                </p>
                              </div>
                            }

                            {state.events && state.events.length > 0 &&
                              <div className={styles.events}>
                                {state.events.map(event =>
                                  <div className={styles.event} key={event}>
                                    <button className={buttons.main} onClick={() => this.handleStateChange(event)}>{I18n.t(`state.events.${event}`)}</button>
                                  </div>
                                )}
                              </div>
                            }
                          </>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={page.text}>
                <div className={styles.stages}>
                  <StagesList stages={stages} location={this.props.location} />
                </div>
              </div>

              <Comments object="Career" id={this.props.id} />
            </>
          }
        </div>
      </div>
    )
  }
}

export default Show
