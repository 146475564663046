import React, { useState, useEffect } from 'react'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import axios from 'axios'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { YMInitializer } from 'react-yandex-metrika'

import ScrollToTop from './ScrollToTop'

import Current from './Current'

import Index from './Index/Index'
import Search from './Search/Search'
import SearchForm from './Search/Form'

import About from './Pages/About'

import Auth from './Auth/Auth'

import Abiturs from './Abiturs/Abiturs'
import Students from './Students/Students'
import Universities from './Universities/Universities'

import Contacts from './Pages/Contacts'
import Copyright from './Pages/Copyright'
import Support from './Pages/Support'
import Terms from './Terms/Terms'

import Header from './Header/Header'
import Footer from './Footer/Footer'

import CDN from './CDN/CDN'

// import Careers from './Careers/Careers'

// import Users from './Users/Users'
// import UsersRoles from './UsersRoles/UsersRoles'
// import Facilities from './Facilities/Facilities'
//
// import Forms from './Forms/Forms'
//
import InnerDocuments from './InnerDocuments/InnerDocuments'
// import TargetDocuments from './TargetDocuments'
// import TargetPrograms from './TargetPrograms/TargetPrograms'
// import Trainings from './Trainings/Trainings'
import Corrections from './Corrections/Corrections'
// import Targets from './Targets/Targets'
//
// import { RoutesContext } from './Context/RoutesContext'
// import { ListsContext } from './Context/ListsContext'
// import { AuthTokenContext } from './Context/AuthTokenContext'
// import { UserContext } from './Context/UserContext'
// import { I18nContext } from './Context/I18nContext'

import I18n from './I18n'
import { Routes } from './Routes'

import Consumer from './Consumer'

Sentry.init({
  dsn: "https://538888ba725c4b869176f70997525eda@sentry.miccedu.ru/21",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const Account = loadable(() => import('./Account/Account'))
const Events = loadable(() => import('./Events/Events'))
const Requests = loadable(() => import('./Requests/Requests'))
const Rumcs = loadable(() => import('./Rumcs/Rumcs'))
const Forms = loadable(() => import('./Forms/Forms'))
const Reports = loadable(() => import('./Reports/Reports'))
// const Stats = loadable(() => import('./Stats/Stats'))
//
const Schools = loadable(() => import('./Schools/Schools'))
const Careers = loadable(() => import('./Careers/Careers'))
const Vacancy = loadable(() => import('./Vacancy/Vacancy'))
const Courses = loadable(() => import('./Courses/Courses'))
const Umms = loadable(() => import('./Umms/Umms'))
const Stages = loadable(() => import('./Stages/Stages'))
const Tomorrow = loadable(() => import('./Events/Static/Tomorrow'))
const Admin = loadable(() => import('./Admin/Admin'))
const Calls = loadable(() => import('./Calls/Calls'))
const Monitoring = loadable(() => import('./Monitorings/Show'))
//
const Statistics = loadable(() => import('./Statistics/Statistics'))

const Partners = loadable(() => import('./Partners/Partners'))
const TypesEquipment = loadable(() => import('./TypesEquipment/TypesEquipment'))
const News = loadable(() => import('./News/News'))
const Annotations = loadable(() => import('./Students/Annotations'))
const MonitoringPage = loadable(() => import('./MonitoringPage/MonitoringPage'))
const Projects = loadable(() => import('./Projects/Projects'))


App.propTypes = {
  // title: PropTypes.string,
  env: PropTypes.string,
  token: PropTypes.string,
  // authenticity_token: PropTypes.string,
  index_counters: PropTypes.object,
  // lists: PropTypes.object,
  locale: PropTypes.string,
  user: PropTypes.object
}

export default function App (props) {
  I18n.locale = props.locale

  const [user, setUser] = useState(props.user)
  const [token, setToken] = useState(props.token)
  const [loaf, setLoaf] = useState([])

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`

    axios.interceptors.response.use(
      response => response,
      error => {
        if (!axios.isCancel(error) && error.response.status === 401 && location) {
          location.reload(true)
        } else {
          return Promise.reject(error)
        }
      })
  }, [token])

  useEffect(() => {
    Consumer.subscriptions.create(
      { channel: 'UserChannel' },
      {
        received ({ user, token }) {
          setToken(token)
          setUser(user)
        }
      }
    )
  }, [])
  
  // console.log(Routes)

  return (
    <Current.Provider
      value={{
        user,
        setUser,
        loaf,
        setLoaf
      }}
    >
      <HelmetProvider>
        <Helmet
          defaultTitle={I18n.t('meta.site')}
          titleTemplate={'%s — ' + I18n.t('meta.site')}
        />

        <Router>
          <ScrollToTop>
            <Header />
            
            <Route exact strict path={[Routes.root_path, Routes.search_path]}>
              <SearchForm />
            </Route>

            <Switch> 

              <Route path={Routes.projects_path}>
                <Projects />
              </Route>

              <Route path={Routes.monitoring_page_index_path}>
                <MonitoringPage />
              </Route>

              <Route path={Routes.annotations_path}>
                <Annotations />
              </Route>

              <Route path={Routes.partners_index_path}>
                <Partners />
              </Route>

              <Route path={Routes.types_equipment_index_path}>
                <TypesEquipment />
              </Route> 

              <Route path={Routes.news_index_path}>
                <News />
              </Route>

              <Route path={Routes.cdn_index_path}>
                <CDN />
              </Route>

              <Route path={Routes.about_path}>
                <About />
              </Route>

              <Route path={Routes.abiturs_path}>
                <Abiturs />
              </Route>

              <Route path={Routes.students_path}>
                <Students />
              </Route>

              <Route path={Routes.universities_path}>
                <Universities />
              </Route>

              <Route path={Routes.careers_path}>
                <Careers />
              </Route>

              <Route path={Routes.vacancy_index_path}>
                <Vacancy />
              </Route>

              <Route path={[Routes.stage_course_path, Routes.stage_umm_path]}>
                <Stages />
              </Route>

              <Route path={Routes.umms_path}>
                <Umms />
              </Route>

              <Route path={Routes.courses_path}>
                <Courses />
              </Route>

              <Route path={Routes.rumcs_path}>
                <Rumcs />
              </Route>

              <Route path={Routes.events_path}>
                <Events />
              </Route>

              <Route path={Routes.schools_path}>
                <Schools />
              </Route>

              <Route path={Routes.forms_path}>
                <Forms />
              </Route>

              <Route path={Routes.monitoring_path}>
                <Monitoring />
              </Route>

              <Route path={Routes.reports_path}>
                <Reports />
              </Route>

              <Route
                path={[
                  Routes.login_path,
                  Routes.join_path,
                  Routes.recovery_path
                ]}
              >
                <Auth />
              </Route>

              <Route path={Routes.search_path}>
                <Search />
              </Route>

              <Route path={Routes.account_path}>
                <Account />
              </Route>

              <Route path={Routes.admin_path}>
                <Admin />
              </Route>

              <Route path={Routes.inner_documents_path}>
                <InnerDocuments />
              </Route>

              <Route path={Routes.contacts_path}><Contacts /></Route>
              <Route path={Routes.requests_path}><Requests /></Route>

              <Route path={Routes.support_path}><Support /></Route>
              <Route path={Routes.copyright_path}><Copyright /></Route>

              <Route path={Routes.terms_path}><Terms /></Route>

              <Route exact strict path={Routes.root_path}>
                <Index counters={props.index_counters} />
              </Route>

              <Route path={Routes.tomorrow_path}><Tomorrow /></Route>

              <Route path={Routes.calls_path}><Calls /></Route>

              <Route path={Routes.statistics_path}>
                <Statistics />
              </Route>

              <Route path={Routes.corrections_path}>
                <Corrections />
              </Route>
            </Switch>

            <Footer />

            {props.env === 'production' &&
              <YMInitializer
                accounts={[38958365]}
                version="2"
                options={{
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true,
                  trackHash: true
                }}
              />
            }
          </ScrollToTop>
        </Router>
      </HelmetProvider>
    </Current.Provider>
  )
}

// App.propTypes = {
//   title: PropTypes.string,
//   env: PropTypes.string,
//   jwt_token: PropTypes.string,
//   authenticity_token: PropTypes.string,
//   lists: PropTypes.object,
//   locale: PropTypes.string,
//   user: PropTypes.object
// }

// export default App
