import React from 'react'
// import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import I18n from '../I18n'

import page from '../Page.module.css'

class Support extends React.Component {
  render () {
    const title = 'Техническая поддержка'

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>{I18n.t('pages.support')}</h1>

            <Helmet>
              <title>{I18n.t('pages.support')}</title>
            </Helmet>
          </div>

          <div className={page.text}>
            <p>Техническую поддержку портала осуществляет структурное подразделение Российского технологического университета МИРЭА — ГИВЦ</p>
            <p><a href='https://mirea.ru' target='_blank'>https://mirea.ru</a> — МИРЭА — Российский технологический университет</p>
            <p><a href='https://miccedu.ru' target='_blank'>https://miccedu.ru</a> — Главный информационно-вычислительный центр</p>
            <hr />
            <p>
              По техническим вопросам можно написать на почту <a href="mailto:pub@incedu.ru">pub@incedu.ru</a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Support
