import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import dayjs from 'dayjs'

import Link from '../../Routes'

import styles from './Calendar.module.css'

class Calendar extends React.Component {
  state = {
  }

  handleNext = () => {
    let date = this.state.date.clone().add(1, 'month')

    this.setState(prevState => ({
      date: date,
      start_date: date.clone().startOf('month').startOf('week'),
      end_date: date.clone().endOf('month').endOf('week')
    }))
  }

  handlePrev = () => {
    let date = this.state.date.clone().subtract(1, 'month')

    this.setState(prevState => ({
      date: date,
      start_date: date.clone().startOf('month').startOf('week'),
      end_date: date.clone().endOf('month').endOf('week')
    }))
  }

  static getDerivedStateFromProps (props, state) {
    if (state.prevDate !== props.date || !state.prev) {
      const date = dayjs(props.date)
      return {
        prev: true,
        prevDate: props.date,
        date: date,
        active: date,
        start_date: date.startOf('month').startOf('week'),
        end_date: date.endOf('month').endOf('week')
      }
    }

    return null
  }

  render () {
    const { date, active, end_date: endDate, start_date: startDate } = this.state
    // console.log(startDate);

    if (!date) return null

    return (
      <div className={styles.root}>
        <div className={styles.months}>
          <div className={classNames(styles.item, styles.prev, styles.schema_calendar)} onClick={this.handlePrev}>
            {this.state.date.clone().subtract(1, 'M').format('MMM')}
          </div>
          <div className={classNames(styles.item, styles.current, styles.schema_calendar)}>
            {this.state.date.format('MMMM YYYY')}
          </div>
          <div className={classNames(styles.item, styles.next, styles.schema_calendar)} onClick={this.handleNext}>
            {this.state.date.clone().add(1, 'M').format('MMM')}
          </div>
        </div>
        <div className={styles.month}>
          {Array.from({ length: endDate.diff(startDate, 'days') + 1 }, (v, k) => startDate.clone().add(k, 'day')).map((day, _) =>
            <Day
              key={day.year() + '_' + day.month() + '_' + day.date()}
              active={active}
              date={date}
              day={day}
              end_date={date.endOf('month')}
              start_date={date.startOf('month')}
              events={this.props.events.filter(event => (event.year === day.year() && event.month === (day.month() + 1) && event.day === day.date()))}
            />
          )}
        </div>
      </div>
    )
  }
}

Calendar.propTypes = {
  date: PropTypes.string,
  events: PropTypes.array
}

class Day extends Component {
  isToday = () => {
    return this.props.day.isSame(dayjs(), 'day')
  }

  isActive = () => {
    return this.props.day.isSame(this.props.active, 'day')
  }

  isOut = () => {
    if (this.props.day.$D == this.props.start_date.$D && this.props.day.$M == this.props.start_date.$M) {
      return false
    }
    return !this.props.day.isBetween(this.props.start_date, this.props.end_date);
  }

  groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item
      ]
    }),
    {}
  )

  render () {
    const { events, day } = this.props

    return (
      <div className={classNames(styles.item, { [styles.today]: this.isToday(), [styles.active]: this.isActive(), [styles.out]: this.isOut() })}>
        {!this.isOut() &&
          <>
            {events.length > 0 &&
              <Link to="date_events_path" params={{ date: this.props.day.format('YYYY-MM-DD') }} className={styles.schema_calendar} target="_blank">
                {this.props.events.length > 0 &&
                  <div className={styles.events}>
                    {Object.entries(this.groupBy(this.props.events, 'section')).map((section, _) =>
                      <div key={section[0]} className={classNames(styles.event, styles[`section_${section[0]}`])}>
                      </div>
                    )}
                  </div>
                }
                {day.format('D')}
              </Link>
            }

            {events.length < 1 &&
              <div className={styles.void}>
                {day.format('D')}
              </div>
            }
          </>

        }
      </div>
    )
  }
}

Day.propTypes = {
  events: PropTypes.array,
  date: PropTypes.object,
  day: PropTypes.object,
  start_date: PropTypes.object,
  end_date: PropTypes.object,
  active: PropTypes.object
}

export default Calendar
