import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import qs from 'query-string';

import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import List from '../List';

import page from '../../Page.module.css';
import buttons from '../../Buttons.module.css'

class Index extends Component {
  state = {
    stories: null,
    search: this.props.location.search,
    filters: null
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('account_stories_path', {}, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search) })
      .then(res => {
        this.setState({ ...res.data });
      });
  }

  static getDerivedStateFromProps(props, state) {
    if(props.location.search !== state.search) {
      return {
        stories: null,
        search: props.location.search,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.stories === null) this._loadAsyncData();
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  render() {
    const { stories, filters, can_new: canNew } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">{I18n.t('account.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('stories.title')}
            </h1>

            <Helmet>
              <title>{I18n.t('stories.title')}</title>
            </Helmet>

            {canNew &&
              <div>
                <Link className={buttons.main} to="new_account_story_path">Новая история</Link>
              </div>
            }
          </div>

          <div>
            <List items={stories} filters={filters} {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
