import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import classNames from 'classnames'

import Loaf from '../Loaf/Loaf'

import Link, { path } from '../Routes'
import I18n from '../I18n'
import Datec from '../Datec'

import buttons from '../Buttons.module.css'
import page from '../Page.module.css'
import styles from './Index.module.css'

export default function Index () {
  // const history = useHistory()
  const cancelToken = axios.CancelToken.source()

  const [requests, setRequests] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('requests_path', {}, true), { cancelToken: cancelToken.token })

      setRequests(data.requests)
    }

    _fetch()

    return function cleanup () {
      cancelToken.cancel()
    }
  }, [])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('requests.title')}
          </h1>

          <Helmet>
            <title>
              {I18n.t('requests.title')}
            </title>
          </Helmet>
        </div>

        <div>
          <Link className={buttons.main} to="new_request_path">
            Новый вопрос
          </Link>
        </div>

        {requests &&
          <div className={styles.requests}>
            {requests.map(request =>
              <Link to="request_path" params={{ id: request.id }} className={styles.request} key={request.id}>
                <div className={styles.meta}>
                  <div className={classNames(styles.state, styles[`state_${request.state}`])}>
                    {I18n.t(`request.state.${request.state}`)}
                  </div>

                  <div className={styles.id}>
                    Вопрос № {request.id}
                  </div>

                  <div>
                    <Datec time={request.created_at} />
                  </div>
                </div>

                <div className={styles.body}>
                  {request.body}
                </div>
              </Link>
            )}
          </div>
        }
      </div>
    </div>
  )
}
