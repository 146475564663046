import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'

import Loaf from '../../Loaf/Loaf'
import I18n from '../../I18n'
import Datec from '../../Datec'
import { path } from '../../Routes'
import AttachmentsList from '../../Attachments/AttachmentsList'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
import styles from './Show.module.css'
import stylesStates from '../States.module.css'

class Show extends Component {
  state = {
    target_program: null
  }

  componentDidMount () {
    this._loadAsyncData(this.props.id)
  }

  _loadAsyncData (id) {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('account_target_program_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { target_program } = res.data

        this.setState({ target_program })
        this._asyncRequest = null;
      });
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.target_program === null) this._loadAsyncData(this.props.id)
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  handleStateChange = (event) => {
    this._asyncRequest = axios.CancelToken.source()

    axios({
      method: 'post',
      cancelToken: this._asyncRequest.token,
      url: path(`${event}_account_target_program_path`, { id: this.props.id }, true)
    }).then(
      res => {
        this.setState({
          target_program: null
        })
      }
    )
  }

  render () {
    const { target_program } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={path('account_target_programs_path')}>{I18n.t('target_programs.title')}</Link>
          </Loaf>

          {target_program &&
            <>
              <div className={page.title}>
                <h1>
                  {target_program.title}
                </h1>

                <Helmet>
                  <title>{target_program.title}</title>
                </Helmet>
              </div>

              <div className={stylesStates.root}>
                <div className={stylesStates.list}>
                  <div className={classNames(stylesStates.item, stylesStates.item_link)} key={99}>
                    {`Ссылка на страницу: https://инклюзивноеобразование.рф${decodeURIComponent(path('account_target_program_path', { id: this.props.id }))}`}
                  </div>
                  {target_program.state.states.map((state, i) =>
                    <div className={stylesStates.item} key={state.id}>
                      <div className={classNames(stylesStates.current, { [stylesStates.active]: state.active })}>
                        {i + 1}
                      </div>

                      <div className={stylesStates.rest}>
                        <div>
                          {I18n.t(`state.${state.id}`)}
                        </div>

                        {state.id === 'active' &&
                          <div className={styles.active}>
                            <p>
                              <strong>
                                {target_program.rumc.title}
                              </strong>
                              <br />
                              Программа была создана <Datec time={target_program.created_at} />.

                              {target_program.can_edit &&
                                <>
                                  <br/>
                                  <Link to={path('edit_account_target_program_path', {id: target_program.id })}>Редактировать</Link>
                                </>
                              }

                            </p>

                            <AttachmentsList attachments={target_program.attachments} />
                          </div>
                        }

                        {state.active &&
                          <>
                            {state.id === 'agreed' &&
                              <div className={styles.review}>
                                <p>
                                  Программа проверена, согласован и готов к публикации для расчета индекса. Для того чтобы она участвовал в формировании индекса необходимо проставить ручные характеристики объекта. Программы не публикуются в публичном доступе.

                                  {target_program.can_review &&
                                    <>
                                      <br />
                                      <Link to={path('review_account_target_program_path', { id: target_program.id })}>
                                        Поставить оценки
                                      </Link>
                                      <div className={classNames(styles.reviewed, { [styles.not]: target_program.review.rated_fields < target_program.review.fields })}>
                                        {`${target_program.review.rated_fields} из ${target_program.review.fields}`}
                                      </div>
                                    </>
                                  }
                                </p>
                              </div>
                            }

                            {state.id === 'archived' &&
                              <div className={styles.archived}>
                                <p>
                                  Программа находится в архиве и участвует в расчете показателей. Вы можете разархивировать чтобы изменить оценки или чтобы она не участвовала в расчете показателей.
                                </p>
                              </div>
                            }

                            {state.events && state.events.length > 0 &&
                              <div className={styles.events}>
                                {state.events.map(event =>
                                  <div className={styles.event} key={event}>
                                    <button className={buttons.main} onClick={() => this.handleStateChange(event)}>{I18n.t(`state.events.${event}`)}</button>
                                  </div>
                                )}
                              </div>
                            }
                          </>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  }
}

export default Show;
