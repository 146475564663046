import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import I18n from './I18n'

import styles from './Datec.module.css'

class Datec extends Component {
  render () {
    const time = DateTime.fromISO(this.props.time, { setZone: true })
    const timeOffset = time.offset

    const mask = this.props.type === 'date' ? 'dd.LL.yy' : 'dd.LL.yy HH:mm'

    return (
      <>
        {time.setZone(180).toFormat(mask)}<span className={styles.tz}>МСК</span>
        {timeOffset !== 180 && timeOffset !== 0 &&
          <span className={styles.local}>
            ({time.setZone(timeOffset).toFormat('HH:mm')}<span className={styles.tz}>{I18n.t(`tz.${time.setZone(timeOffset).offset}`)}</span>)
          </span>
        }
      </>
    )
  }
}

Datec.propTypes = {
  time: PropTypes.string,
  type: PropTypes.string
}

export default Datec
