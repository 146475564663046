import React from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import I18n from '../I18n'

import styles from './About.module.css'
import page from '../Page.module.css'

import IconUsers from '../../images/pages/about/about_icon_1.svg'
import IconSchools from '../../images/pages/about/about_icon_2.svg'
import IconMinistries from '../../images/pages/about/about_icon_3.svg'
import IllCrane from '../../images/icons/crane.svg'

export default function About () {
  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <div className={classNames(page.title, styles.title)}>
          <h1>{I18n.t('pages.about.title')}</h1>

          <Helmet>
            <title>{I18n.t('pages.about.title')}</title>
          </Helmet>

          <p>
            {I18n.t('pages.about.desc')}
          </p>

          <p>
            <img src={IllCrane} alt="Иконка для оформления: логотип" aria-hidden="true" />
          </p>
        </div>

        <div className={styles.benefits}>
          <div className={styles.text}>
            <h2>
              {I18n.t('pages.about.benefits.title')}
            </h2>

            <p>
              {I18n.t('pages.about.benefits.desc')}
            </p>
          </div>

          <div className={styles.video}>
            <div className={styles.frame}>
              <iframe src={`https://www.youtube.com/embed/312ELG9YdEo?modestbranding=1&autohide=1&showinfo=0&rel=0&cc_load_policy=1`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
            </div>
          </div>
        </div>

        <div className={styles.who}>
          <div className={styles.text}>
            <h2>
              {I18n.t('pages.about.who.title')}
            </h2>

            <p>
              {I18n.t('pages.about.who.desc')}
            </p>
          </div>

          <div className={styles.target}>
            <img src={IconUsers} alt="Иконка для оформления: кресло" aria-hidden="true" />

            <h3>
              {I18n.t('pages.about.who.users.title')}
            </h3>

            <p>
              {I18n.t('pages.about.who.users.desc')}
            </p>
          </div>

          <div className={styles.target}>
            <img src={IconSchools} alt="Иконка для оформления: ноутбук" aria-hidden="true" />

            <h3>
              {I18n.t('pages.about.who.schools.title')}
            </h3>

            <p>
              {I18n.t('pages.about.who.schools.desc')}
            </p>
          </div>

          <div className={styles.target}>
            <img src={IconMinistries} alt="Иконка для оформления: символ здания" aria-hidden="true" />

            <h3>
              {I18n.t('pages.about.who.ministries.title')}
            </h3>

            <p>
              {I18n.t('pages.about.who.ministries.desc')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
