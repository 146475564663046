import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import Loaf from '../Loaf/Loaf'

import I18n from '../I18n'
import Link, { path } from '../Routes'
import Directory from '../Directory'

import page from '../Page.module.css'

export default function Index () {
  const cancelToken = useRef(axios.CancelToken.source())
  const [umms, setUmms] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('umms_path', {}, true), { cancelToken: cancelToken.current.token })

      setUmms(data.umms)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="students_path">{I18n.t('nav.students')}</Link>
        </Loaf>
        <div className={page.title}>
          <h1>
            {I18n.t('umms.title')}
          </h1>

          <Helmet>
            <title>{I18n.t('umms.title')}</title>
          </Helmet>
        </div>

        <Directory items={umms} type="umm" />
      </div>
    </div>
  )
}
