import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { Routes } from '../Routes';

import Index from './Index';
import Form from './Form';

class EventVisits extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact strict path={decodeURIComponent(Routes.event_event_visits_path)} render={props => <Index event_id={props.match.params.event_id} />} />
          <Route path={decodeURIComponent(Routes.new_event_event_visit_path)} render={props => <Form event_id={props.match.params.event_id} />} />
          <Route path={decodeURIComponent(Routes.edit_event_event_visit_path)} render={props => <Form event_id={props.match.params.event_id} id={props.match.params.id} />} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default EventVisits;
