import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import classNames from 'classnames'
import axios from 'axios'
import qs from 'query-string'

import Loaf from '../../Loaf/Loaf'
import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import List from '../List'
import Stats from './Index/Stats'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'

class Index extends Component {
  state = {
    stats: false,
    events: null,
    search: this.props.location.search,
    filters: null,
    loading: false,
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  _loadAsyncData = async () => {
    console.log('_loadAsyncData')
    this.setState({loading: true});
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('account_events_path', {}, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search) })
    this._asyncRequest = null

    this.setState({ ...res.data, loading: false })
  }

  static getDerivedStateFromProps (props, state) {
    if (props.location.search !== state.search) {
      return {
        events: null,
        search: props.location.search
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.events === null && !this.state.loading) this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  render () {
    const { stats, events, filters, can_new: canNew, loading } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">{I18n.t('account.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('events.title')}
            </h1>

            <Helmet>
              <title>{I18n.t('events.title')}</title>
            </Helmet>


            {loading && 
              <button className={classNames(buttons.main, buttons.preloader)} disabled={true} style={{height: '48px', marginBottom: '20px'}} />
            }

            {canNew &&
              <div className={page.links}>
                <Link className={buttons.main} to="new_account_event_path">Новое событие</Link>

                <div className={buttons.grey} onClick={() => this.setState(state => ({ ...state, stats: !state.stats }))}>
                  Статистика участников
                </div>
              </div>
            }
          </div>

          <div>
            {stats && events &&
              <Stats eventIds={events.map(event => event.id)} />
            }

            <List items={events} filters={filters} {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

Index.propTypes = {
  location: PropTypes.object
}

export default Index
