import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import GlobalVariables from '../Vars';

import I18n from '../I18n';
import Link, { path } from '../Routes';
import Loaf from '../Loaf/Loaf';
import SearchBar from './Search/SearchBar';

import page from '../Page.module.css';
import buttons from '../Buttons.module.css';
import styles from './Index.module.css';
import show_styles from './Show.module.css';

import umm_img from '../../images/schools/umm.svg';
import test_1 from '../../images/schools/test_1.svg';
import test_2 from '../../images/schools/test_2.svg';

import StarRatings from 'react-star-ratings';
import qs from 'qs';
import { DefaultFilterValue, FilterType } from './Search/Filter/constants';

import ScrollIntoView from 'react-scroll-into-view';

import forward_arrow from '../../images/icons/forward_arrow_white.svg';
import back_arrow from '../../images/icons/bc_white.svg';

class Index extends Component {
    state = {
      input: '',
      schools: null,
      appliedFilters: DefaultFilterValue,
      pages: [1],
      pageContentSize: 20,
      search: this.props.location.search,
      filtered_pages: [],
      hide_images: false,
      schools_count: 716,
      isScroll: false
    };

    componentDidMount() {
      this.letsGoBoys();
      window.addEventListener('scroll', this.handleScroll);
    }

    letsGoBoys = () => {
      const params = this.parseURLQuery();
      this.setState({
        appliedFilters: params,
        hide_images: params.hide_images == "true" ? true : false
      });
      this.loadSchoolsList(params);
    };

    parseURLQuery = () => {
      const searchParamsStr = this.props.history?.location?.search;
      const paramsObject = qs.parse(searchParamsStr || '', { ignoreQueryPrefix: true }) || {};

      var overriddenDefaultFilters = { ...DefaultFilterValue };
      for (const key of Object.keys(DefaultFilterValue)) {
        if (paramsObject[key]) {
          overriddenDefaultFilters[key] = paramsObject[key];
        }
      }
      return overriddenDefaultFilters;
    };

    componentWillUnmount() {
      if (this._asyncRequestSchools) this._asyncRequestSchools.cancel();
      window.removeEventListener('scroll', this.handleScroll);
    }

    filterSchools = (e) => {
      this.setState({ input: e.target.value.toLowerCase() });
    };

    filterPages = () => {
      let active_page = this.state.appliedFilters[FilterType.Page] || 1;

      let min = active_page - 3;
      let max = active_page + 2;
      if (window.innerWidth <= 480) {
        min = active_page - 2;
        max = active_page + 1;
      }
      let filtered = [...this.state.pages];

      if (min < 0) {
        min = 0;
        max = 5;
        if (window.innerWidth <= 480) {
          max = 3;
        }
      }

      if (max >= filtered.length) {
        min = filtered.length - 5;
        if (window.innerWidth <= 320) {
          min = filtered.length - 3;
        }
        max = filtered.length;
      }
      return filtered.filter((v, i) => {
        if (i >= min && i < max) return v;
      });
    };

    setActivePage = (e, page) => {
      var appliedFiltersCopy = { ...this.state.appliedFilters };
      appliedFiltersCopy[FilterType.Page] = page;

      setTimeout(() => {
        this.setState({
          appliedFilters: appliedFiltersCopy
        });
        this.loadSchoolsList(appliedFiltersCopy);
      }, 100);
    };

    hideImages() {
      const searchParamsStr = this.props.history?.location?.search;
      const paramsObject = qs.parse(searchParamsStr || '', { ignoreQueryPrefix: true }) || {};
      this.props.history.push({
        pathname: this.props.match.path,
        search: qs.stringify({ ...paramsObject, hide_images: !this.state.hide_images })
      });

      this.setState({ hide_images: !this.state.hide_images });
    }

    handleScroll = () => {
      if (window.scrollY >= 500  && !this.state.isScroll) {
        this.setState({ isScroll: true });
      } else if (window.scrollY < 500 && this.state.isScroll) {
        this.setState({ isScroll: false });
      }
    }

    loadSchoolsList = async (params = {}) => {
      this._asyncRequestSchools = axios.CancelToken.source();
      const res = await axios.get(path('schools_path', {}, true), { cancelToken: this._asyncRequestSchools.token, params: params });

      //Обновляем браузерный URL
      const strParams = qs.stringify(params) || '';
      this.props.history.push({
        pathname: this.props.match.path,
        search: strParams.length > 0 ? '?' + qs.stringify(params) : ''
      });

      this._asyncRequestSchools = null;

      this.setState({
        schools: res.data.schools,
        pages: [...Array(res.data.pages).keys()].map((i) => i + 1),
        schools_count: res.data.count
      });
    };

    applyFilters = async (incomeFilters) => {
      var filters = { ...incomeFilters };
      filters[FilterType.Page] = 1;
      this.setState({
        appliedFilters: filters,
        schools: undefined
      });

      localStorage.setItem("schools_applied_filters", JSON.stringify(filters));

      await this.loadSchoolsList(filters);
    };

    setFilterRegion = (e, region_id) => {
      e.preventDefault();
      if (!region_id) return;

      var overridenFiltters = { ...DefaultFilterValue };
      overridenFiltters[FilterType.Regions] = [region_id];
      this.applyFilters(overridenFiltters);
    };

    goToRumc(event, rumc_id) {
      event.preventDefault();
      const rumcPath = path('rumc_path', { id: rumc_id }, false);
      this.props.history.push({
        pathname: rumcPath
      });
    }

    render() {
      let schools_count = this.state.schools_count;
      let isScroll = this.state.isScroll;
      const { schools, appliedFilters } = this.state;
      const activePage = Number(appliedFilters[FilterType.Page]) || 1;

      return (
        <div className={page.wrapper} id="header">
          <div className={page.container}>
            {this.props.location.hash !== '#index' && (
              <Loaf>
                <Link to="root_path">{I18n.t('nav.root')}</Link>
                <Link to="abiturs_path">{I18n.t('nav.abiturs')}</Link>
              </Loaf>
            )}
            {isScroll && <ScrollIntoView selector="#header" className={show_styles.button_up}></ScrollIntoView>}
            <div className={page.title}>
              <h1>{I18n.t('schools.title')}</h1>

              <Helmet>
                <title>{I18n.t('schools.title')}</title>
              </Helmet>
            </div>

            {!schools && <div className={styles.loading} /> }
            <div className={styles.schoolsSearchWrapper}>
              {schools && schools.length > 0 && (
                <div className={styles.schools}>
                  <SearchBar presetFilters={this.parseURLQuery()} onApplyFilters={this.applyFilters} schools_count={schools_count} />
                  {schools.map((school) => (
                      <div className={styles.card_background} key={school.id}>
                        <Link to={"school_path"} params={{ id: school.id, query: {hide_images: this.state.hide_images} }} className={!this.state.hide_images ? classNames(styles.card, styles.card_background_img) : styles.card}>
                          <div className={styles.card_header}>
                            {!this.state.hide_images && <img src={GlobalVariables.cdnHost + school.gerb_org} alt="Логотип"></img>}

                            <div className={styles.title}>
                              {school.abbr && school.abbr != school.short_name && <div className={styles.abbr}>{school.abbr}</div>}
                              <div className={styles.name}>{school.short_name}</div>
                              {school.stars && (
                                <div className={styles.rating}>
                                  <StarRatings
                                    rating={school.stars}
                                    starRatedColor="#FFDD09"
                                    numberOfStars={5}
                                    starDimension="15px"
                                    starSpacing="1px"
                                    isSelectable={false}
                                    isAggregateRating={true}
                                  />
                                  <span>{I18n.t('schools.counters.stars', { count: school.count_stars })}</span>
                                </div>
                              )}

                              <div className={styles.city} onClick={(e) => this.setFilterRegion(e, school.regions_id)}>
                                {school.city}
                              </div>

                              <div className={styles.card_items}>
                                {school.count_specialties && school.count_specialties > 0 && (
                                  <span>{I18n.t('schools.counters.programs', { count: school.count_specialties })}</span>
                                )}
                                {school.branches_count != 0 && <span>{I18n.t('schools.counters.branches', { count: school.branches_count })}</span>}
                              </div>
                            </div>

                            <div className={styles.nosologies}>
                              {school.is_rumc && <div className={classNames(styles.n_item, styles.is_rumc)} title="Вуз является РУМЦ-ем" />}
                              <div className={school.e ? classNames(styles.n_item, styles.e) : styles.n_item} title="Нарушения зрения"></div>
                              <div className={school.h ? classNames(styles.n_item, styles.h) : styles.n_item} title="Нарушения слуха"></div>
                              <div className={school.m ? classNames(styles.n_item, styles.m) : styles.n_item} title="Нарушения опорно-двигательного аппарата"></div>
                            </div>
                          </div>

                          <div className={styles.card_stat}>
                              <div className={styles.item}>
                                  <div className={styles.title}>Статус</div>
                                  <div className={styles.val}>{school.uchred}</div>
                              </div>

                              <div className={styles.item}>
                                  <div className={styles.title}>Количество студентов</div>
                                  <div className={styles.val}>{school.count_students}</div>
                              </div>

                              <div className={styles.item}>
                                  <div className={styles.title}>Количество студентов с ОВЗ</div>
                                  <div className={styles.val}>{school.count_ovz}</div>
                              </div>

                              {school.percent_grad_ovz_employed && (
                                  <div className={styles.item}>
                                      <div className={styles.title}>Трудоустроенных студентов с ОВЗ</div>
                                      <div className={styles.val}>{school.percent_grad_ovz_employed}%</div>
                                  </div>
                              )}
                          </div>
                          
                          {school.rumcs_id && 
                            <>
                              <hr className={styles.school_hr} />

                              <div className={styles.card_footer}>
                                      <button className={classNames(buttons.main, styles.button, styles.button_main)} onClick={(event) => this.goToRumc(event, school.rumcs_id)}>
                                          <span className={styles.big_rumc_btn}>Ресурсный учебно-методический центр</span>
                                          <span className={styles.small_rumc_btn}>РУМЦ</span>
                                      </button>
                                  {!this.state.hide_images && <div className={styles.chelik}></div>}
                              </div>
                            </>
                          }
                        </Link>
                      </div>
                  ))}
                  <div className={styles.pagination}>
                    <div
                      className={classNames(styles.first_last_page, styles.first_last_page_first)}
                      title="В начало"
                      onClick={(e) => {
                          activePage != 1 ? this.setActivePage(e, 1) : '';
                      }}
                    >
                      <img src={back_arrow} />
                      <img src={back_arrow} />
                    </div>

                    <div
                      className={styles.back_forward}
                      onClick={(e) => {
                          activePage != 1 ? this.setActivePage(e, activePage - 1) : '';
                      }}
                    >
                      <div className={styles.back_arrow}></div>
                      <span className={activePage == 1 ? styles.disabled : styles.enabled} id="back">
                        Назад
                      </span>
                    </div>
                    {this.filterPages().map((p, i) => (
                      <div
                        key={i}
                        className={activePage == p ? classNames(styles.pagination_page, styles.pagination_active) : styles.pagination_page}
                        id={p}
                        onClick={(e) => {
                            this.setActivePage(e, p);
                        }}
                      >
                        {p}
                      </div>
                    ))}
                    <div
                      className={styles.back_forward}
                      onClick={(e) => {
                        activePage != this.state.pages[this.state.pages.length - 1] ? this.setActivePage(e, activePage + 1) : '';
                      }}
                    >
                      <span className={activePage == this.state.pages[this.state.pages.length - 1] ? styles.disabled : styles.enabled} id="forward">
                        Вперед
                      </span>
                      <div className={styles.forward_arrow}></div>
                    </div>

                    <div
                      className={styles.first_last_page}
                      title="В конец"
                      onClick={(e) => {
                          activePage != this.state.pages[this.state.pages.length - 1] ? this.setActivePage(e, this.state.pages[this.state.pages.length - 1]) : '';
                      }}
                    >
                      <img src={forward_arrow} />
                      <img src={forward_arrow} />
                    </div>
                  </div>
                </div>
              )}

              {!schools && <div className={styles.loading} /> }

              {schools && schools.length == 0 && (
                <div className={styles.schools}>
                  <SearchBar presetFilters={this.parseURLQuery()} onApplyFilters={this.applyFilters} />
                  <h1>Вузов, удовлетворяющих вашему запросу не найднено.</h1>
                </div>
              )}

              <div className={styles.left_small}>
                <Link to="umms_path" className={classNames(buttons.main, styles.left_small_item)}>
                  Учебно-методические
                  <br />
                  материалы
                </Link>
                <Link to="abiturs_test_path" className={classNames(buttons.main, styles.left_small_item)}>
                  Профтестирование
                </Link>
                <button className={classNames(buttons.main, styles.hide_image_button, styles.left_small_item)} onClick={() => this.hideImages()}>
                  {!this.state.hide_images && <p>Отключить изображения</p>}
                  {this.state.hide_images && <p>Показать изображения</p>}
                </button>
              </div>

              <div className={styles.left}>
                <div className={!this.state.hide_images ? classNames(styles.close_images, styles.close_images_background) : styles.close_images}>
                  <button className={classNames(buttons.main, styles.hide_image_button)} onClick={() => this.hideImages()}>
                    {!this.state.hide_images && <p>Отключить изображения</p>}
                    {this.state.hide_images && <p>Показать изображения</p>}
                  </button>
                </div>

                <div className={classNames(styles.left_card, styles.a_in_block)}>
                  <div className={styles.card_title}>Учебно-методические&nbsp;материалы</div>
                  <div className={styles.card_text}>Некоторые курсы могут помочь&nbsp;в адаптации на первых порах</div>
                  <Link to="umms_path" className={classNames(buttons.main)}>
                    Перейти к материалам
                  </Link>
                  <div className={!this.state.hide_images ? styles.card_img : styles.card_img_hidden}>{!this.state.hide_images && <img className={styles.first_img} src={umm_img}></img>}</div>
                </div>

                <div className={classNames(styles.left_card, styles.a_in_block)}>
                  <div className={styles.abbr}>«Якоря карьеры»</div>
                  <div className={styles.card_text}>Определите свои сильные&nbsp;стороны!</div>
                  <Link to="abiturs_test_path" className={classNames(buttons.main)}>
                    Перейти к тесту
                  </Link>
                  <div className={!this.state.hide_images ? styles.card_img : styles.card_img_hidden}>{!this.state.hide_images && <img className={styles.second_img} src={test_1}></img>}</div>
                </div>

                <div className={classNames(styles.left_card, styles.a_in_block)}>
                  <div className={styles.abbr}>Тест Дж. Л. Голланда&nbsp;(Дж. Л. Холланда)</div>
                  <div className={styles.card_text}>Раскройте профессиональные&nbsp;ориентиры по типу личности </div>
                  <Link to="abiturs_test_path" className={classNames(buttons.main)}>
                    Перейти к тесту
                  </Link>
                  <div className={!this.state.hide_images ? styles.card_img : styles.card_img_hidden}>{!this.state.hide_images && <img className={styles.second_img} src={test_2}></img>}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

Index.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object
};

export default Index;
