import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import I18n from '../../I18n'
import Link from '../../Routes'

import styles from './Rumc.module.css'

Rumc.propTypes = {
  rumc: PropTypes.object,
  monitoring: PropTypes.object
}

export default function Rumc ({ rumc, monitoring }) {
  return (
    <div>
      <h2>
        Статистика заполнения мониторинга ваших вузов и вузов вашей территории
      </h2>

      {rumc.rumc_schools.length > 0 &&
        <div>
          <h3>
            Вузы с которыми заключены соглашения
          </h3>

          <div className={styles.schools}>
            {rumc.rumc_schools.map(school =>
              <div key={school.id} className={styles.school}>
                <div className={styles.title}>
                  <div className={classNames(styles.state, styles[school.report ? school.report.state : 'empty'])}>
                    {I18n.t(`report.state.${school.report ? school.report.state : 'empty'}`)}
                  </div>

                  {school.report &&
                    <Link to="report_path" params={{ id: school.report.id }}>
                      № {school.report.id} &nbsp;
                    </Link>
                  }

                  <Link to="school_path" params={{ id: school.id }}>
                    {school.label}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      }

      {rumc.schools.length > 0 &&
        <div>
          <h3>
            Вузы с вашей закреплённой территории
          </h3>

          <div className={styles.schools}>
            {rumc.schools.filter(s => s.ministry.department === rumc.ministry.department).map(school =>
              <div key={school.id} className={styles.school}>
                <div className={styles.title}>
                  <div className={classNames(styles.state, styles[school.report ? school.report.state : 'empty'])}>
                    {I18n.t(`report.state.${school.report ? school.report.state : 'empty'}`)}
                  </div>

                  {school.report &&
                    <Link to="report_path" params={{ id: school.report.id }}>
                      № {school.report.id} &nbsp;
                    </Link>
                  }

                  <Link to="school_path" params={{ id: school.id }}>
                    {school.label}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      }
    </div>
  )
}
