import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Index'

class Calls extends React.Component {
  render () {
    return (
      <React.Fragment>
        <Switch>
          <Route exact strict path={Routes.calls_path} component={Index} />
        </Switch>
      </React.Fragment>
    )
  }
}

export default Calls
