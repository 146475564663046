import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import classNames from 'classnames'
import querystring from 'querystring'
import axios from 'axios'

import I18n from '../I18n'
import Link, { path } from '../Routes'
import Loaf from '../Loaf/Loaf'

import styles from './Login.module.css'
import page from '../Page.module.css'
import form from '../Form.module.css'
import buttons from '../Buttons.module.css'

export default function Login () {
  const history = useHistory()
  const location = useLocation()
  const cancelToken = useRef(axios.CancelToken.source())
  const redirectTo = querystring.parse(location.search.slice(1)).redirect_to

  const [values, setValues] = useState({ email: '', password: '' })
  const [send, setSend] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setError(false)
      setSend(true)
    }

    await axios.post(
      path('login_path'),
      { user: values, redirect_to: redirectTo },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(_error => {
      setError(true)
      setSend(false)
    })
  }

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value })
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="root_path">{I18n.t('nav.root')}</Link>
        </Loaf>
        <div className={styles.root}>
          <div className={page.title}>
            <h1>
              {I18n.t('auth.login')}
            </h1>

            <Helmet>
              <title>{I18n.t('auth.login')}</title>
            </Helmet>
          </div>

          <div className={styles.social}>
            <div className={styles.desc}>
              Войти через соцсети
            </div>

            <div className={styles.networks}>
              <a className={styles.vkontakte} href={`${path('auth_at_provider_path', { provider: 'vk' })}${redirectTo ? `?redirect_to=${redirectTo}` : ''}`}>
                {I18n.t('auth.vkontakte')}
              </a>

              <a className={styles.givc} title="Единая система идентификации и аутентификации ГИВЦ" href={`${path('auth_at_provider_path', { provider: 'heroku' })}${redirectTo ? `?redirect_to=${redirectTo}` : ''}`}>
                {I18n.t('auth.givc')}
              </a>

              {/* <a className={styles.facebook} href={`${path('auth_at_provider_path', { provider: 'facebook' })}${redirectTo ? `?redirect_to=${redirectTo}` : ''}`}>
                {I18n.t('auth.facebook')}
              </a> */}
            </div>
            
          </div>

          <div className={styles.form}>
            <form className={form.root} onSubmit={handleSubmit}>
              <div className={form.item}>
                <div className={form.label}>
                  Электронная почта
                </div>

                <div className={form.input}>
                  <input type="text" name="email" value={values.email} onChange={handleInputChange} />
                </div>
              </div>

              <div className={form.item}>
                <div className={form.label}>
                  Пароль
                </div>

                <div className={form.input}>
                  <input type="password" name="password" value={values.password} onChange={handleInputChange} />
                </div>

                <div className={form.hint}>
                  Забыли? <Link to="recovery_path">Восстановить пароль</Link>
                </div>
              </div>

              <div className={form.submit}>
                <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                  Войти
                </button>
              </div>

              {error &&
                <div className={styles.error}>
                  Неправильные почта и пароль. Повторите попытку или нажмите на ссылку «Восстановить пароль», чтобы сбросить его.
                </div>
              }
            </form>
          </div>

          <div className={styles.join}>
            Нет аккаунта? <Link to="join_path">Зарегистрироваться</Link>
          </div>

          {/* <div className={styles.networks}>
            <a className={styles.givc} href={`${path('auth_at_provider_path', { provider: 'heroku' })}${redirectTo ? `?redirect_to=${redirectTo}` : ''}`}>
              {I18n.t('auth.givc')}
            </a>
          </div> */}

        </div>
      </div>
    </div>
  )
}
