import React from 'react'
import Cookies from 'universal-cookie'
import qs from 'query-string'
import classNames from 'classnames'
import update from 'immutability-helper'

import fonts from '../Fonts.module.css'

const cookies = new Cookies()

class Filter extends React.Component {
  state = {
    opened: false,
    items: this.props.items
  }

  toggleList = () => {
    this.setState({ opened: !this.state.opened })
  }

  handleClick = (event) => {
    this.props.toggleItem(this.props.id, event.target.value, event.target.checked);
  }

  render () {
    const { id, label, items } = this.props
    const { opened } = this.state
    const active = items.filter(item => item.active).length

    return (
      <div className={'rct_filters_filter opened_' + this.state.opened + ' rct_filters_filter_' + this.props.filter}>
        <div className="rct_filters_filter_label" onClick={this.toggleList}>
          {label}
          {!!active &&
            <div className="rct_filters_filter_label_counter as_mt">
              {active}
            </div>
          }
        </div>
        <ul className="rct_filters_filter_list">
          {items.map(item =>
            <li key={`${id}-${item.id}`} className={classNames('rct_filters_filter_list_item', { 'selected': item.active })}>
              <label>
                <input
                  checked={item.active}
                  type="checkbox"
                  name={id}
                  value={item.id}
                  onChange={this.handleClick}
                />
                {item.label}
              </label>
            </li>
          )}
        </ul>
      </div>
    )
  }
}

class Filters extends React.Component {
  state = {
    // filters: this.props.filters
  }

  toggleItem = (id, item, value) => {
    let _ = this.state.filters[id].items.findIndex(_ => _.id == item)
    const filters = update(this.state.filters, {
      [id]: {
        items: {
          [_]: { active: { $set: value } }
        }
      }
    })

    let query = {}

    Object.entries(filters).map(ob => {
      let values = ob[1].items.filter(it => it.active).map(it => it.id)
      if(values.length> 0) query[ob[0]] = values
    })
    let search = qs.stringify(query)
    if (search) search = `?${search}`
    if (this.props.location.search !== search) {
      this.props.history.push(this.props.location.pathname + search)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return (this.state !== nextState)
  }

  static getDerivedStateFromProps (props, state) {
    if (props.filters !== state.f) {
      let filters = props.filters

      const query = qs.parse(props.location.search)
      Object.entries(query).map(ob => {
        if (!props.filters[ob[0]]) return false
        let values = ob[1]
        if (!Array.isArray(values)) values = [values]
        values.map(val => {
          let _ = props.filters[ob[0]].items.findIndex(_ => _.id == val)
          filters = update(filters, {
            [ob[0]]: {
              items: {
                [_]: { active: { $set: true } }
              }
            }
          })
        })
      })

      cookies.set('filters', query, { path: encodeURI(props.location.pathname) })

      return {
        f: props.f,
        filters: filters
      }
    }

    return null
  }

  render () {
    const { filters } = this.state

    return (
      <div className={classNames(fonts.as_at)}>
        {Object.entries(filters).map(ob =>
          <Filter key={ob[0]} id={ob[0]} items={ob[1].items} label={ob[1].label} filter={ob[0]} toggleItem={this.toggleItem} />
        )}
      </div>
    )
  }
}

export default Filters
