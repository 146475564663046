import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';

import styles from '../Section.module.css';
import form from '../../Form.module.css';

class Docs extends React.Component {
  state = {
    show: true,
    values: {
      ws: this.props.values.ws || '',
      pol: this.props.values.pol || '',
      pas: this.props.values.pas || '',
      ev: this.props.values.ev || '',
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      [name]: {
        $set: value
      }
    });

    this.setState({ values });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.onValuesChange) this.props.onValuesChange(this.props.prefix, this.state.values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state != nextState);
  }

  render () {
    const { values, show } = this.state;
    const { num } = this.props;

    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Обеспеченность деятельности вузов нормативно-правовой документацией

          <div className={classNames(styles.label, { [styles.ok]: Object.values(values).filter(v => v).length == Object.values(values).length })}>
            {Object.values(values).filter(v => v).length} из {Object.values(values).length}
          </div>
        </div>

        <div className={styles.content}>
          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="ws" checked={values.ws} onChange={this.handleInputChange} />
                {num}.1. Наличие на сайте вуза специального раздела для лиц с ОВЗ и инвалидностью
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="pol" checked={values.pol} onChange={this.handleInputChange} />
                {num}.2. Наличие отдельных локальных актов или отдельных положений из этих локальных актов, регламентирующих работу с обучающимися с ОВЗ и инвалидностью, на официальном сайте вуза
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="pas" checked={values.pas} onChange={this.handleInputChange} />
                {num}.3. Утверждены паспорта доступности зданий
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="ev" checked={values.ev} onChange={this.handleInputChange} />
                {num}.4. Утвержден план мероприятий (дорожная карта) по развитию инклюзивного образования в вузе
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Docs.defaultProps = {
  prefix: 'docs',
  num: '3'
};

export default Docs;
