import React from 'react';
import PropTypes from 'prop-types';

import styles from './Actions.module.css';
import page from '../Page.module.css';

import buttons from '../Buttons.module.css';

class Actions extends React.Component {
  render() {

    return (
      <React.Fragment>
        <div className={styles.root}>
          <div className={styles.status} dangerouslySetInnerHTML={{__html: this.props.actions.status}} />
          {this.props.actions.items &&
            <div className={styles.items}>
              {this.props.actions.items.map((item, index) =>
                <div key={index} className={buttons.main} onClick={() => this.props.onStateChange(item.action)}>{item.title}</div>
              )}
            </div>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default Actions;
