import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import update from 'immutability-helper';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

import Files from '../../Attachments/Files'
import Loaf from '../../Loaf/Loaf';

import { path } from '../../Routes';

import page from '../../Page.module.css';
import form from '../../Form.module.css';
import buttons from '../../Buttons.module.css';

// import Icon from '!svg-react-loader!../../images/icons/download.svg';

class Form extends React.Component {
  state = {
    story: null,
    attachments: [],
    values: {
      title: '',
      name: '',
      school_id: '',
      qualification: '',
      job: '',
      soc_link: '',
      attachment_ids: [],
      attachments_attributes: [],
    },
    school: null,
    submit: {
      url: path('account_stories_path', {}, true),
      method: 'post',
    },
    send: false,
  }

  componentDidMount() {
    if(this.props.id) {
      this._loadAsyncData(this.props.id);
    }
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.attachments !== prevState.attachments) {
      this.setState(prevState => ({
        values: { ...prevState.values,
          attachments_attributes: this.state.attachments.map(a => ({id: a.id, title: a.title, section: a.section}) ),
          attachment_ids: this.state.attachments.map(a => a.id),
        }
      }));
    }
  }

  handleSubmit = (e) => {
    if(this.canSubmit()) {
      this.setState({
        send: true
      });

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        data: Object.assign({
          story: this.state.values
        }),
      }).then(
        res => {
          this.props.history.push(res.headers.location)
        }
      );
    }

    e.preventDefault();
  }

  canSubmit = () => {
    return this.state.values.name && this.state.values.title && this.state.values.qualification && this.state.values.job && !this.state.send;
  }

  _loadSchools = inputValue => {
    return axios.get(`${this.props.routes.schools_path}/list.json`, { params: { q: inputValue } }).then(res => {
      return res.data.schools.slice(0, 100);
    });
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }));
  }

  handleAttachmentsUpdate = (attachments) => {
    this.setState({
      attachments: attachments,
    })
  }

  handleFilesChanged = files => {
    this.setState(prevState => ({
      values: { ...prevState.values,
        attachments_attributes: [...files.values()].map(file => ({ id: file.id, title: file.title, section: file.section })),
        attachment_ids: [...files.values()].map(file => file.id)
      }
    }))
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();
    axios.get(path('account_story_path', {id: id}, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState(prevState => ({
          story: res.data.story,
          values: {
            title: res.data.story.title,
            name: res.data.story.name,
            qualification: res.data.story.qualification,
            job: res.data.story.job,
            soc_link: res.data.story.soc_link,
            school_id: res.data.story.school_id,
          },
          attachments: res.data.story.attachments.map(a => a),
          school: res.data.story.school,
          sections: res.data.story.sections,
          submit: {
            method: 'patch',
            url: path('account_story_path', {id: id}, true)
          }
        }));

        this._asyncRequest = null;
      });
  }

  render() {
    const { story } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>Кабинет</Link>
            <Link to={path('account_stories_path')}>Истории успеха</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {story ? 'Редактирование истории' : 'Новая итория успеха' }
            </h1>
          </div>

          <div className={form.tight}>
            <form className="form" onSubmit={this.handleSubmit}>
              <div className="input">
                <div className="input_label">
                  <label>
                    <abbr title="обязательно">*</abbr> Название ролика
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="title" onChange={this.handleInputChange} value={this.state.values.title}/>
                </div>
              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    <abbr title="обязательно">*</abbr> Фамилия, имя, отчество героя ролика
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="name" onChange={this.handleInputChange} value={this.state.values.name}/>
                </div>
              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    <abbr title="обязательно">*</abbr> Квалификация по диплому
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="qualification" onChange={this.handleInputChange} value={this.state.values.qualification}/>
                </div>
              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    <abbr title="обязательно">*</abbr> Место работы, должность
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="job" onChange={this.handleInputChange} value={this.state.values.job}/>
                </div>
              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    Ссылка на профили героя ролика в социальных сетях
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="soc_link" onChange={this.handleInputChange} value={this.state.values.soc_link}/>
                </div>
              </div>

              <Files
                files={this.state.attachments}
                onFilesChanged={this.handleFilesChanged}
                sections={['video', 'subs']}
              />

              <div className="input_submit">
                <input type="submit" value="Сохранить материал" className={buttons.main} disabled={!this.canSubmit()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Form
