import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import qs from 'query-string';

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import Link, { path } from '../../Routes';
import List from '../List';

import page from '../../Page.module.css';
import buttons from '../../Buttons.module.css'

class Index extends Component {
  state = {
    target_programs: [],
    search: this.props.location.search,
    filters: null
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('account_target_programs_path', {}, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search) })
      .then(res => {
        const { target_programs, can_new, filters } = res.data;

        this.setState({ target_programs, can_new, filters });
        this._asyncRequest = null;
      });
  }

  static getDerivedStateFromProps(props, state) {
    if(props.location.search !== state.search) {
      return {
        target_programs: null,
        search: props.location.search,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.target_programs === null) this._loadAsyncData();
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  render() {
    const { target_programs, filters, can_new } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">{I18n.t('account.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('target_programs.title')}
            </h1>

            <Helmet>
              <title>{I18n.t('target_programs.title')}</title>
            </Helmet>

            {can_new &&
              <div className={page.links}>
                <Link className={buttons.main} to="new_account_target_program_path" params={{query: { section: qs.parse(this.props.location.search).section }}}>Новая программа</Link>
              </div>

            }
          </div>

          <div>
            <List items={target_programs} filters={filters} {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
