import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';
import AsyncSelect from 'react-select/async';

import VerificateInput from './VerificateInput';

import styles from '../Section.module.css';
import form from '../../Form.module.css';

class Environment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      values: [
        'bld',
        'bb_e_y',
        'bb_e_p',
        'bb_e_n',
        'bb_h_y',
        'bb_h_p',
        'bb_h_n',
        'bb_m_y',
        'bb_m_p',
        'bb_m_n',
        'br',
        'pib',
        'bfh_b',
        'bfh_d',
        'bfh_p',
        'bfh_o',
        'pns',
        'wfh',
        'lib_e',
        'lib_h',
        'lib_m',
      ].reduce((acc, e) => {
        acc[e] = this.props.values[e] || '';
        return acc;
      }, {}),
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      [name]: {
        $set: value
      }
    });

    this.setState({ values });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.onValuesChange) this.props.onValuesChange(this.props.prefix, this.state.values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state != nextState);
  }

  render () {
    const { values, show } = this.state;
    const { num } = this.props;
    const bbl_options = [{id: 0, title: 'не создана'}, {id: 1, title: 'частично'}, {id: 2, title: 'полностью'}]

    const errors = {
      'err_e': ~~parseInt(values.bld) < ~~parseInt(values.bb_e_y) + ~~parseInt(values.bb_e_p) + ~~parseInt(values.bb_e_n),
      'err_h': ~~parseInt(values.bld) < ~~parseInt(values.bb_h_y) + ~~parseInt(values.bb_h_p) + ~~parseInt(values.bb_h_n),
      'err_m': ~~parseInt(values.bld) < ~~parseInt(values.bb_m_y) + ~~parseInt(values.bb_m_p) + ~~parseInt(values.bb_m_n),
    };

    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Создание безбарьерной среды для лиц с ОВЗ и инвалидностью

          <div className={classNames(styles.label, { [styles.ok]: Object.values(values).filter(v => v).length == Object.values(values).length })}>
            {Object.values(values).filter(v => v).length} из {Object.values(values).length}
          </div>
        </div>

        <div className={styles.content}>
          <div className={form.elem}>
            <div className={form.label}>
              {num}.1. Общее число учебных корпусов в вузе
            </div>

            <div className={form.input}>
              <VerificateInput name="bld" value={values.bld} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.desc}>
            {num}.2. Из них (п.{num}.1) число учебных корпусов в вузе, в которых создана безбарьерная среда:
            {errors['err_e'] &&
              <div className={form.error}>Ошибка в данных об обучающихся с нарушениями зрения</div>
            }
            {errors['err_h'] &&
              <div className={form.error}>Ошибка в данных об обучающихся с нарушениями слуха</div>
            }
            {errors['err_m'] &&
              <div className={form.error}>Ошибка в данных об обучающихся с нарушениями опорно-двигательного аппарата</div>
            }
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2.1. Полностью создана для обучающихся с нарушениями зрения
            </div>

            <div className={form.input}>
              <VerificateInput name="bb_e_y" value={values.bb_e_y} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2.2. Частично создана для обучающихся с нарушениями зрения
            </div>

            <div className={form.input}>
              <VerificateInput name="bb_e_p" value={values.bb_e_p} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2.3. Не создана для обучающихся с нарушениями зрения
            </div>

            <div className={form.input}>
              <VerificateInput name="bb_e_n" value={values.bb_e_n} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2.4. Полностью создана для обучающихся с нарушениями слуха
            </div>

            <div className={form.input}>
              <VerificateInput name="bb_h_y" value={values.bb_h_y} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2.5. Частично создана для обучающихся с нарушениями слуха
            </div>

            <div className={form.input}>
              <VerificateInput name="bb_h_p" value={values.bb_h_p} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2.6. Не создана для обучающихся с нарушениями слуха
            </div>

            <div className={form.input}>
              <VerificateInput name="bb_h_n" value={values.bb_h_n} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2.7. Полностью создана для обучающихся с нарушениями опорно-двигательного аппарата
            </div>

            <div className={form.input}>
              <VerificateInput name="bb_m_y" value={values.bb_m_y} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2.8. Частично создана для обучающихся с нарушениями опорно-двигательного аппарата
            </div>

            <div className={form.input}>
              <VerificateInput name="bb_m_p" value={values.bb_m_p} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2.9. Не создана для обучающихся с нарушениями опорно-двигательного аппарата
            </div>

            <div className={form.input}>
              <VerificateInput name="bb_m_n" value={values.bb_m_n} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3. Число специализированных санитарно-гигиенических помещений (туалетная кабина для маломобильных студентов, откидные опорные поручни, обустройство штанг и т.п.)
            </div>

            <div className={form.input}>
              <VerificateInput name="br" value={values.br} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4. Количество специально оборудованных мест в общежитии для проживания студентов с нарушениями опорно-двигательного аппарата
            </div>

            <div className={form.input}>
              <VerificateInput name="pib" value={values.pib} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.desc}>
            {num}.5. Обеспечение условий проживания в общежитии обучающихся с нарушениями зрения:
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="bfh_b" checked={values.bfh_b} onChange={this.handleInputChange} />
                {num}.5.1. Подписи помещений на азбуке Брайля
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="bfh_d" checked={values.bfh_d} onChange={this.handleInputChange} />
                {num}.5.2. Направляющие дорожки
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="bfh_p" checked={values.bfh_p} onChange={this.handleInputChange} />
                {num}.5.3. Поручни
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="bfh_o" checked={values.bfh_o} onChange={this.handleInputChange} />
                {num}.5.4. Другое
              </label>
            </div>
          </div>

          <hr className={styles.hr} />

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="pns" checked={values.pns} onChange={this.handleInputChange} />
                {num}.6. Обеспечены условия проживания в общежитии для обучающихся с нарушениями слуха
              </label>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.checkbox}>
              <label>
                <input type="checkbox" name="wfh" checked={values.wfh} onChange={this.handleInputChange} />
                {num}.7. Функционирование на сайте вуза раздела с версией для слабовидящих
              </label>
            </div>
          </div>


          <div className={styles.desc}>
            {num}.8. Доступность библиотечного фонда для обучающихся:
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              С нарушениями зрения
            </div>

            <div className={form.input}>
              <select name="lib_e" onChange={this.handleInputChange} value={this.state.values.lib_e}>
                <option />
                {bbl_options.map(o =>
                  <option key={o['id']} value={o['id']}>{o['title']}</option>
                )}
              </select>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              С нарушениями слуха
            </div>

            <div className={form.input}>
              <select name="lib_h" onChange={this.handleInputChange} value={this.state.values.lib_h}>
                <option />
                {bbl_options.map(o =>
                  <option key={o['id']} value={o['id']}>{o['title']}</option>
                )}
              </select>
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              С нарушениями опорно-двигательного аппарата
            </div>

            <div className={form.input}>
              <select name="lib_m" onChange={this.handleInputChange} value={this.state.values.lib_m}>
                <option />
                {bbl_options.map(o =>
                  <option key={o['id']} value={o['id']}>{o['title']}</option>
                )}
              </select>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

Environment.defaultProps = {
  prefix: 'environment',
  num: '5'
};

export default Environment;
