import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Routes } from '../Routes.js.erb';
import Loaf from '../Loaf/Loaf';

import page from '../Page.module.css';

class TermsRumcForms extends React.Component {
  render() {
    const title = 'Анкеты мониторинга';

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={Routes.terms_path}>Правила</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {title}
            </h1>

            <Helmet>
              <title>
                {title}
              </title>
            </Helmet>
          </div>

          <div className={page.text}>
            <p>
              Все анкеты мониторинга имеют общие правила. Анкеты могут заполнять как сотрудники ресурсных центров, так и любые респонденты. У сотрудников ресурсных центров в личных кабинетах есть кнопки создания новых анкет. Чтобы передать ссылку респондентам, нужно зайти на саму анкету и перед анкетой будет отображена ссылка в которой находится идентификатор вашего ресурсного центра. Поэтому любая анкета, заполненная по этому ссылке будет привязана к вашему центру. В списке анкет видны все анкеты: и ваши и ваших респондентов.
            </p>

            <p>
              Выгрузка данных мониторинга будет сделана в ближайшее время. Сотрудники ресурсных центров смогут смотреть результаты заполнения своих респондентов в виде таблицы, а также скачать в формате xlsx.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsRumcForms;
