import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../../Routes'

import New from './New'
import Form from './Form'
import Index from './Index'
import Show from './Show'
import Review from './Review'

class RumcSchools extends Component {
  render () {
    return (
      <Switch>
        <Route
          path={decodeURIComponent(Routes.new_account_rumc_school_path)}
          component={New}
        />

        <Route
          path={decodeURIComponent(Routes.edit_account_rumc_school_path)}
          render={props => <Form id={props.match.params.id} {...props} />}
        />

        <Route
          path={decodeURIComponent(Routes.review_account_rumc_school_path)}
          render={props => <Review id={props.match.params.id} {...props} />}
        />

        <Route
          path={decodeURIComponent(Routes.account_rumc_school_path)}
          render={props => <Show id={props.match.params.id} {...props} />}
        />

        <Route
          exact
          path={decodeURIComponent(Routes.account_rumc_schools_path)}
          component={Index}
        />
      </Switch>
    )
  }
}

export default RumcSchools
