import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import { path } from '../../Routes';

import page from '../../Page.module.css';
import form from '../../Form.module.css';
import buttons from '../../Buttons.module.css';

class Review extends Component {
  state = {
    target_document: null,
    values: {
      review: '',
      quality: '',
      form_requirements: '',
      structure_requirements: '',
      roadmap_sign: '',
      phone_number: '',
      info: '',
    }
  }


  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('review_account_target_document_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          target_document: res.data.target_document,
          values: res.data.values,
        });

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleSubmit = (e) => {
    axios({
      method: 'patch',
      url: path('account_target_document_path', { id: this.props.id }, true),
      data: {
        target_document: this.state.values
      },
    }).then(
      res => {
        this.props.history.push(path('account_target_document_path', { id: this.props.id }))
      }
    );

    e.preventDefault();
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }));
  }

  render() {
    const { target_document, values } = this.state;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={path('account_target_documents_path')}>{I18n.t('target_documents.title')}</Link>
          </Loaf>

          {target_document &&
            <>
              <div className={page.title}>
                <h1>
                  {target_document.title}
                </h1>

                <Helmet>
                  <title>{target_document.title}</title>
                </Helmet>
              </div>

              <div className={page.form}>
                <form className={form.root} onSubmit={this.handleSubmit}>

                  {target_document.label != 'roadmap' && target_document.label != 'roadmap_collective_usage' &&
                    <>
                      <div className={form.elem}>
                        <div className={form.label}>
                          Оформление
                        </div>

                        <div className={form.input}>
                          <select name="form_requirements" onChange={this.handleInputChange} value={values.form_requirements}>
                            <option />
                            <option value={0}>Соответствует требованиям</option>
                            <option value={1}>Не соответствует требованиям</option>
                          </select>
                        </div>
                      </div>

                      <div className={form.elem}>
                        <div className={form.label}>
                          Структура
                        </div>

                        <div className={form.input}>
                          <select name="structure_requirements" onChange={this.handleInputChange} value={values.structure_requirements}>
                            <option />
                            <option value={0}>Соответствует требованиям</option>
                            <option value={1}>Не соответствует требованиям</option>
                          </select>
                        </div>
                      </div>

                      <div className={form.elem}>
                        <div className={form.label}>
                          Качество отчета
                        </div>

                        <div className={form.input}>
                          <select name="quality" onChange={this.handleInputChange} value={values.quality}>
                            <option />
                            <option value={0}>Низкое</option>
                            <option value={1}>Среднее</option>
                            <option value={2}>Высокое</option>
                          </select>
                        </div>
                      </div>
                    </>
                  }

                  {target_document.label == 'roadmap' || target_document.label == 'roadmap_collective_usage' &&
                    <>
                      <div className={form.elem}>
                        <div className={form.label}>
                          Дорожная карта подписана
                        </div>

                        <div className={form.input}>
                          <select name="roadmap_sign" onChange={this.handleInputChange} value={values.roadmap_sign}>
                            <option />
                              <option value={0}>Руководитель РУМЦ</option>
                              <option value={1}>Ректор/проректор</option>
                          </select>
                        </div>
                      </div>

                      {target_document.label == 'roadmap' &&
                        <div className={form.elem}>
                          <div className={form.label}>
                            Наличие номера 8-800
                          </div>

                          <div className={form.input}>
                            <select name="phone_number" onChange={this.handleInputChange} value={values.phone_number}>
                              <option />
                              <option value={0}>Нет</option>
                              <option value={1}>Да</option>
                            </select>
                          </div>
                        </div>
                      }

                      {target_document.label == 'roadmap_collective_usage' &&
                        <div className={form.elem}>
                          <div className={form.label}>
                            Информация о ЦКП внесена на портал 
                          </div>

                          <div className={form.input}>
                            <select name="info" onChange={this.handleInputChange} value={values.info}>
                              <option />
                              <option value={0}>Нет</option>
                              <option value={1}>Да</option>
                            </select>
                          </div>
                        </div>
                      }
                    </>
                  }

                  <div className="input">
                    <div className="input_label">
                      <label>
                        Коментарии к оценкам
                      </label>
                    </div>

                    <div className="input_input">
                      <textarea name="review" value={values.review} rows="4" onChange={this.handleInputChange} />
                    </div>
                  </div>

                  <div className={form.submit}>
                    <input type="submit" value="Сохранить" className={buttons.main} />
                  </div>
                </form>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}

export default Review;
