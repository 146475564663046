import React from 'react'
import { Helmet } from 'react-helmet-async'

import I18n from '../I18n'

import page from '../Page.module.css'

export default function Copyright () {
  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <div className={page.title}>
          <h1>{I18n.t('pages.copyright')}</h1>

          <Helmet>
            <title>{I18n.t('pages.copyright')}</title>
          </Helmet>
        </div>

        <div className={page.text}>
          <p>
            Все материалы портала инклюзивного образования могут быть воспроизведены в любых средствах массовой информации, на серверах сети Интернет или на любых иных носителях без каких–либо ограничений по объёму и срокам публикации. Это разрешение в равной степени распространяется на газеты, журналы, радиостанции, телеканалы, сайты и страницы сети Интернет.
          </p>

          <p>
            Единственным условием перепечатки и ретрансляции является ссылка на первоисточник.
          </p>

          <p>
            Все материалы сайта доступны по лицензии <a href="https://creativecommons.org/licenses/by/4.0/deed.ru" target="_blank" rel="noopener noreferrer">Creative Commons Attribution 4.0 International</a>.
          </p>

          <p>
            Используя этот сайт, вы соглашаетесь с <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer">условиями использования YouTube</a>.
          </p>
        </div>
      </div>
    </div>
  )
}
