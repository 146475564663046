import React, { Component } from 'react'

import Item from './Item'

import I18n from '../../I18n'

import section from './Section.module.css'

class Technical extends Component {
  render () {
    const { data } = this.props

    return (
      <div className={section.root}>
        <div className={section.title}>
          <h2>
            Обеспеченность процесса обучения лиц с ОВЗ и инвалидностью техническими средствами
          </h2>
        </div>

        <div className={section.list}>
          <Item data={data.ob_e} label={'stats.technical.ob_e'} />
          <Item data={data.ob_e_vl} label={'stats.technical.ob_e_vl'} />
          <Item data={data.ob_e_blt} label={'stats.technical.ob_e_blt'} />
          <Item data={data.ob_e_bli} label={'stats.technical.ob_e_bli'} />
          <Item data={data.ob_e_o} label={'stats.technical.ob_e_o'} />
          <Item data={data.ob_h} label={'stats.technical.ob_h'} />
          <Item data={data.ob_h_iis} label={'stats.technical.ob_h_iis'} />
          <Item data={data.ob_h_o} label={'stats.technical.ob_h_o'} />
          <Item data={data.ob_m} label={'stats.technical.ob_m'} />
          <Item data={data.ob_m_ct} label={'stats.technical.ob_m_ct'} />
          <Item data={data.ob_m_ss} label={'stats.technical.ob_m_ss'} />
          <Item data={data.ob_m_av} label={'stats.technical.ob_m_av'} />
          <Item data={data.ob_m_o} label={'stats.technical.ob_m_o'} />
          <Item data={data.mad_e} label={'stats.technical.mad_e'} />
          <Item data={data.mad_e_vl} label={'stats.technical.mad_e_vl'} />
          <Item data={data.mad_e_sz} label={'stats.technical.mad_e_sz'} />
          <Item data={data.mad_e_tc} label={'stats.technical.mad_e_tc'} />
          <Item data={data.mad_e_bld} label={'stats.technical.mad_e_bld'} />
          <Item data={data.mad_e_pea} label={'stats.technical.mad_e_pea'} />
          <Item data={data.mad_e_daisy} label={'stats.technical.mad_e_daisy'} />
          <Item data={data.mad_e_o} label={'stats.technical.mad_e_o'} />
          <Item data={data.mad_h} label={'stats.technical.mad_h'} />
          <Item data={data.mad_h_ha} label={'stats.technical.mad_h_ha'} />
          <Item data={data.mad_h_o} label={'stats.technical.mad_h_o'} />
          <Item data={data.mad_mn} label={'stats.technical.mad_mn'} />
          <Item data={data.mad_mm} label={'stats.technical.mad_mm'} />
        </div>
      </div>
    )
  }
}

export default Technical
