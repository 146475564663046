import React, { useState, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import classNames from 'classnames'

import Current from '../Current'
import Link, { path } from '../Routes'
import I18n from '../I18n'
import { Errors, useForm } from '../Form'
import Loaf from '../Loaf/Loaf'

import page from '../Page.module.css'
import form from '../Form.module.css'
import buttons from '../Buttons.module.css'

export default function Password () {
  const { user } = useContext(Current)

  const {
    values,
    handleInputChange,
    cancelToken,
    errors,
    setErrors,
    send,
    setSend
  } = useForm({
    password: '',
    password_confirmation: ''
  })

  const [saved, setSaved] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
      setSaved(false)
    }

    await axios.patch(
      path('user_path', { id: user.id }),
      { user: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      setSaved(true)
    }).catch(error => {
      setErrors(error.response.data)
    })

    setSend(false)
  }

  return (
    <div className={page.root}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('account.password')}
          </h1>
          <Helmet>
            <title>
              {I18n.t('account.password')}
            </title>
          </Helmet>
        </div>

        <div className={form.tight}>
          {saved &&
            <div className={form.success}>
              Пароль успешно изменён
            </div>
          }

          {values &&
            <form onSubmit={handleSubmit}>
              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Новый пароль
                  </div>

                  <div className={form.input}>
                    <input type="password" value={values.password} name="password" onChange={handleInputChange} />
                  </div>
                </label>

                <Errors errors={errors.password} />
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Подтверждение пароля
                  </div>

                  <div className={form.input}>
                    <input type="password" value={values.password_confirmation} name="password_confirmation" onChange={handleInputChange} />
                  </div>
                </label>

                <Errors errors={errors.password_confirmation} />
              </div>

              <div className={form.submit}>
                <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                  Изменить пароль
                </button>
              </div>
            </form>
          }
        </div>
      </div>
    </div>
  )
}
