import React, { Component } from 'react'
import Link from '../../Routes'

import styles from './Cards.module.css'
import annotation_styles from '../../Students/Annotations.module.css'
import index_styles from '../Index.module.css'
import buttons from '../../Buttons.module.css'
import classNames from 'classnames'

// import { SwitchTransition, CSSTransition } from "react-transition-group";

class CardsSlider extends Component {
    state = {
        count_1: 4,
        active_1: 0,
        count_2: 2,
        active_2: 0
    }
    render () {
        let { count_1, active_1, count_2, active_2 } = this.state

        return (
            <div className={styles.cards_container}>
                <div className={styles.cards_container}>
                    <div className={styles.cards_title_wrapper}>
                        <div className={styles.cards_title}>Всероссийские мероприятия</div>
                        {/* <div className={styles.cards_arrows}>
                            <div className={classNames(styles.arrow, styles.arrow_back, active_1 == 0 ? styles.hide_arrow : '')} onClick={() => (this.setState({active_1: active_1 ? active_1 - 1 : count_1 - 1}))} />
                            <div className={styles.arrow} onClick={() => (this.setState({active_1 : active_1 != count_1 - 1 ? active_1 + 1 : 0}))} />
                        </div> */}
                    </div>
                    <div className={styles.card_container}>
                        {active_1 == 0 && <VebinarMonitoring className={styles.card_item} />}
                        {active_1 == 1 && <CardPracticeCompetition2024 className={styles.card_item} />}
                        {active_1 == 2 && <CardMethodologicalCenter className={styles.card_item} />}
                        {active_1 == 3 && <CardForumEmploymentGraduates className={styles.card_item} />
                        }
                    </div>

                    <div className={annotation_styles.pagination}>
                        {[...Array(count_1)].map((p, index) => (
                            // <div 
                            //     className={index == active_1 ? annotation_styles.active_page_new : annotation_styles.page_new} 
                            //     onClick={() => (this.setState({ active_1: index }))}
                            //     key={index}
                            // />
                            <div 
                                className={index == active_1 ? annotation_styles.active_page_new: annotation_styles.page_new} 
                                onClick={() => (this.setState({ active_1: index }))}
                                key={index}
                            >
                                {index + 1}
                            </div>
                        ))}
                    </div>
                </div>


                <div className={styles.cards_container}>
                    {count_2 > 1 && 
                        <div className={styles.cards_title_wrapper}>
                            <div className={styles.cards_title}></div>
                            {/* <div className={styles.cards_arrows}>
                                <div className={classNames(styles.arrow, styles.arrow_back, active_2 == 0 ? styles.hide_arrow : '')} onClick={() => (this.setState({active_2: active_2 ? active_2 - 1 : count_2 - 1}))} />
                                <div className={styles.arrow} onClick={() => (this.setState({active_2 : active_2 != count_2 - 1 ? active_2 + 1 : 0}))} />
                            </div> */}
                        </div>
                    }
                    <div className={styles.card_container}>
                        {active_2 == 0 &&
                            <div className={styles.card_pair_wrapper}>
                                <CardProfzavtra />
                                {/* <UniversalSmallCard 
                                    url='https://new.pnu.edu.ru/ru/forms/forum-rumc/'
                                    name='Всероссийский форум инклюзивного высшего образования'
                                    desc='Инклюзия раздвигает границы возможностей и пространства'
                                    place='Тихоокеанский государственный университет'
                                    date='22-25 мая, 2024'
                                /> */}
                                <CardSmena className={styles.card_item} />
                            </div>
                        }
                        {active_2 == 1 &&
                            <div className={styles.card_pair_wrapper}>
                                {/* <UniversalSmallCard 
                                    class_='styles.card_bg_1'
                                    url='https://edu.mgupp.ru/forum' 
                                    name='Всероссийский форум «Трудовая занятость выпускников вуза с инвалидностью: обеспечение социальной мобильности и интеграции»' 
                                    date='8 декабря 2022 г. Москва'
                                /> */}
                                <CardAbilimpics />
                            </div>
                        }
                    </div>
                    {count_2 > 1 && 
                        <div className={annotation_styles.pagination}>
                            {[...Array(count_2)].map((p, index) => (
                                // <div 
                                //     className={index == active_2 ? annotation_styles.active_page_new : annotation_styles.page_new} 
                                //     onClick={() => (this.setState({ active_2: index }))}
                                //     key={index}
                                // />
                                <div 
                                    className={index == active_2 ? annotation_styles.active_page_new: annotation_styles.page_new} 
                                    onClick={() => (this.setState({ active_2: index }))}
                                    key={index}
                                >
                                    {index + 1}
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default CardsSlider


class CardPracticeCompetition extends Component {
    state = {
        show: false
    }
    render () {
        let show = this.state.show;
        return (
            <div className={classNames(styles.big_card, styles.card_practice_competition)}> 
                {!show &&
                    <>
                        <div className={styles.card_title}>Всероссийский конкурс практик инклюзивного высшего и среднего профессионального образования – 2022</div>
                        <div className={styles.detail} onClick={() => (this.setState({show: true}))}>Подробнее о конкурсе</div>
                        <h4>Зарегистрируйтесь, заполнив электонную заявку:</h4>
                        <div className={styles.butons_wrapper}>
                            <a className={classNames(styles.button_higher, styles.button)} href="https://webanketa.com/forms/6cv32dhj70qkcc9r6nh68chn/" target="_blank">
                                <span>Высшее образование</span>
                            </a>
                            <a className={classNames(styles.button_secondary, styles.button)} href="https://forms.yandex.ru/cloud/631ae04810037be14958a30b/" target="_blank">
                                <span>Среднее профессиональное образование</span>
                            </a>
                        </div>
                        <div className={styles.card_date}><a href="https://aiu-2way.ru" target="_blank" onClick={(e) => {e.stopPropagation()}}>Подробно на сайте Ассоциации инклюзивных вузов</a></div>
                    </>
                }
                {show &&
                    <>
                        <div className={styles.card_practice_competition_header}>
                            <div className={styles.card_practice_competition_close} onClick={() => (this.setState({show: false}))} />
                        </div>
                        <p>Конкурс ориентирован на повышение качества и доступности инклюзивного высшего и среднего профессионального образования, на выявление, поддержку и дальнейшее распространение лучших практик, поощрение лидеров образовательных практик в области инклюзивного образования, заслуживающих доверие профессионального сообщества и благополучателей России, развитие инклюзивного образования, формирование инклюзивной культуры как обязательной составляющей развития инклюзивного общества.</p>
                        <p><b>В 2022 году конкурс пройдет по 13 номинациям в 2 этапа:</b></p>
                        <ul>
                            <li>
                                <div><b>I этап (отборочный)</b></div>
                                <div>с 16 сентября по 20 октября 2022 года</div>
                            </li>
                            <li>
                                <div><b>II этап (финальный)</b></div>
                                <div>не позднее 20 ноября 2022 года</div>
                            </li>
                        </ul>
                        <div><b>Для участия в конкурсе необходимо пройти регистрацию, заполнив электронную заявку.</b></div>
                        <div>Обратите внимание, что для конкурсных работ в сфере инклюзивного высшего образования и работ в сфере </div>
                        <div>инклюзивного среднего профессионального образования заполняются разные формы электронных заявок.</div>
                    </>
                }
            </div>
        )
    }
}

// class CardPracticeCompetitionResults extends Component {
//     state = {
//         show: false
//     }
//     render () {
//         let show = this.state.show;
//         return (
//             <div className={classNames(styles.big_card, styles.card_practice_competition)}> 
//                 <div className={styles.card_title}>Всероссийский конкурс практик инклюзивного высшего и среднего профессионального образования – 2023</div>
//                 <h4>Итоги конкурса</h4>
//                 <div className={styles.butons_wrapper}>
//                     <a className={classNames(styles.button_higher, styles.button)} href="https://инклюзивноеобразование.рф/uploads/document/press_reliz/practice_competition_2023/winners_VO.pdf" target="_blank">
//                         <span>Победители в сфере высшего<br />образования</span>
//                     </a>
//                     <a className={classNames(styles.button_secondary, styles.button)} href="https://инклюзивноеобразование.рф/uploads/document/press_reliz/practice_competition_2023/winners_SPO.pdf" target="_blank">
//                         <span>Победители в сфере среднего профессионального образования</span>
//                     </a>
//                 </div>
//                 <a href='https://инклюзивноеобразование.рф/uploads/document/press_reliz/practice_competition_2023/winners.pdf' target='_blank'>Просмотр практик победителей конкурса</a>
//             </div>
//         )
//     }
// }
class CardPracticeCompetition2024 extends Component {
    render () {
        return (
            <a href="https://fmc-spo.ru/activity/konkurs/" target="_blank"> 
                <div className={classNames(styles.big_card, styles.card_practice_competition)}> 
                    <div className={styles.card_title}>Всероссийский конкурс практик инклюзивного высшего и среднего профессионального образования – 2024</div>
                    <h4>Итоги конкурса</h4>
                    <div className={styles.butons_wrapper}>
                        <a className={classNames(styles.button_higher, styles.button)} href="/uploads/document/index_cards/Polozhenie_o_konkurse_Praktik_IVO_2024_VO.pdf" target="_blank">
                            <span>Положение конкурса</span>
                        </a>
                        <a className={classNames(styles.button_secondary, styles.button)} href="/uploads/document/index_cards/Informacionnoe_pismo_IVO.pdf" target="_blank">
                            <span>Информационное письмо</span>
                        </a>
                    </div>

                    <div className={styles.card_date}>
                        <p>Организатор: Московский государственный психолого-педагогический университет</p>
                        <p>Даты проведения: май - ноябрь, 2024</p>
                    </div>
                </div>
            </a>
        )
    }
}

class CardMethodologicalCenter extends Component {
    render () {
        return (
            <a href="https://fikit.ncfu.ru/" target="_blank"> 
                <div className={classNames(styles.big_card, styles.card_methodological_center)}>
                    <div className={styles.card_title}>I Всероссийский фестиваль инклюзивной культуры и творчества</div>
                    <p>Информация о Фестивале, этапах проведения и форма для подачи заявки на участие</p>
                    <div className={styles.card_date}>Северо-Кавказский федеральный университет, апрель - декабрь, 2024</div>
                </div>
            </a>

        )
    }
}

class CardSmena extends Component {
    render () {
        return (
            <div className={classNames(styles.small_card, styles.card_bg_2)}>
                <div className={styles.card_title}>Третья общероссийская студенческая смена по инклюзивному волонтерству и инклюзивному туризму</div>

                <div className={index_styles.smena_urls}>
                    <a href="https://rtmc.utmn.ru/smena-2024/" target="_blank">Официальный сайт смены</a>

                    <a href="https://rtmc.utmn.ru/upload/medialibrary/dde/f7v6mtfcri7syrses04rhds8q1dx0ovq/Polozhenie-o-Smene_2024.pdf" target="_blank">Положение Смены</a>
                    
                    <a href="https://forms.yandex.ru/cloud/660472d12530c21dcb269a72/" target="_blank">Регистрация на курс</a>

                    {/* <a className={classNames(index_styles.promo_wrapper, index_styles.promo_wrapper_small)} href="https://vk.com/video/@volonterchsu?z=video-150900348_456239330%2Fclub150900348%2Fpl_-150900348_-2" target="_blank">
                        <div className={classNames(buttons.main, index_styles.promo)}>Посмотреть промо-ролик</div>
                    </a> */}
                </div>

                <div className={styles.card_date}>апрель-сентябрь 2024</div>
            </div>
        )
    }
}

class CardForumBig extends Component {
    render () {
        return (
            <div className={classNames(styles.big_card, styles.card_practice_competition)}>
                <div className={styles.card_title}>Всероссийский форум инклюзивного высшего образования</div>
                <p>Инклюзивная культура: от методологии к «коробочным решениям»</p>
                <a href="https://forum.vyatsu.ru/" target="_blank"><b>Официальный сайт форума</b></a>
                <div className={styles.hr} />
                <div className={styles.card_date}>
                    <div>Вятский государственный университет</div>
                    <div>24-25 ноября, 2022</div>
                </div>
            </div>
        )
    }
}


class CardProfzavtra extends Component {
    render () {
        return (
            <Link to="tomorrow_path" className={classNames(styles.small_card, styles.card_profzavtra)}>
                <div className={styles.card_title}>Профессиональное завтра</div>
                <p>Всероссийский сетевой конкурс студенческих проектов с участием студентов с инвалидностью</p>
                <a href="http://profzavtra.nstu.ru/" target="_blank" onClick={(e) => {e.stopPropagation()}}>Официальный сайт конкурса</a>
                <div className={styles.hr} />
                <div className={styles.card_date}>Конкурс апрель-октябрь, 2024</div>
            </Link>
        )
    }
}

class CardAbilimpics extends Component {
    render () {
        return (
            <a href="https://abilympics-russia.ru/" target="_blank" className={classNames(styles.small_card, styles.card_abilimpics)}>
                <div className={styles.card_title}>Абилимпикс</div>
                <p>Национальный чемпионат по профессиональному мастерству среди инвалидов и лиц с ограниченными возможностями здоровья</p>
                {/* <a href="https://abilympics-russia.ru/c/region-championships" target="_blank" onClick={(e) => {e.stopPropagation()}}>График Региональных Чемпионатов</a> */}
                <div className={styles.hr} />
                <div className={styles.card_date}>2024</div>
            </a>
        )
    }
}


class UniversalSmallCard extends Component {
    render () {
        return (
            <a href={this.props.url} target="_blank" className={classNames(styles.small_card, this.props.class_ ? styles.card_bg_2 : styles.card_bg_1)}>
                <div className={styles.card_title}>{this.props.name}</div>
                {this.props.desc && <p>{this.props.desc}</p>}
                <div className={styles.hr} />
                <div className={styles.card_date}>
                    {this.props.place && <div>{this.props.place}</div>}
                    <div>{this.props.date}</div>
                </div>
            </a>
        )
    }
}

class VebinarMonitoring extends Component {
    render () {
        return (
            <div className={classNames(styles.big_card, styles.card_practice_competition, styles.card_smena)}>
                <div className={styles.card_title}>Вебинар по проведению мониторинга инклюзивного образования</div>

                <div className={index_styles.smena_urls}>
                    <a className={index_styles.promo_wrapper} href="https://youtu.be/S75FiDQRsK8" target="_blank">
                        <div className={classNames(buttons.main, index_styles.promo)}>Смотреть запись вебинара</div>
                    </a>
                </div>

                <a href='/вузам/мониторинг'>Перейти на страницу информации</a>
            </div>
        )
    }
}

class CardForumEmploymentGraduates extends Component {
    render () {
        return (
            <a href="https://www.xn--80aih0adc.team/" target="_blank"> 
                <div className={classNames(styles.big_card, styles.card_practice_competition)}>
                    <div className={styles.card_title}>Второй инклюзивный фестиваль спорта и адаптивной физической культуры учащихся высшего образования</div>
                    <div className={styles.hr} />
                    <div className={styles.card_date}>Российский государственный университет социальных технологий, 13-15 ноября 2024</div>
                </div>
            </a>

        )
    }
}
