import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import I18n from '../I18n'
import { Routes, path } from '../Routes';
import Files from '../Attachments/Files'

import page from '../Page.module.css';
import form from '../Form.module.css';
import buttons from '../Buttons.module.css';

class Form extends React.Component {
  state = {
    event_report: null,
    attachments: [],
    values: {
      title: '',
      body: '',
      attachment_ids: [],
      attachments_attributes: []
    },
    submit: {
      url: path('event_event_report_path', { event_id: this.props.event_id }, true),
      method: 'post'
    },
    send: false,
    redirect_to: null
  }

  componentDidMount () {
    if (this.props.id) this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData (id) {
    this._asyncRequest = axios.CancelToken.source()
    axios.get(path('edit_event_event_report_path', { event_id: this.props.event_id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          event_report: res.data.event_report,
          values: {
            title: res.data.event_report.title,
            body: res.data.event_report.body
          },
          attachments: res.data.attachments,
          submit: {
            method: 'patch',
            url: path('event_event_report_path', { event_id: this.props.event_id }, true)
          }
        })

        this._asyncRequest = null
      })
  }

  handleSubmit = (e) => {
    if (this._canSubmit()) {
      this.setState({
        send: true
      })

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        data: { event_report: this.state.values }
      }).then(
        res => {
          this.setState({
            redirect_to: res.headers.location
          })
        }
      )
    }

    e.preventDefault()
  }

  handleFilesChanged = files => {
    this.setState(prevState => ({
      values: { ...prevState.values,
        attachments_attributes: [...files.values()].map(file => ({ id: file.id, title: file.title, section: file.section })),
        attachment_ids: [...files.values()].map(file => file.id)
      }
    }))
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }))
  }

  _canSubmit = () => {
    return (
      this.state.values.title &&
      this.state.values.body &&
      !this.state.send
    )
  }

  render () {
    const { event_report, attachments } = this.state;
    // const { I18n } = this.props;

    if (this.state.redirect_to) return <Redirect to={this.state.redirect_to} />

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          {/*
          <Loaf>
            <Link to={this.props.routes.account_path}>Кабинет</Link>
            <Link to={`${this.props.routes.account_list_path}/online_courses`}>Онлайн курсы</Link>
          </Loaf>
          */}
          <div className={page.title}>
            <h1>
              {event_report ? 'Редактирование отчёта' : 'Новый отчёт' }
            </h1>
          </div>

          <div className={form.tight}>
            <form className="form" onSubmit={this.handleSubmit}>
              <div className="input">
                <div className="input_label">
                  <label>
                    {I18n.t('simple_form.labels.defaults.title')}
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="title" value={this.state.values.title} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    {I18n.t('simple_form.labels.defaults.body')}
                  </label>
                </div>

                <div className="input_input">
                  <textarea name="body" value={this.state.values.body} rows="9" onChange={this.handleInputChange} />
                </div>
              </div>

              <Files
                files={this.state.attachments}
                onFilesChanged={this.handleFilesChanged}
                sections={[]}
              />

              <div className="input_submit">
                <input type="submit" value="Сохранить отчёт" className={buttons.main} disabled={!this._canSubmit()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
export default Form
