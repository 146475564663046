import React, { Component } from 'react';

import Item from './Item';

import section from './Section.module.css';

class Consulting extends Component {
  render() {
    const { data, I18n } = this.props;

    return (
      <div className={section.root}>
        <div className={section.title}>
          <h2>
            Call-центр
          </h2>
        </div>

        <div className={section.list}>
          <Item data={data.calls} label={'stats.rumc.calls'} />
          <Item data={data.roadmaps} label={'stats.rumc.roadmaps'} />
        </div>
      </div>
    );
  }
}

export default Consulting;
