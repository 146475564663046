import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withI18n } from '../Context/I18nContext';

import styles from './AttachmentSection.module.css';

class AttachmentSection extends React.Component {

  render() {
    let checked = this.props.sections.checked;

    return (
      <div className={styles.sections}>
        { this.props.sections.items &&
          this.props.sections.items.filter(i => (!checked.includes(i) || this.props.item.section == i)).map((section, index) =>
            <div className={classNames([styles.section], {[styles.active]: this.props.item.section ==  section})} key={index} onClick={() => {this.props.onSectionChange(section)}}>{this.props.I18n.t(`attachments.types.${section}`)}</div>
          )
        }
      </div>
    )
  }
}

export default withI18n(AttachmentSection);
