import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

class Appppp extends Component {
  state = {

  }

  static getDerivedStateFromProps (props, state) {
    if (I18n.locale !== props.locale) {
      I18n.locale = props.locale
    }

    axios.defaults.headers.common['Authorization'] = `Bearer ${props.jwt_token}`

    return null
  }

  render () {
    return (
      <HelmetProvider>
        <Helmet
          defaultTitle={this.props.title}
          titleTemplate={'%s — ' + this.props.title}
        />

        <I18nContext.Provider value={I18n}>
          <RoutesContext.Provider value={Routes}>
            <AuthTokenContext.Provider value={this.props.authenticity_token}>
              <ListsContext.Provider value={this.props.lists}>
                <UserContext.Provider value={this.props.user}>
                  <Router>
                    <ScrollToTop>
                      <React.Fragment>
                        <Header />

                        <Route exact strict path={[Routes.root_path, decodeURIComponent(Routes.search_path)]} component={SearchForm} />

                        <Switch>
                          <Route path={decodeURIComponent(Routes.login_path)} component={Auth} />
                          <Route path={decodeURIComponent(Routes.join_path)} component={Auth} />
                          <Route path={decodeURIComponent(Routes.recovery_path)} component={Auth} />

                          <Route path={decodeURIComponent(Routes.requests_path)} component={Requests} />
                          <Route path={decodeURIComponent(Routes.events_path)} component={Events} />
                          <Route path={decodeURIComponent(Routes.tomorrow_path)} component={Tomorrow} />

                          <Route path={Routes.calls_path} component={Calls} />
                          <Route path={Routes.admin_users_roles_path} component={UsersRoles} />
                          <Route path={Routes.users_path} component={Users} />
                          <Route path={Routes.reports_path} component={Reports} />
                          <Route path={Routes.stories_path} component={Stories} />
                          <Route path={Routes.inner_documents_path} component={InnerDocuments} />
                          <Route path={Routes.target_documents_path} component={TargetDocuments} />
                          <Route path={Routes.target_programs_path} component={TargetPrograms} />
                          <Route path={Routes.trainings_path} component={Trainings} />
                          <Route path={Routes.corrections_path} component={Corrections} />
                          <Route path={Routes.facilities_path} component={Facilities} />
                          <Route path={decodeURIComponent(Routes.forms_path)} component={Forms} />

                          <Route path={decodeURIComponent(Routes.abiturs_path)} component={Abiturs} />
                          <Route path={decodeURIComponent(Routes.students_path)} component={Students} />
                          <Route path={decodeURIComponent(Routes.universities_path)} component={Universities} />
                          <Route path={decodeURIComponent(Routes.rumcs_path)} component={Rumcs} />
                          <Route path={decodeURIComponent(Routes.schools_path)} component={Schools} />

                          <Route path={decodeURIComponent(Routes.stage_course_path)} component={Stages} />
                          <Route path={decodeURIComponent(Routes.stage_umm_path)} component={Stages} />
                          <Route path={decodeURIComponent(Routes.courses_path)} component={Courses} />
                          <Route path={decodeURIComponent(Routes.umms_path)} component={Umms} />
                          <Route path={decodeURIComponent(Routes.careers_path)} component={Careers} />

                          <Route exact strict path={decodeURIComponent(Routes.contacts_path)} component={Contacts} />
                          <Route exact strict path={decodeURIComponent(Routes.copyright_path)} component={Copyright} />
                          <Route path={decodeURIComponent(Routes.account_path)} component={Account} />
                          <Route exact strict path={decodeURIComponent(Routes.support_path)} component={Support} />
                          <Route path={decodeURIComponent(Routes.about_path)} component={About} />
                          <Route path={decodeURIComponent(getPath('stats_path'))} render={props => <Stats {...props} id={props.match.params.id} />} />
                          <Route path={decodeURIComponent(Routes.terms_path)} component={Terms} />

                          <Route path={Routes.targets_path} component={Targets} />

                          <Route path={decodeURIComponent(Routes.search_path)} component={Search} />

                          <Route path={decodeURIComponent(Routes.statistics_path)} component={Statistics} />
                          <Route exact strict path={Routes.root_path} component={Index} />
                        </Switch>
                        <Footer />

                        {this.props.env === 'production' &&
                          <YMInitializer
                            accounts={[38958365]}
                            version="2"
                            options={{
                              clickmap: true,
                              trackLinks: true,
                              accurateTrackBounce: true,
                              webvisor: true,
                              trackHash: true
                            }}
                          />
                        }
                      </React.Fragment>
                    </ScrollToTop>
                  </Router>
                </UserContext.Provider>
              </ListsContext.Provider>
            </AuthTokenContext.Provider>
          </RoutesContext.Provider>
        </I18nContext.Provider>
      </HelmetProvider>
    )
  }
}
