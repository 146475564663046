import React from 'react'
// import PropTypes from 'prop-types'
import classNames from 'classnames'
import update from 'immutability-helper'

import I18n from '../I18n'

import styles from './Vision.module.css'

class Vision extends React.Component {
  state = {
    font: 2,
    kerning: 1,
    schema: 1
  }

  componentDidMount () {
    let state = update(this.state, {
      font: { $set: null },
      kerning: { $set: null },
      schema: { $set: null }
    })

    this._setStyles(state, this.state)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return (this.state !== nextState)
  }

  componentDidUpdate (prevProps, prevState) {
    this._setStyles(prevState, this.state)
  }

  _setStyles = (prevState, nextState) => {
    if (prevState.font !== nextState.font) {
      document.documentElement.classList.remove(styles[`font_${prevState.font}`])
      document.documentElement.classList.add(styles[`font_${nextState.font}`])
    }

    if (prevState.kerning !== nextState.kerning) {
      document.documentElement.classList.remove(styles[`kerning_${prevState.kerning}`])
      document.documentElement.classList.add(styles[`kerning_${nextState.kerning}`])
    }

    if (prevState.schema !== nextState.schema) {
      document.documentElement.classList.remove(styles[`schema_${prevState.schema}`])
      document.documentElement.classList.add(styles[`schema_${nextState.schema}`])
    }
  }

  render () {
    const { font, kerning, schema } = this.state
    // const { I18n } = this.props;

    return (
      <React.Fragment>
        <div className={styles.root}>
          <div className={styles.container}>
            <div className={styles.group}>
              <div className={styles.label}>
                {I18n.t('vision.font')}
              </div>
              <div className={styles.items}>
                <div className={classNames(styles.item, styles.fontSmall, { [styles.active]: font === 1 })} onClick={() => this.setState({ font: 1 })} />
                <div className={classNames(styles.item, styles.fontRegular, { [styles.active]: font === 2 })} onClick={() => this.setState({ font: 2 })} />
                <div className={classNames(styles.item, styles.fontBig, { [styles.active]: font === 3 })} onClick={() => this.setState({ font: 3 })} />
              </div>
            </div>

            <div className={styles.group}>
              <div className={styles.label}>
                {I18n.t('vision.kerning')}
              </div>
              <div className={styles.items}>
                <div className={classNames(styles.item, styles.kerningRegular, { [styles.active]: kerning === 1 })} onClick={() => this.setState({ kerning: 1 })} />
                <div className={classNames(styles.item, styles.kerningBig, { [styles.active]: kerning === 2 })} onClick={() => this.setState({ kerning: 2 })} />
                <div className={classNames(styles.item, styles.kerningBigger, { [styles.active]: kerning === 3 })} onClick={() => this.setState({ kerning: 3 })} />
              </div>
            </div>

            <div className={styles.group}>
              <div className={styles.label}>
                {I18n.t('vision.schema')}
              </div>
              <div className={styles.items}>
                <div className={classNames(styles.item, styles.schemaRegular, { [styles.active]: schema === 1 })} onClick={() => this.setState({ schema: 1 })} />
                <div className={classNames(styles.item, styles.schemaBlack, { [styles.active]: schema === 2 })} onClick={() => this.setState({ schema: 2 })} />
                <div className={classNames(styles.item, styles.schemaBlue, { [styles.active]: schema === 3 })} onClick={() => this.setState({ schema: 3 })} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Vision
