import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Routes } from '../Routes.js.erb';
import Loaf from '../Loaf/Loaf';

import page from '../Page.module.css';

class TermsRumcFacilities extends React.Component {
  render() {
    const title = 'Работа с реестром центра коллективного доступа';

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={Routes.terms_path}>Правила</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {title}
            </h1>

            <Helmet>
              <title>
                {title}
              </title>
            </Helmet>
          </div>

          <div className={page.text}>
            <p>
              Заполнение ЦКД (центра коллективного доступа) возможно в блоке № 2 в личном кабинете. Внутри блока можно перейти на список всех ваших технических средств и добавить новое. При добавлении нового обязательно нужно заполнить нозологию, категорию, название, технические характеристики и количество.
            </p>

            <p>
              После занесения, оборудование появится в списке ваших средств и вы сможете фиксировать передачу его в пользование. Для этого нужно около название соответствующего средства нажать «Дать в пользование». В открывшейся форме необходимо выбрать вуз, количество, дата начала. В списке вузов можно выбрать только те вузы, с кем у вашего ресурсного центра заключены соглашения. Дата окончания необязательна — если она не заполнена, значит оборудование находится в пользовании. После того как вуз вернул оборудование — нужно указать дату окончания, тогда это уйдет в архив. Чтобы занести факт пользования оборудования, которое уже вернули, то нужно сразу заполнить дату окончания.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsRumcFacilities;
