import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import I18n from '../../../I18n'
import Link from '../../../Routes'

import styles from './Block.module.css'

class Block extends Component {
  render () {
    const { num, id, items, rumcs } = this.props

    return (
      <div className={styles.root}>
        <div className={styles.data}>
          <div className={styles.title}>
            {num}.{I18n.t(`account.rumc.target.${id}.title`)}
          </div>
        </div>

        {items && items.length > 0 &&
          <div className={styles.sections}>
            {items.map(item =>
              <Wrapper key={item.id} url={item.url}>
                <Item item={item} rumcs={rumcs} section={id} />
              </Wrapper>
            )}
          </div>
        }
      </div>
    )
  }
}

Block.propTypes = {
  num: PropTypes.number,
  id: PropTypes.string,
  items: PropTypes.array,
  rumcs: PropTypes.array
}

export default Block

function Wrapper (props) {
  return (
    <>
      {props.url &&
        <Link {...props.url} className={styles.section}>
          {props.children}
        </Link>
      }

      {!props.url &&
        <div className={styles.section}>
          {props.children}
        </div>
      }
    </>
  )
}

Wrapper.propTypes = {
  url: PropTypes.object,
  children: PropTypes.node
}

class Item extends Component {
  isSuccess () {
    return this.props.item.goal && this.props.item.current >= (this.props.item.goal_calc ? this.props.item.goal : this.props.item.goal * this.props.rumcs.length)
  }

  render () {
    const { item, rumcs, section } = this.props

    return (
      <>
      {(!!item.goal || item.current !== undefined) &&
        <div className={classNames(styles.goal, { [styles.success]: this.isSuccess() })}>
          <div>{item.current}</div>
          {!!item.goal &&
            <>
              <div className={styles.from}>из</div>
              <div>{item.goal_calc ? item.goal : item.goal * rumcs.length}</div>
            </>
          }
        </div>
      }

      {item.state === 'inactive' &&
        <div className={styles.inactive}>
          Утверждается
        </div>
      }

      <div>
        {I18n.t(`account.rumc.target.${section}.${item.label}.title`)}
      </div>

      {item.desc &&
        <div className={styles.sdesc}>
          {item.desc}
        </div>
      }
      </>
    )
  }
}

Item.propTypes = {
  item: PropTypes.object,
  rumcs: PropTypes.array,
  section: PropTypes.string
}
