import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import Link, { path } from '../../Routes'
import I18n from '../../I18n'
import Loaf from '../../Loaf/Loaf'

import styles from './Index.module.css'
import page from '../../Page.module.css'

export default function Facilities () {
  const { rumc_id: rumcId } = useParams()
  const cancelToken = useRef(axios.CancelToken.source())
  const [rumc, setRumc] = useState()
  const [facilities, setFacilities] = useState()
  const [sections, setSections] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('rumc_facilities_path', { rumc_id: rumcId }, true), { cancelToken: cancelToken.current.token })

      setRumc(data.rumc)
      setFacilities(data.facilities)
      setSections(data.sections)
    }

    _fetch()

    return () => {
      cancelToken.current.cancel()
    }
  }, [rumcId])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="rumcs_path">{I18n.t('nav.rumcs')}</Link>
          {rumc &&
            <Link to="rumc_path" params={{ id: rumc.id }}>{rumc.title}</Link>
          }
        </Loaf>

        {rumc &&
          <div className={styles.root}>
            <div className={page.title}>
              <h1>
                Оборудование
              </h1>

              <Helmet>
                <title>Оборудование — {rumc.title}</title>
              </Helmet>

              <p className={styles.h1p}>
                {rumc.title}
              </p>
            </div>

            {sections &&
              <div className={styles.sections}>
                {sections.map(section =>
                  <div key={section} className={styles.section}>
                    <h2>
                      {I18n.t(`facility.section.${section}`)}
                    </h2>

                    {facilities &&
                      <div className={styles.facilities}>
                        {facilities.filter(f => f.section === section).map(facility =>
                          <Link to="rumc_facility_path" params={{ rumc_id: rumc.id, id: facility.id }} key={facility.id} className={classNames(styles.facility, styles.a_in_block)}>
                            <div className={styles.container}>
                              <div className={styles.title}>
                                <div>
                                  {facility.title}
                                </div>

                                <div className={classNames(styles.nosology, styles[facility.nosology.slug])} />
                              </div>

                              <div className={styles.desc}>
                                {facility.desc}
                              </div>

                              <div className={styles.meta}>
                                <div className={styles.quantity}>
                                  {I18n.t('facility.quantity.count', { count: facility.quantity })}
                                </div>

                                {facility.youtube_id &&
                                  <div className={styles.video}>
                                    есть видеоинструкция
                                  </div>
                                }
                              </div>
                            </div>

                            <div className={styles.more}>
                              Подробнее
                            </div>
                          </Link>
                        )}
                      </div>
                    }
                  </div>
                )}
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}
