import React from 'react'
import { Helmet } from 'react-helmet-async'

import I18n from '../../I18n'

import styles from './Common.module.css'

export default function Contacts () {
  return (
    <div className={styles.root}>
      <Helmet>
        <title>{I18n.t('events.tomorrow.contacts')}</title>
      </Helmet>
      <h2>
        {I18n.t('events.tomorrow.contacts')}
      </h2>
      <p>Оператор VII Всероссийский сетевой конкурс студенческих проектов «Профессиональное завтра» в 2024 году —Новосибирский государственный технический университет</p>
      <p>Вся информация о конкурсе и условия участия на сайте → <a href="https://profzavtra.nstu.ru" target="_blank">profzavtra.nstu.ru</a>.</p>
      <p>Координатор конкурса: Алина Андреевна Бабина, тел.: +7 (953) 803-92-46, e-mail: profzavtra2024@yandex.ru.</p>

      <hr />

      <p>
        В 2023 году: <a href="https://rumc.ncfu.ru/kontakty/" target="_blank" rel="noopener noreferrer">https://rumc.ncfu.ru/kontakty/</a>
      </p>

      <p>
        В 2022 году: <a href="https://www.utmn.ru/professional-tomorrow/" target="_blank" rel="noopener noreferrer">https://www.utmn.ru/professional-tomorrow/</a>
      </p>
      <p>
        В 2021 году: <a href="https://rumc.cfuv.ru/node/169" target="_blank" rel="noopener noreferrer">https://rumc.cfuv.ru/node/169</a>
        {/* <strong>Васина Людмила Григорьевна</strong>
        <br />
        Специалист РУМЦ МГППУ
        <br />
        тел. раб.:8 (800) 707-49-29, тел. моб.: +7 (916) 177-18-04, VasinaLG @ mgppu . ru */}
      </p>
    </div>
  )
}
