import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import Datec from '../../Datec';
import { path } from '../../Routes';
import Rate from '../../Rate/Rate';

import page from '../../Page.module.css';
import buttons from '../../Buttons.module.css';
import styles from './Show.module.css';
import stylesStates from '../States.module.css';

class Show extends Component {
  state = {
    inquiry: null,
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('account_inquiry_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { inquiry } = res.data;

        this.setState({ inquiry });
        this._asyncRequest = null;
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.inquiry === null) this._loadAsyncData(this.props.id);
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleDestroy = () => {
    this._asyncRequest = axios.CancelToken.source();

    axios({
      method: 'delete',
      url: path('account_inquiry_path', { id: this.state.inquiry.id }, true),
      cancelToken: this._asyncRequest.token })
    .then(
      res => {
        this.props.history.push(res.headers.location)
      }
    );
  }

  handleRateSave = () => {
    this._loadAsyncData(this.props.id);
  }

  render() {
    const { inquiry, rate } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={`${path('account_inquiries_path')}`}>{I18n.t('inquiries.title')}</Link>
          </Loaf>

          {inquiry &&
            <>
              <div className={page.title}>
                <h1>
                  {inquiry.title}
                </h1>

                <Helmet>
                  <title>{inquiry.title}</title>
                </Helmet>
              </div>

              <div className={page.control}>
                <Link to={path('edit_account_inquiry_path', { id: inquiry.id })} className={buttons.small}>Редактировать запрос</Link>
                <div onClick={() => this.handleDestroy()}className={buttons.small}>Удалить запрос</div>
              </div>

              {inquiry.inquiry_rates &&
                inquiry.inquiry_rates.map((inquiry_rate, _) =>
                  <>
                    <h2 className={styles.title}>{inquiry_rate.title}</h2>
                    <Rate rate={inquiry_rate.rate} ratable={{ type: "InquiryRate", id: inquiry_rate.id}} button="Сохранить" onRateSave={this.handleRateSave} />
                  </>
                )
              }
            </>
          }
        </div>
      </div>
    );
  }
}

export default Show;
