import React, { Component } from 'react'
import PropTypes from 'prop-types'

import I18n from '../../I18n'

import styles from './Jobs.module.css'
import classNames from 'classnames'

class Jobs extends Component {
  render () {
    const { jobs } = this.props

    return (
      <div className={styles.jobs}>
        {jobs.sort((a, b) => b.salary - a.salary).map(job =>
          // <a href={`https://trudvsem.ru/vacancy/search?_profession=${job.title}&_special=34c5d8b0-8039-11e4-bc4a-3da98a0a212b`} key={job.id} target="_blank" rel="noopener noreferrer" className={styles.job}>
          <a href={`https://trudvsem.ru/vacancy/search?_title=${job.title.replace(",", "").replace(" ", "%20")}&education=HIGH&isDisabled=true&_regionIds`} key={job.id} target="_blank" rel="noopener noreferrer" className={classNames(styles.job, styles.a_in_block)}>
            <div className={styles.title}>
              {job.title}
            </div>

            <div className={styles.desc}>
              {I18n.t('career.jobs.vacancies.count', { count: job.vacancies })} co средней зарплатой {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(job.salary)} в месяц.
            </div>
          </a>
        )}
      </div>
    )
  }
}

Jobs.propTypes = {
  jobs: PropTypes.array
}

export default Jobs
