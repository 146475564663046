import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {EditorState, convertFromRaw, convertToRaw} from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import classNames from 'classnames';

import Attachments from '../Attachments/Attachments';

// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import forms from '../Form.module.css';
import draft from '../Draft.module.css';
import styles from './StageItem.module.css'
import buttons from '../Buttons.module.css';

class StageItem extends React.Component {
  state = {
    title: this.props.stage.title,
    desc: this.props.stage.desc,
    id: this.props.stage.id,
    attachments: this.props.stage.attachments || [],
    show: this.props.stage.show,
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      if(!this.state && this.props.onStageDelete) {
        this.props.onStageDelete(this.props.index)
      }
      else if (this.props.onStagesUpdate) {
        this.props.onStagesUpdate(this.props.index, this.state)
      }
    }
  }

  handleEditorChange = (editorState) => {
    this.setState(prevState => ({
      desc: editorState,
    }));
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      [name]: value
    }));
  }

  handleStageDelete = () => {
    this.props.onStageDelete(this.props.index);
  }

  handleAttachmentsUpdate = (attachments) => {
    this.setState({ attachments: attachments });
  }

  render() {
    const {title, desc, attachments, show} = this.state
    return (
      <React.Fragment>
        <div className={classNames(styles.stage, { [styles.show]: show })}>
          <div className={classNames(styles.title)} onClick={() => this.setState(state => ({ show: !state.show }))}>
              {title ? title : 'Раздел без навзвания'}
          </div>

          {show &&
            <>
              <div className={forms.elem}>
                <div className={forms.label}>
                  Название
                </div>

                <div className={forms.input}>
                  <input type="text" name="title" value={title} onChange={(e) => this.handleInputChange(e)} />
                </div>
              </div>
              <div className={forms.elem}>
                <div className={forms.label}>
                  Содержание
                </div>

                <div className={forms.input}>
                  <div className={draft.textarea}>
                    <Editor
                      editorState={desc}
                      onEditorStateChange={this.handleEditorChange}
                      toolbar={{
                        options: ['inline', 'list', 'blockType', 'link'],
                        inline: {
                          options: ['bold']
                        },
                        list: {
                          options: ['unordered', 'ordered']
                        },
                        blockType: {
                          inDropdown: false,
                          options: ['Blockquote', 'H3'],
                        }
                      }}
                      stripPastedStyles = {true}
                      localization={{
                        locale: 'ru',
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={forms.input}>
                <div className={forms.label}>
                  <Attachments onAttachmentsUpdate={this.handleAttachmentsUpdate} attachments={attachments} sections={['video', 'subtitles']}/>
                </div>
              </div>

              <div className={buttons.main} onClick={this.handleStageDelete}>Удалить раздел</div>
            </>
          }
        </div>
      </React.Fragment>
    )
  }
}

export default StageItem
