import React from 'react';
import PropTypes from 'prop-types';

import RateList from './RateList';

import styles from './Rate.module.css';

class Rate extends React.Component {

  render () {
    const { categories, results, rated , can_rate } = this.props.rate;

    if ( rated || can_rate || this.props.not_rated ) {
      return (
        <div className={styles.root}>
          <RateList categories={categories} can_rate={can_rate} rated={rated} results={results} not_rated={this.props.not_rated} ratable={this.props.ratable} button={this.props.button} onRateSave={this.props.onRateSave} />
        </div>
      );
    }
    else return null;
  }
}

export default Rate;
