import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Login from './Login'
import Recovery from './Recovery'
import Join from './Join'

class Auth extends Component {
  render () {
    return (
      <Switch>
        <Route path={Routes.login_path} component={Login} />
        <Route path={Routes.recovery_path} component={Recovery} />
        <Route path={Routes.join_path} component={Join} />
      </Switch>
    )
  }
}

export default Auth
