import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

import styles from './Report.module.css'
import showStyles from '../Show.module.css'

class Report extends Component {
  render () {
    const { body, attachments } = this.props

    const images = attachments.filter(attachment => attachment.image)

    return (
      <div className={styles.root}>
        <h2>
          Отчёт о событии
        </h2>

        <div>
          <ReactMarkdown source={body} />
        </div>

        <br />  
        {images &&
          <div className={showStyles.files}>
            {images.map(image =>
              <a href={image.url} target="_blank" rel="noopener noreferrer" key={image.id}>Скриншот</a>
            )}
          </div>
          // <div className={styles.images}>
          //   {images.map(image =>
          //     <a href={image.url} target="_blank" rel="noopener noreferrer" key={image.id} className={styles.image}>
          //       <img src={image.images.thumb} />
          //     </a>
          //   )}
          // </div>
        }
      </div>
    )
  }
}

Report.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  body: PropTypes.string,
  attachments: PropTypes.array
}

export default Report
