import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import classNames from 'classnames'

import I18n from '../I18n'
import Link, { path } from '../Routes'

import Cookies from 'universal-cookie'

import Loaf from '../Loaf/Loaf'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import styles from './Test.module.css'
import GlobalVariables from '../Vars';

const cookies = new Cookies()

class TestQuestion extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.handleNext = this.handleNext.bind(this)
    this.handlePrev = this.handlePrev.bind(this)
    this.handleResult = this.handleResult.bind(this)
  }

  handleNext () {
    this.props.handleQuestionChange(1)
  }

  handlePrev () {
    this.props.handleQuestionChange(-1)
  }

  handleResult () {
    this.props.handleTestOver()
  }

  render () {
    const question = this.props.data[this.props.question];
    const has_next = Object.keys(this.props.data).length > this.props.question;
    const disabled = this.props.answers[question.id] != undefined && this.props.answers[question.id].length >= 2;
    const checked_answers = this.props.answers[question.id] != undefined ? this.props.answers[question.id] : [];


    return (
      <div>
        <div className={styles.alertInformationWrap}>
          <div className={styles.alertInformation}>Раздел находится в состоянии актуализации</div>
        </div>
        <div className={styles.text}>
          <p className="qu">
            Вопрос № {question.id} из {Object.keys(this.props.data).length}
          </p>

          <h2>
            {question.text}
          </h2>
        </div>

        <ul className={styles.answers}>
          {Object.keys(question.answers).map((id) =>
            <TestQuestionAnswer handleAnswer={this.props.handleAnswer} label={question.answers[id]} id={parseInt(id)} question={this.props.id} key={this.props.id + '_' + id} disabled={disabled} checked={checked_answers.includes(parseInt(id))} />
          )}
        </ul>


        <div className={styles.buttons}>
          {question.id > 1 &&
            <button onClick={this.handlePrev} className={buttons.cancel}>
              Назад
            </button>
          }

          {has_next &&
            <button onClick={this.handleNext} className={buttons.main}>
              Далее
            </button>
          }

          {!has_next &&
            <button onClick={this.handleResult} className={buttons.main}>
              Узнать результаты
            </button>
          }
        </div>
      </div>
    )
  };
}

class TestQuestionAnswer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      checked: false
    }

    if (this.props.checked) {
      this.state.checked = true
    }

    // console.log(this.props);
  }

  // defaultChecked={this.state.checked}

  handleCB = (event) => {
    const target = event.target
    this.setState({
      checked: target.checked
    })

    this.props.handleAnswer(this.props.question, this.props.id, target.checked)
  }

  render () {
    return (
      <li className={classNames(styles.answer, { [styles.disabled]: this.props.disabled && !this.state.checked })}>
        <label>
          <input disabled={this.props.disabled && !this.state.checked} checked={this.state.checked} type="checkbox" name={'question_' + this.props.id} value={1} onChange={this.handleCB} />
          {this.props.label}
        </label>
      </li>
    )
  }
}

TestQuestionAnswer.propTypes = {
  id: PropTypes.number,
  handleAnswer: PropTypes.func,
  question: PropTypes.number,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
}

class TestResult extends React.Component {
  render () {
    const { results } = this.props

    return (
      <div className={styles.results}>
        {results.map((result, _) =>
          <div className={styles.result} key={_}>
            <h2>
              {result.text}
            </h2>
            <ul className="test_result_list">
              {result.texts.map((result, __) =>
                <li className="test_result_list_item" key={__} dangerouslySetInnerHTML={{ __html: result.text }} />
              )}
            </ul>
          </div>
        )}
      </div>
    )
  }
}

TestResult.propTypes = {
  results: PropTypes.array
}

// ? Компонент отображения комментария 
class CommentRender extends React.Component {
  render () {
    const { comments } = this.props
    return (
      <div>
        {comments.map((item, _k) => {
          return <div key={_k}>{item}</div>
        })}
      </div>
    )
  }
}

TestResult.propTypes = {
  comments: PropTypes.array
}

function ResultFileDelay(props) {
  const [counter, setCounter] = React.useState(10); 

  useEffect(() => {
    const timer = counter > 0 ? setInterval(() => setCounter(counter - 1), 1000) : props.callback();
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <button className={buttons.main}>Подготовка отчета, можно скачать через {counter}</button>
  )
}

// ? Страница теста 
class Test extends React.Component {
  constructor (props) {
    super(props)

    let q = cookies.get('test_question')
    let an = cookies.get('test_answers')

    if (q === undefined) {
      q = 1
    } else {
      q = parseInt(q)
    }

    if (an === undefined) {
      an = {}
    }

    this.state = {
      tests: null,
      testActive: null,   // Активный индекс теста 
      questions: null,
      answersList : {},   // Список ответов 
      answerErrors: [],
      resultsInfo: null,
      saveResult: false,
      loadingResults: false,
      loadingResultsReport: false,

      data: null,
      question: q,
      answers: an,
      hasNext: true,
      results: false,
      results_fetch: false,
      results_error: false
    }

    this.handleQuestionChange = this.handleQuestionChange.bind(this)
    this.handleTestOver = this.handleTestOver.bind(this)
    this.handleAnswer = this.handleAnswer.bind(this)
  }

  // ? Получить список тестов 
  getTests() {
    axios.get(GlobalVariables.apitestHost + "/tests", { params: {  } })
      .then(res => {
        this.setState({
          tests : res.data.items
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  // ? Список вопросов теста 
  getQuestions(_id, __index){
    axios.post(GlobalVariables.apitestHost + "/questions", { 'test_id' : _id  })
    .then(res => {
      this.setState({
        questions : res.data.items,
        testActive: __index
      })

      // ? Автоматически заполняем тест ответами
      // res.data.items.map((item) => {
      //   if( item.type == 1 )
      //   this.saveAnswer( item.number, (Math.floor(Math.random() * (2 - 1 + 1)) + 1) )
      // })
    })
    .catch(error => {
      console.log(error)
    })
  }

  // Сохранение ответов 
  saveAnswer(id, key){
    let answers = this.state.answersList

    if (answers[id] === undefined) {
      answers[id] = 1
    }

    answers[id] = key

    this.setState({
      answersList : answers
    })
  }
  
  componentDidMount() {
    // this._loadAsyncData()
    this.getTests()       // ? Получаем список тестов 
  }

  // ? Добавить новер вопроса в ошибки 
  addError(error){
    let _error = this.state.answerErrors
    _error.push(error)
    this.setState({
      answerErrors : _error,
    })
  }

  // ? Очистить ошибки 
  clearErrors(){
    this.setState({
      answerErrors : []
    })
  }
  
  // ? Обрабатываем результаты теста 
  renderResults(){
    this.state.answerErrors = []
    let _answers = this.state.answersList

    this.state.questions.map((item) => {
      if ( item.type == 1 && !Object.keys(_answers).includes(item.number+"") ) {
        this.addError(item.number)
      }
    })

    if ( this.state.answerErrors.length > 0 ) {
      return false;
    }

    this.setState({
      loadingResults : true
    })
    
    let prepare = this.state.answersList
    prepare['test_id'] = this.state.tests[this.state.testActive].id    
    axios.post(GlobalVariables.apitestHost + "/result", prepare)
    .then(res => {
      this.setState({
        resultsInfo : res.data.result,
        loadingResults : false,
        saveResult: false
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  getReport(uuid) {
    this.setState({loadingResultsReport: true})
    axios.get(GlobalVariables.apitestHost + `/result/report/${uuid}`).then(res => {
      let link = document.createElement('a');
      link.href = res.data[0];
      link.target = '_blank';
      link.download = `Результат прохождения тестирования.pdf`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.setState({loadingResultsReport: false})
    }).catch(error => {
      console.log(error)
    })
  }

  // ? 
  closeModalResults(){
    this.setState({
      questions : null,
      testActive: null,
      answersList: {},
      answerErrors: [],
      resultsInfo: null,
      loadingResults: false
    })
  }

  componentWillUnmount () {
    if (this._asyncRequest) {
      this._asyncRequest.cancel()
    }
  }

  _loadAsyncData (id) {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('abiturs_test_path', {}, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          data: res.data.data
        })
        this._asyncRequest = null
      })
  }

  handleQuestionChange (d) {
    this.setState(prevState => ({
      question: prevState.question + d
    }), function () {
      cookies.set('test_question', this.state.question)
    })
  }

  handleTestOver () {
    let answers = this.state.answers
    let questions = this.state.data

    this.setState({
      results_fetch: true,
      results_error: false
    })

    let m = []

    Object.keys(questions).map(function (_) {
      let q = questions[_]

      Object.keys(q.answers).map(function(a_id) {
        a_id = parseInt(a_id)
        if (answers[q.id] === undefined) answers[q.id] = []
        if (answers[q.id].includes(a_id)) {
          m.push(2)
        } else {
          m.push(1)
        }
      })
    })

    axios.get(path('abiturs_ht_path', {}, true), { params: { answers: m } })
      .then(res => {
        this.setState({
          results: res.data.variants
        }, function () {
          cookies.remove('test_question')
          cookies.remove('test_answers')
          this.state.question = 1
          this.state.answers = {}
        })
      })
      .catch(error => {
        this.setState({
          results_fetch: false,
          results_error: true
        })
        console.log(error.response.status)
      })
  }

  handleAnswer (question, answer, checked) {
    let answers = this.state.answers

    if (answers[question] === undefined && checked) {
      answers[question] = []
    }

    if (checked) {
      answers[question].push(answer)
    } else {
      answers[question] = answers[question].filter(e => e !== answer)
    }

    this.setState({
      answers: answers
    }, function () {
      cookies.set('test_answers', this.state.answers)
    })
  }

  render () {
    const { data, results_error: resultsError } = this.state
    const {questions, tests, answersList, testActive, resultsInfo, saveResult, answerErrors, loadingResults, loadingResultsReport} = this.state
    
    let variables = []
    for(let i = 1; i <= 10; i++) variables.push(i)

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            {this.props.location.hash === '#index' ? (
              <Link to="root_path">{I18n.t('meta.index.short')}</Link>
            ) : (
              <Link to="abiturs_path">{I18n.t('meta.abiturs.title')}</Link>
            )}
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('meta.abiturs.test.title')}
            </h1>

            {resultsInfo && 
              <div className={styles.resultsTestWrapper}>
                <div className={styles.resultsTestBox}>
                  <h3>
                    <span>Результат прохождения тестирования:</span>
                    <button className={buttons.main} onClick={(e) => this.closeModalResults()}>Закрыть</button>
                  </h3>
                  <div>
                    <img srcSet={resultsInfo.url} alt="График" className={styles.testGraph} />
                  </div>
                  {resultsInfo.messages && Object.values(resultsInfo.messages).map((item, _k)=> (
                    <div key={_k} className={styles.resultsMessageRow}>
                      <h3>{item.title}<span>{item.procent}</span></h3>
                      <i>
                        {/* {item.comment} */}
                        <CommentRender comments={item.comment}></CommentRender>
                      </i>
                      <p>{item.message}</p>
                    </div>
                  ))}
                  <div className={styles.closeBtnWrapper}>
                    <button className={buttons.main} onClick={(e) => this.closeModalResults()}>Закрыть</button>
                    {resultsInfo.uuid && !loadingResultsReport &&
                      <button className={buttons.main} onClick={() => this.getReport(resultsInfo.uuid)}>
                        Сохранить результат
                      </button>
                    }
                    {loadingResultsReport &&
                      <div className={styles.loader} />
                    }
                  </div>
                </div>
              </div>
            }

            <div className={styles.newBox}>  
              {testActive == null &&
                <div>
                  <h3>Дорогие абитуриенты!</h3>
                  <p>Важный этап вашей жизни – профессиональное самоопределение. Мир профессий многогранен, но существуют инструменты (тесты), которые помогают сузить круг поисков и определить, чем бы человек хотел и мог заниматься.</p>
                  <p>На этой странице два теста, которые будут полезны вам, чтобы определиться с будущей сферой профессиональной деятельности.</p>
                  <p>Первый тест определит ваши сильные стороны, а второй раскроет ваши профессиональные ориентиры по типу личности.</p>
                  {tests && 
                    <div>
                      {tests.map((item, _index) =>
                        <div key={item.id}>
                          <a onClick={(e) => this.getQuestions(item.id, _index)} className={styles.testRowLink}>{item.id} - {item.value}</a>
                        </div>
                      )}
                    </div>
                  }
                </div>
              }
              {testActive != null &&
                <h2>{tests[testActive].value}</h2>
              }

              {questions && tests[testActive].type == 1 && 
                <div className={styles.wrapperQueston}>
                  {questions.map((item)=> (
                    <div key={item.id}>
                      {item.type == 1 &&
                      <div>
                        <h4>Вопрос {item.number} - {item.value} <span className={(answersList[item.number] ? styles.isActive : '')}></span></h4>
                        <div className={styles.qAnswer}>
                          {variables.map( (number, index) => (
                            <button key={index} className={(answersList[item.number] && answersList[item.number] == number? styles.isSelectedBtn : '')} onClick={(e) => this.saveAnswer(item.number, number)}>{number}</button>
                          ))}
                        </div>
                      </div>
                      }
                      {item.type == 2 && 
                        <h3 className={styles.rowTitle}>{item.value}</h3>
                      }
                    </div>
                  ))}

                  {answerErrors && 
                  <div className={styles.answerErrors}>
                    {answerErrors.map( (error, _i) => (
                      <div key={_i} className={styles.errorRow}>Вы не ответили на {error} вопрос</div>
                    ))}
                  </div>
                  }

                  <div className={styles.wrapperForBtn}>
                    <button className={buttons.main} onClick={(e) => this.renderResults()}>Результат</button>
                    {loadingResults &&
                      <span className={styles.pendingResults}>Обработка результатов ...</span>
                    }
                  </div>
                </div>
              }

              {questions && tests[testActive].type == 2 &&
              <div>
                <table>
                  <thead>
                    <tr>
                      <td>№</td>
                      <td>Вариант А</td>
                      <td className={styles.testRtl}>Вариант Б</td>
                    </tr>
                  </thead>
                  <tbody>
                  {questions.map((item, _i)=> (
                    <tr key={_i}>
                      <td colSpan={( item.type == 2 ? "3" : "" )}>
                        {item.type==1 &&
                          <span>{item.number}</span>
                        }
                        {item.type==2 &&
                          <div className={styles.testAlignInform}>{item.value}</div>
                        }
                      </td>
                      {item.type == 1 && Object.keys(item.value).map((_k)=> (
                        <td key={_k}>
                          <div className={styles.boxSelectecRow}>
                            <div className={(_k==2?styles.orderTwo:'')}>{item.value[_k]}</div>
                            <div className={(styles.checkboxWrapper)}>
                              <input checked={(answersList[item.number] == _k ? 'checked' : '')} type="checkbox" name={'question_' + _k} value={1}  onChange={(e) => this.saveAnswer(item.number, _k)} />
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                  </tbody>
                </table>

                {answerErrors && 
                <div className={styles.answerErrors}>
                  {answerErrors.map( (error, _i) => (
                    <div key={_i} className={styles.errorRow}>Вы не ответили на {error} вопрос</div>
                  ))}
                </div>
                }

                <div className={styles.wrapperForBtn}>
                  <button className={buttons.main} onClick={(e) => this.renderResults()}>Результат</button>
                  {loadingResults &&
                    <span className={styles.pendingResults}>Обработка результатов ...</span>
                  }
                </div>
                
              </div>
              }

              {testActive == null &&
              <div>
                <h3 className={styles.textCenter}>Профессиональных взлетов и успехов!</h3>
                <p>*«Якоря карьеры» (Э. Шейн, перевод и адаптация В.А. Чикер, В.Э. Винокурова)</p>
                <p>**Тест Дж.Голланда (Дж. Холланда) на определение профессионального типа личности (модификация Г.В. Резапкиной)</p>  
              </div>
              }
            </div>
            

            <Helmet>
              <title>{I18n.t('meta.abiturs.test.title')}</title>
            </Helmet>
          </div>

        </div>
      </div>
    )
  };
}

Test.propTypes = {
  location: PropTypes.object
}

export default Test
