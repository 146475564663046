import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';

import VerificateInput from './VerificateInput';

import styles from '../Section.module.css';
import form from '../../Form.module.css';

class Technical extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      values: [
        'ob_e',
        'ob_e_vl',
        'ob_e_blt',
        'ob_e_bli',
        'ob_e_o',
        'ob_h',
        'ob_h_iis',
        'ob_h_o',
        'ob_m',
        'ob_m_ct',
        'ob_m_ss',
        'ob_m_av',
        'ob_m_o',
        'mad_e',
        'mad_e_vl',
        'mad_e_sz',
        'mad_e_tc',
        'mad_e_bld',
        'mad_e_pea',
        'mad_e_daisy',
        'mad_e_o',
        'mad_h',
        'mad_h_ha',
        'mad_h_o',
        'mad_mn',
        'mad_mm',
      ].reduce((acc, e) => {
        acc[e] = (this.props.values[this.props.prefix] && this.props.values[this.props.prefix][e]) || '';
        return acc;
      }, {}),
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      [name]: {
        $set: value
      }
    });

    this.setState({ values });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.onValuesChange) this.props.onValuesChange(this.props.prefix, this.state.values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state != nextState);
  }

  render () {
    const { values, show } = this.state;
    const { num } = this.props;

    const errors = {
      'err_ob_e': ~~parseInt(values.ob_e) >= ~~parseInt(values.ob_e_vl) && ~~parseInt(values.ob_e) >= ~~parseInt(values.ob_e_blt) && ~~parseInt(values.ob_e) >= ~~parseInt(values.ob_e_bli) && ~~parseInt(values.ob_e) >= ~~parseInt(values.ob_e_o),
      'err_ob_h': ~~parseInt(values.ob_h) >= ~~parseInt(values.ob_h_iis) && ~~parseInt(values.ob_h) >= ~~parseInt(values.ob_h_o),
      'err_ob_m': ~~parseInt(values.ob_m) >= ~~parseInt(values.ob_m_ct) && ~~parseInt(values.ob_m) >= ~~parseInt(values.ob_m_ss) && ~~parseInt(values.ob_m) >= ~~parseInt(values.ob_m_av) && ~~parseInt(values.ob_m) >= ~~parseInt(values.ob_m_o),
      'err_mad_e': ~~parseInt(values.mad_e) >= ~~parseInt(values.mad_e_vl) && ~~parseInt(values.mad_e) >= ~~parseInt(values.mad_e_sz) && ~~parseInt(values.mad_e) >= ~~parseInt(values.mad_e_tc) && ~~parseInt(values.mad_e) >= ~~parseInt(values.mad_e_bld) && ~~parseInt(values.mad_e) >= ~~parseInt(values.mad_e_pea) && ~~parseInt(values.mad_e) >= ~~parseInt(values.mad_e_daisy) && ~~parseInt(values.mad_e) >= ~~parseInt(values.mad_e_o),
      'err_mad_h': ~~parseInt(values.mad_h) >= ~~parseInt(values.mad_h_ha) && ~~parseInt(values.mad_h) >= ~~parseInt(values.mad_h_o),
    };

    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Обеспечение процесса обучения лиц с ОВЗ и инвалидностью техническими средствами

          <div className={classNames(styles.label, { [styles.ok]: Object.values(values).filter(v => v).length == Object.values(values).length })}>
            {Object.values(values).filter(v => v).length} из {Object.values(values).length}
          </div>
        </div>

        <div className={styles.content}>
          <div className={form.elem}>
            <div className={form.label}>
              {num}.1. Количество аудиторий, обеспеченных стационарными техническими средствами для лиц с нарушениями зрения:
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_e" value={values.ob_e} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.desc}>
            В том числе из пункта {num}.1:

            {!errors['err_ob_e'] &&
              <div className={form.error}>Ошибка в данных. Одно из введенных значений превышает допустимое.</div>
            }
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.1.1. Видеоувеличителями
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_e_vl" value={values.ob_e_vl} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.1.2. Устройствами рельефно-точечной печати текстовой информации (Брайлевские принтеры)
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_e_blt" value={values.ob_e_blt} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.1.3. Устройствами печати рельефных изображений
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_e_bli" value={values.ob_e_bli} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.1.4. Другими техническими средствами
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_e_o" value={values.ob_e_o} onChange={this.handleInputChange} />
            </div>
          </div>

          <hr />

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2. Количество аудиторий, обеспеченных стационарными техническими средствами для лиц с нарушениями слуха:
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_h" value={values.ob_h} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.desc}>
            В том числе из пункта {num}.2:
            {!errors['err_ob_h'] &&
              <div className={form.error}>Ошибка в данных. Одно из введенных значений превышает допустимое.</div>
            }
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2.1. Информационной индукционной системой
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_h_iis" value={values.ob_h_iis} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2.2. Другими техническими средствами
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_h_o" value={values.ob_h_o} onChange={this.handleInputChange} />
            </div>
          </div>

          <hr />

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3. Количество аудиторий, обеспеченных стационарными техническими средствами для лиц с нарушениями опорно-двигательного аппарата:
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_m" value={values.ob_m} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.desc}>
            В том числе из пункта {num}.3:
            {!errors['err_ob_m'] &&
              <div className={form.error}>Ошибка в данных. Одно из введенных значений превышает допустимое.</div>
            }
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.1. Специализированной компьютерной техникой
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_m_ct" value={values.ob_m_ct} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.2. Специальным программным обеспечением
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_m_ss" value={values.ob_m_ss} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.3. Альтернативными устройствами ввода информации
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_m_av" value={values.ob_m_av} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3.4. Другими техническими средствами
            </div>

            <div className={form.input}>
              <VerificateInput name="ob_m_o" value={values.ob_m_o} onChange={this.handleInputChange} />
            </div>
          </div>

          <hr />

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4. Количество мобильных адаптирующих устройств для лиц с нарушениями зрения:
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_e" value={values.mad_e} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.desc}>
            В том числе из пункта {num}.4:
            {!errors['err_mad_e'] &&
              <div className={form.error}>Ошибка в данных. Одно из введенных значений превышает допустимое.</div>
            }
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4.1. Видеоувеличители
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_e_vl" value={values.mad_e_vl} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4.2. Программы увеличения изображения на экране
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_e_sz" value={values.mad_e_sz} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4.3. Тифлокомпьютеры
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_e_tc" value={values.mad_e_tc} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4.4. Брайлевский дисплей
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_e_bld" value={values.mad_e_bld} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4.5. Программы экранного доступа
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_e_pea" value={values.mad_e_pea} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4.6. Цифровая аудиозапись учебных материалов в формате DAISY - Digital Accessible Information System
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_e_daisy" value={values.mad_e_daisy} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4.7. Другие адаптирующие устройства
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_e_o" value={values.mad_e_o} onChange={this.handleInputChange} />
            </div>
          </div>

          <hr />

          <div className={form.elem}>
            <div className={form.label}>
              {num}.5. Количество мобильных адаптирующих устройств для лиц с нарушениями слуха:
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_h" value={values.mad_h} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={styles.desc}>
            В том числе из пункта {num}.5:
            {!errors['err_mad_h'] &&
              <div className={form.error}>Ошибка в данных. Одно из введенных значений превышает допустимое.</div>
            }
          </div>


          <div className={form.elem}>
            <div className={form.label}>
              {num}.5.1. Слуховые аппараты
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_h_ha" value={values.mad_h_ha} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.5.2. Другие адаптирующие устройства
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_h_o" value={values.mad_h_o} onChange={this.handleInputChange} />
            </div>
          </div>

          <hr />

          <div className={form.elem}>
            <div className={form.label}>
              {num}.6. Количество мобильных адаптирующих устройств для лиц с нарушениями опорно-двигательного аппарата (мобильные)
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_mm" value={values.mad_mm} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.7. Количество мобильных адаптирующих устройств для лиц с нарушениями опорно-двигательного аппарата (на кресле-коляске)
            </div>

            <div className={form.input}>
              <VerificateInput name="mad_mn" value={values.mad_mn} onChange={this.handleInputChange} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Technical.defaultProps = {
  prefix: 'technical',
  num: '6'
};

export default Technical;
