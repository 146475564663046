import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import update from 'immutability-helper';
import pluralize from 'pluralize'
import { Link } from 'react-router-dom';

import { Routes, path } from '../Routes';

import Loaf from '../Loaf/Loaf';
import StageItem from './StageItem'

import page from '../Page.module.css';
import form from '../Form.module.css';
import buttons from '../Buttons.module.css';

import { Editor, EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import styles from './Stages.module.css'

class StagesIndex extends React.Component {
  state = {
    stages: [],
    values: null
  }

  componentDidMount () {
    if (this.props.stageable_id) this._loadAsyncData(this.props.stageable_id, this.props.stageable_type)
  }

  _loadAsyncData (id, type) {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('stages_path', {}, true), { cancelToken: this._asyncRequest.token, params: { stageable_type: type, stageable_id: id } })
      .then(res => {
        this.setState({
          stages: res.data.stages.map(s => ({id: s.id, title: s.title, desc: EditorState.createWithContent(convertFromRaw(s.desc)), attachments: s.attachments}) ),
          submit: {
            url: path(`account_${type}_path`, {id: id}, true),
            method: 'patch'
          }
        });

        this._asyncRequest = null;
      })
  }

  handleStagesUpdate = (index, item) => {
    let newState = update(this.state.stages, {
      [index]: {
        $set: item
      }
    })

    this.setState(prevState => ({
      stages: newState
    }))
  }

  handleSubmit = (e) => {
    axios({
      method: this.state.submit.method,
      url: this.state.submit.url,
      data: {
        [this.props.stageable_type]: {
          stages_attributes: this.state.stages.map(s => {
            return {
              id: s.id,
              title: s.title,
              desc: convertToRaw(s.desc.getCurrentContent()),
              attachments_attributes: s.attachments.map(a => ({ id: a.id, title: a.title, section: a.section })),
              attachment_ids: s.attachments.map(a => a.id)
            }
          }),
          stage_ids: this.state.stages.map(s => s.id)
        }
      }
    }).then(
      res => {
        this.props.history.push(res.headers.location);
      }
    )

    e.preventDefault()
  }

  handleNewStage = () => {
    let newStages = update(this.state.stages,
      {$push: [{id: null, title: '', show: true, desc: EditorState.createEmpty()}]}
    )

    this.setState({
      stages: newStages
    });
  }

  handleDeleteStage = (index) => {
    let newStages = update(this.state.stages, {
      $splice: [[index, 1]]
    });

    this.setState({
      stages: newStages
    });
  }

  handleAtachementDelete = index => {
    let newState = update(this.state.attachments, {
      $splice: [[index, 1]]
    });

    this.setState(prevState => ({
      attachments: newState
    }));
  }

  render() {
    const { stages } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>

          <Loaf>
            <Link to={path('account_path')}>Кабинет</Link>
            <Link to={path(`account_${pluralize(this.props.stageable_type)}_path`)}>Материалы</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Редактирование разделов
            </h1>
          </div>

          <div className={form.tight}>
            {stages.length > 0 &&
              stages.map((stage, _) =>
                <StageItem key={stage.id ? stage.id : _} stage={stage} index={_} onStagesUpdate={this.handleStagesUpdate} onStageDelete={this.handleDeleteStage}/>
              )
            }
          </div>

          <div className={styles.actions}>
            <div className={buttons.main} onClick={this.handleNewStage}>Добавить раздел</div>

            <div className={buttons.main} onClick={this.handleSubmit}>Сохранить</div>
          </div>
        </div>
      </div>
    )
  }
}

export default StagesIndex
