import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Form from './Form'
import Index from './Index'
import Show from './Show'

export default function Events () {
  return (
    <Switch>
      <Route path={Routes.new_event_event_registration_path}>
        <Form />
      </Route>

      <Route path={Routes.event_event_registration_path}>
        <Show />
      </Route>

      <Route path={Routes.event_event_registrations_path}>
        <Index />
      </Route>
    </Switch>
  )
}
