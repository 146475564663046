import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'query-string'
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';

import { Routes, path } from '../Routes';


import Loaf from '../Loaf/Loaf';
import Filters from '../Filters/Filters';

import page from '../Page.module.css';
import form from '../Form.module.css';
import fonts from '../Fonts.module.css';
import styles from './TomorrowRequestsList.module.css'

class TomorrowRequestsList extends React.Component {
  state = {
    requests: [],
    filters: null,
    search: this.props.location.search,
    values: {
      input: '',
      school_id: null,
    }
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('tomorrow_tomorrow_requests_path', {}, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search) })
      .then(res => {
        this.setState({
          requests: res.data.requests,
          filters: res.data.filters,
          dictionaries: res.data.dictionaries,
          expert: res.data.expert
        });

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(state.filters && props.location.search !== state.search) {
      return {
        requests: null,
        search: props.location.search,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.requests === null) {
      this._loadAsyncData();
    }
  }

  filterRequests = (e) => {
    let value = e.target.value;
    this.setState(prevState => ({
      values: { ...prevState.values,
        input: value.toLowerCase()
      }
    }));
  }

  _loadSchools = (input, callback) => {
    callback(this.state.dictionaries.schools.filter(school => school.title.toLowerCase().includes(input.toLowerCase())).slice(0, 100));
  };

  render() {
    const { filters, dictionaries, expert } = this.state;

    const { history, location } = this.props;

    let requests = this.state.requests;

    if(requests) {
      requests = requests.filter(request => this.state.values.input === '' || request.authors.toLowerCase().includes(this.state.values.input));

      requests = requests.filter(request => !this.state.values.school_id || request.school_id == this.state.values.school_id);
    }

    return (
      <div>
        <div className={page.title}>
          <h2>
            Заявки на конкурс
          </h2>

          <Helmet>
            <title>
              Заявки на конкурс
            </title>
          </Helmet>
        </div>
        {expert &&
          <div className={styles.hint}>
            Для того что бы оценить заявку, выберите ее из списка ниже. Заявки которые требуют вашей оценки, имеют метку "Ожидает оценки". На странице заявки отображется описание и прикрепленные файлы с работой, а также форма с оценкой работы по критериям и возможностью оставить краткий отзыв. После проставления оценок по всем критериям необходимо нажать на кнопку "Сохранить и утвердить".
          </div>
        }

        <div className={styles.root}>
          <div className={styles.list}>
            { requests && requests.length > 0 &&
              requests.map((request, _) =>
                <div key={_} className={styles.item}>
                  <Link className={styles.link} to={path('tomorrow_tomorrow_request_path', { id: request.id })}>
                  </Link>
                  <div>{_ + 1}. {request.title}</div>

                    {(request.markers || request.rates) &&
                      <div className={classNames(styles.markers, fonts.as_mt)}>
                        {request.markers &&
                          <>
                            {request.markers.map((marker, _) =>
                              <div key={_} className={styles.marker}>
                                {marker}
                              </div>
                            )}
                          </>
                        }

                        {request.rates &&
                          <>
                            {request.rates.map((rate, _) =>
                              <div key={_} className={classNames(styles.marker, { [styles.rated]: rate.rated })}>
                                {rate.label}
                              </div>
                            )}
                          </>
                        }
                      </div>
                    }
                </div>
              )
            }
            { requests && requests.length == 0 &&
              <div>У вас нет активных заявок</div>
            }
            { !requests &&
              <div>Ваши заявки будут храниться здесь</div>
            }
          </div>

          <div className={styles.filters}>
            {filters &&
              <>
                <Filters {...this.props} filters={filters} history={history} location={location} />

                <div className={styles.search}>
                  <div className={form.input}>
                    <input type="text" onChange={this.filterRequests} placeholder="ФИО автора" />
                  </div>
                </div>

                { dictionaries &&
                  <div className={styles.search}>
                    <div className={form.input}>
                      <AsyncSelect
                        classNamePrefix="react-select"
                        getOptionLabel={option => option.title}
                        getOptionValue={option => option.id}
                        loadOptions={this._loadSchools}
                        placeholder="Выберите вуз.."
                        onChange={value => this.setState(state => ({ values: { ...state.values, school_id: value.id } }))}
                        noOptionsMessage={({inputValue}) => inputValue == '' ? 'Введите часть названия вуза' : 'Нет доступных результатов'}
                      />
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default TomorrowRequestsList
