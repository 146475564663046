import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Card from './Card'

import styles from '../News/News.module.css'

class Block extends React.Component {
  state = {
    more: false
  }

  render () {
    const { news, count, title } = this.props

    if (!news) return null

    return (
      <div className={styles.wrapper}>
        <div className={classNames(styles.wrapperBlocks)}>
          {news.slice(0, count).map(one_news =>
            <Card key={one_news.id} {...one_news} />
          )}
        </div>
      </div>
    )
  }
}

Block.propTypes = {
  title: PropTypes.string,
  news: PropTypes.array,
  count: PropTypes.number
}

Block.defaultProps = {
  title: 'Новости'
}

export default Block
