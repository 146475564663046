import React from 'react';
import { Chart as ChartJS, ArcElement, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';

ChartJS.register(ArcElement, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);
ChartJS.defaults.color = '#000';
ChartJS.defaults.borderColor = '#fff';

const PolarAreaChart = (props) => {
    var data = {
        labels: props.data.map((i) => i.title),
        datasets: [
            {
                data: props.data.map((i) => i.val),
                backgroundColor: [
                    'rgba(250, 207, 7, 0.8)',
                    'rgba(255, 221, 9, 0.4)',
                    'rgba(250, 207, 7, 0.8)',
                    'rgba(255, 221, 9, 0.4)',
                    'rgba(250, 207, 7, 0.8)',
                    'rgba(255, 221, 9, 0.4)',
                    'rgba(250, 207, 7, 0.8)',
                    'rgba(255, 221, 9, 0.4)'
                ],
                borderWidth: 0
            }
        ]
    };

    var options = {
        responsive: true,
        plugins: {
            legend: { display: false },
            title: { display: false },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let res = context.label + ': ' + props.data[context.dataIndex].percent + '%';
                        if (res.length >= 45) {
                            return [res.substr(0, res.length / 2), res.substr(res.length / 2, res.length - 1)];
                        } else {
                            return res;
                        }
                    }
                }
            }
        },
        scales: {
            r: {
                ticks: { display: false },
                grid: {
                    color: '#999999',
                    lineWidth: 0.259233
                }
            }
        }
    };

    return <PolarArea data={data} options={options} />;
};

export default PolarAreaChart;
