import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

import { Routes, path } from '../Routes';

import Loaf from '../Loaf/Loaf';

import forms from '../Form.module.css'; // Другое имя чтобы не было коллапса с моделью
import buttons from '../Buttons.module.css';
import page from '../Page.module.css';
import styles from './Form.module.css';

class FormResults extends React.Component {
  state = {
    values: {
      title: ''
    },
    results: null,
    redirect_to: null
  }

  componentDidMount() {
    if(this.props.id) this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('form_form_results_path', { form_id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          results: res.data.results,
          values: {
            title: res.data.title
          },
        });

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  render () {
    const { values, results } = this.state;
    const title = `Результаты заполнения «${values.title}»`;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('form_path', { id: this.props.id})}>Анкета</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {title}
            </h1>

            <Helmet>
              <title>
                {title}
              </title>
            </Helmet>
          </div>

          {results && results.length > 0 &&
            <div>
              <ul>
                {results.map(result =>
                  <li key={result.id}>
                    <Link to={path('edit_form_form_result_path', { form_id: this.props.id, id: result.id })}>
                      Анкета  №{result.id} от {result.date}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default FormResults
