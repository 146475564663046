import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import I18n from '../../../I18n'
import Link from '../../../Routes'

import styles from './Block.module.css'

class Block extends Component {
  render () {
    const { num, id, items } = this.props

    return (
      <div className={styles.root}>
        <div className={styles.data}>
          <div className={styles.title}>
            {num}. {I18n.t(`account.rumc.target.${id}.title`)}
          </div>
          {id == 'atypical_report' &&
            <p style={{fontSize: '13px'}}><i>*для РУМЦ, подведомственных Минпросвещения России, Минкультуры России, Минтрансу России, Минспорту России, Минздраву России, Минсельхозу России</i></p>
          }
        </div>

        {items && items.length > 0 &&
          <div className={styles.sections}>
            {items.map(item =>
              <Wrapper key={item.id} url={item.url}>
                <Item item={item} section={id} />
              </Wrapper>
            )}
          </div>
        }
      </div>
    )
  }
}

Block.propTypes = {
  num: PropTypes.number,
  id: PropTypes.string,
  items: PropTypes.array
}

export default Block

function Wrapper (props) {
  // console.log(props.url)
  return (
    <>
      {props.url &&
        <Link {...props.url} className={styles.section}>
          {props.children}
        </Link>
      }

      {!props.url &&
        <div className={styles.section}>
          {props.children}
        </div>
      }
    </>
  )
}

Wrapper.propTypes = {
  url: PropTypes.object,
  children: PropTypes.node
}

class Item extends Component {
  isSuccess () {
    return this.props.item.goal && this.props.item.current >= this.props.item.goal
  }

  isProcess () {
    return this.props.item.goal && this.props.item.current < this.props.item.goal
  }

  render () {
    const { item, section } = this.props

    return (
      <>
      {(!!item.goal || item.current !== undefined) &&
        <div className={classNames(styles.goal, { [styles.success]: this.isSuccess(), [styles.process]: this.isProcess() })}>
          <div>{item.current}</div>
          {!!item.goal &&
            <>
              <div className={styles.from}>из</div>
              <div>{item.goal}</div>
            </>
          }
        </div>
      }

      {item.state === 'inactive' &&
        <div className={styles.inactive}>
          Утверждается
        </div>
      }

      <div>
        {I18n.t(`account.rumc.target.${section}.${item.label}.title`)}
      </div>

      {item.desc && section != "training_grad_ovz" &&
        <div className={styles.sdesc}>
          {item.desc}
        </div>
      }

      {item.desc && section == "training_grad_ovz" &&
        <div className={styles.sdesc}>
          {item.desc}
          <br />
          Для добавления аннотации отправьте заполненный шаблон на почту 
          <a href="mailto:pub@incedu.ru?subject=Аннотация программы ДПО ПК" target="_blank" onClick={(e) => (e.stopPropagation())}> pub@incedu.ru</a>
          <br />
          <a href="/uploads/templates/annotation_template.xlsx" target="_blank" onClick={(e) => (e.stopPropagation())}>Скачать шаблон</a>
        </div>
      }
      </>
    )
  }
}

Item.propTypes = {
  item: PropTypes.object,
  section: PropTypes.string
}
