import React, { useState, useEffect, componentDidUpdate, useRef, Component } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import Loaf from '../Loaf/Loaf'
import Link, { path } from '../Routes'
import Datec from '../Datec'
import I18n from '../I18n'

import page from '../Page.module.css'
import styles from './News.module.css'

export default function Show () {
  const { id } = useParams()
  const cancelToken = useRef(axios.CancelToken.source())
  const [news, setNews] = useState()
  // const [comments, setComments] = useState()
  const [next, setNext] = useState()
  const [last, setLast] = useState()


  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('news_path', { id }, true), { cancelToken: cancelToken.current.token })
      setNews(data.news)
      // setComments(data.comments)
      setNext(data.next)
      setLast(data.last)
    }

    _fetch()

    return () => {
      cancelToken.current.cancel()
    }
  }, [id])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="news_index_path">{I18n.t('nav.news')}</Link>
        </Loaf>

        <Helmet>
          <title>{I18n.t('nav.news')}</title>
        </Helmet>

        {/* Wrapper */}
        <div className={styles.showNewsWrapper}>
          {/* Left */}
          <div className={styles.content}>
            {news &&
              <>
                <div className={styles.news_date}>
                  {news.date &&
                    <div>
                      {news.date}
                      <meta itemprop="datePublished" content={news.created_at}></meta>
                    </div>
                  }
                  {!news.date &&
                    <Datec time={news.created_at} />
                  }
                </div>
                <div className={styles.news_show_h}>
                  {news.name}
                </div>

                {news.rumc &&
                  <Link to="rumc_path" params={{ id: news.rumcs_id }} className={styles.news_date} key={news.rumcs_id}>РУМЦ: {news.rumc}</Link>
                }
                {news.ministry &&
                  <p className={styles.news_date}>{news.ministry}</p>
                }

                <p className="content">
                <p dangerouslySetInnerHTML={{__html: news.desc}}></p>
                </p>

                {news.videos &&
                  <div className={styles.banners}>
                    {news.videos.map((video) => (
                      <iframe 
                        className={styles.video}
                        title='Youtube player'
                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                        src={`https://youtube.com/embed/${video.youtubeID}?autoplay=0`}>
                      </iframe>
                    ))}
                  </div>
                }

                {news.banners &&
                  <div className={styles.banners}>
                    {news.banners.map((banner, inex) =>
                      <img src={banner.url} key={inex} />
                    )}
                  </div>
                }
                

                <div className={styles.files_urls_block}>
                  {news.urls &&
                    <div>
                      <h3>Полезные ссылки:</h3>
                      <ul>
                        {news.urls.map((url, index) => 
                          <li className={styles.links} >
                            {/* <img src={web_svg} className={styles.links}></img> */}
                            <a href={url.url} target="_blank" rel="noopener noreferrer" key={index + 'u'}>{url.name}</a>
                          </li>
                        )}
                      </ul>
                    </div>
                  }
                  {news.files &&
                    <div>
                      <p></p>
                      <h3>Файлы:</h3>
                      <div className={styles.files}>
                        {news.files.map((file, index) => 
                          <span>
                            <a href={file.url} target="_blank" rel="noopener noreferrer" key={index + 'f'}>{file.name}</a>
                          </span>
                        )}
                      </div>
                    </div>
                  }
                </div>

                {/* <div className={styles.viewCounter}>{news.view_counter}</div> */}
              </>
            }
          </div>
          {/* Right */}
          <div className={styles.navigation}>
              {next &&
                <div className={styles.newsNavigation}>
                  <div className={styles.news_date}>
                    {news.date &&
                      <div>{next.date}</div>
                    }
                    {!news.date &&
                      <Datec time={next.created_at} />
                    }
                  </div>
                  <div className={styles.news_title}>{next.name}</div>

                  {/* <Link to="news_index_path" to="news_path" params={{ id: next.id }} >Следующая новость</Link> */}
                  <div className={styles.next_last}>
                    <a href={"/новости/" + next.id}>Следующая новость</a>
                  </div>
                </div>
              }

              {last &&
                <div className={styles.newsNavigation}>
                  <div className={styles.news_date}>
                    {/* <Datec time={last.created_at} /> */}
                    {news.date &&
                      <div>{last.date}</div>
                    }
                    {!news.date &&
                      <Datec time={last.created_at} />
                    }
                  </div>
                  <div className={styles.news_title}>{last.name}</div>

                  {/* <Link to="news_index_path" to="news_path" params={{ id: last.id }} >Предыдущая новость</Link> */}
                  <div className={styles.next_last}>
                    <a href={"/новости/" + last.id}>Предыдущая новость</a>
                  </div>
                </div>
              } 
          </div>
        </div>
        
      </div>
    </div>
  )
}