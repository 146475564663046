import React, { Component } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
// import { ResponsiveContainer, RadialBarChart, RadialBar, Legend, PolarAngleAxis, Label, Tooltip } from 'recharts'

import { path } from '../Routes'

import page from '../Page.module.css'
// import buttons from '../Buttons.module.css'
import styles from './Index.module.css'

import VacanciesIll from '../../images/stats/vacancies.svg'
import SmileIll from '../../images/stats/smile.svg'
import LifeIll from '../../images/stats/life.svg'
import StateIll from '../../images/stats/state.svg'

class Index extends Component {
  state = {
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  _loadAsyncData () {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('stats_path', { id: this.state.id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          schools: res.data.schools,
          forms: res.data.forms,
          monitoring: res.data.monitoring,
          target: res.data.target
        })

        this._asyncRequest = null
      })
  }

  static getDerivedStateFromProps (props, state) {
    if (state.id !== props.match.params.id) {
      return {
        id: props.match.params.id
      }
    }

    return null
  }

  render () {
    const { schools, id, monitoring, forms, target } = this.state

    if (schools) {
      // console.log(schools.ministries);
    }

    return (
      <div className={page.root}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>
              Отчёт {id}
            </h1>

            <Helmet>
              <title>
                {id}
              </title>
            </Helmet>
          </div>

          {monitoring &&
            <div className={styles.root}>
              <Link to={path('stats_schools_path', { id: id })} className={styles.link}>
                <div className={styles.title}>
                  Деятельность высших учебных заведений
                </div>

                <div className={styles.desc}>
                  {~~(schools.reports / schools.schools * 100)}% <span>вузов из {schools.schools} успешно заполнили форму мониторинга</span>
                </div>
              </Link>

              <Link to={path('stats_rumcs_path', { id: id })} className={styles.link}>
                <div className={styles.title}>
                  Деятельность ресурсных центров
                </div>
              </Link>

              <Link to={path('abiturs_vacancies_path')} className={styles.link}>
                <div className={styles.title}>
                  Региональный рынок труда (квотированных рабочих мест)
                </div>

                <div className={styles.vacancies}>
                  <img src={VacanciesIll} />
                </div>
              </Link>

              {forms.map(form =>
                <Link to={path('stats_form_path', { id: target.id, form_id: form.id })} className={styles.link} key={form.id}>
                  {form.id === 1366 &&
                    <div className={styles.state}>
                      <img src={StateIll} />
                    </div>
                  }

                  {form.id === 1368 &&
                    <div className={styles.smile}>
                      <img src={SmileIll} />

                      <p className={styles.hint}>
                        Удовлетворенность студенческой жизнью
                      </p>
                    </div>
                  }

                  {form.id === 1369 &&
                    <div className={styles.life}>
                      <img src={LifeIll} />

                      <p className={styles.hint}>
                        <span>
                          63%
                        </span>
                         имеют потребность в асистенте-помощнике
                      </p>
                    </div>
                  }

                  <div className={styles.title}>
                    {form.title}
                  </div>
                </Link>
              )}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default Index
