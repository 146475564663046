import React from 'react'
import GlobalVariables from '../../Vars';

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { mapStyle } from "./MapStyle";

const containerStyle = {
  position: 'relative',  
  width: '100%',
  height: '500px',
}


export class MapContainer extends React.Component {
  state = {}

  constructor(props) {
    super(props);

    let branches_koord = []
    this.props.data.branches_addresses.map((branch) => {
      if (branch.koordinats) {
        branches_koord.push({
          position: {lat: parseFloat(branch.koordinats.split(",")[0]), lng: parseFloat(branch.koordinats.split(",")[1])},
          information: branch
        })
      }
    })

    this.state = {
      head: {
        lat: parseFloat(this.props.data.coordinates[0].coordinates.split(",")[0]),
        lng: parseFloat(this.props.data.coordinates[0].coordinates.split(",")[1])
      },
      branches: branches_koord,
      gerb_head: this.props.school.gerb_org.includes("/0.png") ? GlobalVariables.cdnHost + "icons/building.svg" : GlobalVariables.cdnHost + this.props.school.gerb_org.replace("org_logo", "org_logo_c"),
      size_head: this.props.school.gerb_org.includes("/0.png") ? 30 : 50
    }
  }
 
  _mapLoaded(mapProps, map) {
    map.setOptions({
      styles: mapStyle,
    })
  }

  onMarkerClick (props, marker, e) {
    console.log(marker)
    marker.map.setOptions({
      zoom: 12,
      center: marker.position
    })
  }

  render() {
    const { head, branches, gerb_head, size_head } = this.state
    console.log(gerb_head)


    return (
      <Map 
        google={this.props.google} 
        zoom={12}
        containerStyle={containerStyle}
        initialCenter={head}
        center={this.props.center}
        onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
      >
        <Marker 
          title={'Головная организация'} 
          onClick={this.onMarkerClick} 
          position={head}
          icon={{
            url: gerb_head,
            scaledSize: new google.maps.Size(size_head, size_head)
          }}
        />

        {branches.map((branch) => 
          <Marker 
            id={branch.information.org_id}
            key={branch.information.org_id} 
            title={branch.information.full_name} 
            onClick={this.onMarkerClick}
            position={branch.position}
            icon={{
              url: GlobalVariables.cdnHost + "icons/building_filled.svg",
              scaledSize: new google.maps.Size(20, 20)
            }}
          />
        )}
      </Map>
    );
  }
}


export default GoogleApiWrapper({
  apiKey: ('AIzaSyB0Qc18b6r8wffdZltAGoAgV7oJgNz8Crg'),
  language: "ru-RU"
})(MapContainer)

