import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import classNames from 'classnames'

import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'
import { useForm, Errors } from '../../Form'

import page from '../../Page.module.css'
import form from '../../Form.module.css'
import buttons from '../../Buttons.module.css'

export default function Form () {
  const { id } = useParams()
  const history = useHistory()
  const cancelToken = useRef(axios.CancelToken.source())

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    send,
    setSend
  } = useForm()

  const [dictionaries, setDictionaries] = useState()

  const handleSubmit = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    if (id) {
      handleUpdate()
    } else {
      handleCreate()
    }
  }

  const handleCreate = async () => {
    await axios.post(
      path('account_facilities_path'),
      { facility: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const handleUpdate = async () => {
    await axios.patch(
      path('account_facility_path', { id }),
      { facility: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const [facility, setFacility] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(
        id ? path('edit_account_facility_path', { id }, true) : path('new_account_facility_path', {}, true),
        { cancelToken: cancelToken.current.token }
      )

      setFacility(data.facility)
      setDictionaries(data.dictionaries)
      setValues(data.values)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
          <Link to="account_facilities_path">{I18n.t('account.facilities.title')}</Link>
          {facility &&
            <Link to="account_facility_path" params={{ id: facility.id }}>{facility.title}</Link>
          }
        </Loaf>

        {!facility &&
          <div className={page.title}>
            <h1>
              {I18n.t('account.facilities.new')}
            </h1>

            <Helmet>
              <title>{I18n.t('account.facilities.new')}</title>
            </Helmet>
          </div>
        }

        {values && dictionaries &&
          <div className={form.tight}>
            <form onSubmit={handleSubmit}>
              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Название
                  </div>

                  <div className={form.input}>
                    <input type="text" value={values.title} name="title" onChange={handleInputChange} />
                  </div>
                </label>

                <Errors errors={errors.title} />
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Тип оборудования
                  </div>

                  <div className={form.input}>
                    <select name="facility_type_id" value={values.facility_type_id} onChange={handleInputChange}>
                      <option />
                      {dictionaries.facility_types.map(type =>
                        <option key={type.id} value={type.id}>{type.title}</option>
                      )}
                    </select>
                  </div>
                </label>

                <Errors errors={errors.facility_type} />
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Нозология
                  </div>

                  <div className={form.input}>
                    <select name="nosology_id" value={values.nosology_id} onChange={handleInputChange}>
                      <option />
                      {dictionaries.nosologies.map(nosology =>
                        <option key={nosology.id} value={nosology.id}>{nosology.title}</option>
                      )}
                    </select>
                  </div>
                </label>

                <Errors errors={errors.nosology} />
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Категория оборудования
                  </div>

                  <div className={form.input}>
                    <select name="section" value={values.section} onChange={handleInputChange}>
                      <option />
                      {dictionaries.sections.map(section =>
                        <option key={section.id} value={section.id}>{section.title}</option>
                      )}
                    </select>
                  </div>
                </label>

                <Errors errors={errors.section} />
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Технические характеристики
                  </div>

                  <div className={form.input}>
                    <textarea value={values.specification} name="specification" onChange={handleInputChange} />
                  </div>
                </label>

                <Errors errors={errors.specification} />
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Количество экземпляров
                  </div>

                  <div className={form.input}>
                    <input type="text" value={values.quantity} name="quantity" onChange={handleInputChange} />
                  </div>
                </label>

                <Errors errors={errors.quantity} />
              </div>

              <div className={form.submit}>
                <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                  Сохранить
                </button>
              </div>
            </form>
          </div>
        }
      </div>
    </div>
  )
}
