import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'query-string';

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import { path } from '../../Routes';
import List from '../List';

import page from '../../Page.module.css';

class Index extends Component {
  state = {
    target_documents: [],
    search: this.props.location.search,
    filters: null
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('account_target_documents_path', {}, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search) })
      .then(res => {
        const { target_documents, filters } = res.data;

        this.setState({ target_documents, filters });
        this._asyncRequest = null;
      });
  }

  static getDerivedStateFromProps(props, state) {
    if(props.location.search !== state.search) {
      return {
        target_documents: null,
        search: props.location.search,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.target_documents === null) this._loadAsyncData();
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  render() {
    const { target_documents, filters } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('target_documents.title')}
            </h1>

            <Helmet>
              <title>{I18n.t('target_documents.title')}</title>
            </Helmet>
          </div>

          <div>
            <List items={target_documents} filters={filters} {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
