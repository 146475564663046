import React from 'react'

import I18n from '../I18n'
import Link from '../Routes'

import Logo from '!svg-react-loader?!../../images/logo.svg'
import styles from './Footer.module.css'
import classNames from 'classnames'
import test from '../Header/Vision.module.css'

export default function Footer () {
  return (
    <footer className={styles.root}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo className={I18n.locale} />

          <div className={styles.locales}>
            <a href="https://xn--80aabdcpejeebhqo2afglbd3b9w.xn--p1ai/">Русский</a>
            <a href="https://incedu.ru">English</a>
          </div>
        </div>

        <div className={styles.nav}>
          <div className={styles.title}>
            {I18n.t('footer.sections')}
          </div>
          <ul>
            <li>
              <Link to="abiturs_path">
                {I18n.t('footer.abiturs')}
              </Link>
            </li>
            <li>
              <Link to="students_path">
                {I18n.t('footer.students')}
              </Link>
            </li>
            <li>
              <Link to="universities_path">
                {I18n.t('footer.universities')}
              </Link>
            </li>
            <li>
              <Link to="rumcs_path">
                {I18n.t('footer.rumcs')}
              </Link>
            </li>
            <li>
              <Link to="about_path">
                {I18n.t('footer.about')}
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.nav}>
          <div className={styles.title}>
            {I18n.t('footer.help')}
          </div>
          <ul>
            <li>
              <Link to="contacts_path">
                {I18n.t('footer.contact')}
              </Link>
            </li>
            <li>
              <Link to="copyright_path">
                {I18n.t('footer.information')}
              </Link>
            </li>
            <li>
              <Link to="terms_path">
                {I18n.t('footer.docs')}
              </Link>
            </li>
            <li>
              <Link to="support_path">
                {I18n.t('footer.support')}
              </Link>
            </li>


            <li>
              <Link to="cdn_index_path">
                {I18n.t('footer.partners')}
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.about}>
          <div className={styles.longname}>
            {I18n.t('footer.what')}
          </div>

          <div className={styles.register}>
            {I18n.t('footer.gis')}
          </div>
        </div>

      </div>
    </footer>
  )
}
