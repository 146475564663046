import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';

import VerificateInput from './VerificateInput';

import styles from '../Section.module.css';
import form from '../../Form.module.css';

class Numbers extends React.Component {
  state = {
    show: true,
    values: {
      total: this.props.values.total || '',
      d: this.props.values.d || '',
      e: this.props.values.e || '',
      h: this.props.values.h || '',
      mm: this.props.values.mm || '',
      mn: this.props.values.mn || '',
      s: this.props.values.s || '',
      p: this.props.values.p || '',
      o: this.props.values.o || '',
      pe: this.props.values.pe || '',
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      [name]: {
        $set: value
      }
    });

    this.setState({ values });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.onValuesChange) this.props.onValuesChange(this.props.prefix, this.state.values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state != nextState);
  }

  render () {
    const { values, show } = this.state;
    const { num, monitoring } = this.props;

    const errors = {
      'err_total': ~~parseInt(values.total) < ~~parseInt(values.d),
      'err_d': ~~parseInt(values.d) >= ~~parseInt(values.e) && ~~parseInt(values.d) >= ~~parseInt(values.h) && ~~parseInt(values.d) >= ~~parseInt(values.mm) && ~~parseInt(values.d) >= ~~parseInt(values.mn) && ~~parseInt(values.d) >= ~~parseInt(values.s) && ~~parseInt(values.d) >= ~~parseInt(values.p) && ~~parseInt(values.d) >= ~~parseInt(values.o),
    };

    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Численность студентов

          <div className={classNames(styles.label, { [styles.ok]: Object.values(values).filter(v => v).length == Object.values(values).length })}>
            {Object.values(values).filter(v => v).length} из {Object.values(values).length}
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.desc}>
            Указывать данные на дату заполнения мониторинга. Допускается указание данных от 1 октября {monitoring.current_year}.
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.1. Общая численность обучающихся в вузе
            </div>

            <div className={form.input}>
              <VerificateInput name="total" value={values.total} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2. Численность обучающихся с ОВЗ и инвалидностью
            </div>
            {errors['err_total'] &&
              <div className={form.error}>Ошибка в данных. Численность обучающихся с ОВЗ и инвалидностью не может превышать общую численность обучающихся в вузе.</div>
            }
            {!errors['err_d'] &&
              <div className={form.error}>Ошибка в данных. Одно из введенных значений превышает допустимое.</div>
            }

            <div className={form.input}>
              <VerificateInput name="d" value={values.d} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3. Численность обучающихся с нарушением зрения
            </div>

            <div className={form.input}>
              <VerificateInput name="e" value={values.e} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4. Численность обучающихся с нарушением слуха
            </div>

            <div className={form.input}>
              <VerificateInput name="h" value={values.h} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.5. Численность обучающихся с нарушением опорно-двигательного аппарата (мобильные)
            </div>

            <div className={form.input}>
              <VerificateInput name="mm" value={values.mm} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.6. Численность обучающихся с нарушением опорно-двигательного аппарата (на кресле-коляске)
            </div>

            <div className={form.input}>
              <VerificateInput name="mn" value={values.mn} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.7. Численность обучающихся с соматическими заболеваниями
            </div>

            <div className={form.input}>
              <VerificateInput name="s" value={values.s} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.8. Численность обучающихся с психическими заболеваниями
            </div>

            <div className={form.input}>
              <VerificateInput name="p" value={values.p} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.9. Численность обучающихся с ОВЗ и инвалидностью без указания диагноза
            </div>

            <div className={form.input}>
              <VerificateInput name="o" value={values.o} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.10. Количество студентов с инвалидностью и ОВЗ, зачисленных на 1 курс в {monitoring.current_year} г. имеющих  среднее профессиональное образование.
            </div>

            <div className={form.input}>
              <VerificateInput name="pe" value={values.pe} onChange={this.handleInputChange} />
            </div>
          </div>



        </div>
      </div>
    );
  }
}

Numbers.defaultProps = {
  prefix: 'numbers',
  num: '1',
};

export default Numbers
