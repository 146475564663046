import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import classNames from 'classnames'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { EditorRich } from '../../Draft'

import Link, { path } from '../../Routes'
import I18n from '../../I18n'
import Loaf from '../../Loaf/Loaf'
import { useForm, Errors } from '../../Form'
import Files from '../../Attachments/Files'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
import form from '../../Form.module.css'

export default function Form () {
  const { id } = useParams()
  const history = useHistory()

  const [stage, setStage] = useState()

  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    send,
    setSend,
    cancelToken
  } = useForm()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(
        path('edit_account_stage_path', { id }, true),
        {
          cancelToken: cancelToken.current.token
        }
      )

      setStage(data.stage)

      data.values.desc = data.values.desc ? EditorState.createWithContent(convertFromRaw(data.values.desc)) : EditorState.createEmpty()
      setValues(data.values)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [id])

  const handleSubmit = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    await axios.patch(
      path('account_stage_path', { id }),
      { stage: {
        ...values,
        desc: values.desc.getCurrentContent().hasText() ? convertToRaw(values.desc.getCurrentContent()) : null
      } },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const handleFilesChanged = files => {
    setValues({
      ...values,
      attachments_attributes: [...files.values()].map(file => ({ id: file.id, title: file.title, section: file.section })),
      attachment_ids: [...files.values()].map(file => file.id)
    })
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>

          {stage && stage.stageable &&
            <>
              {stage.stageable.plan &&
                <Link to="account_plan_path" params={{ id: stage.stageable.plan.id }}>{stage.stageable.plan.title}</Link>
              }

              <Link to={`account_${stage.stageable.stageable_type}_path`} params={{ id: stage.stageable.id }}>{stage.stageable.title}</Link>
            </>
          }
        </Loaf>

        {values &&
          <>
            <div className={page.title}>
              <h1>
                {values.title}
              </h1>

              <Helmet>
                <title>{values.title}</title>
              </Helmet>
            </div>

            <div className={form.tight}>
              <form onSubmit={handleSubmit}>
                <div className={form.el}>
                  <label>
                    <div className={form.label}>
                      Название раздела
                    </div>

                    <div className={form.input}>
                      <input
                        type="text"
                        name="title"
                        value={values.title}
                        onChange={handleInputChange}
                      />
                    </div>
                  </label>

                  <Errors errors={errors.title} />
                </div>

                <div className={form.el}>
                  <label>
                    <div className={form.label}>
                      Текст раздела
                    </div>

                    <div className={form.draft}>
                      <EditorRich
                        editorState={values.desc}
                        onEditorStateChange={(editorState) => setValues({ ...values, desc: editorState })}
                      />
                      {/* <Editor
                        editorState={values.desc}
                        onChange={(editorState) => setValues({ ...values, desc: editorState })}
                      /> */}
                    </div>
                  </label>
                </div>

                <Files
                  files={stage && stage.attachments}
                  onFilesChanged={handleFilesChanged}
                  sections={['video', 'subtitles']}
                />

                <div className={form.submit}>
                  <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                    Сохранить
                  </button>
                </div>
              </form>
            </div>
          </>
        }
      </div>
    </div>
  )
}
