import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { path } from '../Routes.js.erb';

import RateListItem from './RateListItem';

import styles from './RateList.module.css';
import buttons from '../Buttons.module.css';
import form from '../Form.module.css';

class RateList extends React.Component {
  state = {
    desc: '',
    cause: null,
    categories: {},
  }

  handleActiveChange = (category_id, value) => {
    this.setState(state => ({
      categories: {...state.categories, [category_id]: value}
    }));
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      [name]: value
    }));
  }

  handleSubmit = (e) => {
    if(this._canSubmit()) {
      this.state.send = true;

      axios({
        method: 'post',
        url: path('rates_path'),
        data: {
          rate: {
            desc: this.state.desc,
            cause: this.state.cause,
            ratable_id: this.props.ratable.id,
            ratable_type: this.props.ratable.type,
            rate_items_attributes: Object.entries(this.state.categories).map(ob => ({ category_id: ob[0], value: ob[1] }))
          }
        },
      }).then(
        res => {
          this.props.onRateSave();
        }
      );
    }

    e.preventDefault();
  }

  _canSubmit = () => {
    return Object.entries(this.state.categories).length >= this.props.categories.length;
  }

  render () {
    const { categories } = this.props;
    if(!categories) return null;

    let save = (!this.props.rated && !this.props.not_rated) || this.props.not_rated

    return (
      <div className={styles.root}>
        {this.props.rated && !this.props.not_rated &&
          <h3>Результаты оценки</h3>
        }
        {this.props.not_rated &&
          <h3>Самостоятельная оценка</h3>
        }
        {categories.map(category =>
          <RateListItem key={category.id} rated={this.props.rated} can_rate={this.props.can_rate} results={this.props.results} category={category} not_rated={this.props.not_rated} onActiveChange={this.handleActiveChange} />
        )}

        {save &&
          <form className={form.root} onSubmit={this.handleSubmit}>
            <div className={form.elem}>
              <div className={form.label}>
                <label>
                  Примечание
                </label>
              </div>

              <div className={form.input}>
                <textarea name="desc" value={this.state.desc} rows="5" onChange={this.handleInputChange} />
              </div>
            </div>

            {this.props.not_rated &&
              <div className={form.elem}>
                <div className={form.label}>
                  Причина оценки
                </div>

                <div className={form.input}>
                  <select name="cause" onChange={this.handleInputChange} value={this.state.cause}>
                    <option />
                    <option value={0}>эксперт не оценил</option>
                    <option value={1}>не загружены файлы</option>
                    <option value={2}>не исправлено замечание эксперта</option>
                  </select>
                </div>
              </div>
            }

            <div className={form.submit}>
              <input type="submit" value={this.props.button} className={buttons.main} disabled={!this._canSubmit()} />
            </div>
          </form>
        }
      </div>
    );
  }
}

export default RateList
