import React, { useContext, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import Current from '../Current'

import { Routes, path } from '../Routes'

import UsersRoles from './UsersRoles/UsersRoles'

export default function Admin () {
  const history = useHistory()
  const { user } = useContext(Current)

  useEffect(() => {
    !user && history.push(path('root_path'))
  }, [user])

  return (
    <Switch>
      <Route exact path={decodeURIComponent(Routes.admin_users_roles_path)} component={UsersRoles} />
    </Switch>
  )
}
