import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'

import I18n from '../I18n'
import Link, { path } from '../Routes'

import styles from './Show.module.css'
import page from '../Page.module.css'

export default function Show () {
  const cancelToken = useRef(axios.CancelToken.source())
  const { id } = useParams()

  const [monitoring, setMonitoring] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('monitoring_path', { id }, true), { cancelToken: cancelToken.current.token })

      setMonitoring(data.monitoring)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        {monitoring &&
          <div>
            {monitoring.regions.map(region =>
              <div key={region.id}>
                <h2>
                  {region.label}
                </h2>

                <div className={styles.schools}>
                  {monitoring.schools.filter(s => s.region_id === region.id).map(school =>
                    <div key={school.id} className={styles.school}>
                      <div className={styles.title}>
                        <div className={classNames(styles.state, styles[school.report ? school.report.state : 'empty'])}>
                          {I18n.t(`report.state.${school.report ? school.report.state : 'empty'}`)}
                        </div>

                        <Link to="school_path" params={{ id: school.id }}>
                          {school.label}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  )
}
