import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Filters from '../../Filters/Filters'

import { path } from '../../Routes'
import Datec from '../../Datec'

import list from '../List.module.css'

class List extends Component {
  render () {
    const { items, filters } = this.props
    const { history, location } = this.props

    return (
      <div className={list.root}>
        <div className={list.filters}>
          {filters &&
            <Filters {...this.props} filters={filters} history={history} location={location} />
          }
        </div>

        {items && items.length > 0 &&
          <div className={list.list}>
            {items.map((item, _) =>
              <Link to={path(`account_${item.type}_path`,{ id: item.id })} key={item.id} className={list.item}>
                {item.state &&
                  <div className={list.state}>
                    {item.media_items > 0 &&
                      <>
                        <div>
                          Ссылок на СМИ:
                        </div>

                        <div className={list.current}>
                          {item.media_items}
                        </div>
                      </>
                    }
                    {item.media_items === 0 &&
                      <div>
                        Нет ссылок на СМИ
                      </div>
                    }
                  </div>
                }

                <div className={list.title}>
                  #{_ + 1} • {item.title}
                </div>

                <div className={list.desc}>
                  <div className={list.date}>
                    <Datec time={item.created_at} />
                    {item.updated_at &&
                      <>
                        (<Datec time={item.updated_at} />)
                      </>
                    }
                  </div>

                  {item.rumc &&
                    <div className={list.rumc}>
                      {item.rumc.title}
                    </div>
                  }
                </div>
              </Link>
            )}
          </div>
        }

      </div>
    )
  }
}

export default List
