import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../../Routes'

import Index from './Index'
import Show from './Show'
import Form from './Form'

export default function Courses () {
  return (
    <Switch>
      <Route path={[Routes.edit_account_facility_path, Routes.new_account_facility_path]}>
        <Form />
      </Route>

      <Route path={Routes.account_facility_path} exact strict>
        <Show />
      </Route>

      <Route path={Routes.account_facilites_path} exact strict>
        <Index />
      </Route>
    </Switch>
  )
}
