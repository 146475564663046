import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

import Link, { path } from '../Routes'
import I18n from '../I18n'
import Loaf from '../Loaf/Loaf'
import { Errors, useForm } from '../Form'
import Datec from '../Datec'

import Social from '../Auth/Social'
import Current from '../Current'

import styles from './Form.module.css'
import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import form from '../Form.module.css'

export default function Form () {
  const { event_id: eventId, id } = useParams()
  const { user } = useContext(Current)
  const history = useHistory()

  const { values, setValues, send, errors, setSend, setErrors, cancelToken, handleInputChange } = useForm()

  const [audience, setAudience] = useState()
  const [event, setEvent] = useState()
  const [eventRegistration, setEventRegistration] = useState()
  const [dictionaries, setDictionaries] = useState()

  const [needAuth, setNeedAuth] = useState(false)


  const _fetch = async () => {
    const { data } = await axios.get(
      id ? path('edit_event_event_registration_path', { event_id: eventId, id }, true) : path('new_event_event_registration_path', { event_id: eventId }, true),
      {
        cancelToken: cancelToken.current.token
      }
    )

    setEventRegistration(data.event_registration)
    setEvent(data.event)
    setValues(data.values)
    setDictionaries(data.dictionaries)
  }

  useEffect(() => {
    // redirect(path('login_path', {query: {redirect_to: path('new_event_event_registration_path', { event_id: eventId }, true)}}))

    if (!user) {
      setNeedAuth(true)
    } else {
      _fetch()

      return function cleanup () {
        cancelToken.current.cancel()
      }
    }
  }, [eventId])

  const loadSchools = (input, callback) => {
    callback(dictionaries.schools.filter(school => school.label.toLowerCase().includes(input.toLowerCase())).slice(0, 100))
  }

  const handleUserChange = newUserValues => {
    setValues({ ...values, user_attributes: newUserValues })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    if (id) {
      handleUpdate()
    } else {
      handleCreate()
    }
  }

  const handleCreate = async () => {
    await axios.post(
      path('event_event_registrations_path', { event_id: eventId }),
      { event_registration: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const handleUpdate = async () => {
  //   await axios.patch(
  //     path('account_facility_type_path', { id }),
  //     { facility_type: values },
  //     { cancelToken: cancelToken.current.token }
  //   ).then(res => {
  //     res.headers.location && history.push(res.headers.location)
  //   }).catch(error => {
  //     setErrors(error.response.data)
  //     setSend(false)
  //   })
  }


  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="events_path">{I18n.t('nav.events')}</Link>
          {event &&
            <Link to="event_path" params={{ id: event.id }}>
              {event.title}
            </Link>
          }

        </Loaf>

        <div className={page.title}>
          <h1>
            {id ? 'Редактирование' : 'Регистрация на событие' }
          </h1>

          {event &&
            <div className={styles.event}>
              <h3>
                {event.title}
              </h3>

              <p>Дата и время: <Datec time={event.started_at} /></p>
            </div>
          }
        </div>

        {/* {eventRegistration && eventRegistration.time_is_out &&
          <p>
            Увы, но время регистрации на событие прошло.
          </p>
        } */}

        {needAuth &&
          <>
            <p>
              Чтобы зарегистрироваться на собитие необходимо войти или зарегистрироваться, это займет не более одной минуты,
              нужно только указать адрес своей электронной почты или войти через соцсети.
            </p>

            <p>
              <Link to="login_path" params={{ query: { redirect_to: encodeURI(path('new_event_event_registration_path', { event_id: eventId }, false)) } }} className={buttons.main}>
                Войти
              </Link>
            </p>
          </>
        }

        {values && dictionaries &&
          <div className={styles.d_flex}>
            <div className={styles.left}>
              <div className={form.auth}>
                {/* {event &&
                  <div className={styles.event}>
                    <h3>
                      {event.title}
                    </h3>

                    <p>Дата и время: <Datec time={event.started_at} /></p>
                  </div>
                } */}

                <form onSubmit={handleSubmit}>

                  {!user &&
                    <>
                      <Social or />

                      <User
                        user={user}
                        errors={errors}
                        onValuesChange={handleUserChange}
                      />
                    </>
                  }

                  <div className={form.el}>
                    <label>
                      <div className={form.label}>
                        Имя
                      </div>

                      <div className={form.input}>
                        <input
                          type="text"
                          name="first_name"
                          value={values.first_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </label>

                    <Errors errors={errors.first_name} />
                  </div>

                  <div className={form.el}>
                    <label>
                      <div className={form.label}>
                        Фамилия
                      </div>

                      <div className={form.input}>
                        <input
                          type="text"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </label>

                    <Errors errors={errors.last_name} />
                  </div>

                  <div className={form.el}>
                    <label>
                      <div className={form.label}>
                        Отчество
                      </div>

                      <div className={form.input}>
                        <input
                          type="text"
                          name="middle_name"
                          value={values.middle_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </label>

                    <div className={form.hint}>
                      Необязательно для заполнения
                    </div>

                    <Errors errors={errors.middle_name} />
                  </div>

                  <div className={form.el}>
                    <label>
                      <div className={form.label}>
                        Регион участника
                      </div>

                      <div className={form.input}>
                        <Select
                          classNamePrefix="react-select"
                          value={dictionaries.regions.find(region => region.id === values.region_id)}
                          getOptionValue={option => option.id}
                          noOptionsMessage={() => 'Ничего не найдено'}
                          options={dictionaries.regions}
                          placeholder="Выберите регион.."
                          onChange={value => setValues({ ...values, region_id: value.id })}
                        />
                      </div>
                    </label>

                    <Errors errors={errors.region} />
                  </div>

                  <div className={form.el}>
                    <label>
                      <div className={form.label}>
                        Категория участника
                      </div>

                      <div className={form.input}>
                        <Select
                          isSearchable={false}
                          classNamePrefix="react-select"
                          value={dictionaries.audiences.find(a => a.id === values.audience_id)}
                          getOptionValue={option => option.id}
                          noOptionsMessage={() => 'Ничего не найдено'}
                          options={dictionaries.audiences}
                          placeholder="Выберите категорию.."
                          onChange={value => {
                            setAudience(value.model)
                            setValues({ ...values, audience_id: value.id })
                          }}
                        />
                      </div>
                    </label>

                    <Errors errors={errors.audience} />
                  </div>

                  {audience === 'School' &&
                    <div className={form.el}>
                      <label>
                        <div className={form.label}>
                          Вуз
                        </div>

                        <div className={form.input}>
                          <AsyncSelect
                            classNamePrefix="react-select"
                            noOptionsMessage={({ inputValue }) => inputValue === '' ? 'Введите часть названия вуза' : 'Нет доступных результатов'}
                            value={dictionaries.schools.find(a => a.id === values.school_id)}
                            getOptionValue={option => option.id}
                            loadOptions={loadSchools}
                            placeholder="Выберите вуз.."
                            onChange={value => setValues({ ...values, school_id: value.id })}
                          />
                        </div>
                      </label>

                      {errors.school &&
                        <Errors errors={errors.school}/>
                      }

                      <div className={form.hint}>
                        Начните вводить название вуза чтобы отобразились доступные варианты. Количество результатов поиска ограничено 100 вузами.
                      </div>
                    </div>
                  }

                  {audience === 'Rumc' &&
                    <div className={form.el}>
                      <label>
                        <div className={form.label}>
                          Ресурсный центр
                        </div>

                        <div className={form.input}>
                          <Select
                            classNamePrefix="react-select"
                            noOptionsMessage={() => 'Ничего не найдено'}
                            getOptionValue={option => option.id}
                            value={dictionaries.rumcs.find(a => a.id === values.rumc_id)}
                            options={dictionaries.rumcs}
                            placeholder="Выберите РУМЦ.."
                            onChange={value => setValues({ ...values, rumc_id: value.id })}
                          />
                        </div>
                      </label>

                      {errors.rumc &&
                        <Errors errors={errors.rumc}/>
                      }
                    </div>
                  }

                  <div className={form.submit}>
                    <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                      Подтвердить регистрацию
                    </button>
                  </div>

                  <Errors errors={errors.created_at} />
                </form>
              </div>
            </div>
            <div className={styles.registration_image} />

          </div>
        }
      </div>
    </div>
  )
}

User.propTypes = {
  user: PropTypes.object,
  errors: PropTypes.object,
  onValuesChange: PropTypes.func
}

function User ({ user, errors, onValuesChange }) {
  const [values, setValues] = useState({
    email: ''
  })

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    onValuesChange && onValuesChange(values)
  }, [values])

  return (
    <div>
      <div className={form.el}>
        <label>
          <div className={form.label}>
            Электронная почта
          </div>

          <div className={form.input}>
            <input
              type="text"
              name="email"
              value={values.email}
              onChange={handleInputChange}
            />
          </div>
        </label>

        <Errors errors={errors['user.email']} />
      </div>
    </div>
  )
}
