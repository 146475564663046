import { makeAutoObservable, runInAction, toJS } from 'mobx';
import axios from 'axios';
import { path } from '../../Routes';
import { DefaultFilterValue, FilterType } from './Filter/constants';

export class SearchBarViewModel {
    /**
     * @type {Object.<string, Array>}
     * Контент для фильтров
     */
    filtersData = {};
    /**
     * @type {Object.<string, Array>}
     * Доступные для выбора фильтры
     */
    availbleFilters = {};
    /**
     * @type {Object.<string, Array>}
     * Примененные фильтры
     */
    appliedFilters = {};
    /**
     * @type {Object.<string, Array>}
     * Измененнные пользователем фильтры. Используется для временного хранения перед примененением
     */
    changedFilters = DefaultFilterValue;
    /**
     * @type {boolean}
     * Индикатор загрузки/обновления фильтров
     */
    onLoadingFilters = false;

    /**
     * Init
     * @param {Object.<string, Array>} presetFilters
     */
    constructor() {
        makeAutoObservable(this);
    }

    onStart(presetFilters) {
        if (Object.keys(this.filtersData).length == 0) {
            this.firstLoading(presetFilters);
            if (presetFilters) {
                runInAction(() => {
                    this.appliedFilters = presetFilters;
                    this.changedFilters = presetFilters;
                });
            }
        }
    }

    getAvailableFilters = () => {
        return toJS(this.availbleFilters);
    };

    getFiltersData = () => {
        return toJS(this.filtersData);
    };

    getChangedFilters = () => {
        return toJS(this.changedFilters);
    };

    getAppliedFilters = () => {
        return toJS(this.appliedFilters);
    };

    firstLoading = async (presetFilters) => {
        const fullFiltersData = await this.fetchFilters();
        var availbleFilters = fullFiltersData;
        if (presetFilters) {
            availbleFilters = await this.fetchFilters(presetFilters);
        }
        runInAction(() => {
            this.filtersData = fullFiltersData;
            this.availbleFilters = this.convertAvailableFilters(availbleFilters);
        });
    };

    convertAvailableFilters = (filters) => {
        var availableFiltersIds = {};
        for (const key of Object.keys(filters || {})) {
            const availableIdsByType = filters[key].map((item) => {
                return item.id;
            });
            availableFiltersIds[key] = availableIdsByType;
        }
        return availableFiltersIds;
    };

    fetchFilters = async (filterParams) => {
        runInAction(() => {
            this.onLoadingFilters = true;
        });
        const res = await axios.get(path('filters_schools_path', {}, true), { params: filterParams || {} });
        runInAction(() => {
            this.onLoadingFilters = false;
        });
        return res.data.filters || {};
    };

    onChangeSomeFilterValue = async (type, values) => {
        var changedFiltersCopy = { ...this.changedFilters };
        changedFiltersCopy[type] = values;

        //Для поискового запроса не делаем обновление доступных фильтров
        if (type == FilterType.Search) {
            runInAction(() => {
                this.changedFilters = changedFiltersCopy;
            });
            return;
        }

        const newAvailableFilters = await this.fetchFilters(changedFiltersCopy);
        runInAction(() => {
            this.availbleFilters = this.convertAvailableFilters(newAvailableFilters);
            this.changedFilters = changedFiltersCopy;
        });
    };

    resetChangedFilters = () => {
        runInAction(() => {
            this.availbleFilters = this.convertAvailableFilters(this.filtersData);
            this.changedFilters = DefaultFilterValue;
        });
    };

    resetAllFilters = () => {
        runInAction(() => {
            this.availbleFilters = this.convertAvailableFilters(this.filtersData);
            this.changedFilters = DefaultFilterValue;
            this.appliedFilters = DefaultFilterValue;
        });
    };
}
