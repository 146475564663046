import React from 'react';
import { Helmet } from 'react-helmet-async';

import I18n from '../../I18n'

import styles from './Common.module.css';

export default function Committee() {
  return (
    <div className={styles.root}>
      <Helmet>
        <title>{I18n.t('events.tomorrow.committee')}</title>
      </Helmet>
      <h2>
        {I18n.t('events.tomorrow.committee')}
      </h2>

      <h3>Организационный комитет (2024 г.) на официальном сайте Конкурса → <a href="https://profzavtra.nstu.ru/" target="_blank">https://profzavtra.nstu.ru/</a></h3>

      <h3>Организационный комитет (2023 г.) на официальном сайте Конкурса: <a href='https://rumc.ncfu.ru/' target="_blank">https://rumc.ncfu.ru/</a></h3>

      <h3>Организационный комитет (2022 г.) на официальном сайте Конкурса: <a href='https://www.utmn.ru/professional-tomorrow/' target="_blank">https://www.utmn.ru/professional-tomorrow/</a></h3>

      <h3>Организационный комитет (2021 г.) (
        <a href="https://rumc.cfuv.ru/node/164" target="_blank">https://rumc.cfuv.ru/node/164</a>):
      </h3>
      <strong>Сопредседатели организационного комитета</strong>
      <ul>
        <li>Трухановская Наталья Сергеевна – директор Департамента координации деятельности организаций высшего образования Министерства науки и высшего образования Российской Федерации, г Москва;</li>
        <li>Фалалеев Андрей Павлович – ректор ФГАОУ ВО «Крымский федеральный университет имени В И Вернадского», г Симферополь;</li>
        <li>Цвиринько Ирина Николаевна - проректор по учебной и методической деятельности ФГАОУ ВО «Крымский федеральный университет имени В И Вернадского», г Симферополь;</li>
        <li>Горбунова Наталья Владимировна - директор Гуманитарно-педагогической академии (филиал) ФГАОУ ВО «Крымский федеральный университет имени ВИ Вернадского», г Ялта</li>
      </ul>
      <strong>Члены организационного комитета</strong>
      <ul>
        <li>Алехина Светлана Владимировна - проректор по инклюзивному образованию, директор Института проблем инклюзивного образования ФГБОУ ВО «Московский государственный психолого-педагогический университет», г Москва;</li>
        <li>Башмакова Светлана Борисовна - руководитель Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Вятский государственный университет», г Киров;</li>
        <li>Барсукова Анжелика Владимировна - директор Института социальных проектов и цифровых технологий ФГБОУ ВО «Московский государственный институт пищевых производств», г Москва;</li>
        <li>Бикбулатова Альбина Ахатовна – проректор по учебно-воспитательной работе ФГБОУ ВО «Московский государственный университет пищевых производств», руководитель Проектного офиса Минобрнауки России по сети Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья, г Москва;</li>
        <li>Богинская Юлия Валериевна – доктор педагогических наук, доцент, заведующая кафедрой социально-педагогических технологий и педагогики девиантного поведения, руководитель Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Крымский федеральный университет имени ВИ Вернадского», г Ялта;</li>
        <li>Борозинец Наталья Михайловна - кандидат психологических наук, доцент, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Северокавказский федеральный университет», г Ставрополь;</li>
        <li>Винокурова Ирина Викторовна – заместитель директора Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Нижегородский государственный педагогический университет имени Козьмы Минина (Мининский университет)», ответственный за организацию конкурса «Профессиональное завтра», г Нижний Новгород;</li>
        <li>Бузолина Анастасия Николаевна - начальник отдела инклюзивной молодежной политики, заместитель директора по молодежной политике Института психологии и педагогики ФГАОУ ВО «Тюменский государственный университет», г Тюмень;</li>
        <li>Гутерман Лариса Александровна – кандидат биологических наук, доцент, руководитель Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Южный федеральный университет», г Ростов-на-Дону;</li>
        <li>Денисова Ольга Александровна – доктор педагогических наук, профессор, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья Северо-Западного Федерального округа ФГБОУ ВО «Череповецкий государственный университет», г Череповец;</li>
        <li>Иванов Василий Александрович - директор по образовательной политике ФГАОУ ВО «Северокавказский федеральный университет», г Ставрополь;</li>
        <li>Карапетянц Ирина Владимировна – доктор исторических наук, профессор директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Российский университет транспорта», г Москва;</li>
        <li>Касацкая Ирина Федоровна - кандидат исторических наук, специалист по учебно-методической работе -ой категории Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья Российский государственный педагогический университет им АИ Герцена, г Санкт-Петербург;</li>
        <li>Константинов Михаил Дмитриевич - заместитель директора Головного учебно-исследовательского и методический центра имени Баумана, г Москва;</li>
        <li>Краснов Евгений Владимирович – директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Государственный университет управления», г Москва;</li>
        <li>Краснопевцева Татьяна Федоровна – директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Нижегородский государственный педагогический университет имени Козьмы Минина (Мининский университет)», г Нижний Новгород;</li>
        <li>Крестьянинова Ольга Александровна - заместителя начальника Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО Рязанский государственный медицинский университет имени академика ИП Павлова Минздрава России, г Рязань;</li>
        <li>Луковенко Татьяна Геннадьевна – кандидат педагогических наук, доцент, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Тихоокеанский государственный университет», заведующая кафедрой теории и методики педагогического и дефектологического образования Педагогического института ТОГУ, г Хабаровск;</li>
        <li>Махно Павел Викторович – кандидат физико-математических наук, доцент, проректор по образовательной деятельности и информационным технологиям «Южный федеральный университет», г Ростов-на-Дону;</li>
        <li>Моцовкина Елена Владимировна - кандидат педагогических наук, доцент, специалист Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Крымский федеральный университет имени ВИ Вернадского», г Ялта;</li>
        <li>Митрофанова Елена Александровна – доктор экономических наук, профессор, специалист Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Государственный университет управления», г Москва;</li>
        <li>Митрофанов Евгений Петрович - кандидат экономических наук, доцент, заведующий кафедрой информационных технологий и прикладной математики, координатор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУИ ВО Московского государственного гуманитарно-экономического университета, г Москва;</li>
        <li>Осьмук Людмила Алексеевна – доктор социологических наук, профессор, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья, профессор кафедры социальной работы и социальной антропологии ФГБОУ ВО «Новосибирский государственный технический университет», г Новосибирск;</li>
        <li>Панюкова Светлана Валерьевна - доктор педагогических наук, профессор руководитель    Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Российского государственного социального университета», г Москва;</li>
        <li>Романенкова Дарья Феликсовна - кандидат педагогических наук, доцент, начальник Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Челябинский государственный университет», г Челябинск;</li>
        <li>Саитгалиева Гузель Газимовна – директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Московский государственный психолого-педагогический университет», г Москва</li>
      </ul>
      <h3>Организационный комитет (2020 г.) (
        <a href="https://guu.ru/об-университете/доступная-среда-в-гуу/деятельность-румц-2020/конкурс-профессиональное-завтра/организационный-комитет/" target="_blank">https://guu.ru/об-университете/доступная-среда-в-гуу/деятельность-румц-2020/конкурс-профессиональное-завтра/организационный-комитет/</a>):
      </h3>
      <strong>Сопредседатели организационного комитета</strong>
      <ul>
        <li>Трухановская Наталья Сергеевна – директор Департамента координации деятельности организаций высшего образования Министерства науки и высшего образования Российской Федерации;</li>
        <li>Троицкий Александр Витальевич – врио ректора ФГБОУ ВО «Государственный университет управления», г Москва</li>
      </ul>
      <strong>Члены организационного комитета</strong>
      <ul>
        <li>Борозинец Наталья Михайловна – кандидат психологических наук, доцент, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Северо-Кавказский федеральный университет», заведующий кафедрой дефектологии СКФУ, г Ставрополь;</li>
        <li>Васина Людмила Григорьевна – ведущий специалист Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Московский государственный психолого-педагогический университет», г Москва;</li>
        <li>Винокурова Ирина Викторовна – заместитель директора Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Нижегородский государственный педагогический университет имени Козьмы Минина (Мининский университет)», ответственный за организацию конкурса «Профессиональное завтра», г Нижний Новгород;</li>
        <li>Волосникова Людмила Михайловна – кандидат исторических наук, доцент, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Тюменский государственный университет», г Тюмень;</li>
        <li>Гатилова Ольга Николаевна – специалист Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Государственный университет управления», ответственный за организацию конкурса «Профессиональное завтра», г Москва;</li>
        <li>Глузман Юлия Валериевна – руководитель Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Крымский федеральный университет имени ВИ Вернадского», доктор педагогических наук, доцент, заведующая кафедрой социально-педагогических технологий и педагогики девиантного поведения, г Ялта;</li>
        <li>Глухова Ольга Айваровна – специалист Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья Северо-Западного Федерального округа ФГБОУ ВО «Череповецкий государственный университет», ответственный за организацию конкурса «Профессиональное завтра», гЧереповец;</li>
        <li>Гришаева Светлана Алексеевна – кандидат психологических наук, доцент, специалист Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Государственный университет управления», г Москва;</li>
        <li>Гутерман Лариса Александровна – кандидат биологических наук, доцент, руководитель Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Южный федеральный университет», г Ростов-на-Дону;</li>
        <li>Денисова Ольга Александровна – доктор педагогических наук, профессор, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья Северо-Западного Федерального округа ФГБОУ ВО «Череповецкий государственный университет», гЧереповец;</li>
        <li>Дмитракова Василиса Васильевна – социальный педагог Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Тюменский государственный университет», ответственный за организацию конкурса «Профессиональное завтра» , г Тюмень;</li>
        <li>Еремкина Яна Алексеевна – специалист по учебно-методической работе Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Южный федеральный университет», г Ростов-на-Дону;</li>
        <li>Краснов Евгений Владимирович – директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Государственный университет управления», г Москва;</li>
        <li>Краснопевцева Татьяна Федоровна – директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Нижегородский государственный педагогический университет имени Козьмы Минина (Мининский университет)», г Нижний Новгород;</li>
        <li>Лузгина Ксения Сергеевна – ответственный секретарь Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУИ ВО «Московский государственный гуманитарно-экономический университет», ответственный за организацию конкурса “Профессиональное завтра”, г Москва;</li>
        <li>Луковенко Татьяна Геннадьевна – кандидат педагогических наук, доцент, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Тихоокеанский государственный университет», заведующая кафедрой теории и методики педагогического и дефектологического образования Педагогического института ТОГУ, г Хабаровск;</li>
        <li>Махно Павел Викторович – кандидат физико-математических наук, доцент, проректор по образовательной деятельности и информационным технологиям «Южный федеральный университет», г Ростов-на-Дону;</li>
        <li>Митрофанова Елена Александровна – доктор экономических наук, профессор, специалист Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Государственный университет управления», г Москва;</li>
        <li>Мозговая Татьяна Павловна – юрисконсульт Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Новосибирский государственный технический университет», ответственный за организацию конкурса «Профессиональное завтра» г Новосибирск;</li>
        <li>Осьмук Людмила Алексеевна – доктор социологических наук, профессор, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Новосибирский государственный технический университет», г Новосибирск;</li>
        <li>Родина Мария Александровна – специалист -й категории Ресурсного учебно-методического центр по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Южный федеральный университет», г Ростов-на-Дону;</li>
        <li>Рубцов Виталий Владимирович – Президент ФГБОУ ВО «Московский государственный психолого-педагогический университет», г Москва;</li>
        <li>Саитгалиева Гузель Газимовна – директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Московский государственный психолого-педагогический университет», г Москва;</li>
        <li>Смирнова Марина Николаевна – профконсультант Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Тихоокеанский государственный университет», ответственный за организацию конкурса “Профессиональное завтра”, г Хабаровск;</li>
        <li>Сорокин Николай Юрьевич – кандидат технических наук, доцент, проректор ФГБОУ ВО «Тихоокеанский государственный университет», г Хабаровск;</li>
        <li>Соцков Виталий Валерьевич – заместитель директора Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Государственный университет управления», г Москва;</li>
        <li>Шмелева Надежда Александровна – координатор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУИ ВО «Московский государственный гуманитарно-экономический университет», кандидат педагогических наук, начальник отдела проектной деятельности, г Москва</li>
      </ul>
      <h3>Организационный комитет в 2019 году:</h3>



      <strong>
        Сопредседатели организационного комитета
      </strong>
      <ul>
        <li>
          Боровская Марина Александровна – заместитель Министра Министерства высшего образования и науки Российской Федерации, г.Москва;
        </li>

        <li>
          Чернышева Ольга Евгеньевна – директор департамента государственной молодежной политики и социальных проектов в сфере высшего образования Министерства науки и высшего образования Российской Федерации, г.Москва;
        </li>

        <li>
          Марголис Аркадий Аронович – врио ректора ФГБОУ ВО «Московский государственный психолого-педагогический университет», г. Москва;
        </li>

        <li>
          Рубцов Виталий Владимирович – Президент ФГБОУ ВО «Московский государственный психолого-педагогический университет», г. Москва.
        </li>
      </ul>

      <strong>
        Члены организационного комитета Конкурса
      </strong>

      <ul>
        <li>
          Алехина Светлана Владимировна - проректор по инклюзивному образованию ФГБОУ ВО «Московский государственный психолого-педагогический университет», г. Москва;
        </li>

        <li>
          Cаитгалиева Гузель Газимовна –кандидат социологических наук, доцент, директор Ресурсного учебно-методического центра по обучению лиц с ограниченными возможностями и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Московский государственный психолого-педагогический университет», Москва;
        </li>

        <li>
          Васина Людмила Григорьевна - ведущий специалист ФГБОУ ВО «Московский государственный психолого-педагогический университет», г. Москва, ответственный за организацию конкурса «Профессиональное завтра»;
        </li>

        <li>
          Иванова Татьяна Семёновна – руководитель образовательных программ ФГБОУ ВО «Московский государственный психолого-педагогический университет», г. Москва;
        </li>

        <li>
          Андропов Александр Петрович – кандидат педагогических наук, доцент, профессор, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья РГПУ(ЛГПИ) им. А. И. Герцена;
        </li>

        <li>
          Башмакова Светлана Борисовна - кандидат педагогических наук, доцент, руководитель Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Вятский государственный университет» (ВятГУ), г. Киров;
        </li>

        <li>
          Бирюков Евгений Сергеевич – кандидат экономический наук, проректор по учебной работе, начальник Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Челябинский государственный университет», г.Челябинск;
        </li>

        <li>
          Борозинец Наталья Михайловна - кандидат психологических наук, доцент, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Северо-Кавказский федеральный университет», заведующий кафедрой дефектологии СКФУ, г. Ставрополь;
        </li>

        <li>
          Волков Алексей Васильевич - директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Московский государственный технический университет имени Н.Э. Баумана (национальный исследовательский университет)» (МГТУ им. Н.Э. Баумана)», г. Москва;
        </li>

        <li>
          Волосникова Людмила Михайловна – кандидат исторических наук, доцент директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Тюменский государственный университет», г. Тюмень;
        </li>

        <li>
          Гутерман Лариса Александровна – кандидат биологических наук, доцент, руководитель Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Южный федеральный университет», г. Ростов-на-Дону;
        </li>

        <li>
          Дежнев Максим Сергеевич – директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Российский государственный университет физической культуры, спорта, молодежи и туризма (ГЦОЛИФК), г. Москва.
        </li>

        <li>
          Денисова Ольга Александровна - доктор педагогических наук, профессор, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья Северо-Западного Федерального округа ФГБОУ ВО «Череповецкий государственный университет» (ЧГУ), зав. кафедрой дефектологического образования ЧГУ, г.Череповец;
        </li>

        <li>
          Кантор Виталий Зорахович - проректор по инклюзивному образованию ФГБОУ ВО «Российский государственный педагогический университет им. А.Г. Герцена», доктор педагогических наук, профессор, Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья г. Санкт-Петербург;
        </li>

        <li>
          Карплюк Анна Викторовна – доктор педагогических наук, доцент, руководитель Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Российский государственный социальный университет», г. Москва;
        </li>

        <li>
          Краснопевцева Татьяна Федоровна - директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Нижегородский государственный педагогический университет имени Козьмы Минина (Мининский университет)», г. Нижний Новгород;
        </li>

        <li>
          Луковенко Татьяна Геннадьевна –кандидат педагогических наук, доцент, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Тихоокеанский государственный университет» (ТОГУ), зав. Кафедрой теории и методики педагогического и дефектологического образования Педагогического института ТОГУ, г. Хабаровск;
        </li>

        <li>
          Оськин Дмитрий Николаевич - кандидат медицинских наук, доцент, начальник Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Рязанский государственный медицинский университет имени академика И.П. Павлова» Минздрава России, г. Рязань;
        </li>

        <li>
          Осьмук Людмила Алексеевна – доктор социологических наук, профессор, директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Новосибирский государственный технический университет», г.Новосибирск;
        </li>

        <li>
          Романенкова Дарья Феликсовна – кандидат педагогических наук, доцент, начальник Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО “Челябинский государственный университет”;
        </li>

        <li>
          Сорокин Николай Юрьевич – кандидат технических наук, доцент, проректор ФГБОУ ВО «Тихоокеанский государственный университет», г. Хабаровск;
        </li>

        <li>
          Соцков Виталий Валерьевич - директор Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГБОУ ВО «Государственный университет управления», г. Москва;
        </li>

        <li>
          Усатенко Оксана Николаевна - кандидат психологических наук, доцент кафедры психологии, руководитель Ресурсного учебно-методического центра по обучению инвалидов и лиц с ограниченными возможностями здоровья ФГАОУ ВО «Крымский федеральный университет им. В.И. Вернадского», г. Ялта;
        </li>
      </ul>
    </div>
  );
}
