import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import I18n from '../I18n';
import { path } from '../Routes';

import Loaf from '../Loaf/Loaf';

import page from '../Page.module.css';
import styles from './Stories.module.css';
import fonts from '../Fonts.module.css';

class Stories extends React.Component {
  state = {
    stories: null
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  componentWillUnmount() {
    if (this._asyncRequest) this._asyncRequest.cancel();
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('abiturs_stories_path', {}, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          stories: res.data.stories,
        });
        this._asyncRequest = null;
      });
  }

  render() {
    const { stories } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('abiturs_path')}>{I18n.t('meta.abiturs.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('stories.title')}
            </h1>

            <Helmet>
              <title>{I18n.t('stories.title')}</title>
            </Helmet>
          </div>

          {stories &&
            <div className={styles.stories}>
              {stories.map(story =>
                <Link to={path('abiturs_story_path', { id: story.id })} className={styles.story} key={story.id}>
                  <div className={styles.img}>
                    <img src={`https://img.youtube.com/vi/${story.youtube_id}/mqdefault.jpg`} />
                  </div>

                  <div className={styles.desc}>
                    {story.title}
                  </div>
                </Link>
              )}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Stories;
