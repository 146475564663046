import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import I18n from '../I18n'
import { path } from '../Routes'

import styles from './Recovery.module.css'
import page from '../Page.module.css'
import form from '../Form.module.css'
import buttons from '../Buttons.module.css'

class Recovery extends Component {
  state = {
    send: false,
    error: false,
    success: false,
    values: {
      email: ''
    }
  }

  componentWillUnmount() {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  handleInputChange = e =>  {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }))
  }

  handleSubmit = e => {
    if(this._canSubmit()) {
      this.setState({ send: true })
      this._asyncRequest = axios.CancelToken.source()

      axios.post(path('recovery_path'), { user: { ...this.state.values } }, { cancelToken: this._asyncRequest.token })
        .then(res => {
          this._asyncRequest = null
          this.setState({ error: false, success: true })
        })
        .catch(error => {
          this.setState({ error: true, success: false })
        })
        .then(() => {
          this.setState({ send: false })
        })
    }

    e.preventDefault()
  }

  _canSubmit() {
    return !this.state.send

    return (
      this.state.values.email &&
      !this.state.send
    )
  }

  render() {
    const { values, error, success } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <div className={styles.root}>
            <div className={page.title}>
              <h1>
                {I18n.t('auth.recovery')}
              </h1>

              <Helmet>
                <title>
                  {I18n.t('auth.recovery')}
                </title>
              </Helmet>
            </div>

            {success &&
              <div className={styles.success}>
                Инструкции по восстановлению доступа были высланы на вашу почту
              </div>
            }

            {!success &&
              <div className={styles.form}>
                <form className={form.root} onSubmit={this.handleSubmit}>
                  <div className={form.item}>
                    <div className={form.label}>
                      Электронная почта
                    </div>

                    <div className={form.input}>
                      <input type="text" name="email" value={values.email} onChange={this.handleInputChange} />
                    </div>

                    <div className={form.hint}>
                      Инструкции по восстановлению доступа будут высланы на вашу почту
                    </div>
                  </div>

                  {error &&
                    <div className={styles.error}>
                      Пользователь с такой почтой не найден, поэтому восстановить пароль невозможно.
                    </div>
                  }

                  <div className={form.submit}>
                    <input type="submit" value="Восстановить" className={buttons.main} disabled={!this._canSubmit()} />
                  </div>
                </form>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Recovery
