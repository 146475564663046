import React, { Component } from 'react'

import Item from './Item'

import section from './Section.module.css'

class Docs extends Component {
  render () {
    const { data } = this.props

    return (
      <div className={section.root}>
        <div className={section.title}>
          <h2>
            Обеспеченность деятельности вузов нормативно-правовой документацией
          </h2>
        </div>

        <div className={section.list}>
          <Item data={data.ws} label={'stats.docs.ws'} />
          <Item data={data.pol} label={'stats.docs.pol'} />
          <Item data={data.pas} label={'stats.docs.pas'} />
          <Item data={data.ev} label={'stats.docs.ev'} />
        </div>
      </div>
    )
  }
}

export default Docs
