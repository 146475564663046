import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './RateListItem.module.css';
import Icon from '!svg-react-loader?!../../images/icons/star.svg';


class RateListItem extends React.Component {
  state = {
    active: 0,
    hover: 0,
  }

  handleHoverChange = (value) => {
    this.setState({
      hover: value
    });
  }

  handleActiveChange = (value) => {
    this.setState({
      active: value
    }, () => {
      this.props.onActiveChange(this.props.category.id, this.state.active);
    });
  }

  handleMOuseLeave = () => {
    this.setState({
      hover: 0
    });
  }

  render () {

    const { category, results, rated, can_rate, not_rated} = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.title}>
          {category.title}
        </div>

        {rated && !not_rated ? (
          results.map((result, _) =>
            <div className={styles.result} key={_}>
              <div className={styles.author}>{result.author}</div>

              <div className={styles.stars} onMouseLeave={this.handleMOuseLeave}>
                { category.values.map((value, _) =>
                  <Star
                    key={_}
                    value={value}
                    active={value <= result.rates.find(value => value.category_id == category.id).value}
                  />
                )}
              </div>
            </div>
          )
        ) : (
          <div className={styles.stars} onMouseLeave={this.handleMOuseLeave}>
            { category.values.map((value, _) =>
              <Star
                key={_}
                value={value}
                onHoverChange={this.handleHoverChange}
                onActiveChange={this.handleActiveChange}
                hover={value <= this.state.hover}
                active={value <= this.state.active}
                inactive={value <= this.state.active && value > this.state.hover && this.state.hover > 0}
              />
            ) }
          </div>
        )}
      </div>
    );
  }
}

class Star extends React.Component {


  handleMouseEnter = () => {
    if(this.props.onHoverChange) this.props.onHoverChange(this.props.value);
  }

  handleClick = () => {
    if(this.props.onActiveChange) this.props.onActiveChange(this.props.value);
  }

  render () {
    return (
      <div className={classNames(styles.star, { [styles.hover]: this.props.hover, [styles.active]: this.props.active, [styles.inactive]: this.props.inactive })} onMouseEnter={this.handleMouseEnter} onClick={this.handleClick}>
        <Icon />
      </div>
    );
  }
}

export default RateListItem;
