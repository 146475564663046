import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Facilities/Index'
import Show from './Facilities/Show'

export default function Facilities () {
  return (
    <Switch>
      <Route path={Routes.rumc_facility_path}>
        <Show />
      </Route>

      <Route path={Routes.rumc_facilities_path}>
        <Index />
      </Route>
    </Switch>
  )
}
