import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dropzone from 'react-dropzone'
import update from 'immutability-helper';

import I18n from '../I18n'

import AttachmentItem from './AttachmentItem'
import Attachment from './Attachment'

import styles from './Attachments.module.css'

class Attachments extends React.Component {
  state = {
    files: [],
    attachments: this.props.attachments,
    sections: {
      items: this.props.sections,
      checked: this.props.attachments.map(a => a['section']).filter(s => s != null)
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.onAttachmentsUpdate) {
      if (this.state.attachments !== prevState.attachments) {
        this.props.onAttachmentsUpdate(this.state.attachments);

        this.setState(prevState => ({
          sections: { ...prevState.sections, checked: this.state.attachments.map(a => a['section']).filter(s => s != null)
          }
        }));
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if((props.attachments !== state.attachments && state.attachments.length == 0) || props.sections !== state.sections.items) {
      return {
        attachments: props.attachments,
        sections: {
          items: props.sections,
          checked: props.attachments.map(a => a['section']).filter(s => s != null)
        }
      };
    }

    return null;
  }

  handleFileUpload = attachment => {
    this.setState(state => ({
      attachments: [...state.attachments, attachment]
    }));
  }

  onDrop = (files) => {
    this.setState(state => ({
      files: [...state.files, ...files]
    }));
  }

  handleAttachmentUpdate = (index, item) => {
    let newState = update(this.state.attachments, {
      [index]:  {
        $set: item
      }
    });

    this.setState(prevState => ({
      attachments: newState
    }));
  }

  handleAtachementDelete = index => {
    let newState = update(this.state.attachments, {
      $splice: [[index, 1]]
    });

    this.setState(prevState => ({
      attachments: newState
    }));
  }

  render() {
    const { files } = this.state;

    const reqSections = this.state.sections.items && this.state.sections.items.filter(section => !this.state.sections.checked.includes(section));

    return (
      <div className={styles.attachments}>
        <Dropzone onDrop={this.onDrop}>
          {({getRootProps, getInputProps}) => (
            <>
              <div className={styles.zone} {...getRootProps()}>
                <input {...getInputProps()} />
                <span className={styles.desc}>Прикрепите файлы перетащив их в эту область или нажмите для выбора.</span><br />Название файла может содержать не более 50 символов.
              </div>
            </>
          )}
        </Dropzone>

        {files.map((file, _) =>
          <Attachment file={file} key={_} onFileUpload={this.handleFileUpload} />
        )}

        { this.state.attachments &&
          this.state.attachments.map((item, index) =>
            <AttachmentItem key={index} item={item} index={index} onAttachmentUpdate={this.handleAttachmentUpdate} onAttachmentDelete={this.handleAtachementDelete} sections={this.state.sections}/>
          )
        }

        {reqSections && reqSections.length > 0 &&
          <div className={styles.reqs}>
            Необходимо загрузить файлы: {reqSections.map(section => I18n.t(`attachments.types.${section}`)).join(', ')}
          </div>
        }
      </div>
    )
  }
}

export default Attachments
