const round1000 = (val) => {
    if (!val) {
      return 0
    }
    if (val < 1000) {
      return val
    }
    return Math.round(val/1000)*1000
}

export const calcSalaryString = (career) => {
    const salary_min = round1000(career.salary_min);
    const salary_max = round1000(career.salary_max);
    if (salary_min && salary_max && salary_min != salary_max) {
      return `${(salary_min).toLocaleString("ru-RU")} - ${(salary_max).toLocaleString()} рублей`
    }
    if (salary_min && salary_max && salary_min == salary_max) {
      return `${(salary_min).toLocaleString("ru-RU")} рублей`
    }
    if (salary_min && !salary_max) {
      return `от ${(salary_min).toLocaleString("ru-RU")} рублей`
    }
    if (!salary_min && salary_max) {
      return `до ${(salary_max).toLocaleString("ru-RU")} рублей`
    }
}