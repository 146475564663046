import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'
import GlobalVariables from '../Vars';

import Link, { path } from '../Routes'
import { useHistory } from 'react-router-dom'
import qs from "qs"

import Loaf from '../Loaf/Loaf'
import page from '../Page.module.css'
import styles from './Show.module.css'
import buttons from '../Buttons.module.css'
import main_styles from './Index.module.css'

import RadarChart from './Charts/RadarChart.js'
import LineChart from './Charts/LineChart'
import GoogleApiWrapper from './Map/SchoolsMap'

import ImagesList from './Gallery/ImagesList'

import ScrollIntoView from 'react-scroll-into-view'


function Sign ({ i }) {
  return <div className={classNames(styles.sign, { [styles.ok]: i })} />
}

Sign.propTypes = {
  i: PropTypes.bool
}


export default function Show () {
  const { id, filters } = useParams()
  const cancelToken = useRef(axios.CancelToken.source())

  const [school, setSchool] = useState()
  const [school_data, setSchoolData] = useState()
  const [speciality, setSpeciality] = useState()
  const [dynamic, setDynamic] = useState()
  const [dynamicFlag, setDynamicFlag] = useState(0)
  const [questions, setQuestions] = useState()

  const [activeTab, setActiveTab] = useState('common')
  const [activeNosologyTab, setActiveNosologyTab] = useState('e')
  const [isScroll, setIsScroll] = useState(false)
  const [noDataInTable, setNoDataInTable] = useState(false)
  const [hideImages, setHideImages] = useState(false)

  const history = useHistory()


  useEffect(() => {
    let filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    if (filtersFromParams.activeTab && filtersFromParams.activeTab != "NaN") {
      setActiveTab(filtersFromParams.activeTab);
    }
  }, []);

  useEffect(() => {
    const searchParamsStr = history.location?.search;
    const paramsObject = qs.parse(searchParamsStr || '', { ignoreQueryPrefix: true }) || {};
    setHideImages(paramsObject.hide_images == 'true' ? true : false);
    history.push({
      search: qs.stringify({ ...paramsObject, activeTab: activeTab })
    });
  }, [activeTab]);
  

  const handleScroll = () => {
    if (window.scrollY >= 500  && !isScroll) {
      setIsScroll(true);
    } else if (window.scrollY < 500 && isScroll) {
      setIsScroll(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    }
  })


  const [commonSpecialityTable1, setCommonSpecialityTable1] = useState(true)
  const [commonSpecialityTable2, setCommonSpecialityTable2] = useState(true)

  const [firstTableEye, setFirstTableEye] = useState(true)
  const [secondTableEye, setSecondTableEye] = useState(true)
  
  const [aboutTable, setAboutTable] = useState(true)

  const [formValues, setFormValues] = useState({})
  const [specialitiesViewObj, setSpecialitiesViewObj] = useState({})

  const [canSubmitStatus, setCanSubmitStatus] = useState(true)
  const [formSubmit, setFormSubmit] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState({})
  const [onLoading, setOnLoading] = useState(false)
  const [showSmallMenu, setShowSmallMenu] = useState(false)

  const toggleTab = (e, newTab) => {
    e.preventDefault();
    setActiveTab(newTab);
    setShowSmallMenu(false)
  }

  const toggleTabNosology = (e, newTab) => {
    e.preventDefault();
    setActiveNosologyTab(newTab)
  }
  
  const toggleTable = (table) => {
    switch (table) {
      case 'commonSpecialityTable1':
        setCommonSpecialityTable1(!commonSpecialityTable1)
        break;
        case 'commonSpecialityTable2':
          setCommonSpecialityTable2(!commonSpecialityTable2)
          break;
      case 'firstTableEye':
        setFirstTableEye(!firstTableEye)
        break;
      case 'secondTableEye':
        setSecondTableEye(!secondTableEye)
        break;
      case 'aboutTable':
        setAboutTable(!aboutTable)
        break;
    }
  }

  const toggleSelecteBranch = (e, org_id) => { 
    e.preventDefault();

    if (org_id == 0) {
      setSelectedBranch({
        lat: parseFloat(school_data.coordinates[0].coordinates.split(",")[0]),
        lng: parseFloat(school_data.coordinates[0].coordinates.split(",")[1])
      })
    }

    school_data.branches_addresses.map((branch) => {
      if (branch.org_id == org_id) {
        setSelectedBranch({
          lat: parseFloat(branch.koordinats.split(",")[0]),
          lng: parseFloat(branch.koordinats.split(",")[1])
        })
      }
    })
  }

  const toggleSpec = async (g_key, s_key, ugs, form, table_num) => {
    
    let specViews = {...specialitiesViewObj}

    if (!specViews[ugs.id + "_" + table_num]) {
      let currentSpecialities = [...speciality]
      
      if (currentSpecialities &&
          currentSpecialities[g_key]['values'] &&
          currentSpecialities[g_key]['values'][s_key] &&
          currentSpecialities[g_key]['values'][s_key]['values']
      ) {
        if (currentSpecialities[g_key]['values'][s_key]['values'].length == 0) {
          let res = await getSpecByUgs(ugs, form)
          currentSpecialities[g_key]['values'][s_key]['values'] = res.spec
          setSpeciality(currentSpecialities)
        }
      }  
    }
    specViews[ugs.id + "_" + table_num] = !specViews[ugs.id + "_" + table_num]
    setSpecialitiesViewObj(specViews)
    // ugs.is_active = !ugs.is_active 
    // getSpecByUgs(ugs)
  }

  const toggleSmallMenu = () => {
    setShowSmallMenu(!showSmallMenu)
  }

  const checkFormHr = (spec) => {
    let sum = spec.values.reduce(function (sum, i) { 
      return sum + i.col_1 + i.col_2 + i.col_3 + i.col_5 + i.col_6 + i.col_7
    }, 0)
    if (sum) { return true }
    return false
  }

  useEffect(() => {
    const _fetch = async () => {
      setOnLoading(true)
      const { data } = await axios.get(path('school_path', { id }, true), { cancelToken: cancelToken.current.token })
      setOnLoading(false)
      setSchool(data["school"])
      setSchoolData(data["school_data"])
      setSpeciality(data["specialty"])
      setDynamic(data["dynamic"])
      setQuestions(data["questions"])

      const dynamic_flag = data['dynamic'].filter(i => {
        if (i.e_1_val || i.e_2_val || i.e_3_val || i.e_4_val || i.e_5_val || i.e_8_val) 
          return true
        return false
      }).length
      setDynamicFlag(dynamic_flag)

      // ? Формирование предварительного объекта ответов
      if (data["questions"] && Object.keys(data["questions"]).length > 0 && Object.keys(formValues).length == 0) {
        let q_obj = {}
        data["questions"].map((q) => {
          if (q) q_obj["q_" + q.id] = null
        })
        setFormValues(q_obj)
      }
      
      // ? Подготовка объекта статусов для отображения специальностей по укрупненным группам
      if (data["specialty"] && Object.keys(data["specialty"]).length > 0 && Object.keys(specialitiesViewObj).length == 0) {
        // setSpecialitiesViewObj
        let specViews = {}
        data["specialty"].map((form) => {
          if (form.values && Object.keys(form.values).length > 0)
          form.values.map((spec) => {
            specViews[spec.id] = false;
          })
        })
        setSpecialitiesViewObj(specViews)
      }
    }

    _fetch()

    return function cleanup () {
      // cancelToken.current.cancel()
    }
  }, [id])

  const getSpecByUgs = async (ugs, form) => {
    const { data } = await axios.get(path('show_spec_school_path', { id }, true), { cancelToken: cancelToken.current.token, params: {ugs: ugs.ugs, form: form} })
    return data
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let currentValues = {...formValues}
    currentValues[name] = value
    setFormValues(currentValues);
  }

  // ? Получает объект значений по опросу
  // ? Ключ - {'q_'+question}, value - answer_id
  // ? Ключ для textarea- {'review'}
  const getFormValues = (event) => {
    event.preventDefault()
    if (canSubmit()) {
      axios.post(path('proc_form_result_school_path', { id }, false), { cancelToken: cancelToken.current.token, data: formValues })
      setFormSubmit(true)
    }
  }

  const canSubmit = () => {
    let s = true;
    Object.keys(formValues).map(key => {
      if (!parseInt(formValues[key])) {
        s = false;
      }
    })
    if (Object.keys(formValues).length == 0) {
      s = false;
    }
    setCanSubmitStatus(s);
    return s
  }

  const goBackWithParams = () => {
    let strQuery = localStorage.getItem("schools_applied_filters") || '';
    if (strQuery) {
      const json_strQuery = JSON.parse(strQuery);
      history.push({
        pathname: path("schools_path"),
        search: strQuery.length > 0 ? '?' + qs.stringify({...json_strQuery, hide_images: hideImages}) : ''
      });
    } else {
      history.push({
        pathname: path("schools_path")
      });
    }
    
  }

  return (
    <div className={page.wrapper} id="header">
      <div className={styles.title_card_wrapper_bg}>
        <div className={page.container}>
          <Loaf>
            <div className={page.back} onClick={goBackWithParams}>Поиск организации</div>
          </Loaf>
        </div>   
        {isScroll && <ScrollIntoView selector="#header" className={styles.button_up}></ScrollIntoView>}

        {onLoading && <div className={main_styles.loading} />}

        {school && school_data &&
          <div className={classNames(styles.title_card_wrapper, hideImages ? styles.hide_image : '')}>
            {!hideImages}
            {!hideImages && <div className={styles.title_card_img}></div>}
            <div className={styles.title_card}>
              <div className={styles.title_card_body}>
              {!hideImages && <img src={GlobalVariables.cdnHost + school.gerb_org} className={styles.title_card_logo}></img>}
                {school.abbr &&
                  <div className={styles.title_card_header_first_small}>
                    <h4 className={styles.title_sm}>{school.abbr}</h4>
                  </div>
                }
                <div className={styles.title_card_info}>
                  {school.abbr &&
                    <div className={styles.title_card_header_first}>
                      <h4 className={styles.title_sm}>{school.abbr}</h4>
                    </div>
                  }
                  <div className={styles.title_card_header_sec}>
                    {school && school.short_name != school.abbr &&
                      <h5 className={styles.title_sm}>{school.short_name}</h5>
                    }
                  </div>
                  <div className={styles.title_card_header_other}>
                    <div className={styles.title_card_other_item + " " + styles.regular_text}>
                      {school_data.site && <div><span>Сайт: </span><a href={school_data.site} target='_blank'>{school_data.site}</a></div>}
                      {school_data.phone && <div><span>Телефон: </span>{school_data.phone}</div>}
                      {school.city && <div className={styles.city}>{school.city}</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.title_card_bottom + " " + styles.regular_text}>
                <Link to="umms_path" className={classNames(styles.a_in_block, styles.hide_buttons)}>Учебно-методические материалы</Link>
                <Link to="abiturs_stories_path" className={classNames(styles.a_in_block, styles.hide_buttons)}>Истории успеха</Link>
                {school_data.rumcs_id && 
                  <>
                    {school.is_rumc == null && <Link to="rumc_path" params={{ id: school_data.rumcs_id }} className={styles.a_in_block}>Ресурсный учебно-методический центр</Link>}
                    {school.is_rumc == 1 && 
                      <Link to="rumc_path" params={{ id: school_data.rumcs_id }} className={styles.a_in_block}>
                        <div className={main_styles.is_rumc_wrapper}>
                          <div className={main_styles.is_rumc_square} title="Вуз является РУМЦ-ем" />
                          <div>Ресурсный учебно-методический центр</div>
                        </div>
                      </Link>
                    }
                  </>
                  
                }
              </div>
            </div>
          </div>
        }
      </div>
      <div className={page.container}>      
      <div className={styles.info_wrapper}>
      <div className={styles.info_left}>
        <div className={styles.info_nav}>
          <ul>
            <li className={(activeTab == 'common' ? styles.info_active_tab : '')}>
              <a href="#" onClick={(e) => {toggleTab(e, 'common')}}>
                Общая информация
              </a>
            </li>
            <li className={(activeTab == 'about' ? styles.info_active_tab : '')}>
              <a href="#" onClick={(e) => {toggleTab(e, 'about')}}>
                Подробнее о вузе
              </a>
            </li>
            {!hideImages &&
              <li className={(activeTab == 'gallery' ? styles.info_active_tab : '')}>
                <a href="#" onClick={(e) => {toggleTab(e, 'gallery')}}>
                  Галерея
                </a>
              </li>
            }
            <li className={(activeTab == 'help' ? styles.info_active_tab : '')}>
              <a href="#" onClick={(e) => {toggleTab(e, 'help')}}>
                Помочь стать лучше
              </a>
            </li>
          </ul>
        </div>

        <div className={styles.info_nav_menu} onClick={() => toggleSmallMenu()}>Меню</div>
        {showSmallMenu &&
          <div className={styles.info_nav_small}>
            <ul>
              <li className={(activeTab == 'common' ? styles.info_active_small_tab : '')}>
                <a href="#" onClick={(e) => {toggleTab(e, 'common')}}>
                  Общая информация
                </a>
              </li>
              <li className={(activeTab == 'about' ? styles.info_active_small_tab : '')}>
                <a href="#" onClick={(e) => {toggleTab(e, 'about')}}>
                  Подробнее о вузе
                </a>
              </li>
              <li className={(activeTab == 'gallery' ? styles.info_active_small_tab : '')}>
                <a href="#" onClick={(e) => {toggleTab(e, 'gallery')}}>
                  Галерея
                </a>
              </li>
              <li className={(activeTab == 'help' ? styles.info_active_small_tab : '')}>
                <a href="#" onClick={(e) => {toggleTab(e, 'help')}}>
                  Помочь стать лучше
                </a>
              </li>
            </ul>
          </div>
        }

        {activeTab == 'common' && school_data != null &&
          <div className={styles.info_card}>
            <div className={styles.info_common}>
              <h2 id="common_params">Оценка совокупности параметров готовности организации к обучению лиц с инвалидностью и ОВЗ</h2>
              <div className={styles.info_advantages}>
                <div className={styles.info_advantages_item}>
                  <h3>Учебные корпуса с безбарьерной средой</h3>
                  <div className={styles.info_advantages_icons}>
                    <span className={school_data.col_51 > 0 ? styles.e_m : styles.none} title="Нарушения зрения"></span>
                    <span className={school_data.col_52 > 0 ? styles.h_m : styles.none} title="Нарушения слуха"></span>
                    <span className={school_data.col_50 > 0 ? styles.m_m : styles.none} title="Нарушения опорно-двигательного аппарата"></span>
                  </div>
                </div>
                <div className={styles.info_advantages_item}>
                  <h3>Доступность общежитий</h3>
                  <div className={styles.info_advantages_icons}>
                    <span className={school_data.col_54 > 0 ? styles.e_m : styles.none} title="Нарушения зрения"></span>
                    <span className={school_data.col_55 > 0 ? styles.h_m : styles.none} title="Нарушения слуха"></span>
                    <span className={school_data.col_53 > 0 ? styles.m_m : styles.none} title="Нарушения опорно-двигательного аппарата"></span>
                  </div>
                </div>
              </div>
              <div className={styles.info_groups}>
                <div className={styles.info_groups_nav}>
                  <div className={(activeNosologyTab == 'e' ? styles.info_groups_active : '')}>
                    <a href="#" className={styles.g_eye} onClick={(e) => {toggleTabNosology(e, 'e')}}></a>
                  </div>
                  <div className={(activeNosologyTab == 'h' ? styles.info_groups_active : '')}>
                    <a href="#" className={styles.g_ear} onClick={(e) => {toggleTabNosology(e, 'h')}}></a>
                  </div>
                  <div className={(activeNosologyTab == 'm' ? styles.info_groups_active : '')}>
                    <a href="#" className={styles.g_body} onClick={(e) => {toggleTabNosology(e, 'm')}}></a>
                  </div>
                </div>
                {activeNosologyTab == 'e' && school_data != null && 
                  <div>
                    <div className={styles.info_group_text}>
                      <h4>Готовность к обучению слабовидящих</h4>
                      <p>Оценивается совокупность параметров из различных разделов опросной формы, определяющая степень
                        готовности вуза к работе со слабовидящими абитуриентами и студентами.</p>
                    </div>
                    <div className={styles.info_group_lists}>
                      {(school_data.col_3 > 0 | 
                      school_data.col_4 > 0 | 
                      school_data.col_5 > 0 | 
                      school_data.col_6 > 0 | 
                      school_data.col_7 > 0 | 
                      school_data.col_8 > 0) != 0 &&
                        <div className={styles.info_group_list}>
                          <div className={styles.info_group_list_box}>
                            
                                <div className={styles.info_group_list_header}>
                                  Аудитории со стационарными техническими средствами
                                </div>
                                {firstTableEye && 
                                  <div className={styles.info_group_list_body}>
                                    <ul>
                                      {school_data.col_3 > 0 && <li>видеоувеличитель</li>}
                                      {school_data.col_4 > 0 && <li>дисплей Брайля</li>}
                                      {school_data.col_5 > 0 && <li>клавиатура с увеличенными клавишами</li>}
                                      {school_data.col_6 > 0 && <li>клавиатура с шрифтом Брайля</li>}
                                      {school_data.col_7 > 0 && <li>принтер Брайля</li>}
                                      {school_data.col_8 > 0 && <li>иные технические средства</li>}
                                    </ul>
                                  </div>  
                                }
                          </div>
                          <div className={styles.info_group_list_btn + ' ' + (!firstTableEye ? styles.info_group_list_btn_active : '')} onClick={() => toggleTable('firstTableEye')}></div>
                        </div>
                      }
                      
                      {(school_data.col_18 > 0 | school_data.col_19 > 0 | school_data.col_20 > 0 | school_data.col_21 > 0) != 0 &&
                        <div className={styles.info_group_list}>
                          <div className={styles.info_group_list_box}>
                            <div className={styles.info_group_list_header}>
                              Наличие мобильных адаптирующих устройств
                            </div>
                            {secondTableEye && 
                              <div className={styles.info_group_list_body}>
                                <ul>
                                  {school_data.col_18 > 0 && <li>ручной видеоувеличитель</li>}
                                  {school_data.col_19 > 0 && <li>тифлокомпьютер</li>}
                                  {school_data.col_20 > 0 && <li>портативное устройство для чтения</li>}
                                  {school_data.col_21 > 0 && <li>иные устройства</li>}
                                </ul>
                              </div>
                            }
                          </div>
                          <div className={styles.info_group_list_btn + ' ' + (!secondTableEye ? styles.info_group_list_btn_active : '')} onClick={() => toggleTable('secondTableEye')}></div>
                        </div>
                      }
                    </div>
                  </div>
                }

                {activeNosologyTab == 'h' && school_data != null && 
                  <div>
                    <div className={styles.info_group_text}>
                      <h4>Готовность к обучению слабослышащих</h4>
                      <p>Оценивается совокупность параметров из различных разделов опросной формы, определяющая степень
                        готовности вуза к работе со слабослышащими абитуриентами и студентами.</p>
                    </div>
                    <div className={styles.info_group_lists}>

                      {(school_data.col_10  > 0 | school_data.col_11  > 0) != 0 && 
                        <div className={styles.info_group_list}>
                          <div className={styles.info_group_list_box}>
                            <div className={styles.info_group_list_header}>
                              Аудитории со стационарными техническими средствами
                            </div>
                            {firstTableEye && 
                              <div className={styles.info_group_list_body}>
                                <ul>
                                  {school_data.col_10  > 0 && <li>информационная индукционная система</li>}
                                  {school_data.col_11  > 0 && <li>иные технические средства</li>}
                                </ul>
                              </div>  
                              
                            }
                          </div>
                          <div className={styles.info_group_list_btn + ' ' + (!firstTableEye ? styles.info_group_list_btn_active : '')} onClick={() => toggleTable('firstTableEye')}></div>
                        </div>
                      }

                      {(school_data.col_23  > 0 | school_data.col_24  > 0) != 0 && 
                        <div className={styles.info_group_list}>
                          <div className={styles.info_group_list_box}>
                            <div className={styles.info_group_list_header}>
                              Наличие мобильных адаптирующих устройств
                            </div>
                            {secondTableEye && 
                              <div className={styles.info_group_list_body}>
                                <ul>
                                  {school_data.col_23  > 0 && <li>портативная информационная индукционная система</li>}
                                  {school_data.col_24  > 0 && <li>иные устройства</li>}
                                </ul>
                              </div>
                            }
                          </div>
                          <div className={styles.info_group_list_btn + ' ' + (!secondTableEye ? styles.info_group_list_btn_active : '')} onClick={() => toggleTable('secondTableEye')}></div>
                        </div>
                      }
                    </div>
                  </div>
                }

                {activeNosologyTab == 'm' && school_data != null && 
                  <div>
                    <div className={styles.info_group_text}>
                      <h4>Готовность к обучению лиц с нарушениями опорно-двигательного аппарата</h4>
                      <p>Оценивается совокупность параметров из различных разделов опросной формы, определяющая степень
                        готовности вуза к работе абитуриентами и студентами с нарушениями опорно-двигательного аппарата.</p>
                    </div>
                    <div className={styles.info_group_lists}>

                      {(school_data.col_13  > 0 | school_data.col_14  > 0 | school_data.col_15  > 0) != 0 && 
                        <div className={styles.info_group_list}>
                          <div className={styles.info_group_list_box}>
                            <div className={styles.info_group_list_header}>
                              Аудитории со стационарными техническими средствами
                            </div>
                            {firstTableEye && 
                              <div className={styles.info_group_list_body}>
                                <ul>
                                  {school_data.col_13  > 0 && <li>специализированная мебель</li>}
                                  {school_data.col_14  > 0 && <li>альтернативные устройства ввода информации (клавиатура, кнопка, джойстик)</li>}
                                  {school_data.col_15  > 0 && <li>иные технические средства</li>}
                                </ul>
                              </div>  
                            }
                          </div>
                          <div className={styles.info_group_list_btn + ' ' + (!firstTableEye ? styles.info_group_list_btn_active : '')} onClick={() => toggleTable('firstTableEye')}></div>
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>




            <div className={classNames(styles.info_speciality, styles.big_table)}>
              <h2 id="common_name_1">Данные о приёме на 2023-2024 уч.г.</h2>
              <div className={styles.info_speciality_wrapper}>
                <div className={styles.info_speciality_box}>
                  <div className={styles.info_speciality_header_1}>
                    <table>
                      <tbody>
                        <tr>
                          <td>Наименование групп специальностей </td>
                          <td>Наличие бюжетных мест<br />на 2023-2024 уч.г.<br /><span>по формам обучения</span></td>
                          <td>Наличие платных мест<br />на 2023-2024 уч.г.<br /><span>по формам обучения</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {commonSpecialityTable1 && speciality &&
                  <div className={styles.info_speciality_table_1}>
                    <table>
                      <tbody>
                      <tr>
                        <td></td>
                        <td>
                          <div className={styles.budget_forms}>
                            <p>очная</p>
                            <p>очно-заочная</p>
                            <p>заочная</p>
                          </div>
                        </td>
                        <td>
                          <div className={styles.budget_forms}>
                            <p>очная</p>
                            <p>очно-заочная</p>
                            <p>заочная</p>
                          </div>
                        </td>
                      </tr>
                      {speciality && speciality.length > 0 && speciality.map((form,i) =>
                          <>
                            {form.values.map((ugs,j) => 
                              <>
                                <tr key={`ugs_${ugs.id}`}>
                                  <td className={styles.speciality_names}>
                                    <div className={styles.code_title}>
                                      <span>{ugs.code}</span> {ugs.title}
                                    </div>
                                    <div id={ugs.id + "_ugs"} onClick={() => {toggleSpec(i,j,ugs,form.form, 1)}} className={specialitiesViewObj[ugs.id + "_" + 1] ? classNames(styles.code_title, styles.minus) : classNames(styles.code_title, styles.plus)}></div>
                                  </td>
                                  <td>
                                    <div className={classNames(styles.budget_forms, styles.budget_forms_small)}>
                                      {ugs.col_9 == true && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_9 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                          <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                          <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                      {ugs.col_10 == true && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_10 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                            <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                            <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                      {ugs.col_11 == true  && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_11 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                          <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                          <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                    </div>
                                  </td>
                                  <td>
                                    <div className={classNames(styles.budget_forms, styles.budget_forms_small)}>
                                      {ugs.col_12 == true && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_12 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                          <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                          <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                      {ugs.col_13 == true && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_13 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                            <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                            <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                      {ugs.col_14 == true  && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_14 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                          <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                          <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                    </div>
                                  </td>
                                </tr>
                                {specialitiesViewObj[ugs.id + "_" + 1] && ugs.values && ugs.values.length > 0 && ugs.values.map(spec =>
                                  <tr key={spec.id + "_spec"} className={styles.spec_tr} >
                                    <td>
                                      <div>
                                        <ul><li>
                                          <div className={styles.spec_title}>
                                            <span>{spec.code}</span> {spec.title}
                                          </div>
                                        </li></ul>
                                      </div>
                                    </td>
                                    <td>
                                      <div className={styles.budget_forms}>
                                          {spec.col_9 == 1 && 
                                            <div className={styles.info_access_value}>
                                                <span className={styles.info_access_rating}></span>
                                                <span className={styles.text_md}>Да</span>
                                            </div>
                                          }
                                          {spec.col_9 == 0 &&
                                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                                <span className={styles.text_md}>Нет</span>
                                            </div>
                                          }
                                          {spec.col_10 == 1 && 
                                            <div className={styles.info_access_value}>
                                                <span className={styles.info_access_rating}></span>
                                                <span className={styles.text_md}>Да</span>
                                            </div>
                                          }
                                          {spec.col_10 == 0 &&
                                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                                <span className={styles.text_md}>Нет</span>
                                            </div>
                                          }
                                          {spec.col_11 == 1  && 
                                            <div className={styles.info_access_value}>
                                                <span className={styles.info_access_rating}></span>
                                                <span className={styles.text_md}>Да</span>
                                            </div>
                                          }
                                          {spec.col_11 == 0 &&
                                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                                <span className={styles.text_md}>Нет</span>
                                            </div>
                                          }
                                      </div>
                                    </td>
                                    <td>
                                      <div className={styles.budget_forms}>
                                          {spec.col_12 == 1 && 
                                            <div className={styles.info_access_value}>
                                                <span className={styles.info_access_rating}></span>
                                                <span className={styles.text_md}>Да</span>
                                            </div>
                                          }
                                          {spec.col_12 == 0 &&
                                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                                <span className={styles.text_md}>Нет</span>
                                            </div>
                                          }
                                          {spec.col_13 == 1 && 
                                            <div className={styles.info_access_value}>
                                                <span className={styles.info_access_rating}></span>
                                                <span className={styles.text_md}>Да</span>
                                            </div>
                                          }
                                          {spec.col_13 == 0 &&
                                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                                <span className={styles.text_md}>Нет</span>
                                            </div>
                                          }
                                          {spec.col_14 == 1  && 
                                            <div className={styles.info_access_value}>
                                                <span className={styles.info_access_rating}></span>
                                                <span className={styles.text_md}>Да</span>
                                            </div>
                                          }
                                          {spec.col_14 == 0 &&
                                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                                <span className={styles.text_md}>Нет</span>
                                            </div>
                                          }
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            )}
                            {i < speciality.length - 1 &&
                              <tr>
                                <td colSpan={5} className={styles.form_hr}>
                                  <div>{form.name}<span /></div>
                                  <hr></hr>
                                  <div>{speciality[i+1].name}<span /></div>
                                </td>
                              </tr>
                            }
                          </>
                      )}
                      </tbody>
                    </table>
                  </div>
                  }
                </div>
                <div className={classNames(styles.info_speciality_btn, (!commonSpecialityTable1 ? styles.info_speciality_btn_active : ''), styles.big_table)} onClick={() => toggleTable('commonSpecialityTable1')}></div>
              </div>
            </div>
            <div className={classNames(styles.info_speciality, styles.big_table)}>
              <h2 id="common_name_2">Данные о количестве студентов с инвалидностью и ограниченными возможностями здоровья по специальностям и направлениям подготовки</h2>
              <div className={styles.info_speciality_wrapper}>
                <div className={styles.info_speciality_box}>
                  <div className={styles.info_speciality_header_2}>
                    <table>
                      <tbody>
                        <tr>
                          <td>Наименование групп специальностей </td>
                          <td>Принято<br /><span>с 01.10.21 по 30.09.22</span></td>
                          <td>Выпуск<br /><span>с 01.10.21 по 30.09.22</span></td>
                          <td>Трудоустроились</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {commonSpecialityTable2 && speciality &&
                  <div className={styles.info_speciality_table_2}>
                    <table>
                      <tbody>
                      {speciality && speciality.length > 0 && speciality.map((form, i) =>
                        <>
                          {form.values.map((ugs, j) => 
                            <>
                              {(ugs.col_1 + ugs.col_2 + ugs.col_3 + ugs.col_5 + ugs.col_6 + ugs.col_7) > 0 &&
                                <tr key={`${ugs.id}_${i}_${j}`}>
                                  <td className={styles.speciality_names}>
                                    <div className={styles.code_title}>
                                      <span>{ugs.code}</span> {ugs.title}
                                    </div>
                                    <div id={ugs.id + "_ugs"} onClick={() => {toggleSpec(i, j, ugs, form.form, 2)}} className={specialitiesViewObj[ugs.id + "_" + 2] ? classNames(styles.code_title, styles.minus) : classNames(styles.code_title, styles.plus)}></div>
                                  </td>
                                  <td>
                                    <div className={styles.info_speciality_group}>
                                      <span><i className={styles.e_s}></i>{ugs.col_1}</span>
                                      <span><i className={styles.h_s}></i>{ugs.col_2}</span>
                                      <span><i className={styles.m_s}></i>{ugs.col_3}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className={styles.info_speciality_group}>
                                      <span><i className={styles.e_s}></i>{ugs.col_5}</span>
                                      <span><i className={styles.h_s}></i>{ugs.col_6}</span>
                                      <span><i className={styles.m_s}></i>{ugs.col_7}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className={styles.info_speciality_count}>
                                      <span>{ugs.col_4}</span>
                                    </div>
                                  </td>
                                </tr>
                              }
                              {specialitiesViewObj[ugs.id + "_" + 2] && ugs.values && ugs.values.length > 0 && ugs.values.map(spec =>
                                <tr key={spec.id + "_spec"}  >
                                  <td>
                                    <div>
                                      <ul><li>
                                        <div className={styles.spec_title}>
                                          <span>{spec.code}</span> {spec.title}
                                        </div>
                                      </li></ul>
                                    </div>
                                  </td>
                                  <td>
                                    <div className={styles.info_speciality_group}>
                                      <span><i className={styles.e_s}></i>{spec.col_1}</span>
                                      <span><i className={styles.h_s}></i>{spec.col_2}</span>
                                      <span><i className={styles.m_s}></i>{spec.col_3}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className={styles.info_speciality_group}>
                                      <span><i className={styles.e_s}></i>{spec.col_5}</span>
                                      <span><i className={styles.h_s}></i>{spec.col_6}</span>
                                      <span><i className={styles.m_s}></i>{spec.col_7}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className={styles.info_speciality_count}>
                                      <span>{spec.col_8}%</span>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!(specialitiesViewObj[ugs.id + "_" + 2] && ugs.values && ugs.values.length > 0) &&
                                <tr className={styles.nodataintable}>
                                  <td colSpan={4}>Данные отсутствуют</td>
                                </tr>
                              }
                            </>
                          )}
                          {noDataInTable && <td colSpan={4}><p>Данные отсутствуют</p></td>}
                          {i < speciality.length - 1 && checkFormHr(speciality[i]) && checkFormHr(speciality[i+1]) &&
                            <tr>
                              <td colSpan={5} className={styles.form_hr}>
                                <div>{form.name}<span /></div>
                                <hr></hr>
                                <div>{speciality[i+1].name}<span /></div>
                              </td>
                            </tr>
                          }
                          {i < speciality.length - 1 && checkFormHr(speciality[i]) && !checkFormHr(speciality[i+1]) &&
                            <tr>
                              <td colSpan={5} className={styles.form_hr}>
                                <div>{form.name}<span /></div>
                                <hr></hr>
                              </td>
                            </tr>
                          }
                        </>
                      )}
                      </tbody>
                    </table>
                  </div>
                  }
                </div>
                <div className={styles.info_speciality_btn + ' ' + (!commonSpecialityTable2 ? styles.info_speciality_btn_active : '')} onClick={() => toggleTable('commonSpecialityTable2')}></div>
              </div>
            </div>
            






            <div className={classNames(styles.info_speciality, styles.small_table)}>
              <h2 id="common_name_1">Данные о приёме на 2022-2023 уч.г.</h2>
              <div className={styles.info_speciality_wrapper}>
                <div className={styles.info_speciality_box}>
                  {commonSpecialityTable1 && speciality &&
                  <div className={styles.info_speciality_table_1}>
                    <table>
                      <tbody>
                      {speciality && speciality.length > 0 && speciality.map((form,i) =>
                        <>
                          {form.values.map((ugs,j) => 
                            <>
                              <tr key={ugs.id}>
                                <td className={styles.speciality_names}>
                                  <div className={styles.code_title}>
                                    <span>{ugs.code}</span> {ugs.title}
                                  </div>
                                  <div id={ugs.id + "_ugs"} onClick={() => {toggleSpec(i,j,ugs,form.form, 1)}} className={specialitiesViewObj[ugs.id + "_" + 1] ? classNames(styles.code_title, styles.minus) : classNames(styles.code_title, styles.plus)}></div>
                                </td>
                              </tr>
                              {specialitiesViewObj[ugs.id + "_" + 1] && ugs.values && ugs.values.length > 0 &&ugs.values.map(spec =>
                              <>
                                <tr key={spec.id + "_spec"} className={styles.spec_tr} >
                                  <td>
                                    <div className={styles.spec_title}>
                                      <span>{spec.code}</span> {spec.title}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.spec_item}>
                                      <div>Наличие бюжетных мест на 2023-2024 уч.г. <span>по формам обучения</span></div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                  <div className={styles.budget_forms}>
                                    <p>очная</p>
                                    <p>очно-заочная</p>
                                    <p>заочная</p>
                                  </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={classNames(styles.budget_forms, styles.budget_forms_small)}>
                                      {ugs.col_9 == true && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_9 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                          <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                          <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                      {ugs.col_10 == true && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_10 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                            <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                            <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                      {ugs.col_11 == true  && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_11 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                          <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                          <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.spec_item}>
                                      <div>Наличие платных мест на 2023-2024 уч.г. <span>по формам обучения</span></div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                  <div className={styles.budget_forms}>
                                    <p>очная</p>
                                    <p>очно-заочная</p>
                                    <p>заочная</p>
                                  </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={classNames(styles.budget_forms, styles.budget_forms_small)}>
                                      {ugs.col_12 == true && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_12 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                          <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                          <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                      {ugs.col_13 == true && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_13 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                            <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                            <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                      {ugs.col_14 == true  && 
                                        <div className={styles.info_access_value}>
                                          <span className={styles.info_access_rating}></span>
                                          <span className={styles.text_md}>Да</span>
                                        </div>
                                      }
                                      {ugs.col_14 == false &&
                                        <div className={styles.info_access_value + ' ' + styles.negative}>
                                          <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                          <span className={styles.text_md}>Нет</span>
                                        </div>
                                      }
                                    </div>
                                  </td>
                                </tr>
                              </>
                              )}
                            </>
                          )}
                          {i < speciality.length - 1 &&
                            <tr>
                              <td colSpan={5} className={styles.form_hr}>
                                <div>{form.name}<span /></div>
                                <hr></hr>
                                <div>{speciality[i+1].name}<span /></div>
                              </td>
                            </tr>
                          }
                        </>
                      )}
                      </tbody>
                    </table>
                  </div>
                  }
                </div>
                <div className={classNames(styles.info_speciality_btn, (!commonSpecialityTable1 ? styles.info_speciality_btn_active : ''), styles.big_table)} onClick={() => toggleTable('commonSpecialityTable1')}></div>
              </div>
            </div>
            <div className={classNames(styles.info_speciality, styles.small_table)}>
              <h2 id="common_name_2">Данные о количестве студентов с ограниченными возможностями по специальностям</h2>
              <div className={styles.info_speciality_wrapper}>
                <div className={styles.info_speciality_box}>
                  {commonSpecialityTable2 && speciality &&
                  <div className={styles.info_speciality_table_2}>
                    <table>
                      <tbody>
                      {speciality && speciality.length > 0 && speciality.map((form, i) =>
                        <>
                          {form.values.map((ugs, j) => 
                            <>
                              {(ugs.col_1 + ugs.col_2 + ugs.col_3 + ugs.col_5 + ugs.col_6 + ugs.col_7) > 0 &&
                                <tr key={ugs.id}>
                                  <td className={styles.speciality_names}>
                                    <div className={styles.code_title}>
                                      <span>{ugs.code}</span> {ugs.title}
                                    </div>
                                    <div id={ugs.id + "_ugs"} onClick={() => {toggleSpec(i, j, ugs, form.form, 2)}} className={specialitiesViewObj[ugs.id + "_" + 2] ? classNames(styles.code_title, styles.minus) : classNames(styles.code_title, styles.plus)}></div>
                                  </td>
                                </tr>
                              }
                              {specialitiesViewObj[ugs.id + "_" + 2] && ugs.values && ugs.values.length > 0 && ugs.values.map(spec =>
                              <>
                                <tr key={spec.id + "_spec"} className={styles.spec_tr} >
                                  <td>
                                    <div className={styles.spec_title}>
                                      <span>{spec.code}</span> {spec.title}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.spec_item}>
                                      <div>Принято <span>с 01.10.20 по 30.09.21</span></div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.info_speciality_group}>
                                      <span><i className={styles.e_s}></i>{spec.col_1}</span>
                                      <span><i className={styles.h_s}></i>{spec.col_2}</span>
                                      <span><i className={styles.m_s}></i>{spec.col_3}</span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.spec_item}>
                                      <div>Выпуск <span>с 01.10.20 по 30.09.21</span></div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className={styles.info_speciality_group}>
                                      <span><i className={styles.e_s}></i>{spec.col_5}</span>
                                      <span><i className={styles.h_s}></i>{spec.col_6}</span>
                                      <span><i className={styles.m_s}></i>{spec.col_7}</span>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <div className={styles.spec_item}>
                                      <div>Трудоустроились: <b>{spec.col_8}%</b></div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                              )}
                            </>
                          )}
                          {i < speciality.length - 1 && checkFormHr(speciality[i]) && checkFormHr(speciality[i+1]) &&
                            <tr>
                              <td colSpan={5} className={styles.form_hr}>
                                <div>{form.name}<span /></div>
                                <hr></hr>
                                <div>{speciality[i+1].name}<span /></div>
                              </td>
                            </tr>
                          }
                          {i < speciality.length - 1 && checkFormHr(speciality[i]) && !checkFormHr(speciality[i+1]) &&
                            <tr>
                              <td colSpan={5} className={styles.form_hr}>
                                <div>{form.name}<span /></div>
                                <hr></hr>
                              </td>
                            </tr>
                          }
                        </>
                      )}
                      </tbody>
                    </table>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        }

        {activeTab == 'about' && dynamic != null &&
          <div className={styles.about_content}>
            {dynamicFlag > 0 &&
              <div>
                <div className={styles.about_radar}>
                    <div className={styles.about_radar_header}>
                        <h2 className={styles.header_md} id="about_monitoring">Позиции организации по основным показателям*</h2>
                    </div>
                    {!hideImages &&
                      <div className={styles.about_radar_wrapper}>
                        <RadarChart data={dynamic} />
                      </div>
                    }
                </div>
                <div className={styles.about_table_wrapper}>
                    <div className={styles.about_table_box}>
                        <div className={styles.info_speciality_header_1 + ' ' + styles.about_table_header}>
                            <table>
                              <tbody>
                                <tr>
                                  <td className={styles.about_header_name}>Наименование показателя</td>
                                  {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                    <td key={`year_${year_data.year}`} className={styles.about_header_count}>{year_data.year}</td>
                                  )}
                                  <td className={styles.about_header_count + ' ' + styles.about_header_dynamic}>{dynamic[dynamic.length-1].year}|Динамика</td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                        {aboutTable &&
                          <>
                          <div className={styles.about_table}>
                            <table>
                              <tbody>
                              <tr>
                                <td className={styles.about_table_name}>
                                  <div>
                                      <span>Е.1. Образовательная деятельность</span>
                                      <p>Средний балл ЕГЭ студентов, принятых по результатам ЕГЭ на обучение по очной форме по программам бакалавриата и специалитета за счет средств соответствующих бюджетов бюджетной системы Российской Федерации и с оплатой стоимости затрат на обучение физическими и юредическими лицами</p>
                                  </div>
                                </td>

                                {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                  <td key={year_data.year}>
                                    <span>{year_data.e_1_val}</span>
                                  </td>
                                )}
                                {dynamic[dynamic.length-1].e_1_d > 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.positive}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_1_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>+ {dynamic[dynamic.length-1].e_1_d}%</span>
                                    </div>
                                  </td>
                                }
                                {dynamic[dynamic.length-1].e_1_d < 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.negative}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_1_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>{dynamic[dynamic.length-1].e_1_d}%</span>
                                    </div>
                                  </td>
                                }
                              </tr>
                              <tr>
                                <td className={styles.about_table_name}>
                                  <div>
                                      <span>Е.2. Научно-исследовательская деятельность</span>
                                      <p>Объем НИОКР в расчете на одного НПР</p>
                                  </div>
                                </td>

                                {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                  <td key={year_data.year}>
                                    <span>{year_data.e_2_val}</span>
                                  </td>
                                )}
                                {dynamic[dynamic.length-1].e_2_d > 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.positive}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_2_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>+ {dynamic[dynamic.length-1].e_2_d}%</span>
                                    </div>
                                  </td>
                                }
                                {dynamic[dynamic.length-1].e_2_d < 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.negative}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_2_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>{dynamic[dynamic.length-1].e_2_d}%</span>
                                    </div>
                                  </td>
                                }

                              </tr>
                              <tr>
                                <td className={styles.about_table_name}>
                                  <div>
                                      <span>Е.3. Международная деятельность</span>
                                      <p>Удельный вес численности иностранных студентов, обучающихся по программам бакалавриата, специалитета, магистратуры, в общей численности студентов (приведенный контингент)</p>
                                  </div>
                                </td>
                                {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                  <td key={year_data.year}>
                                    <span>{year_data.e_3_val}</span>
                                  </td>
                                )}
                                {dynamic[dynamic.length-1].e_3_d > 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.positive}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_3_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>+ {dynamic[dynamic.length-1].e_3_d}%</span>
                                    </div>
                                  </td>
                                }
                                {dynamic[dynamic.length-1].e_3_d < 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.negative}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_3_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>{dynamic[dynamic.length-1].e_3_d}%</span>
                                    </div>
                                  </td>
                                }
                              </tr>
                              <tr>
                                <td className={styles.about_table_name}>
                                  <div>
                                      <span>Е.4. Финансово-экономическая деятельность</span>
                                      <p>Доходы образовательной организации из всех источников в расчетена одного НПР</p>
                                  </div>
                                </td>
                                {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                  <td key={year_data.year}>
                                    <span>{year_data.e_4_val}</span>
                                  </td>
                                )}
                                {dynamic[dynamic.length-1].e_4_d > 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.positive}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_4_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>+ {dynamic[dynamic.length-1].e_4_d}%</span>
                                    </div>
                                  </td>
                                }
                                {dynamic[dynamic.length-1].e_4_d < 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.negative}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_4_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>{dynamic[dynamic.length-1].e_4_d}%</span>
                                    </div>
                                  </td>
                                }
                              </tr>
                              <tr>
                                <td className={styles.about_table_name}>
                                  <div>
                                      <span>Е.5. Заработная плата ППС</span>
                                      <p>Отношение заработной платы профессорско-преподавательского состава к средней заработной плате по экономике региона</p>
                                  </div>
                                </td>
                                {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                  <td key={year_data.year}>
                                    <span>{year_data.e_5_val}</span>
                                  </td>
                                )}
                                {dynamic[dynamic.length-1].e_5_d > 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.positive}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_5_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>+ {dynamic[dynamic.length-1].e_5_d}%</span>
                                    </div>
                                  </td>
                                }
                                {dynamic[dynamic.length-1].e_5_d < 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.negative}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_5_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>{dynamic[dynamic.length-1].e_5_d}%</span>
                                    </div>
                                  </td>
                                }
                              </tr>
                              <tr>
                                <td className={styles.about_table_name}>
                                  <div>
                                      <span>Е.8. Дополнительный показатель</span>
                                      <p>Численность сотрудников, из числа профессорско-преподавательского состава (приведенных к доле ставки), имеющих ученые степени кандидата или доктора наук, в расчете на 100 студентов</p>
                                  </div>
                                </td>
                                {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                  <td key={year_data.year}>
                                    <span>{year_data.e_8_val}</span>
                                  </td>
                                )}
                                {dynamic[dynamic.length-1].e_8_d > 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.positive}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_8_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>+ {dynamic[dynamic.length-1].e_8_d}%</span>
                                    </div>
                                  </td>
                                }
                                {dynamic[dynamic.length-1].e_8_d < 0 &&
                                  <td className={styles.about_td_21  + ' ' + styles.negative}>
                                    <div>
                                      <span>{dynamic[dynamic.length-1].e_8_val}</span>
                                      <span className={styles.about_td_arrow }></span>
                                      <span>{dynamic[dynamic.length-1].e_8_d}%</span>
                                    </div>
                                  </td>
                                }
                              </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className={styles.about_table_small}>
                            <table>
                              <tbody>
                                <tr>
                                  <td className={styles.about_table_name} colSpan={2}>
                                    <div>
                                        <span>Е.1. Образовательная деятельность</span>
                                        <p>Средний балл ЕГЭ студентов, принятых по результатам ЕГЭ на обучение по очной форме по программам бакалавриата и специалитета за счет средств соответствующих бюджетов бюджетной системы Российской Федерации и с оплатой стоимости затрат на обучение физическими и юредическими лицами</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                      <div className={styles.spec_item} key={year_data.year}><div>{year_data.year}</div></div>
                                    )}
                                    <div className={classNames(styles.spec_item, styles.about_header_dynamic)}><div>{dynamic[dynamic.length-1].year}|Динамика</div></div>
                                  </td>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                        <div className={styles.about_table_small_value}>{year_data.e_1_val}</div>
                                    )}
                                    {dynamic[dynamic.length-1].e_1_d > 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.positive}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_1_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>+ {dynamic[dynamic.length-1].e_1_d}%</span>
                                        </div>
                                      </div>
                                    }
                                    {dynamic[dynamic.length-1].e_1_d < 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.negative}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_1_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_1_d}%</span>
                                        </div>
                                      </div>
                                    }
                                  </td>
                                </tr>


                                <tr>
                                  <td className={styles.about_table_name} colSpan={2}>
                                    <div>
                                        <span>Е.2. Научно-исследовательская деятельность</span>
                                        <p>Объем НИОКР в расчете на одного НПР</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                      <div className={styles.spec_item} key={year_data.year}><div>{year_data.year}</div></div>
                                    )}
                                    <div className={classNames(styles.spec_item, styles.about_header_dynamic)}><div>{dynamic[dynamic.length-1].year}|Динамика</div></div>
                                  </td>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                        <div className={styles.about_table_small_value}>{year_data.e_2_val}</div>
                                    )}
                                    {dynamic[dynamic.length-1].e_2_d > 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.positive}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_2_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>+ {dynamic[dynamic.length-1].e_2_d}%</span>
                                        </div>
                                      </div>
                                    }
                                    {dynamic[dynamic.length-1].e_2_d < 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.negative}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_2_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_2_d}%</span>
                                        </div>
                                      </div>
                                    }
                                  </td>
                                </tr>

                                <tr>
                                  <td className={styles.about_table_name} colSpan={2}>
                                    <div>
                                        <span>Е.3. Международная деятельность</span>
                                        <p>Удельный вес численности иностранных студентов, обучающихся по программам бакалавриата, специалитета, магистратуры, в общей численности студентов (приведенный контингент)</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                      <div className={styles.spec_item} key={year_data.year}><div>{year_data.year}</div></div>
                                    )}
                                    <div className={classNames(styles.spec_item, styles.about_header_dynamic)}><div>{dynamic[dynamic.length-1].year}|Динамика</div></div>
                                  </td>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                        <div className={styles.about_table_small_value}>{year_data.e_3_val}</div>
                                    )}
                                    {dynamic[dynamic.length-1].e_3_d > 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.positive}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_3_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>+ {dynamic[dynamic.length-1].e_3_d}%</span>
                                        </div>
                                      </div>
                                    }
                                    {dynamic[dynamic.length-1].e_3_d < 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.negative}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_3_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_3_d}%</span>
                                        </div>
                                      </div>
                                    }
                                  </td>
                                </tr>

                                <tr>
                                  <td className={styles.about_table_name} colSpan={2}>
                                    <div>
                                        <span>Е.4. Финансово-экономическая деятельность</span>
                                        <p>Доходы образовательной организации из всех источников в расчетена одного НПР</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                      <div className={styles.spec_item} key={year_data.year}><div>{year_data.year}</div></div>
                                    )}
                                    <div className={classNames(styles.spec_item, styles.about_header_dynamic)}><div>{dynamic[dynamic.length-1].year}|Динамика</div></div>
                                  </td>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                        <div className={styles.about_table_small_value}>{year_data.e_4_val}</div>
                                    )}
                                    {dynamic[dynamic.length-1].e_4_d > 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.positive}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_4_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>+ {dynamic[dynamic.length-1].e_4_d}%</span>
                                        </div>
                                      </div>
                                    }
                                    {dynamic[dynamic.length-1].e_4_d < 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.negative}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_4_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_4_d}%</span>
                                        </div>
                                      </div>
                                    }
                                  </td>
                                </tr>

                                <tr>
                                  <td className={styles.about_table_name} colSpan={2}>
                                    <div>
                                        <span>Е.5. Заработная плата ППС</span>
                                        <p>Отношение заработной платы профессорско-преподавательского состава к средней заработной плате по экономике региона</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                      <div className={styles.spec_item} key={year_data.year}><div>{year_data.year}</div></div>
                                    )}
                                    <div className={classNames(styles.spec_item, styles.about_header_dynamic)}><div>{dynamic[dynamic.length-1].year}|Динамика</div></div>
                                  </td>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                        <div className={styles.about_table_small_value}>{year_data.e_5_val}</div>
                                    )}
                                    {dynamic[dynamic.length-1].e_5_d > 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.positive}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_5_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>+ {dynamic[dynamic.length-1].e_5_d}%</span>
                                        </div>
                                      </div>
                                    }
                                    {dynamic[dynamic.length-1].e_5_d < 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.negative}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_5_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_5_d}%</span>
                                        </div>
                                      </div>
                                    }
                                  </td>
                                </tr>

                                <tr>
                                  <td className={styles.about_table_name} colSpan={2}>
                                    <div>
                                        <span>Е.8. Дополнительный показатель</span>
                                        <p>Численность сотрудников, из числа профессорско-преподавательского состава (приведенных к доле ставки), имеющих ученые степени кандидата или доктора наук, в расчете на 100 студентов</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                      <div className={styles.spec_item} key={year_data.year}><div>{year_data.year}</div></div>
                                    )}
                                    <div className={classNames(styles.spec_item, styles.about_header_dynamic)}><div>{dynamic[dynamic.length-1].year}|Динамика</div></div>
                                  </td>
                                  <td>
                                    {dynamic.filter(year_data => year_data.year != dynamic[dynamic.length-1].year).map(year_data =>
                                        <div className={styles.about_table_small_value}>{year_data.e_8_val}</div>
                                    )}
                                    {dynamic[dynamic.length-1].e_8_d > 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.positive}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_8_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>+ {dynamic[dynamic.length-1].e_8_d}%</span>
                                        </div>
                                      </div>
                                    }
                                    {dynamic[dynamic.length-1].e_8_d < 0 &&
                                      <div className={styles.about_td_21  + ' ' + styles.negative}>
                                        <div>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_8_val}</span>
                                          <span className={styles.about_td_arrow }></span>
                                          <span className={styles.about_table_small_value}>{dynamic[dynamic.length-1].e_8_d}%</span>
                                        </div>
                                      </div>
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      }
                    </div>
                    <div className={styles.info_speciality_btn + ' ' + (!aboutTable ? styles.info_speciality_btn_active : '')} onClick={() => {toggleTable('aboutTable')}}></div>
                </div>
                <div className={styles.about_table_footer}>
                    <p className={styles.text_sm}>* Информационно-аналитические материалы по результатам проведения мониторинга
                        эффективности деятельности образовательных организаций высшего образования</p>
                </div>
              </div>
            }
            {dynamicFlag == 0 &&
              <p></p>
            }



            {dynamic && !hideImages &&
              <div className={styles.org_dynamics}>
                  <div className={styles.org_dynamics_header }>
                    <h2  className={styles.header_md} id="about_dynamics">Динамика организации</h2>
                  </div>
                  <div>
                    <div className={styles.org_dynamics_chart + ' ' + styles.yellow_card}>
                      <LineChart data={dynamic} number={1} />
                    </div>
                    <br />
                    <div className={styles.org_dynamics_chart + ' ' + styles.yellow_card}>
                      <LineChart data={dynamic} number={4} />
                    </div>
                    <br />
                    <div className={styles.org_dynamics_chart + ' ' + styles.yellow_card}>
                      <LineChart data={dynamic} number={5} />
                    </div>
                  </div>
              </div>
            }
            {!hideImages &&
              <div className={styles.org_expenses}>
                  <div className={styles.org_expenses_header}>
                        <h2 className={styles.header_md} id="about_costs_environment">Расходы организации на обеспечение доступной среды</h2>
                  </div>
                  <div className={styles.org_expenses_chart + ' ' + styles.yellow_card}>
                    <LineChart data={dynamic} number={2} />
                  </div>
              </div>
            }
            <div className={styles.org_info_access}>
                  <div className={styles.org_info_access_header }>
                      <h2  className={styles.header_md} id="about_access_information">Обеспечение условий доступности информации для инвалидов и лиц с ОВЗ</h2>
                  </div>
                  <div className={styles.info_access_wrapper  + ' ' + styles.yellow_card}>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>На официальном сайте размещен видеопаспорт доступности объектов вуза для инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_25 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_25 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Доступность библиотеки для обучающихся инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_26 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_26 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие медицинского пункта, доступного для инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_27 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_27 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Соответствие официального сайта вуза требованиям представления информации на интернет-ресурсах для инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_28 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_28 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие “горячей линии” по вопросам приема и обучения инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_29 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_29 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие отдела (структурного подразделения) по сопровождению обучающихся инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_30 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_30 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Ответственный за организацию сопровождения обучающихся инвалидов и лиц с ОВЗ в вузе</p>
                          </div>
                          {school_data.col_31 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_31 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>В вузе утверждена программа(ы) содействия трудоустройству выпускников-инвалидов</p>
                          </div>
                          {school_data.col_32 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_32 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
              </div>
            </div>
            <div className={styles.org_info_access  + ' ' + styles.org_info_standarts}>
                  <div className={styles.org_info_access_header }>
                      <h2  className={styles.header_md} id="about_regulatory_support">Нормативное обеспечение деятельности организации 
                          по обучению инвалидов и лиц с ОВЗ</h2>
                  </div>
                  <div className={styles.info_access_wrapper  + ' ' + styles.yellow_card}>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие регламентов, описывающих порядок поступления инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_34 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_34 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие порядка проведения вступительных испытаний для инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_35 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_35 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие положения об обучении инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_36 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_36 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие нормативных документов, регламентирующих организацию и проведение текущего контроля знаний и промежеточной аттестации с учетом наличия контингента обучающихся инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_37 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_37 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие нормативных документов о практике обучающихся, в том числе инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_38 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_38 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          }
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие нормативных документов по государственной итоговой аттестации выпускников-инвалидов</p>
                          </div>
                          {school_data.col_39 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_39 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          } 
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие утвержденных паспортов доступности зданий</p>
                          </div>
                          {school_data.col_40 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_40 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          }
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие утвержденного плана мероприятий (дорожная карта) по развитию и инклюзивного образования в вузе</p>
                          </div>
                          {school_data.col_41 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_41 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          }
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>В программу развития вуза включены мероприятия по развитию условий доступности объектов вуза и образовательных услуг для инвалидов и лиц с ОВЗ</p>
                          </div>
                          {school_data.col_42 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_42 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          }
                      </div>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Предоставлена возможность использования обучающимися инвалидами и лицами с ОВЗ учебных материалов в альтернативных форматах (текст, аудиофайлы, субтитры, тифлокомментарии)</p>
                          </div>
                          {school_data.col_43 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_43 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          }
                      </div>
                  </div>
            </div>
            <div className={styles.org_info_access + ' ' + styles.org_info_support}>
                  <div className={styles.org_info_access_header }>
                      <h2  className={styles.header_md} id="about_initiative">Поддержка инициатив студентов</h2>
                  </div>
                  <div className={styles.info_access_wrapper + ' ' + styles.yellow_card}>
                      <div className={styles.info_access_row}>
                          <div className={styles.info_access_text}>
                              <p className={styles.text_md}>Наличие коворкинга для реализации предпринимательских инициатив студентов и выпускников вуза, в том числе инвалидов и лиц с ОВЗ </p>
                          </div>
                          {school_data.col_44 == true && 
                            <div className={styles.info_access_value}>
                                <span className={styles.info_access_rating}></span>
                                <span className={styles.text_md}>Да</span>
                            </div>
                          }
                          {!school_data.col_44 &&
                            <div className={styles.info_access_value + ' ' + styles.negative}>
                                <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                                <span className={styles.text_md}>Нет</span>
                            </div>
                          }
                      </div>
                  </div>
            </div>
            <div className={styles.org_sports}>
                <div className={styles.org_sports_header }>
                  <h2  className={styles.header_md} id="about_sport">Развитие адаптивной физической культуры и спорта</h2>
                </div>
                {!hideImages &&
                  <div className={styles.org_sports_chart  + ' ' + styles.yellow_card}>
                    <LineChart data={dynamic} number={3} />
                  </div>
                }
                <div className={styles.info_access_wrapper  + ' ' + styles.yellow_card}>
                  <div className={styles.info_access_row}>
                      <div className={styles.info_access_text}>
                          <p className={styles.text_md}>Наличие в вузе программы развития адаптивной физической культуры и спорта </p>
                      </div>
                      {school_data.col_45 == true && 
                        <div className={styles.info_access_value}>
                            <span className={styles.info_access_rating}></span>
                            <span className={styles.text_md}>Да</span>
                        </div>
                      }
                      {!school_data.col_45 &&
                        <div className={styles.info_access_value + ' ' + styles.negative}>
                            <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                            <span className={styles.text_md}>Нет</span>
                        </div>
                      }
                  </div>
                  <div className={styles.info_access_row}>
                      <div className={styles.info_access_text}>
                          <p className={styles.text_md}>Наличие программ для занятий адаптивной физической культурой и спортом для обучающихся инвалидов и лиц с ОВЗ</p>
                      </div>
                      {school_data.col_46 == true && 
                        <div className={styles.info_access_value}>
                            <span className={styles.info_access_rating}></span>
                            <span className={styles.text_md}>Да</span>
                        </div>
                      }
                      {!school_data.col_46 &&
                        <div className={styles.info_access_value + ' ' + styles.negative}>
                            <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                            <span className={styles.text_md}>Нет</span>
                        </div>
                      }
                  </div>
                  <div className={styles.info_access_row}>
                      <div className={styles.info_access_text}>
                          <p className={styles.text_md}>Наличие онлайн-курсов по адаптивной физической культуре и спорту</p>
                      </div>
                      {school_data.col_47 == true && 
                        <div className={styles.info_access_value}>
                            <span className={styles.info_access_rating}></span>
                            <span className={styles.text_md}>Да</span>
                        </div>
                      }
                      {!school_data.col_47 &&
                        <div className={styles.info_access_value + ' ' + styles.negative}>
                            <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                            <span className={styles.text_md}>Нет</span>
                        </div>
                      }
                  </div>
                  <div className={styles.info_access_row}>
                      <div className={styles.info_access_text}>
                          <p className={styles.text_md}>Наличие секций по адаптивному спорту</p>
                      </div>
                      {school_data.col_48 == true && 
                        <div className={styles.info_access_value}>
                            <span className={styles.info_access_rating}></span>
                            <span className={styles.text_md}>Да</span>
                        </div>
                      }
                      {!school_data.col_48 &&
                        <div className={styles.info_access_value + ' ' + styles.negative}>
                            <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                            <span className={styles.text_md}>Нет</span>
                        </div>
                      }
                  </div>
                  <div className={styles.info_access_row}>
                      <div className={styles.info_access_text}>
                          <p className={styles.text_md}>Наличие спортсменов и сборных спортивных команд вуза из числа обучающихся инвалидов и лиц с ОВЗ, учавствующих в спортивных соревнованиях различного уровня </p>
                      </div>
                      {school_data.col_49 == true && 
                        <div className={styles.info_access_value}>
                            <span className={styles.info_access_rating}></span>
                            <span className={styles.text_md}>Да</span>
                        </div>
                      }
                      {!school_data.col_49 &&
                        <div className={styles.info_access_value + ' ' + styles.negative}>
                            <span className={styles.info_access_rating + ' ' + styles.negative}></span>
                            <span className={styles.text_md}>Нет</span>
                        </div>
                      }
                  </div>
              </div>
              
              <br />
              <p><b>Источники данных:</b></p>
              <p>Мониторинг деятельности образовательных организаций высшего образования по вопросам приема, обучения и трудоустройства лиц с инвалидностью и ограниченными возможностями здоровья, формируемый на основе сведений, предоставляемых организациями</p>
              <p>Форма статистического наблюдения № ВПО-1 «Сведения об образовательной организации, осуществляющей образовательную деятельность по образовательным программам высшего образования – программам бакалавриата, программам специалитета, программам магистратуры» (мониторинг ВПО-1)</p>
            </div>
          </div>
        }
        {activeTab == 'gallery' && school_data && school_data.gallery &&
          <div className={styles.gallery_wrapper}>
            <ImagesList images={school_data.gallery || []} />
          </div>
        }
        {activeTab == 'help' && 
          <div className={styles.help}>
            <div className={styles.help_title_box}>
                {!hideImages &&
                  <div className={styles.help_picture}>
                    <div className={styles.help_bg}></div>
                    <div className={styles.help_img}></div>
                  </div>
                }
                <div className={styles.help_title_content + ' ' + styles.yellow_card}>
                  <h2>Анкета качества готовности вуза к обучению студентов-инвалидов и лиц с ОВЗ</h2>
                  <p>В целях обеспечения обратной связи со студентами и улучшения качества предоставляемых
                    услуг просим Вас пройти предлагаемый опрос</p>
                </div>
                                
            </div>
            <div className={styles.help_form}>
              {questions && !formSubmit &&
                <form>
                  <div className={styles.help_form_items}>
                    {questions.map(q =>
                      <div className={styles.help_form_item + ' ' + styles.radio_question + ' ' + styles.yellow_card} key={q.id + '_q'}>
                        <div className={styles.form_item_title}>
                          {q.question}
                        </div>

                        <div className={styles.form_item_options}>
                          {q.answers.map(a =>
                            <div className={styles.form_item_option} key={a.id + '_a'}>
                              <input type="radio" id={a.type_id + "_" + a.id} value={a.weight} name={"q_" + q.id} onChange={(e) => handleInputChange(e)}/>
                              <label className={styles.text_md} htmlFor={a.type_id + "_" + a.id}>{a.answer}</label>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className={styles.help_form_item + ' ' + styles.textarea_question + ' ' + styles.yellow_card}>
                      <label htmlFor="help_review">Будем рады, если Вы также оставите свое мнение о сайте и комментарий по возможным улучшениям</label>
                      <textarea className={classNames(styles.yellow_card_textarea, styles.yellow_card)} value={formValues['review']} name='review' onChange={(e) => handleInputChange(e)} id="help_review" rows="10"></textarea>
                    </div>
                  </div>
                  <div>
                    <button onClick={(e) => getFormValues(e)}>Отправить</button>
                  </div>
                  {!canSubmitStatus &&
                    <div className={styles.yellow_card + ' ' + styles.help_form_alert}>
                      Пожалуйста, заполните все поля формы!
                    </div>
                  }
                </form>
              }
              {formSubmit &&
                    <div className={classNames(styles.help_title_content, styles.yellow_card)}>
                      <h2>Спасибо за уделенное время, Ваше мнение очень значимо для нас!</h2>
                      <p>Ваша оценка позволит выбрать правильное направление в работе над улучшением платформы.</p>
                    </div>
                  }
            </div>
            <div className={styles.help_footer_bg}></div>
          </div>
        }
        {activeTab == 'branches' && school_data != null && school != null &&
          <div className={styles.branches_map}>
            <Loaf>
              <Link to="#" onClick={(e) => {toggleTab(e, 'common')}}>Назад</Link>
            </Loaf>
            <div className={styles.main} onClick={(e) => toggleSelecteBranch(e, 0)} id="map">
              <span className={styles.head}>Головной: </span>{school_data.address}
            </div>
            
            <GoogleApiWrapper data={school_data} school={school} center={selectedBranch} />
            
            {school_data.branches_addresses.length > 0 && 
              <>
                <h2 id="branches">Филиалы</h2>
                {school_data.branches_addresses.map(branch => 
                  <>
                    {branch.post && branch.full_name &&
                      <div>
                        <ScrollIntoView selector="#map" className={styles.branch} onClick={(e) => toggleSelecteBranch(e, branch.org_id)}>{branch.full_name}</ScrollIntoView>
                        <ul>
                          {branch.post && <li>{branch.post}</li>}
                          {branch.website && <li><a href={branch.website} target="_blank">Сайт</a></li>}
                        </ul>
                      </div>

                    }
                  </>
                )}
              </>
            }
            {school_data.branches_addresses.length == 0 && 
              <h2 id="branches">У организации нет филиалов</h2>
            }
          </div>
        }
      </div>
      <div className={styles.info_right}>
      {activeTab == 'common' && 
        <div>
          <div className={styles.info_right_menu}>
            <div className={styles.info_right_menu_header}>
              <h3>Сведения об образовательной организации</h3>
            </div>
            <div className={styles.info_right_menu_body}>
              <ul>
                <li>
                  <ScrollIntoView selector="#common_params" className={styles.info_right_menu_item}>
                    Оценка совокупности параметров готовности организации к обучению лиц с инвалидностью и ОВЗ
                  </ScrollIntoView>
                </li>
                <li>
                  <ScrollIntoView selector="#common_name_1" className={styles.info_right_menu_item}>
                    Данные о приёме на 2023-2024 уч.г.
                  </ScrollIntoView>
                </li>
                <li>
                  <ScrollIntoView selector="#common_name_2" className={styles.info_right_menu_item}>
                    Данные о количестве студентов с инвалидностью и ограниченными возможностями здоровья по специальностям и направлениям подготовки
                  </ScrollIntoView>
                </li>
              </ul>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className={styles.info_right_map}>
            <a href="#" className={classNames(buttons.main, styles.button_main)} onClick={(e) => {toggleTab(e, 'branches')}}>Филиалы на карте</a>
          </div>
        </div>
      }
      {activeTab == 'about' && 
        <div className={styles.info_right_menu}>
          <div className={styles.info_right_menu_header}>
              <h3>Сведения об образовательной организации</h3>
          </div>
          <div className={styles.info_right_menu_body}>
              <ul>
                  <li>
                      <ScrollIntoView selector="#about_monitoring" className={styles.info_right_menu_item}>Позиции организации по основным показателям</ScrollIntoView>
                  </li>
                  <li>
                      <ScrollIntoView selector="#about_dynamics" className={styles.info_right_menu_item}>Динамика организации</ScrollIntoView>
                  </li>
                  <li>
                      <ScrollIntoView selector="#about_costs_environment" className={styles.info_right_menu_item}>Расходы организации на обеспечение доступной среды</ScrollIntoView>
                  </li>
                  <li>
                      <ScrollIntoView selector="#about_access_information" className={styles.info_right_menu_item}>Обеспечение условий доступности информации для инвалидов и лиц с ОВЗ</ScrollIntoView>
                  </li>
                  <li>
                      <ScrollIntoView selector="#about_regulatory_support" className={styles.info_right_menu_item}>Нормативное обеспечение деятельности организации по обучению инвалидов и лиц
                          с ОВЗ</ScrollIntoView>
                  </li>
                  <li>
                      <ScrollIntoView selector="#about_initiative" className={styles.info_right_menu_item}>Поддержка инициатив студентов</ScrollIntoView>
                  </li>
                  <li>
                      <ScrollIntoView selector="#about_sport" className={styles.info_right_menu_item}>Развитие адаптивной физической культуры и спорта</ScrollIntoView>
                  </li>
              </ul>
              <div></div>
              <div></div>
              <div></div>
          </div>
        </div>
      }
      {/* {activePageBranches == 'on' && school_data != null && */}
      {activeTab == 'branches' && school_data != null &&
        <div>
          <div className={styles.info_right_menu}>
            <div className={styles.info_right_menu_header}>
              <h3>Сведения об образовательной организации</h3>
            </div>
            <div className={styles.info_right_menu_body}>
              <ul>
                <li>
                  <ScrollIntoView selector="#map" className={styles.info_right_menu_item}>
                    Карта
                  </ScrollIntoView>
                </li>
                <li>
                  <ScrollIntoView selector="#branches" className={styles.info_right_menu_item}>
                    Филиалы
                  </ScrollIntoView>
                </li>
              </ul>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      }
      </div>
  </div>
      </div>
    </div>
  )
}