import React, { Component } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import qs from 'query-string';
import { Set, List } from 'immutable';

import { path } from '../Routes'
import I18n from '../I18n'

import styles from './Compare.module.css';
import tabs from '../Tabs.module.css';

class Compare extends Component {
  state = {
    searchable: false,
    section: 'regions',
    search: null,
    input: '',
    values: {
      districts: Set(),
      regions: Set(),
      schools: Set(),
      rumcs: Set(),
    }
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('stats_compare_path', {}, true) + '?' + this.props.types.map(type => type + '=true').join('&'), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { schools, regions, districts, rumcs } = res.data;

        this.setState({ schools, regions, districts, rumcs });

        this._asyncRequest = null;
      });
  }

  handleItemChange = e => {
    const target = e.target;
    const { value, checked, name } = target;

    let { values } = this.state;


    if(checked) {
      values = { ...this.state.values, [name]: this.state.values[name].add(value) };
    } else {
      values = { ...this.state.values, [name]: this.state.values[name].delete(value) };
    }

    let search = qs.stringify({section: this.state.section, regions: values.regions.valueSeq().toArray(), districts: values.districts.valueSeq().toArray(), schools: values.schools.valueSeq().toArray(), rumcs: values.rumcs.valueSeq().toArray()});
    if(search) search = `?${search}`;

    if(this.state.search != search) {
      this.props.history.push(this.props.location.pathname + search);
    }
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  static getDerivedStateFromProps(props, state) {
    if(props.search !== state.search) {
      const query = qs.parse(props.search);

      // console.log((Array.isArray(query.regions) ? Set(query.regions) : Set.of(query.regions)).valueSeq().toArray());
      // console.log(Set.of(query.regions).valueSeq().toArray());
      // console.log(List(query.regions).valueSeq().toArray());

      return {
        search: props.search,
        section: query.section,
        values: {
          regions: Array.isArray(query.regions) ? Set(query.regions) : Set.of(query.regions),
          districts: Array.isArray(query.districts) ? Set(query.districts) : Set.of(query.districts),
          schools: Array.isArray(query.schools) ? Set(query.schools) : Set.of(query.schools),
          rumcs: Array.isArray(query.rumcs) ? Set(query.rumcs) : Set.of(query.rumcs),
        }
      }
    }

    return null;
  }

  filterList = (e) => {
    this.setState({ input: e.target.value.toLowerCase() })
  }

  handleSection = section => {
    console.log('sect');
    this.setState({ section });
  }

  render () {
    const { searchable, section, values, input } = this.state;
    let list = null;

    if (section && this.state[section]) {
      list = this.state[section];
    }

    return (
      <div className={styles.root}>
        <div className={tabs.root}>
          { this.state.districts &&
            <div onClick={() => this.handleSection('districts')} className={tabs.tab}>
              Фед. округа
            </div>
          }

          { this.state.regions &&
            <div onClick={() => this.handleSection('regions')} className={tabs.tab}>
              Регионы
            </div>
          }

          { this.state.schools &&
            <div onClick={() => this.handleSection('schools')} className={tabs.tab}>
              Вузы
            </div>
          }

          { this.state.rumcs &&
            <div onClick={() => this.handleSection('rumcs')} className={tabs.tab}>
              РУМЦ
            </div>
          }
        </div>

        {section && list &&
          <div>
            <div className={styles.current}>
              <ul>
                {list.filter(i => values[section].includes(i.id.toString())).map(item =>
                  <li>
                    {item.title}
                  </li>
                )}
              </ul>
            </div>

            <div className={styles.new}>
              <div className={classNames(styles.search, { [styles.searchable]: searchable })}>
                <div className={styles.seaicon}  onClick={() => this.setState({ searchable: false })}></div>
                <input type="text" placeholder="Введите часть названия..." onChange={this.filterList} />
              </div>

              <div className={styles.what} onClick={() => this.setState({ searchable: true })}>
                {I18n.t(`stats.new.${section}`)}
              </div>
            </div>

            {searchable &&
              <div className={styles.list}>
                <ul>
                  {list.filter(i => input === '' || i.title.toLowerCase().includes(input)).slice(0, 100).map(item =>
                    <li key={`${section}_${item.id}`} className={classNames(styles.item, { [styles.selected]: values[section].includes(item.id.toString()) })}>
                      <label>
                        <input type="checkbox" name={section}  onChange={this.handleItemChange} checked={values[section].includes(item.id.toString())} value={item.id} />
                        {item.title}
                      </label>
                    </li>
                  )}
                </ul>
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

export default Compare
