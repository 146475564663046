import React from 'react';
import PropTypes from 'prop-types';

import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import update from 'immutability-helper';

import I18n from '../../I18n';
import { path } from '../../Routes';

import Loaf from '../../Loaf/Loaf';
import Attachments from '../../Attachments/Attachments';

import page from '../../Page.module.css';
import form from '../../Form.module.css';
import buttons from '../../Buttons.module.css';

class Form extends React.Component {
  state = {
    rumcs: [],
    values: {
      title: '',
    },
    submit: {
      url: path('account_inquiries_path', {}, true),
      method: 'post',
    },
    send: false,
    redirect_to: null
  }

  componentDidMount() {
    axios.get(path('rumcs_path', {}, true)).then(res => {
      this.setState({ rumcs: res.data.rumcs });
    });

    if(this.props.id) {
      this._loadAsyncData(this.props.id);
    }
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();
      axios.get(path('edit_account_inquiry_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState(prevState => ({
          inquiry: res.data.inquiry,
          values: {
            title: res.data.inquiry.title,
          },
          submit: {
            method: 'patch',
            url: path('account_inquiry_path', {id: this.props.id}, true),
          }
        }));

        this._asyncRequest = null;
      });
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }));
  }

  handleSubmit = (e) => {
    if(this.canSubmit()) {
      this.setState({
        send: true
      });

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        data: {
          inquiry: this.state.values
        },
      }).then(
        res => {
          this.props.history.push(res.headers.location)
        }
      );
    }

    e.preventDefault();
  }

  canSubmit = () => {
    return (
      this.state.values.title
    )
  }

  render() {
    const { inquiry } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={path('account_inquiries_path')}>{I18n.t('inquiries.title')}</Link>
          </Loaf>
          <div className={page.title}>
            <h1>
              {inquiry ? 'Редактирование запроса' : 'Новый запрос' }
            </h1>
          </div>

           <div className={form.tight}>
            <form className="form" onSubmit={this.handleSubmit}>
              <div className="input">
                <div className="input_label">
                  <label>
                    {I18n.t('simple_form.labels.inquiry.title')}
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="title" value={this.state.values.title} onChange={this.handleInputChange} />
                </div>
              </div>


              <div className="input_submit">
                <input type="submit" value="Сохранить" className={buttons.main} disabled={!this.canSubmit()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
