import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Index'
import Show from './Show'

class Vacancy extends Component {
  render () {
    return (
      <Switch>
        <Route path={decodeURIComponent(Routes.vacancy_path)} render={props => <Show id={props.match.params.id} />} />
        <Route path={decodeURIComponent(Routes.vacancy_index_path)} component={Index} exact strict />
      </Switch>
    )
  }
}

export default Vacancy
