import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';

import VerificateInput from './VerificateInput';

import styles from '../Section.module.css';
import form from '../../Form.module.css';

class Finance extends React.Component {
  state = {
    show: true,
    values: {
      bbs: this.props.values.bbs || '',
      tech: this.props.values.tech || '',
      empl: this.props.values.empl || '',
      meth: this.props.values.meth || '',
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const values = update(this.state.values, {
      [name]: {
        $set: value
      }
    });

    // this.setState({ values }, () => { console.log(this.state.values) });
    this.setState({ values });


  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.onValuesChange) this.props.onValuesChange(this.props.prefix, this.state.values);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state != nextState);
  }

  render () {
    const { values, show } = this.state;
    const { num, monitoring } = this.props;

    return (
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div className={styles.title} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {num}. Финансово-экономическое обеспечение

          <div className={classNames(styles.label, { [styles.ok]: Object.values(values).filter(v => v).length == Object.values(values).length })}>
            {Object.values(values).filter(v => v).length} из {Object.values(values).length}
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.desc}>
            Указывать данные за {monitoring.prev_year} календарный год. Заполнять поля нужно в рублях.
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.1. Общие расходы вуза на обеспечение безбарьерной среды
            </div>

            <div className={form.input}>
              <VerificateInput name="bbs" value={values.bbs} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.2. Общие расходы вуза на обеспечение техническими средствами обучения лиц с ОВЗ и инвалидностью
            </div>

            <div className={form.input}>
              <VerificateInput name="tech" value={values.tech} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.3. Общие расходы вуза на кадровое сопровождение обучения лиц с ОВЗ и инвалидностью
            </div>

            <div className={form.input}>
              <VerificateInput name="empl" value={values.empl} onChange={this.handleInputChange} />
            </div>
          </div>

          <div className={form.elem}>
            <div className={form.label}>
              {num}.4. Общие расходы вуза на специализированное учебно-методическое обеспечение
            </div>

            <div className={form.input}>
              <VerificateInput name="meth" value={values.meth} onChange={this.handleInputChange} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Finance.defaultProps = {
  prefix: 'finance',
  num: '2'
};

export default Finance
