import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import qs from 'query-string'

import Loaf from '../../Loaf/Loaf'
import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import List from './List'

import page from '../../Page.module.css'

class Index extends Component {
  state = {
    events: null,
    search: this.props.location.search,
    filters: null
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('media_account_events_path', {}, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search) })
    this._asyncRequest = null

    this.setState({ ...res.data })
  }

  static getDerivedStateFromProps (props, state) {
    if (props.location.search !== state.search) {
      return {
        events: null,
        search: props.location.search
      }
    }

    return null
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.events === null) this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  render () {
    const { events, filters } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">{I18n.t('account.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('events.title')}
            </h1>

            <Helmet>
              <title>{I18n.t('events.title')}</title>
            </Helmet>
          </div>

          <div>
            Для выполнения требований госзадания, необходимо дополнить информацию о событиях, ссылками на источник в СМИ, которые освещали событие на своих ресурсах. Для добавления ссылок, перейдите на страницу редактирования события, и в пункте «Ссылки на СМИ» нажмите на кнопку «Добавить ссылку на СМИ». Ссылок может быть сколько угодно, но событие учитывается при подсчете, если есть хотя бы одна.
          </div>

          <div>
            <List items={events} filters={filters} {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

Index.propTypes = {
  location: PropTypes.object
}

export default Index
