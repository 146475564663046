import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'
import Redraft from '../../Redraft'

import Modules from './Show/Modules'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
import styles from './Show.module.css'

export default function Show () {
  const { id } = useParams()
  const cancelToken = useRef(axios.CancelToken.source())

  const [plan, setPlan] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('account_plan_path', { id }, true), { cancelToken: cancelToken.current.token })

      setPlan(data.plan)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
          {/* <Link to="account_plans_path">{I18n.t('account.plans.title')}</Link> */}
        </Loaf>

        {plan &&
          <>
            <div className={page.title}>
              <h1>
                {plan.title}
              </h1>

              <Helmet>
                <title>{plan.title}</title>
              </Helmet>

              {(plan.can_edit || plan.plan_module.can_new || plan.plan_theme.can_new) &&
                <div className={page.links}>
                  {plan.can_edit &&
                    <Link className={buttons.main} to="edit_account_plan_path" params={{ id: plan.id }}>Редактировать</Link>
                  }

                  {plan.plan_module.can_new &&
                    <Link className={buttons.main} to="new_account_plan_plan_module_path" params={{ plan_id: plan.id }}>Добавить модуль</Link>
                  }

                  {plan.plan_theme.can_new &&
                    <Link className={buttons.main} to="new_account_plan_plan_theme_path" params={{ plan_id: plan.id }}>Добавить тему</Link>
                  }
                </div>
              }
            </div>

            <div className={page.common}>
              {plan.text &&
                <div className={styles.text}>
                  <Redraft src={plan.text} />
                </div>
              }
            </div>

            {plan.plan_modules.length > 0 &&
              <div>
                <h2>
                  Содержание учебных модулей
                </h2>

                <Modules planModules={plan.plan_modules} />
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}
