import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import querystring from 'querystring'
import classNames from 'classnames'

import { path } from '../Routes'

import { DebounceInput } from 'react-debounce-input'

import styles from './Form.module.css'
import buttons from '../Buttons.module.css'
import form from '../Form.module.css'

export default function Form () {
  const location = useLocation()
  const history = useHistory()

  const [q, setQ] = useState(querystring.parse(location.search.slice(1)).q || '')

  useEffect(() => {
    setQ(querystring.parse(location.search.slice(1)).q || '')
  }, [location])

  const handleSubmit = e => {
    e.preventDefault()
  }

  const handleChange = ({ target: { value } }) => {
    if (q !== value) {
      if (value === '') {
        history.push(path('root_path'))
      } else {
        history.push(path('search_path', { query: { q: value } }))
      }
    }
  }

  const { innerWidth: width, innerHeight: height } = window;
  var _placeholder = "Введите название вуза, профессии или учебно-методического материала.."
  if (width <= 768) {
    _placeholder = "Поиск"
  }

  return (
    <div className={styles.root}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.item}>
          <label className={classNames(form.label, styles.label)} htmlFor="search">
            Строка поиска:
          </label>
          <div className={classNames(form.input, styles.input)}>
            <DebounceInput
              id="search"
              value={q}
              type="text"
              onChange={handleChange}
              placeholder={_placeholder}
              minLength={3}
              debounceTimeout={300} />
          </div>
        </div>
        <div className={styles.action}>
          <input type="submit" className={classNames(buttons.main, styles.button, styles.button_main)} value="Найти" />
        </div>
      </form>
    </div>
  )
}
