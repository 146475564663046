import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import TermsIndex from './TermsIndex'
import TermsRumc from './TermsRumc'
import TermsRumcVideo from './TermsRumcVideo'

export default function Terms () {
  return (
    <Switch>
      <Route path={Routes.terms_path} component={TermsIndex} exact strict />
      <Route path={`${Routes.terms_path}/румц`} component={TermsRumc} />
      <Route path={Routes.terms_video_path} component={TermsRumcVideo} />
    </Switch>
  )
}
