import React from 'react'
import { Link } from 'react-router-dom'

import { Routes, path } from '../../Routes'
import I18n from '../../I18n'

import styles from './Index.module.css'
import file_style from '../../Schools/Index.module.css'

class Index extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      show5: false,
      show4: false,
      showFirst: false,
      showSecond: false,
      youtubeID: 'DQz71mb699A'
    }
  }

  eShow5( ) {
    this.setState({
      show5 : !this.state.show5,
      // show4 : false,
      // showFirst : false,
      // showSecond : false
    })
  }

  eShow4( ) {
    this.setState({
      show4 : !this.state.show4,
      // show5 : false,
      // showFirst : false,
      // showSecond : false
    })
  }

  eShowFirst( ) {
    this.setState({
      showFirst : !this.state.showFirst,
      // show5 : false,
      // showSecond : false,
      // show4: false
    })
  }

  eShowSecond( ) {
    this.setState({
      showSecond : !this.state.showSecond,
      // show5 : false,
      // showFirst: false,
      // show4: false
    })
  }

  render () {
    const { show5, show4, showFirst, showSecond, youtubeID } = this.state
    return (
      <div className={styles.root}>
        <div className={styles.text}>
          <div className={styles.about}>
            <div className={styles.aboutText}>
              <h2>
                О конкурсе
              </h2>

              <p>Инклюзивное высшее образование дает молодым людям возможность не только овладеть квалифицированной профессией и расти в ней, но и найти себя в науке, в бизнесе, в общественной деятельности или в менеджменте. Индивидуальный выбор траектории профессионального развития максимально раскрывает потенциал личности.</p>
              <p>Созданию этих условий способствует <a href="https://profzavtra.nstu.ru/" target="_blank">VII Всероссийский сетевой конкурс студенческих проектов «Профессиональное завтра»</a> с участием студентов с инвалидностью, который служит площадкой трансляции ценности профессионального развития студентов вузов в инклюзивной среде.</p>
              <p>Участие в конкурсе «Профессиональное завтра» позволит студентам, в том числе с инвалидностью, не только продемонстрировать свои инновационные решения, но и внести свой вклад в создание современного инклюзивного молодежного сообщества, получив уникальный опыт работы в инклюзивных командах. Победители получат ценные призы от организаторов и партнеров конкурса, а также возможность напрямую познакомиться с работодателями и шанс получить перспективные предложения по трудоустройству.</p>
              

              <p>В 2024 году Оператором Конкурса VII Всероссийского сетевого конкурса студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью является ФГБОУ ВО «Новосибирский государственный технический университет».</p>
              <p>Регистрация участников и оформление электронной заявки осуществляется на сайте Конкурса: <a href="https://profzavtra.nstu.ru/" target='_blank'>https://profzavtra.nstu.ru/</a></p>
              <p>Для получения дополнительной информацию об условиях участия в Конкурсе обращайтесь к организаторам по e-mail или телефонам, указанным в разделе <a href="https://rumc.ncfu.ru/kontakty/" target='_blank'>Контакты</a> на сайте конкурса.</p>

              <hr />

              <p>В 2023 году Оператором Конкурса VI Всероссийского сетевого конкурса студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью является ФГАОУ ВО «Северо-кавказский федеральный университет».</p>
              <p>Регистрация участников и оформление электронной заявки осуществляется на сайте Конкурса: <a href="https://rumc.ncfu.ru/login/register/" target='_blank'>https://rumc.ncfu.ru/login/register/</a></p>
              <p>Для получения дополнительной информацию об условиях участия в Конкурсе обращайтесь к организаторам по e-mail или телефонам, указанным в разделе <a href="https://rumc.ncfu.ru/kontakty/" target='_blank'>Контакты</a> на сайте конкурса.</p>

              <hr />

              <iframe 
                className={styles.video}
                title='Youtube player'
                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}>
              </iframe>

              <p>В 2022 году Оператором Конкурса V Всероссийского сетевого конкурса студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью является ФГАОУ ВО «Тюменский государственный университет».</p>
              <p>Регистрация участников и оформление электронной заявки осуществляется на сайте Конкурса: <a href="https://www.utmn.ru/professional-tomorrow/" target='_blank'>https://www.utmn.ru/professional-tomorrow/</a></p>
              <p>Получить дополнительную информацию об условиях участия в Конкурсе можно у представителей Организационного комитета:</p>
              <ul>
                <li>Бузолина Анастасия Николаевна, начальник отдела инклюзивной молодежной политики РУМЦ Тюменского государственного университета, тел. раб. <a href="tel:8(3452)59-75-76">8 (3452) 597-576</a></li>
                <li>Call-центр РУМЦ ТюмГУ <a href="tel:8(800)700-76-62">8 (800) 700-76-62</a> (звонок бесплатный), e-mail: <a href="mailto:profzavtra2022@utmn.ru">profzavtra2022@utmn.ru</a>.</li>
              </ul>

              <hr />

              <p>
                В 2021 году оператором Четвертого Всероссийского конкурса «Профессиональное завтра» является Крымский федеральный университет им.  В.И. Вернадского (контактная информация РУМЦ КФУ им. В.И. Вернадского:  т. <a href="tel:8(3654)26-16-65">8 (3654) 26-16-65</a>, e-mail: <a href="mailto:rumtskfu@gmail.com">rumtskfu@gmail.com</a>, сайт: <a href="https://rumc.cfuv.ru/" target="_blank">https://rumc.cfuv.ru/</a>) 
              </p>

              <p>
                Информация на сайте Минобрнауки России: <a href="https://minobrnauki.gov.ru/press-center/news/?ELEMENT_ID=26078&sphrase_id=64642" target="_blank">https://minobrnauki.gov.ru/press-center/news/?ELEMENT_ID=26078&sphrase_id=64642</a>
              </p>

              <p>Информация  о Конкурсе в официальных СМИ:</p>
              <ul>
                <li>В Крыму стартовал Всероссийский конкурс «Профессиональное завтра»: <a href="https://plus.rbc.ru/pressrelease/617134f17a8aa99f5a4764f3" target="_blank">https://plus.rbc.ru/pressrelease/617134f17a8aa99f5a4764f3</a></li>
                <li>"Профессиональное завтра": учиться и работать без преград <a href="https://crimea.ria.ru/20211025/professionalnoe-zavtra-uchitsya-i-rabotat-bez-pregrad-1121213725.html" target="_blank">https://crimea.ria.ru/20211025/professionalnoe-zavtra-uchitsya-i-rabotat-bez-pregrad-1121213725.html</a></li>
              </ul>

              <hr />

              <p>В 2020 году оператором Третьего Всероссийского конкурса являлся Государственный университет управления.</p>

              <hr />

              <p>Второй Всероссийский конкурс состоялся в 2019 году на базе Московского государственного психолого-педагогического университета.</p>
              
              <hr />

              <p>В 2018 году площадкой и организатором Первого Всероссийского конкурса «Профессиональное завтра» являлся Южный федеральный университет.</p>

              <div className={styles.btnsEvent} id="toStart">
                <button onClick={(e) => this.eShow5( )} className={(show5 ? styles.active : styles.a_in_block)}>Пятый Всероссийский сетевой конкурс студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью - 2022 год</button>
                {show5 &&
                  <div className={styles.insideBlock}>
                    <h2>V Всероссийский сетевой конкурс студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью</h2>

                    <p><b>Оператор Конкурса:</b> ФГАОУ ВО «Тюменский государственный университет».</p>
                    <p><b>Сроки проведения Конкурса - с 15 апреля по 21 октября 2022 года в два этапа:</b></p>
                    <ul>
                      <li><b>1 этап (заочный):</b> 15.04.2022 г. – 12.09.2022 г. – прием заявок и конкурсных работ.<br />Победители заочного этапа будут приглашены для участия в очном этапе Конкурса.</li>
                      <li><b>2 этап (очный):</b> 18.10.2022 г. – 21.10.2022 г. – защита проектов.<br />Место проведения очного этапа Конкурса – ФГАОУ ВО «Тюменский государственный университет».</li>
                    </ul>

                    <p><b>Номинации Конкурса:</b></p>
                    <ol>
                      <li>Профессиональный старт-ап молодежи.</li>
                      <li>Исследовательская работа.</li>
                      <li>Мое полезное изобретение.</li>
                      <li>Социальный проект.</li>
                      <li>Социальная реклама.</li>
                    </ol>

                    <p><b>Регистрация участников и оформление электронной заявки</b> осуществляется на сайте Конкурса: <a href="https://www.utmn.ru/professional-tomorrow/" target="_blank">https://www.utmn.ru/professional-tomorrow/</a>.</p>

                    <p><b>Получить дополнительную информацию</b> об условиях участия в Конкурсе можно у представителей Организационного комитета:</p>
                    <ul>
                      <li>Бузолина Анастасия Николаевна, начальник отдела инклюзивной молодежной политики РУМЦ Тюменского государственного университета, тел. раб. <a href="tel:8(3452)59-75-76">8 (3452) 597-576</a></li>
                      <li>Call-центр РУМЦ ТюмГУ <a href="tel:8(800)700-76-62">8 (800) 700-76-62</a> (звонок бесплатный), e-mail: <a href="mailto:profzavtra2022@utmn.ru">profzavtra2022@utmn.ru</a>.</li>
                    </ul> 
                  </div>
                }


                <button onClick={(e) => this.eShow4( )} className={(show4 ? styles.active : styles.a_in_block)}>Четвертый Всероссийский сетевой конкурс студенческих проектов с участием студентов с инвалидностью «Профессиональное завтра» - 2021 год</button>
                {show4 &&
                  <div className={styles.insideBlock}>
                    <h2>IV Всероссийский сетевой конкурс студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью</h2>

                    <p>При поддержке Министерства науки и высшего образования Российской Федерации сетью ресурсных учебно-методических центров высшего образования (далее – РУМЦ)» <i>с 1 сентября по 27 октября 2021 года</i> состоится IV Всероссийский сетевой конкурс студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью (далее - конкурс).</p>
                    <p>Оператором Конкурса в 2021 году является ФГАОУ ВО «Крымский федеральный университет имени В.И. Вернадского».</p>
                    <p>Основными задачами проведения конкурса являются формирование интереса студентов с инвалидностью и ОВЗ к будущей профессии, расширение кругозора в сфере выбранной профессиональной деятельности, стимулирование студентов к дальнейшему профессиональному развитию и трудоустройству по выбранному направлению подготовки.</p>
                    
                    
                    <h3>Конкурс проводится в два этапа:</h3>
                    <ul>
                      <li><b>1 этап (заочный)</b> - <i>с 1 сентября 2021 г. по 2 октября 2021 г.</i> - прием заявок (конкурсных работ). Победители первого этапа (заочного) будут приглашены для участия во втором (очном) этапе Конкурса.</li>
                      <li><b>2 этап (очный)</b> - <i>с 25 октября 2021 г. по 27 октября 2021 г.</i> - защита проектов.<br></br><p><i>Место проведения</i> очного этапа – ФГАОУ ВО «Крымский федеральный университет имени В.И. Вернадского».</p></li>
                    </ul>

                    <h3>Прием конкурсных работ осуществляется по следующим номинациям:</h3>
                    <ul>
                      <li><b>Профессиональный старт-ап молодежи</b><br></br><p>Конкурсная работа выполняется в формате проекта, в котором предложено решение проблемы трудоустройства выпускников вуза, в том числе выпускников с инвалидностью. Проект может содержать бизнес-идею для социального предпринимательства, бизнес-план для открытия малого предприятия с трудоустройством выпускников вузов, в том числе выпускников с инвалидностью, бизнес-проект по предоставлению социальных услуг инвалидам и лицам с ограниченными возможностями здоровья, производству товаров и др.</p></li>
                      <li><b>Исследовательская работа</b><br></br><p>На конкурс предоставляется статья с описанием исследования, посвященного изучению особенностей и проблем профессиональной ориентации инвалидов и трудоустройства выпускников вуза, в том числе выпускников с инвалидностью и ограниченными возможностями здоровья. Примерная тематика исследований: какую работу ждет молодежь; потребности работодателей в молодежных кадрах; карьерные стратегии и карьерные амбиции современной молодежи: путь в профессию; мотивация трудоустройства выпускников с инвалидностью; положение выпускника вуза, в том числе выпускника с инвалидностью, на рынке труда региона и др. Конкурсант может предложить свою тему исследования по данной проблеме.</p></li>
                      <li><b>Мое полезное изобретение</b><br></br><p>Для участия в конкурсе принимаются изобретения, позволяющие обеспечить независимый образ жизни инвалидам и лицам с ограниченными возможностями здоровья в различных сферах (образование, занятость, спорт, досуг и др.). К изобретениям могут быть отнесены: устройства для передвижения маломобильных групп населения, технические средства обучения инвалидов, технические средства для выполнения трудовых функций, реабилитационное оборудование, устройства для работы в дистанционном формате, гаджеты, приложения для ПК и смартфонов, технические средства коммуникации и др.</p></li>
                      <li><b>Социальная реклама</b><br></br><p>Проект выполняется в формате видеоролика, буклета, плаката, проекта проведения акции, флешмоба, плана мероприятия. Содержание социальной рекламы должно быть посвящено теме трудоустройства выпускников вузов, в том числе выпускников с инвалидностью и ограниченными возможностями здоровья.</p></li>
                    </ul>

                    <div className={styles.section}>
                      <h3>К участию в конкурсе приглашаются студенты, обучающиеся по программам высшего образования. Заявки на участие в конкурсе могут присылать как индивидуальные авторы, так и коллективы авторов (индивидуальные или групповые проекты).</h3>
                      <p><i>Обязательное условие</i> – численность команды не должна превышать трех человек; в том числе активное включение в состав команды не менее одного студента с инвалидностью.</p>
                      <p><i>Прием заявок</i>, а также самих конкурсных работ осуществляется <b>до 2 октября 2021 г.</b> Заявки, поданные после 2 октября 2021 г., к участию в Конкурсе не допускаются.</p>
                      <p><i>Регистрация участников</i> и размещение конкурсных работ осуществляется на сайте РУМЦ «Крымский федеральный университет имени В.И. Вернадского» по ссылке: <a href="https://rumc.cfuv.ru" target="_blank">https://rumc.cfuv.ru</a></p>
                      <p><i>Также получить дополнительную информацию по участию в конкурсе можно в организационном комитете:</i> Моцовкина Елена Владимировна, специалист РУМЦ «Крымский федеральный университет имени В.И. Вернадского», тел. раб. 8 (3654) 26-16-65; call-центр 8 (800) 551-70-77 (звонок бесплатный); тел. моб. 8 (978) 073-60-63; e-mail: profzavtra.yalta@gmail.com</p>
                    </div>


                    <div className={styles.file}>
                      <a href="/uploads/tomorrow/infmsgproftomorrow.pdf">Информационное письмо Минобрнауки России</a>
                    </div>

                    <div className={styles.toStartBtns}>
                      <a href="#toStart">Вернуться в начало</a>
                    </div>

                  </div>
                }


                <button onClick={(e) => this.eShowFirst( )} className={(showFirst ? styles.active : styles.a_in_block)}>Третий Всероссийский сетевой конкурс студенческих проектов с участием студентов с инвалидностью «Профессиональное завтра» 2020 год</button>
                {showFirst &&
                  <div className={styles.insideBlock}>
                    <h2>Третий Всероссийский сетевой конкурс студенческих проектов с участием студентов с инвалидностью «Профессиональное завтра» 2020 год</h2>

                    <p>Подробная информация о Третьем всероссийском сетевом конкурсе студенческих проектов с участием студентов с инвалидностью размещена на сайте оператора конкурса в 2020 году. <a href="https://guu.ru/%D0%BE%D0%B1-%D1%83%D0%BD%D0%B8%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%82%D0%B5%D1%82%D0%B5/%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%BD%D0%B0%D1%8F-%D1%81%D1%80%D0%B5%D0%B4%D0%B0-%D0%B2-%D0%B3%D1%83%D1%83/%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B5-%D0%B7%D0%B0%D0%B2%D1%82%D1%80%D0%B0/%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B5/" target="_blank">Подробнее</a></p>
                    
                    <div className={styles.file}>
                      <a href="/uploads/tomorrow/about_competition(II).docx">Положение о конкурсе</a>

                      <a href="/uploads/tomorrow/info_letter.pdf" target="_blank">Информационное письмо министерства науки и высшего образования</a>
                    </div>

                    <div className={styles.section}>
                      <h2>
                        Цель
                      </h2>

                      <p>
                        Профессиональная ориентация и содействие трудоустройству обучающихся вузов в социокультурной инклюзии на этапе обучения в вузе.
                      </p>
                    </div>

                    <div className={styles.section}>
                      <h2>
                        Задачи
                      </h2>

                      <p>
                        Формирование интереса студентов к будущей профессии, расширение их кругозора в сфере выбранной профессиональной деятельности, стимулирование студентов к дальнейшему профессиональному развитию и трудоустройству по выбранному направлению подготовки.
                      </p>
                    </div>

                    <div className={styles.conditions}>
                      <div className={styles.conditionsText}>
                        <h2>
                          Условия участия
                        </h2>

                        <p>
                          К участию в Конкурсе приглашаются студенты, обучающиеся по программам высшего образования.
                        </p>

                        <p>
                          Заявки на участие в Конкурсе могут присылать как индивидуальные авторы, так и проектные коллективы (индивидуальные и групповые проекты). Обязательное условие — численность команды не должна превышать 3 человек, в том числе активное включение в состав команды не менее одного студента с инвалидностью.
                        </p>

                        <h2>
                          Сроки проведения конкурса
                        </h2>

                        <p>
                          <strong>
                            1 этап (заочный)
                          </strong>
                          <br />
                          С 20.10.2020 г. по 25.11.2020 г. – прием заявок (конкурсных работ).
                          Победители заочного этапа были приглашены для участия в онлайн этапе Конкурса.
                        </p>

                        <p>
                          <strong>
                            2 этап (онлайн)
                          </strong>
                          <br />
                          С 15.12.2020 г. по 17.12.2020 г. – защита проектов.
                          Прием заявок, а также самих конкурсных работ осуществлялся до 25 ноября 2020 г.
                        </p>


                      </div>
                    </div>
                  

                    <div className={styles.toStartBtns}>
                      <a href="#toStart">Вернуться в начало</a>
                    </div>
                    
                  </div>
                }


                <button onClick={(e) => this.eShowSecond( )} className={(showSecond ? styles.active : styles.a_in_block)}>Второй Всероссийский сетевой конкурс студенческих проектов с участием студентов с инвалидностью «Профессиональное завтра» 2019 год</button>
                {showSecond && 
                  <div className={styles.insideBlock}>
                    <h2>Второй Всероссийский сетевой конкурс студенческих проектов с участием студентов с инвалидностью «Профессиональное завтра» 2019 год</h2>
                  

                    <div className={styles.section}>
                      <h2>
                        Цель
                      </h2>

                      <p>
                        Профессиональная ориентация и содействие трудоустройству обучающихся вузов в социокультурной инклюзии на этапе обучения в вузе.
                      </p>
                    </div>

                    <div className={styles.section}>
                      <h2>
                        Задачи
                      </h2>

                      <p>
                      Формирование интереса студентов к будущей профессии, расширение их кругозора в сфере выбранной профессиональной деятельности, стимулирование студентов к дальнейшему профессиональному развитию и трудоустройству по выбранному направлению подготовки.
                      </p>
                    </div>

                    <div className={styles.section}>
                      <h2>
                        Условия участия
                      </h2>

                      <p>
                      К участию в Конкурсе приглашались студенты, обучающиеся по программам высшего образования.<br />
                      Заявки на участие в Конкурсе могли присылать как индивидуальные авторы, так и проектные коллективы (индивидуальные и групповые проекты). Обязательное условие — численность команды не должна была превышать 3 человек, в том числе активное включение в состав команды не менее одного студента с инвалидностью.
                      </p>
                    </div>

                    <div className={styles.conditions}>
                      <div className={styles.conditionsText}>
                        
                        <h2>
                          Сроки проведения конкурса
                        </h2>

                        <p>
                          <strong>
                            1 этап (заочный)
                          </strong>
                          <br />
                          С 2 сентября по 20 октября 2019 г. - прием заявок (конкурсных работ).
                        </p>

                        <p>
                          <strong>
                            2 этап (очный)
                          </strong>
                          <br />
                          С 12 по 14 ноября 2019 г. - защита проектов и выполнение конкурсных заданий.
                        </p>

                      </div>
                    </div>

                    <div className={styles.section}>
                      <h2>
                        Место проведения очного этапа
                      </h2>

                      <p>
                        ФГБОУ ВО «Московский государственный психолого-педагогический университет» г. Москва.
                      </p>
                    </div>

                    <div className={styles.toStartBtns}>
                      <a href="#toStart">Вернуться в начало</a>
                    </div>
                  </div>
                }  
              </div>
            </div>

            <div className={styles.mon}>
              <p className={file_style.files}>
                <a href="/uploads/document/press_reliz/letter_propfzavtra_2022.pdf" target="_blank" rel="noopener noreferrer">Официальное письмо-приглашение</a>
              </p>
            </div>
          </div>



          {/* {show5 &&
            <div className={styles.insideBlock}>
              <h2>V Всероссийский сетевой конкурс студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью</h2>

              <p><b>Оператор Конкурса:</b> ФГАОУ ВО «Тюменский государственный университет».</p>
              <p><b>Сроки проведения Конкурса - с 15 апреля по 21 октября 2022 года в два этапа:</b></p>
              <ul>
                <li><b>1 этап (заочный):</b> 15.04.2022 г. – 12.09.2022 г. – прием заявок и конкурсных работ.<br />Победители заочного этапа будут приглашены для участия в очном этапе Конкурса.</li>
                <li><b>2 этап (очный):</b> 18.10.2022 г. – 21.10.2022 г. – защита проектов.<br />Место проведения очного этапа Конкурса – ФГАОУ ВО «Тюменский государственный университет».</li>
              </ul>

              <p><b>Номинации Конкурса:</b></p>
              <ol>
                <li>Профессиональный старт-ап молодежи.</li>
                <li>Исследовательская работа.</li>
                <li>Мое полезное изобретение.</li>
                <li>Социальный проект.</li>
                <li>Социальная реклама.</li>
              </ol>

              <p><b>Регистрация участников и оформление электронной заявки</b> осуществляется на сайте Конкурса: <a href="https://www.utmn.ru/professional-tomorrow/" target="_blank">https://www.utmn.ru/professional-tomorrow/</a>.</p>

              <p><b>Получить дополнительную информацию</b> об условиях участия в Конкурсе можно у представителей Организационного комитета:</p>
              <ul>
                <li>Бузолина Анастасия Николаевна, начальник отдела инклюзивной молодежной политики РУМЦ Тюменского государственного университета, тел. раб. <a href="tel:8(3452)59-75-76">8 (3452) 597-576</a></li>
                <li>Call-центр РУМЦ ТюмГУ <a href="tel:8(800)700-76-62">8 (800) 700-76-62</a> (звонок бесплатный), e-mail: <a href="mailto:profzavtra2022@utmn.ru">profzavtra2022@utmn.ru</a>.</li>
              </ul> 
            </div>
          } */}


          {/* {show4 &&
            <div className={styles.insideBlock}>
              <h2>IV Всероссийский сетевой конкурс студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью</h2>

              <p>При поддержке Министерства науки и высшего образования Российской Федерации сетью ресурсных учебно-методических центров высшего образования (далее – РУМЦ)» <i>с 1 сентября по 27 октября 2021 года</i> состоится IV Всероссийский сетевой конкурс студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью (далее - конкурс).</p>
              <p>Оператором Конкурса в 2021 году является ФГАОУ ВО «Крымский федеральный университет имени В.И. Вернадского».</p>
              <p>Основными задачами проведения конкурса являются формирование интереса студентов с инвалидностью и ОВЗ к будущей профессии, расширение кругозора в сфере выбранной профессиональной деятельности, стимулирование студентов к дальнейшему профессиональному развитию и трудоустройству по выбранному направлению подготовки.</p>
              
              
              <h3>Конкурс проводится в два этапа:</h3>
              <ul>
                <li><b>1 этап (заочный)</b> - <i>с 1 сентября 2021 г. по 2 октября 2021 г.</i> - прием заявок (конкурсных работ). Победители первого этапа (заочного) будут приглашены для участия во втором (очном) этапе Конкурса.</li>
                <li><b>2 этап (очный)</b> - <i>с 25 октября 2021 г. по 27 октября 2021 г.</i> - защита проектов.<br></br><p><i>Место проведения</i> очного этапа – ФГАОУ ВО «Крымский федеральный университет имени В.И. Вернадского».</p></li>
              </ul>

              <h3>Прием конкурсных работ осуществляется по следующим номинациям:</h3>
              <ul>
                <li><b>Профессиональный старт-ап молодежи</b><br></br><p>Конкурсная работа выполняется в формате проекта, в котором предложено решение проблемы трудоустройства выпускников вуза, в том числе выпускников с инвалидностью. Проект может содержать бизнес-идею для социального предпринимательства, бизнес-план для открытия малого предприятия с трудоустройством выпускников вузов, в том числе выпускников с инвалидностью, бизнес-проект по предоставлению социальных услуг инвалидам и лицам с ограниченными возможностями здоровья, производству товаров и др.</p></li>
                <li><b>Исследовательская работа</b><br></br><p>На конкурс предоставляется статья с описанием исследования, посвященного изучению особенностей и проблем профессиональной ориентации инвалидов и трудоустройства выпускников вуза, в том числе выпускников с инвалидностью и ограниченными возможностями здоровья. Примерная тематика исследований: какую работу ждет молодежь; потребности работодателей в молодежных кадрах; карьерные стратегии и карьерные амбиции современной молодежи: путь в профессию; мотивация трудоустройства выпускников с инвалидностью; положение выпускника вуза, в том числе выпускника с инвалидностью, на рынке труда региона и др. Конкурсант может предложить свою тему исследования по данной проблеме.</p></li>
                <li><b>Мое полезное изобретение</b><br></br><p>Для участия в конкурсе принимаются изобретения, позволяющие обеспечить независимый образ жизни инвалидам и лицам с ограниченными возможностями здоровья в различных сферах (образование, занятость, спорт, досуг и др.). К изобретениям могут быть отнесены: устройства для передвижения маломобильных групп населения, технические средства обучения инвалидов, технические средства для выполнения трудовых функций, реабилитационное оборудование, устройства для работы в дистанционном формате, гаджеты, приложения для ПК и смартфонов, технические средства коммуникации и др.</p></li>
                <li><b>Социальная реклама</b><br></br><p>Проект выполняется в формате видеоролика, буклета, плаката, проекта проведения акции, флешмоба, плана мероприятия. Содержание социальной рекламы должно быть посвящено теме трудоустройства выпускников вузов, в том числе выпускников с инвалидностью и ограниченными возможностями здоровья.</p></li>
              </ul>

              <div className={styles.section}>
                <h3>К участию в конкурсе приглашаются студенты, обучающиеся по программам высшего образования. Заявки на участие в конкурсе могут присылать как индивидуальные авторы, так и коллективы авторов (индивидуальные или групповые проекты).</h3>
                <p><i>Обязательное условие</i> – численность команды не должна превышать трех человек; в том числе активное включение в состав команды не менее одного студента с инвалидностью.</p>
                <p><i>Прием заявок</i>, а также самих конкурсных работ осуществляется <b>до 2 октября 2021 г.</b> Заявки, поданные после 2 октября 2021 г., к участию в Конкурсе не допускаются.</p>
                <p><i>Регистрация участников</i> и размещение конкурсных работ осуществляется на сайте РУМЦ «Крымский федеральный университет имени В.И. Вернадского» по ссылке: <a href="https://rumc.cfuv.ru" target="_blank">https://rumc.cfuv.ru</a></p>
                <p><i>Также получить дополнительную информацию по участию в конкурсе можно в организационном комитете:</i> Моцовкина Елена Владимировна, специалист РУМЦ «Крымский федеральный университет имени В.И. Вернадского», тел. раб. 8 (3654) 26-16-65; call-центр 8 (800) 551-70-77 (звонок бесплатный); тел. моб. 8 (978) 073-60-63; e-mail: profzavtra.yalta@gmail.com</p>
              </div>


              <div className={styles.file}>
                <a href="/uploads/tomorrow/infmsgproftomorrow.pdf">Информационное письмо Минобрнауки России</a>
              </div>

              <div className={styles.toStartBtns}>
                <a href="#toStart">Вернуться в начало</a>
              </div>

            </div>
          } */}


          {/* {showFirst &&
            <div className={styles.insideBlock}>
              <h2>Третий Всероссийский сетевой конкурс студенческих проектов с участием студентов с инвалидностью «Профессиональное завтра» 2020 год</h2>

              <p>Подробная информация о Третьем всероссийском сетевом конкурсе студенческих проектов с участием студентов с инвалидностью размещена на сайте оператора конкурса в 2020 году. <a href="https://guu.ru/%D0%BE%D0%B1-%D1%83%D0%BD%D0%B8%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%82%D0%B5%D1%82%D0%B5/%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%BD%D0%B0%D1%8F-%D1%81%D1%80%D0%B5%D0%B4%D0%B0-%D0%B2-%D0%B3%D1%83%D1%83/%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B5-%D0%B7%D0%B0%D0%B2%D1%82%D1%80%D0%B0/%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B5/" target="_blank">Подробнее>>></a></p>
              
              <div className={styles.file}>
                <a href="/uploads/tomorrow/about_competition(II).docx">Положение о конкурсе</a>

                <a href="/uploads/tomorrow/info_letter.pdf" target="_blank">Информационное письмо министерства науки и высшего образования</a>
              </div>

              <div className={styles.section}>
                <h2>
                  Цель
                </h2>

                <p>
                  Профессиональная ориентация и содействие трудоустройству обучающихся вузов в социокультурной инклюзии на этапе обучения в вузе.
                </p>
              </div>

              <div className={styles.section}>
                <h2>
                  Задачи
                </h2>

                <p>
                  Формирование интереса студентов к будущей профессии, расширение их кругозора в сфере выбранной профессиональной деятельности, стимулирование студентов к дальнейшему профессиональному развитию и трудоустройству по выбранному направлению подготовки.
                </p>
              </div>

              <div className={styles.conditions}>
                <div className={styles.conditionsText}>
                  <h2>
                    Условия участия
                  </h2>

                  <p>
                    К участию в Конкурсе приглашаются студенты, обучающиеся по программам высшего образования.
                  </p>

                  <p>
                    Заявки на участие в Конкурсе могут присылать как индивидуальные авторы, так и проектные коллективы (индивидуальные и групповые проекты). Обязательное условие — численность команды не должна превышать 3 человек, в том числе активное включение в состав команды не менее одного студента с инвалидностью.
                  </p>

                  <h2>
                    Сроки проведения конкурса
                  </h2>

                  <p>
                    <strong>
                      1 этап (заочный)
                    </strong>
                    <br />
                    С 20.10.2020 г. по 25.11.2020 г. – прием заявок (конкурсных работ).
                    Победители заочного этапа были приглашены для участия в онлайн этапе Конкурса.
                  </p>

                  <p>
                    <strong>
                      2 этап (онлайн)
                    </strong>
                    <br />
                    С 15.12.2020 г. по 17.12.2020 г. – защита проектов.
                    Прием заявок, а также самих конкурсных работ осуществлялся до 25 ноября 2020 г.
                  </p>


                </div>
              </div>
            

              <div className={styles.toStartBtns}>
                <a href="#toStart">Вернуться в начало</a>
              </div>
              
            </div>
          } */}

          {/* {showSecond && 
            <div className={styles.insideBlock}>
              <h2>Второй Всероссийский сетевой конкурс студенческих проектов с участием студентов с инвалидностью «Профессиональное завтра» 2019 год</h2>
            

              <div className={styles.section}>
                <h2>
                  Цель
                </h2>

                <p>
                  Профессиональная ориентация и содействие трудоустройству обучающихся вузов в социокультурной инклюзии на этапе обучения в вузе.
                </p>
              </div>

              <div className={styles.section}>
                <h2>
                  Задачи
                </h2>

                <p>
                Формирование интереса студентов к будущей профессии, расширение их кругозора в сфере выбранной профессиональной деятельности, стимулирование студентов к дальнейшему профессиональному развитию и трудоустройству по выбранному направлению подготовки.
                </p>
              </div>

              <div className={styles.section}>
                <h2>
                  Условия участия
                </h2>

                <p>
                К участию в Конкурсе приглашались студенты, обучающиеся по программам высшего образования.<br />
                Заявки на участие в Конкурсе могли присылать как индивидуальные авторы, так и проектные коллективы (индивидуальные и групповые проекты). Обязательное условие — численность команды не должна была превышать 3 человек, в том числе активное включение в состав команды не менее одного студента с инвалидностью.
                </p>
              </div>

              <div className={styles.conditions}>
                <div className={styles.conditionsText}>
                  
                  <h2>
                    Сроки проведения конкурса
                  </h2>

                  <p>
                    <strong>
                      1 этап (заочный)
                    </strong>
                    <br />
                    С 2 сентября по 20 октября 2019 г. - прием заявок (конкурсных работ).
                  </p>

                  <p>
                    <strong>
                      2 этап (очный)
                    </strong>
                    <br />
                    С 12 по 14 ноября 2019 г. - защита проектов и выполнение конкурсных заданий.
                  </p>

                </div>
              </div>

              <div className={styles.section}>
                <h2>
                  Место проведения очного этапа
                </h2>

                <p>
                  ФГБОУ ВО «Московский государственный психолого-педагогический университет» г. Москва.
                </p>
              </div>


              <div className={styles.toStartBtns}>
                <a href="#toStart">Вернуться в начало</a>
              </div>

            </div>
          }   */}
        </div>
      </div>
    )
  }
}

export default Index
