import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import I18n from '../I18n'
import Link, { path } from '../Routes'

import Loaf from '../Loaf/Loaf'

import styles from './InnerDocuments.module.css'
// import buttons from '../Buttons.module.css'
import page from '../Page.module.css'

export default function InnerDocuments () {
  const cancelToken = useRef(axios.CancelToken.source())
  const [documents, setDocuments] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('inner_documents_path', {}, true), { cancelToken: cancelToken.current.token })

      setDocuments(data.documents)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            Согласованные документы
          </h1>
        </div>

        <div className={styles.root}>
          {/* {can_create &&
            <div className={styles.add}>
              <a href={path('new_inner_document_path')} className={buttons.main}>Добавить документ</a>
            </div>
          } */}
          {documents &&
            <div className={styles.documents}>
              {documents.map(doc =>
                <div key={doc.id} className={styles.document}>
                  {doc.can_edit &&
                    <a href={path('new_inner_document_path', { id: doc.id })} className={styles.edit}>
                      Редактировать
                    </a>
                  }
                  <a href={doc.url} target="_blank" rel="noopener noreferrer" className={styles.url}>
                    <div className={styles.date}>
                      {doc.date}
                    </div>

                    <div className={styles.title}>
                      {doc.title}
                    </div>
                  </a>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
