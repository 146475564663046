import React, { Component } from 'react';

import Item from './Item';

import I18n from '../../I18n'

import section from './Section.module.css';

class Environment extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className={section.root}>
        <div className={section.title}>
          <h2>
            Создание безбарьерной среды для лиц с ОВЗ и инвалидностью
          </h2>
        </div>

        <div className={section.list}>
          <Item data={data.bld}  label={'stats.environment.bld'}  />

          <Item data={data.bb_e_y}  label={'stats.environment.bb_e_y'}>
            <div className={section.sub}>
              {(data.bb_e_y.value / data.bld.value).toLocaleString(I18n.locale, { style: 'percent' })} учебных корпусов
            </div>
          </Item>

          <Item data={data.bb_e_p}  label={'stats.environment.bb_e_p'}>
            <div className={section.sub}>
              {(data.bb_e_p.value / data.bld.value).toLocaleString(I18n.locale, { style: 'percent' })} учебных корпусов
            </div>
          </Item>

          <Item data={data.bb_e_n}  label={'stats.environment.bb_e_n'}>
            <div className={section.sub}>
              {(data.bb_e_n.value / data.bld.value).toLocaleString(I18n.locale, { style: 'percent' })} учебных корпусов
            </div>
          </Item>

          <Item data={data.bb_h_y}  label={'stats.environment.bb_h_y'}>
            <div className={section.sub}>
              {(data.bb_h_y.value / data.bld.value).toLocaleString(I18n.locale, { style: 'percent' })} учебных корпусов
            </div>
          </Item>

          <Item data={data.bb_h_p}  label={'stats.environment.bb_h_p'}>
            <div className={section.sub}>
              {(data.bb_h_p.value / data.bld.value).toLocaleString(I18n.locale, { style: 'percent' })} учебных корпусов
            </div>
          </Item>

          <Item data={data.bb_h_n}  label={'stats.environment.bb_h_n'}>
            <div className={section.sub}>
              {(data.bb_h_n.value / data.bld.value).toLocaleString(I18n.locale, { style: 'percent' })} учебных корпусов
            </div>
          </Item>

          <Item data={data.bb_m_y}  label={'stats.environment.bb_m_y'}>
            <div className={section.sub}>
              {(data.bb_m_y.value / data.bld.value).toLocaleString(I18n.locale, { style: 'percent' })} учебных корпусов
            </div>
          </Item>

          <Item data={data.bb_m_p}  label={'stats.environment.bb_m_p'}>
            <div className={section.sub}>
              {(data.bb_m_p.value / data.bld.value).toLocaleString(I18n.locale, { style: 'percent' })} учебных корпусов
            </div>
          </Item>

          <Item data={data.bb_m_n}  label={'stats.environment.bb_m_n'}>
            <div className={section.sub}>
              {(data.bb_m_n.value / data.bld.value).toLocaleString(I18n.locale, { style: 'percent' })} учебных корпусов
            </div>
          </Item>

          <Item data={data.br}  label={'stats.environment.br'}  />

          <Item data={data.pib}  label={'stats.environment.pib'}  />

        </div>
      </div>
    );
  }
}

export default Environment
