import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

import Link, { path } from '../Routes'
import I18n from '../I18n'
import Loaf from '../Loaf/Loaf'

import Rumc from './Index/Rumc'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import styles from './Index.module.css'

export default function Index () {
  const [monitoring, setMonitoring] = useState()
  const [reports, setReports] = useState()
  const [schools, setSchools] = useState()
  const [rumc, setRumc] = useState()

  const cancelToken = useRef(axios.CancelToken.source())
  const history = useHistory()

  const _fetch = async () => {
    const { data } = await axios.get(
      path('reports_path', {}, true),
      {
        cancelToken: cancelToken.current.token
      }
    )

    setMonitoring(data.monitoring)
    setReports(data.reports)
    setSchools(data.schools)
    setRumc(data.rumc)
  }

  useEffect(() => {
    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const handleNew = async schoolId => {
    await axios.post(
      path('reports_path', {}, true),
      { report: {
        monitoring_id: monitoring.id,
        school_id: schoolId
      } },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(_error => {
    })
  }

  const handleSaveVPO = async (schoolId, vpoId) => {
    // console.log(schoolId, vpoId)

    await axios.patch(
      path('school_path', { id: schoolId }, true),
      { school: { vpo_id: vpoId } },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      _fetch()
    }).catch(_error => {
    })
  }

  return (
    <div className={page.root}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('nav.account')}</Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('nav.universities_monitoring')}
          </h1>

          <p>
            <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1TtrIyABGutU83ZhmZ2v0kpxtgUwPWZfrNJqIpQmJ70o/edit">Методические рекомендации</a> по заполнению мониторинга за 2019 год.
          </p>

          <Helmet>
            <title>
              {I18n.t('nav.universities_monitoring')}
            </title>
          </Helmet>
        </div>
        <div className={styles.root}>
          {schools && reports &&
            <div>
              {schools.map(school =>
                <div key={school.id} className={styles.school}>
                  <div>
                    <h3>
                      {school.title}
                    </h3>

                    <div className={styles.short}>
                      Пожалуйста, проверьте короткое название вуза! Была допущена двоякая трактовка этого поля. Там не нужно указывать аббревиатуры, для них есть отдельное поле! В коротком названии нужно указывать краткое, понятное название вуза без правовой формы и без аббревиатур. Это общее сообщение для всех вузов, поэтому если у вас все заполнено верно — проигнорируйте данное сообщение.
                    </div>

                    {school.can_edit &&
                      <div className={styles.edit}>
                        {!school.valid &&
                          <div className={styles.noValid}>
                            Заполнены не все обязательные поля вуза, пожалуйста, отредактируйте данные вуза чтобы получить доступ к формам мониторинга.
                          </div>
                        }

                        {school.vpo_ids.length === 0 &&
                          <div className={styles.invalidINN}>
                            Возможно вы допустили ошибку при указании ИНН, т.к. не было найдено ни одного вуза с таким ИНН из мониторинга ВПО-1!
                            <br />
                            Пожалуйста, укажите верный ИНН или свяжитесь с нами по почте pub@incedu.ru
                          </div>
                        }

                        {!school.vpo && school.vpo_ids.length > 0 &&
                          <div className={styles.vpos}>
                            <p>
                              По указанному ИНН были найдены след. вузы из ВПО-1, нажмите кнопку подветрдить напротив нужного вуза:
                            </p>

                            <ul>
                              {school.vpo_ids.map((vpo, i) =>
                                <li key={i}>
                                  {vpo[5]}
                                  <br />
                                  <button className={buttons.meta} onClick={() => handleSaveVPO(school.id, vpo[0])}>Подтвердить</button>
                                </li>
                              )}
                            </ul>
                          </div>
                        }

                        {school.vpo &&
                          <p>
                            Вуз успешно связан с ВПО-1 ({school.vpo.title}). В случае ошибки напиши на почту pub@incedu.ru
                          </p>
                        }

                        <Link to="edit_school_path" params={{ id: school.id }}>
                          Редактировать информацию о вузе
                        </Link>
                      </div>
                    }
                  </div>

                  {school.valid &&
                    <div className={styles.reports}>
                      {monitoring && !reports.find(r => r.monitoring.id === monitoring.id && r.school.id === school.id) &&
                        <div className={classNames(styles.report, styles.new)} onClick={() => handleNew(school.id)}>
                          <div className={styles.data}>
                            <div className={styles.title}>
                              Новая форма мониторинга
                            </div>
                          </div>

                          <div className={styles.more}>
                            Подробнее
                          </div>
                        </div>
                      }

                      {reports.filter(r => r.school.id === school.id).map(report =>
                        <Link key={report.id} to="report_path" params={{ id: report.id }} className={styles.report}>
                          <div className={styles.data}>
                            <div className={styles.title}>
                              {report.title}
                            </div>
                          </div>

                          <div className={styles.more}>
                            Подробнее

                            <div className={classNames(styles.state, [styles[report.state]])}>{I18n.t(`report.state.${report.state}`)}</div>
                          </div>
                        </Link>
                      )}
                    </div>
                  }
                </div>
              )}
            </div>
          }

          {rumc && monitoring &&
            <Rumc rumc={rumc} monitoring={monitoring} />
          }
        </div>
      </div>
    </div>
  )
}
