import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styles from './Audiences.module.css'

Audiences.propTypes = {
  audiences: PropTypes.array,
  registrations: PropTypes.array
}

export default function Audiences ({ audiences, registrations }) {
  return (
    <div className={styles.root}>
      <h3>
        Категории участников
      </h3>

      <p className={styles.desc}>
        Количество участников событий разбитых по категориям.
      </p>

      {audiences.map(audience =>
        <Audience
          key={audience.id} audience={audience}
          registrations={registrations.filter(r => r.audience.id === audience.id)}
        />

      )}
    </div>
  )
}

Audience.propTypes = {
  audience: PropTypes.object,
  registrations: PropTypes.array
}

function Audience ({ audience, registrations }) {
  const [active, setActive] = useState(false)

  if (registrations.length < 1) return null

  const schools = [...new Map(registrations.filter(r => r.school).map(r => [r.school.id, r.school.label]))]

  // console.log(schools)

  return (
    <>
      <div className={styles.row}>
        <div className={styles.values}>
          <div className={styles.main}>
            {registrations.length}
          </div>
        </div>

        <div className={styles.label}>
          <div>
            {audience.label}
          </div>

          {audience.model === 'School' &&
            <div className={styles.toggle} onClick={() => setActive(!active)}><span>по вузам</span></div>
          }
        </div>
      </div>

      {active && audience.model === 'School' &&
        <div className={styles.schools}>
          {schools.map(school =>
            <div className={styles.school} key={school[0]}>
              <div className={styles.values}>
                {registrations.filter(r => r.school && r.school.id === school[0]).length}
              </div>
              <div className={styles.label}>
                {school[1]}
              </div>
            </div>
          )}
        </div>
      }
    </>
  )
}
