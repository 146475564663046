import React, { Component } from 'react'
// import PropTypes from 'prop-types';
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import I18n from '../I18n'
import Link, { path } from '../Routes'

import Loaf from '../Loaf/Loaf'
import RumcsBlock from '../Rumcs/RumcsBlock'

import page from '../Page.module.css'
import styles from './Rumcs.module.css'

class UniversitiesRumcs extends Component {
  state = {
    rumcs: [],
    regions_count: 0,
    rumc_schools: []
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('universities_rumcs_path', {}, true), { cancelToken: this._asyncRequest.token })
    this._asyncRequest = null

    this.setState({ ...res.data })
  }

  render () {
    const { rumcs, regions_count: regionCount, rumc_schools: rumcSchools } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="universities_path">{I18n.t('nav.universities')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('nav.universities_rumcs')}
            </h1>

            <Helmet>
              <title>{I18n.t('nav.universities_rumcs')}</title>
            </Helmet>
          </div>

          <div className={styles.main}>
            <section className={classNames(styles.section, styles.map)}>
              <RumcsBlock />
            </section>

            <section className={classNames(styles.about)}>
              <div className={styles.whatIsThis}>
                <h2>
                  Ресурсные учебно-методические центры
                </h2>

                <p>
                  Ресурсные учебно-методические центры созданы для помощи вузам. Работая с РУМЦ, вуз получает дополнительные возможности: доступ к нормативно-правовым актам, к центру коллективного пользования специфическим оборудованием, постоянная помощь в вопросах инклюзивного образования от сотрудников РУМЦ.
                </p>
              </div>

              <div className={styles.howMany}>
                <div className={styles.lrg}>
                  {rumcs.length}
                </div>
                {I18n.t('rumcs.count_without_count', { count: rumcs.length })} во всех регионах России
              </div>

              <div className={styles.agreements}>
                <div className={styles.lrg}>
                  {rumcSchools}
                </div>
                {I18n.t('rumc_schools.count_without_count', { count: rumcSchools })} с вузами из {I18n.t('regions.count_mod', { count: regionCount })} России
              </div>
            </section>

            <section className={classNames(styles.section, styles.rumcs)}>
              {rumcs.map(rumc =>
                <Link to="rumc_path" params={{ id: rumc.id }} className={styles.rumc} key={rumc.id}>
                  <div>
                    {rumc.title}
                  </div>
                  {rumc.ministry && rumc.ministry.id !== 134 &&
                    <div className={styles.ministry}>
                      {rumc.ministry.title_short || rumc.ministry.title}
                    </div>
                  }
                </Link>
              )}
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default UniversitiesRumcs
