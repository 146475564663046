import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import AsyncSelect from 'react-select/async'
import classNames from 'classnames'

import Link, { path } from '../../Routes'
import I18n from '../../I18n'
import Loaf from '../../Loaf/Loaf'
import { useForm, Errors } from '../../Form'

import page from '../../Page.module.css'
import form from '../../Form.module.css'
import buttons from '../../Buttons.module.css'
import styles from './Roles.module.css'

export default function Roles () {
  const cancelToken = axios.CancelToken.source()
  const { rumc_id: rumcId } = useParams()

  const [rumc, setRumc] = useState()
  const [roles, setRoles] = useState()
  const [users, setUsers] = useState()
  const [usersRoles, setUsersRoles] = useState()

  const _fetch = async () => {
    const { data } = await axios.get(path('account_rumc_rumc_users_path', { rumc_id: rumcId }, true), { cancelToken: cancelToken.token })

    setRumc(data.rumc)
    setUsers(data.users)
    setRoles(data.roles)
    setUsersRoles(data.users_roles)
  }

  useEffect(() => {
    _fetch()

    return function cleanup () {
      cancelToken.cancel()
    }
  }, [])

  const handleSubmit = () => {
    _fetch()
  }

  const handleDestroy = () => {
    _fetch()
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
          {rumc &&
            <Link to="account_rumc_path" params={{ id: rumc.id }}>{rumc.label}</Link>
          }
        </Loaf>

        {rumc && roles && usersRoles && users &&
          <>
            <div className={page.title}>
              <h1>
                {I18n.t('account.rumc.roles.title')}
              </h1>

              <Helmet>
                <title>{I18n.t('account.rumc.roles.title')}</title>
              </Helmet>
            </div>

            <div>
              <New users={users} roles={roles} rumc={rumc} onSubmit={handleSubmit} />

              {roles.map(role =>
                <Role
                  rumc={rumc}
                  role={role}
                  usersRoles={usersRoles.filter(ur => ur.role.id === role.id)}
                  key={role.id}
                  onDestroy={handleDestroy}
                />
              )}
            </div>
          </>
        }
      </div>
    </div>
  )
}

Role.propTypes = {
  usersRoles: PropTypes.array,
  rumc: PropTypes.object,
  role: PropTypes.object,
  onDestroy: PropTypes.func
}

function Role ({ rumc, usersRoles, role, onDestroy }) {
  const handleRemove = async id => {
    await axios.delete(
      path('account_rumc_rumc_user_path', { rumc_id: rumc.id, id })
    )

    onDestroy && onDestroy()
  }

  return (
    <div className={styles.role}>
      <h3>
        {role.title || role.name}
      </h3>

      <p>
        {I18n.t(`account.rumc.roles.${role.name}.desc`)}
      </p>

      {usersRoles.length === 0 &&
        <div>
          Никто не назначен!
        </div>
      }

      {usersRoles.length > 0 &&
        <div className={styles.users}>
          {usersRoles.map(usersRole =>
            <div key={usersRole.id} className={styles.user}>
              {usersRole.user.name} — <span className={styles.remove} onClick={() => handleRemove(usersRole.id)}>Удалить роль</span>
            </div>
          )}
        </div>
      }

    </div>
  )
}

New.propTypes = {
  users: PropTypes.array,
  roles: PropTypes.array,
  rumc: PropTypes.object,
  onSubmit: PropTypes.func
}

function New ({ users, roles, rumc, onSubmit }) {
  const { send, errors, setSend, setErrors, cancelToken, success, setSuccess } = useForm()
  const [values, setValues] = useState({ user_id: '', role_id: '' })

  const loadUsers = (input, callback) => {
    callback(
      users.filter(u =>
        u.name.toLowerCase().includes(input.toLowerCase()) || u.email.toLowerCase().includes(input.toLowerCase())
      ).slice(0, 100)
    )
  }

  useEffect(() => {
    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setSuccess()
      setErrors({})
      setSend(true)
    }

    await axios.post(
      path('account_rumc_rumc_users_path', { rumc_id: rumc.id }),
      { rumc_user: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      onSubmit && onSubmit()
      setValues({ ...values, role_id: '' })
      setSuccess('Пользователю успешно назначена роль!')
    }).catch(error => {
      setErrors(error.response.data)
    })

    setSend(false)
  }

  return (
    <div className={styles.new}>
      <p>
        Вы можете назначить роль в вашем центре любому пользователю портала (он должен предварительно сам зарегистрироваться). Одному пользователю можно назначить несколько ролей, у каждой роли разные возможности, кроме руководителя (он получает все права сотрудника + возможность назначать роли и править информацию центра).
      </p>

      <form onSubmit={handleSubmit}>
        <div className={styles.form}>
          <div className={form.input}>
            <AsyncSelect
              loadOptions={loadUsers}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              classNamePrefix="react-select"
              placeholder="Выберите пользователя.."
              noOptionsMessage={({ inputValue }) => inputValue === '' ? 'Введите имя или почту' : 'Нет доступных результатов'}
              onChange={
                value => setValues({ ...values, user_id: value.id })
              }
            />

            <Errors errors={errors.user} />
          </div>

          <div className={form.input}>
            <select
              onChange={({ target: { value } }) => setValues({ ...values, role_id: value })}
              value={values.role_id}
            >
              <option />
              {roles.map(role =>
                <option key={role.id} value={role.id}>{role.title || role.name}</option>
              )}
            </select>

            <Errors errors={errors.role} />
          </div>
        </div>

        {success &&
          <div className={form.success}>{success}</div>
        }

        <div className={form.submit}>
          <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
            Назначить
          </button>
        </div>
      </form>
    </div>
  )
}
