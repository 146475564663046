import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import classNames from 'classnames'

import Current from '../Current'
import Link, { path } from '../Routes'
import I18n from '../I18n'
import { Errors, useForm } from '../Form'
import Loaf from '../Loaf/Loaf'

import page from '../Page.module.css'
import form from '../Form.module.css'
import buttons from '../Buttons.module.css'

export default function Settings () {
  const { user } = useContext(Current)

  const {
    values,
    setValues,
    handleInputChange,
    cancelToken,
    errors,
    setErrors,
    send,
    setSend
  } = useForm()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios(path('settings_path', {}, true))
      setValues(data.values)
    }

    _fetch()
  }, [])

  const [saved, setSaved] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
      setSaved(false)
    }

    await axios.patch(
      path('user_path', { id: user.id }),
      { user: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      setSaved(true)
    }).catch(error => {
      setErrors(error.response.data)
    })

    setSend(false)
  }

  return (
    <div className={page.root}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('account.settings')}
          </h1>
          <Helmet>
            <title>
              {I18n.t('account.settings')}
            </title>
          </Helmet>
        </div>

        <div className={form.tight}>
          {saved &&
            <div className={form.success}>
              Настройки успешно сохранены
            </div>
          }

          {values &&
            <form onSubmit={handleSubmit}>
              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Фамилия
                  </div>

                  <div className={form.input}>
                    <input type="text" value={values.last_name} name="last_name" onChange={handleInputChange} />
                  </div>
                </label>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Имя
                  </div>

                  <div className={form.input}>
                    <input type="text" value={values.first_name} name="first_name" onChange={handleInputChange} />
                  </div>
                </label>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Отчество
                  </div>

                  <div className={form.input}>
                    <input type="text" value={values.middle_name} name="middle_name" onChange={handleInputChange} />
                  </div>
                </label>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Электронная почта
                  </div>

                  <div className={form.input}>
                    <input type="text" value={values.email} name="email" onChange={handleInputChange} />
                  </div>
                </label>

                <Errors errors={errors.email} />
              </div>

              <div className={form.submit}>
                <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                  Сохранить
                </button>
              </div>
            </form>
          }
        </div>
      </div>
    </div>
  )
}
