import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Routes, getPath, path } from '../Routes';

import Index from './Index';
import Schools from './Schools/Schools';
import Rumcs from './Rumcs/Rumcs';
import RumcSchools from './RumcSchools/RumcSchools';
import Events from './Events/Events';
import Umms from './Umms/Umms';
import Careers from './Careers/Careers';
import Stories from './Stories/Stories';
import Courses from './Courses/Courses';
import TargetDocuments from './TargetDocuments/TargetDocuments';
import TargetPrograms from './TargetPrograms/TargetPrograms';
import Confirmations from './Confirmations/Confirmations';
import Forms from './Forms/Forms';
import Charts from './Charts/ChartsList';
import Efficiency from './Efficiency/Efficiency';
import Rumc from './Rumc/Rumc';


class Stats extends React.Component {
  render() {
    const { id, history } = this.props;

    if(!id) return <Redirect to={path('stats_path', { id: 5 })} />

    return (
      <React.Fragment>
        <Switch>
          <Route path={decodeURIComponent(getPath('stats_schools_path'))} component={Schools} />
          <Route exact strict path={decodeURIComponent(getPath('stats_rumcs_efficiency_path'))} component={Efficiency} />
          <Route exact strict path={decodeURIComponent(getPath('stats_rumc_path'))} render={props => <Rumc id={props.match.params.id} rumc_id={props.match.params.rumc_id} {...props} />} />
          <Route path={decodeURIComponent(getPath('stats_rumcs_path'))} component={Rumcs} />
          <Route path={decodeURIComponent(getPath('stats_form_chart_path'))} component={Charts} exact strict />
          <Route path={decodeURIComponent(getPath('stats_forms_path'))} component={Forms} />
          <Route path={decodeURIComponent(getPath('stats_rumc_schools_path'))} component={RumcSchools} />
          <Route path={decodeURIComponent(getPath('stats_events_path'))} component={Events} />
          <Route path={decodeURIComponent(getPath('stats_umms_path'))} component={Umms} />
          <Route path={decodeURIComponent(getPath('stats_careers_path'))} component={Careers} />
          <Route path={decodeURIComponent(getPath('stats_stories_path'))} component={Stories} />
          <Route path={decodeURIComponent(getPath('stats_courses_path'))} component={Courses} />
          <Route path={decodeURIComponent(getPath('stats_target_documents_path'))} component={TargetDocuments} />
          <Route path={decodeURIComponent(getPath('stats_target_programs_path'))} component={TargetPrograms} />
          <Route path={decodeURIComponent(getPath('stats_confirmations_path'))} component={Confirmations} />
          <Route path={decodeURIComponent(getPath('stats_path'))} component={Index} exact strict />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Stats;
