import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

import I18n from '../../I18n'

import Link, { path } from '../../Routes'

import Loaf from '../../Loaf/Loaf'

import Block from './Show/Block'

import page from '../../Page.module.css'
import styles from './Show.module.css'
import buttons from '../../Buttons.module.css'

class Show extends Component {
  state = {
    rumc: null,
    isLoading : true
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.target !== prevProps.target) {
      this._loadAsyncData()
    }
  }

  _loadAsyncData = async () => {
    this.setState({ ...{
        isLoading:true
      } 
    })

    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('account_rumc_path', { id: this.props.id, query: { target_id: this.props.target } }, true), { cancelToken: this._asyncRequest.token })
    this.setState({ ...res.data, ...{
      isLoading:false
    } })
  }

  render () {
    const { rumc, target, targets, isLoading } = this.state

    if (!rumc) return null

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">{I18n.t('account.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {rumc.title_short}
            </h1>

            <Helmet>
              <title>
                {rumc.title_short}
              </title>
            </Helmet>

            {rumc.can_edit &&
              <p className={page.links}>
                <Link to="edit_account_rumc_path" params={{ id: rumc.id }}>Редактировать</Link>
                <Link to="account_rumc_rumc_users_path" params={{ rumc_id: rumc.id }}>Сотрудники</Link>
              </p>
            }

            {true &&
             <div className={styles.tabs}>
               {targets.map((target, _) =>
                 <Link
                   to="account_rumc_path"
                   params={{ id: rumc.id, hash: _ === 0 ? null : target.id }}
                   key={target.id}
                   className={styles.tab}
                   activeClassName={styles.active}
                 >{target.title}</Link>
               )}
             </div>
            }
          </div>
          
          {isLoading &&
            <div className={styles.flexAroud}>  
              <p className={styles.uploaderClass}>Загрузка данных... Пожалуйста, подождите...</p> 
            </div>
          }

          <div className={styles.row}>
            {/* <Link to="new_account_event_path" className={buttons.main}>Создать событие</Link> */}
            <Link to="account_events_path" params={{ query: { rumc: rumc.id, target: target.id } }} className={buttons.main}>Управление событиями</Link>
          </div>

          <p>
            <Link to="inner_documents_path">Список согласованных документов</Link>
          </p>

          <div className={styles.root}>
            <div className={styles.blocks}>
              {target.sections.map((section, _) =>
                <Block {...section} num={_ + 1} key={_} />
              )}
            </div>
          </div>

          <div>
              {/* <p className={styles.testClass}>Проводятся технические работы по модернизации кабинетов</p> */}
              <p className={styles.testClass}>
                Уважаемые коллеги!<br />
                При заполнении кабинетов, при  наличии вопросов, к какому блоку/направлению отнести те или иные сведения (в том числе с учетом отраслевой специфики),<br />
                просим обращаться вас по электронной почте: <a href="mailto:rumc-vo@yandex.ru">rumc-vo@yandex.ru</a> (с пометкой в теме письма "Кабинет РУМЦ").<br />
                <br />
                При возникновении вопросов технического характера (некорректная работа сайта), просим обращаться по электронной почте <a href="mailto:pub@incedu.ru">pub@incedu.ru</a><br />
                <br />
                Благодарим за сотрудничество!
              </p>
            </div>
        </div>
      </div>
    )
  }
}

Show.propTypes = {
  id: PropTypes.string,
  target: PropTypes.string
}

export default Show
