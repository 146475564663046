import React from 'react';
import axios from 'axios';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { path } from '../Routes';
import Loaf from '../Loaf/Loaf';
import Filters from '../Filters/Filters';

import page from '../Page.module.css';
import styles from './Events.module.css';
import fonts from '../Fonts.module.css';

class EventsRumc extends React.Component {
  render() {
    const { audiences, regions } = this.props;

    const schools = this.props.items.filter(i => i.school).reduce((acc, i) => {
      if(!acc.find(_ => _.id == i.school.id)) {
        acc.push(i.school);
      }
      return acc;
    }, []);

    return(
      <React.Fragment>
        <tr className={styles.rumc}>
          <td>
            <div className={fonts.h3}>
              {this.props.rumc.title}
            </div>
          </td>
          <td>
            {schools.length}
          </td>
          <td>
            {this.props.events.length}
          </td>
          {audiences.map(audience =>
            <td>
              {this.props.items.filter(i => i.audience && i.audience.id == audience.id).length}
            </td>
          )}
        </tr>

        {regions.map(region =>
          <EventsRegion region={region} audiences={audiences} items={this.props.items.filter(i => i.region && i.region.id == region.id)} events={this.props.events.filter(e => e.region_ids.includes(region.id))} />
        )}
      </React.Fragment>
    );
  }
}

class EventsRegion extends React.Component {
  render() {
    const { audiences } = this.props;

    const schools = this.props.items.filter(i => i.school).reduce((acc, i) => {
      if(!acc.find(_ => _.id == i.school.id)) {
        acc.push(i.school);
      }
      return acc;
    }, []);

    const rumcs = this.props.items.filter(i => i.rumc).reduce((acc, i) => {
      if(!acc.find(_ => _.id == i.rumc.id)) {
        acc.push(i.rumc);
      }
      return acc;
    }, []);

    // console.log(schools);

    return(
      <React.Fragment>
        <tr className={styles.region}>
          <td>
            {this.props.region.title}
          </td>
          <td>
            {schools.length}
          </td>
          <td>
            {this.props.events.length}
          </td>
          {audiences.map(audience =>
            <td>
              {this.props.items.filter(i => i.audience && i.audience.id == audience.id).length}
            </td>
          )}
        </tr>

        {schools.map(school =>
          <tr>
            <td>
              {school.title_last}
            </td>
            <td></td>
            <td></td>
            {audiences.map(audience =>
              <td>
                {audience.model == 'School' ? (
                  this.props.items.filter(i => i.audience && i.audience.id == audience.id && i.school && i.school.id == school.id).length
                ) : (
                  ' '
                )}

              </td>
            )}

          </tr>
        )}
        {rumcs.map(rumc =>
          <tr>
            <td>
              {rumc.title}
            </td>
            <td></td>
            <td></td>
            {audiences.map(audience =>
              <td>
                {audience.model == 'Rumc' ? (
                  this.props.items.filter(i => i.audience && i.audience.id == audience.id && i.rumc && i.rumc.id == rumc.id).length
                ) : (
                  ' '
                )}
              </td>
            )}
          </tr>
        )}
      </React.Fragment>
    );
  }
}

class EventsStat extends React.Component {
  state = {
    items: null,
    search: this.props.location.search
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();
    axios.get(path('account_events_stat_path', {}, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search)})
      .then(res => {
        this.setState({
          items: res.data.items,
          filters: res.data.filters,
          regions: res.data.regions,
          rumcs: res.data.rumcs,
          audiences: res.data.audiences,
          events: res.data.events,
        });

        this._asyncRequest = null;
      });
  }

  static getDerivedStateFromProps(props, state) {
    if(state.filters && props.location.search !== state.search) {
      return {
        items: null,
        search: props.location.search,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.items === null) this._loadAsyncData();
  }


  render() {
    const { filters, items, regions, audiences, rumcs, events } = this.state;
    const { history, location } = this.props;

    let schools = [];

    // if(!items) return null;
    //
    if (items && items.length > 0) {
      schools = items.filter(i => i.school).reduce((acc, i) => {
        if(!acc.find(_ => _.id == i.school.id)) {
          acc.push(i.school);
        }
        return acc;
      }, []);
    }

    // console.log(items.filter(i => i.rumc));

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>Кабинет</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Статистика событий
            </h1>

            <Helmet>
              <title>Статистика событий</title>
            </Helmet>
          </div>

          <div className={styles.root}>
            <div className={styles.filters}>
              {filters &&
                <Filters filters={filters} history={history} location={location} />
              }
            </div>

            <div className={styles.items}>
              <table className={styles.table}>
                <tbody>
                  {audiences &&
                    <tr>
                      <td>
                      </td>
                      <td className={styles.val}>
                        Кол. вузов
                      </td>
                      <td className={styles.val}>
                        Кол.
                      </td>
                      {audiences.map(audience =>
                        <td className={styles.val}>
                          {audience.title}
                        </td>
                      )}
                    </tr>
                  }

                  {items &&
                    <React.Fragment>
                      <tr>
                        <td>
                          <div className={fonts.h3}>
                            Всего
                          </div>
                        </td>
                        <td>
                          {schools.length}
                        </td>
                        <td>
                          {events.length}
                        </td>
                        {audiences.map(audience =>
                          <td>
                            {items.filter(i => i.audience && i.audience.id == audience.id).length}
                          </td>
                        )}
                      </tr>

                      {rumcs.map(rumc =>
                        <EventsRumc rumc={rumc} items={items.filter(i => i.event_rumc_id == rumc.id)} audiences={audiences} regions={regions.filter(r => rumc.regions.includes(r.id))} events={events.filter(e => e.rumc_id == rumc.id)} />
                      )}
                    </React.Fragment>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventsStat;
