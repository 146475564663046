import React from 'react'
import PropTypes from 'prop-types'
import redraft from 'redraft'

const renderers = {
  inline: {
    BOLD: (children, { key }) => <strong key={key}>{children}</strong>
  },
  blocks: {
    unstyled: (children) => children.map((child, key) => <p key={key}>{child}</p>),
    blockquote: (children, { key }) => <blockquote key={key}>{children}</blockquote>,
    'header-three': (children) => children.map(child => <h3>{child}</h3>),
    'header-two': (children) => children.map(child => <h2>{child}</h2>),
    'unordered-list-item': (children, { depth, keys }) => <ul key={keys[keys.length - 1]} className={`ul-level-${depth}`}>{children.map(child => <li>{child}</li>)}</ul>,
    'ordered-list-item': (children, { depth, keys }) => <ol key={keys.join('|')} className={`ol-level-${depth}`}>{children.map((child, index) => <li key={keys[index]}>{child}</li>)}</ol>
  },
  entities: {
    LINK: (children, data, { key }) => <a key={key} target="_blank" rel="noopener noreferrer" href={data.url}>{children}</a>
  }
}

Redraft.propTypes = {
  src: PropTypes.object
}

export default function Redraft ({ src }) {
  return redraft(src, renderers)
}
