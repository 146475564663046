import React, { Component } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import classNames from 'classnames'

import Link, { path } from '../Routes'
import I18n from '../I18n'
import { Event } from './Index'

import Loaf from '../Loaf/Loaf'

import page from '../Page.module.css'
import styles from './Archive.module.css'
import nstyles from './IndexNew.module.css'
import Pagination from './Pagination/Pagination'

class Archive extends Component {
  state = {
    events: [],
    loading: false,
    offset: 0,
    limit: 25,
    count_rows: 0,
    search: ''
  }

  mount = React.createRef()

  componentDidMount () {
    // window.addEventListener('scroll', this._handleScroll)
    this._loadAsyncData()
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this._handleScroll)
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.offset !== this.state.offset) {
      this.setState({ loading: true })
    }

    if (!prevState.loading && this.state.loading) {
      this._loadAsyncData()
    }
  }

  _loadAsyncData = async (page=1) => {
    this._asyncRequest = axios.CancelToken.source()
    let _params = {
      page: page,
      limit: this.state.limit,
    }
    if (this.state.search) {
      _params.search = this.state.search;
    }

    const res = await axios.get(
      path('archive_events_path', {}, true),
      {
        cancelToken: this._asyncRequest.token,
        params: _params
      }
    )
    this._asyncRequest = null
    
    const { events, count } = res.data

    // this.setState(state => ({ events: [...state.events, ...events], count_rows: count, loading: false }))
    this.setState({ events: events, count_rows: count, loading: false })
    // console.log("data", res.data)
  }

  changeInput = (event) => {
    this.setState({search: event.target.value})
  }

  handleKeypress = (e) => {
    if (e.key === "Enter") {
      this._loadAsyncData();
    }
  }

  // _handleScroll = e => {
  //   let { innerHeight: height } = window
  //   const { bottom } = this.mount.current.getBoundingClientRect()

  //   height = Math.trunc(height * 1.2)

  //   if (height > bottom && !this.state.loading) {
  //     this.setState(state => ({ offset: state.offset + state.limit }))
  //   }
  // }

  render () {
    const { events, loading, count_rows, search } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="events_path">{I18n.t('events.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('events.archive.title')}
            </h1>

            <Helmet>
              <title>
                {I18n.t('events.archive.title')}
              </title>
            </Helmet>


            <div className={classNames(nstyles.flex_row, styles.search_block_wrapper)}>
              <div className={classNames(nstyles.flex_col, nstyles.title_col)}>
                <div className={classNames(nstyles.flex_row, nstyles.search_input_wrapper)}>
                  <input className={nstyles.search_input} type="text" placeholder={"Добавьте поисковой запрос"} value={search} onChange={this.changeInput} onKeyPress={this.handleKeypress} />
                  <div className={nstyles.new_search_button}><button onClick={() => (this._loadAsyncData())} /></div>
                </div>
              </div>
            </div>


            <div className={classNames(nstyles.count_events, nstyles.count_events_archive)}>
              {/* <span>Проведенных событий</span> */}
              <span>Найдено событий</span>
              <div>{count_rows}</div>
            </div>


            <div className={styles.events} ref={this.mount}>
              {events.map(event =>
                <Event key={event.id} {...event} indexPage archive />
              )}
            </div>
              
            <Pagination
              count_pages={ Math.ceil(this.state.count_rows / this.state.limit) } 
              togglePage={this._loadAsyncData}
              archive
            />

            {/* {loading &&
              <>
              <div className={styles.loading}>
                <div className={styles.pr} />
                <div className={styles.sc} />
              </div>

              <div className={styles.loading}>
                <div className={styles.pr} />
                <div className={styles.sc} />
              </div>
              </>
            } */}
          </div>
        </div>
      </div>
    )
  }
}

export default Archive
