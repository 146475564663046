import React, { useState, useEffect } from 'react';

import ImagesCard from "./ImagesCard";
import styles from '../Show.module.css'



const ImagesList = (props) => {

  const [openedImage, setOpenedImage] = useState("");
  const [openedImageId, setopenedImageId] = useState(0);
  const [openedImageTitle, setOpenedImageTitle] = useState("");


  const closeImage = () => {
    setOpenedImage("")
    setOpenedImageTitle("")
    document.body.style.overflow = ""
  }

  const openImage = (url, title, index) => {
    setOpenedImage(url)
    setOpenedImageTitle(title)
    setopenedImageId(index)
    document.body.style.overflow = "hidden"
  }

  const nextImage = (index) => {
    let next_img = props.images.length == index + 1 ? props.images[0] : props.images[index + 1]
    let next_id = props.images.length == index + 1 ? 0 : index + 1
    setOpenedImage(next_img.url.replace("_crop", ""))
    setOpenedImageTitle(next_img.name)
    setopenedImageId(next_id)
    document.body.style.overflow = "hidden"
  }

  const prevImage = (index) => {
    let prev_img = index == 0 ? props.images[props.images.length - 1] : props.images[index - 1]
    let prev_id = index == 0 ? props.images.length - 1 : index - 1
    setOpenedImage(prev_img.url.replace("_crop", ""))
    setOpenedImageTitle(prev_img.name)
    setopenedImageId(prev_id)
    document.body.style.overflow = "hidden"
  }
    
  // const images = props.images.map((image, index) => {
  //   return <ImagesCard key={index} index={index} image={image} openImage={openImage} />
  // });

  const images = () => {
    const countCols = 4;
    const columns = [];
    const chunkSize = Math.ceil(props.images.length / countCols)
    let colNum = 0;
    for (let i = 0; i < props.images.length; i += chunkSize) {
      const temp = [];
      for (let j = colNum; j < props.images.length; j += countCols) {
        temp.push(props.images[j])
      }
      colNum++;
      columns.push(temp)
    }
    return columns.map((col, col_index) => {
      return (
        <div className={styles.gallery__column} key={`img_col_${col_index}`}>
          {
            col.map((image, index) => {
              return <ImagesCard key={`img_card_${index}`} index={index * 4 + col_index} image={image} openImage={openImage} />
            })
          }
        </div>
      )
    })
  };

  const handleClickOutside = (e) => {
    e.stopPropagation();
    if (e.target.className == 'Show-module__opened_image_card_container___-_ZIN') {
      setOpenedImage('');
      setOpenedImageTitle('');
      document.body.style.overflow = '';
    }
  }

  return (
    <div>
      {/* <div className={styles.image_list}>{images()}</div> */}
      <div className={styles.gallery__row}>{images()}</div>
      {openedImage &&
        <div className={styles.opened_image_card_container} onClick={(e) => handleClickOutside(e)}>
          <div className={styles.next_prev_img}>
            <div className={styles.next} onClick={() => prevImage(openedImageId)} />

            <div className={styles.opened_image_card}>
              <div className={styles.opened_image_header}>
                <span>{openedImageTitle}</span>
                <div onClick={() => closeImage()} className={styles.close_image}></div>
              </div>
              <img src={openedImage} />
            </div>
            
            <div className={styles.prev} onClick={() => nextImage(openedImageId)} />
          </div>
        </div>
      }
    </div>
  )
};

export default ImagesList


