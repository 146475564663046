import React from 'react';
import axios from 'axios';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Filters from './Filters';
import Sections from './Sections';

import styles from './List.module.css';

function Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={styles.i}>
      <path d="M24 12C24 18.62 18.62 24 12 24C5.38 24 0 18.62 0 12C0 5.38 5.38 0 12 0C18.62 0 24 5.38 24 12Z" />
    </svg>
  );
}

class List extends React.Component {
  state = {
    list: null,
    fields: [],
    search: this.props.location.search,
    rumcs: [],
    section: this.props.section || '',
    sort: null,
  }

  // buildURL = () => {
  //   // qs.parse(this.props.location.search);
  //   console.log(qs.stringify({
  //     fields: this.state.fields
  //   }))
  // }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData(id) {
    if(this.state.fields.length < 1) return null;

    this._asyncRequest = axios.CancelToken.source();

    axios.get(this.props.url, { cancelToken: this._asyncRequest.token, params: { fields: this.state.fields, rumcs: this.state.rumcs, section: this.state.section } })
      .then(res => {
        this.setState({
          list: res.data.list,
          sections: res.data.sections,
        });

        if (this.props.onDataChange) this.props.onDataChange(res.data.list)

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  static getDerivedStateFromProps(props, state) {
    let { fields, rumcs, section } = qs.parse(props.location.search);
    if(!fields) {
      if (props.fields == 'all' && state.list) {
        fields = state.list.fields.map(f => f.id);
      }
      else {
        fields = props.fields;
      }
    }

    if(!Array.isArray(fields)) fields = [fields];

    if(!fields.every(e => state.fields.includes(e)) || !state.fields.every(e => fields.includes(e))) {
      return {
        fields: fields,
        list: null,
        rumcs: rumcs || null,
        section: section || null,
      }
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(!this.state.list) this._loadAsyncData();
    if (this.state.section !== prevState.section) {
      this._loadAsyncData();
    }
  }

  handleSectionChange = (section) => {
    this.setState(prevState => ({
      section: section
    }), () => {
      this.props.history.replace(`${this.props.location.pathname}?${qs.stringify({ fields: this.state.fields, rumcs: this.state.rumcs, section: this.state.section})}`);
    });
  }

  handleSort = (id) => {

    const key = Object.keys(this.state.fields).find(key => this.state.fields[key] === id);

    let rows = [...this.state.list.rows]

    rows.sort((a, b) => {
      a = this.state.list.fields[key].rows[Object.keys(this.state.list.fields[key].rows).find(key2 => this.state.list.fields[key].rows[key2].id === a.id)];
      b = this.state.list.fields[key].rows[Object.keys(this.state.list.fields[key].rows).find(key2 => this.state.list.fields[key].rows[key2].id === b.id)];
      return b.value - a.value;
    });


    this.setState(prevState => ({
      list: { ...prevState.list, rows: rows },
      sort: id
    }));
  }

  render() {
    const { list, rumcs, section, sections, sort } = this.state;
    if(!list) return null;

    const { fields, rows, filters } = list;

    return (
      <>
        { sections &&
          <Sections sections={sections} active={section} onSectionChange={this.handleSectionChange}/>
        }

        <Filters fields={fields} section={section} history={this.props.history} location={this.props.location} />

        <p>
          <a href={`${this.props.location.pathname}.xlsx?${qs.stringify({ fields: fields.filter(f => f.active).map(f => f.id), section: section, rumcs: rumcs}, {arrayFormat: 'bracket'})}`}>Скачать в Excel</a>
        </p>

        <div className={styles.table}>
          <div className={styles.column}>
            <div className={styles.head}></div>

            {rows.map(row =>
              <div className={styles.cell} key={row.id}>
                {row.id}
              </div>
            )}
          </div>

          {fields.filter(f => f.active).map(field =>
            <Column sort={sort} field={field} key={field.id} onSort={this.handleSort} rows={rows}/>
          )}

          {/*
          {rows.map((row, _) =>
            <div key={_} className={styles.row}>
              {fields.filter(f => f.active).map((field, __) =>
                <Cell item={row.find(i => i.field == field.id)} field={field} key={__} />
              )}
            </div>
          )}
          */}
        </div>
      </>
    );
  }
}

class Column extends React.Component {
  render () {
    const { id, title, group, rows, labels } = this.props.field;

    return (
      <>
        {Array.isArray(title) ? (
          <div className={styles.group}>
            <div className={styles.groupHead}>
              {group}
            </div>
            {title.map((t, _) =>
              <div key={t.id} className={styles.column}>
                <Head title={t.title} group={group} id={id} sort={this.props.sort} onSort={this.props.onSort}/>
                <Rows rows={rows} id={_} labels={labels} sort={this.props.rows} />
              </div>
            )}
          </div>
        ) : (
          <div className={styles.column}>
            <Head title={title} group={group} id={id} sort={this.props.sort} onSort={this.props.onSort}/>
            <Rows rows={rows} labels={labels} sort={this.props.rows} />
          </div>
        )}
      </>
    );
  }
}

class Rows extends React.Component {
  render () {
    const { rows, id, labels, sort } = this.props;

    return (
      <>
        {sort.map(srt =>
          <>
            {Array.isArray(rows.find(i => i.id == srt.id).value) ? (
              <Cell value={rows.find(i => i.id == srt.id).value[id].value} type={rows.find(i => i.id == srt.id).value[id].type} labels={labels} />
            ) : (
              <Cell value={rows.find(i => i.id == srt.id).value} type={rows.find(i => i.id == srt.id).type} labels={labels} link={rows.find(i => i.id == srt.id).link}/>
            )}
          </>
        )}
      </>
    );
  }
}

class Head extends React.Component {
  render () {
    const { title, group, id, sort } = this.props;

    return (
      <div className={classNames(styles.head, { [styles.headWOG]: group, [styles.sort]: id == sort })} onClick={() => this.props.onSort(id)}>
        {title}
        <Icon />
      </div>
    );
  }
}

class Cell extends React.Component {
  render() {
    const { value, type, labels, link } = this.props;

    let label = value;

    if(type == 'boolean') {
      label = value == '2' ? 'Да' : 'Нет';
    } else if(type == 'radio' && labels) {
      const l = labels.find(l => l.id == value);
      if(l) {
        label = l.title;
      } else {
        label = null;
      }
    } else if(type == 'link') {
      label = <Link target="_blank" to={link}>{value}</Link>
    } else if(type == 'outer_link') {
      label = <a target="_blank" href={link}>{value}</a>
    }

    return (
      <div className={styles.cell}>
        {label}
      </div>
    );
  }
}

export default List;
