import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import Cookies from 'universal-cookie'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import qs from 'query-string'

import Link, { path } from '../Routes'
import I18n from '../I18n'
import Loaf from '../Loaf/Loaf'

import Stringg from './Show/Stringg'
import Text from './Show/Text'
import Checkboxes from './Show/Checkboxes'
import References from './Show/References'
import Radio from './Show/Radio'
import NoRumc from './Show/NoRumc'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import forms from '../Form.module.css'
import styles from './Show.module.css'

export default function Show () {
  const query = new URLSearchParams(useLocation().search)
  const { id } = useParams()
  const history = useHistory()
  const cookies = new Cookies()
  const cancelToken = useRef(axios.CancelToken.source())

  const rumcId = query.get('rumc_id')
  const cookieRumcId = cookies.get('form_rumc_id')

  const [form, setForm] = useState()
  const [elements, setElements] = useState()
  const [dictionaries, setDictionaries] = useState()

  if (!rumcId && !cookieRumcId) return <NoRumc />

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('form_path', { id }, true))

      setForm(data.form)
      setDictionaries(data.dictionaries)
      setElements(data.form_elements)
    }

    if (!rumcId) {
      if (cookieRumcId) {
        history.push(path('form_path', { id, query: { rumc_id: cookieRumcId } }))
      } else {

      }
    } else {
      cookies.set('form_rumc_id', rumcId, { path: '/', expires: new Date(Date.now() + 2592000) })
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const [values, setValues] = useState(new Map())
  const [send, setSend] = useState(false)
  const [errors, setErrors] = useState({})

  const handleQuestionChange = (q, value) => {
    const newValues = new Map(values)
    newValues.set(q, value)
    setValues(newValues)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    await axios.post(
      path('form_form_results_path', { form_id: id }, true),
      {
        form_result: {
          rumc_id: rumcId,
          form_elements_results_attributes: [...values].map(o => {
            return {
              value: o[1],
              form_element_id: o[0]
            }
          })
        }
      },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      // cookies.remove(`form-${id}`)
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const canSubmit = () => {
    const ready = [...values].map(el => el[0])
    return elements.filter(e => e.required).filter(e => !ready.includes(e.id)).map(e => e.id).length === 0
  }

  const notFull = () => elements.length - [...values].length

  return (
    <div className={page.root}>
      <div className={page.container}>
        <Loaf>
          <Link to="forms_path">Все анкеты</Link>
        </Loaf>

        {form && elements && dictionaries &&
          <div className={page.narrow}>
            <div className={page.title}>
              <h1>{form.title}</h1>

              <Helmet>
                <title>
                  {form.title}
                </title>
              </Helmet>

              {form.desc &&
                <ReactMarkdown source={form.desc} className={styles.intro} />
              }

              {form.rumc &&
                <div className={styles.ref}>
                  <p>
                    Анкеты могут заполнять как сами респонденты, так и сотрудники ресурсных центров с их слов. Чтобы система смогла потом определить что анкета относится к вашему ресурсному центру — нужно передавать респондентам ссылку:
                    <a href={`https://инклюзивноеобразование.рф/forms/${form.id}?rumc_id=${form.rumc.id}`} target="_blank" rel="noopener noreferrer">
                      https://инклюзивноеобразование.рф/forms/{form.id}?rumc_id={form.rumc.id}
                    </a>
                  </p>

                  <p>
                    Проверьте наличие идентификатора rumc_id=XXX, где XXX уникальный номер для каждого РУМЦ.
                  </p>

                  <p>
                    <a className={styles.file} href={`${(path('stats_form_path', { id: 3, form_id: form.id }))}.xlsx?${qs.stringify({ fields: elements.map(e => e.id), rumcs: form.rumc.id }, { arrayFormat: 'bracket' })}` }>Скачать результаты анкет в Excel</a>
                  </p>
                </div>
              }
            </div>

            {elements.map(element =>
              <Question
                key={element.id}
                element={element}
                dictionaries={dictionaries}
                onQuestionChange={handleQuestionChange}
              />
            )}

            {errors.rumc &&
              <p className={forms.error}>
                Не определён РУМЦ для анкеты! Обратитесь к тому, от кого вы получили ссылку на анкету.
              </p>
            }

            <div className={styles.buttons}>
              {!canSubmit() &&
                <p>
                  Вы не ответили на все обязательные вопросы!
                </p>
              }

              {notFull() > 0 && canSubmit() &&
                <p>
                  Вы не ответили на {I18n.t('form.questions', { count: notFull() })}, уверены что хотите завершить заполнение?
                </p>
              }

              <button
                className={classNames(buttons.main, { [buttons.loading]: send })}
                disabled={!canSubmit() || send}
                onClick={handleSubmit}
              >
                Завершить заполнение
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

Question.propTypes = {
  element: PropTypes.object,
  dictionaries: PropTypes.object,
  onQuestionChange: PropTypes.func
}

function Question ({ element, dictionaries, onQuestionChange }) {
  const prevValueRef = useRef()
  const [value, setValue] = useState(prevValueRef.current)

  const handleValueChange = newValue => {
    setValue(newValue)
  }

  useEffect(() => {
    if (value !== prevValueRef.current) {
      prevValueRef.current = value
      onQuestionChange && onQuestionChange(element.id, value)
    }
  }, [value])

  const mapping = {
    string: Stringg,
    text: Text,
    radio: Radio,
    checkbox: Checkboxes,
    references: References
  }

  const Element = mapping[element.type]

  return (
    <div className={styles.question}>
      <h3>
        {element.title}
      </h3>

      {element.required &&
        <div className={styles.required}>
          Обязательно для заполнения
        </div>
      }

      <Element
        element={element}
        dictionaries={dictionaries}
        value={value || (element.type === 'checkbox' ? [] : '')}
        onValueChange={handleValueChange}
      />

    </div>
  )
}
