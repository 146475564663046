import React from 'react';
import { Chart as ChartJS, ArcElement, CategoryScale, BarElement, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, CategoryScale, BarElement, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend);
ChartJS.defaults.color = '#000';
ChartJS.defaults.borderColor = '#fff';

const BarChart = (props) => {
    var labels = [];
    switch (props.number) {
        case 1:
            labels = ['обучаются в государственных и муниципальных организациях', 'обучаются в частных организациях'];
            break;
        case 2:
            labels = ['количество государственных и муниципальных организаций', 'количество частных организаций'];
            break;

        case 'd1':
            labels = ['общая численность обучающихся в вузе', 'численность обучающихся лиц с ОВЗ и инвалидностью'];
            break;
        case 'd2':
            labels = ['общая численность принятых на обучение', 'численность принятых на обучение лиц с ОВЗ и инвалидностью'];
            break;
        case 'd3':
            labels = ['общая численность выпускников', 'численность выпускников с инвалидностью'];
            break;
        case 'd4':
            labels = [
                'численность выпускников с инвалидностью трудоустроившихся в течение календарного года',
                'численность выпускников с инвалидностью трудоустроившихся по специальности в течение календарного года'
            ];
            break;

        case 'yesNo':
            labels = ['да', 'нет'];
            break;

        case 'a1':
            labels = ['реализуемых в вузе', 'по которым обучаются лица с ОВЗ и инвалидностью'];
            break;

        default:
            labels = [''];
            break;
    }

    var data = {
        labels: labels,
        datasets: [
            {
                data: props.data,
                backgroundColor: ['rgba(255, 221, 9, 0.4)', 'rgba(250, 207, 7, 0.8)'],
                borderWidth: 1,
                borderColor: ['#FFDE30', '#FACF07'],
                datalabels: {
                    color: 'rgba(250, 207, 7, 0.8)',
                    anchor: 'end',
                    align: 'vertical' in props ? 'top' : 'right',
                    font: {
                        size: '24px',
                        family: 'Fira Sans',
                        weight: 600
                    }
                }
            }
        ]
    };

    var options = {
        indexAxis: 'vertical' in props ? 'x' : 'y',
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: { display: false },
            title: { display: false },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        var label = context[0].label;
                        if (label.length >= 30) {
                            label = label.split(' ');
                            var tmp = '';
                            var res = [];
                            label.forEach((element) => {
                                if (tmp.length <= 30 && (tmp + element).length <= 30) {
                                    tmp += element + ' ';
                                } else {
                                    res.push(tmp);
                                    tmp = element + ' ';
                                }
                            });
                            res.push(tmp);
                            return res;
                        } else {
                            return label;
                        }
                    },
                    label: function (context) {
                        let res = 'inverTooltip' in props ? context.raw * -1 : context.raw;
                        return res.toLocaleString();
                    }
                }
            },
            datalabels: {
                formatter: function (value, context) {
                    return value.toLocaleString();
                }
            }
        },
        scales: {
            x: {
                display: false,
                grid: { display: false }
            },
            y: {
                display: 'showAxis' in props ? true : false,
                position: 'showAxis' in props ? props.showAxis : '',
                ticks: { display: false },
                grid: {
                    display: false,
                    borderColor: '#808080'
                }
            }
        }
    };

    if ('maXvalue' in props) {
        if ('vertical' in props) {
            options.scales.y.suggestedMax = props.maXvalue + 0.2 * props.maXvalue;
        } else {
            options.scales.x.suggestedMax = props.maXvalue + 0.2 * props.maXvalue;
        }
    }

    if ('setMax' in props) {
        let _max = 'maXvalue' in props ? props.maXvalue : Math.max.apply(Math, props.data) ? Math.max.apply(Math, props.data) : 10;
        if (!('vertical' in props)) {
            _max += 0.35 * _max;
            options.scales.x.max = _max;
        } else {
            _max += 0.2 * _max;
            options.scales.y.max = _max;
        }
    }

    var plugins = [];
    if ('setDl' in props && window.innerWidth > 500) {
        plugins = [ChartDataLabels];
    }

    if ('reverseColors' in props) {
        data.datasets[0].backgroundColor.reverse();
        data.datasets[0].borderColor.reverse();
    }

    // console.log("DATA", data, options)
    return <Bar data={data} options={options} plugins={plugins} />;
};

export default BarChart;
