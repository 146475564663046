import React, { Component } from 'react';

import Item from './Item';

import I18n from '../../I18n'

import section from './Section.module.css';

class Numbers extends Component {
  render () {
    const { data } = this.props;

    return (
      <div className={section.root}>
        <div className={section.title}>
          <h2>
            Численность обучающихся
          </h2>
        </div>

        <div className={section.list}>
          <Item data={data.total} label={'stats.numbers.total'} />

          <Item data={data.d} label={'stats.numbers.d'}>
            <div className={section.sub}>
              {(data.d.value / data.total.value * 100).toLocaleString(I18n.locale)}% от общего числа
            </div>
          </Item>

          <Item data={data.pe} label={'stats.numbers.pe'} />

          <Item data={data.e} label={'stats.numbers.e'}>
            <div className={section.sub}>
              {(data.e.value / data.d.value * 100).toLocaleString(I18n.locale)}% от числа лиц с ОВЗ
            </div>
          </Item>

          <Item data={data.h} label={'stats.numbers.h'}>
            <div className={section.sub}>
              {(data.h.value / data.d.value * 100).toLocaleString(I18n.locale)}% от числа лиц с ОВЗ
            </div>
          </Item>

          <Item data={data.mm} label={'stats.numbers.mm'}>
            <div className={section.sub}>
              {(data.mm.value / data.d.value * 100).toLocaleString(I18n.locale)}% от числа лиц с ОВЗ
            </div>
          </Item>

          <Item data={data.mn} label={'stats.numbers.mn'}>
            <div className={section.sub}>
              {(data.mn.value / data.d.value * 100).toLocaleString(I18n.locale)}% от числа лиц с ОВЗ
            </div>
          </Item>

          <Item data={data.s} label={'stats.numbers.s'}>
            <div className={section.sub}>
              {(data.s.value / data.d.value * 100).toLocaleString(I18n.locale)}% от числа лиц с ОВЗ
            </div>
          </Item>

          <Item data={data.p} label={'stats.numbers.p'}>
            <div className={section.sub}>
              {(data.p.value / data.d.value * 100).toLocaleString(I18n.locale)}% от числа лиц с ОВЗ
            </div>
          </Item>

          <Item data={data.o} label={'stats.numbers.o'}>
            <div className={section.sub}>
              {(data.o.value / data.d.value * 100).toLocaleString(I18n.locale)}% от числа лиц с ОВЗ
            </div>
          </Item>

        </div>
      </div>
    );
  }
}

export default Numbers
