import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Filters from '../Filters/Filters';

import { path } from '../Routes';
import Datec from '../Datec';
import I18n from '../I18n';

import styles from './List.module.css';

class List extends Component {
  render() {
    const { items, filters } = this.props;
    const { history, location } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.filters}>
          {filters &&
            <Filters {...this.props} filters={filters} history={history} location={location} />
          }
        </div>

        {items && items.length > 0 &&
          <div className={styles.list}>
            {items.map((item, _) =>
              <Link to={path(`account_${item.type}_path`,{ id: item.id })} key={item.id} className={styles.item}>
                {item.state &&
                  <div className={styles.state}>
                    <div>
                      {I18n.t(`state.${item.state.id}`)}
                    </div>

                    <div className={styles.current}>
                      {item.state.states.findIndex(state => state.active) + 1}/{item.state.states.length}
                    </div>
                  </div>
                }

                <div className={styles.title}>
                  #{_ + 1} • {item.title}
                </div>

                <div className={styles.desc}>
                  <div className={styles.date}>
                    <Datec time={item.created_at} /> 
                    {item.updated_at &&
                      <>
                        (<Datec time={item.updated_at} />)
                      </>
                    }
                  </div>

                  {item.rumc &&
                    <div className={styles.rumc}>
                      {item.rumc.title}
                    </div>
                  }
                </div>
              </Link>
            )}
          </div>
        }

      </div>
    );
  }
}

export default List;
