import React from 'react';
import PropTypes from 'prop-types';

import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import update from 'immutability-helper';

import I18n from '../../I18n';
import { path } from '../../Routes';

import Loaf from '../../Loaf/Loaf';
import Files from '../../Attachments/Files'

import page from '../../Page.module.css';
import form from '../../Form.module.css';
import buttons from '../../Buttons.module.css';

class Form extends React.Component {
  state = {
    attachments: [],
    values: {
      title: '',
      desc: '',
      body: '',
      volume: '',
      authors: '',
      results: '',
      system: '',
      attachment_ids: [],
      attachments_attributes: [],
    },
    course: {
      inactive: true,
    },
    submit: {
      url: path('account_courses_path', {}, true),
      method: 'post',
    },
    send: false,
    redirect_to: null
  }

  componentDidMount() {
    if(this.props.id) {
      this._loadAsyncData(this.props.id);
    }
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();
      axios.get(path('edit_account_course_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState(prevState => ({
          course: res.data.course,
          values: {
            title: res.data.course.title,
            desc: res.data.course.desc,
            body: res.data.course.body,
            volume: res.data.course.volume || '',
            authors: res.data.course.authors,
            results: res.data.course.results,
            system: res.data.course.system,
          },
          attachments: res.data.attachments || [],
          submit: {
            method: 'patch',
            url: path('account_course_path', {id: this.props.id}, true),
          }
        }));

        this._asyncRequest = null;
      });
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }));
  }

  handleFilesChanged = files => {
    this.setState(prevState => ({
      values: { ...prevState.values,
        attachments_attributes: [...files.values()].map(file => ({ id: file.id, title: file.title, section: file.section })),
        attachment_ids: [...files.values()].map(file => file.id)
      }
    }))
  }

  handleSubmit = (e) => {
    if(this.canSubmit()) {
      this.setState({
        send: true
      });

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        data: {
          course: this.state.values
        },
      }).then(
        res => {
          this.props.history.push(res.headers.location)
        }
      );
    }

    e.preventDefault();
  }

  canSubmit = () => {
    if (this.state.course.inactive)
      return (
        this.state.values.title &&
        this.state.values.desc &&
        !this.state.send
      );
    else
      return (
        this.state.values.title &&
        this.state.values.desc &&
        this.state.values.body &&
        this.state.values.volume &&
        this.state.values.authors &&
        this.state.values.results &&
        this.state.values.system &&
        !this.state.send
      )
  }

  render () {
    const { course, attachments } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={path('account_courses_path')}>{I18n.t('courses.title')}</Link>
          </Loaf>
          <div className={page.title}>
            <h1>
              {course ? 'Редактирование онлайн курса' : 'Новый онлайн курс' }
            </h1>
          </div>

          <div className={form.tight}>
            <form className="form" onSubmit={this.handleSubmit}>
              <div className="input">
                <div className="input_label">
                  <label>
                    {I18n.t('simple_form.labels.online_course.title')}
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="title" value={this.state.values.title} onChange={this.handleInputChange} />
                </div>

                <div className="input_desc">
                  Название курса, не более 70 символов.
                </div>
              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    {I18n.t('simple_form.labels.online_course.desc')}
                  </label>
                </div>

                <div className="input_input">
                  <textarea name="desc" value={this.state.values.desc} rows="3" onChange={this.handleInputChange} />
                </div>

                <div className="input_desc">
                  Краткое и основное содержание курса в 1-2 предложениях, не более 300 символов.
                </div>
              </div>

              {!this.state.course.inactive &&
                <React.Fragment>
                  <div className="input">
                    <div className="input_label">
                      <label>
                        {I18n.t('simple_form.labels.online_course.body')}
                      </label>
                    </div>

                    <div className="input_input">
                      <textarea name="body" value={this.state.values.body} rows="9" onChange={this.handleInputChange} />
                    </div>

                    <div className="input_desc">
                      Объем расширенной аннотации может быть любым.
                    </div>
                  </div>

                  <div className="input">
                    <div className="input_label">
                      <label>
                        {I18n.t('simple_form.labels.online_course.volume')}
                      </label>
                    </div>

                    <div className="input_input">
                      <input type="text" name="volume" value={this.state.values.volume} onChange={this.handleInputChange} />
                    </div>
                  </div>

                  <div className="input">
                    <div className="input_label">
                      <label>
                        {I18n.t('simple_form.labels.online_course.authors')}
                      </label>
                    </div>

                    <div className="input_input">
                      <textarea name="authors" value={this.state.values.authors} rows="5" onChange={this.handleInputChange} />
                    </div>
                  </div>

                  <div className="input">
                    <div className="input_label">
                      <label>
                        {I18n.t('simple_form.labels.online_course.results')}
                      </label>
                    </div>

                    <div className="input_input">
                      <textarea name="results" value={this.state.values.results} rows="5" onChange={this.handleInputChange} />
                    </div>
                  </div>

                  <div className="input">
                    <div className="input_label">
                      <label>
                        {I18n.t('simple_form.labels.online_course.system')}
                      </label>
                    </div>

                    <div className="input_input">
                      <textarea name="system" value={this.state.values.system} rows="5" onChange={this.handleInputChange} />
                    </div>
                  </div>

                  <Files
                    files={this.state.attachments}
                    onFilesChanged={this.handleFilesChanged}
                    sections={['online_course']}
                  />
                </React.Fragment>
              }

              <div className="input_submit">
                <input type="submit" value="Сохранить курс" className={buttons.main} disabled={!this.canSubmit()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Form
