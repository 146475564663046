import React, { useState, useEffect } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import Link, { path } from '../Routes'
import I18n from '../I18n'
import Loaf from '../Loaf/Loaf'
import { useForm, Errors } from '../Form'

import page from '../Page.module.css'
import form from '../Form.module.css'
import buttons from '../Buttons.module.css'
import styles from './Form.module.css'

export default function Form () {
  const { id } = useParams()
  // const { send, errors, setSend, setErrors, cancelToken, success, setSuccess } = useForm()

  const [school, setSchool] = useState()

  const {
    values,
    setValues,
    handleInputChange,
    cancelToken,
    errors,
    setErrors,
    send,
    setSend
  } = useForm()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(
        path('edit_school_path', { id }, true),
        { cancelToken: cancelToken.current.token }
      )

      setSchool(data.school)
      setValues(data.values)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    await axios.patch(
      path('school_path', { id }),
      { school: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      // res.headers.location && history.push(res.headers.location)
      history.back()
      // console.log(history.length)
      // console.log(history)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  return (
    <div className={page.root}>
      <div className={page.container}>
        <Loaf>
          <Link to="schools_path">{I18n.t('schools.nav')}</Link>

          {school &&
            <Link to="school_path" params={{ id: school.id }}>{school.title_last}</Link>
          }
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('schools.edit.title')}
          </h1>
          <Helmet>
            <title>
              {I18n.t('schools.edit.title')}
            </title>
          </Helmet>
        </div>

        <div className={styles.help}>
          <p>
            Пожалуйста, обратите внимание на корректность заполнения! После точек, запятых и скобок нужно ставить пробел.
          </p>
          <p>
            Плохо:
          </p>
          <ul>
            <li>
              (3812)320-614 (нет кода страны и пробела после скобки)
            </li>
            <li>
              644024, г. Омск, ул. Ленина, д.20. (точка в конце и нет проблема после точки)
            </li>
            <li>
              в г.Можайск (нет пробела после точки)
            </li>
            <li>
              г.Уфа, ул.50-летия Октября , 34 (лишний пробел перед запятой и нет пробела после точек)
            </li>
            <li>
              162600 Вологодская область, г.Череповец, пр.Луначарского, д.5 (нет запятой после индекса, нет пробела после точек)
            </li>
          </ul>

          <p>
            Хорошо:
          </p>
          <ul>
            <li>
              +7 (3812) 320-614
            </li>
            <li>
              644024, Омск, ул. Ленина, д. 20
            </li>
            <li>
              в г. Можайск
            </li>
            <li>
              450001, Уфа, ул. 50-летия Октября, 34
            </li>
            <li>
              162600, Вологодская область, Череповец, пр. Луначарского, д. 5
            </li>
          </ul>
        </div>

        <div className={form.tight}>
          {values &&
            <form onSubmit={handleSubmit}>
              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Полное название
                  </div>

                  <div className={form.input}>
                    <textarea value={values.title} name="title" onChange={handleInputChange} />
                  </div>
                </label>

                {errors.title &&
                  <div className={form.error}>
                    <ul>
                      {errors.title.map((error, _) => <li key={_}>{error}</li>)}
                    </ul>

                  </div>
                }

                <div className={form.hint}>
                  Полное название организации вместе с правовой формой (без сокращений) в соответствии с уставными документами — должно быть на сайте организации в разделе «Сведения об образовательной организации». Например: Федеральное государственное бюджетное образовательное учреждение высшего образования «Нижегородский государственный педагогический университет имени Козьмы Минина»
                </div>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Короткое название
                  </div>

                  <div className={form.input}>
                    <textarea value={values.title_short} name="title_short" onChange={handleInputChange} />
                  </div>
                </label>

                {errors.title_short &&
                  <div className={form.error}>
                    <ul>
                      {errors.title_short.map((error, _) => <li key={_}>{error}</li>)}
                    </ul>

                  </div>
                }

                <div className={form.hint}>
                  Короткое название <strong>БЕЗ</strong> правовой формы и <strong>БЕЗ</strong> кавычек. Например: Нижегородский государственный педагогический университет имени Козьмы Минина
                </div>

                <div className={form.error}>
                  В этом поле не указывать правовую форму: АНО, ФГБОУ, НИИ и прочее...)! И не указывать сокращения: НГТУ, МГПУ, МДАДИ и прочее. Аббревиатуры нужно указывать в след. поле! <strong>Аббревиатуры в этом поле допускаются для филиалов если длинное название основного вуза, например: Карельский филиал РАНХиГС или когда аббревиатура является частью названия, например: Институт МИРБИС</strong>
                </div>

                <div className={styles.help}>
                  Пожалуйста, отнеситель к заполнению этого поля очень внимательно и ответственно! Это основное поле для идентификации вашего вуза другими участниками системы, пишите с большой буквы, максимально просто и понятно!
                </div>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Родительный падеж
                  </div>

                  <div className={form.input}>
                    <textarea value={values.title_possessive} name="title_possessive" onChange={handleInputChange} />
                  </div>
                </label>

                <div className={form.hint}>
                  Необязательное поле, заполняется только при наличии филиалов. Указывается название вуза БЕЗ правовой формы в родительном падеже и БЕЗ кавычек. Например: Нижегородского государственного педагогического университета имени Козьмы Минина
                </div>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Аббревиатуры и альтернативные названия
                  </div>

                  <div className={form.input}>
                    <textarea value={values.title_alternatives} name="title_alternatives" onChange={handleInputChange} />
                  </div>
                </label>

                <div className={form.hint}>
                  Необязательное поле, заполняется только если у вуза есть аббревиатуры или альтернативные названия БЕЗ правовой формы. Заполняются через запятую в порядке уменьшения приоритета. Поле необходимо только для поиска! Например: Мининский университет, НГПУ им. К. Минина, НГПУ
                </div>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Телефон
                  </div>

                  <div className={form.input}>
                    <input type="text" value={values.phone} name="phone" onChange={handleInputChange} />
                  </div>
                </label>

                {errors.phone &&
                  <div className={form.error}>
                    <ul>
                      {errors.phone.map((error, _) => <li key={_}>{error}</li>)}
                    </ul>

                  </div>
                }

                <div className={form.hint}>
                  Формат номера: +7 (831) 299-99-99 или 8 800 999-99-99. Желательно указывать только один вариант, несколько телефонов указывать через запятую. Не более 3 номеров.
                </div>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    ИНН
                  </div>

                  <div className={form.input}>
                    <input type="text" value={values.inn} name="inn" onChange={handleInputChange} />
                  </div>
                </label>

                <Errors errors={errors.inn} />

                <div className={form.hint}>
                  Указывается только номер ИНН и только один (без КПП)! В случае если вуз находится вне РФ (ИНН нет) - поставьте точку.
                </div>
              </div>

              {(values.vpo_id || values.vpo_id === '') &&
                <div className={form.el}>
                  <label>
                    <div className={form.label}>
                       ВПО ID
                    </div>

                    <div className={form.input}>
                      <input type="text" value={values.vpo_id} name="vpo_id" onChange={handleInputChange} />
                    </div>
                  </label>
                </div>
              }

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    ОГРН
                  </div>

                  <div className={form.input}>
                    <input type="text" value={values.ogrn} name="ogrn" onChange={handleInputChange} />
                  </div>
                </label>

                <Errors errors={errors.ogrn} />
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Адрес
                  </div>

                  <div className={form.input}>
                    <textarea value={values.address} name="address" onChange={handleInputChange} />
                  </div>
                </label>

                {errors.address &&
                  <div className={form.error}>
                    <ul>
                      {errors.address.map((error, _) => <li key={_}>{error}</li>)}
                    </ul>

                  </div>
                }

                <div className={form.hint}>
                  Только один адрес! Например: 603950, Нижний Новгород, ул. Ульянова, 1
                </div>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Сайт вуза
                  </div>

                  <div className={form.input}>
                    <input type="text" value={values.site} name="site" onChange={handleInputChange} />
                  </div>
                </label>

                {errors.site &&
                  <div className={form.error}>
                    <ul>
                      {errors.site.map((error, _) => <li key={_}>{error}</li>)}
                    </ul>

                  </div>
                }

                <div className={form.hint}>
                  Только один вариант!
                </div>
              </div>

              {/* {saved &&
                <div className={form.success}>
                  Настройки успешно сохранены
                </div>
              } */}

              <div className={form.submit}>
                <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                  Сохранить
                </button>
              </div>

              {Object.entries(errors).length > 0 &&
                <div className={form.error}>
                  Необходимо исправить ошибки!
                </div>
              }
            </form>
          }
        </div>
      </div>
    </div>
  )
}

// class Form extends Component {
//   state = {
//     saved: false,
//     errors: {},
//     values: null
//   }
//
//   handleSubmit = async e => {
//     e.preventDefault()
//
//     await axios.patch(
//       path('school_path', { id: this.props.id }, true),
//       { school: this.state.values }
//     ).then(res => {
//       this.setState({ errors: {}, saved: true })
//     }).catch(({ response }) => {
//       this.setState({ errors: response.data, saved: false })
//     })
//   }
//
//   _handleInputChange = event => {
//     const target = event.target
//     const value = target.value
//     const name = target.name
//
//     this.setState(state => ({
//       values: { ...state.values, [name]: value }
//     }))
//   }
//
//   componentDidMount () {
//     this._loadAsyncData()
//   }
//
//   _loadAsyncData = async () => {
//     const res = await axios(path('edit_school_path', { id: this.props.id }, true))
//
//     this.setState({ ...res.data })
//   }
//
//   render () {
//     const { values, school, errors, saved } = this.state
//
//     return (
//     )
//   }
// }
//
// export default Form
