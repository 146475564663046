import React from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import qs from 'query-string'
import classNames from 'classnames'

import { path } from '../../Routes'

import I18n from '../../I18n'

import styles from './Common.module.css'
import tables from '../../Tables.module.css'

class Data extends React.Component {
  state = {
    results: null
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  _loadAsyncData () {
    axios.get(path('tomorrow_data_path', {}, true), { params: qs.parse(this.props.location.search) })
      .then(res => {
        this.setState({
          results: res.data.results
        })

        this._asyncRequest = null
      })
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  render () {
    const { results } = this.state
    const nominations = {
      1: 'Эссе «Почему я ценный и полезный работник: 10 аргументов»',
      2: 'Профессиональный старт-ап молодежи',
      3: 'Исследовательская работа',
      4: 'Мое полезное изобретение',
      5: 'Социальная реклама'
    }

    return (
      <div className={styles.root}>
        <Helmet>
          <title>{I18n.t('events.tomorrow.results')}</title>
        </Helmet>
        <h2>
          {I18n.t('events.tomorrow.results')}
        </h2>

        <p>
          Список участников Конкурса, прошедших в очный этап Конкурса
        </p>

        {results &&
          <React.Fragment>
            <table className={tables.root}>
              <thead>
                <tr>
                  <th></th>
                  <th className={styles.authors}>ФИО участника</th>
                  <th>Курс обучения</th>
                  <th>Номинация</th>
                  <th>Куратор</th>
                  <th>Адрес эклектронной почты куратора</th>
                  <th>Название вуза</th>
                  <th>Адрес эклектронной почты</th>
                  <th>Телефон</th>
                  <th>Нозология</th>
                </tr>
              </thead>
              <tbody>
                { results.map((value, _) =>
                  <tr key={_}>
                    <td>{_ + 1}</td>
                    <td className={styles.authors}>{value.authors}</td>
                    <td>{value.year}</td>
                    <td>{nominations[value.nomination]}</td>
                    {value.curator &&
                      <>
                        <td>{value.curator.name}</td>
                        <td>{value.curator.email}</td>
                      </>
                    }
                    <td>{value.school}</td>
                    <td>{value.email}</td>
                    <td>{value.phone}</td>
                    <td>{value.nosology}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </React.Fragment>
        }
      </div>
    );
  }
}

export default Data
