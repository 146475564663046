import React, { Component } from 'react';

import Item from './Item';

import I18n from '../../I18n'

import section from './Section.module.css';

class Employees extends Component {
  render () {
    const { data } = this.props;

    return (
      <div className={section.root}>
        <div className={section.title}>
          <h2>
            Обеспеченность процесса обучения лиц с ОВЗ и инвалидностью в вузе кадрами
          </h2>
        </div>

        <div className={section.list}>
          <Item data={data.ofc} label={'stats.employees.ofc'} />
          <Item data={data.resp} label={'stats.employees.resp'} />

          <Item data={data.pp} label={'stats.employees.pp'} />
          <Item data={data.sp} label={'stats.employees.sp'} />
          <Item data={data.spt} label={'stats.employees.spt'} />
          <Item data={data.po} label={'stats.employees.po'} />
          <Item data={data.pry} label={'stats.employees.pry'} />
          <Item data={data.tp} label={'stats.employees.tp'} />
          <Item data={data.t} label={'stats.employees.t'} />
          <Item data={data.as} label={'stats.employees.as'} />
          <Item data={data.o} label={'stats.employees.o'} />

          <Item data={data.ag} label={'stats.employees.ag'} />

          <Item data={data.pps} label={'stats.employees.pps'} />
          <Item data={data.pov} label={'stats.employees.pov'}>
            <div className={section.sub}>
              {(data.pov.value / data.pps.value * 100).toLocaleString(I18n.locale)}% от общего числа ППС
            </div>
          </Item>
        </div>
      </div>
    );
  }
}

export default Employees
