import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'

import page from '../../Page.module.css'
import styles from './Schools.module.css'

export default function Schools () {
  const cancelToken = useRef(axios.CancelToken.source())
  const search = useLocation().search
  const query = new Map(new URLSearchParams(useLocation().search))

  const [reports, setReports] = useState()
  const [fields, setFields] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(
        path('statistics_reports_schools_path', {}, true),
        {
          params: {
            monitoring: query.get('monitoring')
          }
        },
        { cancelToken: cancelToken.current.token }
      )

      setReports(data.reports)
      setFields(data.fields)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [search])

  // console.log(query.get('monitoring'))

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="statistics_path">{I18n.t('statistics.title')}</Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('statistics.reports.schools.title')}
          </h1>

          <Helmet>
            <title>{I18n.t('statistics.reports.schools.title')}</title>
          </Helmet>
        </div>

        {reports && fields &&
          <table className={styles.table}>
            <thead>
              <tr>
                <td rowSpan="2">
                  ID формы
                </td>

                <td colSpan="2">
                  Вуз
                </td>

                <td colSpan="2">
                  Округ
                </td>

                <td colSpan="2">
                  Регион
                </td>

                <td colSpan="2">
                  Министерство
                </td>

                <td rowSpan="2" colSpan="2">
                  Соглашение
                </td>

                {Object.entries(fields).map(ob =>
                  <React.Fragment key={ob[0]}>
                    {Object.entries(ob[1]).map(obv =>
                      <td key={`${ob[0]}_${obv[0]}`} colSpan="2">
                        {I18n.t(`stats.${ob[0]}.${obv[0]}`)}
                      </td>
                    )}
                  </React.Fragment>
                )}

                {['abitur', 'submit', 'student', 'graduate'].map(type =>
                  <React.Fragment key={type}>
                    <td rowSpan="2">
                      {I18n.t(`stats.${type}.ovall`)}
                    </td>

                    <td rowSpan="2">
                      {I18n.t(`stats.${type}.disabled`)}
                    </td>

                    {['e', 'h', 'nm', 'mm', 's', 'p', 'o'].map(nosology =>
                      <td key={nosology} rowSpan="2">
                        {I18n.t(`stats.${type}.${nosology}`)}
                      </td>
                    )}
                  </React.Fragment>
                )}
              </tr>

              <tr>
                <td>ID</td>
                <td className={styles.title}>Название</td>

                <td>ID</td>
                <td className={styles.title}>Название</td>

                <td>ID</td>
                <td className={styles.title}>Название</td>

                <td>ID</td>
                <td className={styles.title}>Название</td>

                {Object.entries(fields).map(ob =>
                  <React.Fragment key={ob[0]}>
                    {Object.entries(ob[1]).map(obv =>
                      <React.Fragment key={`${ob[0]}_${obv[0]}`}>
                        <td>
                          Ключ
                        </td>
                        <td>
                          Значение
                        </td>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </tr>
            </thead>
            <tbody>
              {reports.map(report =>
                <tr key={report.id}>
                  <td>
                    {report.id}
                  </td>

                  <td>
                    {report.school.id}
                  </td>

                  <td>
                    {report.school.label}
                  </td>

                  <td>
                    {report.region.id}
                  </td>

                  <td>
                    {report.region.label}
                  </td>

                  <td>
                    {report.district.id}
                  </td>

                  <td>
                    {report.district.title}
                  </td>

                  <td>
                    {report.ministry.department}
                  </td>

                  <td>
                    {I18n.t(`statistics.ministries.${report.ministry.department}`)}
                  </td>

                  {report.rumc_schools &&
                    <>
                      <td>
                        {report.rumc_schools.rumc.id}
                      </td>
                      <td className={styles.title}>
                        {report.rumc_schools.rumc.label}
                      </td>
                    </>
                  }

                  {!report.rumc_schools &&
                    <>
                      <td>
                        0
                      </td>
                      <td>
                        нет
                      </td>
                    </>
                  }

                  {Object.entries(fields).map(ob =>
                    <React.Fragment key={ob[0]}>
                      {Object.entries(ob[1]).map(obv =>
                        <React.Fragment key={`${ob[0]}_${obv[0]}`}>
                          <td>
                            <Key value={report[`${ob[0]}_${obv[0]}`]} />
                          </td>
                          <td>
                            <Value value={report[`${ob[0]}_${obv[0]}`]} />
                          </td>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}

                  {['abitur', 'submit', 'student', 'graduate'].map(type =>
                    <React.Fragment key={type}>
                      <td>
                        {report[`${type}_ovall`]}
                      </td>

                      <td>
                        {report[`${type}_disabled`]}
                      </td>

                      {['e', 'h', 'nm', 'mm', 's', 'p', 'o'].map(nosology =>
                        <td key={nosology}>
                          {report[`${type}_${nosology}`]}
                        </td>
                      )}
                    </React.Fragment>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        }
      </div>
    </div>
  )
}

function Key ({ value }) {
  let d = value
  if (typeof value === 'boolean') {
    d = value ? 1 : 0
  }

  return d
}

function Value ({ value }) {
  let d = value
  if (typeof value === 'boolean') {
    d = value ? 'да' : 'нет'
  }

  return d
}
