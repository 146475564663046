import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';
import dayjs from 'dayjs';

import I18n from '../../I18n';

import Link from '../../Routes';

import styles from './RumcBlock.module.css';
import page from '../../Page.module.css';
import fonts from '../../Fonts.module.css';

class RumcBlock extends React.Component {
  state = {
    block: null
  }

  buildActivityLine = (activity) =>  {
    const weeks = dayjs().isoWeeksInYear();
    let max = Math.max.apply(null, this.props.rumcs.map(r => Math.max.apply(null, r.activity.map(a => a[1]))));
    const avarage = (this.props.rumcs.filter(r => r.activity.length > 0).map(r => r.activity.reduce((a, b) => a + b[1], 0) / r.activity.length).reduce((a, b) => a + b, 0) / this.props.rumcs.length);
    let activityPoints = [];

    max = avarage * 4;

    for(let i = 1; i <= weeks; i++) {
      // activityPoints.push([])
      let ca = activity.find(x => x[0] == i);

      if(ca) {
        let y = ca[1];
        if(y >= max) {
          y = max;
        }

        activityPoints.push([i / weeks * 150, 29 - y / max * 28]);
      } else {
        activityPoints.push([i / weeks * 150, 29 - 0 / max * 28]);
      }
    }

    return activityPoints.join(' ');
  }

  render() {
    const rumc = this.props.rumc;

    return (
      <div className={styles.root}>

        <div className={styles.content}>
          <div className={classNames(styles.head, {[styles.withUrl]: true})}>

            <div className={classNames(styles.title, fonts.as_h3)}>
              {rumc.title_short}
            </div>

            <div className={classNames(styles.desc, fonts.as_at)}>
              {rumc.title}
            </div>

            <Link to="account_rumc_path" params={{id: rumc.id}} className={styles.url}/>

          </div>

          <div className={classNames(styles.sections)}>
            <div className={styles.section}>

              <div className={styles.title}>
                Активность
              </div>

              <div className={classNames(styles.desc, fonts.as_at)}>
                <div className={styles.graph}>
                  <svg viewBox="0 0 150 30">
                    <polyline points={this.buildActivityLine(rumc.activity)} />
                  </svg>
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.title}>
                Необходимых соглашений: {rumc.rumc_schools_total}
              </div>
            </div>

            <div className={styles.section}>

              <div className={styles.title}>
                Пользователи
              </div>

              <div className={classNames(styles.desc, fonts.as_at)}>
                {rumc.users &&
                  Object.entries(rumc.users).map((role, _) =>
                    <>
                      {role[0] == 'expert' &&
                        <div className={styles.users} key={_}>
                          <Link to='account_experts_path'>
                            {I18n.t(`roles.${role[0]}`)}: {role[1]}
                          </Link>
                        </div>
                      }

                      {role[0] != 'expert' &&
                        <div className={styles.users} key={_}>{I18n.t(`roles.${role[0]}`)}: {role[1]}</div>
                      }
                    </>
                  )
                }
              </div>

            </div>

            <div className={styles.section}>
              <div className={classNames(styles.counter)}>
                {rumc.counters.map((counter, _) =>
                  <Counter counter={counter} key={_} I18n={I18n} rumc={rumc}/>
                )}
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.title}>
                Отчеты: {rumc.reports.target_documents.length} из {rumc.reports.overall}
              </div>
              <div className={classNames(styles.reports)}>
                {rumc.reports.target_documents.length > 0 && rumc.reports.target_documents.map((report, _) =>
                  <div>{report.title} ({report.created_at})</div>
                )}
              </div>
              { rumc.reports.missing.length > 0 &&
                <>
                  <div className={styles.title}>Отсутствуют отчеты:</div>
                  <div className={classNames(styles.reports)}>
                    { rumc.reports.missing.map((label, _) =>
                      <div>{I18n.t(`target_documents.titles.${label}`)}</div>
                    )}
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function Counter(props) {
  let title = props.counter.title;
  let current = props.counter.current;
  let approved = props.counter.approved;
  let states = props.counter.states;
  let url = props.counter.url;
  let rumc = props.rumc;

  const total = props.counter.total;

  let total_condition = (total && current < total);
  let approved_condition = (total_condition && current + approved >= total);

  return (
    <React.Fragment>
      <div>
        {`${title}: `}
        <a href={url} target="_blank" className={classNames(styles.current, { [styles.total_condition]: total_condition, [styles.approved_condition]: approved_condition })}>
          {`${current}${approved ? ` + ${approved}` : ''}${total ? ` из ${total}` : ''}`}
        </a>

        {states && Object.keys(states).map((state, _) =>
          <a href={`${url}&state=${state}&rumc=${rumc.id}`} key={_} className={classNames(styles.current, styles[state])}>{props.I18n.t(`rumcs.states.${state}`)}: {states[state]}</a>
        )}
      </div>
    </React.Fragment>
  );
}

export default RumcBlock;
