import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import qs from 'query-string';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import Datec from '../../Datec';
import { path } from '../../Routes';
import AttachmentsList from '../../Attachments/AttachmentsList';

import Comments from '../../Comments/Comments';

import page from '../../Page.module.css';
import buttons from '../../Buttons.module.css';
import styles from './Show.module.css';
import stylesStates from '../States.module.css';

class Show extends Component {
  state = {
    story: null,
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('account_story_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { story } = res.data;

        this.setState({ story });
        this._asyncRequest = null;
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.story === null) this._loadAsyncData(this.props.id);
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleStateChange = (event) => {
    this._asyncRequest = axios.CancelToken.source();

    axios({
      method: 'post',
      cancelToken: this._asyncRequest.token,
      url: path(`${event}_account_story_path`, { id: this.props.id }, true),
    }).then(
      res => {
        this.setState({
          story: null,
        });
      }
    );
  }

  render() {
    const { story } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={`${path('account_stories_path')}?${qs.stringify(cookies.get('filters', { path: path('account_stories_path') }))}`}>{I18n.t('stories.title')}</Link>
          </Loaf>

          {story &&
            <>
              <div className={page.title}>
                <h1>
                  {story.title}
                </h1>

                <Helmet>
                  <title>{story.title}</title>
                </Helmet>
              </div>

              <div className={stylesStates.root}>
                <div className={stylesStates.list}>
                  <div className={classNames(stylesStates.item, stylesStates.item_link)} key={99}>
                    {`Ссылка на страницу: https://инклюзивноеобразование.рф${decodeURIComponent(path('account_story_path', { id: this.props.id }))}`}
                  </div>
                  {story.state.states.map((state, i) =>
                    <div className={classNames(stylesStates.item, { [stylesStates.show]: state.show })} key={state.id}>
                      <div className={classNames(stylesStates.current, {[stylesStates.passed]: state.passed}, { [stylesStates.active]: state.active }, { [stylesStates.published]: state.id == 'published' && state.active})}>
                        {i + 1}
                      </div>

                      <div className={stylesStates.rest}>
                        <div>
                          {I18n.t(`state.${state.id}`)}
                        </div>

                        {state.id == 'active' &&
                          <div className={styles.active}>
                            <p>
                              <strong>
                                {story.rumc.title}
                              </strong>
                              <br />
                              История была создана <Datec time={story.created_at} />.
                              {story.can_edit &&
                                <>
                                  <br/>
                                  <Link to={path('edit_account_story_path', {id: story.id })}>Редактировать</Link>
                                </>
                              }
                            </p>

                            <AttachmentsList attachments={story.attachments} />
                          </div>
                        }


                        {state.active &&
                          <>
                            {state.id == 'done' &&
                              <div className={styles.done}>
                                <p>
                                  По мнению авторов эта история успеха готова к публикации, поэтому перешла на проверку экспертам. После согласования она будет оценена и опубликована в случае соответствия требованиям.
                                </p>
                              </div>
                            }

                            {state.id == 'agreed' &&
                              <div className={styles.review}>
                                <p>
                                  Эксперты проверили эту историю успеха, теперь ей нужно проставить оценки и после этого редактор сможет опубликовать историю для публичного отображения на страницах портала.

                                  {story.can_review &&
                                    <>
                                      <br />
                                      <Link to={path('review_account_story_path', { id: story.id })}>
                                        Поставить оценки
                                      </Link>
                                      <div className={classNames(styles.reviewed, { [styles.not]: story.review.rated_fields < story.review.fields })}>
                                        {`${story.review.rated_fields} из ${story.review.fields}`}
                                      </div>
                                    </>
                                  }
                                </p>
                              </div>
                            }

                            {state.id == 'archived' &&
                              <div className={styles.archived}>
                                <p>
                                  Соглашение находится в архиве и участвует в расчете показателей. Вы можете разархивировать чтобы изменить оценки или чтобы оно не участвовало в расчете показателей.
                                </p>
                              </div>
                            }

                            {state.events && state.events.length > 0 &&
                              <div className={styles.events}>
                                {state.events.map(event =>
                                  <div className={styles.event} key={event}>
                                    <button className={buttons.main} onClick={() => this.handleStateChange(event)}>{I18n.t(`state.events.${event}`)}</button>
                                  </div>
                                )}
                              </div>
                            }
                          </>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Comments object="Story" id={this.props.id} />
            </>
          }
        </div>
      </div>
    );
  }
}

export default Show;
