import React from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import { path } from '../../Routes'

import I18n from '../../I18n'

import styles from './Common.module.css'
import tables from '../../Tables.module.css'

class Results extends React.Component {
  state = {
    results: null
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  _loadAsyncData () {
    axios.get(path('tomorrow_results_path', {}, true))
      .then(res => {
        this.setState({
          results: res.data.results
        })

        this._asyncRequest = null
      });
  }

  componentWillUnmount() {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  render () {
    const { results } = this.state
    const nominations = {
      1: 'Эссе «Почему я ценный и полезный работник: 10 аргументов»',
      2: 'Профессиональный старт-ап молодежи',
      3: 'Исследовательская работа',
      4: 'Мое полезное изобретение',
      5: 'Социальная реклама',
    }

    return (
      <div className={styles.root}>
        <Helmet>
          <title>{I18n.t('events.tomorrow.results')}</title>
        </Helmet>
        <h2>
          {I18n.t('events.tomorrow.results')}
        </h2>

		<p>Список победителей VII Всероссийского сетевого конкурса студенческих проектов «Профессиональное завтра» в <b>2024</b> году будет доступен по ссылке <a href="https://profzavtra.nstu.ru/winners/" target="_blank">https://profzavtra.nstu.ru/winners/</a> после завершения и подведения итогов Фестиваля. </p>

		<hr />
		
        <p>
          Список победителей Всероссийского сетевого конкурса студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью в 2021 году (публикация официальных результатов на сайте оператора Конкурса - <a target="_blank" href="https://rumc.cfuv.ru/node/168">https://rumc.cfuv.ru/node/168</a>):
        </p>


        <h3>Номинация «Исследовательская работа»</h3>
        <table className={tables.root}>
          <thead>
            <tr>
              <th className={styles.authors}>ФИО участника</th>
              <th>Тема работы</th>
              <th>Название вуза</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="3" className={styles.place}>1 место</td>
            </tr>
            <tr>
              <td>Назарова Екатерина Николаевна</td>
              <td><i>«Доступность и открытость Интернет-ресурсов как условие эффективного поиска работы соискателями с ОВЗ и инвалидностью»</i></td>
              <td>ФГБОУ ВО «Нижегородский государственный педагогический университет им. Козьмы Минина», г. Нижний Новгород</td>
            </tr>
            <tr>
              <td>Воеводская Анастасия Витальевна, Охотникова Елизавета Витальевна, Кузьменко Дарья Егоровна</td>
              <td><i>«О положении выпускников педагогического вуза с инвалидностью на рынке труда»</i></td>
              <td>ФГБОУ ВО «Новосибирский государственный педагогический университет», г.Новосибирск</td>
            </tr>
            <tr>
              <td colspan="3" className={styles.place}>2 место</td>
            </tr>
            <tr>
              <td>Бабина Алина Андреевна, Переломова Алина Николаевна, Чакичева Екатерина </td>
              <td><i>«Интерактивные практики как инструменты содействия трудоустройству выпускников вузов с инвалидностью»</i></td>
              <td>«Новосибирский государственный технический университет», г.Новосибирск</td>
            </tr>
            <tr>
              <td>Филимонова Наталья Сергеевна</td>
              <td><i>«Исследование мотивации трудоустройства обучающихся с ОВЗ и инвалидностью (на примере Бурятской ГСХА им. В.Р. Филиппова)»</i></td>
              <td>ФГБОУ ВО "Бурятская государственная сельскохозяйственная академия им. В.Р. Филиппова", г. Улан-Удэ</td>
            </tr>
            <tr>
              <td colspan="3" className={styles.place}>3 место</td>
            </tr>
            <tr>
              <td>Дронова Владислава Андреевна, Быстрова Алина Евгеньевна</td>
              <td><i>«Сопровождаемое трудоустройство на открытом рынке труда для выпускников с расстройствами аутистического спектра»</i></td>
              <td>Московский гуманитарный экономический университет, г. Москва</td>
            </tr>
            <tr>
              <td>Аушева Анжела Алихановна, Аушева Мадина Алихановна</td>
              <td><i>«Положение выпускника вуза, в том числе выпускника с инвалидностью, на рынке труда Ставропольского края»</i></td>
              <td>ФГБОУ ВО "Пятигорский государственный университет", г. Пятигорск</td>
            </tr>
          </tbody>
        </table>

        <h3>Номинация «Профессиональный старт-ап молодежи»</h3>
        <table className={tables.root}>
          <thead>
            <tr>
              <th className={styles.authors}>ФИО участника</th>
              <th>Бизнес проект</th>
              <th>Название вуза</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="3" className={styles.place}>1 место</td>
            </tr>
            <tr>
              <td>Меркель Иоганн Сергеевич, Гофман Дмитрий Борисович</td>
              <td><i>«Проект «Прикоснись к истории»»</i></td>
              <td>ФГБОУ ВО «Омский государственный педагогический университет», г. Омск</td>
            </tr>
            <tr>
              <td>Вишняков Борис Юрьевич</td>
              <td><i>«Трудоустройство инвалидов по зрению в спортивно-оздоровительный клуб «Слепые гонки»»</i></td>
              <td>ФГБОУ ВО "Российский государственный университет физической культуры, спорта, молодежи и туризма", г. Москва</td>
            </tr>
            <tr>
              <td colspan="3" className={styles.place}>2 место</td>
            </tr>
            <tr>
              <td>Гроховская Анастасия Ивановна, Рыбина Анастасия Станиславовна</td>
              <td><i>«Клуб «Путь к успеху»»</i></td>
              <td>ФГАОУ ВО «Северо-Кавказский федеральный университет», г. Ставрополь</td>
            </tr>
            <tr>
              <td>Коденко Иван Юрьевич</td>
              <td><i>«Искусство для всех»</i></td>
              <td>Гуманитарно-педагогическая академия (филиал) ФГАОУ ВО "Крымский федеральный университет им. В. И. Вернадского" в г. Ялте, г. Ялта</td>
            </tr>
            <tr>
              <td>Морозова Анастасия Дмитриевна</td>
              <td><i>«Dance is life»</i></td>
              <td>ФГБОУ ВО «Ярославский государственный педагогический университет им. К.Д. Ушинского", г. город Ярославль</td>
            </tr>
            <tr>
              <td colspan="3" className={styles.place}>3 место</td>
            </tr>
            <tr>
              <td>Малюшина Елизавета Игоревна, Сененко Мария Сергеевна, Левшук Дарина Витальевна</td>
              <td><i>«Платформа будущего»</i></td>
              <td>ФГБОУ ВО "Государственный университет управления", г. Москва</td>
            </tr>
            <tr>
              <td>Лаврентьев Добрыня Александрович</td>
              <td><i>«Доступный Крым»</i></td>
              <td>Гуманитарно-педагогическая академия (филиал) ФГАОУ ВО "Крымский федеральный университет им. В. И. Вернадского" в г. Ялте, г. Ялта</td>
            </tr>
          </tbody>
        </table>

        <h3>Номинация «Мое полезное изобретение»</h3>
        <table className={tables.root}>
          <thead>
            <tr>
              <th className={styles.authors}>ФИО участников</th>
              <th>Изобретение</th>
              <th>Название вуза</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="3" className={styles.place}>1 место</td>
            </tr>
            <tr>
              <td>Круглик Андрей Сергеевич</td>
              <td><i>«Программный комплекс "Чат-бот для людей с ограниченными возможностями здоровья на основе технологий искусственного интеллекта с дополнительными возможностями роботизированной автоматизации процессов и low-code подхода к проектированию"»</i></td>
              <td>Уфимский государственный авиационный технический университет, г. Уфа</td>
            </tr>
            <tr>
              <td>Першин Дмитрий Викторович, Соколова Анна Игоревна</td>
              <td><i>«Настольная профориентационная диагностическая игра "Раскрой свою матрицу"» </i></td>
              <td>ФГБОУ ВО "Вятский государственный университет", г. Киров</td>
            </tr>
            <tr>
              <td colspan="3" className={styles.place}>2 место</td>
            </tr>
            <tr>
              <td>Распашнов Артём Алексеевич, Бардынин Александр Владимирович, Антохина Александра Владимировна</td>
              <td><i>«Комплект адаптации стандартной клавиатуры для инвалидов по зрению и электронный тренажер брайлевского шрифта»</i></td>
              <td>ФГБОУ ВО «МГТУ им. Н.Э. Баумана», г. Москва</td>
            </tr>
            <tr>
              <td colspan="3" className={styles.place}>3 место</td>
            </tr>
            <tr>
              <td>Климова Кристина Александровна</td>
              <td><i>«Приложение для смартфона «Логопед будущего»»</i></td>
              <td>ФГБОУ ВО "Вятский государственный университет", г. Киров</td>
            </tr>
            <tr>
              <td>Шаматурин Александр Витальевич</td>
              <td><i>"Десктопное приложение "Голосовой помощник""</i></td>
              <td>ФГБОУ ВО "Хакасский государственный университет им. Н.Ф. Катанова", г. Абакан</td>
            </tr>
          </tbody>
        </table>

        <h3>Номинация «Социальная реклама»</h3>
        <table className={tables.root}>
          <thead>
            <tr>
              <th className={styles.authors}>ФИО участника</th>
              <th>Название проекта</th>
              <th>Название вуза</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="3" className={styles.place}>1 место</td>
            </tr>
            <tr>
              <td>Медчаинова Вероника Хаджи Муратовна</td>
              <td><i>«Жизнь без электричества... Или три истории успеха»</i></td>
              <td>ФГБОУ ВО «Омский государственный педагогический университет», г. Омск</td>
            </tr>
            <tr>
              <td>Пушникова Дарья Александровна, Беляева Дарья Евгеньевна, Силинская Юлия Александровна</td>
              <td><i>«Ценный сотрудник»</i></td>
              <td>ФГБОУ ВО «Череповецкий государственный университет», г. Череповец</td>
            </tr>
            <tr>
              <td>Любченко Виктория Александровна</td>
              <td><i>«Социальный плакат «Глухие: успех, свобода, выбор, жизнь-доступны.ru»</i></td>
              <td>ФГБОУ ВО «Российский государственный педагогический университет им. А. И. Герцена», г.Санкт-Петербург</td>
            </tr>
            <tr>
              <td colspan="3" className={styles.place}>2 место</td>
            </tr>
            <tr>
              <td>Хитрикова Евгения Алексеевна</td>
              <td><i>«Любая работа - для разных людей»</i></td>
              <td>ФГБОУ ВО «Новосибирский государственный технический университет», г. Новосибирск</td>
            </tr>
            <tr>
              <td>Сердюк Анастасия Сергеевна</td>
              <td><i>«Будь собой!»</i></td>
              <td>ФГБОУ ВО «Южно-Уральский государственный гуманитарно-педагогический университет», г. Челябинск</td>
            </tr>
            <tr>
              <td>Рогалевич Анастасия Владимировна</td>
              <td><i>«Люди с неограниченными возможностями!»</i></td>
              <td>Гуманитарно-педагогическая академия им. В.И. Вернадского (филиал) ФГАОУ ВО Крымского федерального университета" в г. Ялта, г. Ялта</td>
            </tr>
            <tr>
              <td>Губина Анастасия Владимировна</td>
              <td><i>«Карьера без границ!»</i></td>
              <td>Дальневосточный институт управления — филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (ДВИУ РАНХиГС)</td>
            </tr>
            <tr>
              <td colspan="3" className={styles.place}>3 место</td>
            </tr>
            <tr>
              <td>Шалагинова Ирина Александровна, Кокорев Алексей Александрович, Упшинская Анастасия Олеговна</td>
              <td><i>«Одна работа - ра в/з ные возможности»</i></td>
              <td>ФГБОУ ВО «Вятский государственный университет», г. Киров</td>
            </tr>
            <tr>
              <td>Манакова Александра Витальевна, Кожевников Данил Сергеевич</td>
              <td><i>«Только в движении - жизнь»</i></td>
              <td>ФГБОУ ВО «Шадринский государственный педагогический университет», г. Шадринск</td>
            </tr>
          </tbody>
        </table>

        {/*  */}

        <p>
          Список победителей Всероссийского сетевого конкурса студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью в 2020 году (публикация официальных результатов на сайте оператора Конкурса - <a href="https://guu.ru/об-университете/доступная-среда-в-гуу/деятельность-румц-2020/конкурс-профессиональное-завтра/результаты-онлайн-этапа/" target="_blank">https://guu.ru/об-университете/доступная-среда-в-гуу/деятельность-румц-2020/конкурс-профессиональное-завтра/результаты-онлайн-этапа/</a>):
        </p>
        <h3>Эссе «Почему я ценный и полезный работник: 10 аргументов»</h3>
        <table className={tables.root}>
          <thead>
            <tr>
              <th className={styles.authors}>Место</th>
              <th>ФИО</th>
              <th>Название вуза</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                1 место
              </td>
              <td>
                <p>Похвалий Дмитрий Сергеевич</p>
              </td>
              <td>
                <p>Первый Санкт-Петербургский государственный медицинский университет им. Академика И.П. Павлова</p>
              </td>
            </tr>
            <tr>
              <td>
                1 место
              </td>
              <td>
                <p>Болотов Егор Юрьевич</p>
              </td>
              <td>
                <p>Кубанский государственный аграрный университет имени И.Т. Трубилина</p>
              </td>
            </tr>
            <tr>
              <td>
                2 место
              </td>
              <td>
                <p>Владимирский Игорь Валерьевич</p>
              </td>
              <td>
                <p>Курский государственный университет</p>
              </td>
            </tr>
            <tr>
              <td>
                2 место
              </td>
              <td>
                <p>Сидоркин Александр Александрович</p>
              </td>
              <td>
                <p>Ленинградский государственный университет имени А.С.Пушкина</p>
              </td>
            </tr>
            <tr>
              <td>
                3 место
              </td>
              <td>
                <p>Канаева Берта Ильинична</p>
              </td>
              <td>
                <p>Пятигорский государственный университет</p>
              </td>
            </tr>
            <tr>
              <td>
                3 место
              </td>
              <td>
                <p>Берулава Елена Тристановна</p>
              </td>
              <td>
                <p>Первый Санкт-Петербургский государственный медицинский университет им. Академика И.П. Павлова</p>
              </td>
            </tr>
            <tr>
              <td rowspan="14">
                <p>Дипломами «За харизму» награждаются:</p>
              </td>
              <td>
                <p>Худяков Алексей Владимирович</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Шадринский государственный педагогический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Голышева Полина Валерьевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Уральский государственный медицинский университет» Министерства здравоохранения Российской Федерации</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Акулова Аида Самедовна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Братский государственный университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Джаббарова Виолетта Рустамовна</p>
              </td>
              <td>
                <p>Государственное бюджетное образовательное учреждение высшего образования «Ставропольский государственный педагогический институт»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Пушкарева Дарья Андреевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Коновалова Лилия Валентиновна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Петрозаводский государственный университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Тимунь Наталья Викторовна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Титова Оксана Алексеевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Маскалюк Влада Алексеевна</p>
              </td>
              <td>
                <p>Гуманитарно-педагогическая академия (филиал) федерального государственного автономного образовательного учреждения высшего образования «Крымский федеральный университет имени В.И. Вернадского» в г. Ялте.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Крылова Мария Дмитриевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Московский педагогический государственный университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Акатова Светлана Дмитриевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Хакасский государственный университет им. Н.Ф. Катанова»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Сумкин Александр Васильевич</p>
              </td>
              <td>
                <p>Федеральное государственное автономное образовательное учреждение высшего образования «Тюменский государственный университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Хандогина Юлия Андреевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Томский государственный педагогический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Филаретова Екатерина Дмитриевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новгородский государственный университет имени Ярослава Мудрого»</p>
              </td>
            </tr>
            <tr>
              <td rowspan="9">
                <p>Дипломами «За творческий подход» награждаются:</p>
              </td>
              <td>
                <p>Чухлиб Анна Юрьевна</p>
              </td>
              <td>
                <p>Федеральное государственное автономное образовательное учреждение высшего образования «Балтийский федеральный университет имени Иммануила Канта»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Челноков Илья Игоревич</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Нижегородский государственный лингвистический университет им. Н.А.Добролюбова»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Мкртычан Азнавур Гаспарович</p>
              </td>
              <td>
                <p>Государственное бюджетное образовательное учреждение высшего образования «Северо-Осетинский государственный педагогический институт»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Чернова Полина Александровна</p>
              </td>
              <td>
                <p>Федеральное государственное автономное образовательное учреждение высшего образования «Национальный исследовательский Томский политехнический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Байдуллаев Темурланбек Абдыкадырович</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Шкатова Виолетта Дмитриевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Третьякова Елизавета Владиславовна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Южно-Уральский государственный гуманитарно-педагогический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Пенькова Екатерина Владимировна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кубанский государственный аграрный университет имени И.Т. Трубилина»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Шайхиева Диляра Рамилевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Поволжская государственная академия физической культуры, спорта и туризма»</p>
              </td>
            </tr>
            <tr>
              <td rowspan="18">
                <p>Дипломами «За креативность» награждаются:</p>
              </td>
              <td>
                <p>Владыкин Дмитрий Владимирович</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Уральский государственный педагогический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Лёшина Дарья Алексеевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Владимирский государственный университет имени Александра Григорьевича и Николая Григорьевича Столетовых»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Ефимова Ольга Станиславовна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Тихоокеанский государственный университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Пятых Валерия Александровна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Южно-Уральский государственный гуманитарно-педагогический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Шевякова Полина Васильевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Плетенкина Алина Юрьевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Шкута Мария Дмитриевна</p>
              </td>
              <td>
                <p>Государственное автономное образовательное учреждение высшего образования Ленинградской области «Ленинградский государственный университет имени А.С.Пушкина»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Фофилова Анастасия Андреевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Бабина Алина Андреевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Штерле Артур Феликсович</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Тищенко Мария Алексеевна</p>
              </td>
              <td>
                <p>Ставропольский филиал федерального государственного бюджетного образовательного учреждения высшего образования «Московский педагогический государственный университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Калинина Екатерина Алексеевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Прахт Андреас Александрович</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кубанский государственный аграрный университет имени И.Т. Трубилина»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Зайцева Евгения Игоревна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Красноярский государственный педагогический университет им. В. П. Aстафьева»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Ушакова Дарья Ильинична</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Московский государственный университет технологий и управления им. К.Г. Разумовского»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Козак Мария Петровна</p>
              </td>
              <td>
                <p>Гуманитарно-педагогическая академия (филиал) федерального государственного автономного образовательного учреждения высшего образования «Крымский федеральный университет имени В.И. Вернадского» в г. Симферополь</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Куделина Александра Владимировна</p>
              </td>
              <td>
                <p>Государственное бюджетное образовательное учреждение высшего образования «Ставропольский государственный педагогический институт»</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Петухова Анастасия Сергеевна</p>
              </td>
              <td>
                <p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
              </td>
            </tr>
            <tr>
              <td rowspan="16">
                <p>Дипломами «За нестандартный подход к раскрытию темы» награждаются:</p>
					</td>
					<td>
						<p>Галустян Алина Юрьевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Первый Санкт-Петербургский государственный медицинский университет им. Академика И.П. Павлова» Министерства здравоохранения России</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Рощин Антон Сергеевич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Курский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Саркисян Михаил Арамович</p>
					</td>
					<td>
						<p>Государственное бюджетное образовательное учреждение высшего образования «Северо-Осетинский государственный университет имени Коста Левановича Хетагурова»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Богданова Дарья Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Первый Санкт-Петербургский государственный медицинский университет им. Академика И.П. Павлова» Министерства здравоохранения России</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Попов Всеволод Сергеевич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Московский государственный психолого-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Розина (Мещерякова) Маргарита Алексеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Первый Санкт-Петербургский государственный медицинский университет им. Академика И.П. Павлова» Министерства здравоохранения России</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Чурагулова Алина Руслановна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Башкирский государственный педагогический университет им. М.Акмуллы»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шеститко Светлана Алексеевна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Северо-Кавказский федеральный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Аджибекиров Марлен Юсуфович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение инклюзивного высшего образования «Московский государственный гуманитарно-экономический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Иванова Лилия Ильнуровна</p>
					</td>
					<td>
						<p>Учреждение высшего образования «Университет управления «ТИСБИ»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Рубцова Любовь Алексеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Уральский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Миронова Анастасия Ивановна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Тихоокеанский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Хафизова Лариса Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Красноярский государственный педагогический университет им. В. П. Aстафьева»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Уварова Анастасия Андреевна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Северо-Кавказский федеральный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Воробьева Ангелина Валерьевна</p>
					</td>
					<td>
						<p>Волгоградский филиал Аккредитованного образовательного частного учреждения высшего образования «Московский финансово-юридический университет МФЮА»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Павлов Илья Михайлович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Московский педагогический государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="17">
						<p>Дипломами «За ораторское мастерство» награждаются:</p>
					</td>
					<td>
						<p>Гудкова Ирина Николаевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Российский государственный социальный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Бессонова Ольга Викторовна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Самарский национальный исследовательский университет имени академика С.П. Королева»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Пушкарева Алена Владимировна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Тюменский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Устелёмов Владислав Анатольевич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Санкт-Петербургский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Малыгина Елена Евгеньевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Башкирский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Лукацкая Оксана Валерьевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кировский государственный медицинский университет» Министерства здравоохранения Российской Федерации</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Гурова Анжелика Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Курский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Карпешова Софья Андреевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Тихоокеанский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Языкова Юлия Алексеевна</p>
					</td>
					<td>
						<p>Государственное бюджетное образовательное учреждение высшего образования «Ставропольский государственный педагогический институт»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шевченко Анастасия Олеговна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Казанцева Екатерина Андреевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Ибрагимова Валерия Валерьевна</p>
					</td>
					<td>
						<p>Государственное бюджетное образовательное учреждение высшего образования «Ставропольский государственный педагогический институт»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Михайлова Елена Юрьевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Красноярский государственный педагогический университет им. В. П. Aстафьева»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Бондаренко Амалия Александровна</p>
					</td>
					<td>
						<p>Государственное бюджетное образовательное учреждение высшего образования «Ставропольский государственный педагогический институт»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шевченко Полина Сергеевна</p>
					</td>
					<td>
						<p>Таганрогский институт имени А.П. Чехова (филиал) федерального государственного бюджетного образовательного учреждения высшего образования «Ростовский государственный экономический университет (РИНХ)»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Микаилова Ровэлла Алекберовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Колесникова Анастасия Дмитриевна</p>
					</td>
					<td>
						<p>Таганрогский институт имени А.П. Чехова (филиал) федерального государственного бюджетного образовательного учреждения высшего образования «Ростовский государственный экономический университет (РИНХ)»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="18">
						<p>Дипломами «За убедительность и аргументированность выступления» награждаются:</p>
					</td>
					<td>
						<p>Толочек Марина Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный аграрный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Цыба Иванна Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Балтийский федеральный университет имени Иммануила Канта»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Семенов Александр Иванович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Южно-Уральский государственный аграрный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Бакина Полина Николаевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Уральский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шинкина Алина Андреевна</p>
					</td>
					<td>
						<p>Государственное бюджетное образовательное учреждение высшего образования «Ставропольский государственный педагогический институт»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шамаева Полина Андреевна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Казанский (Приволжский) федеральный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шульгина Дарья Алексеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Омский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Колотушкина Надежда Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Тихоокеанский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Таумурзаева Фарида Даутовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кабардино-Балкарский государственный аграрный университет имени В.М. Кокова»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Глазков Григорий Анатольевич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кубанский государственный аграрный университет имени И.Т. Трубилина»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шапедько Анжелика Владимировна</p>
					</td>
					<td>
						<p>Государственное образовательное учреждение высшего образования Московской области «Государственный гуманитарно-технологический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Квач Ксения Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Югорский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Петухова Ульяна Алексеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новгородский государственный университет имени Ярослава Мудрого»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Коняев Никита Геннадьевич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Елецкий государственный университет им. И.А. Бунина»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Базин Даниил Евгеньевич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Государственный университет управления»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дидыченко Глеб Сергеевич</p>
					</td>
					<td>
						<p>Федеральное государственное казенное учреждение высшего образования «Нижегородская академия Министерства внутренних дел Российской Федерации»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Меркулов Антон Владимирович</p>
					</td>
					<td>
						<p>Государственное бюджетное образовательное учреждение высшего образования «Ставропольский государственный педагогический институт»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Смирнова Дарья Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Вятский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="16">
						<p>Дипломами «За выразительность» награждаются:</p>
					</td>
					<td>
						<p>Кутепова Любовь Олеговна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Владимирский государственный университет имени Александра Григорьевича и Николая Григорьевича Столетовых»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Скляр Диана Геннадьевна</p>
					</td>
					<td>
						<p>Государственное автономное образовательное учреждение высшего образования «Невинномысский государственный гуманитарно-технический институт»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Пужаева Алина Алексеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Челябинский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Алиев Степан Владимирович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Манакова Александра Витальевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Шадринский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Юксель Мелисса Экремовна</p>
					</td>
					<td>
						<p>Дальневосточный филиал Федерального государственного бюджетного образовательного учреждения высшего образования «Всероссийская академия внешней торговли Министерства экономического развития Российской Федерации»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Кравченко Екатерина Евгеньевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Хакасский государственный университет им. Н.Ф. Катанова»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Петрова Екатерина Андреевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новгородский государственный университет имени Ярослава Мудрого»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Камнева Валерия Денисовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Якимова Мария Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Балтийский федеральный университет имени Иммануила Канта»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Баранова Алла Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Тихоокеанский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Тихомирова Виктория Андреевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кубанский государственный аграрный университет имени И.Т. Трубилина»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шабалина Лилия Юрьевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Гулюмова Софья Робертовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Башкирский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Копецкая Дарья Юрьевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Харике Динара Аманжоловна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="9">
						<p>Дипломами «За художественный вкус» награждаются:</p>
					</td>
					<td>
						<p>Силинская Юлия Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Череповецкий государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Перевалова Мария Дмитриевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Уральский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Зыбинская Регина Руслановна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кубанский государственный аграрный университет имени И.Т. Трубилина»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Мамедова Гюнел Солтан-Махмуд кызы</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Байкальский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Переломова Алина Николаевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Ким Ольга Валерьевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Симурова Мария Дмитриевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Ремпович Валерия Владимировна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Южно-Уральский государственный гуманитарно-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Сабирова Вероника Шамильевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Московский педагогический государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="19">
						<p>Дипломами «За владение профессиональной терминологией» награждаются:</p>
					</td>
					<td>
						<p>Ерижоков Осман Заурович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кабардино-Балкарский государственный университет им. Х.М. Бербекова»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Ибрагимова Сафия Рифкатовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Первый Санкт-Петербургский государственный медицинский университет им. Академика И.П. Павлова» Министерства здравоохранения России</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Лопатина Есения Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Северный (Арктический) федеральный университет имени М.В. Ломоносова»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шутова Анна Владиславовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Мурманский арктический государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Зверькова Владлена Юрьевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Нижегородский государственный лингвистический университет им. Н.А.Добролюбова»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Минасян Размик Минасович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кубанский государственный аграрный университет имени И.Т. Трубилина»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Абдурахманова Диана Абакаровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Тихоокеанский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Гимазетдинова Альбина Рифовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Башкирский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Репина Марина Анатольевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Иванова Евгения Ивановна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Волгоградский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Сафронова Мария Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шелест Екатерина Романовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кубанский государственный медицинский университет» Министерства здравоохранения Российской Федерации</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Чухнина Александра Константиновна</p>
					</td>
					<td>
						<p>Стерлитамакский филиал федерального государственного бюджетного образовательного учреждения высшего образования «Башкирский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Смирнова Елена Петровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Красноярский государственный педагогический университет им. В. П. Aстафьева»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Гладкая Гульжана Кадрбулатовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Прядильникова Инна Юрьевна</p>
					</td>
					<td>
						<p>Институт транспорта, сервиса и туризма Государственного бюджетного образовательного учреждения высшего образования «Нижегородский государственный инженерно-экономический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Макаров Никита Александрович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Национальный исследовательский Мордовский государственный университет имени Н.П. Огарёва»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Соколова Анна Игоревна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Вятский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Минаев Сергей Викторович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
					</td>
				</tr>
			</tbody>
		</table>



		<h3>Профессиональный стартап молодежи</h3>
		<table className={tables.root}>
      <thead>
        <tr>
          <th className={styles.authors}>Место</th>
          <th>ФИО</th>
          <th>Название вуза</th>
        </tr>
      </thead>
			<tbody>
				<tr>
					<td>
						1 место
					</td>
					<td>
						<p>Сметанникова Анастасия Валерьевна     Буланенко Анастасия Романовна     Полякова Елена Эдуардовна</p>
					</td>
					<td>
						<p>Тихоокеанский государственный университет</p>
					</td>
				</tr>
				<tr>
					<td>
						1 место
					</td>
					<td>
						<p>Шкрабак Александр Владимирович     Щербаков Данил Сергеевич     Жильченко Ксения Викторовна</p>
					</td>
					<td>
						<p>Институт сферы обслуживания и предпринимательства (филиал) Донского государственного технического университета в г. Шахты Ростовской области</p>
					</td>
				</tr>
				<tr>
					<td>
				2 место
			</td>
					<td>
						<p>Волкова Ольга Сергеевна     Скобкарев Сергей Владимирович     Ардашева Наталья Вячеславовна</p>
					</td>
					<td>
						<p>Глазовский государственный педагогический институт имени В.Г. Короленко</p>
					</td>
				</tr>
				<tr>
					<td>
				2 место
			</td>
					<td>
						<p>Челноков Илья Игоревич     Зверькова Владлена Юрьевна</p>
					</td>
					<td>
						<p>Нижегородский государственный лингвистический университет им. Н.А.Добролюбова</p>
					</td>
				</tr>
				<tr>
					<td>
				3 место
			</td>
					<td>
						<p>Воробьева Полина Вадимовна     Усикова Анастасия Артемовна     Требесова Каролина Дмитриевна</p>
					</td>
					<td>
						<p>Курский государственный университет</p>
					</td>
				</tr>
				<tr>
					<td>
				3 место
			</td>
					<td>
						<p>Аверкович Арина Алексеевна     Заурова Гульзар Джамалдиновна</p>
					</td>
					<td>
						<p>Сургутский государственный педагогический университет</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>3 место</p>
					</td>
					<td>
						<p>Гроховская Анастасия Ивановна     Рыбина Анастасия Станиславовна</p>
					</td>
					<td>
						<p>Северо-Кавказский федеральный университет</p>
					</td>
				</tr>
				<tr>
					<td rowspan="3">
						<p>Дипломами «За харизму» награждаются:</p>
					</td>
					<td>
						<p>Боровиков Алексей Петрович</p>
					</td>
					<td>
						<p>Челябинский филиал Федерального государственного бюджетного образовательного учреждения высшего образования «Российская академия народного хозяйства и государственной службы при Президенте Российской Федерации»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Зубанова Анастасия Евгеньевна     Захарова Анастасия Геннадьевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Орловский государственный университет имени И.С. Тургенева»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Ефремова Ангелина Олеговна     Деминова Ирина Александровна     Фролова Варвара Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Орловский государственный университет имени И.С. Тургенева»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="2">
						<p>Дипломами «За творческий подход» награждаются:</p>
					</td>
					<td>
						<p>Терехина Полина Владимировна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Южный федеральный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Конорева Екатерина Сергеевна     Лукьянчикова Злата Сергеевна     Пожидаева Анастасия Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Курский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="2">
						<p>Дипломами «За креативность» награждаются:</p>
					</td>
					<td>
						<p>Ковальчук Вера Александровна     Хмелёв Денис Андреевич     Ясько Анна Владимировна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Челябинский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Потапенко Варвара Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Курский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="2">
						<p>Дипломами «За актуальность идеи» награждаются:</p>
					</td>
					<td>
						<p>Морозова Татьяна Геннадьевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Тульский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Михальский Артем Викторович     Гущина Анна Александровна     Зигангирова Маргарита Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Курский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="2">
						<p>Дипломами «За значимость проекта» награждаются:</p>
					</td>
					<td>
						<p>Процко Екатерина Григорьевна     Махмудова Алина Айдыновна     Мурыгина Анастасия Владимировна</p>
					</td>
					<td>
						<p>Бюджетное учреждение высшего образования Ханты-мансийского автономного округа – Югры «Сургутский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Новикова Светлана Сергеевна     Королёв Даниил Олегович     Митягина Анастасия Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Нижегородский государственный педагогический университет имени Козьмы Минина»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="2">
						<p>Дипломами «За новизну идеи» награждаются:</p>
					</td>
					<td>
						<p>Овчинникова Надежда Владимировна     Лобачева Валентина Николаевна</p>
					</td>
					<td>
						<p>Институт транспорта, сервиса и туризма Государственного бюджетного образовательного учреждения высшего образования «Нижегородский государственный инженерно-экономический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Петроченко Валентина Анатольевна     Штатных Елена Дмитриевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="10">
						<p>Дипломами «За социальную         направленность проекта» награждаются:</p>
					</td>
					<td>
						<p>Удалова Ольга Владимировна     Федорова Мария Андреевна     Розанова Полина Алексеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Нижегородский государственный лингвистический университет им. Н.А.Добролюбова»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Еременко Елизавета Сергеевна     Дивинская Ксения Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Мурманский арктический государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Калугин Никита Андреевич     Петришко София Юрьевна     Олейникова Алина Станиславовна</p>
					</td>
					<td>
						<p>Таганрогский институт имени А.П. Чехова (филиал) федерального государственного бюджетного образовательного учреждения высшего образования «Ростовский государственный экономический университет (РИНХ)»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Тихонов Ростислав Русланович     Потапенко Варвара Сергеевна     Федорова Елена Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Курский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Якимова Анна Леонидовна     Иванов Иван Сергеевич     Шалагинова Ирина Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Вятский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Макеева Таисия Ильясовна     Вагина Ксения Игоревна     Чудова София Игоревна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Уральский государственный экономический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Хоменюк Дарья Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Байкальский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дубенчук Никита Сергеевич     Софронова Екатерина Андреевна     Кандалова Карина Юрьевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Нижневартовский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Никанов Константин Константинович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кубанский государственный технологический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Богданов Евгений Юрьевич</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Южный федеральный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="2">
						<p>Дипломами «За качество         оформления презентации»        награждаются:</p>
					</td>
					<td>
						<p>Локтева Анна Сергеевна     Фам Ву Бао Нгок     Юдина Софья Романовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Российский государственный социальный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Фоменко Полина Алексеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Тихоокеанский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дипломами «За убедительность         и аргументированность выступления»        награждаются:</p>
					</td>
					<td>
						<p>Малинина Анастасия Алексеевна</p>
					</td>
					<td>
						<p>Филиал государственного бюджетного образовательного учреждения высшего образования «Ставропольский государственный педагогический институт» в г. Ессентуки</p>
					</td>
				</tr>
			</tbody>
		</table>



		<h3>Исследовательская работа</h3>
		<table className={tables.root}>
      <thead>
        <tr>
          <th className={styles.authors}>Место</th>
          <th>ФИО</th>
          <th>Название вуза</th>
        </tr>
      </thead>
			<tbody>
				<tr>
					<td>
            1 место
          </td>
					<td>
						<p>Монгуш Кузел Орлановна</p>
					</td>
					<td>
						<p></p>
						<p>Тувинский государственный университет</p>
					</td>
				</tr>
				<tr>
					<td>
            1 место
          </td>
					<td>
						<p>Байгалина Сулпан Талгатовна</p>
					</td>
					<td>
						<p>Сибайский институт (филиал) Башкирского государственного университета</p>
					</td>
				</tr>
				<tr>
					<td>
            2 место
          </td>
					<td>
						<p>Инюшин Евгений Анатольевич</p>
					</td>
					<td>
						<p>Рубцовский институт (филиал) Алтайского государственного университета</p>
					</td>
				</tr>
				<tr>
					<td>
            2 место
          </td>
					<td>
						<p>Архипова Алина Александровна     Голованова Анна Владимировна     Пустовалова Юлия Александровна</p>
					</td>
					<td>
						<p>Воронежский государственный педагогический университет</p>
					</td>
				</tr>
				<tr>
					<td>
            3 место
          </td>
					<td>
						<p>Королева Ирина Викторовна</p>
					</td>
					<td>
						<p>Алтайский государственный университет</p>
					</td>
				</tr>
				<tr>
					<td>
            3 место
          </td>
					<td>
						<p>Хижняк Артем Николаевич</p>
					</td>
					<td>
						<p>Ульяновский институт гражданской авиации имени Главного маршала авиации Б.П. Бугаева</p>
					</td>
				</tr>
				<tr>
					<td rowspan="5">
						<p>Дипломами «За актуальность темы» награждаются:</p>
					</td>
					<td>
						<p>Никитина Елизавета Константиновна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Южно-Уральский государственный гуманитарно-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Аушева Анжела Алихановна     Аушева Мадина Алихановна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Пятигорский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Поверинова Алина Игоревна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение инклюзивного высшего образования «Московский государственный гуманитарно-экономический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Чуркин Глеб Вячеславович</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Южный федеральный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Поленова Ольга Анатольевна     Кожин Геннадий Игоревич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Тульский государственный педагогический университет им. Л.Н. Толстого»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="2">
						<p>Дипломами «За оригинальность проекта» награждаются:</p>
					</td>
					<td>
						<p>Фартукова Ульяна Владимировна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Южный федеральный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шабалина Анастасия Алексеевна     Гудовская Анастасия Игоревна     Алимкина Дарья Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Сибирский государственный университет науки и технологий имени академика М.Ф. Решетнева»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="2">
						<p>Дипломами «За научность проекта» награждаются:</p>
					</td>
					<td>
						<p>Маслова Анастасия Георгиевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Нижегородский государственный архитектурно-строительный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Злоказова Владилена Владимировна     Кямяря Елизавета Ивановна</p>
					</td>
					<td>
						<p>Карельский филиал федерального государственного бюджетного образовательного учреждения высшего образования «Российская академия народного хозяйства и государственной службы при Президенте Российской Федерации»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дипломами «За качество оформления презентации» награждаются:</p>
					</td>
					<td>
						<p>Алексеева Дарья Сергеевна</p>
					</td>
					<td>
						<p>Южно-Российский институт управления — филиал Федерального государственного автономного образовательного учреждения высшего образования «РАНХиГС при Президенте Российской Федерации»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дипломами «За убедительность         и аргументированность выступления»        награждаются:</p>
					</td>
					<td>
						<p>Силинская Юлия Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Череповецкий государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="9">
						<p>Дипломами «За социальную         направленность проекта» награждаются:</p>
					</td>
					<td>
						<p>Автухова Маргарита Дмитриевна</p>
					</td>
					<td>
						<p>Академия архитектуры и искусств Федерального государственного автономного образовательного учреждения высшего образования «Южный федеральный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Алексеева Анастасия Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Оренбургский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Липин Андрей Владимирович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Казанский национальный исследовательский технический университет им. А.Н.Туполева-КАИ»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Бурцева Тамара Викторовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Тихоокеанский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дементьева Анна Сергеевна     Мухамедов Мурад Батырович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Российский государственный университет туризма и сервиса»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Геворкян Самсон Лаврентьевич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кубанский государственный аграрный университет имени И.Т. Трубилина»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Махнорылова Полина Сергеевна     Ульяненко Мария Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Южный федеральный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Базарова Дженнет Мурадовна     Шелест Мария Ярославовна</p>
					</td>
					<td>
						<p>Московский городской университет управления Правительства Москвы имени Ю. М. Лужкова</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Боровиков Алексей Петрович</p>
					</td>
					<td>
						<p>Челябинский филиал Федерального государственного бюджетного образовательного учреждения высшего образования «Российская академия народного хозяйства и государственной службы при Президенте Российской Федерации»</p>
					</td>
				</tr>
			</tbody>
		</table>



		<h3>Моё полезное изобретение</h3>
		<table className={tables.root}>
      <thead>
        <tr>
          <th className={styles.authors}>Место</th>
          <th>ФИО</th>
          <th>Название вуза</th>
        </tr>
      </thead>
			<tbody>
				<tr>
					<td>
            1 место
          </td>
					<td>
						<p>Круглик Андрей Сергеевич</p>
					</td>
					<td>
						<p>Уфимский государственный авиационный технический университет</p>
					</td>
				</tr>
				<tr>
					<td>
            1 место
          </td>
					<td>
						<p>Кузнецов Арсений Дмитриевич</p>
					</td>
					<td>
						<p>Северо-Западный институт управления — филиал РАНХиГС при Президенте Российской Федерации</p>
					</td>
				</tr>
				<tr>
					<td>
            2 место
          </td>
					<td>
						<p>Блинда Марина Игоревна     Ефременкова Валерия Валерьевна     Захарова Дарья Викторовна</p>
					</td>
					<td>
						<p>Национальный государственный Университет физической культуры, спорта и здоровья имени П.Ф.Лесгафта</p>
					</td>
				</tr>
				<tr>
					<td>
            2 место
          </td>
					<td>
						<p>Гейтман Даниил Кириллович</p>
					</td>
					<td>
						<p>Рязанский государственный агротехнологический университет имени П.А.Костычева</p>
					</td>
				</tr>
				<tr>
					<td>
            3 место
          </td>
					<td>
						<p>Кабанин Александр Владимирович</p>
					</td>
					<td>
						<p>Пензенский государственный технологический университет</p>
					</td>
				</tr>
				<tr>
					<td>
            3 место
          </td>
					<td>
						<p>Мисюль Василий Сергеевич</p>
					</td>
					<td>
						<p>Тарский филиал Омского государственного аграрного университета имени П.А. Столыпина</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дипломом «За харизму» награждаются:</p>
					</td>
					<td>
						<p>Калатозишвили Ульяна Олеговна     Коленик Татьяна Игоревна     Скабицкая Екатерина Александровна</p>
					</td>
					<td>
						<p>Таганрогский институт имени А.П. Чехова (филиал) федерального государственного бюджетного образовательного учреждения высшего образования «Ростовский государственный экономический университет (РИНХ)»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дипломом «За социальную направленность проекта» награждаются:</p>
					</td>
					<td>
						<p>Пасичник Александр Андреевич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Сибирский государственный медицинский университет» Министерства здравоохранения Российской Федерации</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дипломом «За оригинальность проекта» награждаются:</p>
					</td>
					<td>
						<p>Нефедов Станислав Александрович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Ивановский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дипломом «За качество         оформления презентации» награждаются</p>
					</td>
					<td>
						<p>Седунова Елизавета Андреевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Иркутский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дипломом «За полезность изобретения» награждаются:</p>
					</td>
					<td>
						<p>Филиппова Кристина Тофиковна     Розинкевич Валентина Сергеевна     Мачагина Алина Олеговна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Красноярский государственный педагогический университет им. В. П. Aстафьева»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дипломом «За практическую значимость изобретения» награждаются:</p>
					</td>
					<td>
						<p>Иванов Максим Степанович     Маряшина Дарья Николаевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Казанский национальный исследовательский технический университет им. А.Н.Туполева-КАИ»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дипломом «За реалистичность         внедрения изобретения» награждаются:</p>
					</td>
					<td>
						<p>Худяков Сергей Игоревич     Билашева Полина Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Балтийский федеральный университет имени Иммануила Канта»</p>
					</td>
				</tr>
			</tbody>
		</table>



		<h3>Социальная реклама</h3>
		<table className={tables.root}>
      <thead>
        <tr>
          <th className={styles.authors}>Место</th>
          <th>ФИО</th>
          <th>Название вуза</th>
        </tr>
      </thead>
			<tbody>
				<tr>
					<td>
            1 место
          </td>
					<td>
						<p>Забирова Лилия Рудиковна     Богданова Анастасия Сергеевна     Потапова Анна Владимировна</p>
					</td>
					<td>
						<p>Тюменский государственный университет</p>
					</td>
				</tr>
				<tr>
					<td>
            1 место
          </td>
					<td>
						<p>Никурадзе Ольга Ивановна     Проткина Анастасия Михайловна</p>
					</td>
					<td>
						<p>Государственный университет управления</p>
					</td>
				</tr>
				<tr>
					<td>
            2 место
          </td>
					<td>
						<p>Поспелова Юлия Александровна     Климентьева Елена Александровна     Соколова Анна Игоревна</p>
					</td>
					<td>
						<p>Вятский государственный университет</p>
					</td>
				</tr>
				<tr>
					<td>
            2 место
          </td>
					<td>
						<p>Суворова Ксения Леонидовна     Кувалдина Ольга Олеговна     Шалагинова Ирина Александровна</p>
					</td>
					<td>
						<p>Вятский государственный университет</p>
					</td>
				</tr>
				<tr>
					<td>
            3 место
          </td>
					<td>
						<p>Бекчиндаева Анжелина Диганшевна     Фардеев Эдуард Рустемович     Королева Анастасия Владимировна</p>
					</td>
					<td>
						<p>Поволжская государственная академия физической культуры, спорта и туризма</p>
					</td>
				</tr>
				<tr>
					<td>
            3 место
          </td>
					<td>
						<p>Малышева Юлиана Васильевна</p>
					</td>
					<td>
						<p>Институт сферы обслуживания и предпринимательства (филиал) Донского государственного технического университета в г. Шахты Ростовской области</p>
					</td>
				</tr>
				<tr>
					<td rowspan="3">
						<p>Дипломами «За харизму» награждаются:</p>
					</td>
					<td>
						<p>Зайцева Анастасия Вячеславовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Поволжская государственная академия физической культуры, спорта и туризма»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Копецкая Дарья Юрьевна     Пушкарёва Дарья Андреевна     Шец Александр Александрович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Пчельников Иван Андреевич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Пензенский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="9">
						<p>Дипломами «За творческий подход» награждаются:</p>
					</td>
					<td>
						<p>Стрельцова Екатерина Александровна     Шалагинова Ирина Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Вятский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Сорокина Мария Вадимовна</p>
					</td>
					<td>
						<p>Институт пищевых технологий и дизайна – филиал государственного бюджетного образовательного учреждения высшего образования «Нижегородский государственный инженерно-экономический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Мартынова Наталья Алексеевна     Пятых Валерия Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Южно-Уральский государственный гуманитарно-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Помазкина Татьяна Владимировна     Ковальчук Вадим Александрович     Алимкина Дарья Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Сибирский государственный университет науки и технологий имени академика М.Ф. Решетнева»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Войтюкова Екатерина Владимировна     Помазкина Татьяна Владимировна     Алимкина Дарья Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Сибирский государственный университет науки и технологий имени академика М.Ф. Решетнева»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Жегалина Мария Александровна</p>
					</td>
					<td>
						<p>Институт транспорта, сервиса и туризма Государственного бюджетного образовательного учреждения высшего образования «Нижегородский государственный инженерно-экономический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Ткаченко Максим Андреевич     Гринева Нинель Николаевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кубанский государственный аграрный университет имени И.Т. Трубилина»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Самко Валерия Анатольевна</p>
					</td>
					<td>
						<p>Государственное бюджетное образовательное учреждение высшего образования «Северо-Осетинский государственный педагогический институт»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Синицына Дарья Олеговна</p>
					</td>
					<td>
						<p>Государственное бюджетное образовательное учреждение высшего образования Челябинской области «Магнитогорская государственная консерватория (академия) имени М. И. Глинки»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="2">
						<p>Дипломами «За креативность» награждаются:</p>
					</td>
					<td>
						<p>Давтян Мариам Вазгеновна     Беляева Дарья Евгеньевна     Силинская Юлия Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Череповецкий государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Богданова Татьяна Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Красноярский государственный педагогический университет им. В. П. Aстафьева»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дипломами «За новизну идеи» награждаются:</p>
					</td>
					<td>
						<p>Кубай Мария Олеговна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Российский государственный социальный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="2">
						<p>Дипломами «За оригинальность проекта» награждаются:</p>
					</td>
					<td>
						<p>Фадеев Илья Владимирович     Гайнуллин Раиль Ринатович     Садовников Александр Валерьевич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Казанский национальный исследовательский технический университет им. А.Н.Туполева-КАИ»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Садовская Татьяна Игоревна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Тихоокеанский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="6">
						<p>Дипломами «За нестандартный подход» награждаются:</p>
					</td>
					<td>
						<p>Каберти Марго Мурмановна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Тюменский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Францев Иван Иванович</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Лазарева Дарья Андреевна</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Северо-Кавказский федеральный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Сердюк Анастасия Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Южно-Уральский государственный гуманитарно-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Аранчын Диана Эдер-ооловна     Титаренко София Сергеевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Байкальский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Тутов Артур Аркадьевич     Дадова Ларина Анзоровна     Гуппоева Джамиля Салыховна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Кабардино-Балкарский государственный аграрный университет имени В.М. Кокова»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="3">
						<p>Дипломами «За ораторское мастерство» награждаются:</p>
					</td>
					<td>
						<p>Огородникова Анастасия Денисовна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Пермский государственный гуманитарно-педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Смелков Фёдор Леонидович     Матвеева Анастасия Дмитриевна     Меркулова Юлия Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Нижегородский государственный педагогический университет имени Козьмы Минина»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шашкова Дарья Юрьевна     Лобова Анна Александровна     Филатова Регина Геннадьевна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Курский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="2">
						<p>Дипломами «За качество         оформления презентации» награждаются:</p>
					</td>
					<td>
						<p>Сулейманова Юлия Фатиховна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Уральский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Щетинина Анна Павловна     Аленина Алина Владимировна     Ярыгин Илья Николаевич</p>
					</td>
					<td>
						<p>Федеральное государственное автономное образовательное учреждение высшего образования «Северный (Арктический) федеральный университет имени М.В. Ломоносова»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="6">
						<p>Дипломами «За убедительность         и аргументированность выступления»        награждаются:</p>
					</td>
					<td>
						<p>Машкова Марина Игоревна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Воронежский государственный университет инженерных технологий»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Шулятьева Яна Олеговна     Дзябко Мария Петровна     Соколова Анна Игоревна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Вятский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Кафорина Анастасия Андреевна</p>
					</td>
					<td>
						<p>Автономная некоммерческая организация высшего образования «Московский государственный гуманитарно-экономический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Черепанова Елизавета Валерьевна     Комарова Ксения Дмитриевна     Соколова Анна Игоревна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Вятский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Заварницына Екатерина Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Шадринский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Климова Ксения Владимировна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Оренбургский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td rowspan="7">
						<p>Дипломами «За социальную направленность проекта» награждаются:</p>
					</td>
					<td>
						<p>Мозговая Кристина Николаевна     Полеха Владислав Владимирович     Колесникова Анастасия Дмитриевна</p>
					</td>
					<td>
						<p>Таганрогский институт имени А.П. Чехова (филиал) федерального государственного бюджетного образовательного учреждения высшего образования «Ростовский государственный экономический университет (РИНХ)»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Першин Дмитрий Викторович     Тунёва Полина Владимировна     Соколова Анна Игоревна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Вятский государственный университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Павленко Павел Андреевич</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Аксенова Екатерина Олеговна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Воронежский государственный педагогический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Сафронова Мария Александровна</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Новосибирский государственный технический университет»</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Дедюра Алина Сергеевна</p>
					</td>
					<td>
						<p>Гуманитарно-педагогическая академия (филиал) федерального государственного автономного образовательного учреждения высшего образования «Крымский федеральный университет имени В.И. Вернадского» в г. Ялте.</p>
					</td>
				</tr>
				<tr>
					<td>
						<p>Урываева Наталья Александровна     Тарутанова Надежда</p>
					</td>
					<td>
						<p>Федеральное государственное бюджетное образовательное учреждение высшего образования «Волгоградский государственный социально-педагогический университет»</p>
					</td>
				</tr>
			</tbody>
		</table>








        {/*  */}

        <br />
        <br />
        <p>
          Список победителей Всероссийского сетевого конкурса студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью в 2019 году:
        </p>


        <h3>Номинация «Эссе «Почему я ценный и полезный работник: 10 аргументов»»</h3>
        <table className={tables.root}>
          <thead>
            <tr>
              <th className={styles.authors}>ФИО участника</th>
              <th>Название вуза</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2" className={styles.place}>1 место</td>
            </tr>
            <tr>
              <td>Медчаинова Вероника Хаджи Муратовна</td>
              <td>Омский государственный педагогический университет</td>
            </tr>
            <tr>
              <td>Байгалина Сулпан Талгатовна</td>
              <td>Башкирский государственный университет - Сибайский институт (филиал)</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>2 место</td>
            </tr>
            <tr>
              <td>Пинчук Екатерина Дмитриевна</td>
              <td>Новосибирский государственный технический университет</td>
            </tr>
            <tr>
              <td>Забирова Лилия Рудиковна</td>
              <td>Тюменский государственный университет</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>3 место</td>
            </tr>
            <tr>
              <td>Лопоухова Екатерина Витальевна</td>
              <td>Южно-Уральский государственный гуманитарно-педагогический университет</td>
            </tr>
            <tr>
              <td>Князева Марина Юрьевна</td>
              <td>Волгоградский государственный социально-педагогический университет</td>
            </tr>
          </tbody>
        </table>

        <h3>Номинация «Профессиональный старт-ап молодежи»</h3>
        <h4>Бизнес проект</h4>
        <table className={tables.root}>
          <thead>
            <tr>
              <th className={styles.authors}>ФИО участника</th>
              <th>Название вуза</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2" className={styles.place}>1 место</td>
            </tr>
            <tr>
              <td>Окшин Тихон Николаевич</td>
              <td>Новосибирский государственный технический университет</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>2 место</td>
            </tr>
            <tr>
              <td>Моргач Александра Денисовна</td>
              <td>Южно-Уральский государственный гуманитарно-педагогический университет</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>3 место</td>
            </tr>
            <tr>
              <td>Ложкина Александра Ива-новна</td>
              <td>Глазовский государственный педаго-гический институт имени В.Г. Короленко</td>
            </tr>
            <tr>
              <td>Пушина Людмила Николаевна</td>
              <td>Глазовский государственный педаго-гический институт имени В.Г. Короленко</td>
            </tr>
            <tr>
              <td>Скобкарев Сергей Владимирович</td>
              <td>Глазовский государственный педаго-гический институт имени В.Г. Короленко</td>
            </tr>
          </tbody>
        </table>

        <h4>Социальные проекты</h4>
        <table className={tables.root}>
          <thead>
            <tr>
              <th className={styles.authors}>ФИО участников</th>
              <th>Название вуза</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2" className={styles.place}>1 место</td>
            </tr>
            <tr>
              <td>Силинская Юлия Александровна</td>
              <td>Череповецкий государственный университет</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>2 место</td>
            </tr>
            <tr>
              <td>Марсавина Анастасия Владимировна</td>
              <td>Новосибирский государственный технический университет</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>3 место</td>
            </tr>
            <tr>
              <td>Павлов Даниил Русланович</td>
              <td>Российский государственный социальный университет</td>
            </tr>
            <tr>
              <td>Митряхин Дмитрий Валерьевич</td>
              <td>Российский государственный социальный университет</td>
            </tr>
          </tbody>
        </table>

        <h3>Номинация «Исследовательская работа»</h3>
        <table className={tables.root}>
          <thead>
            <tr>
              <th className={styles.authors}>ФИО участника</th>
              <th>Название вуза</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2" className={styles.place}>1 место</td>
            </tr>
            <tr>
              <td>Михайлова Елена Юрьевна</td>
              <td>Красноярский государственный педагогический университет им. В.П. Астафьева</td>
            </tr>
            <tr>
              <td>Привалихина Анна Андреевна</td>
              <td>Красноярский государственный педагогический университет им. В.П. Астафьева</td>
            </tr>
            <tr>
              <td>Катренко Алексей Александрович</td>
              <td>Красноярский государственный педагогический университет им. В.П. Астафьева</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>2 место</td>
            </tr>
            <tr>
              <td>Поленова Ольга Анатольевна</td>
              <td>Тульский государственный педагогический университет им. Л.Н. Толстого</td>
            </tr>
            <tr>
              <td>Радин Андрей Алексеевич</td>
              <td>Тульский государственный педагогический университет им. Л.Н. Толстого</td>
            </tr>
            <tr>
              <td>Клочек Мария Олеговна</td>
              <td>Тульский государственный педагогический университет им. Л.Н. Толстого</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>3 место</td>
            </tr>
            <tr>
              <td>Житникова Вера Алексеевна</td>
              <td>Санкт-Петербургский государственный архитектурно-строительный университет</td>
            </tr>
            <tr>
              <td>Ломакина Дарья Павловна</td>
              <td>Санкт-Петербургский государственный архитектурно-строительный университет</td>
            </tr>
            <tr>
              <td>Погребная Оксана Алексеевна</td>
              <td>Санкт-Петербургский государственный архитектурно-строительный университет</td>
            </tr>
            <tr>
              <td>Чайкова Елизавета Максимовна</td>
              <td>Южно-Уральский государственный гуманитарно-педагогический университет</td>
            </tr>
          </tbody>
        </table>

        <h3>Номинация «Мое полезное изобретение»</h3>
        <table className={tables.root}>
          <thead>
            <tr>
              <th className={styles.authors}>ФИО участника</th>
              <th>Название вуза</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2" className={styles.place}>1 место</td>
            </tr>
            <tr>
              <td>Градинарова Анастасия Евгеньевна</td>
              <td>Ростовский государственный экономический университет (РИНХ) - Таганрогский институт имени А.П. Чехова (филиал)</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>2 место</td>
            </tr>
            <tr>
              <td>Левонович Никита Ильич</td>
              <td>Московский государственный психолого-педагогический университет</td>
            </tr>
            <tr>
              <td>Пышко Евгений Юрьевич</td>
              <td>Мурманский государственный технический университет</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>3 место</td>
            </tr>
            <tr>
              <td>Медведев Анатолий Андреевич</td>
              <td>Череповецкий государственный университет</td>
            </tr>
            <tr>
              <td>Марченко Антон Васильевич</td>
              <td>Московский государственный технический университет имени Н.Э. Баумана (национальный исследовательский университет)</td>
            </tr>
          </tbody>
        </table>

        <h3>Номинация «Социальная реклама»</h3>
        <table className={tables.root}>
          <thead>
            <tr>
              <th className={styles.authors}>ФИО участника</th>
              <th>Название вуза</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2" className={styles.place}>1 место</td>
            </tr>
            <tr>
              <td>Кислый Никита Сергеевич</td>
              <td>Уральский федеральный университет имени первого Президента России Б.Н. Ельцина</td>
            </tr>
            <tr>
              <td>Булгакова Дана Алексеевна</td>
              <td>Омский государственный педагогический университет</td>
            </tr>
            <tr>
              <td>Иванина Юлия  Алекандровна</td>
              <td>Омский государственный педагогический университет</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>2 место</td>
            </tr>
            <tr>
              <td>Денисенков Даниил Дмитриевич</td>
              <td>Челябинский государственный университет</td>
            </tr>
            <tr>
              <td>Пуганова Дарья</td>
              <td>Московский государственный психолого-педагогический университет</td>
            </tr>
            <tr>
              <td>Сергей Белоусов</td>
              <td>Московский государственный психолого-педагогический университет</td>
            </tr>
            <tr>
              <td colspan="2" className={styles.place}>3 место</td>
            </tr>
            <tr>
              <td>Раджабов Тимур Аспенди оглы</td>
              <td>Ленинградский государственный университет имени А.С. Пушкина</td>
            </tr>
            <tr>
              <td>Таску Денис Андреевич</td>
              <td>Ленинградский государственный университет имени А.С. Пушкина</td>
            </tr>
            <tr>
              <td>Игнатов Игорь Олегович</td>
              <td>Ленинградский государственный университет имени А.С. Пушкина</td>
            </tr>
          </tbody>
        </table>




      







        

        <p>
          Список участников Конкурса, прошедших в очный этап Конкурса
        </p>

        {results &&
          Object.keys(results).map(key =>
            <React.Fragment key={key}>
              <h3>Номинация «{nominations[key]}»</h3>
              <table className={tables.root}>
                <thead>
                  <tr>
                    <th></th>
                    <th className={styles.authors}>ФИО участника</th>
                    <th>Название вуза</th>
                  </tr>
                </thead>
                <tbody>
                  { results[key].map((value, _) =>
                    <tr key={_}>
                      <td>{_ + 1}</td>
                      <td className={styles.authors}>{value.authors}</td>
                      <td>{value.school}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </React.Fragment>
          )
        }
      </div>
    );
  }
}

export default Results
