import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import qs from 'query-string';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import ReactMarkdown from 'react-markdown';
import update from 'immutability-helper';

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import Datec from '../../Datec';
import { path } from '../../Routes';
import AttachmentsList from '../../Attachments/AttachmentsList';

import Comments from '../../Comments/Comments';
import StagesList from '../../Stages/StagesList';

import page from '../../Page.module.css';
import buttons from '../../Buttons.module.css';
import styles from './Show.module.css';
import stylesStates from '../States.module.css';

class Show extends Component {
  state = {
    course: null,
    stages: null,
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('account_course_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { course, stages } = res.data;

        this.setState({ course, stages });
        this._asyncRequest = null;
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.course === null) this._loadAsyncData(this.props.id);
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleShow = (state) => {
    if (this.state.course.state.states[state].passed) {
      const course = update(this.state.course, {
        state: {
          states: {
            [state]: {
              show: {
                $set: !this.state.course.state.states[state].show
              }
            }
          }
        }
      });

      this.setState(prevState => ({
        course: course
      }));
    }
  }

  handleStateChange = (event) => {
    this._asyncRequest = axios.CancelToken.source();
    axios({
      method: 'post',
      cancelToken: this._asyncRequest.token,
      url: path(`${event}_account_course_path`, { id: this.props.id }, true),
    }).then(
      res => {
        this.setState({
          course: null,
        });
      }
    );
  }

  render() {
    const { course, stages } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={`${path('account_courses_path')}?${qs.stringify(cookies.get('filters', { path: path('account_stories_path') }))}`}>{I18n.t('courses.title')}</Link>
          </Loaf>

          {course &&
            <>
              <div className={page.title}>
                <h1>
                  {course.title}
                </h1>

                <Helmet>
                  <title>{course.title}</title>
                </Helmet>
              </div>

              <div className={stylesStates.root}>
                <div className={stylesStates.list}>
                  <div className={classNames(stylesStates.item, stylesStates.item_link)} key={99}>
                    {`Ссылка на страницу: https://инклюзивноеобразование.рф${decodeURIComponent(path('account_course_path', { id: this.props.id }))}`}
                  </div>
                  {course.state.states.map((state, i) =>
                    <div className={classNames(stylesStates.item, { [stylesStates.show]: state.show })} key={state.id}>
                      <div className={classNames(stylesStates.current, {[stylesStates.passed]: state.passed}, { [stylesStates.active]: state.active }, { [stylesStates.published]: state.id == 'published' && state.active})}>
                        {i + 1}
                      </div>

                      <div className={stylesStates.rest}>
                        <div className={stylesStates.title} onClick={() => this.handleShow(i)}>
                          {I18n.t(`state.${state.id}`)}
                        </div>

                        {state.show &&
                          <>
                            {state.id == 'inactive' &&
                              <div className={styles.inactive}>
                                <p>
                                  <strong>
                                    {course.rumc.title}
                                  </strong>
                                  <br/>
                                  Курс был создан <Datec time={course.created_at} />.
                                  {course.can_edit &&
                                    <>
                                      <br/>
                                      <Link to={path('edit_account_course_path', {id: course.id })}>Редактировать</Link>
                                      {/* <br/>
                                      <Link to={path('stages_account_course_path', {id: course.id })}>Редактировать разделы</Link> */}
                                    </>
                                  }
                                </p>

                              </div>
                            }

                            {state.id == 'done' &&
                              <div className={styles.done}>
                                <p>
                                  По мнению авторов этот курс готов к публикации, поэтому перешел на проверку экспертам. После согласования он будет оценен и опубликован в случае соответствия требованиям.
                                </p>
                              </div>
                            }

                            {state.id == 'agreed' &&
                              <div className={styles.review}>
                                <p>
                                  Эксперты проверили этот курс, теперь ему нужно проставить оценки и после этого редактор сможет опубликовать курс для публичного отображения на страницах портала.

                                  {course.can_review &&
                                    <>
                                      <br />
                                      <Link to={path('review_account_course_path', { id: course.id })}>
                                        Поставить оценки
                                      </Link>
                                      <div className={classNames(styles.reviewed, { [styles.not]: course.review.rated_fields < course.review.fields })}>
                                        {`${course.review.rated_fields} из ${course.review.fields}`}
                                      </div>
                                    </>
                                  }
                                </p>
                              </div>
                            }

                            {state.events && state.events.length > 0 &&
                              <div className={styles.events}>
                                {state.events.map(event =>
                                  <div className={styles.event} key={event}>
                                    <button className={buttons.main} onClick={() => this.handleStateChange(event)}>{I18n.t(`state.events.${event}`)}</button>
                                  </div>
                                )}
                              </div>
                            }
                          </>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={page.text}>
                <div className={styles.desc}>
                  <ReactMarkdown source={course.desc} />
                </div>

                <AttachmentsList attachments={course.attachments} />
              </div>

              <Comments object="Course" id={this.props.id} />
            </>
          }
        </div>
      </div>
    );
  }
}

export default Show;
