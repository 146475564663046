import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from '../Routes'
import { Event } from './Index'

import styles from './Block.module.css'

class Block extends React.Component {
  state = {
    more: false
  }

  render () {
    const { events, count, title } = this.props

    const { more } = this.state

    if (!events) return null

    return (
      <div className={styles.root}>
        {title &&
          <h2>{title}</h2>
        }

        <div className={classNames(styles.events, styles[`count_${count}`])}>
          {events.slice(0, more ? events.length : count).map(event =>
            <Event key={event.id} {...event} />
          )}
        </div>

        {events.length > count && !more &&
          <div className={styles.more} onClick={() => this.setState(state => ({ more: !this.state.more }))}>
            <div className={styles.title}>Ещё</div>
            <div className={styles.count}>{events.length - count}</div>
          </div>
        }

        {events.length == 0 && 
          <>
            <p>События, относящиеся к данному разделу, не найдены</p>
            <Link to={'events_path'}>Перейти ко всем событиям</Link>
          </>
        }
      </div>
    )
  }
}

Block.propTypes = {
  title: PropTypes.string,
  events: PropTypes.array,
  count: PropTypes.number
}

Block.defaultProps = {
  title: 'События'
}

export default Block
