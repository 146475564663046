import React, { useEffect, useState } from 'react';
import styles from '../../Index.module.css';
import Checkbox from './Checkbox';
import { observer } from 'mobx-react-lite';

const FilterTab = observer((props) => {
    const { filter, viewModel } = props;
    const { type, groupBy, searchable = false } = filter;

    const [searchValue, setSearchValue] = useState('');
    const [scrollContent, setScrollContent] = useState([]);
    const [checkedValues, setCheckedValues] = useState([]);

    useEffect(() => {
        setSearchValue('');
        const filtersData = viewModel.getFiltersData();
        const currentFilterData = filtersData[type] || [];
        const changedFilters = viewModel.getChangedFilters();

        if (groupBy) {
            var grouped = {};
            currentFilterData.map((item) => {
                const groupName = item[groupBy] || '';
                const groupedByNameArray = grouped[groupName] || [];
                groupedByNameArray.push(item);
                grouped[groupName] = groupedByNameArray;
            });
            var groupedArray = [];
            for (const key of Object.keys(grouped)) {
                groupedArray.push({
                    name: key,
                    items: grouped[key]
                });
            }
            setScrollContent(groupedArray);
        } else {
            setScrollContent(currentFilterData);
        }

        setCheckedValues(changedFilters[type]);
    }, [type]);

    const onChangeSearchValue = (event) => {
        const content = viewModel.filtersData[type];
        var value = event.target.value;
        setSearchValue(value);

        if (value.length > 1) {
            value = value.toLowerCase();
            const whereSearch = [...content];
            const found = whereSearch.filter((item) => {
                return item.title.toLowerCase().match(new RegExp(value, 'g'));
            });
            setScrollContent(found);
        } else {
            setScrollContent(content);
        }
    };

    const onChangeCheckboxValue = (id, state) => {
        var values = viewModel.changedFilters[type] || [];

        if (state && !values.includes(id)) {
            values.push(id);
        }
        if (!state && values.includes(id)) {
            values.splice(values.indexOf(id), 1);
        }
        setCheckedValues(values);
        viewModel.onChangeSomeFilterValue(type, values);
    };
    return (
        <div>
            {viewModel.onLoadingFilters && 
                <div className={styles.loading} />
            }

            {!viewModel.onLoadingFilters && 
                <>
                    {searchable && (
                        <div className={styles.new_search_filter_search}>
                            <input type="text" placeholder="Поиск" value={searchValue} onChange={onChangeSearchValue} />
                        </div>
                    )}
                    <ul>
                        {scrollContent.map((item, index) => {
                            if (groupBy) {
                                return <GroupedItem groupItem={item} key={`groupBy_item_${index}`} checkedValues={checkedValues} availableArray={viewModel.getAvailableFilters()[type]} onChange={onChangeCheckboxValue} />;
                            } else {
                                return (
                                    <li key={index}>
                                        <Item item={item} checkedValues={checkedValues} availableArray={viewModel.getAvailableFilters()[type]} onChange={onChangeCheckboxValue} />
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </>
            }
        </div>
    );
});

const GroupedItem = (props) => {
    const { groupItem, checkedValues, availableArray, onChange } = props;
    const { name, items } = groupItem;
    const [opened, setOpened] = useState(false);

    return (
        <div>
            <div className={styles.filter_lists_item}>
                <div className={styles.filter_lists_head}>
                    <span
                        className={styles.filter_lists_title}
                        onClick={() => {
                            setOpened(!opened);
                        }}
                    >
                        {name}
                    </span>
                    <span className={styles.filter_lists_arrow + ' ' + (opened ? styles.filter_lists_arrow_active : '')}></span>
                </div>
                {opened && (
                    <div className={styles.filter_lists_content + ' ' + (opened ? styles.filter_lists_content_active : '')}>
                        {items.map((item, index) => {
                            return (
                                <li key={`tab_item_${index}`}>
                                    <Item item={item} checkedValues={checkedValues} availableArray={availableArray} onChange={onChange} />
                                </li>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

const Item = (props) => {
    const { item, checkedValues, availableArray, onChange } = props;
    return <Checkbox id={item.id} title={item.title ? item.title : item.name} checked={checkedValues.includes(item.id)} disabled={!availableArray.includes(item.id)} onChange={onChange} />;
};

export default FilterTab;
