import React from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import I18n from '../I18n'
import Link, { path } from '../Routes'
import Loaf from '../Loaf/Loaf'

import Covid from '../Requests/Covid'
import Block from '../Stories/Block'
import Events from '../Events/Block'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import styles from './Index.module.css'
import schools_styles from './Schools.module.css'

import CareersIll from '../../images/pages/abiturs/careers.svg'
import TestIll from '../../images/pages/abiturs/test.svg'
import AccessibilityByIll from '../../images/pages/abiturs/by_accessibility.svg'
import RegionByIll from '../../images/pages/abiturs/by_region.svg'
import CareerByIll from '../../images/pages/abiturs/by_career.svg'

import ScrollIntoView from 'react-scroll-into-view'

class Index extends React.Component {
  state = {
    events: null,
    stories: null
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('abiturs_path', {}, true), { cancelToken: this._asyncRequest.token })
    this._asyncRequest = null

    this.setState({ ...res.data })
  }

  render () {
    const { events, stories } = this.state

    return (
      <div className={page.wrapper} id="header">
        <div className={page.container}>
          <Loaf>
            <Link to="root_path">{I18n.t('nav.root')}</Link>
          </Loaf>
          <div className={page.title}>
            <h1>
              {I18n.t('nav.abiturs')}
            </h1>

            <Helmet>
              <title>{I18n.t('nav.abiturs')}</title>
            </Helmet>
          </div>

          <div className={styles.nav}>
            <div className={styles.item}>
              <ScrollIntoView selector="#schools" className={styles.link}>
                <div className={styles.title}>Поиск вуза</div>
              </ScrollIntoView>
            </div>
            <div className={styles.item}>
              <ScrollIntoView selector="#abiturs_test" className={styles.link}>
                <div className={styles.title}>Профтестирование</div>
              </ScrollIntoView>
            </div>
            <div className={styles.item}>
              <ScrollIntoView selector="#careers" className={styles.link}>
                <div className={styles.title}>Атлас профессий</div>
              </ScrollIntoView>
            </div>
            <div className={styles.item}>
              <ScrollIntoView selector="#abiturs_stories" className={styles.link}>
                <div className={styles.title}>Истории успеха</div>
              </ScrollIntoView>
            </div>
            <div className={styles.item}>
              <ScrollIntoView selector="#events" className={styles.link}>
                <div className={styles.title}>События</div>
              </ScrollIntoView>
            </div>
            <div className={styles.item}>
              <ScrollIntoView selector="#questions" className={styles.link}>
                <div className={styles.title}>Популярные вопросы</div>
              </ScrollIntoView>
            </div>
          </div>

          <ScrollIntoView selector="#header" className={styles.button_up}></ScrollIntoView>

          <div className={styles.main}>
            <section className={classNames(styles.section, styles.test)}>
              <div className={styles.ill}>
                <img src={TestIll} />
              </div>

              <div className={styles.content}>
                <h2 id="abiturs_test">
                  {I18n.t('pages.abiturs.test.title')}
                </h2>

                <p>
                  {I18n.t('pages.abiturs.test.desc')}
                </p>

                {I18n.locale === 'ru' &&
                  <p>
                    <Link to="abiturs_test_path" className={buttons.main}>
                      Пройти тест
                    </Link>
                  </p>
                }

                <a href="https://fmc-spo.ru/" target="_blank" className={styles.url}>
                  <p><b>Федеральный методический центр по инклюзивному образованию</b> (информация о программах среднего профессионального образования)</p>
                </a>
              </div>
            </section>

            <section className={classNames(styles.section, styles.careers)}>
              <div className={styles.ill}>
                <img src={CareersIll} />
              </div>

              <div className={styles.content}>
                <h2 id="careers">
                  {I18n.t('pages.abiturs.careers.title')}
                </h2>

                <p>
                  {I18n.t('pages.abiturs.careers.desc')}
                </p>

                {I18n.locale === 'ru' &&
                  <p>
                    <Link to="careers_path" className={buttons.main}>
                      Выбрать профессию
                    </Link>
                    {/*
                    <Link to={routes.abiturs_vacancies_path} className={buttons.main}>
                      Посмотреть вакансии
                    </Link>
                    */}
                  </p>
                }

                <p><b>Альманах «Атлас доступных профессий»</b><br />региональный опыт СПО</p>
                <p>
                  {/* <a href="https://fmc-spo.ru/netcat_files/AtlasProfessions.pdf" target="_blank" className={buttons.main}>Посмотреть</a> */}
                  <a href="/uploads/document/abiturs/SPO_2023.pdf" target="_blank" className={buttons.main}>Посмотреть</a>
                </p>

              </div>
            </section>

            <section className={classNames(styles.section, schools_styles.root)}>
              <div className={schools_styles.header}>
                <h2 id="schools">
                  {I18n.t('pages.abiturs.schools.title')}
                </h2>

                <p>
                  {I18n.t('pages.abiturs.schools.desc')}
                </p>
              </div>

              <div className={schools_styles.items}>
                <div className={schools_styles.item}>
                  <img src={AccessibilityByIll} />

                  <h3>
                    {I18n.t('pages.abiturs.schools.by.accessibility.title')}
                  </h3>

                  <p>
                    {I18n.t('pages.abiturs.schools.by.accessibility.desc')}
                  </p>
                </div>

                <div className={schools_styles.item}>
                  <img src={RegionByIll} />

                  <h3>
                    {I18n.t('pages.abiturs.schools.by.region.title')}
                  </h3>

                  <p>
                    {I18n.t('pages.abiturs.schools.by.region.desc')}
                  </p>
                </div>

                <div className={schools_styles.item}>
                  <img src={CareerByIll} />

                  <h3>
                    {I18n.t('pages.abiturs.schools.by.career.title')}
                  </h3>

                  <p>
                    {I18n.t('pages.abiturs.schools.by.career.desc')}
                  </p>
                </div>
              </div>

              {I18n.locale === 'ru' &&
                <div className={schools_styles.footer}>
                  <Link to="schools_path" className={buttons.main}>
                    Найти учебное заведение
                  </Link>
                </div>
              }
            </section>

            <section className={classNames(styles.section, styles.stories)} id="abiturs_stories">
              <Block stories={stories} />
            </section>

            {I18n.locale === 'ru' && events &&
              <section className={classNames(styles.section, styles.events)} id="events">
                <Events events={events} count={3} />
              </section>
            }

            {I18n.locale === 'ru' &&
              <section className={classNames(styles.section, styles.faq)}>
                <h2 id="questions">
                  Популярные вопросы
                </h2>

                <div className={styles.questions}>
                  {/* <div className={styles.question}>
                    <p>
                      <strong>
                        Как поступить в вуз по шагам
                      </strong>
                    </p>

                    <p>
                      На этом этапе возникает больше всего вопросов, хотя на самом деле в нем нет ничего трудного
                    </p>
                  </div>

                  <div className={styles.question}>
                    <p>
                      <strong>
                        Как узнать какое оборудование и специалисты есть в вузе
                      </strong>
                    </p>
                    <p>
                      Информация о наличии условий доступности получения образования
                    </p>
                  </div> */}
                  <a className={styles.ask} href="/uploads/document/press_reliz/call-center-answers-2024.pdf"  target="_blank" rel="noopener noreferrer">
                    <p>
                      Сборник ответов на наиболее распространенные вопросы по итогам работы «горячей линии» сети РУМЦ (2024)
                    </p>
                  </a>


                  <Link to="new_request_path" className={styles.ask}>
                    <p>
                      <strong>
                        Не нашли ответа на ваш вопрос?
                      </strong>
                    </p>
                    <p>
                      Напишите нам и мы поможем найти вам ответ на ваш вопрос
                    </p>
                  </Link>
                </div>
              </section>
            }

            <section className={classNames(styles.section, styles.request)}>
              <Covid />
              {/* <RequestNewBlock text={'С 20 июня работает «горячая линия» сети ресурсных учебно-методических центров по обучению лиц с инвалидностью по вопросам поступления в вузы'} title={'Горячая линия по вопросам поступления в вузы'} /> */}
            </section>

          </div>
        </div>
      </div>
    )
  }
}

export default Index
