import { withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import classNames from 'classnames'
import Link, { path } from '../Routes'
import axios from 'axios'
import qs from 'qs';

import { debounce } from 'lodash';

import { newBackendHost } from '../Vars';
// import styles from './Index.module.css'
import styles from '../Careers/Index.module.css'
import showStyles from '../Careers/Show.module.css'
import page from '../Page.module.css'
import { calcSalaryString, calc_rating } from '../Careers/helpers'

// 
// TODO:
// Переход со страницы профессии по нажатию на кнопку "Показать больше" в подразделе "Атлас вакансий"
// 

class Index extends Component {
  state = {
    loading_careers: false,
    vacancy: null,
    vacancy_count: null,
    show_filters_mobile: false,

    search: '',
    timeout: 1000,

    location_region: {},
    regions_list: [],
    showRegions: false,
    regions: [],

    nosology_list: [
      {id: 'e', title: 'Нарушения зрения'},
      {id: 'h', title: 'Нарушения слуха'},
      {id: 'm', title: 'Нарушения ОДА'},
    ],
    nosology: [],

    pages: [1],
    pages_count: 1,
    active_page: 1,

    rumcs_contacts: [],

    specialisation_list: [],
    specialisation: [],
    specialisation_show_all: false,
    schedule_list: [],
    schedule: [],
    schedule_show_all: false,
    education_list: [],
    education: [],
    education_show_all: false,
  }

  constructor(props) {
    super(props);
    this.throttleHandleSearch = debounce(this.throttleHandleSearch.bind(this), 500);
    this.handleSearch = this.handleSearch.bind(this);
  }
  
  async componentDidMount () {
    await this.getLocation();

    const searchParamsStr = this.props.history?.location?.search;
    const paramsObject = qs.parse(searchParamsStr || '', { ignoreQueryPrefix: true }) || {};
    if (paramsObject.regions) {
      paramsObject.regions = paramsObject.regions.map(r => parseInt(r));
    } else if (this.state.location_region.id) {
      paramsObject.regions = [this.state.location_region.id]

      const strParams = qs.stringify(paramsObject) || '';
      this.props.history.push({
        pathname: this.props.match.path,
        search: strParams.length > 0 ? '?' + strParams : ''
      });
    }
    if (paramsObject.specialisation)
      paramsObject.specialisation = paramsObject.specialisation.map(r => parseInt(r));
    if (paramsObject.education)
      paramsObject.education = paramsObject.education.map(r => parseInt(r));
    if (paramsObject.schedule)
      paramsObject.schedule = paramsObject.schedule.map(r => parseInt(r));

    this.setState({ ...paramsObject })

    await this.getVacancy({
      region_id: paramsObject.regions,
      nosologies: paramsObject.nosology,
      page: paramsObject.page,
      search: paramsObject.search,

      specialisation: this.state.specialisation,
      schedule: this.state.schedule,
      education: this.state.education,
    });

    if (this.state.location_region.id) {
      await this.getRumcContacts()
    }

    await this.getFilters();
  }

  getLocation = async () => {
    const { data: resp } = await axios.get(`${newBackendHost}/careers/location`)
    this.setState({
      location_region: resp
    })
    localStorage.setItem('location_region', JSON.stringify(resp))
  }

  //
  // TODO: debounce на фильтры
  // сбросить все фильтры
  //
  getVacancy = async (filters) => {
    this.setState({loading_careers: true});
    const res = await axios.get(`${newBackendHost}/vacancy`, { params: filters })
    
    if (res.status != 200) {
      this.setState({loading_careers: false});
    }

    const pages_count = Math.ceil(res.data.count / res.data.limit);
    this.setState({ 
      vacancy: res.data.data,
      vacancy_count: res.data.count,
      loading_careers: false,

      pages_count,
      pages: [...Array(pages_count).keys()].map((i) => i + 1),
    })
  }

  getFilters = async () => {
    try {
      const { data: filters } = await axios.get(`${newBackendHost}/vacancy/filters`)
      this.setState({ 
        regions_list: filters.regions,
        specialisation_list: filters.specialisation,
        schedule_list: filters.schedule,
        education_list: filters.education,
      })
    } catch (e) {
      console.log(e)
    }
  }

  getRumcContacts = async () => {
    const { data: rumcs_contacts } = await axios.get(`${newBackendHost}/rumc`, { params: { region: this.state.location_region.id } })
    this.setState({ rumcs_contacts })
    localStorage.setItem('rumcs_contacts', JSON.stringify(rumcs_contacts))
  }

  filterPages = () => {
    let active_page = this.state.active_page;

    let min = active_page - 3;
    let max = active_page + 2;
    if (window.innerWidth <= 480) {
      min = active_page - 2;
      max = active_page + 1;
    }
    let filtered = this.state.pages;

    if (min < 0) {
      min = 0;
      max = 5;
      if (window.innerWidth <= 480) {
        max = 3;
      }
    }

    if (max >= filtered.length) {
      min = filtered.length - 5;
      if (window.innerWidth <= 320) {
        min = filtered.length - 3;
      }
      max = filtered.length;
    }

    return filtered.filter((v, i) => {
      if (i >= min && i < max) return v;
    });
  };

  setActivePage = (page) => {
    setTimeout(() => {
      this.setState({
        active_page: page
      });
      this.applyFilters('page', page);
    }, 100);
  };


  back () {
    this.props.history.goBack();
  }

  applyFilters(filter_title, filter_value) {
    const all_filters = {
      regions: this.state.regions,
      nosology: this.state.nosology,
      page: this.state.active_page,
      search: this.state.search,

      specialisation: this.state.specialisation,
      schedule: this.state.schedule,
      education: this.state.education,
    }

    all_filters[filter_title] = filter_value;

    //Обновляем браузерный URL
    const strParams = qs.stringify(all_filters) || '';
    this.props.history.push({
      pathname: this.props.match.path,
      search: strParams.length > 0 ? '?' + strParams : ''
    });

    this.setState(all_filters);
    this.getVacancy({
      region_id: all_filters.regions,
      nosologies: all_filters.nosology,
      page: all_filters.page,
      search: all_filters.search,

      specialisation: all_filters.specialisation,
      schedule: all_filters.schedule,
      education: all_filters.education,
    })
  }

  setRegionFilter(id) {
    let regions = this.state.regions;

    const exist_ind = regions.indexOf(id);
    if (exist_ind == -1) regions.push(id)
    else regions.splice(exist_ind, 1)

    this.applyFilters('regions', regions)
    this.setActivePage(1);
  }

  setNosologyFilter(id) {
    let nosology = this.state.nosology;

    const exist_ind = nosology.indexOf(id);
    if (exist_ind == -1) nosology.push(id)
    else nosology.splice(exist_ind, 1)
    
    this.applyFilters('nosology', nosology)
    this.setActivePage(1);
  }

  setSpecialisationFilter(val) {
    let specialisation = this.state.specialisation;

    const exist_ind = specialisation.indexOf(val);
    if (exist_ind == -1) specialisation.push(val)
    else specialisation.splice(exist_ind, 1)
    
    this.applyFilters('specialisation', specialisation)
    this.setActivePage(1);
  }

  setScheduleFilter(val) {
    let schedule = this.state.schedule;

    const exist_ind = schedule.indexOf(val);
    if (exist_ind == -1) schedule.push(val)
    else schedule.splice(exist_ind, 1)
    
    this.applyFilters('schedule', schedule)
    this.setActivePage(1);
  }

  setEducationFilter(val) {
    let education = this.state.education;

    const exist_ind = education.indexOf(val);
    if (exist_ind == -1) education.push(val)
    else education.splice(exist_ind, 1)
    
    this.applyFilters('education', education)
    this.setActivePage(1);
  }

  throttleHandleSearch() {
    this.applyFilters('search', this.state.search)
    this.setActivePage(1);
  }

  handleSearch(e) {
    this.setState({search: e.target.value, loading_careers: true});
    this.throttleHandleSearch();
  };

  openOrganisation(e, site) {
    e.preventDefault();
    e.stopPropagation();
    window.open(site.startsWith('http') ? site : `https://${site}`,  '_blank');
  }

  render () {
    const { 
      location_region, loading_careers,
      vacancy, vacancy_count, 
      show_filters_mobile,
      active_page,
      search, 
      showRegions, regions_list, regions,
      nosology_list, nosology,
      rumcs_contacts,
      specialisation_list, specialisation, specialisation_show_all,
      schedule_list, schedule, schedule_show_all,
      education_list, education, education_show_all,
    } = this.state;

    return (
      <div>
        <div className={styles.header}>
          <div className={styles.page_container}>
            <div className={classNames(styles.container, styles.navbar)}>
              <div className={styles.column}>
                <p><a href='/'>Главная</a> / Атлас вакансий</p>
                </div>
              <div className={styles.column}></div>
              {location_region.id && 
                <>
                  <div className={classNames(styles.column, styles.flex_row, styles.location_region)}>
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 0C3.58 0 0 4 0 8.5C0 12.96 2.55 17.81 6.53 19.67C7.46 20.1 8.53 20.1 9.46 19.67C13.44 17.81 16 12.96 16 8.5C16 4 12.41 0 8 0ZM8 10C9.1 10 10 9.1 10 8C10 6.89 9.1 6 8 6C6.89 6 6 6.89 6 8C6 9.1 6.89 10 8 10Z" fill="#050505" fillOpacity="1.000000" fillRule="evenodd"/>
                    </svg>
                    <p>{location_region.title}</p>
                  </div>
                </>
              }
            </div>

            <div className={styles.container}>
              <div className={classNames(styles.column, styles.navbar_title)}>Атлас вакансий</div>
              <div className={classNames(styles.column, styles.navbar_text)}>
                <p>На этой странице можно посмотреть востребованные вакансии.</p>
                <p>Еще можно посмотреть особенность будущей работы, среднюю заработную плату и открытые вакансии.</p>
              </div>
              <div className={styles.column}></div>
            </div>
          </div>
        </div>


        <div className={page.wrapper}>
          <div className={styles.page_container}>

            <div className={styles.container}>
              <div className={styles.column}>
                <div className={classNames(styles.flex_row, styles.page_subtitle_container)}>
                  <div className={styles.page_subtitle}>Вакансии</div>
                  <span className={loading_careers ? styles.active : ''}>{vacancy_count}</span>
                </div>
              </div>

              {/* Центр */}
              <div className={classNames(styles.column, styles.careers_cards_container)}>
                
                {/* Поиск */}
                <div className={styles.careers_search_container}>
                  <label>Используйте поиск, чтобы найти конкретную вакансию</label>
                  <div className={styles.careers_search}>
                    <input placeholder='Поиск' name='search' value={search} type="text" onChange={(e) => this.handleSearch(e)} />
                  </div>

                  {/* Примененные фильтры */}
                  <div className={styles.selected_filters_container}>
                    {regions.length != 0 &&
                      <>
                        <div className={styles.filters_category}>Регион:</div>
                        {regions_list.length > 0 && regions.map(f =>
                          <div key={`selected_region_${f}`} className={styles.selected_filters_item}>
                            <span className={styles.selected_filters_item_title}>{regions_list.find(r => r.id == f)?.title}</span>
                            <svg onClick={() => this.setRegionFilter(f)} width="19.000000" height="19.000000" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path id="Vector" d="M19 9.5C19 14.74 14.74 19 9.5 19C4.25 19 0 14.74 0 9.5C0 4.25 4.25 0 9.5 0C14.74 0 19 4.25 19 9.5ZM6.62 6.62C6.89 6.34 7.35 6.34 7.62 6.62L9.5 8.49L11.37 6.62C11.64 6.34 12.1 6.34 12.37 6.62C12.65 6.89 12.65 7.35 12.37 7.62L10.5 9.5L12.37 11.37C12.65 11.64 12.65 12.1 12.37 12.37C12.1 12.65 11.64 12.65 11.37 12.37L9.5 10.5L7.62 12.37C7.35 12.65 6.89 12.65 6.62 12.37C6.34 12.1 6.34 11.64 6.62 11.37L8.49 9.5L6.62 7.62C6.34 7.35 6.34 6.89 6.62 6.62Z" fill="#FF8D00" fillOpacity="1.000000" fillRule="evenodd"/>
                            </svg>
                          </div>
                        )}
                      </>
                    }

                    {specialisation.length != 0 &&
                      <>
                        <div className={styles.filters_category}>Специализация:</div>
                        {specialisation_list.length > 0 && specialisation.map(f =>
                          <div key={`selected_region_${f}`} className={styles.selected_filters_item}>
                            <span className={styles.selected_filters_item_title}>{specialisation_list.find(r => r.id == f)?.title}</span>
                            <svg onClick={() => this.setSpecialisationFilter(f)} width="19.000000" height="19.000000" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path id="Vector" d="M19 9.5C19 14.74 14.74 19 9.5 19C4.25 19 0 14.74 0 9.5C0 4.25 4.25 0 9.5 0C14.74 0 19 4.25 19 9.5ZM6.62 6.62C6.89 6.34 7.35 6.34 7.62 6.62L9.5 8.49L11.37 6.62C11.64 6.34 12.1 6.34 12.37 6.62C12.65 6.89 12.65 7.35 12.37 7.62L10.5 9.5L12.37 11.37C12.65 11.64 12.65 12.1 12.37 12.37C12.1 12.65 11.64 12.65 11.37 12.37L9.5 10.5L7.62 12.37C7.35 12.65 6.89 12.65 6.62 12.37C6.34 12.1 6.34 11.64 6.62 11.37L8.49 9.5L6.62 7.62C6.34 7.35 6.34 6.89 6.62 6.62Z" fill="#FF8D00" fillOpacity="1.000000" fillRule="evenodd"/>
                            </svg>
                          </div>
                        )}
                      </>
                    }
                    {schedule.length != 0 &&
                      <>
                        <div className={styles.filters_category}>Тип занятости:</div>
                        {schedule_list.length > 0 && schedule.map(f =>
                          <div key={`selected_region_${f}`} className={styles.selected_filters_item}>
                            <span className={styles.selected_filters_item_title}>{schedule_list.find(r => r.id == f)?.title}</span>
                            <svg onClick={() => this.setScheduleFilter(f)} width="19.000000" height="19.000000" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path id="Vector" d="M19 9.5C19 14.74 14.74 19 9.5 19C4.25 19 0 14.74 0 9.5C0 4.25 4.25 0 9.5 0C14.74 0 19 4.25 19 9.5ZM6.62 6.62C6.89 6.34 7.35 6.34 7.62 6.62L9.5 8.49L11.37 6.62C11.64 6.34 12.1 6.34 12.37 6.62C12.65 6.89 12.65 7.35 12.37 7.62L10.5 9.5L12.37 11.37C12.65 11.64 12.65 12.1 12.37 12.37C12.1 12.65 11.64 12.65 11.37 12.37L9.5 10.5L7.62 12.37C7.35 12.65 6.89 12.65 6.62 12.37C6.34 12.1 6.34 11.64 6.62 11.37L8.49 9.5L6.62 7.62C6.34 7.35 6.34 6.89 6.62 6.62Z" fill="#FF8D00" fillOpacity="1.000000" fillRule="evenodd"/>
                            </svg>
                          </div>
                        )}
                      </>
                    }
                    {education.length != 0 &&
                      <>
                        <div className={styles.filters_category}>Образование:</div>
                        {education_list.length > 0 && education.map(f =>
                          <div key={`selected_region_${f}`} className={styles.selected_filters_item}>
                            <span className={styles.selected_filters_item_title}>{education_list.find(r => r.id == f)?.title}</span>
                            <svg onClick={() => this.setEducationFilter(f)} width="19.000000" height="19.000000" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path id="Vector" d="M19 9.5C19 14.74 14.74 19 9.5 19C4.25 19 0 14.74 0 9.5C0 4.25 4.25 0 9.5 0C14.74 0 19 4.25 19 9.5ZM6.62 6.62C6.89 6.34 7.35 6.34 7.62 6.62L9.5 8.49L11.37 6.62C11.64 6.34 12.1 6.34 12.37 6.62C12.65 6.89 12.65 7.35 12.37 7.62L10.5 9.5L12.37 11.37C12.65 11.64 12.65 12.1 12.37 12.37C12.1 12.65 11.64 12.65 11.37 12.37L9.5 10.5L7.62 12.37C7.35 12.65 6.89 12.65 6.62 12.37C6.34 12.1 6.34 11.64 6.62 11.37L8.49 9.5L6.62 7.62C6.34 7.35 6.34 6.89 6.62 6.62Z" fill="#FF8D00" fillOpacity="1.000000" fillRule="evenodd"/>
                            </svg>
                          </div>
                        )}
                      </>
                    }
                  </div>
                </div>

                {vacancy && vacancy.map(vac => 
                  // <Link to="vacancy_path" params={{ id: vac.id }} className={styles.careers_card} key={vac.id}>
                  <a href={`${path('vacancy_path', {id: vac.id})}${window.location.search}`} className={styles.careers_card} key={vac.id}>
                    {/* <div className={styles.careers_card} key={vac.id}> */}
                    <div className={styles.career_rating_about_row}>
                      <div className={styles.ellipse_rating}>
                        <span>{vac.rating}</span>
                        {calc_rating(vac.rating).map((i, index) => 
                          <div key={`star_${index}`}>
                            {i != 0 &&
                              <svg  width="15.755859" height="15.082092" viewBox="0 0 15.7559 15.0821" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="Star" d="M7.05 0.43L4.9 3.56C4.77 3.75 4.58 3.89 4.36 3.96L0.71 5.03C0.04 5.23 -0.23 6.04 0.2 6.6L2.52 9.61C2.66 9.8 2.73 10.02 2.73 10.25L2.62 14.05C2.6 14.75 3.3 15.25 3.96 15.02L7.54 13.75C7.75 13.67 7.99 13.67 8.21 13.75L11.79 15.02C12.45 15.25 13.14 14.75 13.12 14.05L13.02 10.25C13.02 10.02 13.08 9.8 13.23 9.61L15.54 6.6C15.97 6.04 15.71 5.23 15.03 5.03L11.39 3.96C11.17 3.89 10.98 3.75 10.85 3.56L8.7 0.43C8.3 -0.15 7.45 -0.15 7.05 0.43Z" fill="#9399A6" fillOpacity="1.000000" fillRule="evenodd"/>
                              </svg>
                            }
                            {i == 0 &&
                              <svg width="8.318359" height="14.007385" viewBox="0 0 8.31836 14.0074" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="Star " d="M6.53 0.38L4.9 2.47C4.77 2.63 4.59 2.75 4.39 2.81L0.71 3.9C0.04 4.1 -0.23 4.91 0.2 5.47L2.52 8.48C2.66 8.66 2.73 8.88 2.73 9.11L2.62 12.97C2.6 13.66 3.26 14.16 3.92 13.96L7.61 12.81C8.03 12.68 8.31 12.29 8.31 11.85L8.31 1C8.31 0.05 7.11 -0.37 6.53 0.38Z" fill="#9399A6" fillOpacity="1.000000" fillRule="evenodd"/>
                              </svg>
                            }
                          </div>
                        )}
                      </div>
                      <div className={styles.ellipse_about}>Подробнее</div>
                    </div>

                    <div className={styles.careers_card_title}>{vac.title}</div>

                    {/* SALARY */}
                    <div className={styles.careers_card_salary}>{calcSalaryString(vac)}</div>

                    {vac.company_name && 
                      <div className={classNames(styles.card_info, styles.company_name)}>
                        {vac.company_name}
                        
                        {vac.company_site && 
                          <svg style={{cursor: 'pointer'}} onClick={e => (this.openOrganisation(e, vac.company_site))} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
                          </svg>
                        }
                      </div>
                    }
                    
                    <div className={styles.card_info}>
                      {vac.regions && vac.regions.title &&
                        <div className={styles.item}>
                          <span>Регион:</span>{vac.regions.title}
                        </div>
                      }
                      {vac.vacancies_schedule &&
                        <div className={styles.item}>
                          <span>Тип занятости:</span>{vac.vacancies_schedule.title}
                        </div>
                      }
                      {vac.vacancies_specialisation &&
                        <div className={styles.item}>
                          <span>Специализация:</span>{vac.vacancies_specialisation.title}
                        </div>
                      }
                      {vac.vacancies_education &&
                        <div className={styles.item}>
                          <span>Образование:</span>{vac.vacancies_education.title}
                        </div>
                      }
                    </div>
                  </a>
                )}
                {vacancy_count == 0 &&
                  <h2>Вакансии не найдены</h2>
                }

                <div className={styles.paginaton_container}>
                  <div className={styles.paginaton_wrapper}>
                    {this.filterPages().map((p) => (
                      <div key={`page_${p}`} className={classNames(styles.page, active_page == p ? styles.active : '')} onClick={() => this.setActivePage(p)}>
                        {p}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              
              {/* Правая колонка */}
              {/* Фильтры: */}
              <div className={styles.column}>
                {window.innerWidth <= 950 &&
                  <div 
                    className={classNames(styles.ellipse_small_filters, show_filters_mobile ? styles.active : '')} 
                    onClick={() => this.setState({show_filters_mobile: !show_filters_mobile})}
                  >
                    Фильтры
                  </div>
                }

                {((window.innerWidth > 950) || (window.innerWidth <= 950 && show_filters_mobile)) &&
                  <div className={styles.filters_container}>
                    {/* Регион */}
                    <div className={styles.filter_title}>Регион:</div>
                    <div 
                      className={classNames(styles.filter_item_region, showRegions ? styles.filter_item_region_active : '')}
                      onClick={() => this.setState({showRegions: !showRegions})}
                    >
                      Области:
                    </div>
                    <div className={styles.regions_list_wrapper}>
                      {showRegions && regions_list.length != 0 && regions_list.map(r => 
                        <div className={styles.filter_item_wrapper} key={`region_filter_item_${r.id}`} onClick={() => this.setRegionFilter(r.id)}>
                          <div className={classNames(styles.filter_item_checkbox, regions && regions.includes(r.id) ? styles.filter_item_checkbox_active : '')} />
                          <span>{r.title}</span>
                        </div>
                      )}
                    </div>
                    
                    {/* Специализация */}
                    <div className={styles.filter_title}>Специализация:</div>
                    <div className={specialisation_show_all ? styles.regions_list_wrapper : styles.flex_column}>
                    {/* {specialisation_list.length != 0 && specialisation_list.slice(0, specialisation_show_all ? specialisation_list.length : 5).map(f =>  */}
                    {specialisation_list.length != 0 && specialisation_list.slice(0, specialisation_show_all ? specialisation_list.length : 5).map(f => 
                      <div className={styles.filter_item_wrapper} key={`specialisation_filter_item_${f.title}`} onClick={() => this.setSpecialisationFilter(f.id)}>
                        <div className={classNames(styles.filter_item_checkbox, specialisation.includes(f.id) ? styles.filter_item_checkbox_active : '')} />
                        <span>{f.title}</span>
                      </div>
                    )}
                    </div>
                    {!specialisation_show_all && <div className={styles.show_all} onClick={() => this.setState({specialisation_show_all: true})}>Показать все</div>}
                    {specialisation_show_all && <div className={styles.show_all} onClick={() => this.setState({specialisation_show_all: false})}>Скрыть</div>}


                    {/* Тип занятости: */}
                    <div className={styles.filter_title}>Тип занятости:</div>
                    {schedule_list.length != 0 && schedule_list.map(f => 
                      <div className={styles.filter_item_wrapper} key={`schedule_filter_item_${f.title}`} onClick={() => this.setScheduleFilter(f.id)}>
                        <div className={classNames(styles.filter_item_checkbox, schedule.includes(f.id) ? styles.filter_item_checkbox_active : '')} />
                        <span>{f.title}</span>
                      </div>
                    )}


                    {/* Образование: */}
                    {/* education_list */}
                    <div className={styles.filter_title}>Образование:</div>
                    {education_list.length != 0 && education_list.map(f => 
                      <div className={styles.filter_item_wrapper} key={`education_filter_item_${f.title}`} onClick={() => this.setEducationFilter(f.id)}>
                        <div className={classNames(styles.filter_item_checkbox, education.includes(f.id) ? styles.filter_item_checkbox_active : '')} />
                        <span>{f.title}</span>
                      </div>
                    )}
                    {/* Доступная среда ??? */}
                  </div>
                }
              </div>
            </div>

            <div className={styles.divider} />
                  
            <div className={styles.container}>
              <div className={styles.column}>
                <h2>Контактная<br />информация</h2>
              </div>
              <div className={styles.column}>

                <div className={styles.consulting_about_container}>
                  <div className={styles.consulting_about_row}>
                    <svg width="22.000000" height="22.000000" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="Vector" d="M14.84 0C18.79 0 22 3.2 22 7.14C22 8.2 21.76 9.21 21.35 10.11C21.17 10.51 21.1 10.96 21.22 11.38L21.36 11.9C21.64 12.97 20.67 13.94 19.6 13.66L19.24 13.56C19.24 13.44 19.25 13.32 19.25 13.2C19.25 7.56 14.79 2.97 9.21 2.75C10.51 1.08 12.55 0 14.84 0ZM8.79 22C13.66 22 17.59 18.06 17.59 13.2C17.59 8.33 13.66 4.39 8.79 4.39C3.93 4.39 0 8.33 0 13.2C0 14.55 0.3 15.84 0.85 16.99C1.04 17.39 1.11 17.83 1 18.26L0.63 19.6C0.35 20.67 1.32 21.64 2.39 21.36L3.73 20.99C4.16 20.88 4.6 20.95 5 21.14C6.15 21.69 7.44 22 8.79 22ZM6.04 13.2C6.04 13.8 5.55 14.3 4.95 14.3C4.34 14.3 3.84 13.8 3.84 13.2C3.84 12.59 4.34 12.1 4.95 12.1C5.55 12.1 6.04 12.59 6.04 13.2ZM9.9 13.2C9.9 13.8 9.4 14.3 8.79 14.3C8.19 14.3 7.7 13.8 7.7 13.2C7.7 12.59 8.19 12.1 8.79 12.1C9.4 12.1 9.9 12.59 9.9 13.2ZM13.75 13.2C13.75 13.8 13.25 14.3 12.65 14.3C12.04 14.3 11.54 13.8 11.54 13.2C11.54 12.59 12.04 12.1 12.65 12.1C13.25 12.1 13.75 12.59 13.75 13.2Z" fill="#FCDA2D" fillOpacity="1.000000" fillRule="evenodd"/>
                    </svg>
                  </div>
                  <Link to="contacts_path" target='_blank' className={styles.ellipse_about}>Подробнее</Link>
                </div>
                <div className={styles.consulting_about_title}>Сервис онлайн-консультирования</div>
                <p>Чтобы получить ответ на свой вопрос можно воспользоваться сервисом онлайн-консультирования.</p>
                
                <div className={styles.consulting_region_container}>
                  <div className={styles.consulting_about_row}>
                    <svg width="16.000000" height="20.000000" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="Vector" d="M8 0C3.58 0 0 4 0 8.5C0 12.96 2.55 17.81 6.53 19.67C7.46 20.1 8.53 20.1 9.46 19.67C13.44 17.81 16 12.96 16 8.5C16 4 12.41 0 8 0ZM8 10C9.1 10 10 9.1 10 8C10 6.89 9.1 6 8 6C6.89 6 6 6.89 6 8C6 9.1 6.89 10 8 10Z" fill="#FCDA2D" fillOpacity="1.000000" fillRule="evenodd"/>
                    </svg>
                    <span>{location_region.title}</span>
                  </div>
                </div>
                <div className={styles.consulting_about_title}>Горячая линия по вопросам обучения</div>

                {rumcs_contacts.length > 0 && rumcs_contacts.map(r => 
                  <div key={`contact_rumc_${r.id}`}>
                    <div className={styles.contact_rumc_phone}>{r.phone}</div>
                    <Link to="rumc_path" params={{id: r.id}} target='_blank'>{r.title}</Link>
                  </div>
                )}
              </div>
              <div className={styles.column}></div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default withRouter(Index)
