import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { Switch, Route } from 'react-router-dom'
import classNames from 'classnames'

import Loaf from '../../Loaf/Loaf'
import Link, { path, Routes } from '../../Routes'
import I18n from '../../I18n'

import Index from './Index'
import Nominations from './Nominations'
import Committee from './Committee'
import Experts from './Experts'
import Results from './Results'
import Data from './Data'
import Contacts from './Contacts'
import Form from '../../TomorrowRequests/Form'
import TomorrowRequestsList from '../../TomorrowRequests/TomorrowRequestsList'
import TomorrowRequestShow from '../../TomorrowRequests/TomorrowRequestShow'

import page from '../../Page.module.css';
// import buttons from '../../Buttons.module.css';
import tabs from '../../Tabs.module.css';
import styles from './Tomorrow.module.css';
import file_style from '../../Schools/Index.module.css'

class Tomorrow extends React.Component {
  render() {
    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('events_path')}>События</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('events.tomorrow.title')}
            </h1>

            <p className={styles.desc}>
              {I18n.t('events.tomorrow.desc')}
            </p>

            <p><b>Дайджест сетевого конкурса студенческих проектов «Профессиональное завтра» с участием студентов с инвалидностью:</b></p>
            <p className={file_style.files}>
              <a href="/uploads/document/prof_zavtra/DAJDZHEST KONKURSA _PROFESSIONALNOE ZAVTRA_2024.pdf" target="_blank" rel="noopener noreferrer">Дайджест</a>
            </p>

            <Helmet>
              <title>{I18n.t('events.tomorrow.title')}</title>
              <meta property="og:title" content={I18n.t('events.tomorrow.title')} />
              <meta property="og:description" content={I18n.t('events.tomorrow.desc')} />
            </Helmet>
          </div>

          <div className={classNames(tabs.root, styles.tabs)}>
            <Link
              to="tomorrow_path"
              className={classNames(tabs.tab, styles.a_in_btn)}
              activeClassName={tabs.active}
            >{I18n.t('events.tomorrow.about')}</Link>

            <Link
              to="tomorrow_nominations_path"
              className={classNames(tabs.tab, styles.a_in_btn)}
              activeClassName={tabs.active}
            >{I18n.t('events.tomorrow.nominations')}</Link>

            <Link
              to="tomorrow_committee_path"
              className={classNames(tabs.tab, styles.a_in_btn)}
              activeClassName={tabs.active}
            >{I18n.t('events.tomorrow.committee')}</Link>

            <Link
              to="tomorrow_experts_path"
              className={classNames(tabs.tab, styles.a_in_btn)}
              activeClassName={tabs.active}
            >{I18n.t('events.tomorrow.experts')}</Link>

            <Link
              to="tomorrow_results_path"
              className={classNames(tabs.tab, styles.a_in_btn)}
              activeClassName={tabs.active}
            >{I18n.t('events.tomorrow.results')}</Link>

            <Link
              to="tomorrow_contacts_path"
              className={classNames(tabs.tab, styles.a_in_btn)}
              activeClassName={tabs.active}
            >{I18n.t('events.tomorrow.contacts')}</Link>

            <Link
              to="tomorrow_tomorrow_requests_path"
              className={classNames(tabs.tab, styles.a_in_btn)}
              activeClassName={tabs.active}
            >{I18n.t('events.tomorrow.requests')}</Link>
          </div>

          <div className={styles.root}>
            <div className={styles.shares}>
              <p>
                Поделиться
              </p>
              <br />
              <ul>
                <li className={styles.vk}>
                  <a target="_blank" href={`https://vk.com/share.php?url=https%3A//%D0%B8%D0%BD%D0%BA%D0%BB%D1%8E%D0%B7%D0%B8%D0%B2%D0%BD%D0%BE%D0%B5%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5.%D1%80%D1%84/%D0%BF%D1%80%D0%BE%D1%84%D0%B7%D0%B0%D0%B2%D1%82%D1%80%D0%B0`}>ВКонтакте</a>
                </li>

                {/* <li className={styles.fb}>
                  <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//%D0%B8%D0%BD%D0%BA%D0%BB%D1%8E%D0%B7%D0%B8%D0%B2%D0%BD%D0%BE%D0%B5%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5.%D1%80%D1%84/%D0%BF%D1%80%D0%BE%D1%84%D0%B7%D0%B0%D0%B2%D1%82%D1%80%D0%B0`}>Фейсбук</a>
                </li>

                <li className={styles.tw}>
                  <a target="_blank" href={`https://twitter.com/home?status=https%3A//%D0%B8%D0%BD%D0%BA%D0%BB%D1%8E%D0%B7%D0%B8%D0%B2%D0%BD%D0%BE%D0%B5%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5.%D1%80%D1%84/%D0%BF%D1%80%D0%BE%D1%84%D0%B7%D0%B0%D0%B2%D1%82%D1%80%D0%B0`}>Твиттер</a>
                </li> */}
              </ul>
            </div>

            <div className={styles.main}>
              <Switch>
                <Route exact strict path={decodeURIComponent(Routes.tomorrow_path)} component={Index} />
                <Route exact strict path={decodeURIComponent(Routes.new_tomorrow_tomorrow_request_path)} component={Form} />
                <Route exact strict path={decodeURIComponent(Routes.tomorrow_tomorrow_requests_path)} component={TomorrowRequestsList} />
                <Route exact strict path={decodeURIComponent(Routes.tomorrow_tomorrow_request_path)} render={props => <TomorrowRequestShow id={props.match.params.id} {...props} />} />
                <Route exact strict path={decodeURIComponent(Routes.tomorrow_nominations_path)} component={Nominations} />
                <Route exact strict path={decodeURIComponent(Routes.tomorrow_committee_path)} component={Committee} />
                <Route exact strict path={decodeURIComponent(Routes.tomorrow_experts_path)} component={Experts} />
                <Route exact strict path={decodeURIComponent(Routes.tomorrow_results_path)} component={Results} />
                <Route exact strict path={decodeURIComponent(Routes.tomorrow_contacts_path)} component={Contacts} />
                <Route exact strict path={decodeURIComponent(Routes.tomorrow_data_path)} component={Data} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tomorrow;
