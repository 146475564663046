import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import { Link } from 'react-router-dom'
import update from 'immutability-helper'

import Loaf from '../Loaf/Loaf'
import { path } from '../Routes'
import I18n from '../I18n'

import page from '../Page.module.css'
import styles from './Show.module.css'
import buttons from '../Buttons.module.css'

class Show extends Component {
  state = {
    report: null,
    values: null,
    programs: []
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData () {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('report_path', { id: this.props.id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { report, values, programs } = res.data

        this.setState({ report, values, programs })
        this._asyncRequest = null
      })
  }

  handleProgramsClick = (key) => {
    const programs = update(this.state.programs, {
      [key]: {
        show: {
          $set: !this.state.programs[key].show
        }
      }
    })

    this.setState(prevState => ({
      programs: programs
    }))
  }

  handleSubmit = (e) => {
    if (this._canSubmit()) {
      this.setState({ send: true, save: false })
      this._asyncRequest = axios.CancelToken.source()

      axios({
        method: 'patch',
        url: path('report_path', { id: this.props.id }, true),
        cancelToken: this._asyncRequest.token,
        data: { report: this.state.values }
      }).then(
        res => {
          this.setState({ send: false, save: true })
        }
      ).catch(_error => {
        this.setState({ send: false })
      }).then(() => {
        this._asyncRequest = null
      })
    }

    if (e) e.preventDefault()
  }

  handleReady = e => {
    this.setState(state => ({
      values: { ...state.values, state: 'archived' }
    }), () => {
      this.handleSubmit()
    })
  }

  _canSubmit = () => {
    return (
      !!this.state.report &&
      !this.state.send
    )
  }

  render () {
    const { report, values, programs } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={path('reports_path')}>{I18n.t('reports.title')}</Link>
          </Loaf>

          {report &&
            <div className={page.title}>
              <h1>
                {report.title}
              </h1>

              <Helmet>
                <title>{report.title}</title>
              </Helmet>

              <p>
                <a rel="noopener noreferrer" target="_blank" href="https://docs.google.com/document/d/1TtrIyABGutU83ZhmZ2v0kpxtgUwPWZfrNJqIpQmJ70o/edit">Методические рекомендации и подробное описание полей</a>
              </p>

              {/*<p>
                <a target="_blank" href="/uploads/universities/form_2018.pdf">Версия формы для печати (PDF)</a>. Обратите внимание что раздел 12 выведен только для одного направления, а в электронном виде нужно заполнять каждое направление.
              </p>*/}
            </div>
          }

          {values &&
            <div className={styles.root}>
              <Group
                report={report}
                group="numbers"
                num={1}
                fields={{
                  total: 1,
                  d: 2,
                  e: 3,
                  h: 4,
                  mm: 5,
                  mn: 6,
                  s: 7,
                  p: 8,
                  o: 9,
                  pe: 10
                }}
                values={values.stats.numbers}
              />

              <Group
                report={report}
                group="finance"
                num={2}
                fields={{
                  bbs: 1,
                  tech: 2,
                  empl: 3,
                  meth: 4
                }}
                values={values.stats.finance}
              />

              <Group
                report={report}
                group="docs"
                num={3}
                fields={{
                  ws: 1,
                  pol: 2,
                  pas: 3,
                  ev: 4
                }}
                values={values.stats.docs}
              />

              <Group
                report={report}
                group="employees"
                num={4}
                fields={{
                  ofc: 1,
                  resp: 2,
                  pp: '3.1',
                  sp: '3.2',
                  spt: '3.3',
                  po: '3.4',
                  pry: '3.5',
                  tp: '3.6',
                  t: '3.7',
                  as: '3.8',
                  o: '3.9',
                  ag: 4,
                  pps: 5,
                  pov: 6
                }}
                values={values.stats.employees}
              />

              <Group
                report={report}
                group="environment"
                num={5}
                fields={{
                  bld: 1,
                  bb_e_y: '2.1',
                  bb_e_p: '2.2',
                  bb_e_n: '2.3',
                  bb_h_y: '2.4',
                  bb_h_p: '2.5',
                  bb_h_n: '2.6',
                  bb_m_y: '2.7',
                  bb_m_p: '2.8',
                  bb_m_n: '2.9',
                  br: 3,
                  pib: 4,
                  bfh_b: '5.1',
                  bfh_d: '5.2',
                  bfh_p: '5.3',
                  bfh_o: '5.4',
                  pns: 6,
                  wfh: 7,
                  lib_e: '8.1',
                  lib_h: '8.2',
                  lib_m: '8.3'
                }}
                values={values.stats.environment}
              />

              <Group
                report={report}
                group="technical"
                num={6}
                fields={{
                  ob_e: 1,
                  ob_e_vl: '1.1',
                  ob_e_blt: '1.2',
                  ob_e_bli: '1.3',
                  ob_e_o: '1.4',
                  ob_h: 2,
                  ob_h_iis: '2.1',
                  ob_h_o: '2.2',
                  ob_m: 3,
                  ob_m_ct: '3.1',
                  ob_m_ss: '3.2',
                  ob_m_av: '3.3',
                  ob_m_o: '3.4',
                  mad_e: 4,
                  mad_e_vl: '4.1',
                  mad_e_sz: '4.2',
                  mad_e_tc: '4.3',
                  mad_e_bld: '4.4',
                  mad_e_pea: '4.5',
                  mad_e_daisy: '4.6',
                  mad_e_o: '4.7',
                  mad_h: 5,
                  mad_h_ha: '5.1',
                  mad_h_o: '5.2',
                  mad_mm: 6,
                  mad_mn: 7
                }}
                values={values.stats.technical}
              />

              <Group
                report={report}
                group="prof"
                num={7}
                fields={{
                  w: 1,
                  wc: 2,
                  ori_dod: '3.1',
                  ori_incon: '3.2',
                  ori_gcon: '3.3',
                  ori_pst: '3.4',
                  ori_cncl: '3.5',
                  ori_o: '3.6',
                  por_e: '4.1',
                  por_h: '4.2',
                  por_m: '4.3',
                  por_o: '4.4',
                  por_sch: '4.5',
                  spo_n: 5,
                  spo_p: '5.1'
                }}
                values={values.stats.prof}
              />

              <Group
                report={report}
                group="adaptation"
                num={8}
                fields={{
                  count: 1,
                  count_e: '1.1',
                  count_h: '1.2',
                  count_m: '1.3',
                  count_o: '1.4',
                  nal_mod: '2.1',
                  nal_ch: '2.2',
                  nal_in: '2.3',
                  nal_prc: '2.4',
                  nal_int: '2.5',
                  meth: 3,
                  meth_count: '3.1'
                }}
                values={values.stats.adaptation}
              />

              <Group
                report={report}
                group="quality"
                num={9}
                fields={{
                  pps: 1,
                  med: 2,
                  t: 3,
                  srs: 4,
                  o: 5,
                  fin: 6,
                  vol: 7,
                  sprt: 8
                }}
                values={values.stats.quality}
              />

              <Group
                report={report}
                group="employment"
                num={10}
                fields={{
                  ofc: 1,
                  prg: 2,
                  py: 3,
                  pyw: 4,
                  pyd: 5,
                  pydw: 6,
                  pydwc: 7,
                  pydws: 8,
                  pydc: 9
                }}
                values={values.stats.employment}
              />

              <Group
                report={report}
                group="post"
                num={11}
                fields={{
                  prg: 1,
                  cnt: 2,
                  ovall: 3
                }}
                values={values.stats.post}
              />

              <div className={styles.group}>
                <div className={styles.title}>
                  12. Контингент студентов
                  <div>
                    <Link to={path('edit_report_path', { id: report.id, query: { section: 'programs' } })}>Редактировать данные</Link>
                  </div>
                </div>
                <div className={styles.content}>
                  {report &&
                    <div className={styles.programs}>
                      {programs.map((program, _) =>
                        <div key={program.id} className={styles.program}>
                          <div className={styles.programTitle} onClick={() => this.handleProgramsClick(_)}>
                            {program.title}
                          </div>

                          {program.show &&
                            <>
                              <div className={styles.progrma_item_header}>
                                <div className={styles.program_item_label}></div>

                                <div className={styles.program_item_value}>Очная</div>
                                <div className={styles.program_item_value}>Заочная</div>
                                <div className={styles.program_item_value}>Оч.-зао.</div>
                              </div>

                              {program.fields && program.fields.map((field, _) =>
                                <div key={field} className={styles.program_item}>
                                  <div className={styles.program_item_label}>
                                    {I18n.t(`report.labels.programs.${field}`)}
                                  </div>

                                  <div className={styles.program_item_value}>
                                    {typeof program.values[`${field}_f`] === 'boolean' ? (program.values[`${field}_f`] ? 'Да' : 'Нет') : program.values[`${field}_f`]}
                                  </div>

                                  <div className={styles.program_item_value}>
                                    {typeof program.values[`${field}_p`] === 'boolean' ? (program.values[`${field}_p`] ? 'Да' : 'Нет') : program.values[`${field}_p`]}
                                  </div>

                                  <div className={styles.program_item_value}>
                                    {typeof program.values[`${field}_fp`] === 'boolean' ? (program.values[`${field}_fp`] ? 'Да' : 'Нет') : program.values[`${field}_fp`]}
                                  </div>
                                </div>
                              )}
                            </>
                          }
                        </div>
                      )}
                    </div>
                  }
                </div>
              </div>
            </div>
          }
          {values && values.state !== 'archived' &&
            <>
              <p>
                Заполненную форму не нужно заверять, подписывать и прикладывать подписанный скан. Достаточно заполнить ее в цифровом виде. После того как вы заполните все поля и сохраните введенные данные, нажмите на кнопку «Завершить заполнение». После этого форма будет помечена как готовая и будет участвовать в обработке результатов.
              </p>

              <p>
                <button className={buttons.main} onClick={this.handleReady}>
                  Завершить заполнение
                </button>
              </p>
            </>
          }

          {values && values.state === 'archived' &&
            <p>
              Форма отмечена как готовая и будет участвовать в обработке результатов. Спасибо что приняли участие в мониторинге.
            </p>
          }
        </div>
      </div>
    )
  }
}

class Group extends Component {
  render () {
    const { report, group, num, fields, values } = this.props
    return (
      <div className={styles.group}>
        <div className={styles.title}>
          {num}. {I18n.t(`report.labels.${group}.group`)}
          {report.can_edit &&
            <div>
              <Link to={path('edit_report_path', { id: report.id, query: { section: group } })}>Редактировать данные</Link>
            </div>
          }
        </div>
        {values &&
          <div className={styles.content}>
            {Object.entries(fields).map((ob, _) =>
              <Item key={ob[0]} group={group} num={`${num}.${ob[1]}.`} field={ob[0]} value={values[ob[0]]} />
            )}
          </div>
        }
      </div>
    )
  }
}

class Item extends Component {
  render () {
    const { value, field, num, group } = this.props

    return (
      <div className={styles.item}>
        <div className={styles.label}>
          {num} {I18n.t(`report.labels.${group}.${field}`)}
        </div>

        <div className={styles.value}>
          {typeof value === 'boolean' ? (value ? 'Да' : 'Нет') : value}
        </div>
      </div>
    )
  }
}

export default Show
