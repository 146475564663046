import React, { Component } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import qs from 'query-string';
// import * as V from 'victory';
// import { VictoryPie } from 'victory';

import { path } from '../../Routes';
import Compare from '../Compare';
import Loaf from '../../Loaf/Loaf';
import Reports from './Reports';
import Finance from './Finance';
import Numbers from './Numbers';
import Docs from './Docs';
import Employees from './Employees';
import Environment from './Environment';
import Technical from './Technical';

import styles from './Schools.module.css';
import page from '../../Page.module.css';

class Index extends Component {
  state = {
    search: this.props.location.search
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.summary === null) {
      this._loadAsyncData();
    }
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('stats_schools_path', { id: this.state.id }, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.state.search)})
      .then(res => {
        this.setState({
          monitorings: res.data.monitorings,
          summary: res.data.summary,
          finance: res.data.finance,
          numbers: res.data.numbers,
          employees: res.data.employees,
          environment: res.data.environment,
          docs: res.data.docs,
          technical: res.data.technical,
        });

        this._asyncRequest = null;
      });
  }

  static getDerivedStateFromProps(props, state) {
    if(state.id != props.match.params.id || props.location.search !== state.search) {
      return {
        id: props.match.params.id,
        search: props.location.search,
        summary: null
      }
    }

    return null;
  }

  render() {
    const { summary, id, finance, numbers, docs, employees, environment, technical } = this.state;
    // if(!summary) return null;


    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('stats_path', { id })}>Весь отчёт</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Мониторинг вузов {id}
            </h1>


            {/*
              <div>
                <Link to={`${this.props.location.pathname}?${qs.stringify({ prev: 4 })}`}>
                  2017
                </Link>
              </div>
            */}

            <Helmet>
              <title>
                Мониторинг вузов {id}
              </title>
            </Helmet>
          </div>

          <Compare search={this.state.search} history={this.props.history} location={this.props.location} types={['schools', 'regions', 'districts']}/>


          {summary &&
            <div className={styles.root}>
              <div className={styles.reports}>
                <Reports reports={summary.reports} />
              </div>

              <div className={styles.finance}>
                <Finance data={finance} />
              </div>

              <div className={styles.numbers}>
                <Numbers data={numbers} />
              </div>

              <div className={styles.docs}>
                <Docs data={docs} />
              </div>

              <div className={styles.employees}>
                <Employees data={employees} />
              </div>

              <div className={styles.environment}>
                <Environment data={environment} />
              </div>

              <div className={styles.technical}>
                <Technical data={technical} />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Index;
