import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

import Link, { path } from '../Routes'
import I18n from '../I18n'
import Loaf from '../Loaf/Loaf'

import Events from '../Events/Block'
// import Facilities from './Show/Facilities'

import page from '../Page.module.css'
import styles from './Show.module.css'
import buttons from '../Buttons.module.css'

export default function Show () {
  const { id } = useParams()
  const cancelToken = useRef(axios.CancelToken.source())
  const [rumc, setRumc] = useState()
  const [events, setEvents] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('rumc_path', { id }, true), { cancelToken: cancelToken.current.token })

      setRumc(data.rumc)
      setEvents(data.events)
    }

    _fetch()

    return () => {
      cancelToken.current.cancel()
    }
  }, [id])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="rumcs_path">{I18n.t('nav.rumcs')}</Link>
        </Loaf>

        {rumc &&
          <div className={styles.root}>
            <div className={page.title}>
              <h1>
                {rumc.title}
              </h1>

              <Helmet>
                <title>{rumc.title}</title>
              </Helmet>

              <p area-hidden="true" className={styles.h1p}>
                Ресурсный учебно-методический центр
              </p>
            </div>

            <div className={styles.main}>
              <div className={styles.contacts}>
                <div className={styles.address}>
                  Адрес: {rumc.address}
                </div>
                <div className={styles.phone}>
                  Телефон: {rumc.phone}
                </div>
                <div className={styles.email}>
                  Почта: <a href={`mailto:${rumc.email}`} target="_blank" rel="noopener noreferrer">{rumc.email}</a>
                </div>
                <div className={styles.url}>
                  Сайт: <Url source={rumc.url} />
                </div>
              </div>

              {/* <div className={styles.regions}>
                Закреплённая за ресурсным центром территория: {rumc.regions.length > 50 ? 'все регионы России' : rumc.regions.map(region => region.title).join(', ')}.
              </div> */}
              {rumc.id != 17 &&
                <div className={styles.regions}>
                  Закреплённая за ресурсным центром территория: {rumc.regions.length > 50 ? 'все регионы России' : rumc.regions.map(region => region.title).join(', ')}.
                </div>
              }
              {rumc.id == 17 &&
                <div className={styles.regions}>
                  Закреплённые вузы: отраслевые образовательные организации высшего образования, подведомственные Минпросвещения России.
                </div>
              }
            </div>

            {events &&
              <div className={styles.events}>
                <Events events={events} count={4} />
              </div>
            }

            <div className={styles.facilities}>
              <h2>
                Реестр технических средств
              </h2>

              <p>
                В распоряжении ресурсных центров находится оборудование для организации процесса обучения, которое может быть передано в пользование вузам.
                Подготовлены видеоинструкции по эксплуатации технических средств.
              </p>

              <Link className={buttons.main} to="rumc_facilities_path" params={{ rumc_id: rumc.id }}>
                Оборудование и инструкции
              </Link>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

Url.propTypes = {
  source: PropTypes.string
}

function Url ({ source }) {
  const url = new URL(!/^https?:\/\//i.test(source) ? `http://${source}` : source)

  return (
    <a href={url.toString()} target="_blank" rel="noopener noreferrer">{url.hostname}{url.pathname !== '/' && url.pathname}</a>
  )
}
