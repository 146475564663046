import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import { Routes, path } from '../Routes';

import Loaf from '../Loaf/Loaf';

import form from '../Form.module.css';
import buttons from '../Buttons.module.css';
import page from '../Page.module.css';

class Form extends React.Component {
  state = {
    send: false,
    event_visit: null,
    dictionaries: null,
    audience: null,
    values: null,
    submit: {
      url: path('event_event_visits_path', { event_id: this.props.event_id }, true),
      method: 'post',
    },
    redirect_to: null
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(id ? path('edit_event_event_visit_path', { event_id: this.props.event_id, id: id }, true) : path('new_event_event_visit_path', { event_id: this.props.event_id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          event_visit: res.data.event_visit,
          dictionaries: res.data.dictionaries,
          values: res.data.values,
        });

        if(id) {
          this.setState({
            dictionaries: res.data.dictionaries,
            values: res.data.values,
            submit: {
              url:  path('event_event_visit_path', { event_id: this.props.event_id, id: id }, true),
              method: 'patch'
            }
          });
        }

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }));
  }

  handleSubmit = (e) => {
    if(this._canSubmit()) {
      this.setState({ send: true });
      this._asyncRequest = axios.CancelToken.source();

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        cancelToken: this._asyncRequest.token,
        data: { event_visit: this.state.values }
      }).then(
        res => {
          this.setState({
            redirect_to: path('event_event_visits_path', { event_id: this.props.event_id })
          });
        }
      ).catch((error) => {
        console.log(error);
        this.setState({ send: false });
      }).then(() => {
        this._asyncRequest = null;
      });
    }

    e.preventDefault();
  }

  _loadSchools = (input, callback) => {
    callback(this.state.dictionaries.schools.filter(school => school.title.toLowerCase().includes(input.toLowerCase())).slice(0, 100));
  };

  _canSubmit = () => {
    return (
      this.state.values.name &&
      this.state.values.audience_ids &&
      (this.state.values.region_id || this.state.values.region_id == 0) &&
      ((this.state.audience == 'School' && this.state.values.school_id) || this.state.audience != 'School') &&
      ((this.state.audience == 'Rumc' && this.state.values.rumc_id) || this.state.audience != 'Rumc') &&
      !this.state.send
    );
  }

  render() {
    if(this.state.redirect_to) return <Redirect to={this.state.redirect_to} push />
    const { I18n } = this.props;
    const { event_visit, values, dictionaries, audience } = this.state;

    if(!values) return null;
    const title = event_visit ? 'Редактирование участника' : 'Новый участник';


    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>Кабинет</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {title}
            </h1>

            <Helmet>
              <title>
                {title}
              </title>
            </Helmet>
          </div>

          <div className={page.form}>
            <form className={form.root} onSubmit={this.handleSubmit}>
              <div className={form.elem}>
                <div className={form.label}>
                  ФИО
                </div>

                <div className={form.input}>
                  <input type="text" name="name" value={values.name} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={form.elem}>
                <div className={form.label}>
                  Регион
                </div>

                <div className={form.input}>
                  <Select
                    classNamePrefix="react-select"
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    options={dictionaries.regions}
                    value={dictionaries.regions.find(region => region.id == this.state.values.region_id)}
                    placeholder="Выберите регион.."
                    onChange={value => this.setState(state => ({ values: { ...state.values, region_id: value.id } }))}
                  />
                </div>
              </div>

              <div className={form.elem}>
                <div className={form.label}>
                  Категория участника
                </div>

                <div className={form.input}>
                  <Select
                    classNamePrefix="react-select"
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    options={dictionaries.audiences}
                    value={dictionaries.audiences.find(audience => audience.id == this.state.values.audience_ids)}
                    placeholder="Выберите категорию участника.."
                    onChange={value => this.setState(state => ({ audience: value.model, values: { ...state.values, audience_ids: value.id } }))}
                  />
                </div>
              </div>


              {audience == 'Rumc' &&
                <div className={form.elem}>
                  <div className={form.label}>
                    Ресурсный учебно-методический центр
                  </div>

                  <div className={form.input}>
                    <Select
                      classNamePrefix="react-select"
                      getOptionLabel={option => option.title}
                      getOptionValue={option => option.id}
                      options={dictionaries.rumcs}
                      value={dictionaries.rumcs.find(rumc => rumc.id == this.state.values.rumc_id)}
                      placeholder="Выберите РУМЦ.."
                      onChange={value => this.setState(state => ({ values: { ...state.values, rumc_id: value.id } }))}
                    />
                  </div>
                </div>
              }

              {audience == 'School' &&
                <div className={form.elem}>
                  <div className={form.label}>
                    Вуз
                  </div>

                  <div className={form.input}>
                    <AsyncSelect
                      classNamePrefix="react-select"
                      getOptionLabel={option => option.title}
                      getOptionValue={option => option.id}
                      loadOptions={this._loadSchools}
                      placeholder="Выберите вуз.."
                      value={dictionaries.schools.find(school => school.id == this.state.values.school_id)}
                      onChange={value => this.setState(state => ({ values: { ...state.values, school_id: value.id } }))}
                      noOptionsMessage={({inputValue}) => inputValue == '' ? 'Введите часть названия вуза' : 'Нет доступных результатов'}
                    />
                  </div>

                  <div className={form.hint}>
                    Начните вводить название вуза чтобы отобразились доступные варианты. Количество результатов ограничено 100 вузами.
                  </div>
                </div>
              }

              <div className={form.submit}>
                <input type="submit" value="Сохранить" className={buttons.main} disabled={!this._canSubmit()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

//

export default Form
