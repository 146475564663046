import React, { Component } from 'react'

import Index from './Index'
import Archive from './Archive'
import ArchiveRUMC from './ArchiveRUMC'
import Show from './Show'

import EventReports from '../EventReports/EventReports'
// import EventVisits from '../EventVisits/EventVisits'

// import RegistrationForm from '../Registration/RegistrationForm'
// import RegistrationIndex from '../Registration/RegistrationIndex'

import EventRegistrations from '../EventRegistrations/EventRegistrations'

import { Routes } from '../Routes'

import { Switch, Route } from 'react-router-dom'

class Events extends Component {
  render () {

    return (
      <Switch>
        <Route path={decodeURIComponent(Routes.event_event_report_path)} component={EventReports} />
        {/* <Route path={decodeURIComponent(Routes.event_event_visits_path)} component={EventVisits} /> */}
        {/* <Route exact strict path={decodeURIComponent(Routes.events_path) + '/:id(\\d+)/регистрация'} render={props => <RegistrationForm id={props.match.params.id} />} /> */}

        <Route path={decodeURIComponent(Routes.archive_events_path)} component={Archive} />

        <Route path={decodeURIComponent(Routes.archiveRUMC_events_path)} component={ArchiveRUMC} />

        <Route path={decodeURIComponent(Routes.date_events_path) + '(\\d{4}-\\d{2}-\\d{2})'} component={Index} />

        <Route path={Routes.event_event_registrations_path}>
          <EventRegistrations />
        </Route>

        <Route path={Routes.event_path}>
          <Show />
        </Route>

        <Route path={decodeURIComponent(Routes.events_path)} component={Index} exact strict />
      </Switch>
    )
  }
}

export default Events
