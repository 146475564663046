import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import update from 'immutability-helper';

import { path } from '../Routes';

import Icon from '!svg-react-loader!../../images/icons/download.svg';
import AttachmentSection from './AttachmentSection';

import styles from './AttachmentItem.module.css';

class AttachmentItem extends React.Component {
  state = {
    item: this.props.item,
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.onAttachmentUpdate) {
      if (this.state.item !== prevState.item) {
        if(!this.state.item && this.props.onAttachmentDelete) {
          this.props.onAttachmentDelete(this.props.index)
        }
        else if (this.props.onAttachmentUpdate) {
          this.props.onAttachmentUpdate(this.props.index, this.state.item)
        }
      }
    }
  }

  handleSectionChange = (value) => {
    let newState = update(this.state.item, {
      section: {
        $set: this.state.item.section == value ? null : value
      }
    });

    this.setState(prevState => ({
      item: newState
    }));
  }

  handleAttachmentChange = (prop) => (e) => {
    const target = e.target;
    const value = target.value;

    let newState = update(this.state.item, {
      [prop]: {
        $set: value
      }
    });

    this.setState(prevState => ({
      item: newState
    }));
  }

  handleAttachmentDelete = (item) => {
    axios.delete(path('attachments_path', { id: item.id }))

    this.setState(prevState => ({
      item: null,
    }));
  }

  render() {
    let index = this.props.index;

    return (
      <>
      {this.state.item &&
        <div className={styles.attachment}>
          <div className={styles.meta}>
            <div className={styles.sections}>
              {this.props.sections.items &&
                <AttachmentSection item={this.state.item} sections={this.props.sections} index={index} onSectionChange={this.handleSectionChange}/>
              }
            </div>
            <div className={styles.destroy} onClick={e => this.handleAttachmentDelete(this.state.item)}>
              Удалить
            </div>
          </div>





          <div className={styles.info}>
            <div className="input_input">
              <input type="text" name={'attachment_title_' + index } onChange={this.handleAttachmentChange('title')} value={this.state.item.title} />
            </div>
          </div>
        </div>
      }
      {!this.state.item && <p> </p>}
      </>
    )
  }
}

export default AttachmentItem
