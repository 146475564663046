import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../../Routes'

import Index from './Index'
import Show from './Show'
import Review from './Review'
import Form from './Form'

class TargetPrograms extends Component {
  render () {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={decodeURIComponent(Routes.new_account_target_program_path)} component={Form} />
          <Route exact path={decodeURIComponent(Routes.edit_account_target_program_path)} render={props => <Form id={props.match.params.id} {...props} />} />
          <Route path={decodeURIComponent(Routes.review_account_target_program_path)} render={props => <Review id={props.match.params.id} {...props} />} />
          <Route path={decodeURIComponent(Routes.account_target_program_path)} render={props => <Show id={props.match.params.id} />} />
          <Route exact path={decodeURIComponent(Routes.account_target_programs_path)} component={Index}/>
        </Switch>
      </React.Fragment>
    )
  }
}

export default TargetPrograms
