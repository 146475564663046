import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Editor, EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import classNames from 'classnames'

import Files from '../../Attachments/Files'
import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'
import I18n from '../../I18n'

import page from '../../Page.module.css'
import form from '../../Form.module.css'
import styles from './ReportItems.module.css'
import buttons from '../../Buttons.module.css'
import draft from '../../Draft.module.css'

Form.propTypes = {
  id: PropTypes.string
}

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

export default function Form (props) {
  const query = useQuery()

  const [send, setSend] = useState(false)
  const [values, setValues] = useState({ section: query.get('section'), label: query.get('label'), desc: EditorState.createEmpty(), attachment_ids: [], attachments: [] })
  const [errors, setErrors] = useState({})
  const { id } = props

  const history = useHistory()

  const handleEditorChange = editorState => {
    setValues({ ...values, desc: editorState })
  }

  useEffect(() => {
    const _loadAsyncData = async () => {
      const { data: { values } } = await axios.get(id ? path('edit_account_report_item_path', { id: id }, true) : path('new_account_report_item_path', {}, true))
      // console.log(222, values)
      if (id) {
        setValues({ ...values, desc: values.desc ? EditorState.createWithContent(convertFromRaw(values.desc)) : EditorState.createEmpty() })
      }
    }

    _loadAsyncData()
  }, [id])

  const handleFilesChanged = files => {
    setValues({
      ...values,
      attachments_attributes: [...files.values()].map(file => ({ id: file.id, title: file.title, section: file.section })),
      attachment_ids: [...files.values()].map(file => file.id)
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    const params = {
      report_item: {
        ...values,
        desc: values.desc.getCurrentContent().hasText() ? convertToRaw(values.desc.getCurrentContent()) : null
      }
    }

    if (id) {
      _handleUpdate(params)
    } else {
      _handleCreate(params)
    }
  }

  const _handleCreate = async params => {
    await axios.post(
      path('account_report_items_path'),
      params
    ).then(res => {
      history.push(res.headers.location)
    }).catch((error) => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const _handleUpdate = async params => {
    await axios.patch(
      path('account_report_item_path', { id }),
      params
    ).then(res => {
      history.push(res.headers.location)
    }).catch((error) => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const handleAttachmentsUpdate = (attachments) => {
    setValues({ ...values, attachments: attachments, attachment_ids: attachments.map(a => a.id) })
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">Кабинет</Link>
        </Loaf>

        <form onSubmit={handleSubmit}>
          {values.section && values.label == null &&
            <h2>Отчет &quot;{I18n.t(`report_items.sections.${values.section}`)}&quot;</h2>
          }
          {values.section && values.label &&
            <h2>Отчет &quot;{I18n.t(`account.rumc.target.${values.section}.${values.label}.title`)}&quot;</h2>
          }


          <div className={styles.form}>
            <div className={form.el}>
              <div className={draft.textarea}>
                <label>
                  <div className={form.input}>
                    <Editor
                      editorState={values.desc}
                      onChange={handleEditorChange}
                    />
                  </div>
                </label>
              </div>
            </div>

            <Files
              files={values.attachments}
              onFilesChanged={handleFilesChanged}
              sections={[]}
            />

            <div className={form.submit}>
              <input type="submit" value="Сохранить" className={classNames(buttons.main, { [buttons.progress]: send })} disabled={send} />
            </div>
          </div>
        </form>
      </div>
    </div>
  )

}
