import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import AsyncSelect from 'react-select/async'

import Link, { path } from '../Routes'
import I18n from '../I18n'

import Current from '../Current'
import Loaf from '../Loaf/Loaf'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import styles from './Monitoring.module.css'

export default function Monitoring () {
  const { user } = useContext(Current)
  const cancelToken = axios.CancelToken.source()

  const [schools, setSchools] = useState()
  const [usersRoles, setUsersRoles] = useState()
  const [exist, setExist] = useState(false)
  const [send, setSend] = useState(false)
  const [school, setSchool] = useState(null)

  const _fetch = async () => {
    const { data } = await axios.get(path('universities_monitoring_path', {}, true), { cancelToken: cancelToken.token })

    setSchools(data.schools)
    setUsersRoles(data.users_roles)
  }

  useEffect(() => {
    _fetch()

    return function cleanup () {
      cancelToken.cancel()
    }
  }, [])

  const _loadSchools = (input, callback) => {
    callback(schools.filter(school => school.title.toLowerCase().includes(input.toLowerCase())).slice(0, 100))
  }

  const _handleSubmit = () => {
    if (school && !send) {
      setSend(true)

      axios.post(path('role_school_path', { id: school.id }, true), { cancelToken: cancelToken.token })
        .then(
          res => {
            if (res.status === 200) {
              setExist(true)
            }
            _fetch()
            setSchool(null)
          }
        ).catch((error) => {
          console.log(error)
        }).then(() => {
          setSend(false)
        })
    }
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="universities_path">{I18n.t('nav.universities')}</Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('nav.universities_monitoring')}
          </h1>

          <Helmet>
            <title>{I18n.t('nav.universities_monitoring')}</title>
          </Helmet>
        </div>

        <div className={page.text}>
          

          {/* 2021
          <div className={styles.files}>
            <a href="/uploads/universities/monitoring_ivo_2021.pdf" target="_blank">Письмо о проведении мониторинга <span>от 01.11.2021 № МН-7/9344</span></a>
            <a href="/uploads/universities/METHODOLOGICAL_RECOMMENDATIONS_mon_ivo_2021.pdf" target="_blank" rel="noopener noreferrer">Методические рекомендации по заполнению мониторинга</a>
          </div> 
          */}
          <a href='https://youtu.be/S75FiDQRsK8' target="_blank" rel="noopener noreferrer" className={styles.flex_row}>
            <div className={styles.round} />
            <div>Смотреть запись вебинара</div>
          </a>

          <div className={styles.files}>
            <a href="https://ceph.miccedu.ru/incedu/uploads/files/monitoring_2022/Otvetyana_voprosy_k_vebinaru_po_forme_monitoringa_IVO.pdf" target="_blank" rel="noopener noreferrer">Ответы на вопросы по итогам вебинара</a>
            <a href="https://ceph.miccedu.ru/incedu/uploads/files/monitoring_2022/Prezentaciya_na_vebinar_po_monitoringu_IVO.pdf" target="_blank" rel="noopener noreferrer">Презентационные материалы</a>
          </div>

          <div className={styles.files}>
            <a href="https://ceph.miccedu.ru/incedu/uploads/files/monitoring_2022/Pismo_o_provedenii_monitoringa_IVO_za_2022_MN_7_6071.pdf" target="_blank" rel="noopener noreferrer">Письмо о проведении мониторинга от 11.11.2022 №МН-7/6071</a>
            <a href="https://ceph.miccedu.ru/incedu/uploads/files/monitoring_2022/Metodicheskie_rekomendacii_po_zapolneniyu_formy_Monitoringa_IVO_2022.pdf" target="_blank" rel="noopener noreferrer">Методические рекомендации по заполнению формы мониторинга</a>
          </div>

          <p>
            Мониторинг деятельности образовательных организаций высшего образования по вопросам приема, обучения и трудоустройства лиц с инвалидностью и ограниченными возможностями здоровья проводится  на сайте <a href="http://stat.miccedu.ru" target="_blank" rel="noopener noreferrer">stat.miccedu.ru</a> в личных кабинетах вузов.
          </p>
        </div>
      </div>
    </div>
  )
}
