import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { DateTime } from 'luxon'

import Link, { path } from '../../Routes'

import Loaf from '../../Loaf/Loaf'
import Files from '../../Attachments/Files'
import Items from '../MediaItems/Items'

import page from '../../Page.module.css'
import form from '../../Form.module.css'
import buttons from '../../Buttons.module.css'
import tabs from '../../Tabs.module.css'
import draft from '../../Draft.module.css'

class Form extends Component {
  state = {
    attachments: [],
    media_items: [],
    values: null,
    errors: {},
    loading: false,
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  _urlAsyncData () {
    return this.props.id ? path('edit_account_event_path', { id: this.props.id }, true) : path('new_account_event_path', {}, true)
  }

  _loadAsyncData = async () => {
    const res = await axios(this._urlAsyncData())

    if (!res.data.values.timezone) {
      res.data.values.timezone = new Date().getTimezoneOffset() / 60 * -1
    }

    if (res.data.values.started_at) {
      let currentZone = new Date().getTimezoneOffset() / 60 * -1
      res.data.values.started_at = DateTime.fromISO(res.data.values.started_at).setZone(`UTC+${res.data.values.timezone}`).setZone(`UTC+${currentZone}`, { keepLocalTime: true }).toJSDate()
    }

    res.data.values.registration = dayjs(res.data.values.registration)
    res.data.values.registration = res.data.values.registration.isValid() ? res.data.values.registration.toDate() : ''

    if (res.data.values.intro) {
      res.data.values.intro = EditorState.createWithContent(convertFromRaw(res.data.values.intro))
    } else {
      res.data.values.intro = EditorState.createEmpty()
    }

    this.setState({ ...res.data })
  }

  handleEditorChange = (editorState) => {
    this.setState(prevState => ({
      values: { ...prevState.values, intro: editorState }
    }))
  }

  handleSubmit = (e) => {
    if (this.props.id) {
      this._handleUpdate()
    } else {
      this._handleCreate()
    }
    e.preventDefault()
  }

  _handleUpdate = async () => {
    this.setState({loading: true});
    await axios.patch(
      path('account_event_path', { id: this.props.id }),
      { event: {
        ...this.state.values, intro: convertToRaw(this.state.values.intro.getCurrentContent()), started_at: DateTime.fromMillis(Date.parse(this.state.values.started_at)).setZone(`UTC+${this.state.values.timezone}`, { keepLocalTime: true }).toJSDate()
      } }
    ).then(
      res => {
        this.props.history.push(res.headers.location)
      }
    ).catch((error) => {
      if (error.response.data) {
        document.getElementById(Object.keys(error.response.data)[0]).scrollIntoView({behavior: 'smooth'});
      }
      this.setState({
        errors: error.response.data
      })
    })
    this.setState({loading: false});
  }

  _handleCreate = async () => {
    this.setState({loading: true});
    await axios.post(
      path('account_events_path'),
      { event: {
        ...this.state.values, intro: convertToRaw(this.state.values.intro.getCurrentContent()), started_at: DateTime.fromMillis(Date.parse(this.state.values.started_at)).setZone(`UTC+${this.state.values.timezone}`, { keepLocalTime: true }).toJSDate()
      } }
    ).then(
      res => {
        this.props.history.push(res.headers.location)
      }
    ).catch((error) => {
      if (error.response.data) {
        document.getElementById(Object.keys(error.response.data)[0]).scrollIntoView({behavior: 'smooth'});
      }
      this.setState({
        errors: error.response.data
      })
    })
    this.setState({loading: false});
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }))
  }

  handleBooleanChange = (e) => {
    const target = e.target
    const value = target.checked
    const name = target.name

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }))
  }

  handleRadioChange = (e) => {
    const target = e.target
    const value = target.value

    this.setState(prevState => ({
      values: { ...prevState.values, registrable: value }
    }))
  }

  handleFilesChanged = files => {
    this.setState(prevState => ({
      values: { ...prevState.values,
        attachments_attributes: [...files.values()].map(file => ({ id: file.id, title: file.title, section: file.section })),
        attachment_ids: [...files.values()].map(file => file.id)
      }
    }))
  }

  handleItemsChanged = items => {
    this.setState(prevState => ({
      values: { ...prevState.values,
        media_items_attributes: [...items.values()].map(item => (item))
      }
    }))
  }

  handleSectionChange = (value) => {
    this.setState(prevState => ({
      values: { ...prevState.values, section: value }
    }))
  }

  handleRegionsChange = (selected) => {
    this.setState(prevState => ({
      values: { ...prevState.values, region_ids: selected ? selected.map(s => s.value) : '' }
    }))
  }

  handleAudienceChange = (item) => {
    if (this.state.values.audience_ids.includes(item)) {
      this.setState(prevState => ({
        values: { ...prevState.values, audience_ids: [...prevState.values.audience_ids.filter(id => id !== item)] }
      }))
    } else {
      this.setState(prevState => ({
        values: { ...prevState.values, audience_ids: [ ...prevState.values.audience_ids, item ] }
      }))
    }
  }

  selectAllRegions = () => {
    if (this.state.values.region_ids?.length == this.state.dictionaries.regions.length) {
      this.setState(state => ({ values: { ...state.values, region_ids: [] } }))
      document.getElementById('regions').scrollIntoView({behavior: 'smooth'});
    } else {
      this.setState(state => ({ values: { ...state.values, region_ids: this.state.dictionaries.regions.map(v => v.id) } }))
    }
  }

  render () {
    const { values, dictionaries, errors, loading } = this.state

    if (!values) return null

    const title = values.title.length > 0 ? values.title : 'Новое событие'

    let sections = ['event_program']

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">Кабинет</Link>
            <Link to="account_events_path">События</Link>
          </Loaf>
          <div className={page.title}>
            <h1>
              {title}
            </h1>
            <Helmet>
              <title>
                {title}
              </title>
            </Helmet>
          </div>

          <div className={form.tight}>
            <form onSubmit={this.handleSubmit}>
              <div className={form.el}>
                <label>
                  <div className={form.label} id="title">
                    Заголовок события*
                  </div>

                  <div className={form.input}>
                    <input type="text" name="title" onChange={this.handleInputChange} value={values.title}/>
                  </div>
                </label>

                {errors.title &&
                  <Errors errors={errors.title}/>
                }

                <div className={form.hint}>
                  Запрещено писать целиком заглавными буквами! ЭТО НАРУШЕНИЕ ПРАВИЛ РУССКОГО ЯЗЫКА!!!
                </div>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label} id="started_at">
                    Локальные время и дата начала события*
                  </div>

                  <div className={form.datetime}>
                    <div className={form.input}>
                      <DatePicker
                        className={form.date}
                        selectsStart
                        selected={values.started_at}
                        onChange={(date) => {
                          if (date.getHours() === 0) {
                            date.setHours(9)
                          }
                          this.setState(state => ({ values: { ...state.values, started_at: date } }))
                        }}
                        dateFormat="dd.MM.yyyy"
                      />
                    </div>

                    {values.started_at &&
                      <>
                        <div className={form.input}>
                          <select
                            value={dayjs(values.started_at).hour()}
                            onChange={(e) => {
                              const value = e.target.value
                              this.setState(state => ({ values: { ...state.values, started_at: dayjs(state.values.started_at).hour(value).toDate() } }))
                            }}
                          >
                            <option value={7}>07:00</option>
                            <option value={8}>08:00</option>
                            <option value={9}>09:00</option>
                            <option value={10}>10:00</option>
                            <option value={11}>11:00</option>
                            <option value={12}>12:00</option>
                            <option value={13}>13:00</option>
                            <option value={14}>14:00</option>
                            <option value={15}>15:00</option>
                            <option value={16}>16:00</option>
                            <option value={17}>17:00</option>
                            <option value={18}>18:00</option>
                            <option value={19}>19:00</option>
                            <option value={20}>20:00</option>
                            <option value={21}>21:00</option>
                          </select>
                        </div>

                        <div className={form.input}>

                          <select
                            disabled
                            value={values.timezone}
                            onChange={(e) => {
                              const value = e.target.value
                              this.setState(state => ({ values: { ...state.values, timezone: value } }))
                            }}>
                            <option value={2}>+2 Калининград</option>
                            <option value={3}>Москва</option>
                            <option value={4}>+4 Самара</option>
                            <option value={5}>+5 Екатеринбург</option>
                            <option value={6}>+6 Омск</option>
                            <option value={7}>+7 Красноярск</option>
                            <option value={8}>+8 Иркутск</option>
                            <option value={9}>+9 Якутск</option>
                            <option value={10}>+10 Владивовсток</option>
                            <option value={11}>+11 Магадан</option>
                            <option value={12}>+12 Петропавловск-Камчатский.</option>
                          </select>

                        </div>
                      </>
                    }
                  </div>
                </label>

                {errors.started_at &&
                  <Errors errors={errors.started_at}/>
                }

                <div className={form.hint}>
                  Выберите дату, а затем время проведения события. Часовой пояс будет выбран автоматически на основании вашего местоположения.
                  <br />
                </div>
              </div>

              <div className={form.el}>
                <div className={form.label} id="section">
                  <label>
                    Тип события*
                  </label>
                </div>

                <div className={tabs.root}>
                  {Object.entries(dictionaries.sections).map((tab, _) =>
                    <div key={_} className={classNames([tabs.tab], { [tabs.active]: values.section === tab[0] })} onClick={() => this.handleSectionChange(tab[0])}>
                      {tab[1]}
                    </div>
                  )}
                </div>

                {errors.section &&
                  <Errors errors={errors.section}/>
                }
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label} id="regions">
                    Регионы события
                  </div>
                  {dictionaries.regions &&
                    <>
                       
                      <div className={form.input}>
                        <Select
                          value={dictionaries.regions.filter(region => values.region_ids.includes(region.id))}
                          classNamePrefix="react-select"
                          isMulti
                          getOptionLabel={option => option.title}
                          getOptionValue={option => option.id}
                          options={dictionaries.regions}
                          placeholder="Выберите регионы.."
                          onChange={value => this.setState(state => ({ values: { ...state.values, region_ids: value ? value.map(v => v.id) : [] } }))}
                        />
                      </div>

                      <div className={form.checkbox}>
                        <label>
                          <input type="checkbox" checked={this.state.values.region_ids?.length == dictionaries.regions.length} onChange={() => this.selectAllRegions()} />
                          Все регионы
                        </label>
                      </div>
                    </>
                  }
                </label>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label} id="audiences">
                    Аудитория события*
                  </div>

                  {dictionaries.audiences &&
                    <div className={form.input}>
                      {dictionaries.audiences.map(audience =>
                        <div key={audience.id} className={form.checkbox}>
                          <label>
                            <input type="checkbox" name={audience.id} checked={values.audience_ids.includes(audience.id)} onChange={() => this.handleAudienceChange(parseInt(audience.id))} />
                            {audience.title}
                          </label>
                        </div>
                      )}
                    </div>
                  }
                </label>

                {errors.audiences &&
                  <Errors errors={errors.audiences}/>
                }
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                      Анонс
                  </div>

                  {values.text &&
                    <div className={form.input}>
                      <textarea name="text" value={values.text} rows="14" onChange={this.handleInputChange} />
                    </div>
                  }

                  {!values.text &&
                    <div className={form.input}>
                      <div className={draft.textarea}>
                        <Editor
                          editorState={values.intro}
                          onEditorStateChange={this.handleEditorChange}
                          toolbar={{
                            options: [],
                          }}
                          toolbarClassName="toolbar-hide"
                          stripPastedStyles = {true}
                          localization={{
                            locale: 'ru'
                          }}
                        />
                      </div>
                    </div>
                  }
                </label>

                {errors.intro &&
                  <Errors errors={errors.intro}/>
                }

                <div className={form.hint}>
                  Подробный анонс мероприятия.
                </div>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Удалённый формат проведения (вебинар)
                  </div>

                  <div className={form.input}>
                    <div className={form.checkbox}>
                      <label>
                        <input type="checkbox" name='web' checked={values.web} onChange={this.handleBooleanChange} />
                          Да
                      </label>
                    </div>
                  </div>
                </label>
              </div>

              { this.state.values.web &&
                <div className={form.el}>
                  <label>
                    <div className={form.label}>
                      Ссылка на вебинар
                    </div>

                    <div className={form.input}>
                      <input type="text" name="web_url" onChange={this.handleInputChange} value={values.web_url}/>
                    </div>
                  </label>

                  <div className={form.hint}>
                    {/* Ссылка на вебинар на площадке Miropolis VR. После создания мероприятия на площадке, скопируйте содержимое поля "Код для вставки формы регистрации" в данное поле. */}
                    Внимание! Данная ссылка будет отправляться пользователям при регистрации на событие.
                  </div>
                </div>
              }

              {!this.state.values.web &&
                <div className={form.el}>
                  <label>
                    <div className={form.label}>
                      Место проведения
                    </div>

                    <div className={form.input}>
                      <input type="text" name="place" onChange={this.handleInputChange} value={values.place}/>
                    </div>
                  </label>

                  <div className={form.hint}>
                    Адрес обязателен для заполнения если это не вебинар, для вебинаров может отсутствовать
                  </div>
                </div>
              }

              <Items
                items={this.state.media_items}
                onItemsChanged={this.handleItemsChanged}
              />

              <div className={form.el}>
                <div className={form.label}>
                  Включить регистрацию на событие?
                </div>

                <div className={form.input}>
                  <div className={form.checkbox}>
                    <label>
                      <input type="radio" name='registrable_0' checked={values.registrable === 'not'} value="not" onChange={this.handleRadioChange} />
                        Нет
                    </label>
                  </div>
                  <div className={form.checkbox}>
                    <label>
                      <input type="radio" name='registrable_1' checked={values.registrable === 'public'} value="public" onChange={this.handleRadioChange} />
                        Все пользователи
                    </label>
                  </div>
                  <div className={form.checkbox}>
                    <label>
                      <input type="radio" name='registrable_2' checked={values.registrable === 'rumcs'} value="rumcs" onChange={this.handleRadioChange} />
                        Сотрудники ресурсных центров
                    </label>
                  </div>
                </div>
              </div>

              {this.state.values.registrable !== 'not' &&
                <div className={form.el}>
                  <label>
                    <div className={form.label}>
                      Дата и время завершения регистрации
                    </div>

                    <div className={form.datetime}>
                      <div className={form.input}>
                        <DatePicker
                          className={form.date}
                          selectsStart
                          selected={values.registration}
                          onChange={(date) => {
                            if (date.getHours() === 0) {
                              date.setHours(9)
                            }
                            this.setState(state => ({ values: { ...state.values, registration: date } }))
                          }}
                        />
                      </div>
                    </div>
                  </label>

                  {errors.registration &&
                    <Errors errors={errors.registration}/>
                  }
                </div>
              }

              <Files
                files={this.state.attachments}
                onFilesChanged={this.handleFilesChanged}
                sections={sections}
              />

              <div className="input_submit">
                {loading && 
                 <button className={classNames(buttons.main, buttons.loading)} disabled={true} style={{height: '48px'}} />
                }
                {!loading &&
                  <input type="submit" value="Сохранить материал" className={classNames(buttons.main, buttons.loading)} />
                }   
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

function Errors (props) {
  return (
    <div className={form.error}>
      <ul>
        {props.errors.map((error, _) => <li key={_}>{error}</li>)}
      </ul>
    </div>
  )
}

export default Form
