import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Block.module.css';
import page from '../../Page.module.css';
import fonts from '../../Fonts.module.css';

function Counter(props) {
  let current = props.counter.current;
  let approved = props.counter.approved;
  let url = props.counter.url;
  let rumc = props.rumc;

  const total = props.counter.total;

  let total_condition = (total && current < total);
  let approved_condition = (total_condition && current + approved >= total);

  return (
    <>
      <div>
        {url &&
          <a href={url} target="_blank" className={classNames(styles.current, { [styles.total_condition]: total_condition, [styles.approved_condition]: approved_condition })}>
            {`${current}${approved ? ` + ${approved}` : ''}${total ? ` из ${total}` : ''}`}
          </a>
        }
        {!url &&
          <div className={classNames(styles.current, { [styles.total_condition]: total_condition, [styles.approved_condition]: approved_condition })}>{`${current}${approved ? ` + ${approved}` : ''}${total ? ` из ${total}` : ''}`}</div>
        }
      </div>
    </>
  );
}

class Block extends React.Component {
  state = {
    block: this.props.block
  }

  render() {
    const { title, desc, url, label, sections, notifer, efficiency } = this.state.block;

    return (
      <div className={classNames(styles.root, styles[this.props.type])}>
        {this.state.block &&
          <div className={styles.content}>
            <div className={classNames(styles.head, { [styles.labelad]: label, [styles.withUrl]: url })}>
              {label &&
                <div className={styles.label}>
                  {label}
                </div>
              }

              <div className={classNames(styles.title, fonts.as_h3)}>
                {title}
              </div>

              <div className={classNames(styles.efficiency, fonts.as_h3)}>
                {efficiency}
              </div>

              {desc &&
                <div className={classNames(styles.desc, fonts.as_at)}>
                  {desc}
                </div>
              }

              {url &&
                <a href={url} className={styles.url} />
              }
            </div>

            {sections &&
              <div className={classNames(styles.sections)}>
                {Object.values(sections).map((section, _) =>
                  <div key={_} className={classNames(styles.section, { [styles.dev]: section.dev })}>

                    {section.title &&
                      <div className={classNames(styles.title)}>
                        {section.title}
                        {!!section.efficiency &&
                          <div className={classNames(styles.efficiency)}>
                            {section.efficiency}
                          </div>
                        }
                      </div>
                    }

                    {section.counter &&
                      <div className={classNames(styles.counter)}>
                        <Counter counter={section.counter}/>
                      </div>
                    }

                    {section.minister &&
                      <div className={classNames(styles.counter)}>
                        <Counter counter={section.minister}/>
                      </div>
                    }

                    {section.desc &&
                      <div className={classNames(styles.desc, fonts.as_at)} dangerouslySetInnerHTML={{__html: section.desc}} />
                    }

                    {section.links &&
                      <div className={classNames(styles.links, fonts.as_at)}>
                        {section.links.map((link, _) =>
                          <div key={_} className={styles.link} dangerouslySetInnerHTML={{__html: link}} />
                        )}
                      </div>
                    }
                  </div>
                )}
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

export default Block;
