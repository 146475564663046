import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'

import page from '../../Page.module.css'
import form from '../../Form.module.css'
import buttons from '../../Buttons.module.css'

class Form extends Component {
  state = {
    saved: false,
    errors: {},
    values: null
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  handleSubmit = async e => {
    e.preventDefault()

    await axios.patch(
      path('account_rumc_path', { id: this.props.id }, true),
      { rumc: this.state.values }
    ).then(res => {
      this.setState({ errors: {}, saved: true })
    }).catch(({ response }) => {
      this.setState({ errors: response.data, saved: false })
    })
  }

  _handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }))
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('edit_account_rumc_path', { id: this.props.id }, true), { cancelToken: this._asyncRequest.token })
    this.setState({ ...res.data })
  }

  render () {
    const { rumc, values, errors, saved } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">{I18n.t('account.title')}</Link>

            {rumc &&
              <Link to="account_rumc_path" params={{ id: rumc.id }}>{rumc.title_last}</Link>
            }
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('account.rumc.edit.title')}
            </h1>
            <Helmet>
              <title>
                {I18n.t('account.rumc.edit.title')}
              </title>
            </Helmet>
          </div>

          <div className={form.tight}>
            {values &&
              <form onSubmit={this.handleSubmit}>
                <div className={form.el}>
                  <label>
                    <div className={form.label}>
                      Название
                    </div>

                    <div className={form.input}>
                      <textarea value={values.title} name="title" onChange={this._handleInputChange} />
                    </div>
                  </label>

                  <Errors errors={errors.title} />

                  <div className={form.hint}>
                    Короткое название БЕЗ правовой формы и БЕЗ кавычек. Например: Нижегородский государственный педагогический университет имени Козьмы Минина
                  </div>
                </div>

                <div className={form.el}>
                  <label>
                    <div className={form.label}>
                      Краткое название
                    </div>

                    <div className={form.input}>
                      <textarea value={values.title_short} name="title_short" onChange={this._handleInputChange} />
                    </div>
                  </label>

                  <Errors errors={errors.title_short} />

                  <div className={form.hint}>
                    Короткое название. Например: РУМЦ МГГЭУ, РУМЦ Минкультуры России, РУМЦ МГТУ им. Н. Э. Баумана
                  </div>
                </div>

                {saved &&
                  <div className={form.success}>
                    Данные РУМЦ успешно сохранены
                  </div>
                }

                <div className={form.submit}>
                  <input type="submit" value="Сохранить" className={buttons.main} />
                </div>
              </form>
            }
          </div>
        </div>
      </div>
    )
  }
}

Form.propTypes = {
  id: PropTypes.string
}

function Errors (props) {
  return (
    <>
      {props.errors &&
        <div className={form.error}>
          <ul>
            {props.errors.map((error, _) => <li key={_}>{error}</li>)}
          </ul>
        </div>
      }
    </>
  )
}

Errors.propTypes = {
  errors: PropTypes.array
}

export default Form
