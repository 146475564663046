/**
 * Карточка "Общероссийский сетевой вебинар в рамках приемной кампании 2024"
 */
import React, { Component } from 'react'
import styles from './Vebinar.module.css'


class VabinarCard2024 extends Component {
    state = {
        items: [
            {
                title: 'Центральный федеральный округ',
                date: '17 июня 2024',
                time: '11.00-13.00 (МСК)',
                url: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZWJjOWQ3YmQtZmY4ZS00ZmNkLWEzZGItMmU2YzQ2OWJiMjgx%40thread.v2/0?context=%7b%22Tid%22%3a%2219ba435d-e46c-436a-84f2-1b01e693e480%22%2c%22Oid%22%3a%222607555d-3d30-4cd3-a1fb-cacbbc9409c5%22%7d',
            },
            {
                title: 'Северо-Западный федеральный округ',
                date: '19 июня 2024',
                time: '14:00-16:00 (МСК)',
                url: 'https://youtube.com/live/nbV54l8pCiw?feature=share',
            },
            {
                title: 'Южный федеральный округ',
                date: '17 июня 2024',
                time: '11.00-13.00 (МСК)',
                url: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZWJjOWQ3YmQtZmY4ZS00ZmNkLWEzZGItMmU2YzQ2OWJiMjgx%40thread.v2/0?context=%7b%22Tid%22%3a%2219ba435d-e46c-436a-84f2-1b01e693e480%22%2c%22Oid%22%3a%222607555d-3d30-4cd3-a1fb-cacbbc9409c5%22%7d',
            },
            {
                title: 'Северо-Кавказский федеральный округ',
                date: '14 июня 2024',
                time: '10.00-12.00 (МСК)',
                url: 'https://webinar.ncfu.ru/b/ayx-tpn-6cc',
            },
            {
                title: 'Приволжский федеральный округ',
                date: '14 июня 2024',
                time: '10.00.-12.00 (МСК)',
                url: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_N2U2ZDkwMmUtNTFjZC00YmY4LWJmOTAtNWQzNzE3YWM0MDRi%40thread.v2/0?context=%7b%22Tid%22%3a%229feed2cc-ba06-4560-9d13-954ee5d83983%22%2c%22Oid%22%3a%22f0af7efa-5e54-4889-9774-d55890f4bd4a%22%7d',
            },
            {
                title: 'Уральский федеральный округ',
                date: '17 июня 2024',
                time: '13.00-15.00 (МСК)',
                url: 'https://utmn.mts-link.ru/j/88137663/440800052',
            },
            {
                title: 'Сибирский федеральный округ',
                date: '14 июня 2024',
                time: '10.00.-12.00 (МСК)',
                url: 'https://utmn.mts-link.ru/j/88137663/1784935993',
            },
            {
                title: 'Дальневосточный федеральный округ',
                date: '19 июня 2024',
                time: '9.00-11.00 (МСК)',
                url: 'https://b86252.vr.mirapolis.ru/mira/s/ELWIxm',
            },
        ]
    }

    render() {
        const { items } = this.state

        return (
            <div className={styles.root}>
                <div className={styles.info_wrapper}>
                    <div className={styles.info_container}>
                        <h2>Общероссийский сетевой вебинар</h2>
                        <div className={styles.subheader}>в рамках приемной кампании 2024</div>

                        <p>Сеть ресурсных учебно-методических центров по обучению инвалидов и лиц с ограниченными возможностями здоровья в рамках взаимодействия с образовательными организациями высшего образования организует общероссийский вебинар «Поступление в вузы лиц с инвалидностью и ОВЗ в рамках приемной кампании 2024 года».</p>
                        <p className={styles.white_box_text}>Общероссийский вебинар будет проходить в формате серии встреч с использованием видеоконференцсвязи, проводимых по Федеральным округам в период <b>с 12 по 20 июня.</b></p>
                        <p style={{paddingBottom: '30px'}}>Ссылка для подключения, а также информация о дате и времени проведения вебинара расположены ниже.</p>
                    </div>
                </div>
                <div className={styles.items_wrapper}>
                    {items.map(item => 
                        <div className={styles.white_box_item}>
                            <div>{item.title}</div>
                            <div>{item.date}</div>
                            <div>{item.time}</div>
                            <a href={item.url} target='_blank'>Ссылка на вебинар</a>
                        </div>
                    )}
                </div>
            </div>
        )

    }
}

export default VabinarCard2024;