import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import qs from 'query-string'

import Loaf from '../../Loaf/Loaf'
import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import List from '../List'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'

class Index extends Component {
  state = {
    rumc_schools: null,
    search: this.props.location.search,
    filters: null
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('account_rumc_schools_path', {}, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search) })
    this._asyncRequest = null

    this.setState({ ...res.data })
  }

  static getDerivedStateFromProps (props, state) {
    if (props.location.search !== state.search) {
      return {
        rumc_schools: null,
        search: props.location.search
      }
    }

    return null
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.rumc_schools === null) this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  render () {
    const { rumc_schools: rumcSchools, filters, can_new: canNew } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">{I18n.t('account.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('rumc_schools.title')}
            </h1>

            <Helmet>
              <title>{I18n.t('rumc_schools.title')}</title>
            </Helmet>

            {canNew &&
              <div>
                <Link className={buttons.main} to="new_account_rumc_school_path">Новое соглашение</Link>
              </div>
            }
          </div>

          <div>
            <List items={rumcSchools} filters={filters} {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

Index.propTypes = {
  location: PropTypes.object
}

export default Index
