import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from '../Routes';

import Form from './Form';
import Show from './Show';
import Index from './Index';

class Reports extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path={Routes.new_report_path} component={Form} />
          <Route path={Routes.edit_report_path} render={props => <Form id={props.match.params.id} {...props} />} />
          <Route path={Routes.report_path} render={props => <Show id={props.match.params.id} />} />
          <Route path={Routes.reports_path} component={Index} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Reports;
