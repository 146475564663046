import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import { path } from '../../Routes';

import page from '../../Page.module.css';
import form from '../../Form.module.css';
import buttons from '../../Buttons.module.css';

class Review extends Component {
  state = {
    rumc_school: null,
    values: {
      review: '',
      agreement_sign: '',
      roadmap_sign: ''
    }
  }


  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('review_account_rumc_school_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          rumc_school: res.data.rumc_school,
          values: res.data.values,
        });

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleSubmit = (e) => {
    axios({
      method: 'patch',
      url: path('account_rumc_school_path', { id: this.props.id }, true),
      data: {
        rumc_school: this.state.values
      },
    }).then(
      res => {
        this.props.history.push(path('account_rumc_school_path', { id: this.props.id }))
      }
    );

    e.preventDefault();
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }));
  }

  render() {
    const { rumc_school, values } = this.state;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={path('account_rumc_schools_path')}>{I18n.t('rumc_schools.title')}</Link>
          </Loaf>

          {rumc_school &&
            <>
              <div className={page.title}>
                <h1>
                  {rumc_school.title}
                </h1>

                <Helmet>
                  <title>{rumc_school.title}</title>
                </Helmet>
              </div>

              <div className={page.form}>
                <form className={form.root} onSubmit={this.handleSubmit}>

                  <div className={form.elem}>
                    <div className={form.label}>
                      Соглашение подписано
                    </div>

                    <div className={form.input}>
                      <select name="agreement_sign" onChange={this.handleInputChange} value={values.agreement_sign}>
                        <option />
                        <option value={0}>Руководитель РУМЦ</option>
                        <option value={1}>Ректор/проректор</option>
                      </select>
                    </div>
                  </div>

                  <div className={form.elem}>
                    <div className={form.label}>
                      Дорожная карта подписана
                    </div>

                    <div className={form.input}>
                      <select name="roadmap_sign" onChange={this.handleInputChange} value={values.roadmap_sign}>
                        <option />
                        <option value={0}>Не подписана</option>
                        <option value={1}>Руководитель РУМЦ</option>
                        <option value={2}>Ректор/проректор</option>
                      </select>
                    </div>
                  </div>

                  <div className={form.elem}>
                    <div className={form.label}>
                      Срок реализации мероприятий дорожной карты
                    </div>

                    <div className={form.input}>
                      <select name="roadmap_events" onChange={this.handleInputChange} value={values.roadmap_events}>
                        <option />
                        <option value={0}>Не соответсвует</option>
                        <option value={1}>Соотносится с датой заключения соглашения</option>
                      </select>
                    </div>
                  </div>

                  <div className={form.elem}>
                    <div className={form.label}>
                      Качество дорожной карты
                    </div>

                    <div className={form.input}>
                      <select name="roadmap_quality" onChange={this.handleInputChange} value={values.roadmap_quality}>
                        <option />
                        <option value={0}>Типовая</option>
                        <option value={1}>Учитывает потребности вуза</option>
                      </select>
                    </div>
                  </div>

                  <div className="input">
                    <div className="input_label">
                      <label>
                        Коментарии к оценкам
                      </label>
                    </div>

                    <div className="input_input">
                      <textarea name="review" value={values.review} rows="4" onChange={this.handleInputChange} />
                    </div>
                  </div>

                  <div className={form.submit}>
                    <input type="submit" value="Сохранить" className={buttons.main} />
                  </div>
                </form>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}

export default Review;
