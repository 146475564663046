function round1000(val) {
  if (!val) {
    return 0
  }
  if (val < 1000) {
    return val
  }
  return Math.round(val/1000)*1000
}

export function calcSalaryString(career) {
  const salary_min = round1000(career.salary_min);
  const salary_max = round1000(career.salary_max);
  if (salary_min && salary_max && salary_min != salary_max) {
    return `${(salary_min).toLocaleString()} - ${(salary_max).toLocaleString()} рублей`
  }
  if (salary_min && salary_max && salary_min == salary_max) {
    return `${(salary_min).toLocaleString()} рублей`
  }
  if (salary_min && !salary_max) {
    return `От ${(salary_min).toLocaleString()} рублей`
  }
  if (!salary_min && salary_max) {
    return `До ${(salary_max).toLocaleString()} рублей`
  }
}


export function calc_rating(raw_rating) {
  const full_stars = Math.trunc(raw_rating);
  const arr =  Array.from({length: full_stars}, (_, i) => 1)
  if (raw_rating - full_stars > 0.5) 
    arr.push(0)
  return arr
}