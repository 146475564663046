import React from 'react'
import classNames from 'classnames'
import page from '../Page.module.css'
import { Helmet } from 'react-helmet-async'
import Loaf from '../Loaf/Loaf'
import Link, { path } from '../Routes'
import I18n from '../I18n'
import styles from './Projects.module.css'
import buttons from '../Buttons.module.css'

import GlobalVariables from '../Vars';
import SimpleImageSlider from "react-simple-image-slider";

import filter_styles from '../Filters.module.css'


class Projects extends React.Component {
    state = {
        slider_width: 0,
        slider_heigth: 0,

        autoplay: true,
        autoPlayOffDelay: 0
    }
    
    componentDidMount () {
        let w = window.innerWidth > 1280 ? 1280 : window.innerWidth - 40;
        let h = w / (1280 / 720);
        this.setState({slider_width: w, slider_heigth: h})
    }
 
    render () {
        const { user } = this.context

        return (
            <div className={page.wrapper}>
                <div className={page.container}>
                    <Loaf>
                        <Link to="root_path">{I18n.t('nav.root')}</Link>
                    </Loaf>

                    <Helmet>
                        <title>{I18n.t('nav.projects')}</title>
                    </Helmet>
                    
                    <div className={page.title}>
                        <h1>{I18n.t('nav.projects')}</h1>
                    </div>

                    <div className={styles.previewImg} />


                    <div className={styles.subtitle}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.2721 5.36533L23.0892 6.85414L23.2721 5.36533ZM25.1826 7.3461L23.6863 7.45144L25.1826 7.3461ZM25.1826 25.793L23.6863 25.6877L25.1826 25.793ZM6.73218 27.7366L6.85749 26.2418L6.73218 27.7366ZM15 6.12054C17.7328 6.12054 20.3799 6.5213 23.0892 6.85414L23.455 3.87653C20.8614 3.5579 17.9678 3.12054 15 3.12054V6.12054ZM6.91077 6.85414C9.62011 6.5213 12.2672 6.12054 15 6.12054V3.12054C12.0321 3.12054 9.13854 3.5579 6.54497 3.87653L6.91077 6.85414ZM23.1425 26.2418C17.5522 26.7105 12.4478 26.7105 6.85749 26.2418L6.60687 29.2313C12.364 29.714 17.636 29.714 23.3931 29.2313L23.1425 26.2418ZM26.6789 25.8983C27.1249 19.5639 27.1249 13.5752 26.6789 7.24076L23.6863 7.45144C24.1224 13.6456 24.1224 19.4935 23.6863 25.6877L26.6789 25.8983ZM3.32105 7.24076C2.87512 13.5752 2.87512 19.5639 3.32105 25.8983L6.31365 25.6876C5.87759 19.4935 5.87759 13.6456 6.31365 7.45143L3.32105 7.24076ZM6.54497 3.87653C4.83487 4.08661 3.44611 5.46433 3.32105 7.24076L6.31365 7.45143C6.33386 7.16434 6.56767 6.89629 6.91077 6.85414L6.54497 3.87653ZM23.0892 6.85414C23.4323 6.89629 23.6661 7.16434 23.6863 7.45144L26.6789 7.24076C26.5539 5.46433 25.1651 4.08661 23.455 3.87653L23.0892 6.85414ZM23.3931 29.2313C25.1637 29.0829 26.5546 27.665 26.6789 25.8983L23.6863 25.6877C23.6654 25.9845 23.4333 26.2175 23.1425 26.2418L23.3931 29.2313ZM6.85749 26.2418C6.56671 26.2175 6.33454 25.9845 6.31365 25.6876L3.32105 25.8983C3.44543 27.665 4.83625 29.0829 6.60687 29.2313L6.85749 26.2418Z" fill="#FFDD09"/>
                            <path d="M10.9152 12.4621C10.0868 12.4621 9.41519 13.1336 9.41519 13.9621C9.41519 14.7905 10.0868 15.4621 10.9152 15.4621V12.4621ZM19.0848 15.4621C19.9133 15.4621 20.5848 14.7905 20.5848 13.9621C20.5848 13.1336 19.9133 12.4621 19.0848 12.4621V15.4621ZM10.9152 15.4621H19.0848V12.4621H10.9152V15.4621Z" fill="#FFDD09"/>
                            <path d="M10.9152 18.6897C10.0868 18.6897 9.41519 19.3613 9.41519 20.1897C9.41519 21.0182 10.0868 21.6897 10.9152 21.6897V18.6897ZM15.8451 21.6897C16.6736 21.6897 17.3451 21.0182 17.3451 20.1897C17.3451 19.3613 16.6736 18.6897 15.8451 18.6897V21.6897ZM10.9152 21.6897H15.8451V18.6897H10.9152V21.6897Z" fill="#FFDD09"/>
                            <rect x="8.93973" y="1.60714" width="12.1205" height="6.36161" rx="3.1808" fill="#FFDD09"/>
                        </svg>
                        <div>Всего проектов:</div>
                        <div>3 обобщающих (185 элементов)</div>
                    </div>

                    <div className={styles.hr} />

                    <div>
                        <h2>Банк технологий и решений</h2>

                        <p>Формирование  банка образовательных и социальных технологий для лиц с инвалидностью и ОВЗ (в том числе с использованием специальных технических средств обучения, AR-, VR-средств) в рамках реализации мероприятия по пункту II.3.2 
                            <a href='https://инклюзивноеобразование.рф/uploads/document/press_reliz/1_plan_secondary.pdf' target="_blank"> Межведомственного комплексного плана мероприятий по повышению доступности среднего профессионального и высшего образования для инвалидов и лиц с ограниченными возможностями здоровья, в том числе профориентации и занятости указанных лиц, утвержденного Заместителем Председателя Правительства Российской Федерации Т.А. Голиковой 21 декабря 2021 г. № 14000п-П8 </a>
                            в 2022 году  осуществлялось в ходе кампании Форума инклюзивного высшего образования, состоявшегося на базе ФГБОУ ВО «Вятский государственный университет»</p>
                        <p>Банк образовательных и социальных технологий для лиц с инвалидностью и ОВЗ (текущая версия) размещен на ресурсе Форума инклюзивного высшего образования</p>
                        
                        <p>
                            <a href="https://forum.vyatsu.ru/projects/magazin-korobochnyh-resheniy" target="_blank" className={buttons.main}>
                                Перейти
                            </a>
                        </p>   

                        <div className={styles.hr} />

                        <h2>Межведомственный комплексный план от 10 апреля 2023 года</h2>
                        <p>Межведомственный комплексный план мероприятий по повышению доступности среднего профессионального и высшего образования для инвалидов и лиц с ограниченными возможностями здоровья, в том числе профориентации и занятости указанных лиц, утвержденного Заместителем Председателя Правительства Российской Федерации Т.А. Голиковой 10 апреля 2023 г. № 3838п-П8</p>
                        <p>
                            <a href="https://инклюзивноеобразование.рф/uploads/document/press_reliz/MKP_2023.pdf" target="_blank" className={buttons.main}>
                                Скачать
                            </a>
                        </p> 

                        <div className={styles.hr} />

                        <h2>Прототип общероссийского сетевого ресурса (библиотеки)</h2>
                        <p>
                            Создание прототипа общероссийского сетевого ресурса (библиотеки), включающего примерные адаптированные образовательные программы и комплекты учебно-методических материалов с учетом требований универсального дизайна и дифференциации подходов, исходя из тяжести нарушений по конкретной нозологии, осуществляется в рамках реализации мероприятия по пункту II.4.3 
                            <a href='https://инклюзивноеобразование.рф/uploads/document/press_reliz/1_plan_secondary.pdf' target='_blank'> Межведомственного комплексного плана мероприятий по повышению доступности среднего профессионального и высшего образования для инвалидов и лиц с ограниченными возможностями здоровья, в том числе профориентации и занятости указанных лиц, утвержденного Заместителем Председателя Правительства Российской Федерации Т.А. Голиковой 21 декабря 2021 г. № 14000п-П8 </a>
                        </p>
                        <p><b>Структура прототипа</b> общероссийского сетевого ресурса (библиотеки) разработана сетью РУМЦ</p>
                        <p>Структура включает <b>гипертекстовые ссылки</b> на уже функционирующие на портале инклюзивноеобразование.рф <b>примеры материалов по разделам общероссийского сетевого ресурса (библиотеки)</b></p>

                        <div className={styles.slides_wrapper + " wraptest"}>
                            <div className={styles.slider}>
                                <SimpleImageSlider
                                    width={this.state.slider_width}
                                    height={this.state.slider_heigth}
                                    images={[...Array(10)].map((i, index) => (i =  {id: `${index+1}`, url:  GlobalVariables.cdnHost + `projects/1/slide${index+1}.JPG` }))}
                                    showBullets={false}
                                    showNavs={true}
                                    navStyle={2}
                                    navSize={30}
                                    // autoPlay={this.state.autoplay}
                                    autoPlay={false}
                                    autoPlayDelay={7}
                                />
                            </div>
                        </div>

                        {/* <p>
                            <p className={classNames(filter_styles.item, { [filter_styles.selected]: !this.state.autoplay })}>
                                <label>
                                    <input
                                        className={styles.input_style}
                                        checked={!this.state.autoplay}
                                        type="checkbox"
                                        name={12}
                                        onChange={() => this.autoPlayOff()}
                                        style={{marginRight: '8px'}}
                                    />
                                    Отключить автоматическое перелистывание слайдов 
                                </label>
                            </p>
                        </p> */}
                        
                        <p><b>Файлы для скачивания:</b></p>
                        <ul>
                            <li>
                                <a href='https://инклюзивноеобразование.рф/uploads/document/press_reliz/Schema_repository_portal.pdf' target="_blank">Структура формат "pdf"</a>
                            </li>
                            <li>
                                <a href='https://инклюзивноеобразование.рф/uploads/document/press_reliz/Schema_repository_portal.pptx' target="_blank">Структура формат "pptx"</a>
                            </li>
                        </ul>

                        <p>Ознакомиться с единым информационным ресурсом по адаптированным образовательным программам по профессиям и специальностям СПО, ПО (созданным в рамках данного пункта II.4.3 Межведомственного комплексного плана) предлагается по ссылке на официальном сайте Федерального методического центра инклюзивного образования:
                        <a href='https://fmc-spo.ru/activity/rumc/' target='_blank'>https://fmc-spo.ru/activity/rumc/</a></p>

                        <div className={styles.hr} />

                        <h2>Видеопаспорта о доступности образовательных организаций высшего образования для лиц с инвалидностью и ОВЗ</h2>
                        <p>
                            Формирование банка видеопаспортов доступности образовательных организаций высшего образования для лиц с инвалидностью и ОВЗ в рамках реализации мероприятия по пункту I.3.5
                            <a href='https://инклюзивноеобразование.рф/uploads/document/press_reliz/1_plan_secondary.pdf' target='_blank'>  Межведомственного комплексного плана мероприятий по повышению доступности среднего профессионального и высшего образования для инвалидов и лиц с ограниченными возможностями здоровья, в том числе профориентации и занятости указанных лиц, утвержденного Заместителем Председателя Правительства Российской Федерации Т.А. Голиковой 21 декабря 2021 г. № 14000п-П8 </a>
                            в 2022 году осуществлялось вузами РУМЦ при согласовании утвержденного вида работ ФОИВом-учредителем.
                        </p>
                        <p><b>Файлы для скачивания:</b></p>
                        <ul>
                            <li>
                                <a href='https://инклюзивноеобразование.рф/uploads/document/press_reliz/videopasport_links.pdf' target="_blank">Видеопаспорта ссылки "pdf"</a>
                            </li>
                            <li>
                                <a href='https://инклюзивноеобразование.рф/uploads/document/press_reliz/videopasport_links.pptx' target="_blank">Видеопаспорта ссылки "docx"</a>
                            </li>
                        </ul>
                        <p>Сетью РУМЦ был разработан и согласован для распространения среди вузов-партнеров «Методический алгоритм-инструкция по созданию модельного образца видеопаспорта доступности образовательной организации высшего образования для лиц с инвалидностью и ОВЗ»</p>
                        <p><b>Файлы для скачивания:</b></p>
                        <ul>
                            <li>
                                <a href='https://инклюзивноеобразование.рф/uploads/document/press_reliz/algoritm_videopasport.pdf' target="_blank">Алгоритм - формат "pdf"</a>
                            </li>
                            <li>
                                <a href='https://инклюзивноеобразование.рф/uploads/document/press_reliz/algoritm_videopasport.pptx' target="_blank">Алгоритм - формат "docx"</a>
                            </li>
                        </ul>

                        {/* --------------------- */}

                        <div className={styles.hr} />
                        <h2>Актуальные материалы о работе, целях, принципах и задачах сети ресурсных учебно-методических центров</h2>
                        <p><b>Файлы для скачивания:</b></p>
                        <ul>
                            <li>
                                <a href="/uploads/document/RUMC/Prezentaciya_seti_RUMC.pdf" target="_blank">Презентация сети РУМЦ</a>
                            </li>
                            <li>
                                <a href="/uploads/document/universities/Metodicheskie_rekomendacii_po_organizacii_IVO.pdf" target="_blank">
                                    Методические рекомендации по организации инклюзивного образования
                                </a>
                            </li>
                            <li>
                                <a href='/uploads/document/projects/Activity_RUMC.pptx' target='_blank'>Деятельность сети ресурсных учебно-методических центров.pptx</a>
                            </li>
                            <li>
                                <a href='/uploads/document/projects/Modern_understanding_phenomenon_disability.pptx' target='_blank'>Современное понимание феномена инвалидности методология инклюзии.pptx</a>
                            </li>
                            <li>
                                <a href='/uploads/document/projects/report_2022.pdf' target='_blank'>Сводный отчет по работе за 2022 год.pdf</a>
                            </li>
                            <li>
                                <a href='/uploads/document/projects/results_2022.pdf' target='_blank'>Результаты 2022 год.pdf</a>
                            </li>
                            <li><a href='/uploads/document/projects/actual/1.pdf' target='_blank'>Информационно-аналитический доклад о реализуемых мерах по развитию образовательных технологий с применением электронного обучения, различных технологических платформ для дистанционного обучения в интересах инвалидов.pdf</a></li>
                            <li><a href='/uploads/document/projects/actual/2.pdf' target='_blank'>Информационно-аналитический доклад о реализуемых мерах по развитию образовательных технологий с применением электронного обучения, различных технологических платформ для дистанционного обучения в интересах инвалидов.pdf</a></li>
                            <li><a href='/uploads/document/projects/actual/3.pdf' target='_blank'>Информационно-аналитическая справка по вопросам профориентации, сопровождения обучения и трудоустройства молодых людей с инвалидностью в системе высшего образования.pdf</a></li>
                            <li><a href='/uploads/document/projects/actual/4.docx' target='_blank'>Информационно-аналитическая справка по вопросам профориентации, сопровождения обучения и трудоустройства молодых людей с инвалидностью в системе высшего образования.docx</a></li>
                            <li><a href='/uploads/document/projects/actual/5.pdf' target='_blank'>Информационная справка о социальных гарантиях в сфере высшего образования для инвалидов I группы.pdf</a></li>
                            <li><a href='/uploads/document/projects/actual/6.docx' target='_blank'>Информационная справка о социальных гарантиях в сфере высшего образования для инвалидов I группы.docx</a></li>
                            <li><a href='/uploads/document/projects/actual/7.pdf' target='_blank'>Отчет по итогам специально организованного опроса обучающихся в системе ВОР инвалидов и лиц с ОВЗ.pdf</a></li>
                            <li><a href='/uploads/document/projects/actual/8.docx' target='_blank'>Отчет  по итогам специально организованного опроса обучающихся в системе ВО инвалидов и лиц с ОВЗ.docx</a></li>
                            <li><a href='/uploads/document/projects/actual/9.docx' target='_blank'>Методические рекомендации по использованию унифицированного подхода к доступности электронной информационно-образовательной среды для обучающихся с инвалидностью и ограниченными возможностями здоровья в образовательной организации высшего образования.docx</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Projects

