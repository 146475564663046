import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'

import Link, { path } from '../../Routes'
import I18n from '../../I18n'
import Loaf from '../../Loaf/Loaf'
import { Errors } from '../../Form'
import Files from '../../Attachments/Files'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
import form from '../../Form.module.css'
import tabs from '../../Tabs.module.css'

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

export default function Form () {
  const query = useQuery()

  const { id } = useParams()
  const history = useHistory()
  const cancelToken = useRef(axios.CancelToken.source())

  const [values, setValues] = useState({
    title: '',
    desc: '',
    section: query.get('section')
  })

  const [attachments, setAttachments] = useState([])
  const [dictionaries, setDictionaries] = useState()

  const [send, setSend] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(
        id ? path('edit_account_target_program_path', { id }, true) : path('new_account_target_program_path', {}, true),
        {
          cancelToken: cancelToken.current.token
        }
      )

      if (id) {
        setValues(data.values)
        setAttachments(data.attachments)
      }

      setDictionaries(data.dictionaries)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [id])

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value })
  }

  const handleSectionChange = (value) => {
    setValues({ ...values, section: value })
  }

  const handleFilesChanged = files => {
    setValues({
      ...values,
      attachments_attributes: [...files.values()].map(file => ({ id: file.id, title: file.title, section: file.section })),
      attachment_ids: [...files.values()].map(file => file.id)
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    if (id) {
      handleUpdate()
    } else {
      handleCreate()
    }
  }

  const handleCreate = async () => {
    await axios.post(
      path('account_target_programs_path'),
      { target_program: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const handleUpdate = async () => {
    await axios.patch(
      path('account_target_program_path', { id }),
      { target_program: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
          <Link to="account_target_programs_path">{I18n.t('target_programs.title')}</Link>
        </Loaf>
        <div className={page.title}>
          <h1>
            {id ? 'Редактирование' : 'Новая программа' }
          </h1>
        </div>

        <div className={form.tight}>
          <form onSubmit={handleSubmit}>
            <div className={form.el}>
              <label>
                <div className={form.label}>
                  Название
                </div>

                <div className={form.input}>
                  <input
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleInputChange}
                  />
                </div>
              </label>

              <Errors errors={errors.title} />
            </div>

            {dictionaries &&
              <div className={form.el}>
                <div className={form.label}>
                  <label>
                    Тип программы
                  </label>
                </div>

                <div className={tabs.root}>
                  {Object.entries(dictionaries.sections).map((tab, _) =>
                    <div key={_} className={classNames([tabs.tab], { [tabs.active]: values.section === tab[0] })} onClick={() => handleSectionChange(tab[0])}>
                      {tab[1]}
                    </div>
                  )}
                </div>

                {errors.section &&
                  <Errors errors={errors.section}/>
                }
              </div>
            }

            <div className={form.el}>
              <label>
                <div className={form.label}>
                  Описание
                </div>

                <div className={form.input}>
                  <textarea
                    name="desc"
                    value={values.desc}
                    onChange={handleInputChange}
                  />
                </div>
              </label>

              <Errors errors={errors.desc} />
            </div>

            <Files
              files={attachments}
              onFilesChanged={handleFilesChanged}
              sections={['video']}
            />

            <div className={form.submit}>
              <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
