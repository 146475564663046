import React from "react";
import styles from "./Loading.module.css";
// import classNames from 'classnames'

export const Loading = (props) => {

  return (
    <div className={styles.container}>
      <div className={styles.loading} />
    </div>
  )
}

export default Loading;
