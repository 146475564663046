import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import dayjs from 'dayjs'

import I18n from '../I18n'
import Link, { path } from '../Routes'
import Loaf from '../Loaf/Loaf'

import styles from './Show.module.css'
import page from '../Page.module.css'
import buttons from '../Buttons.module.css'

export default function Show () {
  const { event_id: eventId, id } = useParams()
  const history = useHistory()

  const cancelToken = useRef(axios.CancelToken.source())
  const [event, setEvent] = useState()
  const [registration, setRegistration] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('event_event_registration_path', { event_id: eventId, id }, true), { cancelToken: cancelToken.current.token })

      setRegistration(data.registration)
      setEvent(data.event)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const handleDestroy = async e => {
    e.preventDefault()

    await axios.delete(
      path('event_event_registration_path', { event_id: eventId, id })
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    })
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="events_path">{I18n.t('nav.events')}</Link>
          {event &&
            <>
              <Link to="event_path" params={{ id: event.id }}>
                {event.title}
              </Link>
              <Link to="event_event_registrations_path" params={{ event_id: event.id }}>
                Участники
              </Link>
            </>
          }
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('event_registration.title', { id })}
          </h1>

          <Helmet>
            <title>{I18n.t('event_registration.title', { id })}</title>
          </Helmet>
        </div>

        {event && event.registration.can_new &&
          <div className={styles.new}>
            <Link to="new_event_event_registration_path" params={{ event_id: event.id }} className={buttons.main}>
              Добавить нового участника
            </Link>
          </div>
        }

        {registration &&
          <div>
            <p>
              <strong>
                Имя участника
              </strong>
              <br />
              {registration.name}
            </p>

            <p>
              <strong>
                Дата регистрации
              </strong>
              <br />
              {dayjs(registration.created_at).format('DD.MM.YYYY')}
            </p>

            <p>
              <strong>
                Категория
              </strong>
              <br />
              {registration.audience.label}
            </p>

            {registration.audience.model === 'Rumc' &&
              <p>
                <strong>
                  Ресурсный центр
                </strong>
                <br />
                {registration.rumc.label}
              </p>
            }

            {registration.audience.model === 'School' &&
              <p>
                <strong>
                  Вуз
                </strong>
                <br />
                {registration.school.label}
              </p>
            }

            {event && event.registration.can_new &&
              <div>
                <button className={buttons.cancel} onClick={handleDestroy}>
                  Отменить регистрацию
                </button>
              </div>
            }
          </div>
        }

      </div>
    </div>
  )
}
