import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'query-string';

import Loaf from '../../Loaf/Loaf';
import I18n from '../../I18n';
import { path } from '../../Routes';
import List from '../List';

import page from '../../Page.module.css';
import buttons from '../../Buttons.module.css';

class Index extends Component {
  state = {
    careers: null,
    search: this.props.location.search,
    filters: null
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('account_inquiries_path', {}, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search) })
      .then(res => {
        const { inquiries } = res.data;

        this.setState({ inquiries });
        this._asyncRequest = null;
      });
  }

  static getDerivedStateFromProps(props, state) {
    if(props.location.search !== state.search) {
      return {
        inquiries: null,
        search: props.location.search,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.inquiries === null) this._loadAsyncData();
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  render() {
    const { inquiries } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('inquiries.title')}
            </h1>

            <Helmet>
              <title>{I18n.t('inquiries.title')}</title>
            </Helmet>
          </div>

          <div className="page_actions">
            <Link className={buttons.main} to={path('new_account_inquiry_path', {}, false)}>
              Новый запрос
            </Link>
          </div>

          <div>
            <List items={inquiries} {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
