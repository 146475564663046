import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import update from 'immutability-helper';
import classNames from 'classnames';

import { Routes, path } from '../Routes';

import Loaf from '../Loaf/Loaf';

import styles from './Index.module.css';
import page from '../Page.module.css';
import buttons from '../Buttons.module.css';

class Index extends React.Component {
  state = {
    toggle: false,
    event_visits: [],
    event_registrations: [],
    stat: null,
    event: null,
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('event_event_visits_path', { event_id: this.props.event_id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          event: res.data.event,
          event_visits: res.data.event_visits,
          event_registrations: res.data.event_registrations,
          stat: res.data.stat,
        });
        this._asyncRequest = null;
      });
  }

  handleRemove = (e, id) => {
    this._asyncRequest = axios.CancelToken.source();
    axios({
      method: 'delete',
      url: path('event_event_visit_path', { event_id: this.props.event_id, id: id }, true),
      cancelToken: this._asyncRequest.token,
      data: Object.assign({
        authenticity_token: this.props.authenticity_token
      })
    }).then(
      res => {
        const event_visits = update(this.state.event_visits, {
          $unset: [this.state.event_visits.findIndex(ev => ev.id == id)]
        });

        this.setState({ event_visits });
      }
    ).catch((error) => {
    }).then(() => {
      this._asyncRequest = null;
    });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleRegistrationDestroy = (registration) => {
    this._asyncRequest = axios.CancelToken.source();

    axios({
      method: 'delete',
      url: path('register_event_path', { id: this.state.event.id }, true),
      data: Object.assign({
        authenticity_token: this.props.authenticity_token,
        user_id: registration.user.id,
      }),
      cancelToken: this._asyncRequest.token })
    .then(
      res => {
        const event_registrations = update(this.state.event_registrations, {
          $unset: [this.state.event_registrations.findIndex(er => er.id == registration.id)]
        });

        this.setState({ event_registrations });
      }
    ).catch((error) => {
    }).then(() => {
        this._asyncRequest = null;
    });
  }

  render() {
    const { I18n } = this.props;
    const { event_visits, event_registrations, event, stat } = this.state;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_events_path')}>{I18n.t('events.nav')}</Link>
            {event &&
              <Link to={path('account_event_path', { id: event.id })}>
                {event.title}
              </Link>
            }
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('event_visits.nav')}
            </h1>

            <Helmet>
              <title>
                {I18n.t('event_visits.nav')}
              </title>
            </Helmet>
          </div>

          <div>
            <div className={styles.new}>
              <Link className={buttons.small} to={path('new_event_event_visit_path', { event_id: this.props.event_id })}>
                Добавить участника
              </Link>

              <div className={buttons.small} onClick={() => this.setState(state => ({ toggle: !state.toggle }))}>
                Статистика участников
              </div>
            </div>

            {stat &&
              <div className={classNames(styles.stat, { [styles.show]: this.state.toggle })}>
                <div className={styles.statab}>
                  <h3>
                    Организации
                  </h3>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Организация
                        </th>

                        <th>
                          Количество
                        </th>

                        <th>
                          % от общего числа
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {stat.schools.map(school =>
                        <tr>
                          <td className={styles.orgName}>
                            {school.title}
                          </td>
                          <td>
                            {school.count}
                          </td>

                          <td>
                            {parseInt((school.count / stat.schools.reduce((sum, x) => (sum + x.count), 0)) * 100)}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td>
                          Итого: {stat.schools.length}
                        </td>
                        <td>
                          {stat.schools.reduce((sum, x) => (sum + x.count), 0)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className={styles.statab}>
                  <h3>
                    Регионы
                  </h3>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Регион
                        </th>

                        <th>
                          Количество
                        </th>

                        <th>
                          % от общего числа
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {stat.regions.map(region =>
                        <tr>
                          <td className={styles.orgName}>
                            {region.title}
                          </td>
                          <td>
                            {region.count}
                          </td>

                          <td>
                            {parseInt((region.count / stat.regions.reduce((sum, x) => (sum + x.count), 0)) * 100)}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td>
                          Итого: {stat.regions.length}
                        </td>
                        <td>
                          {stat.regions.reduce((sum, x) => (sum + x.count), 0)}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>

                <div className={styles.statab}>
                  <h3>
                    Аудитория
                  </h3>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Аудитория
                        </th>

                        <th>
                          Количество
                        </th>

                        <th>
                          % от общего числа
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {stat.audiences.map(audience =>
                        <tr>
                          <td className={styles.orgName}>
                            {audience.title}
                          </td>
                          <td>
                            {audience.count}
                          </td>

                          <td>
                            {parseInt((audience.count / stat.audiences.reduce((sum, x) => (sum + x.count), 0)) * 100)}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td>
                          Итого: {stat.audiences.length}
                        </td>
                        <td>
                          {stat.audiences.reduce((sum, x) => (sum + x.count), 0)}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            }

            <div className={styles.visits}>
              <h3>
                Участники, занесенные вручную
              </h3>

              <table>
                <thead>
                  <tr>
                    <th className={styles.date}>
                      Дата
                    </th>
                    <th>
                      Имя
                    </th>
                    <th>
                      Категория
                    </th>
                    <th>
                      Регион
                    </th>
                    <th>
                      Организация
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {event_visits.map(visit =>
                    <tr>
                      <td className={styles.date}>
                        {visit.date}
                      </td>

                      <td>
                        {visit.name}
                      </td>

                      <td>
                        {visit.audience ? visit.audience.title : ''}
                      </td>

                      <td>
                        {visit.region ? visit.region.title : ''}
                      </td>

                      <td>
                        {visit.audience.model == 'Rumc' && visit.rumc ? visit.rumc.title : null}
                        {visit.audience.model == 'School' && visit.school ? visit.school.title_last : null}
                      </td>
                      <td>
                        <Link to={path('edit_event_event_visit_path', { event_id: this.props.event_id, id: visit.id })}>
                          Редактировать
                        </Link>
                      </td>
                      <td>
                        <span className={styles.remove} onClick={(e) => { if(window.confirm('Уверены что хотите удалить безвозвратно?')) this.handleRemove(e, visit.id) }}>Удалить</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

            </div>
            <div className={styles.visits}>
              <h3>
                Самостоятельно зарегистрировавшиеся участники
              </h3>
              <table>
                <thead>
                  <tr>
                    <th className={styles.date}>
                      Дата
                    </th>
                    <th>
                      Имя
                    </th>
                    <th>
                      Email
                    </th>
                    <th>
                      Категория
                    </th>
                    <th>
                      Регион
                    </th>
                    <th>
                      Организация
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {event_registrations.map(registration =>
                    <tr>
                      <td className={styles.date}>
                        {registration.date}
                      </td>

                      <td>
                        {registration.user.name}
                      </td>

                      <td>
                        {registration.user.email}
                      </td>

                      <td>
                        {registration.audience ? registration.audience.title : ''}
                      </td>

                      <td>
                        {registration.region ? registration.region.title : ''}
                      </td>

                      <td>
                        {registration.audience && registration.audience.model == 'Rumc' && registration.rumc ? registration.rumc.title : null}
                        {registration.audience && registration.audience.model == 'School' && registration.school ? registration.school.title_last : null}
                      </td>

                      <td>
                        <div className={buttons.main} onClick={() => this.handleRegistrationDestroy(registration)}>Удалить</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index
