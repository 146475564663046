import React from 'react'
// import PropTypes from 'prop-types';

// import { Link } from 'react-router-dom'

import Link from '../Routes'

import Loaf from '../Loaf/Loaf'

import page from '../Page.module.css'

class TermsRumcVideo extends React.Component {
  render () {
    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="terms_path">Правила</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Требования к видеофайлам
            </h1>
          </div>

          <div>
            <h3>
              1. Формат файла
            </h3>

            <p>
              MPEG-4
            </p>

            <h3>
              2. Видеокодек
            </h3>

            <p>
              H.264
            </p>

            <h3>
              3. Аудиокодек
            </h3>

            <p>
              AAC — битрейт 128 Кбит/с или выше
            </p>

            <h3>
              4. Разрешение видео
            </h3>

            <p>
              1080p (1920×1080)
            </p>

            <h3>
              5. Соотношение сторон
            </h3>

            <p>
              16:9
            </p>

            <h3>
              6. Частота кадров
            </h3>

            <p>
              Исходная частота кадров от 24 до 30 в секунду.
            </p>

            <h3>
              7. Качественные характеристики видео
            </h3>

            <p>
              Стыки (монтажные склейки) из двух и более сцен не должны сопровождаться какими-либо эффектами перехода. Для обеспечения естественного ощущения просмотра не рекомендуется стыковать (монтировать) сцены одной крупности.
            </p>

            <h3>
              8. Субтитры
            </h3>

            <p>
              Субтитры должны дублировать речь и звуки с экрана. Субтитры должны быть выполнены на русском языке, с соблюдением грамматики и правил пунктуации. Звуки и особенности речи должны сопровождаться соответствующими ремарками.
            </p>

            <p>
              Продолжительность текста субтитра в 2-е строки (максимальная). Полные две строки субтитра (содержащие 14-16 слов) должны задерживаться на экране не меньше, чем на 5 1/2 секунд. Субтитры не должны появляться одновременно с произношением реплики, а с запозданием на 1/4 секунды.
            </p>

            <p>
              Субтитры нельзя оставлять в кадре более двух секунд после конца произнесения, даже если за эти две секунды не последовало новой реплики. Временной промежуток между двумя «соседними» субтитрами должен быть 1/4 секунды.
            </p>

            <p>
              Субтитры должны быть сохранены во внешнем файле, в формате WebVTT (Timed text), описание формата <a href="https://www.w3.org/TR/webvtt1/">https://www.w3.org/TR/webvtt1/</a>. Наложение субтитров на видео запрещено.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default TermsRumcVideo
