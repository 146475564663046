import React from 'react'
// import PropTypes from 'prop-types';

import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Index'
import Rumcs from './Rumcs'
import Monitoring from './Monitoring'

export default function Universities () {
  return (
    <Switch>
      <Route path={Routes.universities_monitoring_path}>
        <Monitoring />
      </Route>

      <Route path={Routes.universities_rumcs_path}>
        <Rumcs />
      </Route>

      <Route path={Routes.universities_path} exact strict>
        <Index />
      </Route>
    </Switch>
  )
}
