import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import I18n from '../I18n';
import { path } from '../Routes';

import Loaf from '../Loaf/Loaf';

import page from '../Page.module.css';
import styles from './Story.module.css';
import buttons from '../Buttons.module.css';
import fonts from '../Fonts.module.css';

class Story extends React.Component {
  state = {
    story: null
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  componentWillUnmount() {
    if (this._asyncRequest) this._asyncRequest.cancel();
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('abiturs_story_path', { id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          story: res.data.story,
        });
        this._asyncRequest = null;
      });
  }

  render() {
    const { story } = this.state;

    // if(!story) return null;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('abiturs_path')}>{I18n.t('meta.abiturs.title')}</Link>
            <Link to={path('abiturs_stories_path')}>{I18n.t('stories.title')}</Link>
          </Loaf>

          {story &&
            <>
              {story.can_edit &&
                <div className={page.control}>
                  <Link to={path('edit_account_story_path', {id: story.id })} className={buttons.small}>Редактировать историю</Link>
                </div>
              }
              <div className={page.title}>
                <h1>
                  {story.title}
                </h1>

                <Helmet>
                  <title>{story.title}</title>
                </Helmet>
              </div>

              <div className={page.text}>
                <div className={styles.video}>
                  <iframe width="560" height="315" src={`https://www.youtube.com/embed/${story.youtube_id}?modestbranding=1&autohide=1&rel=0&cc_load_policy=1`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
                </div>

                <div className={styles.desc}>
                  <h3>
                    {story.name}
                  </h3>

                  <p>
                    {story.qualification}
                    <br />
                    {story.job}
                  </p>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}

export default Story;
