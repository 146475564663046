import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import redraft from 'redraft'

import I18n from '../I18n'
import Attachments from '../Attachments/Public'
import Link, { path } from '../Routes'
import Loaf from '../Loaf/Loaf'

import styles from './Show.module.css'
import page from '../Page.module.css'

const renderers = {
  inline: {
    BOLD: (children, { key }) => <strong key={key}>{children}</strong>
  },
  blocks: {
    unstyled: (children) => children.map(child => <p>{child}</p>),
    blockquote: (children) => <blockquote>{children}</blockquote>,
    'header-three': (children) => children.map(child => <h2>{child}</h2>),
    'header-two': (children) => children.map(child => <h2>{child}</h2>),
    'unordered-list-item': (children, { depth, keys }) => <ul key={keys[keys.length - 1]} className={`ul-level-${depth}`}>{children.map(child => <li>{child}</li>)}</ul>,
    'ordered-list-item': (children, { depth, keys }) => <ol key={keys.join('|')} className={`ol-level-${depth}`}>{children.map((child, index) => <li key={keys[index]}>{child}</li>)}</ol>
  },
  entities: {
    LINK: (children, data, { key }) => <a key={key} target="_blank" href={data.url}>{children}</a>
  }
}

export default function Show ({ type }) {
  const cancelToken = useRef(axios.CancelToken.source())
  const { id, stage_id } = useParams()

  const [stage, setStage] = useState()
  const [object, setObject] = useState()
  const [attachments, setAttachments] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path(`stage_${type}_path`, { id, stage_id }, true), { cancelToken: cancelToken.current.token })

      setStage(data.stage)
      setObject(data.object)
      setAttachments(data.attachments)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  let desc = null

  if (stage) {
    desc = redraft(stage.desc, renderers)
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to={`${type}s_path`}>{I18n.t(`${type}s.title`)}</Link>
          {object &&
            <Link to={`${type}_path`} params={{ id: object.id }}>{object.title}</Link>
          }
        </Loaf>

        {stage &&
          <>
            <div className={page.title}>
              <h1>
                {stage.title}
              </h1>

              <Helmet>
                <title>{stage.title}</title>
              </Helmet>
            </div>

            <div className={page.text}>
              {stage.youtube_id &&
                <div className={styles.youtube}>
                  <iframe src={`https://www.youtube.com/embed/${stage.youtube_id}?modestbranding=1&autohide=1&showinfo=0&rel=0&cc_load_policy=1`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
                </div>
              }

              {attachments && attachments.length > 0 &&
                <div className={styles.attachments}>
                  <Attachments attachments={attachments} />
                </div>
              }

              <div className={styles.desc}>
                {desc}
              </div>
            </div>
          </>
        }

      </div>
    </div>
  )
}
