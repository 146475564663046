import React from 'react'
import PropTypes from 'prop-types'

import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import update from 'immutability-helper'
import AsyncSelect from 'react-select/async'
import classNames from 'classnames'

import I18n from '../../I18n'
import { path } from '../../Routes'

import Loaf from '../../Loaf/Loaf'
import Files from '../../Attachments/Files'

import page from '../../Page.module.css'
import form from '../../Form.module.css'
import buttons from '../../Buttons.module.css'

class Form extends React.Component {
  state = {
    errors: {},
    career: null,
    attachments: [],
    values: {
      title: '',
      desc: '',
      occupation: '',
      areas: '',
      responsibilities: '',
      competences: '',
      conditions: '',
      nosologies: {
        e: false,
        h: false,
        m: false
      },
      etks: '',
      attachment_ids: [],
      attachments_attributes: []
    },
    directions: [],
    submit: {
      url: path('account_careers_path', {}, true),
      method: 'post'
    },
    send: false,
    redirect_to: null
  }

  componentDidMount () {
    if(this.props.id) {
      this._loadAsyncData(this.props.id)
    }
  }

  componentWillUnmount () {
    if (this._asyncRequest) {
      this._asyncRequest.cancel()
    }
  }

  _loadAsyncData (id) {
    this._asyncRequest = axios.CancelToken.source()
    axios.get(path('edit_account_career_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState(prevState => ({
          career: res.data.career,
          values: {
            title: res.data.career.title,
            desc: res.data.career.desc,
            occupation: res.data.career.occupation,
            areas: res.data.career.areas,
            responsibilities: res.data.career.responsibilities,
            competences: res.data.career.competences,
            conditions: res.data.career.conditions,
            nosologies: update(prevState.values.nosologies, { $merge: res.data.career.nosologies }),
            etks: res.data.career.etks
          },
          attachments: res.data.attachments,
          directions: res.data.career.directions,
          submit: {
            method: 'patch',
            url: path('account_career_path', { id: id }, true)
          }
        }))

        this._asyncRequest = null
      })
  }

  getDirections = inputValue => {
    return axios.get('/directions.json', { params: { q: inputValue } }).then(res => {
      return res.data.slice(0, 100)
    })
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }))
  }

  handleNosologyChange = (e) => {
    const target = e.target
    const value = target.checked
    const name = target.name
    const values = update(this.state.values, {
      nosologies: {
        [name]: { $set: value }
      }
    })

    this.setState({
      values: values
    })
  }

  handleDirectionsChange = (value) => {
    this.setState({
      directions: value
    })
  }

  handleSubmit = (e) => {
    if (this.canSubmit()) {
      this.setState({
        send: true
      })

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        data: {
          career: {
            ...this.state.values,
            nosologies: Object.entries(this.state.values.nosologies).filter(ob => ob[1]).map(ob => ob[0]),
            direction_ids: this.state.directions.map(direction => direction.id)
          }
        }
      }).then(
        res => {
          this.props.history.push(res.headers.location)
        }
      ).catch((error) => {
        this.setState({
          errors: error.response.data,
          send: false
        })
      }).then(() => {
        this._asyncRequest = null;
      })
    }

    e.preventDefault()
  }

  handleFilesChanged = files => {
    this.setState(prevState => ({
      values: { ...prevState.values,
        attachments_attributes: [...files.values()].map(file => ({ id: file.id, title: file.title, section: file.section })),
        attachment_ids: [...files.values()].map(file => file.id)
      }
    }))
  }

  handleDestroy = () => {
    axios.delete(path('career_path', { id: this.props.id }, true))
      .then(res => {
        this.setState({
          redirect_to: `${path('account_list_path')}/careers`
        })
      })
  }

  canSubmit = () => {
    return (
      (this.state.values.title && this.state.values.title.length < 70) &&
      (this.state.values.desc && this.state.values.desc.length < 280) &&
      this.state.values.etks && this.state.directions.length > 0 &&
      Object.entries(this.state.values.nosologies).filter(ob => ob[1]).length > 0 &&
      !this.state.send
    )
  }

  render() {
    const { career, errors } = this.state

    if (this.state.redirect_to) return <Redirect to={this.state.redirect_to} />

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={path('account_path')}>{I18n.t('account.title')}</Link>
            <Link to={path('account_careers_path')}>{I18n.t('careers.title')}</Link>
          </Loaf>
          <div className={page.title}>
            <h1>
              {career ? 'Редактирование профессии' : 'Новая профессия' }
            </h1>
          </div>

          <div className={form.tight}>
            <p>
              При заполнении формы просим руководствоваться «доброжелательной политикой к пользователям», т.е. учитывая что пользователями данного раздела являются абитуриенты (которые зачастую не знают направления подготовки/специальности, а порой и не могут определиться с профессией) наша задача максимально эффективно донести «понятную для них информацию», избежав официального, бюрократического языка.
            </p>
            <p>
              В связи с этим в поле «Название» вносится наименование профессии с учетом конкретной привязки к рабочему месту и исполняемым функциям, избегайте общих пространных терминов. Например, указав профессию «Программист» мы очерчиваем практически бескрайнее поле применение профессиональных навыков и компетенций. А указав «Разработчик мобильных приложений», и далее раскрыв в других полях и область применения профессиональных знаний и навыков, и описав чем конкретно (в той или иной степени) будет заниматься пользователь, выбор вида деятельности становится более ясным и понятным. Учитывайте и тот момент, что не стоит делать слишком конкретную профессию, например «Учитель биологии» и «Учитель химии» не должны быть двумя разными профессиями.
            </p>
            <p>
              Однако, для оптимальной работы системы также необходимы и формальные данные, которые указываются в поле «Ссылка на профстандарт и/или наименование должности согласно разделу ЕТКС». Поле «Направление подготовки» обязательно для заполнения и к нему нужно отнестись внимательно, посколько абитуриент после процедуры выбора профессии может перерейти к дополнительными фильтрами по высшим учебным заведениям и сделать окончательный выбор, задав индивидуальные параметры (регион, наличие оборудование, общий рейтинг готовности вуза к обучению лиц с ОВЗ и инвалидностью).
            </p>

            <form className="form" onSubmit={this.handleSubmit}>
              <div className="input">
                <div className="input_label">
                  <label>
                    Название
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="title" value={this.state.values.title} onChange={this.handleInputChange} />
                </div>

                {this.state.values.title.length >= 70 &&
                  <div className={form.error}>
                    слишком длинное название
                  </div>
                }

                <div className="input_desc">
                  Название профессии, не более 70 символов.
                </div>
              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    Аннотация
                  </label>
                </div>

                <div className="input_input">
                  <textarea name="desc" value={this.state.values.desc} rows="9" onChange={this.handleInputChange} />
                </div>

                {this.state.values.desc.length >= 280 &&
                  <div className={form.error}>
                    слишком длинный текст
                  </div>
                }

                <div className="input_desc">
                  Краткое понятное описание профессии. Не более 260 символов, более подробно описывающее заголовок профессии.
                </div>
              </div>

              <div className="input">
                <div className="input_label">
                  Нозологии
                </div>

                <div className="input_input">
                  <div className={form.checkbox}>
                    <label>
                      <input type="checkbox" name="e" checked={this.state.values.nosologies.e} onChange={this.handleNosologyChange} />
                      Слабовидящие
                    </label>
                  </div>
                  <div className={form.checkbox}>
                    <label>
                      <input type="checkbox" name="h" checked={this.state.values.nosologies.h} onChange={this.handleNosologyChange} />
                      Слабослышащие
                    </label>
                  </div>
                  <div className={form.checkbox}>
                    <label>
                      <input type="checkbox" name="m" checked={this.state.values.nosologies.m} onChange={this.handleNosologyChange} />
                      Маломобильные
                    </label>
                  </div>
                </div>
              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    Направления подготовки
                  </label>
                </div>

                <div className="input_input">
                  <AsyncSelect
                    classNamePrefix="react-select"
                    name="direction_ids"
                    isMulti
                    noOptionsMessage={({ inputValue }) => inputValue === '' ? 'Введите часть названия направления или кода' : 'Нет доступных результатов'}
                    placeholder="Укажите направления подготовки..."
                    value={this.state.directions}
                    getOptionLabel={option => option.to_label}
                    getOptionValue={option => option.id}
                    onChange={this.handleDirectionsChange}
                    loadOptions={this.getDirections}
                  />
                </div>
              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    Ссылка на профстандарт и/или наименование должности согласно ЕКСД
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="etks" value={this.state.values.etks} onChange={this.handleInputChange} />
                </div>
              </div>

              {this.state.career && this.state.career.state !== 'inactive' && this.state.career.state !== 'declined' &&
                <React.Fragment>
                  {'occupation areas responsibilities competences conditions'.split(' ').map((i, _) =>
                    <div className="input" key={_}>
                      <div className="input_label">
                        <label>
                          {I18n.t(`simple_form.labels.career.${i}`)}
                        </label>
                      </div>

                      <div className="input_input">
                        <textarea name={i} value={this.state.values[i]} rows="6" onChange={this.handleInputChange} />
                      </div>

                      {this.state.values[i] !== null && this.state.values[i].length >= 800 &&
                        <div className={form.error}>
                          слишком длинный текст
                        </div>
                      }

                      <div className="input_desc">
                        Не более 800 символов.
                      </div>
                    </div>
                  )}

                  <Files
                    files={this.state.attachments}
                    onFilesChanged={this.handleFilesChanged}
                    sections={['media', 'subtitles']}
                  />
                </React.Fragment>
              }

              <div className={form.submit}>
                <input type="submit" value="Сохранить профессию" className={buttons.main} disabled={!this.canSubmit()} />

                {career && career.can_destroy &&
                  <div className={classNames(buttons.discard, form.discard)} onClick={this.handleDestroy}>
                    Удалить
                  </div>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
