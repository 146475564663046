import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import AsyncSelect from 'react-select/async';

import { Routes, path } from '../Routes';

import Attachments from '../Attachments/Attachments';

import form from '../Form.module.css';
import buttons from '../Buttons.module.css';
import page from '../Page.module.css';

class Form extends React.Component {
  state = {
    send: false,
    complete: false,
    attachments: [],
    tomorrow_request: null,
    values: {
      authors: '',
      disability: false,
      year: '',
      phone: '',
      email: this.props.user ? this.props.user.email : '',
      title: '',
      desc: '',
      url: '',
      attachment_ids: [],
      attachments_attributes: [],
    },
    curator: {
      name: '',
      phone: '',
      email: ''
    },
    submit: {
      url: path('tomorrow_tomorrow_requests_path', {}, true),
      method: 'post',
    },
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }));
  }

  handleCuratorInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(state => ({
      curator: { ...state.curator, [name]: value }
    }));
  }

  handleCheckboxChange = (e) =>  {
    const target = e.target;
    const value = target.checked;
    const name = target.name;

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.attachments !== prevState.attachments) {
      this.setState(prevState => ({
        values: { ...prevState.values,
          attachments_attributes: this.state.attachments.map(a => ({id: a.id, title: a.title, section: a.section}) ),
          attachment_ids: this.state.attachments.map(a => a.id),
        }
      }));
    }
  }

  _loadSchools = inputValue => {
    return axios.get(path('list_schools_path', {}, true), { params: { q: inputValue } }).then(res => {
      return res.data.schools.slice(0, 100);
    });
  }

  _loadDirections = inputValue => {
    return axios.get(path('list_directions_path', {}, true), { params: { q: inputValue } }).then(res => {
      return res.data.directions.slice(0, 100);
    });
  }

  handleAttachmentsUpdate = (attachments) => {
    this.setState({ attachments: attachments });
  }

  handleSubmit = (e) => {
    if(this._canSubmit()) {
      this.setState({ send: true });
      this._asyncRequest = axios.CancelToken.source();

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        cancelToken: this._asyncRequest.token,
        data: { tomorrow_request: { ...this.state.values, curator: this.state.curator } }
      }).then(
        res => {
          this.setState({ send: false, complete: true });
        }
      ).catch((error) => {
        console.log(error);
        this.setState({ send: false, complete: true });
      }).then(() => {
        this._asyncRequest = null;
      });
    }

    e.preventDefault();
  }

  _canSubmit = () => {
    return (
      this.state.values.authors &&
      this.state.values.title &&
      this.state.values.nomination &&
      this.state.values.desc &&
      this.state.values.school_id &&
      this.state.values.direction_id &&
      !this.state.complete &&
      !this.state.send
    );
  }

  render() {
    const { I18n, user } = this.props;
    const { values, attachments, curator } = this.state;

    const title = 'Новая конкурсная работа';


    return (
      <div className={page.form}>
        <h2>
          {title}
        </h2>

        <Helmet>
          <title>
            {title}
          </title>
        </Helmet>

        {user ? (
          <React.Fragment>
            {this.state.complete ? (
              <p>
                Спасибо. Вы успешно отправили конкурсную работу на рассмотрение. В ближайшее время мы сможете увидеть список ваших работ и их статус.
              </p>
            ) : (
              <form className={form.root} onSubmit={this.handleSubmit}>
                <div className={form.elem}>
                  <div className={form.label}>
                    Название работы
                  </div>

                  <div className={form.input}>
                    <input type="text" name="title" value={values.title} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className={form.elem}>
                  <div className={form.label}>
                    Номинация
                  </div>

                  <div className="input_input">
                    <select name="nomination" onChange={this.handleInputChange} value={this.state.values.nomination}>
                      <option />
                      <option value="1">Эссе «Почему я ценный и полезный работник: 10 аргументов»</option>
                      <option value="2">Профессиональный старт-ап молодежи</option>
                      <option value="3">Исследовательская работа</option>
                      <option value="4">Мое полезное изобретение</option>
                      <option value="5">Социальная реклама</option>
                    </select>
                  </div>
                </div>

                <div className={form.elem}>
                  <div className={form.label}>
                    Аннотация
                  </div>

                  <div className={form.input}>
                    <textarea name="desc" value={values.desc} onChange={this.handleInputChange} rows="5" />
                  </div>

                  <div className={form.hint}>
                    Краткое описание конкурсной работы объемом до 500 знаков
                  </div>
                </div>

                <div className={form.elem}>
                  <div className={form.label}>
                    Ссылка на работу
                  </div>

                  <div className={form.input}>
                    <input type="text" name="url" value={values.url} onChange={this.handleInputChange} />
                  </div>

                  <div className={form.hint}>
                    В случае если проект (видеофильм, ролик и т.д) размещены на каких-либо сайтах, например, YouTube.
                  </div>
                </div>

                <div className="input">
                  <div className="input_input">
                    <Attachments onAttachmentsUpdate={this.handleAttachmentsUpdate} attachments={attachments} />
                  </div>
                </div>

                <br />
                <br />

                <div className={form.elem}>
                  <div className={form.label}>
                    ФИО участников работы
                  </div>

                  <div className={form.input}>
                    <input type="text" name="authors" value={values.authors} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className={form.elem}>
                  <div className={form.input}>
                    <div className={form.checkbox}>
                      <label>
                        <input type="checkbox" name="disability" checked={values.disability} onChange={this.handleCheckboxChange} />
                        Наличие инвалидности
                      </label>
                    </div>
                  </div>
                </div>

                {values.disability &&
                  <div className={form.elem}>
                    <div className={form.label}>
                      Нозологическая группа
                    </div>

                    <div className="input_input">
                      <select name="nosology_id" onChange={this.handleInputChange} value={this.state.values.nosology_id}>
                        <option />
                        <option value="1">Нарушения зрения</option>
                        <option value="2">Нарушения слуха</option>
                        <option value="3">Нарушения опорно-двигательного аппарата</option>
                        <option value="4">Иные нозологические категорий</option>
                        <option value="5">Общие заболевания</option>
                      </select>
                    </div>
                  </div>
                }

                <div className={form.elem}>
                  <div className={form.label}>
                    Курс обучения
                  </div>

                  <div className={form.input}>
                    <input type="text" name="year" value={values.year} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className={form.elem}>
                  <div className={form.label}>
                    Название вуза
                  </div>

                  <div className={form.input}>
                    <AsyncSelect
                      classNamePrefix="react-select"
                      noOptionsMessage={({inputValue}) => inputValue == '' ? 'Введите часть названия вуза' : 'Нет доступных результатов'}
                      getOptionLabel={option => option.title}
                      getOptionValue={option => option.id}
                      loadOptions={this._loadSchools}
                      placeholder="Выберите вуз.."
                      onChange={value => this.setState(state => ({ values: { ...state.values, school_id: value.id }}))}
                    />
                  </div>
                </div>

                <div className={form.elem}>
                  <div className={form.label}>
                    Направление подготовки (специальность)
                  </div>

                  <div className={form.input}>
                    <AsyncSelect
                      classNamePrefix="react-select"
                      noOptionsMessage={({inputValue}) => inputValue == '' ? 'Введите часть названия направления' : 'Нет доступных результатов'}
                      getOptionLabel={option => option.title}
                      getOptionValue={option => option.id}
                      loadOptions={this._loadDirections}
                      placeholder="Выберите направление.."
                      onChange={value => this.setState(state => ({ values: { ...state.values, direction_id: value.id }}))}
                    />
                  </div>
                </div>

                <div className={form.elem}>
                  <div className={form.label}>
                    Телефон (моб) участника
                  </div>

                  <div className={form.input}>
                    <input type="text" name="phone" value={values.phone} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className={form.elem}>
                  <div className={form.label}>
                    Электронная почта участника
                  </div>

                  <div className={form.input}>
                    <input type="text" name="email" value={values.email} onChange={this.handleInputChange} />
                  </div>
                </div>

                <br />
                <br />

                <div className={form.elem}>
                  <div className={form.label}>
                    ФИО куратора от вуза
                  </div>

                  <div className={form.input}>
                    <input type="text" name="name" value={curator.name} onChange={this.handleCuratorInputChange} />
                  </div>
                </div>

                <div className={form.elem}>
                  <div className={form.label}>
                    Телефон (моб) куратора
                  </div>

                  <div className={form.input}>
                    <input type="text" name="phone" value={curator.phone} onChange={this.handleCuratorInputChange} />
                  </div>
                </div>

                <div className={form.elem}>
                  <div className={form.label}>
                    Электронная почта куратора
                  </div>

                  <div className={form.input}>
                    <input type="text" name="email" value={curator.email} onChange={this.handleCuratorInputChange} />
                  </div>
                </div>

                <div className={form.submit}>
                  <input type="submit" value="Сохранить" className={buttons.main} disabled={!this._canSubmit()} />
                </div>
              </form>
            )}
          </React.Fragment>
        ) : (
          <div>
            <p>
              Отправить конкурсную работу могут только зарегистрированные пользователи. Войдите или зарегистрируйтесь.
            </p>

            <p>
              <a href={`${path('login_path')}?return_to_url=${path('new_tomorrow_tomorrow_request_path')}`} className={buttons.main}>Войти</a>
            </p>
          </div>
        )}

      </div>
    );
  }
}

export default Form
