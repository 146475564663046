import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'

import Link, { path } from '../../Routes'
import I18n from '../../I18n'
import Loaf from '../../Loaf/Loaf'
import { Errors } from '../../Form'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
import form from '../../Form.module.css'

export default function Form () {
  const { id, plan_id: planId } = useParams()
  const history = useHistory()
  const cancelToken = useRef(axios.CancelToken.source())

  const [module, setModule] = useState()
  const [values, setValues] = useState({
    title: ''
  })
  const [send, setSend] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(
        id ? path('edit_account_plan_module_path', { id }, true) : path('new_account_plan_plan_module_path', { plan_id: planId }, true),
        {
          cancelToken: cancelToken.current.token
        }
      )

      setModule(data.module)
      setValues(data.values)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [id])

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    if (id) {
      handleUpdate()
    } else {
      handleCreate()
    }
  }

  const handleCreate = async () => {
    await axios.post(
      path('account_plan_plan_modules_path', { plan_id: planId }),
      { plan_module: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const handleUpdate = async () => {
    await axios.patch(
      path('account_plan_module_path', { id }),
      { plan_module: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
          <Link to="account_plans_path">{I18n.t('account.plans.title')}</Link>
          {module &&
            <Link to="account_plan_path" params={{ id: module.plan.id }}>{module.plan.title}</Link>
          }
        </Loaf>
        <div className={page.title}>
          <h1>
            {id ? 'Редактирование' : 'Новый модуль' }
          </h1>
        </div>

        <div className={form.tight}>
          <form onSubmit={handleSubmit}>
            <div className={form.el}>
              <label>
                <div className={form.label}>
                  Название модуля
                </div>

                <div className={form.input}>
                  <input
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleInputChange}
                  />
                </div>
              </label>

              <Errors errors={errors.title} />
            </div>

            <div className={form.submit}>
              <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
