import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'

import page from '../../Page.module.css'

export default function Show () {
  const { form_id: formId, id } = useParams()

  const [form, setForm] = useState()
  const [formResult, setFormResult] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('form_form_result_path', { form_id: formId, id }, true))

      setForm(data.form)
      setFormResult(data.form_result)
    }

    _fetch()
  }, [formId, id])

  return (
    <div className={page.root}>
      <div className={page.container}>
        <Loaf>
          <Link to="forms_path">Все анкеты</Link>
        </Loaf>

        {form && formResult &&
          <div className={page.narrow}>
            <div className={page.title}>
              <h1>Анкета № {formResult.id}</h1>

              <Helmet>
                <title>
                  {`Анкета № ${formResult.id}`}
                </title>
              </Helmet>
            </div>

            <p>
              Спасибо что заполнили анкету, она будет учтена в общем мониторинге.
            </p>
          </div>
        }
      </div>
    </div>
  )
}
