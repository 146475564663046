import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Routes } from '../Routes.js.erb';
import Loaf from '../Loaf/Loaf';

import page from '../Page.module.css';

class TermsRumcUmm extends React.Component {
  render() {
    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to={Routes.terms_path}>Правила</Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              Создание и публикация учебных материалов
            </h1>

            <Helmet>
              <title>
                Создание и публикация учебных материалов
              </title>
            </Helmet>
          </div>

          <div className={page.text}>
            <p>
              1. Для создания любого учебного материала (комплекса, пособиях, технологии или адаптационного модуля) необходимо зайти в личный кабинет и найти блок №5 «Учебно-методические материалы». Внутри блока есть соответствующие ссылки на создание учебно-методического материала. По клику на ссылку создания открывается форма добавление материала, которая содержит необходимые поля: название, тип и аннотацию, остальные поля будут доступны для заполнения после утверждения темы материала. После нажатия на кнопку «Сохранить материал» он уходит на утверждение. Список ваших материалов можно посмотреть по ссылке «Ваши материалы».
            </p>
            <p>
              2. После создания материала он находится в статусе «Новый» и ожидает подтверждения темы модератором. От сотрудников ресурсных центров никаких действий не требуется. После утверждения или отклонения темы автор получает уведомление об этом на почту. В случае отклонения будет указана причина отказа и вы сможете внести правки в форму редактирования материала и повторно отправить на утверждения.
            </p>

            <p>
              3. После успешного утверждения темы материала он переходит в статус «Активный», это означает что от сотрудников центров требуется заполнить недостающие поля и загрузить необходимые файлы. К каждому материалу можно загрузить любое количество файлов. Около названий файлов есть обязательные метки, которые нужно проставить напротив соответствующих файлов.
            </p>

            <p>
              4. Когда все поля и файлы будут заполнены — у вас появится возможность перевести материал в статус «Согласование». На этом этапе вам будут назначены два анонимных эксперта, которые должны будут подготовить свою рецензию и заклюение на ваш материал.
            </p>

            <p>
              5. В случае положительных заключений экспертов — вы сможете перевести материал в статус «Готов». На этом этапе его снова проверяет модератор. В случае необходимых правок он сообщит об этом, в случае их отсутствия — он отправит материал в статус «Опубликован».
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsRumcUmm;
