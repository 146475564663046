import React from 'react';
import axios from 'axios';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { path } from '../../Routes';
import I18n from '../../I18n'

import page from '../../Page.module.css';
import styles from './Charts.module.css';
import details from '../Schools/Details.module.css';

class ChartsList extends React.Component {
  state = {
    charts: [],
    overall: 0,
    rumcs: [],
    search: this.props.location.search,
    fields: [],
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('stats_form_chart_path', { id: this.props.match.params.id, form_id: this.props.match.params.form_id }, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search) })
      .then(res => {
        this.setState({
          charts: res.data.charts,
          filters: res.data.filters,
          overall: res.data.overall,
        });

        this._asyncRequest = null;
      });
  }

  static getDerivedStateFromProps(props, state) {
    console.log('LOG');

    if(props.location.search !== state.search) {
      return {
        charts: null,
        search: props.location.search,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.charts === null) this._loadAsyncData();
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  render() {
    const { charts, overall, filters } = this.state;
    const form_id = this.props.match.params.form_id;
    if(!charts) return null;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <div>
            <p>Общее число анкет: {overall}</p>
          </div>

          {filters &&
            <div>
              {Object.entries(filters).map(ob =>
                <div>
                  <strong>
                    {ob[1].label}: 
                  </strong>
                  {ob[1].items.map(item =>
                    <Link to={`${path('stats_form_chart_path', { id: this.props.match.params.id, form_id: this.props.match.params.form_id })}?${qs.stringify({ filters: [ob[0]], [ob[0]]: [item.id] })}`}>
                      {item.label} 
                    </Link>
                  )}
                </div>
              )}
            </div>
          }

          {charts && charts.map((block, _) =>
            <div className={styles.field} key={_}>
              <ChartBlock block={block} overall={overall} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

class ChartBlock extends React.Component {
  render () {
    const { block, overall } = this.props;

    return (
      <>
        <div className={styles.field_title}>{block.title}</div>
        { block.labels.map((item, _) =>
          <div className={details.root}>
            <div className={details.item}>
              <div className={details.label}>
                <div className={details.value}>
                  <span className={details.percents}>
                    {`${(item.value / overall).toLocaleString(I18n.locale, { style: 'percent' })} (${item.value})`}
                  </span>
                </div>

                <div className={details.title}>
                  {item.title}
                </div>
              </div>

              <div className={details.bar}>
                <div className={details.percent} style={{width: `${parseInt(item.value / overall * 100)}%`}}></div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}



export default ChartsList
