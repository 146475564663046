import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import Select from 'react-select'
import axios from 'axios'
import classNames from 'classnames'

import Current from '../Current'

import Link, { path } from '../Routes'
import I18n from '../I18n'
import { Errors } from '../Form'

import buttons from '../Buttons.module.css'
import form from '../Form.module.css'
import page from '../Page.module.css'
import styles from './New.module.css'

function Registration (props) {
  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <div className={page.title}>
          <h1>
            Новая консультация
          </h1>

          <Helmet>
            <title>Новая консультация</title>
          </Helmet>
        </div>

        <div className={page.text}>
          <p>
            Вопросы, направленные через официальный сайт инклюзивноеобразование.рф, поступают в
            ресурсные учебно-методические центры по обучению инвалидов и лиц с ограниченными
            возможностями здоровья (далее – РУМЦ), находящиеся в регионе проживания заявителя,
            и рассматриваются сотрудниками данных центров либо уполномоченными лицами.
          </p>

          <p>
            Чтобы задать вопрос необходимо войти или зарегистрироваться, это займет не более одной минуты,
            нужно только указать адрес своей электронной почты или войти через соцсети.
          </p>

          <p>
            <Link to="login_path" params={{ query: { redirect_to: encodeURI(path('new_request_path')) } }} className={buttons.main}>
              Войти
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default function New () {
  const cancelToken = useRef(axios.CancelToken.source())
  const { user } = useContext(Current)
  const history = useHistory()

  const [values, setValues] = useState()
  const [dictionaries, setDictionaries] = useState()
  const [send, setSend] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('new_request_path', {}, true), { cancelToken: cancelToken.current.token })

      setValues(data.values)
      setDictionaries(data.dictionaries)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    await axios.post(
      path('requests_path'),
      { request: values },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value })
  }

  if (!user) return <Registration />

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <div className={page.title}>
          <h1>{I18n.t('request.title')}</h1>

          <Helmet>
            <title>{I18n.t('request.title')}</title>
          </Helmet>
        </div>

        <div className={form.tight}>
          <div className={styles.desc}>
            <p>Вопросы, направленные через официальный сайт инклюзивноеобразование.рф,
            поступают в ресурсные учебно-методические центры по обучению инвалидов и лиц с
            ограниченными возможностями здоровья (далее – РУМЦ), находящиеся в регионе
            проживания заявителя, и рассматриваются сотрудниками данных центров либо
            уполномоченными лицами.</p>
            <p>Ответ на ваш вопрос либо уведомление о его переадресации направляется
            по адресу электронной почты, на которую зарегистрирован пользователь.
            В случае если текст вашего вопроса не позволяет определить его суть,
            то ответ на него не дается, и он не подлежит направлению
            на рассмотрение в РУМЦ, о чем вам будет сообщено в течение семи дней со дня
            регистрации вопроса.</p>
            <p>Перед отправкой вопроса внимательно ознакомьтесь со следующей информацией.</p>
            <p>1. Перед отправкой вопроса, необходимо заполнить личные данные, содержащие:
            категорию граждан к которой вы относитесь, регион местонахождения. Поле «контактный телефон» не является обязательным к заполнению.</p>
            <p>2. Вопрос оформляется путем набора текстового сообщения в диалоговом окне.</p>
            <p>3. После заполнения всех полей необходимо нажать на кнопку «Направить вопрос».</p>
            <p>4. Чтобы узнать статус вашего обращения, необходимо перейти в личный кабинет, в раздел «Вопросы».</p>
            <p>5. Срок рассмотрения обращения – 7 календарных дней.</p>
          </div>

          {values && dictionaries &&
            <form onSubmit={handleSubmit}>
              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Регион местонахождения <abbr title="обязательно">*</abbr>
                  </div>

                  <div className={form.input}>
                    <Select
                      classNamePrefix="react-select"
                      // value={dictionaries.regions.find(region => region.id === values.region_id)}
                      getOptionValue={option => option.id}
                      noOptionsMessage={() => 'Ничего не найдено'}
                      options={dictionaries.regions}
                      placeholder="Выберите регион.."
                      onChange={value => setValues({ ...values, region_id: value.id })}
                    />
                  </div>
                </label>

                <div className={form.hint}>
                  Регион необходим чтобы определить РУМЦ, в который будет направлен вопрос
                </div>

                <Errors errors={errors.region} />
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Текст вопроса <abbr title="обязательно">*</abbr>
                  </div>

                  <div className={form.input}>
                    <textarea name="body" value={values.body} rows="9" onChange={handleInputChange} />
                  </div>
                </label>

                <Errors errors={errors.body} />
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Категория <abbr title="обязательно">*</abbr>
                  </div>

                  <div className={form.input}>
                    <Select
                      classNamePrefix="react-select"
                      options={dictionaries.audiences}
                      getOptionValue={option => option.id}
                      placeholder="Выберите вашу категорию.."
                      isSearchable={false}
                      onChange={value => setValues({ ...values, audience_id: value.id })}
                    />
                  </div>
                </label>

                <Errors errors={errors.audience} />
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Контактный телефон
                  </div>

                  <div className={form.input}>
                    <input
                      type="tel"
                      name="phone"
                      value={values.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                </label>

                <Errors errors={errors.phone} />
              </div>

              <div className={form.el}>
                <div className={form.hint}>
                  {user.name}
                  <br />
                  Ответ, уведомления и прочая информация будут направлены вам на почту {user.email}
                </div>
              </div>

              <div className={form.submit}>
                <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                  Направить вопрос
                </button>
              </div>
            </form>
          }
        </div>
      </div>
    </div>
  )
}
