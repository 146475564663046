import React, { Component } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import Link, { path } from '../Routes'
import I18n from '../I18n'
import Loaf from '../Loaf/Loaf'

import Events from '../Events/Block'
import Covid from '../Requests/Covid'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import styles from './Index.module.css'
import stylesTrainings from './UniversitiesIndexTrainings.module.css'

import RumcsIll from '../../images/pages/universities/rumcs.svg'
import MonitoringIll from '../../images/pages/universities/monitoring.svg'
import UmmsIll from '../../images/pages/universities/documents.svg'
import Practices from '../../images/pages/universities/practices.svg'
import TrSupp from '../../images/pages/universities/training_support.svg'

class Index extends Component {
  state = {
    events: null,
    show_list_publications: false
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) {
      this._asyncRequest.cancel()
    }
  }

  _loadAsyncData = async (id) => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('universities_path', {}, true), { cancelToken: this._asyncRequest.token })
    this._asyncRequest = null

    this.setState({ ...res.data })
  }

  render () {
    const { events, trainings, show_list_publications } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="root_path">{I18n.t('nav.root')}</Link>
          </Loaf>
          <div className={page.title}>
            <h1>
              {I18n.t('nav.universities')}
            </h1>

            <Helmet>
              <title>{I18n.t('nav.universities')}</title>
            </Helmet>
          </div>

          <div className={styles.main}>

            <section className={classNames(styles.section, styles.monitoring)}>
              <div className={styles.ill}>
                <img src={UmmsIll} />
              </div>

              <div className={styles.content}>
                <h2>
                  {I18n.t('pages.universities.files.title')}
                </h2>

                <p>
                  {I18n.t('pages.universities.files.desc')}
                </p>

                {I18n.locale === 'ru' &&
                  <p>
                    <Link to="types_equipment_index_path" className={buttons.main}>
                      Инфраструктурные листы
                    </Link>
                  </p>
                }
              </div>
            </section>

            <section className={classNames(styles.section, styles.rumcs)}>
              <div className={styles.ill}>
                <img src={RumcsIll} />
              </div>

              <div className={styles.content}>
                <h2>
                  {I18n.t('pages.universities.rumcs.title')}
                </h2>

                <p>
                  {I18n.t('pages.universities.rumcs.desc')}
                </p>

                  
                {I18n.locale === 'ru' && 
                  <div className={styles.section_rumc}>
                    <p>
                      <Link to="rumcs_path" className={buttons.main}>
                        Сеть ресурсных центров &nbsp;
                      </Link>
                    </p>

                    <div className={styles.flex_row}>
                      <div className={buttons.main} onClick={() => (this.setState({show_list_publications: !show_list_publications}))}>Список публикаций РУМЦ</div>
                      <div className={show_list_publications ? styles.flex_item_active : styles.flex_item}>
                        <a 
                          href="/uploads/document/press_reliz/List_university_publications_RUMC.docx" 
                          target="_blank"
                        >Скачать в формате .docx</a>
                      </div>
                      <div className={show_list_publications ? styles.flex_item_active : styles.flex_item}>
                        <a 
                          href="/uploads/document/press_reliz/List_university_publications_RUMC.pdf" 
                          target="_blank"
                        >Скачать в формате .pdf</a>
                      </div>
                    </div>
                  </div>
                }

                {I18n.locale === 'ru' && 
                  <a className={buttons.main} href="/uploads/document/press_reliz/digest_RUMCS.pdf">Дайджест результатов исследования в области инклюзивного высшего образования</a>
                }
                
              </div>
            </section>

            <section className={classNames(styles.section, styles.monitoring)}>
              <div className={styles.ill}>
                <img src={MonitoringIll} />
              </div>

              <div className={styles.content}>
                <h2>
                  {I18n.t('pages.universities.monitoring.title')}
                </h2>

                <p>
                  {I18n.t('pages.universities.monitoring.desc')}
                </p>

                {I18n.locale === 'ru' &&
                  <p>
                    <Link to="universities_monitoring_path" className={buttons.main}>
                      Информация
                    </Link>
                  </p>
                }
              </div>
            </section>

            <section className={classNames(styles.section, styles.umms)}>
              <div className={styles.ill}>
                <img src={TrSupp} />
              </div>

              <div className={styles.content}>
                <h2>
                  {I18n.t('pages.universities.umms.title')}
                </h2>

                <p>
                  {I18n.t('pages.universities.umms.desc')}
                </p>

                {I18n.locale === 'ru' &&
                  <>
                    <p>
                      <Link to="umms_path" className={buttons.main}>
                        Учебно-методические материалы
                      </Link>
                    </p>
                    <p>
                      <a 
                        href="/uploads/document/universities/Metodicheskie_rekomendacii_po_organizacii_IVO.pdf" 
                        className={buttons.main} target="_blank"
                      >
                        Методические рекомендации по организации инклюзивного образования
                      </a>
                    </p>
                  </>
                }
              </div>
            </section>
            


            <section className={classNames(styles.section, styles.practices)}>
              <div className={styles.ill}>
                <img src={Practices} className={styles.givc_ill} />
              </div>

              <div className={styles.content}>
                <h2>
                  {I18n.t('pages.universities.best_practices.title')}
                </h2>


                <p>
                  {I18n.t('pages.universities.best_practices.desc_4')}
                </p>
                {I18n.locale === 'ru' &&
                  <>
                    <a href="/uploads/document/press_reliz/Collection_best_practices_2.pdf" target="_blank" className={buttons.main}>
                      Скачать каталог
                    </a>
                  </>
                }


                <p>
                  {I18n.t('pages.universities.best_practices.desc_1')}
                </p>
                <p>
                  {I18n.t('pages.universities.best_practices.desc_2')}
                </p>
                <p>
                  {I18n.t('pages.universities.best_practices.desc_3')}
                </p>

                {I18n.locale === 'ru' &&
                  <>
                    <a href="/uploads/document/press_reliz/Collection_best_practices.pdf" target="_blank" className={buttons.main}>
                      Скачать сборник
                    </a>
                  </>
                }

              </div>
            </section>





            <section className={styles.practices_career} id="webinars">
              <div className={styles.practices_career_wrapper}>
                <h2>
                  Практики по профориентации и трудоустройству
                </h2>
                <span>материалы цикла вебинаров сети РУМЦ</span>
                <div className={styles.practices_career_items}>
                  <div className={styles.practices_career_item}>
                    <div className={styles.practices_career_item_name}>
                      <span>Вебинар 1.</span>
                      <span>Стратегии реализации приемной кампании вузов как ресурс формирования и развития карьерных траекторий лиц с ОВЗ и инвалидностью (28 июня 2022 г.)</span>
                    </div>
                    <div className={styles.practices_career_item_btns}>
                      <a href="https://инклюзивноеобразование.рф/uploads/news/vebinar_1.zip" className={buttons.main}>
                          Скачать материалы
                      </a>
                      <a href="https://bbb-records.edu.nstu.ru/playback/presentation/2.3/8c3229f94ae7b3374144a5100b38e6704abec296-1656398019395?meetingId=8c3229f94ae7b3374144a5100b38e6704abec296-1656398019395" target="_blank" className={buttons.main}>
                          Посмотреть запись
                      </a>
                    </div>
                  </div>

                  <div className={styles.practices_career_item}>
                    <div className={styles.practices_career_item_name}>
                      <span>Вебинар 2.</span>
                      <span>Трудоустройство инвалидов: права и возможности. Долгосрочная программа содействия занятости молодёжи до 2030 г. (11 октября 2022 г.)</span>
                    </div>
                    <div className={styles.practices_career_item_btns}>
                      <a href="https://инклюзивноеобразование.рф/uploads/news/vebinar_2.zip" className={buttons.main}>
                          Скачать материалы
                      </a>
                      <a href="https://youtu.be/czripPqCEZw" target="_blank" className={buttons.main}>
                          Посмотреть запись
                      </a>
                    </div>
                  </div>

                  <div className={styles.practices_career_item}>
                    <div className={styles.practices_career_item_name}>
                      <span>Вебинар 3.</span>
                      <span>Управленческие практики и организационные решения по содействию трудоустройству и постдипломному сопровождению выпускников с инвалидностью: опыт РУМЦ и вузов-партнёров (25 октября 2022 г.)</span>
                    </div>
                    <div className={styles.practices_career_item_btns}>
                      <a href="https://инклюзивноеобразование.рф/uploads/news/vebinar_3.zip" className={buttons.main}>
                          Скачать материалы
                      </a>
                      <a href="https://youtu.be/Jjo4icZcvgg" target="_blank" className={buttons.main}>
                          Посмотреть запись
                      </a>
                    </div>
                  </div>

                  <div className={styles.practices_career_item}>
                    <div className={styles.practices_career_item_name}>
                      <span>Вебинар 4.</span>
                      <span>Экосистема трудоустройства лиц с инвалидностью: взаимодействие вуза с  органами власти, работодателями, бизнес-сообществом, НКО (9 ноября 2022 г.)</span>
                    </div>
                    <div className={styles.practices_career_item_btns}>
                      <a href="https://инклюзивноеобразование.рф/uploads/news/vebinar_4.zip" className={buttons.main}>
                          Скачать материалы
                      </a>
                      <a href="https://youtu.be/X2QpvKbsKgg" target="_blank" className={buttons.main}>
                          Посмотреть запись
                      </a>
                    </div>
                  </div>

                  <div className={styles.practices_career_item}>
                    <div className={styles.practices_career_item_name}>
                      <span>Вебинар 5.</span>
                      <span>Ассистивные и цифровые сервисы поддержки трудоустройства инвалидов (16 ноября 2022 г.)</span>
                    </div>
                    <div className={styles.practices_career_item_btns}>
                      <a href="https://инклюзивноеобразование.рф/uploads/news/vebinar_5.zip" className={buttons.main}>
                          Скачать материалы
                      </a>
                      <a href="https://youtu.be/Vh-N8aYVM0E" target="_blank" className={buttons.main}>
                          Посмотреть запись
                      </a>
                    </div>
                  </div>

                  <div className={styles.practices_career_item}>
                    <div className={styles.practices_career_item_name}>
                      <span>Вебинар 6.</span>
                      <span>Самозанятость, поддержка предпринимательских инициатив и формирование soft-skills у обучающихся и выпускников вузов из числа лиц с инвалидностью (15 декабря 2022 г.)</span>
                    </div>
                    <div className={styles.practices_career_item_btns}>
                      <a href="https://инклюзивноеобразование.рф/uploads/news/vebinar_6.zip" className={buttons.main}>
                          Скачать материалы
                      </a>
                      <a href="https://youtu.be/qQPBLuxeG3c" target="_blank" className={buttons.main}>
                          Посмотреть запись
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </section>



            {I18n.locale === 'ru' && events &&
              <section className={classNames(styles.section, styles.events)}>
                <Events events={events} count={3} />
              </section>
            }

            {I18n.locale === 'ru' &&
              <section className={classNames(styles.section, styles.trainings)}>
                <h2>
                  Семинары
                </h2>

                {trainings &&
                  <div className={stylesTrainings.trainings}>
                    {trainings.map(training =>
                      <Link to="event_path" params={{ id: training.id }} className={classNames(stylesTrainings.training, styles.a_in_block)} key={training.id}>
                        <div className={stylesTrainings.content}>
                          <div className={stylesTrainings.title}>
                            {training.title}
                          </div>
                          <div className={stylesTrainings.date}>
                            {training.date_or_time_human}
                          </div>
                        </div>

                        <div className={stylesTrainings.more}>
                          Подробнее о семинаре
                        </div>
                      </Link>
                    )}
                  </div>
                }
                {!trainings &&
                  <p>Семинары, относящиеся к данному разделу, не найдены</p>
                }
              </section>
            }

            <section className={classNames(styles.section, styles.request)}>
              <Covid />
              {/* <RequestNewBlock text={'С 20 июня работает «горячая линия» сети ресурсных учебно-методических центров по вопросам организации обучения лиц с инвалидностью'} title={'Горячая линия по вопросам инклюзивного образования'} /> */}
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default Index
