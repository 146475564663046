import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Index from './Index'
import Show from './Show'

import { Routes } from '../Routes'

export default function Umms () {
  return (
    <Switch>
      <Route path={Routes.umm_path} render={props => <Show id={props.match.params.id} {...props} />} />
      <Route path={Routes.umms_path} component={Index} exact strict />
    </Switch>
  )
}
