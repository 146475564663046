import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import Link, { path } from '../../Routes'
import Loaf from '../../Loaf/Loaf'

import Files from '../../Attachments/Files'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'

export default function Upload () {
  const cancelToken = useRef(axios.CancelToken.source())

  const [upload, setUpload] = useState()
  // useEffect(() => {
  //   const _fetch = async () => {
  //     const { data } = await axios.get(path('form_form_result_path', { form_id: formId, id }, true))
  //
  //     setForm(data.form)
  //     setFormResult(data.form_result)
  //   }
  //
  //   _fetch()
  // }, [formId, id])

  const handleFilesChanged = files => {
    setUpload(files.get(Array.from(files.keys()).pop()))
  }

  const handleFileCheck = async () => {
    await axios.post(
      path('upload_check_forms_path'),
      { upload: upload },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
    })
    console.log(upload);
  }

  return (
    <div className={page.root}>
      <div className={page.container}>
        <Loaf>
          <Link to="forms_path">Все анкеты</Link>
        </Loaf>

        <Files
          files={[]}
          onFilesChanged={handleFilesChanged}
          sections={[]}
        />

        {upload &&
          <div>
            <button className={buttons.main} onClick={() => handleFileCheck()}>
              Проверить
            </button>
            Можно загружать
          </div>
        }
      </div>
    </div>
  )
}
