import React, { Component } from 'react';
import Datec from '../Datec';
import I18n from '../I18n';

import styles from './AttachmentsList.module.css';

class AttachmentsList extends Component {
  render() {
    const { attachments } = this.props;
    if(!attachments) return null;

    return (
      <div className={styles.attachments}>
        {attachments.map(attachment =>
          <a key={attachment.id} href={attachment.url} target="_blank" className={styles.attachment}>
            <div className={styles.title}>
              {attachment.title}
            </div>

            {attachment.section &&
              <div className={styles.section}>
                {I18n.t(`sections.${attachment.section}`)}
              </div>
            }

            {attachment.created_at &&
              <div className={styles.created_at}>
                <Datec time={attachment.created_at} />
              </div>
            }
          </a>
        )}
      </div>
    );
  }
}

export default AttachmentsList;
