import React, { useState, useEffect } from 'react';
import styles from '../Index.module.css';
import axios from 'axios';
import { DefaultFilterValue, Filters, FilterType } from './Filter/constants';
import FilterTab from './Filter/FilterTab';
import { path } from '../../Routes';
import { observer } from 'mobx-react-lite';
import { SearchBarViewModel } from './SearchBar.model';
import classNames from 'classnames';

/**
 * Бар с фильрами и поиском Schools
 * @param {*} props
 * props: {
 *  presetFilters: @type {Object.<string, Array> } - значения фильтров
 *  onApplyFilters: @type { () => void; } - реакция на применение комплекса фильтров
 * }
 */

const SearchBar = observer((props) => {
    const { presetFilters = {}, onApplyFilters, schools_count, filterParams } = props;

    const [viewModel] = useState(new SearchBarViewModel());
    const [currentFilter, setCurrentFilter] = useState(undefined);
    const [searchValue, setSearchValue] = useState(presetFilters[FilterType.Search] || '');

    useEffect(() => {
        viewModel.onStart(presetFilters);
    }, []);

    const toggleFilterTab = (e, filter) => {
        if (e) e.preventDefault();
        setCurrentFilter(filter);
        
        if (filter == undefined) {
            document.body.style.overflow = ""
        } else {
            document.body.style.overflow = "hidden"
        }

        if (!filter) {
            //Сбросим пользовательский выбор фильтров если просто закрыл без применения
            viewModel.resetChangedFilters();
        }
    };

    const handleSearchBarKeyDown = (event) => {
        if (event.key === 'Enter') {
            applyFilters();
        }
    };

    const applyFilters = (event) => {
        //Добавляем строковый поиск
        var filters = viewModel.getChangedFilters();
        if (0 < searchValue.length < 101) {
            filters[FilterType.Search] = searchValue;
        }

        onApplyFilters(filters);
        toggleFilterTab(event, undefined);
        document.body.style.overflow = ""
    };

    const resetFilters = (event) => {
        viewModel.resetAllFilters();
        setSearchValue('');
        onApplyFilters(DefaultFilterValue);
        toggleFilterTab(event, undefined);
    };

    const onChangeSearchValue = (event) => {
        const value = event.target.value;
        if (value.length < 100) {
            setSearchValue(value);
        }
    };

    return (
        <div className={styles.new_search}>
            {currentFilter && (
                <div
                    onClick={(e) => {
                        toggleFilterTab(e, undefined);
                    }}
                    className={styles.searchOverflow}
                ></div>
            )}
            <div className={styles.new_search_wrapper}>
                <input className={classNames(styles.search_input, styles.search_input_big)} type="text" placeholder={"Искать среди " + schools_count + " вузов"} value={searchValue} onChange={onChangeSearchValue} onKeyDown={handleSearchBarKeyDown}/>
                
                <div className={styles.new_search_small}>
                    <input className={classNames(styles.search_input, styles.search_input_small)} type="text" placeholder={"Искать среди " + schools_count + " вузов"} value={searchValue} onChange={onChangeSearchValue} />
                    <button onClick={applyFilters} />
                </div>


                <div className={styles.new_search_filter + ' ' + (currentFilter ? styles.filter_active : '')}>
                    {Filters.map((filter) => {
                        return (
                            <a
                                key={`filter_type_${filter.type}`}
                                href=""
                                onClick={(e) => toggleFilterTab(e, filter)}
                                className={classNames(
                                    styles.new_search_filter_item, 
                                    ((currentFilter || {}).type == filter.type ? styles.filter_chosen : ''), 
                                    (viewModel.onLoadingFilters ? styles.filters_disables : '')
                                    )}
                            >
                                <div className={styles.new_search_filter_text}>
                                    <span>
                                        {filter.title}
                                        {currentFilter && Object.keys(viewModel.changedFilters[filter.type] || {}).length > 0 && (
                                            <span className={styles.new_search_filter_counter}>{Object.keys(viewModel.changedFilters[filter.type]).length}</span>
                                        )}
                                        {!currentFilter && Object.keys(viewModel.appliedFilters[filter.type] || {}).length > 0 && (
                                            <span className={styles.new_search_filter_counter}>{Object.keys(viewModel.appliedFilters[filter.type]).length}</span>
                                        )}

                                        {viewModel.onLoadingFilters && (
                                            <span className={classNames(styles.new_search_filter_counter, styles.filters_loading)} />
                                        )}
                                    </span>
                                </div>
                            </a>
                        );
                    })}
                    {currentFilter && <a href="" onClick={(e) => toggleFilterTab(e, undefined)} className={styles.new_search_filter_exit}></a>}
                </div>
                {currentFilter && (
                    <div className={styles.new_search_filter_panel}>
                        <div className={styles.new_search_filter_list}>
                            <FilterTab filter={currentFilter} viewModel={viewModel} />
                        </div>
                        <span></span>
                        <div className={styles.new_search_filter_footer}>
                            <div className={styles.reset_filters} onClick={resetFilters}>
                                Сбросить фильтры
                            </div>
                            <div className={styles.new_search_button} onClick={applyFilters}>
                                <button></button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className={classNames(styles.new_search_button, (currentFilter ? styles.new_search_button_hidden : ''), styles.external_search_btn)} onClick={applyFilters}>
                <button></button>
            </div>
        </div>
    );
});

export default SearchBar;
