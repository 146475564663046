import React, { Component } from 'react';

import Item from './Item';

import section from './Section.module.css';

class Consulting extends Component {
  render() {
    const { data, I18n } = this.props;

    return (
      <div className={section.root}>
        <div className={section.title}>
          <h2>
            Консалтинг вузов
          </h2>
        </div>

        <div className={section.list}>
          <Item data={data.rumc_schools} label={'stats.rumc.schools_total'}/>
          <Item data={data.webinars} label={'stats.events.webinars'} />
          <Item data={data.meetings} label={'stats.events.meetings'} />
          <Item data={data.call_schools} label={'stats.rumc.call_schools'} />
        </div>
      </div>
    );
  }
}

export default Consulting;
