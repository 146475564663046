import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../../Routes'

import Show from './Show'
import Form from './Form'

export default function Courses () {
  return (
    <Switch>
      <Route path={Routes.edit_account_stage_path}>
        <Form />
      </Route>

      <Route path={Routes.account_stage_path} exact strict>
        <Show />
      </Route>
    </Switch>
  )
}
