import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import axios from 'axios';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { path } from '../../Routes';

import Loaf from '../../Loaf/Loaf';
import Attachments from '../../Attachments/Attachments';

import page from '../../Page.module.css';
import form from '../../Form.module.css';
import buttons from '../../Buttons.module.css';

class Form extends React.Component {
  state = {
    title: 'Новый учебно-методический материал',
    section: '',
    attachments: [],
    progress: null,
    values: {
      title: '',
      desc: '',
      section: '',
      authors: '',
      attachment_ids: [],
      attachments_attributes: [],
    },
    submit: {
      url: path('account_umms_path', {}, false),
      method: 'post',
    },
    umm: null,
    send: false,
  }

  componentDidMount() {
    if(this.props.id) {
      this._loadAsyncData(this.props.id);
    }
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();
    axios.get(path('edit_account_umm_path', {id: id}, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState(prevState => ({
          umm: res.data.umm,
          values: {
            title: res.data.umm.title,
            section: res.data.umm.section,
            desc: res.data.umm.desc || '',
            body: res.data.umm.body || '',
            authors: res.data.umm.authors || '',
          },
          umm_sections: res.data.umm.umm_sections,
          attachments: res.data.attachments,
          sections: res.data.umm.sections,
          section: res.data.umm.section,
          submit: {
            method: 'patch',
            url: path('account_umm_path', {id: id}, true)
          }
        }));

        this._asyncRequest = null;
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.attachments !== prevState.attachments) {
      this.setState(prevState => ({
        values: { ...prevState.values,
          attachments_attributes: this.state.attachments.map(a => ({id: a.id, title: a.title, section: a.section}) ),
          attachment_ids: this.state.attachments.map(a => a.id),
        }
      }));
    }
  }

  handleSubmit = (e) => {
    if(this.canSubmit()) {
      this.setState({
        send: true
      });

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        data: Object.assign({
          umm: this.state.values
        }),
      }).then(
        res => {
          this.props.history.push(res.headers.location)
        }
      );
    }

    e.preventDefault();
  }

  canSubmit = () => {
    return (
      (this.state.values.desc && this.state.values.desc.length < 500) &&
      (this.state.values.title && this.state.values.title.length < 160) &&
      this.state.values.section &&
      !this.state.send
    );
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }));
  }

  handleAttachmentsUpdate = (attachments) => {
    this.setState({ attachments: attachments });
  }

  handleDestroy = () => {
    axios.delete(path('account_umm_path', { id: this.props.id }, true))
      .then(res => {
        this.props.history.push(res.headers.location)
      });
  }


  render() {
    const { umm, attachments, values, umm_sections } = this.state;

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
          <Link to={path('account_path', {}, false)}>Кабинет</Link>
          <Link to={path('account_umms_path', {}, false)}>События</Link>
          </Loaf>
          <div className={page.title}>
            <h1>
              {umm ? 'Редактирование материала' : 'Новый материал' }
            </h1>
          </div>

           <div className={form.tight}>
            {!umm &&
              <p>
                Форма создания материала для предварительного согласования. После утверждения тематики вам будут доступны остальные поля для заполнения.
              </p>
            }

            <p>
              В названии материала не нужно указывать его тип — для этого есть отдельное поле. Все название не нужно обрамлять в кавычки.
            </p>

            <p>
              <Link to={`${path('terms_path')}/румц/умм`} target="_blank">Подробнее о процессе создания материалов</Link>
            </p>

            <form className="form" onSubmit={this.handleSubmit}>
              <div className="input">
                <div className="input_label">
                  <label>
                    Название
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="title" onChange={this.handleInputChange} value={this.state.values.title} />
                </div>

                <div className="input_desc">
                  Название материала, не более 150 символов.
                </div>

                {values.title.length >= 160 &&
                  <div className={form.error}>
                    Слишком длинное название, сократите до 150 символов!
                  </div>
                }
              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    Тип
                  </label>
                </div>

                <div className="input_input">
                  <select name="section" onChange={this.handleInputChange} value={this.state.values.section}>
                    <option />
                    {umm_sections && Object.entries(umm_sections).map(o =>
                      <option key={o[0]} value={o[0]}>{o[1]}</option>
                    )}
                  </select>
                </div>

              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    Аннотация
                  </label>
                </div>

                <div className="input_input">
                  <textarea name="desc" value={this.state.values.desc} rows="4" onChange={this.handleInputChange} />
                </div>

                {values.desc.length >= 500 &&
                  <div className={form.error}>
                    Слишком длинное описание, сократите до 400 символов!
                  </div>
                }

                <div className="input_desc">
                  Краткое и основное содержание материала в 1-2 предложениях, не более 400 символов.
                </div>
              </div>

              <div className="input">
                <div className="input_label">
                  <label>
                    Авторы
                  </label>
                </div>

                <div className="input_input">
                  <input type="text" name="authors" onChange={this.handleInputChange} value={this.state.values.authors} />
                </div>
              </div>

              {this.state.umm && this.state.umm.state != 'inactive' && this.state.umm.state != 'declined' &&
                <React.Fragment>
                  <div className="input">
                    <div className="input_label">
                      <label>
                        Описание
                      </label>
                    </div>

                    <div className="input_input">
                      <textarea name="body" value={this.state.values.body} rows="12" onChange={this.handleInputChange} />
                    </div>
                  </div>

                  <div className="input">
                    <div className="input_input">
                      <Attachments authenticity_token={this.props.authenticity_token} onAttachmentsUpdate={this.handleAttachmentsUpdate} attachments={this.state.attachments} sections={this.state.sections}/>
                    </div>
                  </div>
                </React.Fragment>
              }

              <div className={form.submit}>
                <input type="submit" value="Сохранить" className={buttons.main} disabled={!this.canSubmit()} />

              {umm && umm.can_destroy &&
                <div className={classNames(buttons.discard, form.discard)} onClick={this.handleDestroy}>
                  Удалить
                </div>
              }
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Form
