import React, { useState, useEffect, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import qs from 'query-string';

import I18n from '../I18n'
import Link, { path } from '../Routes'
import Loaf from '../Loaf/Loaf'
import Stats from '../Account/Events/Index/Stats'

import page from '../Page.module.css'
import styles from './Index.module.css'
import buttons from '../Buttons.module.css'
import classNames from 'classnames'

import Current from '../Current'
import Modal from '../elements/Modal'
import Loading from '../elements/Loading'
import Files from '../Attachments/Files'
import GlobalVariables from '../Vars';

export default function Index () {
  const { event_id: eventId } = useParams()

  const cancelToken = useRef(axios.CancelToken.source())
  const [event, setEvent] = useState()
  const [registrations, setRegistrations] = useState()
  const [showStats, setShowStats] = useState(false)
  const [dictionaries, setDictionaries] = useState()
  const [loadingExcel, setLoadingExcel] = useState(false)
  const [limited, setLimited] = useState(false)

  const [modalImport, setModalImport] = useState(false)
  const [loadingImport, setLoadingImport] = useState(false)
  const [countImportData, setCountImportData] = useState(0)
  const [importData, setImportData] = useState([])
  const [importToken, setImportToken] = useState('')
  const [errorText, setErrorText] = useState(null)
  const { user } = useContext(Current)

  const _fetch = async () => {
    const { data } = await axios.get(path('event_event_registrations_path', { event_id: eventId }, true), { cancelToken: cancelToken.current.token })

    setRegistrations(data.registrations)
    setEvent(data.event)
    setDictionaries(data.dictionaries)

    location.search && setLimited(qs.parse(location.search).limited ? true : false)
  }

  useEffect(() => {
    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const exportExcel = async () => {
    setLoadingExcel(true);
    // const { data } = await axios.get(`${path('event_event_registrations_path', { event_id: eventId })}.xlsx`, { cancelToken: cancelToken.current.token })
    // let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    // let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    // link.href = url;
    link.href = `${path('event_event_registrations_path', { event_id: eventId })}.xlsx`;
    link.download = `Участники события ${event.title.slice(0, 30)}${event.title.length > 30 ? '... ' : ''}.xlsx`
    document.body.appendChild(link);
    link.click();
    link.remove();

    setLoadingExcel(false);
  }

  const getImportToken = async () => {
    try {
      const resp = await axios.post(`${GlobalVariables.apitestHost}/eventRegistrations/token`, {...user, event_id: eventId})
      setImportToken(resp.data)
      return resp.data
    } catch (e) {
      setLoadingImport(false)
      setErrorText('Ошибка доступа. Обратитесь в техническую поддержку')
    }
  }

  const sendFileToImport = async (file) => {
    if (file.size) {
      setLoadingImport(true)
      const token = await getImportToken();
      if (token) {
        var data = new FormData()
        data.append('file', file.values().next().value)
        data.append('token', token)
        await axios.post(`${GlobalVariables.apitestHost}/eventRegistrations/import`, data)
          .then(res => {
            if (res.data.status) {
              setImportData(res.data.data)
              setCountImportData(res.data.count)
              setErrorText(null)
            } else {
              setLoadingImport(false)
              setErrorText(res.data.info)
            }
          })
          .catch(error => {
            console.log(error)
            alert("Ошибка")
          })
        setLoadingImport(false)
      }
    }
  }

  const saveImportData = async () => {
    await axios.post(`${GlobalVariables.apitestHost}/eventRegistrations/save`, { data: importData, token: importToken, event_id: eventId })
      .then(res => {
        if (res.data.status) {
          setModalImport(false)
          _fetch()
        } else {
          setErrorText(res.data.info)
        }
      })
      .catch(error => {
        console.log(error)
        alert("Ошибка")
      })
  }

  const closeImportModel = () => {
    setModalImport(false);
    setCountImportData(0);
    setErrorText(null);
  }

  const importModalContent = () => {
    return (
      <div className={styles.importModalContent}>
        <p>1) Скачайте шаблон: <a href="/uploads/templates/EVENT_REGISTRATIONS.xlsx" target='_blank'>скачать</a></p>
        <p>2) Загрузите заполненный шаблон</p>
        {!loadingImport && countImportData == 0 &&
          <Files
            files={null}
            onFilesChanged={sendFileToImport}
            sections={[]}
            single={true}
          />
        }
        {loadingImport && <Loading />}
        {errorText && <p style={{color: 'red'}}>{errorText}</p>}
        {countImportData > 0 &&
          <div>
            <p>
              <span style={{marginRight: '10px'}}>3) Проверьте данные и нажмите</span>
              <button className={buttons.main} onClick={() => saveImportData()}>сохранить</button>
            </p>
            {importData.map((row, i) => (
              <div key={`check_row_${i}`} className={styles.registration}>
                <div className={styles.i}>{i + 1}.</div>
                <div className={styles.name}>{row.first_name} {row.last_name}</div>
                <div className={styles.id}>{user.email}</div>
                {dictionaries &&
                  <>
                    <div className={styles.regions}>
                      {row.region_id && dictionaries.regions.find(el => el.id === row.region_id).label}
                    </div>
                    <div className={styles.audiences}>
                      {row.audience_id && dictionaries.audiences.find(el => el.id === row.audience_id).label}
                    </div>
                    <div className={styles.schools}>
                      {row.school_id && dictionaries.schools.find(el => el.id === row.school_id).label}
                      {row.rumc_id && dictionaries.rumcs.find(el => el.id === row.rumc_id).label}
                    </div>
                  </>
                }
              </div>
            ))}
          </div>
        }
      </div>
    )
  }

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="events_path">{I18n.t('nav.events')}</Link>
          {event &&
            <Link to="event_path" params={{ id: event.id }}>
              {event.title}
            </Link>
          }
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('event_registrations.title')}
          </h1>

          <Helmet>
            <title>{I18n.t('event_registrations.title')}</title>
          </Helmet>
        </div>

        <div className={styles.new}>
          {event && event.registration.can_new && !limited && 
            <>
              <Link to="new_event_event_registration_path" params={{ event_id: event.id }} className={buttons.main}>
                Добавить нового участника
              </Link>

              <button className={classNames(buttons.main, styles.excel)} onClick={() => setModalImport(true)}>
                <div className={classNames(styles.excelIcon, { [styles.excelLoading]: loadingExcel })} />
                <span>Импорт из Excel</span>
              </button>
            </>
          }

          {modalImport && <Modal slot={importModalContent()} close={() => closeImportModel()} title={'Импорт списка участников из Excel'} /> }

          {event && registrations && registrations.length != 0 && event.registration.can_export && 
            <>
              <button className={classNames(buttons.main, styles.excel)} onClick={() => {exportExcel()}}>
                <div className={classNames(styles.excelIcon, { [styles.excelLoading]: loadingExcel })} />
                <span>Экспорт в Excel</span>
              </button>

              <div className={buttons.grey} onClick={() => setShowStats(!showStats)}>
                Статистика участников
              </div>
            </>
          }
        </div>

        {showStats && registrations &&
          <>
            <Stats eventIds={[eventId]} />
            <p />
          </>
        }

        {registrations &&
          <div>
            {registrations.map((registration, i) =>
              <div key={registration.id} className={styles.registration}>
                <div className={styles.i}>
                  {i + 1}.
                </div>
                <div className={styles.name}>
                  <Link to="event_event_registration_path" params={{ event_id: registration.event_id, id: registration.id }} key={registration.id}>
                    {registration.name}
                  </Link>
                </div>
                <div className={styles.id}>
                  {registration.email}
                </div>
                {dictionaries &&
                  <>
                    <div className={styles.regions}>
                      {registration.region_id &&
                        dictionaries.regions.find(el => el.id === registration.region_id).label
                      }
                    </div>
                    <div className={styles.audiences}>
                      {registration.audience_id &&
                        dictionaries.audiences.find(el => el.id === registration.audience_id).label
                      }
                    </div>
                    <div className={styles.schools}>
                      {registration.school_id &&
                        dictionaries.schools.find(el => el.id === registration.school_id).label
                      }
                      {registration.rumc_id &&
                        dictionaries.rumcs.find(el => el.id === registration.rumc_id).label
                      }
                    </div>
                  </>
                }
              </div>
            )}
          </div>
        }
        {registrations && !registrations.length &&
          <p>Участники не найдены</p>
        }
      </div>
    </div>
  )
}
