import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

import I18n from '../../I18n'
import Link, { path } from '../../Routes'
import Filters from '../../Filters'
import Loaf from '../../Loaf/Loaf'

// import Type from './Index/Type'
import Types from './Index/Types'

import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
import styles from './Index.module.css'

export default function Index () {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const cancelToken = useRef(axios.CancelToken.source())

  const [facilities, setFacilities] = useState()
  const [facilityTypes, setFacilityTypes] = useState()
  const [filters, setFilters] = useState()

  const _fetch = async () => {
    const { data } = await axios.get(
      path('account_facilities_path', {}, true),
      {
        params: query,
        cancelToken: cancelToken.current.token
      }
    )

    setFacilityTypes(data.facility_types)
    setFacilities(data.facilities)
    setFilters(data.filters)
  }

  useEffect(() => {
    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  useEffect(() => {
    _fetch()
  }, [location])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="account_path">{I18n.t('account.title')}</Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t('account.facilities.title')}
          </h1>

          <Helmet>
            <title>{I18n.t('account.facilities.title')}</title>
          </Helmet>

          <p className={styles.intro}>
            Добавлен тип для группировки оборудования. Старайтесь новому оборудования указывать тип, а уже заведённым в систему задать при редактировании. Тип оборудования можно создавать — он сквозной для всех РУМЦ, поэтому вы видите типы оборудования созданные другими центрами, а созданные вами будет видны другим. Старайтесь не править название типов без необходимости, потому что другие участники могут этого не ожидать и их оборудование может не соответствовать изменениям.
          </p>

          <div className={page.links}>
            <Link className={buttons.main} to="new_account_facility_path">Добавить оборудование</Link>
            <Link className={buttons.main} to="new_account_facility_type_path">Новый тип оборудования</Link>
          </div>
        </div>

        {facilities && facilityTypes && filters &&
          <div className={styles.root}>
            <div className={styles.filters}>
              <Filters filters={filters} />
            </div>

            {facilityTypes.length > 0 &&
              <div className={styles.types}>
                <Types
                  types={facilityTypes}
                  rumcId={query.get('rumc_id')}
                />
              </div>
            }

            {facilityTypes.length < 1 &&
              <div className={styles.facilities}>
                {facilities.map(facility =>
                  <Link to="account_facility_path" params={{ id: facility.id }} className={styles.facility} key={facility.id}>
                    {facility.title}
                  </Link>
                )}
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}
