import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

import { Routes, path } from '../Routes';

import Loaf from '../Loaf/Loaf';

import AttachmentsList from '../Attachments/AttachmentsList'
import Rate from '../Rate/Rate';

import forms from '../Form.module.css'; // Другое имя чтобы не было коллапса с моделью
import page from '../Page.module.css';
import buttons from '../Buttons.module.css';
import styles from './TomorrowRequestShow.module.css'

class TomorrowRequestShow extends React.Component {
  state = {
    request: [],
    rate: null,
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('tomorrow_tomorrow_request_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          request: res.data.request,
          rate: res.data.rate,
        });

        this._asyncRequest = null;
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.request === null) {
      this._loadAsyncData(this.props.id);
    }
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleRateSave = () => {
    this.setState({
      request: null
    });
  }

  handleDelete = () => {
    axios.delete(path('tomorrow_tomorrow_request_path', { id: this.props.id })).then(() => {
      this.props.history.push(path('tomorrow_tomorrow_requests_path'));
    });
  }

  render (){
    const { request, rate } = this.state

    let nomination = ['Эссе «Почему я ценный и полезный работник: 10 аргументов', 'Профессиональный старт-ап молодежи', 'Исследовательская работа', 'Мое полезное изобретение', 'Социальная реклама']

    if(!request) return null;

    return (
      <div className={page.root}>
        <div className={page.container}>

          <div className={page.title}>
            <h2>
              {request.title}
            </h2>

            <Helmet>
              <title>
                {request.title}
              </title>
            </Helmet>
            {request.can_delete &&
              <div className={buttons.main} onClick={() => this.handleDelete()}>
                Удалить
              </div>
            }
          </div>

          <div className={styles.info}>
            {request.authors && !rate.can_rate &&
              <div>ФИО участников работы: {request.authors}</div>
            }
            {request.email && !rate.can_rate &&
              <div>Эл. почта: {request.email}</div>
            }
            {request.phone && !rate.can_rate &&
              <div>Телефон: {request.phone}</div>
            }
            {request.nomination &&
              <div>Номинация: {nomination[request.nomination - 1]}</div>
            }
            {request.url &&
              <div>Ссылка на работу: {request.url}</div>
            }
            {request.year && !rate.can_rate &&
              <div>Курс обучения: {request.year}</div>
            }
            {request.school && !rate.can_rate &&
              <div>Название вуза: {request.school}</div>
            }
            {request.direction &&
              <div>Направление: {request.direction}</div>
            }
            {request.curator && !rate.can_rate &&
              <div>Куратор: {request.curator.name}</div>
            }


          </div>

          {request.desc &&
            <div className={styles.desc}>
              {request.desc}
            </div>
          }

          {request.attachments &&
            <AttachmentsList attachments={request.attachments} />
          }

          {rate &&
            <React.Fragment>
              <Rate rate={rate} ratable={{ type: "TomorrowRequest", id: request.id}} button="Сохранить и утвердить" onRateSave={this.handleRateSave} />
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

export default TomorrowRequestShow;
