import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import axios from 'axios'

import { path } from '../../Routes'
import { useForm, Errors } from '../../Form'

// import page from '../../Page.module.css'
import buttons from '../../Buttons.module.css'
import form from '../../Form.module.css'
import styles from './Add.module.css'

Add.propTypes = {
  stageable_id: PropTypes.number,
  stageable_type: PropTypes.string
}

export default function Add ({ stageable_id: stageableId, stageable_type: stageableType }) {
  const history = useHistory()

  const [active, setActive] = useState(false)

  const {
    values,
    handleInputChange,
    errors,
    setErrors,
    send,
    setSend,
    cancelToken
  } = useForm({
    title: ''
  })

  const handleSubmit = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    await axios.post(
      path('account_stages_path'),
      { stage: {
        ...values,
        stageable_id: stageableId,
        stageable_type: stageableType
      } },
      { cancelToken: cancelToken.current.token }
    ).then(res => {
      res.headers.location && history.push(res.headers.location)
    }).catch(error => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  return (
    <div className={styles.root}>
      <div onClick={() => setActive(!active)} className={styles.toggle}>
        Добавить лекцию, практику или сам. работу
      </div>

      {active &&
        <div className={form.tight}>
          <form onSubmit={handleSubmit}>
            <div className={form.el}>
              <label>
                <div className={form.label}>
                  Название темы
                </div>

                <div className={form.input}>
                  <input
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleInputChange}
                  />
                </div>
              </label>

              <Errors errors={errors.title} />
            </div>

            <div className={form.submit}>
              <button className={classNames(buttons.main, { [buttons.loading]: send })} disabled={send}>
                Добавить
              </button>
            </div>
          </form>
        </div>
      }
    </div>
  )
}
