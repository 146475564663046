import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import classNames from 'classnames'

import I18n from '../I18n'
import Link, { path } from '../Routes'
import Loaf from '../Loaf/Loaf'

import styles from './Show.module.css'
import page from '../Page.module.css'
import buttons from '../Buttons.module.css'

export default function Show () {
  const cancelToken = useRef(axios.CancelToken.source())
  const { id } = useParams()

  const [course, setCourse] = useState()
  const [stages, setStages] = useState()

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('course_path', { id }, true), { cancelToken: cancelToken.current.token })

      setCourse(data.course)
      setStages(data.stages)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        <Loaf>
          <Link to="courses_path">{I18n.t('courses.title')}</Link>
        </Loaf>

        {course &&
          <>
            {course.can_edit &&
              <div className={page.control}>
                <Link to="edit_account_course_path" params={{ id }} className={buttons.small}>Редактировать курс</Link>
              </div>
            }
            <div className={page.title}>
              <h1>
                {course.title}
              </h1>

              <Helmet>
                <title>{course.title}</title>
              </Helmet>
            </div>
          </>
        }

        {course &&
          <div className={page.text}>
            <div className={styles.desc}>
              {course.desc}
            </div>

            <div className={styles.results}>
              <h2>
                Формируемые результаты обучения
              </h2>

              <p>
                {course.results}
              </p>
            </div>

            <div className={styles.system}>
              <h2>
                Описание системы оценивания
              </h2>

              <p>
                {course.system}
              </p>
            </div>

            <div>
              <h2>
                Разделы
              </h2>

              {stages &&
                <div className={styles.stages}>
                  {stages.map((stage, _) =>
                    <div key={stage.id} className={styles.stage}>
                      <Link to="stage_course_path" params={{ id: course.id, stage_id: stage.id }} className={classNames({ [styles.has_youtube]: stage.youtube, [styles.has_desc]: stage.desc }, styles.a_in_block)}>
                        <span className={styles.num}>{_ + 1}</span>
                        {stage.title}

                        {stage.youtube_duration &&
                          <div className={styles.duration}>
                            {(stage.youtube_duration.minutes || 0)}:{(stage.youtube_duration.seconds || 0).toString().padStart(2, '0')} мин
                          </div>
                        }
                      </Link>
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}
