import React from 'react';
import PropTypes from 'prop-types';

export class CommentsList extends React.Component {
  render() {
    const comments = this.props.comments;
    if(!comments || comments.length < 0) return null;

    return (
      <div className="comments_list">
        {comments.map(comment =>
          <div className="comments_list_item comment" key={comment.id}>
            <div className="comment_header">
              <div className="author">
                {comment.author.name}
              </div>
              <div className="created_at">
                {comment.created_at_human}
              </div>
            </div>
            <div className="comment_body">
              <div className="body_content" dangerouslySetInnerHTML={{ __html: comment.body }} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
