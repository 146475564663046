import React from 'react'
import PropTypes from 'prop-types'

import Link from '../../Routes'

import styles from './Branches.module.css'
import classNames from 'classnames'

Branches.propTypes = {
  branches: PropTypes.array
}

export default function Branches ({ branches }) {
  return (
    <ul className={styles.branches}>
      {branches.map(branch =>
        <li key={branch.id}>
          <Link to="school_path" params={{ id: branch.id }} className={classNames(styles.branch, styles.a_in_block)}>
            <div>
              {branch.label}
            </div>

            {branch.region &&
              <div className={styles.region}>
                {branch.region.label}
              </div>
            }
          </Link>
        </li>
      )}
    </ul>
  )
}
