import React, { Component } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import axios from 'axios'
import DatePicker from 'react-datepicker'

import Link, { path } from '../../Routes'
import I18n from '../../I18n'

import Loaf from '../../Loaf/Loaf'
import Files from '../../Attachments/Files'

import page from '../../Page.module.css'
import form from '../../Form.module.css'
import buttons from '../../Buttons.module.css'

class Form extends Component {
  state = {
    rumc_school: null,
    attachments: [],
    values: null,
    errors: {},
    saved: false
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('edit_account_rumc_school_path', { id: this.props.id }, true), { cancelToken: this._asyncRequest.token })
    this._asyncRequest = null

    res.data.values.date = dayjs.utc(res.data.values.date)
    res.data.values.date = res.data.values.date.isValid() ? res.data.values.date.toDate() : ''
    this.setState({ ...res.data })
  }

  handleFilesChanged = files => {
    this.setState(prevState => ({
      values: { ...prevState.values,
        attachments_attributes: [...files.values()].map(file => ({ id: file.id, title: file.title, section: file.section })),
        attachment_ids: [...files.values()].map(file => file.id)
      }
    }))
  }

  handleSubmit = async e => {
    e.preventDefault()

    await axios.patch(
      path('account_rumc_school_path', { id: this.props.id }, true),
      { rumc_school: this.state.values }
    ).then(res => {
      this.setState({ errors: {}, saved: true })
    }).catch(({ response }) => {
      this.setState({ errors: response.data, saved: false })
    })
  }

  render () {
    const { rumc_school: rumcSchool, attachments, values, errors, saved } = this.state

    if (!values) return null

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="account_path">{I18n.t('account.title')}</Link>
            <Link to="account_rumc_schools_path">{I18n.t('account.rumc_schools.title')}</Link>
            {rumcSchool &&
              <Link to="account_rumc_school_path" params={{ id: rumcSchool.id }}>{rumcSchool.title}</Link>
            }
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('account.rumc_school.edit.title')}
            </h1>
          </div>

          {values &&
            <div className={form.tight}>
              <form onSubmit={this.handleSubmit}>
                <div className={form.el}>
                  <label>
                    <div className={form.label}>
                      Дата заключения соглашения
                    </div>

                    <div className={form.input}>
                      <DatePicker
                        dateFormat="dd.MM.yyyy"
                        selected={values.date}
                        onChange={date => this.setState(state => ({ values: { ...state.values, date: date } }))}
                      />
                    </div>
                  </label>

                  <Errors errors={errors.date} />
                </div>

                <Files
                  files={this.state.attachments}
                  onFilesChanged={this.handleFilesChanged}
                  sections={rumcSchool.sections}
                />

                {saved &&
                  <div className={form.success}>
                    Данные соглашения успешно сохранены
                  </div>
                }

                <div className={form.submit}>
                  <input type="submit" value="Сохранить" className={buttons.main} />
                </div>
              </form>
            </div>
          }
        </div>
      </div>
    )
  }
}

Form.propTypes = {
  id: PropTypes.string
}

function Errors (props) {
  return (
    <>
      {props.errors &&
        <div className={form.error}>
          <ul>
            {props.errors.map((error, _) => <li key={_}>{error}</li>)}
          </ul>
        </div>
      }
    </>
  )
}

Errors.propTypes = {
  errors: PropTypes.array
}

export default Form
