import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../../Routes'

import Form from './Form'
import Show from './Show'

export default function PlanModules () {
  return (
    <Switch>
      <Route path={[Routes.new_account_plan_plan_module_path, Routes.edit_account_plan_module_path]} exact strict>
        <Form />
      </Route>

      <Route path={Routes.account_plan_module_path} exact strict>
        <Show />
      </Route>
    </Switch>
  )
}
