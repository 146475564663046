import React from "react";
import styles from '../Show.module.css'


class ImagesCard extends React.Component {
  constructor(props) {
    super(props);
  }

  openImage(url, name) {
    this.props.openImage(url.replace("_crop", ""), name, this.props.index)
  }


  render() {
    const { id, url, name } = this.props.image;
    
    return (
      <div onClick={() => {this.openImage(url, name)}} title={name}> 
        <img src={url} />
        {/* <span>{name}</span> */}
      </div>
    );
  }
}

export default ImagesCard