import React from "react";
import styles from "./Modal.module.css";

export const Modal = (props) => {

  return (
    <div className={styles.like_modal_container}>
      <div className={styles.like_modal}>
        <div className={styles.close_button}>
          <h3>{props.title}</h3>
          <svg onClick={props.close} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.3756 10L0 18.3756L1.6244 20L10 11.6244L18.3756 20L20 18.3756L11.6244 10L20 1.6244L18.3756 0L10 8.3756L1.6244 0L0 1.6244L8.3756 10Z" fill="#212121"/>
          </svg>
        </div>
        
        <div className={styles.modal_body}>
          {props.slot}
        </div>
      </div>
    </div>
  )
}

export default Modal;
