import React from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import update from 'immutability-helper'

import { path } from '../Routes'

import forms from '../Form.module.css' // Другое имя чтобы не было коллапса с моделью
import buttons from '../Buttons.module.css'
import page from '../Page.module.css'
import styles from './Form.module.css'

class Form extends React.Component {
  state = {
    send: false,
    form: null,
    values: {
      title: '',
      desc: ''
    },
    form_elements: [],
    submit: {
      url: path('forms_path', {}, true),
      method: 'post'
    },
    redirect_to: null
  }

  componentDidMount () {
    if (this.props.id) this._loadAsyncData(this.props.id);
  }

  _loadAsyncData (id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('edit_form_path', { id: id }, true), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          form: res.data.form,
          values: {
            title: res.data.form.title,
            desc: res.data.form.desc
          },
          form_elements: res.data.form_elements,
          submit: {
            url:  path('form_path', { id: id }, true),
            method: 'patch'
          }
        })

        this._asyncRequest = null;
      })
  }

  componentWillUnmount () {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }));
  }

  handleElemInputChange = (e, i) =>  {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const form_elements = update(this.state.form_elements, {
      [i]: {
        values: {
          [name]: {
            $set: value
          }
        }
      }
    });

    this.setState({ form_elements });
  }

  handleNewClick = () => {
    let fe = {
      id: null,
      values: {
        title: '',
        desc: '',
        type: 'string',
        settings: '',
        weight: 0,
        required: false,
      }
    };

    const form_elements = update(this.state.form_elements, { $push: [fe] })

    this.setState({ form_elements })
  }

  handleSubmit = (e) => {
    if (this._canSubmit()) {
      this.setState({ send: true });
      this._asyncRequest = axios.CancelToken.source();

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        cancelToken: this._asyncRequest.token,
        data: { form: {
          ...this.state.values,
          elements_attributes: this.state.form_elements.map(fe => {
            return {
              ...fe.values,
              id: fe.id
            }
          })
        } }
      }).then(
        res => {
          this.setState({ send: false })
          // this.setState({
          //   redirect_to: Routes.facilities_path
          // });
        }
      ).catch(_error => {
        this.setState({ send: false })
      }).then(() => {
        this._asyncRequest = null
      })
    }

    e.preventDefault()
  }

  _canSubmit = () => {
    return (
      this.state.values.title &&
      !this.state.send
    )
  }

  render () {
    const { form, values, form_elements } = this.state
    const title = values.title || 'Новая форма'

    return (
      <div className={page.root}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>
              {title}
            </h1>

            <Helmet>
              <title>
                {title}
              </title>
            </Helmet>
          </div>

          <div className={page.form}>
            <form className={forms.root} onSubmit={this.handleSubmit}>
              <div className={forms.elem}>
                <div className={forms.label}>
                  Название
                </div>

                <div className={forms.input}>
                  <input type="text" name="title" value={values.title} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={forms.elem}>
                <div className={forms.label}>
                  Описание
                </div>

                <div className={forms.input}>
                  <textarea name="desc" value={this.state.values.desc} rows="4" onChange={this.handleInputChange} />
                </div>
              </div>

              {form_elements.map((fe, _) =>
                <div key={_} className={styles.elem}>
                  <div className={forms.elem}>
                    <div className={forms.label}>
                      Вопрос
                    </div>

                    <div className={forms.input}>
                      <input type="text" name="title" value={fe.values.title} onChange={(e) => this.handleElemInputChange(e, _)} />
                    </div>
                  </div>

                  <div className={forms.elem}>
                    <div className={forms.label}>
                      Описание если требуется
                    </div>

                    <div className={forms.input}>
                      <textarea name="desc" value={fe.values.desc} onChange={(e) => this.handleElemInputChange(e, _)} />
                    </div>
                  </div>

                  <div className={forms.elem}>
                    <div className={forms.label}>
                      Сортировка
                    </div>

                    <div className={forms.input}>
                      <input type="text" name="weight" value={fe.values.weight} onChange={(e) => this.handleElemInputChange(e, _)} />
                    </div>
                  </div>

                  <div className={forms.elem}>
                    <div className={forms.label}>
                      Тип вопроса
                    </div>

                    <div className={forms.input}>
                      <select name="type" onChange={(e) => this.handleElemInputChange(e, _)} value={fe.values.type}>
                        <option value="string">Строка</option>
                        <option value="text">Текст</option>
                        <option value="radio">Радио</option>
                        <option value="checkbox">Чекбоксы</option>
                        <option value="references">Референс</option>
                      </select>
                    </div>
                  </div>

                  <div className={forms.elem}>
                    <div className={forms.label}>
                      Настройки
                    </div>

                    <div className={forms.input}>
                      <textarea name="settings" value={fe.values.settings} onChange={(e) => this.handleElemInputChange(e, _)} />
                    </div>
                  </div>

                  <div className={forms.elem}>
                    <div className={forms.checkbox}>
                      <label>
                        <input type="checkbox" name="required" checked={fe.values.required} onChange={(e) => this.handleElemInputChange(e, _)} />
                        Требуется для заполнения
                      </label>
                    </div>
                  </div>
                </div>
              )}

              <div className="new" onClick={this.handleNewClick}>
                Добавить вопрос
              </div>

              <div className={forms.submit}>
                <input type="submit" value="Сохранить" className={buttons.main} disabled={!this._canSubmit()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Form
