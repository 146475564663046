import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from '../../Routes';

import Index from './Index';
import Show from './Show';
import Review from './Review';
import Form from './Form';
import Media from '../MediaItems/Index'

class Events extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={decodeURIComponent(Routes.media_account_events_path)} component={Media}/>
          <Route exact path={decodeURIComponent(Routes.new_account_event_path)} component={Form} />
          <Route exact path={decodeURIComponent(Routes.edit_account_event_path)} render={props => <Form id={props.match.params.id} {...props} />} />
          <Route path={decodeURIComponent(Routes.review_account_event_path)} render={props => <Review id={props.match.params.id} {...props} />} />
          <Route path={decodeURIComponent(Routes.account_event_path)} render={props => <Show id={props.match.params.id} />} />
          <Route exact path={decodeURIComponent(Routes.account_events_path)} component={Index}/>
        </Switch>
      </React.Fragment>
    );
  }
}

export default Events;
