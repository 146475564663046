import React, { Component } from 'react'
import axios from 'axios'
import Link, { path } from '../Routes'
import qs from 'qs';

import styles from './Annotations.module.css'
import classNames from 'classnames'
import page from '../Page.module.css'

class TrainingsList extends Component {
  state = {
    annotations: [],

    openedCard: false,
    openedCardItems: {},
    openCompetencies: false,
    openKnowledge: false,
    openSkills: false,

    pages: 1,
    activePage: 0,
    loading: true
  }

  componentDidMount () {
    const paramsObject = qs.parse(window.location.search || '', { ignoreQueryPrefix: true }) || {};
    this._loadAsyncData({ page: 1, ...paramsObject })
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async (params) => {
    this._asyncRequest = axios.CancelToken.source()
    // this.setState({ loading: true })

    const { data } = await axios.get(path('annotations_path', {}, true), { cancelToken: this._asyncRequest.token, params: params })

    this.setState({ annotations: data.annotations, pages: data.pages, loading: false })

    this._asyncRequest = null
  }

  //   TODO: страницу в ссылку
  togglePage = (index) => {
    this._loadAsyncData({ page: index + 1 })
    this.setState({ activePage: index })
  }

  toggleOpenCard = async (id) => {
    this._asyncRequest = axios.CancelToken.source()
    const { data } = await axios.get(path('annotation_path', { id }, true), { cancelToken: this._asyncRequest.token })
    this.setState({openedCard: true, openedCardItems: data.annotation})
    this._asyncRequest = null
  }

  render () {
    const { annotations, openedCard, openedCardItems, openCompetencies, openKnowledge, openSkills, pages, activePage, loading } = this.state;
    // console.log(annotations)
    // console.log(openedCardItems)
    return (
        <div className={page.wrapper}>
        <div className={page.container}>
        <div className={styles.container}>
            {annotations.length != 0 && loading == false &&
            <>
                {/* <div className={styles.arrows}>
                    <div 
                        className={pages > 1 ? styles.arrow : classNames(styles.arrow, styles.disabled_arrow)} 
                        onClick={() => (this.togglePage(activePage ? activePage - 1 : pages - 1))} 
                        disablesd={pages > 1 ? 0 : 1}
                    />
                    <div 
                        className={pages > 1 ? classNames(styles.arrow, styles.arrow_down) : classNames(styles.arrow, styles.arrow_down, styles.disabled_arrow)} 
                        onClick={() => (this.togglePage(activePage < pages - 1 ? activePage + 1 : 0))}
                        disablesd={pages > 1 ? 0 : 1}
                    />
                </div> */}
                
                <div className={annotations.length == 8 ? styles.cards_container : styles.cards_container_small}>
                    {annotations.map((c) => 
                        <div className={styles.card} key={c.id}>
                            <div className={styles.card_header}>
                                {c.time && <div className={styles.clock}>{c.time}</div>}
                                {!c.time && <div> </div>}
                                <div className={styles.header_img} />
                            </div>

                            <div className={styles.card_body}>
                                <div className={styles.card_body_item}>
                                    {c.schools_shows_id && <Link to="school_path" params={{ id: c.schools_shows_id }} className={styles.card_rumc}>{c.abbr ? c.abbr : c.short_name}</Link>}
                                    {!c.schools_shows_id && <Link to="rumc_path" params={{ id: c.rumcs_id }} className={styles.card_rumc}>{c.title_short}</Link>}
                                    <span>{c.title}</span>
                                </div>
                                <div className={styles.card_body_last_item}>
                                    {c.site && <a href={c.site} target="_balnk">На сайт вуза</a>}
                                    {!c.site && <p />}
                                    {/* <Link to="course_path" params={{ id: c.id }} className={styles.materials}>К материалам программы</Link> */}
                                    <a href={c.url} target='_blank' className={styles.materials}>К материалам программы</a>
                                </div>
                            </div>

                            <div className={styles.card_footer} onClick={() => (this.toggleOpenCard(c.id))}>Подробнее</div>
                        </div>
                    )}
                </div>

                <div className={styles.pagination}>
                    {[...Array(pages)].map((p, index) => (
                        // <div 
                        //     className={index == activePage ? styles.active_page: styles.page} 
                        //     onClick={() => (this.togglePage(index))}
                        //     key={index}
                        // />
                        <div 
                            className={index == activePage ? styles.active_page_new: styles.page_new} 
                            onClick={() => (this.togglePage(index))}
                            key={index}
                        >
                            {index + 1}
                        </div>
                    ))}
                </div>
            </>
            }

            {annotations.length == 0 && loading == false && <h1 className={styles.arrows}>Аннотации не найдены</h1> }
            {loading == true && <div className={styles.loading} />}

            {openedCard &&
                <div className={styles.opened_card_container}>
                    <div className={styles.opened_card}>
                        <div className={styles.opened_card_header}>
                            <div className={styles.close} onClick={() => (this.setState({openedCard: false}))} />
                        </div>
                        <div className={classNames(styles.opened_card_body, styles.scrollbar)}>
                            <p className={styles.card_paragraph_desc}>{openedCardItems.desc}</p>

                            <p className={styles.card_subtitle}>К освоению дополнительной профессиональной программе допускаются:</p>

                            <ul className={styles.modifications_ul}>
                                {openedCardItems.target_audience.map((t) => (
                                    <li key={t.id}>{t.value}</li>
                                ))}
                            </ul>

                            <p className={styles.card_subtitle}>Цель:</p>
                                {openedCardItems.goal}
                            <p className={styles.card_paragraph}>
                                
                            </p>

                            <p className={styles.card_subtitle}>Задачи:</p>

                            <ul className={styles.modifications_ul}>
                                {openedCardItems.tasks.map((t) => (
                                    <li key={t.id}>{t.value}</li>
                                ))}
                            </ul>

                            <p className={styles.card_subtitle}>Формируемые:</p>

                            <div className={styles.formed}>
                                <div className={styles.formed_item}>
                                    <div className={styles.formed_item_name}>Компетенции</div>
                                    <div 
                                        className={!openCompetencies ? classNames(styles.arrow, styles.arrow_down) : classNames(styles.arrow)} 
                                        onClick={() => (this.setState({openCompetencies: !openCompetencies}))} 
                                    />
                                </div>
                                {openCompetencies &&
                                    <ul className={styles.modifications_ul}>
                                        {openedCardItems.competencies.map((t) => (
                                            <li key={t.id}>{t.value}</li>
                                        ))}
                                    </ul>
                                }

                                <div className={styles.formed_item}>
                                    <div className={styles.formed_item_name}>Знания</div>
                                    <div 
                                        className={!openKnowledge ? classNames(styles.arrow, styles.arrow_down) : classNames(styles.arrow)} 
                                        onClick={() => (this.setState({openKnowledge: !openKnowledge}))} 
                                    />
                                </div>
                                {openKnowledge &&
                                    <ul className={styles.modifications_ul}>
                                        {openedCardItems.knowledge.map((t) => (
                                            <li key={t.id}>{t.value}</li>
                                        ))}
                                    </ul>
                                }

                                <div className={styles.formed_item}>
                                    <div className={styles.formed_item_name}>Умения</div>
                                    <div 
                                        className={!openSkills ? classNames(styles.arrow, styles.arrow_down) : classNames(styles.arrow)} 
                                        onClick={() => (this.setState({openSkills: !openSkills}))} 
                                    />
                                </div>
                                {openSkills &&
                                    <ul className={styles.modifications_ul}>
                                        {openedCardItems.skills.map((t) => (
                                            <li key={t.id}>{t.value}</li>
                                        ))}
                                    </ul>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            }
      </div>
      </div>
      </div>
    )
  }
}

export default TrainingsList
